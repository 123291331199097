"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file products.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsServiceSearchAllReq = exports.ProductsServiceCountReq = exports.ProductsServiceFilterReq = exports.ProductsServicePaginationResponse = exports.ProductsServicePaginationReq = exports.ProductsList = exports.Product = exports.ProductsServiceSendToStoreRequest = exports.ProductsServiceUpdateRequest = exports.ProductsServiceCreateRequest = exports.PRODUCT_REF_FROM = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Stores all the possible references from which a product can be added
 *
 * @generated from enum Scailo.PRODUCT_REF_FROM
 */
var PRODUCT_REF_FROM;
(function (PRODUCT_REF_FROM) {
    /**
     * Used only in filters
     *
     * @generated from enum value: PRODUCT_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    PRODUCT_REF_FROM[PRODUCT_REF_FROM["PRODUCT_REF_FROM_ANY_UNSPECIFIED"] = 0] = "PRODUCT_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes that the product originated from initial stock
     *
     * @generated from enum value: PRODUCT_REF_FROM_INITIAL_STOCK = 1;
     */
    PRODUCT_REF_FROM[PRODUCT_REF_FROM["PRODUCT_REF_FROM_INITIAL_STOCK"] = 1] = "PRODUCT_REF_FROM_INITIAL_STOCK";
    /**
     * Denotes that the product originated from a production plan
     *
     * @generated from enum value: PRODUCT_REF_FROM_PRODUCTION_PLAN = 5;
     */
    PRODUCT_REF_FROM[PRODUCT_REF_FROM["PRODUCT_REF_FROM_PRODUCTION_PLAN"] = 5] = "PRODUCT_REF_FROM_PRODUCTION_PLAN";
})(PRODUCT_REF_FROM || (exports.PRODUCT_REF_FROM = PRODUCT_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCT_REF_FROM)
protobuf_1.proto3.util.setEnumType(PRODUCT_REF_FROM, "Scailo.PRODUCT_REF_FROM", [
    { no: 0, name: "PRODUCT_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "PRODUCT_REF_FROM_INITIAL_STOCK" },
    { no: 5, name: "PRODUCT_REF_FROM_PRODUCTION_PLAN" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ProductsServiceCreateRequest
 */
class ProductsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 18;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.PRODUCT_REF_FROM ref_from = 31;
     */
    refFrom = PRODUCT_REF_FROM.PRODUCT_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * Stores the quantity (in cents)
     *
     * @generated from field: uint64 quantity = 37;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 39;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional quantity in the secondary unit of product
     *
     * @generated from field: uint64 secondary_quantity = 40;
     */
    secondaryQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional shelf life as a timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp = 41;
     */
    shelfLifeTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional warranty as a timestamp
     *
     * @generated from field: uint64 warranty_timestamp = 42;
     */
    warrantyTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the item needs to be stored
     *
     * @generated from field: uint64 location_id = 54;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The description of the product
     *
     * @generated from field: string description = 60;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCT_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "shelf_life_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "warranty_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 54, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceCreateRequest, a, b);
    }
}
exports.ProductsServiceCreateRequest = ProductsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ProductsServiceUpdateRequest
 */
class ProductsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 18;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 38;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional quantity in the secondary unit of product
     *
     * @generated from field: uint64 secondary_quantity = 39;
     */
    secondaryQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional shelf life as a timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp = 40;
     */
    shelfLifeTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional warranty as a timestamp
     *
     * @generated from field: uint64 warranty_timestamp = 41;
     */
    warrantyTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: bool is_qc_report_public = 52;
     */
    isQcReportPublic = false;
    /**
     * Stores any applicable remaining dimensions as a string
     *
     * @generated from field: string remaining_dimensions = 55;
     */
    remainingDimensions = "";
    /**
     * The description of the product
     *
     * @generated from field: string description = 60;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 18, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 38, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "shelf_life_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "warranty_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 55, name: "remaining_dimensions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceUpdateRequest, a, b);
    }
}
exports.ProductsServiceUpdateRequest = ProductsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to send a product to store
 *
 * @generated from message Scailo.ProductsServiceSendToStoreRequest
 */
class ProductsServiceSendToStoreRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional shelf life as a timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp = 40;
     */
    shelfLifeTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional warranty as a timestamp
     *
     * @generated from field: uint64 warranty_timestamp = 41;
     */
    warrantyTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: bool is_qc_report_public = 52;
     */
    isQcReportPublic = false;
    /**
     * Stores any applicable remaining dimensions as a string
     *
     * @generated from field: string remaining_dimensions = 55;
     */
    remainingDimensions = "";
    /**
     * The description of the product
     *
     * @generated from field: string description = 60;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceSendToStoreRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "shelf_life_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "warranty_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 55, name: "remaining_dimensions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceSendToStoreRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceSendToStoreRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceSendToStoreRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceSendToStoreRequest, a, b);
    }
}
exports.ProductsServiceSendToStoreRequest = ProductsServiceSendToStoreRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Product
 */
class Product extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this product
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this product
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 4;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this product
     *
     * @generated from field: repeated Scailo.LogbookLogInventoryLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when the item was admitted into store
     *
     * @generated from field: uint64 store_intake_at = 10;
     */
    storeIntakeAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was either consumed or rejected
     *
     * @generated from field: uint64 consumed_or_rejected_at = 11;
     */
    consumedOrRejectedAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the rework process began
     *
     * @generated from field: uint64 rework_start_at = 12;
     */
    reworkStartAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the rework process ended
     *
     * @generated from field: uint64 rework_end_at = 13;
     */
    reworkEndAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was either returned or scrapped
     *
     * @generated from field: uint64 returned_or_scrapped_at = 14;
     */
    returnedOrScrappedAt = protobuf_1.protoInt64.zero;
    /**
     * The timestamp of when the item was discarded
     *
     * @generated from field: uint64 discarded_at = 15;
     */
    discardedAt = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 18;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the parent inventory item (if applicable)
     *
     * @generated from field: uint64 parent_ref_id = 30;
     */
    parentRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.PRODUCT_REF_FROM ref_from = 31;
     */
    refFrom = PRODUCT_REF_FROM.PRODUCT_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The auto generated code of the product
     *
     * @generated from field: string code = 34;
     */
    code = "";
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * The computed hash of the product
     *
     * @generated from field: string hash = 36;
     */
    hash = "";
    /**
     * Stores the quantity (in cents)
     *
     * @generated from field: uint64 quantity = 37;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining = 38;
     */
    quantityRemaining = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 39;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional quantity in the secondary unit of product
     *
     * @generated from field: uint64 secondary_quantity = 40;
     */
    secondaryQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional shelf life as a timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp = 41;
     */
    shelfLifeTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional warranty as a timestamp
     *
     * @generated from field: uint64 warranty_timestamp = 42;
     */
    warrantyTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: bool is_qc_report_public = 52;
     */
    isQcReportPublic = false;
    /**
     * The location ID of where the item needs to be stored
     *
     * @generated from field: uint64 location_id = 54;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * Stores any applicable remaining dimensions as a string
     *
     * @generated from field: string remaining_dimensions = 55;
     */
    remainingDimensions = "";
    /**
     * The description of the product
     *
     * @generated from field: string description = 60;
     */
    description = "";
    /**
     * The short URL of the item
     *
     * @generated from field: string short_url = 70;
     */
    shortUrl = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Product";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogInventoryLC, repeated: true },
        { no: 10, name: "store_intake_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "consumed_or_rejected_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rework_start_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "rework_end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "returned_or_scrapped_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "discarded_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "parent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCT_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 34, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 38, name: "quantity_remaining", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "shelf_life_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "warranty_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 54, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "remaining_dimensions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "short_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new Product().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Product().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Product().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Product, a, b);
    }
}
exports.Product = Product;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ProductsList
 */
class ProductsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Product list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Product, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsList, a, b);
    }
}
exports.ProductsList = ProductsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ProductsServicePaginationReq
 */
class ProductsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INVENTORY_SORT_KEY sort_key = 5;
     */
    sortKey = base_scailo_pb_js_1.INVENTORY_SORT_KEY.INVENTORY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this product
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 6;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServicePaginationReq, a, b);
    }
}
exports.ProductsServicePaginationReq = ProductsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ProductsServicePaginationResponse
 */
class ProductsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Product payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Product, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServicePaginationResponse, a, b);
    }
}
exports.ProductsServicePaginationResponse = ProductsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ProductsServiceFilterReq
 */
class ProductsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INVENTORY_SORT_KEY sort_key = 5;
     */
    sortKey = base_scailo_pb_js_1.INVENTORY_SORT_KEY.INVENTORY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this product
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 10;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of store intake timestamp
     *
     * @generated from field: uint64 store_intake_at_start = 20;
     */
    storeIntakeAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of store intake timestamp
     *
     * @generated from field: uint64 store_intake_at_end = 21;
     */
    storeIntakeAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of discarded timestamp
     *
     * @generated from field: uint64 discarded_at_start = 22;
     */
    discardedAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of discarded timestamp
     *
     * @generated from field: uint64 discarded_at_end = 23;
     */
    discardedAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the parent inventory item
     *
     * @generated from field: uint64 parent_ref_id = 30;
     */
    parentRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.PRODUCT_REF_FROM ref_from = 31;
     */
    refFrom = PRODUCT_REF_FROM.PRODUCT_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The auto generated code of the product
     *
     * @generated from field: string code = 34;
     */
    code = "";
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * The computed hash of the product
     *
     * @generated from field: string hash = 36;
     */
    hash = "";
    /**
     * The minimum remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining_min = 37;
     */
    quantityRemainingMin = protobuf_1.protoInt64.zero;
    /**
     * The maximum remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining_max = 38;
     */
    quantityRemainingMax = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 39;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * The start range of shelf life timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp_start = 40;
     */
    shelfLifeTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of shelf life timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp_end = 41;
     */
    shelfLifeTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of the warranty timestamp
     *
     * @generated from field: uint64 warranty_timestamp_start = 42;
     */
    warrantyTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of the warranty timestamp
     *
     * @generated from field: uint64 warranty_timestamp_end = 43;
     */
    warrantyTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: Scailo.BOOL_FILTER is_qc_report_public = 52;
     */
    isQcReportPublic = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * Filter by the location ID
     *
     * @generated from field: uint64 location_id = 54;
     */
    locationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 20, name: "store_intake_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "store_intake_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "discarded_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "discarded_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "parent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCT_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 34, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "quantity_remaining_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 38, name: "quantity_remaining_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "shelf_life_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "shelf_life_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "warranty_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 43, name: "warranty_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 54, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceFilterReq, a, b);
    }
}
exports.ProductsServiceFilterReq = ProductsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ProductsServiceCountReq
 */
class ProductsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this product
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 10;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of store intake timestamp
     *
     * @generated from field: uint64 store_intake_at_start = 20;
     */
    storeIntakeAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of store intake timestamp
     *
     * @generated from field: uint64 store_intake_at_end = 21;
     */
    storeIntakeAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of discarded timestamp
     *
     * @generated from field: uint64 discarded_at_start = 22;
     */
    discardedAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of discarded timestamp
     *
     * @generated from field: uint64 discarded_at_end = 23;
     */
    discardedAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the parent inventory item
     *
     * @generated from field: uint64 parent_ref_id = 30;
     */
    parentRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.PRODUCT_REF_FROM ref_from = 31;
     */
    refFrom = PRODUCT_REF_FROM.PRODUCT_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The auto generated code of the product
     *
     * @generated from field: string code = 34;
     */
    code = "";
    /**
     * Stores the internal code (as given by user)
     *
     * @generated from field: string internal_item_code = 35;
     */
    internalItemCode = "";
    /**
     * The computed hash of the product
     *
     * @generated from field: string hash = 36;
     */
    hash = "";
    /**
     * The minimum remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining_min = 37;
     */
    quantityRemainingMin = protobuf_1.protoInt64.zero;
    /**
     * The maximum remaining quantity (in cents)
     *
     * @generated from field: uint64 quantity_remaining_max = 38;
     */
    quantityRemainingMax = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 39;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * The start range of shelf life timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp_start = 40;
     */
    shelfLifeTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of shelf life timestamp
     *
     * @generated from field: uint64 shelf_life_timestamp_end = 41;
     */
    shelfLifeTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of the warranty timestamp
     *
     * @generated from field: uint64 warranty_timestamp_start = 42;
     */
    warrantyTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of the warranty timestamp
     *
     * @generated from field: uint64 warranty_timestamp_end = 43;
     */
    warrantyTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: Scailo.BOOL_FILTER is_qc_report_public = 52;
     */
    isQcReportPublic = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * Filter by the location ID
     *
     * @generated from field: uint64 location_id = 54;
     */
    locationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 20, name: "store_intake_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "store_intake_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "discarded_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "discarded_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "parent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCT_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 34, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "internal_item_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "quantity_remaining_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 38, name: "quantity_remaining_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "shelf_life_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "shelf_life_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "warranty_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 43, name: "warranty_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 54, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceCountReq, a, b);
    }
}
exports.ProductsServiceCountReq = ProductsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ProductsServiceSearchAllReq
 */
class ProductsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INVENTORY_SORT_KEY sort_key = 5;
     */
    sortKey = base_scailo_pb_js_1.INVENTORY_SORT_KEY.INVENTORY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE status = 10;
     */
    status = base_scailo_pb_js_1.INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the parent inventory item
     *
     * @generated from field: uint64 parent_ref_id = 30;
     */
    parentRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the reference from
     *
     * @generated from field: Scailo.PRODUCT_REF_FROM ref_from = 31;
     */
    refFrom = PRODUCT_REF_FROM.PRODUCT_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 32;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the associated family
     *
     * @generated from field: uint64 family_id = 33;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional secondary unit of product
     *
     * @generated from field: uint64 secondary_uom_id = 38;
     */
    secondaryUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the store to which the product is sent to
     *
     * @generated from field: uint64 store_id = 50;
     */
    storeId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional ID of the associated storage that the product is stored in
     *
     * @generated from field: uint64 storage_id = 51;
     */
    storageId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the associated QC report should be public
     *
     * @generated from field: Scailo.BOOL_FILTER is_qc_report_public = 52;
     */
    isQcReportPublic = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.INVENTORY_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "parent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCT_REF_FROM) },
        { no: 32, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 38, name: "secondary_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "store_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "is_qc_report_public", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new ProductsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductsServiceSearchAllReq, a, b);
    }
}
exports.ProductsServiceSearchAllReq = ProductsServiceSearchAllReq;
