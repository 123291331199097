"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file infrastructures.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfrastructuresService = void 0;
const infrastructures_scailo_pb_js_1 = require("./infrastructures.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each infrastructure
 *
 * @generated from service Scailo.InfrastructuresService
 */
exports.InfrastructuresService = {
    typeName: "Scailo.InfrastructuresService",
    methods: {
        /**
         * Create (and Send for QC)
         *
         * @generated from rpc Scailo.InfrastructuresService.Create
         */
        create: {
            name: "Create",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send to Store
         *
         * @generated from rpc Scailo.InfrastructuresService.SendToStore
         */
        sendToStore: {
            name: "SendToStore",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceSendToStoreRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.InfrastructuresService.Update
         */
        update: {
            name: "Update",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for Rework
         *
         * @generated from rpc Scailo.InfrastructuresService.SendForRework
         */
        sendForRework: {
            name: "SendForRework",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for QC
         *
         * @generated from rpc Scailo.InfrastructuresService.SendForQC
         */
        sendForQC: {
            name: "SendForQC",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Split into unit quantities
         *
         * @generated from rpc Scailo.InfrastructuresService.SplitLot
         */
        splitLot: {
            name: "SplitLot",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create a partition from the parent lot (and returns the identifier of the new partition)
         *
         * @generated from rpc Scailo.InfrastructuresService.Partition
         */
        partition: {
            name: "Partition",
            I: base_scailo_pb_js_1.InventoryPartitionRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Consume
         *
         * @generated from rpc Scailo.InfrastructuresService.Consume
         */
        consume: {
            name: "Consume",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reject
         *
         * @generated from rpc Scailo.InfrastructuresService.Reject
         */
        reject: {
            name: "Reject",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Scrap
         *
         * @generated from rpc Scailo.InfrastructuresService.Scrap
         */
        scrap: {
            name: "Scrap",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Return
         *
         * @generated from rpc Scailo.InfrastructuresService.Return
         */
        return: {
            name: "Return",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.InfrastructuresService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.InfrastructuresService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.InfrastructuresService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: infrastructures_scailo_pb_js_1.Infrastructure,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.InfrastructuresService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: infrastructures_scailo_pb_js_1.Infrastructure,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.InfrastructuresService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: infrastructures_scailo_pb_js_1.InfrastructuresList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.InfrastructuresService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServicePaginationReq,
            O: infrastructures_scailo_pb_js_1.InfrastructuresServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given Identifier
         *
         * @generated from rpc Scailo.InfrastructuresService.DownloadQCReportByID
         */
        downloadQCReportByID: {
            name: "DownloadQCReportByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.InfrastructuresService.DownloadQCReportByUUID
         */
        downloadQCReportByUUID: {
            name: "DownloadQCReportByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given Identifier
         *
         * @generated from rpc Scailo.InfrastructuresService.DownloadLabelByID
         */
        downloadLabelByID: {
            name: "DownloadLabelByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.InfrastructuresService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all inventory interactions for infrastructure with the given IdentifierUUID
         *
         * @generated from rpc Scailo.InfrastructuresService.ViewInventoryInteractions
         */
        viewInventoryInteractions: {
            name: "ViewInventoryInteractions",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.InventoryInteractionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.InfrastructuresService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceSearchAllReq,
            O: infrastructures_scailo_pb_js_1.InfrastructuresList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.InfrastructuresService.Filter
         */
        filter: {
            name: "Filter",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceFilterReq,
            O: infrastructures_scailo_pb_js_1.InfrastructuresList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.InfrastructuresService.Count
         */
        count: {
            name: "Count",
            I: infrastructures_scailo_pb_js_1.InfrastructuresServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
