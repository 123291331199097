import { 
    associateFiltersMenu, 
    // associateInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleAssociateFilters } from "./associates.filters";
// import { handleAssociateInsights } from "./associates.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(associateFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, associateFiltersMenu)) {
            handleAssociateFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(associateInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, associateInsightsMenu)) {
    //         handleAssociateInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
}