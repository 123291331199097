"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file attendances.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendancesServiceSearchAllReq = exports.AttendancesServiceCountReq = exports.AttendancesServiceFilterReq = exports.AttendancesServicePaginationResponse = exports.AttendancesServicePaginationReq = exports.AttendancesList = exports.Attendance = exports.AttendancesServiceImageExitRequest = exports.AttendancesServiceImageEntryRequest = exports.AttendancesServiceUpdateRequest = exports.AttendancesServiceCreateRequest = exports.ATTENDANCE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ATTENDANCE_SORT_KEY
 */
var ATTENDANCE_SORT_KEY;
(function (ATTENDANCE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ATTENDANCE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_CREATED_AT = 1;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_CREATED_AT"] = 1] = "ATTENDANCE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_MODIFIED_AT = 2;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_MODIFIED_AT"] = 2] = "ATTENDANCE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_APPROVED_ON = 3;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_APPROVED_ON"] = 3] = "ATTENDANCE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_APPROVED_BY = 4;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_APPROVED_BY"] = 4] = "ATTENDANCE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ATTENDANCE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_COMPLETED_ON = 6;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_COMPLETED_ON"] = 6] = "ATTENDANCE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_REFERENCE_ID = 10;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_REFERENCE_ID"] = 10] = "ATTENDANCE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "ATTENDANCE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the user ID
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_USER_ID = 12;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_USER_ID"] = 12] = "ATTENDANCE_SORT_KEY_USER_ID";
    /**
     * Fetch ordered results by the "from timestamp"
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_ENTRY_TIMESTAMP = 13;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_ENTRY_TIMESTAMP"] = 13] = "ATTENDANCE_SORT_KEY_ENTRY_TIMESTAMP";
    /**
     * Fetch ordered results by the "to timestamp"
     *
     * @generated from enum value: ATTENDANCE_SORT_KEY_EXIT_TIMESTAMP = 14;
     */
    ATTENDANCE_SORT_KEY[ATTENDANCE_SORT_KEY["ATTENDANCE_SORT_KEY_EXIT_TIMESTAMP"] = 14] = "ATTENDANCE_SORT_KEY_EXIT_TIMESTAMP";
})(ATTENDANCE_SORT_KEY || (exports.ATTENDANCE_SORT_KEY = ATTENDANCE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ATTENDANCE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ATTENDANCE_SORT_KEY, "Scailo.ATTENDANCE_SORT_KEY", [
    { no: 0, name: "ATTENDANCE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ATTENDANCE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ATTENDANCE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ATTENDANCE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ATTENDANCE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ATTENDANCE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "ATTENDANCE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ATTENDANCE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "ATTENDANCE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "ATTENDANCE_SORT_KEY_USER_ID" },
    { no: 13, name: "ATTENDANCE_SORT_KEY_ENTRY_TIMESTAMP" },
    { no: 14, name: "ATTENDANCE_SORT_KEY_EXIT_TIMESTAMP" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.AttendancesServiceCreateRequest
 */
class AttendancesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the attendance
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the attendance begins
     *
     * @generated from field: uint64 entry_timestamp = 12;
     */
    entryTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the attendance ends
     *
     * @generated from field: uint64 exit_timestamp = 13;
     */
    exitTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the attendance
     *
     * @generated from field: string description = 14;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "entry_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "exit_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceCreateRequest, a, b);
    }
}
exports.AttendancesServiceCreateRequest = AttendancesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.AttendancesServiceUpdateRequest
 */
class AttendancesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the attendance
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The UNIX timestamp from when the attendance begins
     *
     * @generated from field: uint64 entry_timestamp = 12;
     */
    entryTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the attendance ends
     *
     * @generated from field: uint64 exit_timestamp = 13;
     */
    exitTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the attendance
     *
     * @generated from field: string description = 14;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "entry_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "exit_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceUpdateRequest, a, b);
    }
}
exports.AttendancesServiceUpdateRequest = AttendancesServiceUpdateRequest;
/**
 *
 * Describes the message payload that is necessary to create an entry record. This will most likely be called only from mobile devices.
 *
 * @generated from message Scailo.AttendancesServiceImageEntryRequest
 */
class AttendancesServiceImageEntryRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * // The reference ID of the attendance
     * string reference_id = 10 [(genesis_validate.rules).string = {
     *     pattern: "[0-9A-Za-z ]+$", // Allow spaces
     * }];
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The raw image content of the entry image
     *
     * @generated from field: bytes entry_image = 13;
     */
    entryImage = new Uint8Array(0);
    /**
     * The MIME type of the entry image
     *
     * @generated from field: string entry_image_mime_type = 14;
     */
    entryImageMimeType = "";
    /**
     * The latitude of where the attendance entry was recorded
     *
     * @generated from field: double entry_latitude = 15;
     */
    entryLatitude = 0;
    /**
     * The longiture of where the attendance entry was recorded
     *
     * @generated from field: double entry_longitude = 16;
     */
    entryLongitude = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceImageEntryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "entry_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 14, name: "entry_image_mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "entry_latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 16, name: "entry_longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceImageEntryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceImageEntryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceImageEntryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceImageEntryRequest, a, b);
    }
}
exports.AttendancesServiceImageEntryRequest = AttendancesServiceImageEntryRequest;
/**
 *
 * Describes the message payload that is necessary to create an exit record. This will most likely be called only from mobile devices.
 *
 * @generated from message Scailo.AttendancesServiceImageExitRequest
 */
class AttendancesServiceImageExitRequest extends protobuf_1.Message {
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The raw image content of the exit image
     *
     * @generated from field: bytes exit_image = 13;
     */
    exitImage = new Uint8Array(0);
    /**
     * The MIME type of the exit image
     *
     * @generated from field: string exit_image_mime_type = 14;
     */
    exitImageMimeType = "";
    /**
     * The latitude of where the attendance exit was recorded
     *
     * @generated from field: double exit_latitude = 15;
     */
    exitLatitude = 0;
    /**
     * The longiture of where the attendance exit was recorded
     *
     * @generated from field: double exit_longitude = 16;
     */
    exitLongitude = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceImageExitRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "exit_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 14, name: "exit_image_mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "exit_latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 16, name: "exit_longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceImageExitRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceImageExitRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceImageExitRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceImageExitRequest, a, b);
    }
}
exports.AttendancesServiceImageExitRequest = AttendancesServiceImageExitRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Attendance
 */
class Attendance extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this attendance
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this attendance
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this attendance
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this attendance was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the attendance
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 12;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the attendance begins
     *
     * @generated from field: uint64 entry_timestamp = 13;
     */
    entryTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the attendance ends
     *
     * @generated from field: uint64 exit_timestamp = 14;
     */
    exitTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the attendance
     *
     * @generated from field: string description = 15;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Attendance";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "entry_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "exit_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Attendance().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Attendance().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Attendance().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Attendance, a, b);
    }
}
exports.Attendance = Attendance;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.AttendancesList
 */
class AttendancesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Attendance list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Attendance, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesList, a, b);
    }
}
exports.AttendancesList = AttendancesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.AttendancesServicePaginationReq
 */
class AttendancesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ATTENDANCE_SORT_KEY sort_key = 5;
     */
    sortKey = ATTENDANCE_SORT_KEY.ATTENDANCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this attendance
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ATTENDANCE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServicePaginationReq, a, b);
    }
}
exports.AttendancesServicePaginationReq = AttendancesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.AttendancesServicePaginationResponse
 */
class AttendancesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Attendance payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Attendance, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServicePaginationResponse, a, b);
    }
}
exports.AttendancesServicePaginationResponse = AttendancesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.AttendancesServiceFilterReq
 */
class AttendancesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ATTENDANCE_SORT_KEY sort_key = 5;
     */
    sortKey = ATTENDANCE_SORT_KEY.ATTENDANCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this attendance
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the attendance
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The start range of "from timestamp"
     *
     * @generated from field: uint64 entry_timestamp_start = 24;
     */
    entryTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "from timestamp"
     *
     * @generated from field: uint64 entry_timestamp_end = 25;
     */
    entryTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "to timestamp"
     *
     * @generated from field: uint64 exit_timestamp_start = 26;
     */
    exitTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "to timestamp"
     *
     * @generated from field: uint64 exit_timestamp_end = 27;
     */
    exitTimestampEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ATTENDANCE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "entry_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "entry_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "exit_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "exit_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceFilterReq, a, b);
    }
}
exports.AttendancesServiceFilterReq = AttendancesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.AttendancesServiceCountReq
 */
class AttendancesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this attendance
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the attendance
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The start range of "from timestamp"
     *
     * @generated from field: uint64 entry_timestamp_start = 24;
     */
    entryTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "from timestamp"
     *
     * @generated from field: uint64 entry_timestamp_end = 25;
     */
    entryTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "to timestamp"
     *
     * @generated from field: uint64 exit_timestamp_start = 26;
     */
    exitTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "to timestamp"
     *
     * @generated from field: uint64 exit_timestamp_end = 27;
     */
    exitTimestampEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "entry_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "entry_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "exit_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "exit_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceCountReq, a, b);
    }
}
exports.AttendancesServiceCountReq = AttendancesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.AttendancesServiceSearchAllReq
 */
class AttendancesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ATTENDANCE_SORT_KEY sort_key = 5;
     */
    sortKey = ATTENDANCE_SORT_KEY.ATTENDANCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the user that this attendance belongs to
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AttendancesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ATTENDANCE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AttendancesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AttendancesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AttendancesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AttendancesServiceSearchAllReq, a, b);
    }
}
exports.AttendancesServiceSearchAllReq = AttendancesServiceSearchAllReq;
