import { Router } from "./router";
import * as login from "./login";
import * as admin from "./admin/routes";

import * as orders from "./orders/orders";
import * as inventory from "./inventory/inventory";
import * as production from "./production/production";
import * as payments from "./payments/payments";
import * as quotations from "./quotations/quotations";
import * as qa from "./qa/qa";

export function start() {
    let r = new Router();
    login.Routes(r);
    admin.Routes(r);
    orders.Routes(r);
    inventory.Routes(r);
    production.Routes(r);
    payments.Routes(r);
    quotations.Routes(r);
    qa.Routes(r);
    
    r.setDefault((ctx) => {
        location.href = "/";
    });

    r.start();
}

