"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vault_services.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiXRelayResponse = exports.GiXRelayReqWithBody = exports.GiXRelayReqWithoutBody = exports.GiXRelayHeader = exports.VaultResourcesList = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const vault_files_scailo_pb_js_1 = require("./vault_files.scailo_pb.js");
const vault_folders_scailo_pb_js_1 = require("./vault_folders.scailo_pb.js");
/**
 *
 * Describes the message consisting of the list of vault resources
 *
 * @generated from message Scailo.VaultResourcesList
 */
class VaultResourcesList extends protobuf_1.Message {
    /**
     * List of files
     *
     * @generated from field: repeated Scailo.VaultFile files = 1;
     */
    files = [];
    /**
     * List of folders
     *
     * @generated from field: repeated Scailo.VaultFolder folders = 2;
     */
    folders = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultResourcesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "files", kind: "message", T: vault_files_scailo_pb_js_1.VaultFile, repeated: true },
        { no: 2, name: "folders", kind: "message", T: vault_folders_scailo_pb_js_1.VaultFolder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultResourcesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultResourcesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultResourcesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultResourcesList, a, b);
    }
}
exports.VaultResourcesList = VaultResourcesList;
/**
 *
 * Describes the message that stores the headers necessary for performing the RELAY request
 *
 * @generated from message Scailo.GiXRelayHeader
 */
class GiXRelayHeader extends protobuf_1.Message {
    /**
     * The name of the header
     *
     * @generated from field: string name = 1;
     */
    name = "";
    /**
     * The value of the header
     *
     * @generated from field: string value = 2;
     */
    value = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXRelayHeader";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXRelayHeader().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXRelayHeader().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXRelayHeader().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXRelayHeader, a, b);
    }
}
exports.GiXRelayHeader = GiXRelayHeader;
/**
 *
 * Describes the message that is required to execute a RELAY instruction for GET, DELETE and HEAD requests (without payload body)
 *
 * @generated from message Scailo.GiXRelayReqWithoutBody
 */
class GiXRelayReqWithoutBody extends protobuf_1.Message {
    /**
     * The UUID of the app execution
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The content type header of the relay request (e.g., 'application/json', 'text/html')
     *
     * @generated from field: string relay_content_type_header = 4;
     */
    relayContentTypeHeader = "";
    /**
     * The accept header of the relay request (e.g., 'application/json', 'text/html')
     *
     * @generated from field: string relay_accept_header = 5;
     */
    relayAcceptHeader = "";
    /**
     * The list of additional headers that will be relayed to the remote URL
     *
     * @generated from field: repeated Scailo.GiXRelayHeader relay_headers = 10;
     */
    relayHeaders = [];
    /**
     * The remote URL that needs to be accessed
     *
     * @generated from field: string url = 20;
     */
    url = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXRelayReqWithoutBody";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 4, name: "relay_content_type_header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 5, name: "relay_accept_header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "relay_headers", kind: "message", T: GiXRelayHeader, repeated: true },
        { no: 20, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXRelayReqWithoutBody().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXRelayReqWithoutBody().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXRelayReqWithoutBody().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXRelayReqWithoutBody, a, b);
    }
}
exports.GiXRelayReqWithoutBody = GiXRelayReqWithoutBody;
/**
 *
 * Describes the message that is required to execute a RELAY instruction for POST, PUT and PATCH requests (with payload)
 *
 * @generated from message Scailo.GiXRelayReqWithBody
 */
class GiXRelayReqWithBody extends protobuf_1.Message {
    /**
     * The UUID of the app execution
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The content type header of the relay request (e.g., 'application/json', 'text/html')
     *
     * @generated from field: string relay_content_type_header = 4;
     */
    relayContentTypeHeader = "";
    /**
     * The accept header of the relay request (e.g., 'application/json', 'text/html')
     *
     * @generated from field: string relay_accept_header = 5;
     */
    relayAcceptHeader = "";
    /**
     * The list of additional headers that will be relayed to the remote URL
     *
     * @generated from field: repeated Scailo.GiXRelayHeader relay_headers = 10;
     */
    relayHeaders = [];
    /**
     * The remote URL that needs to be accessed
     *
     * @generated from field: string url = 20;
     */
    url = "";
    /**
     * The body that needs to be relayed
     *
     * @generated from field: bytes body = 30;
     */
    body = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXRelayReqWithBody";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 4, name: "relay_content_type_header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 5, name: "relay_accept_header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "relay_headers", kind: "message", T: GiXRelayHeader, repeated: true },
        { no: 20, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "body", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXRelayReqWithBody().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXRelayReqWithBody().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXRelayReqWithBody().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXRelayReqWithBody, a, b);
    }
}
exports.GiXRelayReqWithBody = GiXRelayReqWithBody;
/**
 *
 * Describes the payload that will be returned to the GiX Relay RPC
 *
 * @generated from message Scailo.GiXRelayResponse
 */
class GiXRelayResponse extends protobuf_1.Message {
    /**
     * The UUID of the app execution
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The response from the remote URL
     *
     * @generated from field: bytes body = 10;
     */
    body = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXRelayResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "body", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXRelayResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXRelayResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXRelayResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXRelayResponse, a, b);
    }
}
exports.GiXRelayResponse = GiXRelayResponse;
