import { Interceptor, UnaryResponse, StreamResponse, ConnectError, Code } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { showFailureAlert } from "./utilities";

// appendAuthToken is an interceptor that appends the auth token to the request prior to sending the request
const appendAuthToken: Interceptor = (next) => async (req) => {
    return await next(req).catch((err: ConnectError) => {
        if (err) {
            if (err.code == Code.Unauthenticated) {
                location.href = "/";
                return;
            }
            showFailureAlert(err.message);
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

export function getTransport() {
    return createConnectTransport({
        // Need to use binary format (at least for the time being)
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken
        ]
    });
}
