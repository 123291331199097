"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file forms_sections.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsSectionsServiceSearchAllReq = exports.FormsSectionsServiceCountReq = exports.FormsSectionsServiceFilterReq = exports.FormsSectionsServicePaginationReq = exports.FormsSectionsServiceUpdateRequest = exports.FormsSectionsServiceCreateRequest = exports.FormSectionPaginationResp = exports.FormsSectionsList = exports.FormSection = exports.FORM_SECTION_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys for retrieving forms sections
 *
 * @generated from enum Scailo.FORM_SECTION_SORT_KEY
 */
var FORM_SECTION_SORT_KEY;
(function (FORM_SECTION_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: FORM_SECTION_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    FORM_SECTION_SORT_KEY[FORM_SECTION_SORT_KEY["FORM_SECTION_SORT_KEY_ID_UNSPECIFIED"] = 0] = "FORM_SECTION_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: FORM_SECTION_SORT_KEY_CREATED_AT = 1;
     */
    FORM_SECTION_SORT_KEY[FORM_SECTION_SORT_KEY["FORM_SECTION_SORT_KEY_CREATED_AT"] = 1] = "FORM_SECTION_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: FORM_SECTION_SORT_KEY_MODIFIED_AT = 2;
     */
    FORM_SECTION_SORT_KEY[FORM_SECTION_SORT_KEY["FORM_SECTION_SORT_KEY_MODIFIED_AT"] = 2] = "FORM_SECTION_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by name
     *
     * @generated from enum value: FORM_SECTION_SORT_KEY_NAME = 10;
     */
    FORM_SECTION_SORT_KEY[FORM_SECTION_SORT_KEY["FORM_SECTION_SORT_KEY_NAME"] = 10] = "FORM_SECTION_SORT_KEY_NAME";
})(FORM_SECTION_SORT_KEY || (exports.FORM_SECTION_SORT_KEY = FORM_SECTION_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(FORM_SECTION_SORT_KEY)
protobuf_1.proto3.util.setEnumType(FORM_SECTION_SORT_KEY, "Scailo.FORM_SECTION_SORT_KEY", [
    { no: 0, name: "FORM_SECTION_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "FORM_SECTION_SORT_KEY_CREATED_AT" },
    { no: 2, name: "FORM_SECTION_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "FORM_SECTION_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the data structure of each form section on the platform
 *
 * @generated from message Scailo.FormSection
 */
class FormSection extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The name of the form section
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form section
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * A short description of the form section
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * The width of the form section
     *
     * @generated from field: string width = 13;
     */
    width = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormSection";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormSection().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormSection().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormSection().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormSection, a, b);
    }
}
exports.FormSection = FormSection;
/**
 *
 * Describes the data structure that stores a list of forms sections
 *
 * @generated from message Scailo.FormsSectionsList
 */
class FormsSectionsList extends protobuf_1.Message {
    /**
     * List of forms sections
     *
     * @generated from field: repeated Scailo.FormSection list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FormSection, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsList, a, b);
    }
}
exports.FormsSectionsList = FormsSectionsList;
/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message Scailo.FormSectionPaginationResp
 */
class FormSectionPaginationResp extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.FormSection payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormSectionPaginationResp";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: FormSection, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormSectionPaginationResp().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormSectionPaginationResp().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormSectionPaginationResp().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormSectionPaginationResp, a, b);
    }
}
exports.FormSectionPaginationResp = FormSectionPaginationResp;
/**
 *
 * Describes the necessary data structure during creation of a form section
 *
 * @generated from message Scailo.FormsSectionsServiceCreateRequest
 */
class FormsSectionsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the form section
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form section
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    /**
     * A short description of the form section
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * The width of the form section
     *
     * @generated from field: string width = 13;
     */
    width = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServiceCreateRequest, a, b);
    }
}
exports.FormsSectionsServiceCreateRequest = FormsSectionsServiceCreateRequest;
/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a form section
 *
 * @generated from message Scailo.FormsSectionsServiceUpdateRequest
 */
class FormsSectionsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the form section that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The name of the form section
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * A short description of the form section
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * The width of the form section
     *
     * @generated from field: string width = 13;
     */
    width = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "width", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServiceUpdateRequest, a, b);
    }
}
exports.FormsSectionsServiceUpdateRequest = FormsSectionsServiceUpdateRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.FormsSectionsServicePaginationReq
 */
class FormsSectionsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FORM_SECTION_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_SECTION_SORT_KEY.FORM_SECTION_SORT_KEY_ID_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_SECTION_SORT_KEY) },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServicePaginationReq, a, b);
    }
}
exports.FormsSectionsServicePaginationReq = FormsSectionsServicePaginationReq;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.FormsSectionsServiceFilterReq
 */
class FormsSectionsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FORM_SECTION_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_SECTION_SORT_KEY.FORM_SECTION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The name of the form section
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form section
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_SECTION_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServiceFilterReq, a, b);
    }
}
exports.FormsSectionsServiceFilterReq = FormsSectionsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.FormsSectionsServiceCountReq
 */
class FormsSectionsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The name of the form section
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The type of the form section
     *
     * @generated from field: Scailo.FORM_TYPE type = 11;
     */
    type = base_scailo_pb_js_1.FORM_TYPE.FORM_TYPE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.FORM_TYPE) },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServiceCountReq, a, b);
    }
}
exports.FormsSectionsServiceCountReq = FormsSectionsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on forms sections
 *
 * @generated from message Scailo.FormsSectionsServiceSearchAllReq
 */
class FormsSectionsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the response
     *
     * @generated from field: Scailo.FORM_SECTION_SORT_KEY sort_key = 5;
     */
    sortKey = FORM_SECTION_SORT_KEY.FORM_SECTION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter forms sections
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 10;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormsSectionsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FORM_SECTION_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormsSectionsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormsSectionsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormsSectionsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormsSectionsServiceSearchAllReq, a, b);
    }
}
exports.FormsSectionsServiceSearchAllReq = FormsSectionsServiceSearchAllReq;
