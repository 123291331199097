"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file goals.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalsService = void 0;
const goals_scailo_pb_js_1 = require("./goals.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each goal
 *
 * @generated from service Scailo.GoalsService
 */
exports.GoalsService = {
    typeName: "Scailo.GoalsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.GoalsService.Create
         */
        create: {
            name: "Create",
            I: goals_scailo_pb_js_1.GoalsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.GoalsService.Draft
         */
        draft: {
            name: "Draft",
            I: goals_scailo_pb_js_1.GoalsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.GoalsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: goals_scailo_pb_js_1.GoalsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.GoalsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.GoalsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.GoalsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.GoalsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.GoalsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: goals_scailo_pb_js_1.GoalsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.GoalsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.GoalsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.GoalsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.GoalsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.GoalsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.GoalsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.GoalsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone goal from an existing goal (denoted by the identifier)
         *
         * @generated from rpc Scailo.GoalsService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a goal
         *
         * @generated from rpc Scailo.GoalsService.AddGoalItem
         */
        addGoalItem: {
            name: "AddGoalItem",
            I: goals_scailo_pb_js_1.GoalsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a goal
         *
         * @generated from rpc Scailo.GoalsService.ModifyGoalItem
         */
        modifyGoalItem: {
            name: "ModifyGoalItem",
            I: goals_scailo_pb_js_1.GoalsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a goal
         *
         * @generated from rpc Scailo.GoalsService.ApproveGoalItem
         */
        approveGoalItem: {
            name: "ApproveGoalItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a goal
         *
         * @generated from rpc Scailo.GoalsService.DeleteGoalItem
         */
        deleteGoalItem: {
            name: "DeleteGoalItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a goal
         *
         * @generated from rpc Scailo.GoalsService.ReorderGoalItems
         */
        reorderGoalItems: {
            name: "ReorderGoalItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View goal Item by ID
         *
         * @generated from rpc Scailo.GoalsService.ViewGoalItemByID
         */
        viewGoalItemByID: {
            name: "ViewGoalItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goals_scailo_pb_js_1.GoalItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goal items for given goal ID
         *
         * @generated from rpc Scailo.GoalsService.ViewApprovedGoalItems
         */
        viewApprovedGoalItems: {
            name: "ViewApprovedGoalItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goals_scailo_pb_js_1.GoalsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goal items for given goal ID
         *
         * @generated from rpc Scailo.GoalsService.ViewUnapprovedGoalItems
         */
        viewUnapprovedGoalItems: {
            name: "ViewUnapprovedGoalItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goals_scailo_pb_js_1.GoalsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the goal item
         *
         * @generated from rpc Scailo.GoalsService.ViewGoalItemHistory
         */
        viewGoalItemHistory: {
            name: "ViewGoalItemHistory",
            I: goals_scailo_pb_js_1.GoalItemHistoryRequest,
            O: goals_scailo_pb_js_1.GoalsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goal items for given goal ID with pagination
         *
         * @generated from rpc Scailo.GoalsService.ViewPaginatedApprovedGoalItems
         */
        viewPaginatedApprovedGoalItems: {
            name: "ViewPaginatedApprovedGoalItems",
            I: goals_scailo_pb_js_1.GoalItemsSearchRequest,
            O: goals_scailo_pb_js_1.GoalsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goal items for given goal ID with pagination
         *
         * @generated from rpc Scailo.GoalsService.ViewPaginatedUnapprovedGoalItems
         */
        viewPaginatedUnapprovedGoalItems: {
            name: "ViewPaginatedUnapprovedGoalItems",
            I: goals_scailo_pb_js_1.GoalItemsSearchRequest,
            O: goals_scailo_pb_js_1.GoalsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through goal items with pagination
         *
         * @generated from rpc Scailo.GoalsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: goals_scailo_pb_js_1.GoalItemsSearchRequest,
            O: goals_scailo_pb_js_1.GoalsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.GoalsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goals_scailo_pb_js_1.Goal,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.GoalsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: goals_scailo_pb_js_1.GoalsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.GoalsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goals_scailo_pb_js_1.GoalsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.GoalsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: goals_scailo_pb_js_1.GoalsServicePaginationReq,
            O: goals_scailo_pb_js_1.GoalsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.GoalsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: goals_scailo_pb_js_1.GoalsServiceSearchAllReq,
            O: goals_scailo_pb_js_1.GoalsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.GoalsService.Filter
         */
        filter: {
            name: "Filter",
            I: goals_scailo_pb_js_1.GoalsServiceFilterReq,
            O: goals_scailo_pb_js_1.GoalsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.GoalsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.GoalsService.Count
         */
        count: {
            name: "Count",
            I: goals_scailo_pb_js_1.GoalsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
