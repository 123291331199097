import { context } from "./../../router";
import { BOOL_FILTER, GeneralSettings, SORT_ORDER, User, Client, CLIENT_STREAM_LIFECYCLE, CLIENT_STREAM_MESSAGE_TYPE, CLIENT_STREAM_REF_FROM, CLIENT_STREAM_SORT_KEY, ClientStream, ClientStreamInternalSubscriber, ClientStreamMessage, ClientStreamsService, ClientStreamsServiceCountReq, ClientStreamsServiceFilterReq, ClientStreamClientSubscriber } from "@kernelminds/scailo-sdk";
import { getGeneralSettingsServiceClient, getSalesOrderServiceClient, getClientsServiceClient, getClientStreamsServiceClient, getGoodsDispatchServiceClient, getSalesInvoiceServiceClient, getSalesReturnServiceClient, getCreditNoteServiceClient, getSalesReceiptServiceClient, getSalesEnquiriesServiceClient, getSalesQuotationsServiceClient } from "../../clients";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderInput } from "../../ui";
import { attachEditor, convertBigIntTimestampToDateTime, convertEditorJSContentToHTML, decodeClientStreamRefFrom, decodeClientStreamStatus, randomId, returnUserBadge, toTitleCase } from "../../utilities";
import { PromiseClient } from "@connectrpc/connect";
import { usersMapFromUsernames, usersMapFromUUIDs } from "../../fetches";

const replyButton = "reply-button";
const goToParentMessageButton = "go-to-parent-message-button";
const expandStreamButton = "expand-stream-button";
const messageDiv = "__message-div";

export async function handleIndividualStream(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const accessClient = getClientStreamsServiceClient();
    const [clientstream, messages] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewMessages({ uuid: ctx.params.uuid })
    ]);
    document.title = `Stream - ` + clientstream.internalRef;

    let container = document.createElement("div");
    container.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded";
    content.appendChild(container);

    let title = document.createElement("h4");
    title.innerText = document.title;
    title.classList.add("text-center");
    container.appendChild(title);

    const { formGrid, usersByUsernameMap } = await getForm(clientstream, messages.list, accessClient);

    container.appendChild(formGrid);

    // At the bottom, have an ADD MESSAGE button (if status = Open)
    if (clientstream.status == CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_OPEN) {
        let buttonContainer = document.createElement("div");
        buttonContainer.classList.add("col-span-3", "grid", "justify-end");
        let addButton = document.createElement("button");
        addButton.innerText = "Add Message";
        addButton.className = "btn btn-success btn-outline btn-sm";
        buttonContainer.appendChild(addButton);
        container.appendChild(buttonContainer);

        addButton.addEventListener("click", async evt => {
            evt.preventDefault();
            handleAddStreamMessage(accessClient, clientstream.metadata?.uuid!, usersByUsernameMap, {
                responseToMessageUuid: "",
                parentMessage: undefined,
                index: 0,
            }, clientstream.vaultFolderUuid);
        });
    }

    let messagesMap: Map<string, ClientStreamMessage> = new Map();
    messages.list.forEach(message => {
        messagesMap.set(message.metadata?.uuid!, message);
    });

    // When the add button is clicked, open up a modal, with editorJS configured
    // Reuse the modal for replies as well
    let replyButtons = container.getElementsByClassName(replyButton);
    for (let i = 0; i < replyButtons.length; i++) {
        const replyBtn = <HTMLElement>replyButtons[i];
        replyBtn.addEventListener("click", async evt => {
            evt.preventDefault();
            handleAddStreamMessage(accessClient, clientstream.metadata?.uuid!, usersByUsernameMap, {
                responseToMessageUuid: replyBtn.getAttribute("data-uuid") || "",
                parentMessage: messagesMap.get(replyBtn.getAttribute("data-uuid") || ""),
                index: parseInt(replyBtn.getAttribute("data-index")!) || 0,
            }, clientstream.vaultFolderUuid);
        });
    }

    let goToParentButtons = container.getElementsByClassName(goToParentMessageButton);
    for (let i = 0; i < goToParentButtons.length; i++) {
        const btn = <HTMLElement>goToParentButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            let divs = container.getElementsByClassName(messageDiv);
            for (let j = 0; i < divs.length; j++) {
                if (divs[j].getAttribute("data-uuid") == btn.getAttribute("data-parent-uuid")) {
                    divs[j].scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                }
            }
        });
    }

    let expandMessageButtons = container.getElementsByClassName(expandStreamButton);
    for (let i = 0; i < expandMessageButtons.length; i++) {
        let btn = <HTMLElement>expandMessageButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            const messageUuid = btn.getAttribute("data-uuid") || "";
            const parentMessage = messagesMap.get(messageUuid) || new ClientStreamMessage();

            let childMessages = <HTMLDivElement[]>[
                renderIndividualClientStreamMessage(parentMessage, parseInt(btn.getAttribute("data-index") || "0"), usersByUsernameMap, true)
            ];
            for (let j = 0; j < messages.list.length; j++) {
                let m = messages.list[j];
                if (m.responseToMessageUuid == messageUuid) {
                    childMessages.push(renderIndividualClientStreamMessage(m, j + 1, usersByUsernameMap, true));
                }
            }

            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();

            let modalDiv = document.createElement("div");
            let title = document.createElement("h4");
            title.style.textAlign = "center";
            title.innerText = `Sub Stream - ${parentMessage.internalRef}`;
            modalDiv.appendChild(title);

            childMessages.forEach(m => {
                modalDiv.appendChild(m)
            });

            dialog.innerHTML = `
                <div class="max-w-full modal-box bg-white">
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                    ${modalDiv.outerHTML}
                </div>
            `;

            document.body.appendChild(dialog);
            dialog.showModal();
        });
    }
}

async function getForm(clientstream: ClientStream, messages: ClientStreamMessage[], accessClient: PromiseClient<typeof ClientStreamsService>) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-12";

    // Create a form with the individual fields
    formGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Title", inputType: "text", dataMapper: "title", dataType: "string", value: clientstream.title, mdColSpan: 12, helpText: "Title of the stream." }));
    formGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Internal Ref", inputType: "text", dataMapper: "internalRef", dataType: "string", value: clientstream.internalRef, mdColSpan: 2, helpText: "Internal Reference of the stream." }));
    // Display status as well
    formGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Stream Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeClientStreamStatus(clientstream.status)), mdColSpan: 2, helpText: "Status of the stream." }));

    // Display ref from as an input
    formGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Ref From", inputType: "text", dataMapper: "refFrom", dataType: "string", value: toTitleCase(decodeClientStreamRefFrom(clientstream.refFrom).split("-").join(" ")), mdColSpan: 2, helpText: "The reference type of this stream." }));

    let reference = "";

    // Display ref ID as an input
    if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_ORDER) {
        const accessClient = getSalesOrderServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_GOODS_DISPATCH) {
        const accessClient = getGoodsDispatchServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_INVOICE) {
        const accessClient = getSalesInvoiceServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_RETURN) {
        const accessClient = getSalesReturnServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_CREDIT_NOTE) {
        const accessClient = getCreditNoteServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_RECEIPT) {
        const accessClient = getSalesReceiptServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_QUOTATION) {
        const accessClient = getSalesQuotationsServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    } else if (clientstream.refFrom == CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_SALES_ENQUIRY) {
        const accessClient = getSalesEnquiriesServiceClient();
        let resp = await accessClient.viewByUUID({ uuid: clientstream.refUuid });
        reference = resp.approvalMetadata?.approvedOn || 0 > 0 ? resp.finalRefNumber : resp.referenceId;
    }

    formGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: reference, mdColSpan: 6, helpText: "The reference of this stream." }));

    const [
        organizationInfo, clientInfo, internalSubscribers, clientSubscribers
    ] = await Promise.all([
        getGeneralSettingsServiceClient().viewSettings({}),
        getClientsServiceClient().viewByUUID({ uuid: localStorage.getItem("client_uuid") || "" }),
        accessClient.viewInternalSubscribers({ uuid: clientstream.metadata?.uuid }),
        accessClient.viewClientSubscribers({ uuid: clientstream.metadata?.uuid }),
    ]);

    // Create the necessary map here as well
    let internalSubscribersUserUuids = Array.from(new Set(internalSubscribers.list.map(sub => {
        return sub.userUuid;
    })));

    let clientSubscribersUserUuids = Array.from(new Set(clientSubscribers.list.map(sub => {
        return sub.userUuid;
    })));

    let [usersByUuidMap, usersByUsernameMap] = await Promise.all([
        usersMapFromUUIDs(internalSubscribersUserUuids.concat(clientSubscribersUserUuids)),
        usersMapFromUsernames(Array.from(new Set(messages.map(m => {
            return m.metadata?.addedBy || ""
        }))))
    ]);

    // Render organization info & internal subscribers
    formGrid.appendChild(renderInternalSubscribers(organizationInfo, usersByUuidMap, internalSubscribers.list));
    // Render client info & client subscribers
    formGrid.appendChild(renderClientSubscribers(clientInfo, usersByUuidMap, clientSubscribers.list));

    formGrid.appendChild(renderClientStreamMessages(messages, usersByUsernameMap, clientstream.status == CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_OPEN ? false : true));

    return { formGrid, usersByUuidMap, usersByUsernameMap }
}

function renderInternalSubscribers(organizationInfo: GeneralSettings, usersByUuidMap: Map<string, User>, internalSubscribers: ClientStreamInternalSubscriber[]): HTMLDivElement {
    let div = document.createElement("div");
    div.className = "col-span-12 mt-8 ml-5";

    let title = document.createElement("h5");
    title.className = "mb-3";
    title.innerText = `Subscribers from ${toTitleCase(organizationInfo.companyName)}`;

    div.appendChild(title);

    internalSubscribers.forEach(sub => {
        let user = usersByUuidMap.get(sub.userUuid);
        if (user) {
            div.appendChild(returnUserBadge(user));
        }
    });

    return div;
}

function renderClientSubscribers(clientInfo: Client, usersByUuidMap: Map<string, User>, clientSubscribers: ClientStreamClientSubscriber[]): HTMLDivElement {
    let div = document.createElement("div");
    div.className = "col-span-12 mt-8 ml-5";

    let title = document.createElement("h5");
    title.className = "mb-3";
    title.innerText = `Subscribers from ${toTitleCase(clientInfo.name)}`;

    div.appendChild(title);

    clientSubscribers.forEach(sub => {
        let user = usersByUuidMap.get(sub.userUuid);
        if (user) {
            div.appendChild(returnUserBadge(user));
        }
    });

    return div;
}

function renderClientStreamMessages(messages: ClientStreamMessage[], usersByUsernameMap: Map<string, User>, readonly: boolean): HTMLDivElement {
    let div = document.createElement("div");
    div.className = "col-span-12 mt-8 ml-5";
    let title = document.createElement("h5");
    title.className = "mb-3";
    title.innerText = "Messages";
    div.appendChild(title);

    messages.forEach((message, index) => {
        div.appendChild(renderIndividualClientStreamMessage(message, index + 1, usersByUsernameMap, readonly));
    });
    return div;
}

function renderIndividualClientStreamMessage(message: ClientStreamMessage, index: number, usersByUsernameMap: Map<string, User>, readonly: boolean): HTMLDivElement {
    let div = document.createElement("div");
    div.classList.add("col-span-12");
    div.classList.add("mt-2");
    div.classList.add("ml-5");
    div.classList.add("chat");
    div.classList.add("chat-start");
    div.classList.add(messageDiv);
    div.setAttribute("data-internal-ref", message.internalRef);
    div.setAttribute("data-uuid", message.metadata?.uuid!);
    if (readonly) {
        div.setAttribute("data-readonly", "true");
    }

    let content = document.createElement("div");
    convertEditorJSContentToHTML(message.content).map(el => {
        let span = document.createElement("div");
        span.innerHTML = el + "<br>";
        return span;
    }).forEach(el => {
        content.appendChild(el);
    });

    div.innerHTML = `
        <div class="chat-image avatar">
            <div class="w-10 rounded-full">
            <img src="/avatar/${usersByUsernameMap.get(message.metadata?.addedBy!)?.metadata?.uuid}" alt="User Image" />
            </div>
        </div>
        <div class="chat-header w-full">
            <div>
                ${index}. 
                ${usersByUsernameMap.get(message.metadata?.addedBy!)?.name}
                <span class="text-xs opacity-70">(${message.internalRef})</span>
                <time class="text-xs opacity-50">${convertBigIntTimestampToDateTime(message.metadata?.createdAt!)}</time>
            </div>
        </div>
        <div class="chat-bubble">${content.innerHTML}</div>
        <div class="chat-footer">
            ${readonly ? "" : `
                <span class="tooltip" data-tip="Reply To This Message">
                    <i data-index="${index}" data-uuid='${message.metadata?.uuid}' class='${replyButton} cursor-pointer bx bx-reply text-2xl'></i>
                <span>
                ${message.responseToMessageUuid.length != 36 ? "" : `
                    <span class="tooltip" data-tip="Go to Parent Message">
                        <i data-index="${index}" data-parent-uuid="${message.responseToMessageUuid}" data-uuid='${message.metadata?.uuid}' class='${goToParentMessageButton} cursor-pointer bx bxs-up-arrow text-xl'></i>
                    <span>
                `}
                <span class="tooltip" data-tip="Expand Stream">
                    <i data-index="${index}" data-uuid='${message.metadata?.uuid}' class='${expandStreamButton} cursor-pointer bx bx-expand text-xl'></i>
                <span>
            `}
        </div>
    `;

    return div;
}

/**Displays a popup with editorJS configured to the textarea */
function handleAddStreamMessage(accessClient: PromiseClient<typeof ClientStreamsService>, clientStreamUuid: string, usersByUsernameMap: Map<string, User>, replyTo: {
    responseToMessageUuid: string, 
    parentMessage: ClientStreamMessage | undefined,
    index: number,
}, vaultFolderUuid: string) {
    const editorJSIdentifierClass = "_editorjs";
    
    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-3", "grid", "justify-end");
    let submitButton = document.createElement("button");
    submitButton.id = randomId();
    submitButton.innerText = "Send";
    submitButton.className = "btn btn-success btn-outline btn-sm";
    buttonContainer.appendChild(submitButton);

    let parentMessagePanel = "";
    if (replyTo.parentMessage != undefined) {
        parentMessagePanel = (renderIndividualClientStreamMessage(replyTo.parentMessage, replyTo.index, usersByUsernameMap, true)).outerHTML;
    }

    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box bg-white">
        ${replyTo.parentMessage != undefined ? parentMessagePanel : ""}
        <p class="text-center text-xl">Enter your ${replyTo.parentMessage == undefined ? "Message" : "Reply"}</p>
        <form method="dialog">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            <section class="${editorJSIdentifierClass}"></section>
            ${buttonContainer.outerHTML}
        </form>
    </div>          
    `;

    document.body.appendChild(dialog);
    dialog.showModal();
    attachEditor(editorJSIdentifierClass, { vaultFolderUuid });

    document.getElementById(submitButton.id)?.addEventListener("click", async evt => {
        evt.preventDefault();

        accessClient.addMessage({
            messageType: CLIENT_STREAM_MESSAGE_TYPE.CLIENT_STREAM_MESSAGE_TYPE_USER,
            clientStreamUuid,
            responseToMessageUuid: replyTo.responseToMessageUuid,
            content: (<HTMLDivElement>dialog.getElementsByClassName(editorJSIdentifierClass)[0]).getAttribute("data-value") || "",
        });

        dialog.close();
        location.reload();
    });
}