"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file vendor_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorInvoicesService = void 0;
const vendor_invoices_scailo_pb_js_1 = require("./vendor_invoices.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each vendor invoice
 *
 * @generated from service Scailo.VendorInvoicesService
 */
exports.VendorInvoicesService = {
    typeName: "Scailo.VendorInvoicesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.VendorInvoicesService.Create
         */
        create: {
            name: "Create",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.VendorInvoicesService.Draft
         */
        draft: {
            name: "Draft",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.VendorInvoicesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.VendorInvoicesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.VendorInvoicesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.VendorInvoicesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.VendorInvoicesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.VendorInvoicesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.VendorInvoicesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.VendorInvoicesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.VendorInvoicesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.VendorInvoicesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.VendorInvoicesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.VendorInvoicesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.VendorInvoicesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.VendorInvoicesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.AddVendorInvoiceItem
         */
        addVendorInvoiceItem: {
            name: "AddVendorInvoiceItem",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.ModifyVendorInvoiceItem
         */
        modifyVendorInvoiceItem: {
            name: "ModifyVendorInvoiceItem",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.ApproveVendorInvoiceItem
         */
        approveVendorInvoiceItem: {
            name: "ApproveVendorInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.DeleteVendorInvoiceItem
         */
        deleteVendorInvoiceItem: {
            name: "DeleteVendorInvoiceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.ReorderVendorInvoiceItems
         */
        reorderVendorInvoiceItems: {
            name: "ReorderVendorInvoiceItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Vendor Invoice Item by ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewVendorInvoiceItemByID
         */
        viewVendorInvoiceItemByID: {
            name: "ViewVendorInvoiceItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved vendor invoice items for given vendor invoice ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewApprovedVendorInvoiceItems
         */
        viewApprovedVendorInvoiceItems: {
            name: "ViewApprovedVendorInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved vendor invoice items for given vendor invoice ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewUnapprovedVendorInvoiceItems
         */
        viewUnapprovedVendorInvoiceItems: {
            name: "ViewUnapprovedVendorInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the vendor invoice item
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewVendorInvoiceItemHistory
         */
        viewVendorInvoiceItemHistory: {
            name: "ViewVendorInvoiceItemHistory",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemHistoryRequest,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved vendor invoice items for given vendor invoice ID with pagination
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewPaginatedApprovedVendorInvoiceItems
         */
        viewPaginatedApprovedVendorInvoiceItems: {
            name: "ViewPaginatedApprovedVendorInvoiceItems",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsSearchRequest,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved vendor invoice items for given vendor invoice ID with pagination
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewPaginatedUnapprovedVendorInvoiceItems
         */
        viewPaginatedUnapprovedVendorInvoiceItems: {
            name: "ViewPaginatedUnapprovedVendorInvoiceItems",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsSearchRequest,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through vendor invoice items with pagination
         *
         * @generated from rpc Scailo.VendorInvoicesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemsSearchRequest,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.VendorInvoicesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.VendorInvoicesService.UploadVendorInvoiceItems
         */
        uploadVendorInvoiceItems: {
            name: "UploadVendorInvoiceItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.VendorInvoicesService.AddVendorInvoiceReference
         */
        addVendorInvoiceReference: {
            name: "AddVendorInvoiceReference",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.VendorInvoicesService.ApproveVendorInvoiceReference
         */
        approveVendorInvoiceReference: {
            name: "ApproveVendorInvoiceReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.VendorInvoicesService.DeleteVendorInvoiceReference
         */
        deleteVendorInvoiceReference: {
            name: "DeleteVendorInvoiceReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewVendorInvoiceReferenceByID
         */
        viewVendorInvoiceReferenceByID: {
            name: "ViewVendorInvoiceReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given vendor invoice ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewVendorInvoiceReferences
         */
        viewVendorInvoiceReferences: {
            name: "ViewVendorInvoiceReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoice,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServicePaginationReq,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective vendor invoice item info for the given family ID and vendor invoice ID
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewProspectiveVendorInvoiceItem
         */
        viewProspectiveVendorInvoiceItem: {
            name: "ViewProspectiveVendorInvoiceItem",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoiceItemProspectiveInfoRequest,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View receipt (goods receipt) statistics of the vendor invoice
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewReceiptStatistics
         */
        viewReceiptStatistics: {
            name: "ViewReceiptStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoiceReceiptStatisticsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.VendorInvoicesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download vendor invoice with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.VendorInvoicesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.VendorInvoicesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceSearchAllReq,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.VendorInvoicesService.Filter
         */
        filter: {
            name: "Filter",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceFilterReq,
            O: vendor_invoices_scailo_pb_js_1.VendorInvoicesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.VendorInvoicesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.VendorInvoicesService.Count
         */
        count: {
            name: "Count",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Returns the sum of the total value of all the records that match the given criteria
         *
         * @generated from rpc Scailo.VendorInvoicesService.AccruedValue
         */
        accruedValue: {
            name: "AccruedValue",
            I: vendor_invoices_scailo_pb_js_1.VendorInvoicesServiceCountReq,
            O: base_scailo_pb_js_1.SumResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
