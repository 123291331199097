"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file replaceable_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplaceableIndentsServicePaginatedItemsResponse = exports.ReplaceableIndentItemsSearchRequest = exports.ReplaceableIndentsServiceSearchAllReq = exports.ReplaceableIndentsServiceCountReq = exports.ReplaceableIndentsServiceFilterReq = exports.ReplaceableIndentsServicePaginationResponse = exports.ReplaceableIndentsServicePaginationReq = exports.ReplaceableIndentItemProspectiveInfoRequest = exports.ReplaceableIndentItemHistoryRequest = exports.ReplaceableIndentsItemsList = exports.ReplaceableIndentsList = exports.ReplaceableIndentItem = exports.ReplaceableIndentsServiceItemUpdateRequest = exports.ReplaceableIndentsServiceItemCreateRequest = exports.ReplaceableIndent = exports.ReplaceableIndentsServiceAutofillRequest = exports.ReplaceableIndentsServiceUpdateRequest = exports.ReplaceableIndentsServiceCreateRequest = exports.REPLACEABLE_INDENT_ITEM_STATUS = exports.REPLACEABLE_INDENT_ITEM_SORT_KEY = exports.REPLACEABLE_INDENT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.REPLACEABLE_INDENT_SORT_KEY
 */
var REPLACEABLE_INDENT_SORT_KEY;
(function (REPLACEABLE_INDENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_CREATED_AT = 1;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_CREATED_AT"] = 1] = "REPLACEABLE_INDENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_MODIFIED_AT = 2;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_MODIFIED_AT"] = 2] = "REPLACEABLE_INDENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_APPROVED_ON = 3;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_APPROVED_ON"] = 3] = "REPLACEABLE_INDENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_APPROVED_BY = 4;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_APPROVED_BY"] = 4] = "REPLACEABLE_INDENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "REPLACEABLE_INDENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_COMPLETED_ON = 6;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_COMPLETED_ON"] = 6] = "REPLACEABLE_INDENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_REFERENCE_ID = 10;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_REFERENCE_ID"] = 10] = "REPLACEABLE_INDENT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "REPLACEABLE_INDENT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: REPLACEABLE_INDENT_SORT_KEY_LOCATION_ID = 12;
     */
    REPLACEABLE_INDENT_SORT_KEY[REPLACEABLE_INDENT_SORT_KEY["REPLACEABLE_INDENT_SORT_KEY_LOCATION_ID"] = 12] = "REPLACEABLE_INDENT_SORT_KEY_LOCATION_ID";
})(REPLACEABLE_INDENT_SORT_KEY || (exports.REPLACEABLE_INDENT_SORT_KEY = REPLACEABLE_INDENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(REPLACEABLE_INDENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(REPLACEABLE_INDENT_SORT_KEY, "Scailo.REPLACEABLE_INDENT_SORT_KEY", [
    { no: 0, name: "REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "REPLACEABLE_INDENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "REPLACEABLE_INDENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "REPLACEABLE_INDENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "REPLACEABLE_INDENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "REPLACEABLE_INDENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "REPLACEABLE_INDENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "REPLACEABLE_INDENT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "REPLACEABLE_INDENT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "REPLACEABLE_INDENT_SORT_KEY_LOCATION_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.REPLACEABLE_INDENT_ITEM_SORT_KEY
 */
var REPLACEABLE_INDENT_ITEM_SORT_KEY;
(function (REPLACEABLE_INDENT_ITEM_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the family ID
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch invoiced results by the internal quantity
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    REPLACEABLE_INDENT_ITEM_SORT_KEY[REPLACEABLE_INDENT_ITEM_SORT_KEY["REPLACEABLE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "REPLACEABLE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY";
})(REPLACEABLE_INDENT_ITEM_SORT_KEY || (exports.REPLACEABLE_INDENT_ITEM_SORT_KEY = REPLACEABLE_INDENT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(REPLACEABLE_INDENT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(REPLACEABLE_INDENT_ITEM_SORT_KEY, "Scailo.REPLACEABLE_INDENT_ITEM_SORT_KEY", [
    { no: 0, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "REPLACEABLE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of replaceable indent items
 *
 * @generated from enum Scailo.REPLACEABLE_INDENT_ITEM_STATUS
 */
var REPLACEABLE_INDENT_ITEM_STATUS;
(function (REPLACEABLE_INDENT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    REPLACEABLE_INDENT_ITEM_STATUS[REPLACEABLE_INDENT_ITEM_STATUS["REPLACEABLE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "REPLACEABLE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the replaceable indent items must have been approved
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_STATUS_APPROVED = 1;
     */
    REPLACEABLE_INDENT_ITEM_STATUS[REPLACEABLE_INDENT_ITEM_STATUS["REPLACEABLE_INDENT_ITEM_STATUS_APPROVED"] = 1] = "REPLACEABLE_INDENT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the replaceable indent items must be waiting for approval
     *
     * @generated from enum value: REPLACEABLE_INDENT_ITEM_STATUS_UNAPPROVED = 2;
     */
    REPLACEABLE_INDENT_ITEM_STATUS[REPLACEABLE_INDENT_ITEM_STATUS["REPLACEABLE_INDENT_ITEM_STATUS_UNAPPROVED"] = 2] = "REPLACEABLE_INDENT_ITEM_STATUS_UNAPPROVED";
})(REPLACEABLE_INDENT_ITEM_STATUS || (exports.REPLACEABLE_INDENT_ITEM_STATUS = REPLACEABLE_INDENT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(REPLACEABLE_INDENT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(REPLACEABLE_INDENT_ITEM_STATUS, "Scailo.REPLACEABLE_INDENT_ITEM_STATUS", [
    { no: 0, name: "REPLACEABLE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "REPLACEABLE_INDENT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "REPLACEABLE_INDENT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ReplaceableIndentsServiceCreateRequest
 */
class ReplaceableIndentsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the replaceable indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 12;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 13;
     */
    supervisor = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 14;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 15;
     */
    itemHash = "";
    /**
     * The description of the replaceable indent
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceCreateRequest, a, b);
    }
}
exports.ReplaceableIndentsServiceCreateRequest = ReplaceableIndentsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ReplaceableIndentsServiceUpdateRequest
 */
class ReplaceableIndentsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the replaceable indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 13;
     */
    supervisor = "";
    /**
     * The description of the replaceable indent
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceUpdateRequest, a, b);
    }
}
exports.ReplaceableIndentsServiceUpdateRequest = ReplaceableIndentsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.ReplaceableIndentsServiceAutofillRequest
 */
class ReplaceableIndentsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Denotes if all the equation dependencies (if applicable) should also be automatically added to the replaceable indent
     *
     * @generated from field: bool populate_using_equation_dependencies = 3;
     */
    populateUsingEquationDependencies = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "populate_using_equation_dependencies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceAutofillRequest, a, b);
    }
}
exports.ReplaceableIndentsServiceAutofillRequest = ReplaceableIndentsServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ReplaceableIndent
 */
class ReplaceableIndent extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this replaceable indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this replaceable indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this replaceable indent
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this replaceable indent was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the replaceable indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 12;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 13;
     */
    supervisor = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 14;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 15;
     */
    itemHash = "";
    /**
     * The description of the replaceable indent
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of associated replaceable indent items
     *
     * @generated from field: repeated Scailo.ReplaceableIndentItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndent";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "list", kind: "message", T: ReplaceableIndentItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndent().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndent().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndent().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndent, a, b);
    }
}
exports.ReplaceableIndent = ReplaceableIndent;
/**
 *
 * Describes the parameters required to add an item to a replaceable indent
 *
 * @generated from message Scailo.ReplaceableIndentsServiceItemCreateRequest
 */
class ReplaceableIndentsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the replaceable indent ID
     *
     * @generated from field: uint64 replaceable_indent_id = 10;
     */
    replaceableIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "replaceable_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceItemCreateRequest, a, b);
    }
}
exports.ReplaceableIndentsServiceItemCreateRequest = ReplaceableIndentsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a replaceable indent
 *
 * @generated from message Scailo.ReplaceableIndentsServiceItemUpdateRequest
 */
class ReplaceableIndentsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceItemUpdateRequest, a, b);
    }
}
exports.ReplaceableIndentsServiceItemUpdateRequest = ReplaceableIndentsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a replaceable indent
 *
 * @generated from message Scailo.ReplaceableIndentItem
 */
class ReplaceableIndentItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this replaceable indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the replaceable indent ID
     *
     * @generated from field: uint64 replaceable_indent_id = 10;
     */
    replaceableIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "replaceable_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentItem, a, b);
    }
}
exports.ReplaceableIndentItem = ReplaceableIndentItem;
/**
 *
 * Describes the message consisting of the list of replaceable indents
 *
 * @generated from message Scailo.ReplaceableIndentsList
 */
class ReplaceableIndentsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ReplaceableIndent list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ReplaceableIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsList, a, b);
    }
}
exports.ReplaceableIndentsList = ReplaceableIndentsList;
/**
 *
 * Describes the message consisting of the list of replaceable indent items
 *
 * @generated from message Scailo.ReplaceableIndentsItemsList
 */
class ReplaceableIndentsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ReplaceableIndentItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ReplaceableIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsItemsList, a, b);
    }
}
exports.ReplaceableIndentsItemsList = ReplaceableIndentsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ReplaceableIndentItemHistoryRequest
 */
class ReplaceableIndentItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the replaceable indent ID
     *
     * @generated from field: uint64 replaceable_indent_id = 10;
     */
    replaceableIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "replaceable_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentItemHistoryRequest, a, b);
    }
}
exports.ReplaceableIndentItemHistoryRequest = ReplaceableIndentItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective replaceable indent item
 *
 * @generated from message Scailo.ReplaceableIndentItemProspectiveInfoRequest
 */
class ReplaceableIndentItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the replaceable indent ID
     *
     * @generated from field: uint64 replaceable_indent_id = 10;
     */
    replaceableIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "replaceable_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentItemProspectiveInfoRequest, a, b);
    }
}
exports.ReplaceableIndentItemProspectiveInfoRequest = ReplaceableIndentItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ReplaceableIndentsServicePaginationReq
 */
class ReplaceableIndentsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.REPLACEABLE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = REPLACEABLE_INDENT_SORT_KEY.REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this replaceable indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(REPLACEABLE_INDENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServicePaginationReq, a, b);
    }
}
exports.ReplaceableIndentsServicePaginationReq = ReplaceableIndentsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ReplaceableIndentsServicePaginationResponse
 */
class ReplaceableIndentsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ReplaceableIndent payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ReplaceableIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServicePaginationResponse, a, b);
    }
}
exports.ReplaceableIndentsServicePaginationResponse = ReplaceableIndentsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ReplaceableIndentsServiceFilterReq
 */
class ReplaceableIndentsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.REPLACEABLE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = REPLACEABLE_INDENT_SORT_KEY.REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this replaceable indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the replaceable indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 23;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 24;
     */
    supervisor = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 25;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 26;
     */
    itemHash = "";
    /**
     * The ID of the constituent family that is part of a replaceable indent
     *
     * @generated from field: uint64 constituent_family_id = 40;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(REPLACEABLE_INDENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceFilterReq, a, b);
    }
}
exports.ReplaceableIndentsServiceFilterReq = ReplaceableIndentsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ReplaceableIndentsServiceCountReq
 */
class ReplaceableIndentsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this replaceable indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the replaceable indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 23;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 24;
     */
    supervisor = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 25;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 26;
     */
    itemHash = "";
    /**
     * The ID of the constituent family that is part of a replaceable indent
     *
     * @generated from field: uint64 constituent_family_id = 40;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceCountReq, a, b);
    }
}
exports.ReplaceableIndentsServiceCountReq = ReplaceableIndentsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ReplaceableIndentsServiceSearchAllReq
 */
class ReplaceableIndentsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.REPLACEABLE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = REPLACEABLE_INDENT_SORT_KEY.REPLACEABLE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 23;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 24;
     */
    supervisor = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 25;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 26;
     */
    itemHash = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(REPLACEABLE_INDENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServiceSearchAllReq, a, b);
    }
}
exports.ReplaceableIndentsServiceSearchAllReq = ReplaceableIndentsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.ReplaceableIndentItemsSearchRequest
 */
class ReplaceableIndentItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.REPLACEABLE_INDENT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = REPLACEABLE_INDENT_ITEM_SORT_KEY.REPLACEABLE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.REPLACEABLE_INDENT_ITEM_STATUS status = 7;
     */
    status = REPLACEABLE_INDENT_ITEM_STATUS.REPLACEABLE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the replaceable indent
     *
     * @generated from field: uint64 replaceable_indent_id = 20;
     */
    replaceableIndentId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(REPLACEABLE_INDENT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(REPLACEABLE_INDENT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "replaceable_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentItemsSearchRequest, a, b);
    }
}
exports.ReplaceableIndentItemsSearchRequest = ReplaceableIndentItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.ReplaceableIndentsServicePaginatedItemsResponse
 */
class ReplaceableIndentsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ReplaceableIndentItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReplaceableIndentsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ReplaceableIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReplaceableIndentsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReplaceableIndentsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReplaceableIndentsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReplaceableIndentsServicePaginatedItemsResponse, a, b);
    }
}
exports.ReplaceableIndentsServicePaginatedItemsResponse = ReplaceableIndentsServicePaginatedItemsResponse;
