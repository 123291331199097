"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file meetings.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeetingsServiceSetRSVPRequest = exports.MeetingsServiceImportEmployeesRequest = exports.MeetingAssociatesList = exports.MeetingAssociate = exports.MeetingsServiceAssociateCreateRequest = exports.MeetingEmployeesList = exports.MeetingEmployee = exports.MeetingsServiceEmployeeCreateRequest = exports.MeetingsServicePaginatedActionablesResponse = exports.MeetingActionablesSearchRequest = exports.MeetingActionableHistoryRequest = exports.MeetingActionablesList = exports.MeetingActionable = exports.MeetingsServiceActionableUpdateRequest = exports.MeetingsServiceActionableCreateRequest = exports.MeetingsServiceSearchAllReq = exports.MeetingsServiceCountReq = exports.MeetingsServiceFilterReq = exports.MeetingsServicePaginationResponse = exports.MeetingsServicePaginationReq = exports.MeetingsList = exports.Meeting = exports.MeetingsServiceUpdateRequest = exports.MeetingsServiceCreateRequest = exports.LogbookLogMeetingLC = exports.MEETING_ACTIONABLE_SORT_KEY = exports.MEETING_RSVP = exports.MEETING_SORT_KEY = exports.MEETING_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each meeting
 *
 * @generated from enum Scailo.MEETING_LIFECYCLE
 */
var MEETING_LIFECYCLE;
(function (MEETING_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: MEETING_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    MEETING_LIFECYCLE[MEETING_LIFECYCLE["MEETING_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "MEETING_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the meeting is open
     *
     * @generated from enum value: MEETING_LIFECYCLE_OPEN = 1;
     */
    MEETING_LIFECYCLE[MEETING_LIFECYCLE["MEETING_LIFECYCLE_OPEN"] = 1] = "MEETING_LIFECYCLE_OPEN";
    /**
     * Denotes that the meeting has completed
     *
     * @generated from enum value: MEETING_LIFECYCLE_COMPLETED = 2;
     */
    MEETING_LIFECYCLE[MEETING_LIFECYCLE["MEETING_LIFECYCLE_COMPLETED"] = 2] = "MEETING_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the meeting has been cancelled
     *
     * @generated from enum value: MEETING_LIFECYCLE_CANCELLED = 3;
     */
    MEETING_LIFECYCLE[MEETING_LIFECYCLE["MEETING_LIFECYCLE_CANCELLED"] = 3] = "MEETING_LIFECYCLE_CANCELLED";
})(MEETING_LIFECYCLE || (exports.MEETING_LIFECYCLE = MEETING_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(MEETING_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(MEETING_LIFECYCLE, "Scailo.MEETING_LIFECYCLE", [
    { no: 0, name: "MEETING_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "MEETING_LIFECYCLE_OPEN" },
    { no: 2, name: "MEETING_LIFECYCLE_COMPLETED" },
    { no: 3, name: "MEETING_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.MEETING_SORT_KEY
 */
var MEETING_SORT_KEY;
(function (MEETING_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: MEETING_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_ID_UNSPECIFIED"] = 0] = "MEETING_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: MEETING_SORT_KEY_CREATED_AT = 1;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_CREATED_AT"] = 1] = "MEETING_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: MEETING_SORT_KEY_MODIFIED_AT = 2;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_MODIFIED_AT"] = 2] = "MEETING_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: MEETING_SORT_KEY_COMPLETED_ON = 6;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_COMPLETED_ON"] = 6] = "MEETING_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: MEETING_SORT_KEY_TITLE = 10;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_TITLE"] = 10] = "MEETING_SORT_KEY_TITLE";
    /**
     * Fetch ordered results by the start time
     *
     * @generated from enum value: MEETING_SORT_KEY_STARTS_AT = 11;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_STARTS_AT"] = 11] = "MEETING_SORT_KEY_STARTS_AT";
    /**
     * Fetch ordered results by the end time
     *
     * @generated from enum value: MEETING_SORT_KEY_ENDS_AT = 12;
     */
    MEETING_SORT_KEY[MEETING_SORT_KEY["MEETING_SORT_KEY_ENDS_AT"] = 12] = "MEETING_SORT_KEY_ENDS_AT";
})(MEETING_SORT_KEY || (exports.MEETING_SORT_KEY = MEETING_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(MEETING_SORT_KEY)
protobuf_1.proto3.util.setEnumType(MEETING_SORT_KEY, "Scailo.MEETING_SORT_KEY", [
    { no: 0, name: "MEETING_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "MEETING_SORT_KEY_CREATED_AT" },
    { no: 2, name: "MEETING_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "MEETING_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "MEETING_SORT_KEY_TITLE" },
    { no: 11, name: "MEETING_SORT_KEY_STARTS_AT" },
    { no: 12, name: "MEETING_SORT_KEY_ENDS_AT" },
]);
/**
 *
 * Describes the list of available RSVP choices
 *
 * @generated from enum Scailo.MEETING_RSVP
 */
var MEETING_RSVP;
(function (MEETING_RSVP) {
    /**
     * Used only in filter requests
     *
     * @generated from enum value: MEETING_RSVP_ANY_UNSPECIFIED = 0;
     */
    MEETING_RSVP[MEETING_RSVP["MEETING_RSVP_ANY_UNSPECIFIED"] = 0] = "MEETING_RSVP_ANY_UNSPECIFIED";
    /**
     * Denotes that the user has not responded to the RSVP
     *
     * @generated from enum value: MEETING_RSVP_NONE = 1;
     */
    MEETING_RSVP[MEETING_RSVP["MEETING_RSVP_NONE"] = 1] = "MEETING_RSVP_NONE";
    /**
     * Denotes that the user has responded with a yes
     *
     * @generated from enum value: MEETING_RSVP_YES = 2;
     */
    MEETING_RSVP[MEETING_RSVP["MEETING_RSVP_YES"] = 2] = "MEETING_RSVP_YES";
    /**
     * Denotes that the user has responded with a no
     *
     * @generated from enum value: MEETING_RSVP_NO = 3;
     */
    MEETING_RSVP[MEETING_RSVP["MEETING_RSVP_NO"] = 3] = "MEETING_RSVP_NO";
    /**
     * Denotes that the user has responded with a maybe
     *
     * @generated from enum value: MEETING_RSVP_MAYBE = 4;
     */
    MEETING_RSVP[MEETING_RSVP["MEETING_RSVP_MAYBE"] = 4] = "MEETING_RSVP_MAYBE";
})(MEETING_RSVP || (exports.MEETING_RSVP = MEETING_RSVP = {}));
// Retrieve enum metadata with: proto3.getEnumType(MEETING_RSVP)
protobuf_1.proto3.util.setEnumType(MEETING_RSVP, "Scailo.MEETING_RSVP", [
    { no: 0, name: "MEETING_RSVP_ANY_UNSPECIFIED" },
    { no: 1, name: "MEETING_RSVP_NONE" },
    { no: 2, name: "MEETING_RSVP_YES" },
    { no: 3, name: "MEETING_RSVP_NO" },
    { no: 4, name: "MEETING_RSVP_MAYBE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.MEETING_ACTIONABLE_SORT_KEY
 */
var MEETING_ACTIONABLE_SORT_KEY;
(function (MEETING_ACTIONABLE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: MEETING_ACTIONABLE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    MEETING_ACTIONABLE_SORT_KEY[MEETING_ACTIONABLE_SORT_KEY["MEETING_ACTIONABLE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "MEETING_ACTIONABLE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: MEETING_ACTIONABLE_SORT_KEY_CREATED_AT = 1;
     */
    MEETING_ACTIONABLE_SORT_KEY[MEETING_ACTIONABLE_SORT_KEY["MEETING_ACTIONABLE_SORT_KEY_CREATED_AT"] = 1] = "MEETING_ACTIONABLE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: MEETING_ACTIONABLE_SORT_KEY_MODIFIED_AT = 2;
     */
    MEETING_ACTIONABLE_SORT_KEY[MEETING_ACTIONABLE_SORT_KEY["MEETING_ACTIONABLE_SORT_KEY_MODIFIED_AT"] = 2] = "MEETING_ACTIONABLE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: MEETING_ACTIONABLE_SORT_KEY_TITLE = 10;
     */
    MEETING_ACTIONABLE_SORT_KEY[MEETING_ACTIONABLE_SORT_KEY["MEETING_ACTIONABLE_SORT_KEY_TITLE"] = 10] = "MEETING_ACTIONABLE_SORT_KEY_TITLE";
    /**
     * Fetch ordered results by the activity tag ID
     *
     * @generated from enum value: MEETING_ACTIONABLE_SORT_KEY_ACTIVITY_TAG_ID = 11;
     */
    MEETING_ACTIONABLE_SORT_KEY[MEETING_ACTIONABLE_SORT_KEY["MEETING_ACTIONABLE_SORT_KEY_ACTIVITY_TAG_ID"] = 11] = "MEETING_ACTIONABLE_SORT_KEY_ACTIVITY_TAG_ID";
})(MEETING_ACTIONABLE_SORT_KEY || (exports.MEETING_ACTIONABLE_SORT_KEY = MEETING_ACTIONABLE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(MEETING_ACTIONABLE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(MEETING_ACTIONABLE_SORT_KEY, "Scailo.MEETING_ACTIONABLE_SORT_KEY", [
    { no: 0, name: "MEETING_ACTIONABLE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "MEETING_ACTIONABLE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "MEETING_ACTIONABLE_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "MEETING_ACTIONABLE_SORT_KEY_TITLE" },
    { no: 11, name: "MEETING_ACTIONABLE_SORT_KEY_ACTIVITY_TAG_ID" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being a meeting lifecycle status
 *
 * @generated from message Scailo.LogbookLogMeetingLC
 */
class LogbookLogMeetingLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE operation = 11;
     */
    operation = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogMeetingLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogMeetingLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogMeetingLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogMeetingLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogMeetingLC, a, b);
    }
}
exports.LogbookLogMeetingLC = LogbookLogMeetingLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.MeetingsServiceCreateRequest
 */
class MeetingsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the meeting
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 12;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The description of the meeting
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceCreateRequest, a, b);
    }
}
exports.MeetingsServiceCreateRequest = MeetingsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.MeetingsServiceUpdateRequest
 */
class MeetingsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the meeting
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 12;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The description of the meeting
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceUpdateRequest, a, b);
    }
}
exports.MeetingsServiceUpdateRequest = MeetingsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Meeting
 */
class Meeting extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this meeting
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this meeting
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE status = 4;
     */
    status = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this meeting
     *
     * @generated from field: repeated Scailo.LogbookLogMeetingLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this meeting was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the meeting
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 12;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The description of the meeting
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Meeting";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogMeetingLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Meeting().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Meeting().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Meeting().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Meeting, a, b);
    }
}
exports.Meeting = Meeting;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.MeetingsList
 */
class MeetingsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Meeting list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Meeting, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsList, a, b);
    }
}
exports.MeetingsList = MeetingsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.MeetingsServicePaginationReq
 */
class MeetingsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.MEETING_SORT_KEY sort_key = 5;
     */
    sortKey = MEETING_SORT_KEY.MEETING_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this meeting
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE status = 6;
     */
    status = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServicePaginationReq, a, b);
    }
}
exports.MeetingsServicePaginationReq = MeetingsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.MeetingsServicePaginationResponse
 */
class MeetingsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Meeting payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Meeting, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServicePaginationResponse, a, b);
    }
}
exports.MeetingsServicePaginationResponse = MeetingsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.MeetingsServiceFilterReq
 */
class MeetingsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.MEETING_SORT_KEY sort_key = 5;
     */
    sortKey = MEETING_SORT_KEY.MEETING_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this meeting
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE status = 10;
     */
    status = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the meeting
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 24;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 25;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "ends at"
     *
     * @generated from field: uint64 ends_at_start = 26;
     */
    endsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "ends at"
     *
     * @generated from field: uint64 ends_at_end = 27;
     */
    endsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 28;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that the given employee is a part of
     *
     * @generated from field: uint64 employee_id = 40;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that the given associate is a part of
     *
     * @generated from field: uint64 associate_id = 41;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that have the following activity tag as part of the actionables
     *
     * @generated from field: uint64 activity_tag_id = 42;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "ends_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "ends_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceFilterReq, a, b);
    }
}
exports.MeetingsServiceFilterReq = MeetingsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.MeetingsServiceCountReq
 */
class MeetingsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this meeting
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE status = 10;
     */
    status = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the meeting
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 24;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 25;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "ends at"
     *
     * @generated from field: uint64 ends_at_start = 26;
     */
    endsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "ends at"
     *
     * @generated from field: uint64 ends_at_end = 27;
     */
    endsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 28;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that the given employee is a part of
     *
     * @generated from field: uint64 employee_id = 40;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that the given associate is a part of
     *
     * @generated from field: uint64 associate_id = 41;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that have the following activity tag as part of the actionables
     *
     * @generated from field: uint64 activity_tag_id = 42;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "ends_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "ends_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceCountReq, a, b);
    }
}
exports.MeetingsServiceCountReq = MeetingsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.MeetingsServiceSearchAllReq
 */
class MeetingsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.MEETING_SORT_KEY sort_key = 5;
     */
    sortKey = MEETING_SORT_KEY.MEETING_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.MEETING_LIFECYCLE status = 10;
     */
    status = MEETING_LIFECYCLE.MEETING_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * Return all the meetings that the given employee is a part of
     *
     * @generated from field: uint64 employee_id = 40;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that the given associate is a part of
     *
     * @generated from field: uint64 associate_id = 41;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Return all the meetings that have the following activity tag as part of the actionables
     *
     * @generated from field: uint64 activity_tag_id = 42;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceSearchAllReq, a, b);
    }
}
exports.MeetingsServiceSearchAllReq = MeetingsServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add an actionable to a meeting
 *
 * @generated from message Scailo.MeetingsServiceActionableCreateRequest
 */
class MeetingsServiceActionableCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * The title of the actionable
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the actionable
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the optional activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 13;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceActionableCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceActionableCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceActionableCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceActionableCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceActionableCreateRequest, a, b);
    }
}
exports.MeetingsServiceActionableCreateRequest = MeetingsServiceActionableCreateRequest;
/**
 *
 * Describes the parameters required to update an actionable in a meeting
 *
 * @generated from message Scailo.MeetingsServiceActionableUpdateRequest
 */
class MeetingsServiceActionableUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The title of the actionable
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the actionable
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the optional activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 13;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceActionableUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceActionableUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceActionableUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceActionableUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceActionableUpdateRequest, a, b);
    }
}
exports.MeetingsServiceActionableUpdateRequest = MeetingsServiceActionableUpdateRequest;
/**
 *
 * Describes the parameters that constitute an actionable associated to a meeting
 *
 * @generated from message Scailo.MeetingActionable
 */
class MeetingActionable extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this meeting
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * The title of the actionable
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the actionable
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the optional activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 13;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingActionable";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingActionable().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingActionable().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingActionable().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingActionable, a, b);
    }
}
exports.MeetingActionable = MeetingActionable;
/**
 *
 * Describes the message consisting of the list of meeting actionables
 *
 * @generated from message Scailo.MeetingActionablesList
 */
class MeetingActionablesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.MeetingActionable list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingActionablesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: MeetingActionable, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingActionablesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingActionablesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingActionablesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingActionablesList, a, b);
    }
}
exports.MeetingActionablesList = MeetingActionablesList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.MeetingActionableHistoryRequest
 */
class MeetingActionableHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * The title of the actionable
     *
     * @generated from field: string title = 11;
     */
    title = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingActionableHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingActionableHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingActionableHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingActionableHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingActionableHistoryRequest, a, b);
    }
}
exports.MeetingActionableHistoryRequest = MeetingActionableHistoryRequest;
/**
 *
 * Describes the request payload to retrieve actionables.
 *
 * @generated from message Scailo.MeetingActionablesSearchRequest
 */
class MeetingActionablesSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.MEETING_ACTIONABLE_SORT_KEY sort_key = 5;
     */
    sortKey = MEETING_ACTIONABLE_SORT_KEY.MEETING_ACTIONABLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The ID of the meeting
     *
     * @generated from field: uint64 meeting_id = 20;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 23;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingActionablesSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_ACTIONABLE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingActionablesSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingActionablesSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingActionablesSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingActionablesSearchRequest, a, b);
    }
}
exports.MeetingActionablesSearchRequest = MeetingActionablesSearchRequest;
/**
 *
 * Describes the response to a pagination actionables request
 *
 * @generated from message Scailo.MeetingsServicePaginatedActionablesResponse
 */
class MeetingsServicePaginatedActionablesResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.MeetingActionable payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServicePaginatedActionablesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: MeetingActionable, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServicePaginatedActionablesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServicePaginatedActionablesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServicePaginatedActionablesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServicePaginatedActionablesResponse, a, b);
    }
}
exports.MeetingsServicePaginatedActionablesResponse = MeetingsServicePaginatedActionablesResponse;
/**
 *
 * Describes the parameters necessary to create a meeting employee
 *
 * @generated from message Scailo.MeetingsServiceEmployeeCreateRequest
 */
class MeetingsServiceEmployeeCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the person's RSVP
     *
     * @generated from field: Scailo.MEETING_RSVP rsvp = 12;
     */
    rsvp = MEETING_RSVP.MEETING_RSVP_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceEmployeeCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rsvp", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_RSVP) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceEmployeeCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceEmployeeCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceEmployeeCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceEmployeeCreateRequest, a, b);
    }
}
exports.MeetingsServiceEmployeeCreateRequest = MeetingsServiceEmployeeCreateRequest;
/**
 *
 * Describes the parameters that constitute a meeting employee
 *
 * @generated from message Scailo.MeetingEmployee
 */
class MeetingEmployee extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this meeting
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the person's RSVP
     *
     * @generated from field: Scailo.MEETING_RSVP rsvp = 12;
     */
    rsvp = MEETING_RSVP.MEETING_RSVP_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingEmployee";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rsvp", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_RSVP) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingEmployee().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingEmployee().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingEmployee().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingEmployee, a, b);
    }
}
exports.MeetingEmployee = MeetingEmployee;
/**
 *
 * Describes the message consisting of the list of meeting employees
 *
 * @generated from message Scailo.MeetingEmployeesList
 */
class MeetingEmployeesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.MeetingEmployee list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingEmployeesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: MeetingEmployee, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingEmployeesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingEmployeesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingEmployeesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingEmployeesList, a, b);
    }
}
exports.MeetingEmployeesList = MeetingEmployeesList;
/**
 *
 * Describes the parameters necessary to create a meeting associate
 *
 * @generated from message Scailo.MeetingsServiceAssociateCreateRequest
 */
class MeetingsServiceAssociateCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the person's RSVP
     *
     * @generated from field: Scailo.MEETING_RSVP rsvp = 12;
     */
    rsvp = MEETING_RSVP.MEETING_RSVP_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceAssociateCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rsvp", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_RSVP) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceAssociateCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceAssociateCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceAssociateCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceAssociateCreateRequest, a, b);
    }
}
exports.MeetingsServiceAssociateCreateRequest = MeetingsServiceAssociateCreateRequest;
/**
 *
 * Describes the parameters that constitute a meeting associate
 *
 * @generated from message Scailo.MeetingAssociate
 */
class MeetingAssociate extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this meeting
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the meeting ID
     *
     * @generated from field: uint64 meeting_id = 10;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the person's RSVP
     *
     * @generated from field: Scailo.MEETING_RSVP rsvp = 12;
     */
    rsvp = MEETING_RSVP.MEETING_RSVP_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingAssociate";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "rsvp", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_RSVP) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingAssociate().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingAssociate().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingAssociate().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingAssociate, a, b);
    }
}
exports.MeetingAssociate = MeetingAssociate;
/**
 *
 * Describes the message consisting of the list of meeting associates
 *
 * @generated from message Scailo.MeetingAssociatesList
 */
class MeetingAssociatesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.MeetingAssociate list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingAssociatesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: MeetingAssociate, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingAssociatesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingAssociatesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingAssociatesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingAssociatesList, a, b);
    }
}
exports.MeetingAssociatesList = MeetingAssociatesList;
/**
 *
 * Describes the data model to handle importing of employees from the given identifier representing a team or a department
 *
 * @generated from message Scailo.MeetingsServiceImportEmployeesRequest
 */
class MeetingsServiceImportEmployeesRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the meeting
     *
     * @generated from field: uint64 meeting_id = 2;
     */
    meetingId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team or the department from which employees need to be added to the meeting
     *
     * @generated from field: uint64 resource_id = 3;
     */
    resourceId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing employees in the meeting need to be deleted before adding from the source record
     *
     * @generated from field: bool delete_existing = 4;
     */
    deleteExisting = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceImportEmployeesRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "resource_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceImportEmployeesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceImportEmployeesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceImportEmployeesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceImportEmployeesRequest, a, b);
    }
}
exports.MeetingsServiceImportEmployeesRequest = MeetingsServiceImportEmployeesRequest;
/**
 *
 * Describes the data model that sets the RSVP of either an associate or an employee
 *
 * @generated from message Scailo.MeetingsServiceSetRSVPRequest
 */
class MeetingsServiceSetRSVPRequest extends protobuf_1.Message {
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the resource
     *
     * @generated from field: string uuid = 10;
     */
    uuid = "";
    /**
     * Stores the person's RSVP
     *
     * @generated from field: Scailo.MEETING_RSVP rsvp = 11;
     */
    rsvp = MEETING_RSVP.MEETING_RSVP_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MeetingsServiceSetRSVPRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "rsvp", kind: "enum", T: protobuf_1.proto3.getEnumType(MEETING_RSVP) },
    ]);
    static fromBinary(bytes, options) {
        return new MeetingsServiceSetRSVPRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MeetingsServiceSetRSVPRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MeetingsServiceSetRSVPRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MeetingsServiceSetRSVPRequest, a, b);
    }
}
exports.MeetingsServiceSetRSVPRequest = MeetingsServiceSetRSVPRequest;
