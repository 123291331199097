"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vault_files.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultFileUnzipRequest = exports.VaultFileVersionChunk = exports.VaultFileAddChunkRequest = exports.VaultFileVersionsList = exports.VaultFileVersion = exports.VaultFilesList = exports.VaultFile = exports.VaultFileMoveFileRequest = exports.VaultFileRenameFileRequest = exports.VaultFileInitiateFileResponse = exports.VaultFileInitiateFileRequest = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const vault_commons_scailo_pb_js_1 = require("./vault_commons.scailo_pb.js");
/**
 *
 * Describes the parameters necessary to initiate a vault file
 *
 * @generated from message Scailo.VaultFileInitiateFileRequest
 */
class VaultFileInitiateFileRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the file
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The file type
     *
     * @generated from field: string type = 11;
     */
    type = "";
    /**
     * The UUID of the folder that the file is present in
     *
     * @generated from field: string folder_uuid = 13;
     */
    folderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileInitiateFileRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileInitiateFileRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileInitiateFileRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileInitiateFileRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileInitiateFileRequest, a, b);
    }
}
exports.VaultFileInitiateFileRequest = VaultFileInitiateFileRequest;
/**
 *
 * Describes the payload in the response to a file initiate request
 *
 * @generated from message Scailo.VaultFileInitiateFileResponse
 */
class VaultFileInitiateFileResponse extends protobuf_1.Message {
    /**
     * The UUID of the file
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The max size of the chunk (in bytes) for uploading a file
     *
     * @generated from field: uint64 chunk_size = 2;
     */
    chunkSize = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileInitiateFileResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "chunk_size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileInitiateFileResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileInitiateFileResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileInitiateFileResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileInitiateFileResponse, a, b);
    }
}
exports.VaultFileInitiateFileResponse = VaultFileInitiateFileResponse;
/**
 *
 * Describes the parameters necessary to rename a vault file
 *
 * @generated from message Scailo.VaultFileRenameFileRequest
 */
class VaultFileRenameFileRequest extends protobuf_1.Message {
    /**
     * The UUID of the file
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The new name of the file
     *
     * @generated from field: string name = 10;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileRenameFileRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileRenameFileRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileRenameFileRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileRenameFileRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileRenameFileRequest, a, b);
    }
}
exports.VaultFileRenameFileRequest = VaultFileRenameFileRequest;
/**
 *
 * Describes the parameters necessary to move a vault file
 *
 * @generated from message Scailo.VaultFileMoveFileRequest
 */
class VaultFileMoveFileRequest extends protobuf_1.Message {
    /**
     * The UUID of the file
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The UUID of the destination folder that the file needs to be moved into
     *
     * @generated from field: string destination_folder_uuid = 13;
     */
    destinationFolderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileMoveFileRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "destination_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileMoveFileRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileMoveFileRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileMoveFileRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileMoveFileRequest, a, b);
    }
}
exports.VaultFileMoveFileRequest = VaultFileMoveFileRequest;
/**
 *
 * Describes the parameters of a vault file
 *
 * @generated from message Scailo.VaultFile
 */
class VaultFile extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The timestamp of when the file upload was completed
     *
     * @generated from field: uint64 completed_at = 6;
     */
    completedAt = protobuf_1.protoInt64.zero;
    /**
     * The name of the file
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The file type
     *
     * @generated from field: string type = 11;
     */
    type = "";
    /**
     * Stores if the file has been made persistent
     *
     * @generated from field: bool is_persistent = 12;
     */
    isPersistent = false;
    /**
     * The ID of the folder that the file lives in
     *
     * @generated from field: uint64 folder_id = 13;
     */
    folderId = protobuf_1.protoInt64.zero;
    /**
     * The path of the file
     *
     * @generated from field: string path_tree = 14;
     */
    pathTree = "";
    /**
     * The size of the file in bytes
     *
     * @generated from field: uint64 size = 20;
     */
    size = protobuf_1.protoInt64.zero;
    /**
     * The list of permissions
     *
     * @generated from field: repeated Scailo.VaultPermission permissions = 30;
     */
    permissions = [];
    /**
     * The UUID of the folder that the file is present in
     *
     * @generated from field: string folder_uuid = 50;
     */
    folderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFile";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 6, name: "completed_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "is_persistent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 13, name: "folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "path_tree", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "permissions", kind: "message", T: vault_commons_scailo_pb_js_1.VaultPermission, repeated: true },
        { no: 50, name: "folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFile().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFile().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFile().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFile, a, b);
    }
}
exports.VaultFile = VaultFile;
/**
 *
 * Describes the message consisting of the list of vault files
 *
 * @generated from message Scailo.VaultFilesList
 */
class VaultFilesList extends protobuf_1.Message {
    /**
     * List of files
     *
     * @generated from field: repeated Scailo.VaultFile list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFilesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VaultFile, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFilesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFilesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFilesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFilesList, a, b);
    }
}
exports.VaultFilesList = VaultFilesList;
/**
 *
 * Describes the parameres of a version of each file
 *
 * @generated from message Scailo.VaultFileVersion
 */
class VaultFileVersion extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The timestamp of when the file version upload was completed
     *
     * @generated from field: uint64 completed_at = 6;
     */
    completedAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the file that the version belongs to
     *
     * @generated from field: uint64 vault_file_id = 10;
     */
    vaultFileId = protobuf_1.protoInt64.zero;
    /**
     * The name of the file
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The file type
     *
     * @generated from field: string type = 12;
     */
    type = "";
    /**
     * The size of the file version in bytes
     *
     * @generated from field: uint64 size = 20;
     */
    size = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileVersion";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 6, name: "completed_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "vault_file_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileVersion().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileVersion().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileVersion().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileVersion, a, b);
    }
}
exports.VaultFileVersion = VaultFileVersion;
/**
 *
 * Describes the message consisting of the list of vault file versions
 *
 * @generated from message Scailo.VaultFileVersionsList
 */
class VaultFileVersionsList extends protobuf_1.Message {
    /**
     * List of versions of the file
     *
     * @generated from field: repeated Scailo.VaultFileVersion list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileVersionsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VaultFileVersion, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileVersionsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileVersionsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileVersionsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileVersionsList, a, b);
    }
}
exports.VaultFileVersionsList = VaultFileVersionsList;
/**
 *
 * Describes the parameters necessary to add a chunk to a file
 *
 * @generated from message Scailo.VaultFileAddChunkRequest
 */
class VaultFileAddChunkRequest extends protobuf_1.Message {
    /**
     * The UUID of the file
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * Stores the sequence ID of this chunk
     *
     * @generated from field: uint64 sequence_id = 11;
     */
    sequenceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the content of the chunk
     *
     * @generated from field: bytes chunk = 12;
     */
    chunk = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileAddChunkRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "sequence_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "chunk", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileAddChunkRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileAddChunkRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileAddChunkRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileAddChunkRequest, a, b);
    }
}
exports.VaultFileAddChunkRequest = VaultFileAddChunkRequest;
/**
 *
 * Describes the parameters of each chunk of a file version
 *
 * @generated from message Scailo.VaultFileVersionChunk
 */
class VaultFileVersionChunk extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the ID of the file version to which this chunk belongs
     *
     * @generated from field: uint64 vault_file_version_id = 10;
     */
    vaultFileVersionId = protobuf_1.protoInt64.zero;
    /**
     * Stores the sequence ID of this chunk
     *
     * @generated from field: uint64 sequence_id = 11;
     */
    sequenceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the content of the chunk
     *
     * @generated from field: bytes chunk = 12;
     */
    chunk = new Uint8Array(0);
    /**
     * Stores the checksum of the chunk
     *
     * @generated from field: string checksum = 13;
     */
    checksum = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileVersionChunk";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "vault_file_version_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "sequence_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "chunk", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 13, name: "checksum", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileVersionChunk().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileVersionChunk().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileVersionChunk().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileVersionChunk, a, b);
    }
}
exports.VaultFileVersionChunk = VaultFileVersionChunk;
/**
 *
 * Stores the request to unzip a zipped file
 *
 * @generated from message Scailo.VaultFileUnzipRequest
 */
class VaultFileUnzipRequest extends protobuf_1.Message {
    /**
     * The UUID of the file that needs to be unzipped
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * Stores if the file needs to be deleted after unzipping
     *
     * @generated from field: bool delete_after_unzip = 2;
     */
    deleteAfterUnzip = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFileUnzipRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "delete_after_unzip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFileUnzipRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFileUnzipRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFileUnzipRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFileUnzipRequest, a, b);
    }
}
exports.VaultFileUnzipRequest = VaultFileUnzipRequest;
