import { goodsDispatchFiltersMenu, goodsDispatchInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleGoodsDispatchFilters } from "./goodsdispatches.filters";
import { handleGoodsDispatchInsights } from "./goodsdispatches.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(goodsDispatchFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsDispatchFiltersMenu)) {
            handleGoodsDispatchFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(goodsDispatchInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsDispatchInsightsMenu)) {
            handleGoodsDispatchInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}