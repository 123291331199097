import { match, MatchFunction } from "path-to-regexp";

/**Stores the relationship between the path and the provided callback */
interface relation {
    path: MatchFunction<object> | null
    callback: (ctx: context) => void
}

/**Class that performs the routing */
export class Router {
    relationships: relation[]
    defaultRelation: relation

    constructor() {
        this.relationships = [];
        this.defaultRelation = <relation>{};
    }

    /**Adds a path along with the callback to the router */
    add(path: string, callback: (ctx: context) => void) {
        let r = <relation>{
            path: match(path),
            callback: callback
        };
        this.relationships.push(r);
    }

    setDefault(callback: (ctx: context) => void) {
        this.defaultRelation = {
            path: null,
            callback: callback
        }
    }

    /**Navigates the user to the provided href */
    private _navigate(href: string, searchParams: string) {
        if (!href.startsWith("tel")) {
            history.pushState({ href: href, searchParams: searchParams }, "", href + searchParams);
            this.traverseRelationships(href, searchParams);
        }
    }

    private traverseRelationships(href: string, searchParams: string) {
        for (let i = 0; i < this.relationships.length; i++) {
            let t = this.relationships[i];
            let match = t.path!(href);
            if (match) {
                let c = <context>{
                    querystring: searchParams,
                    pathname: href,
                    path: href + searchParams,
                    params: match.params
                }
                t.callback(c);
                return
            }
        }
        this.defaultRelation.callback({
            querystring: location.search,
            pathname: location.pathname,
            path: location.pathname + location.search,
            params: null
        });
    }

    _handleAnchorTag(t: HTMLAnchorElement, e: MouseEvent) {
        if (t.getAttribute("download") != null || t.getAttribute("target") != null || t.getAttribute("href") == null) {
            return true
        } else {
            // Prevent default only in the case where the default functionality of the link is being overridden
            e.preventDefault();
            let tempHref = t.href.replace(location.origin, "");
            let split = tempHref.split("?");
            let href = "";
            let searchParams = "";
            if (split.length == 1) {
                href = split[0];
            } else {
                href = split[0];
                searchParams = "?" + split[1];
            }
            this._navigate(href, searchParams);
        }
    }

    /**Starts the router */
    start() {
        let localThis = this;
        document.addEventListener("click", e => {
            if (e.ctrlKey || e.metaKey) {
                // If control key or any other modifier key has been clicked, do not handle it wih this library
                return true
            }
            let el = <HTMLElement>e.target;
            if (el.nodeName.toLowerCase() == "a") {
                this._handleAnchorTag(<HTMLAnchorElement>el, e);
            } else if (el.nodeName.toLowerCase() == "button") {
                return true
            } else {
                let parentAnchor: HTMLAnchorElement | null = null;
                let parentEl = el.parentElement;
                if (parentEl == null) {
                    return true
                }
                while (parentEl != null) {
                    if (parentEl.nodeName.toLowerCase() == "body") {
                        break
                    }
                    if (parentEl.nodeName.toLowerCase() == "a") {
                        parentAnchor = <HTMLAnchorElement>parentEl;
                        break
                    }
                    parentEl = parentEl.parentElement;
                }
                if (parentAnchor == null) {
                    return true
                }
                // Handle click of the parent element here
                this._handleAnchorTag(parentAnchor, e);
            }
        });

        window.addEventListener("popstate", function (e) {
            e.preventDefault();
            localThis.traverseRelationships(location.pathname, location.search)
        });

        /**Create the global function to route to a certain URL */
        (<any>window).routeTo = function (url: string) {
            let t = new URL(location.origin + url);
            localThis._navigate(t.pathname, t.search);
        }

        this._navigate(location.pathname, location.search);
    }
}

/**Stores the router parameters */
export interface context {
    querystring: string
    /**Is the object that consists of the matched parameters */
    params: any
    /**The pathname void of query string "/login" */
    pathname: string
    /**Pathname and query string "/login?foo=bar" */
    path: string
}