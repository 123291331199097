"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file sales_quotations.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesQuotationsService = void 0;
const sales_quotations_scailo_pb_js_1 = require("./sales_quotations.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each sales quotation
 *
 * @generated from service Scailo.SalesQuotationsService
 */
exports.SalesQuotationsService = {
    typeName: "Scailo.SalesQuotationsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalesQuotationsService.Create
         */
        create: {
            name: "Create",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalesQuotationsService.Draft
         */
        draft: {
            name: "Draft",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalesQuotationsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalesQuotationsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalesQuotationsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalesQuotationsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalesQuotationsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalesQuotationsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalesQuotationsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalesQuotationsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalesQuotationsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalesQuotationsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalesQuotationsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalesQuotationsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalesQuotationsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalesQuotationsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the sales quotation and send for revision
         *
         * @generated from rpc Scailo.SalesQuotationsService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.AddSalesQuotationItem
         */
        addSalesQuotationItem: {
            name: "AddSalesQuotationItem",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.ModifySalesQuotationItem
         */
        modifySalesQuotationItem: {
            name: "ModifySalesQuotationItem",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.ApproveSalesQuotationItem
         */
        approveSalesQuotationItem: {
            name: "ApproveSalesQuotationItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.DeleteSalesQuotationItem
         */
        deleteSalesQuotationItem: {
            name: "DeleteSalesQuotationItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.ReorderSalesQuotationItems
         */
        reorderSalesQuotationItems: {
            name: "ReorderSalesQuotationItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Quotation Item by ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationItemByID
         */
        viewSalesQuotationItemByID: {
            name: "ViewSalesQuotationItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Quotation Item's price after factoring in the discount
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationItemPrice
         */
        viewSalesQuotationItemPrice: {
            name: "ViewSalesQuotationItemPrice",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.PriceResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales quotation items for given sales quotation ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewApprovedSalesQuotationItems
         */
        viewApprovedSalesQuotationItems: {
            name: "ViewApprovedSalesQuotationItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales quotation items for given sales quotation ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewUnapprovedSalesQuotationItems
         */
        viewUnapprovedSalesQuotationItems: {
            name: "ViewUnapprovedSalesQuotationItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the sales quotation item
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationItemHistory
         */
        viewSalesQuotationItemHistory: {
            name: "ViewSalesQuotationItemHistory",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemHistoryRequest,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales quotation items for given sales quotation ID with pagination
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewPaginatedApprovedSalesQuotationItems
         */
        viewPaginatedApprovedSalesQuotationItems: {
            name: "ViewPaginatedApprovedSalesQuotationItems",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemsSearchRequest,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales quotation items for given sales quotation ID with pagination
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewPaginatedUnapprovedSalesQuotationItems
         */
        viewPaginatedUnapprovedSalesQuotationItems: {
            name: "ViewPaginatedUnapprovedSalesQuotationItems",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemsSearchRequest,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales quotation items with pagination
         *
         * @generated from rpc Scailo.SalesQuotationsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemsSearchRequest,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.SalesQuotationsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.SalesQuotationsService.UploadSalesQuotationItems
         */
        uploadSalesQuotationItems: {
            name: "UploadSalesQuotationItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.SalesQuotationsService.AddSalesQuotationContact
         */
        addSalesQuotationContact: {
            name: "AddSalesQuotationContact",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceContactCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.SalesQuotationsService.ApproveSalesQuotationContact
         */
        approveSalesQuotationContact: {
            name: "ApproveSalesQuotationContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.SalesQuotationsService.DeleteSalesQuotationContact
         */
        deleteSalesQuotationContact: {
            name: "DeleteSalesQuotationContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationContactByID
         */
        viewSalesQuotationContactByID: {
            name: "ViewSalesQuotationContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given sales quotation UUID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationContacts
         */
        viewSalesQuotationContacts: {
            name: "ViewSalesQuotationContacts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.SalesQuotationsService.AddSalesQuotationReference
         */
        addSalesQuotationReference: {
            name: "AddSalesQuotationReference",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.SalesQuotationsService.ApproveSalesQuotationReference
         */
        approveSalesQuotationReference: {
            name: "ApproveSalesQuotationReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.SalesQuotationsService.DeleteSalesQuotationReference
         */
        deleteSalesQuotationReference: {
            name: "DeleteSalesQuotationReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationReferenceByID
         */
        viewSalesQuotationReferenceByID: {
            name: "ViewSalesQuotationReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given sales quotation ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewSalesQuotationReferences
         */
        viewSalesQuotationReferences: {
            name: "ViewSalesQuotationReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotation,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_quotations_scailo_pb_js_1.SalesQuotation,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components of the record
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_quotations_scailo_pb_js_1.SalesQuotation,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServicePaginationReq,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given sales quotation
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective sales quotation item info for the given family ID and sales quotation ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewProspectiveSalesQuotationItem
         */
        viewProspectiveSalesQuotationItem: {
            name: "ViewProspectiveSalesQuotationItem",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationItemProspectiveInfoRequest,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * View all sales orders IDs that are associated with the given sales quotation ID
         *
         * @generated from rpc Scailo.SalesQuotationsService.ViewAssociatedSalesOrdersIDs
         */
        viewAssociatedSalesOrdersIDs: {
            name: "ViewAssociatedSalesOrdersIDs",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download sales quotation with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.SalesQuotationsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalesQuotationsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceSearchAllReq,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalesQuotationsService.Filter
         */
        filter: {
            name: "Filter",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceFilterReq,
            O: sales_quotations_scailo_pb_js_1.SalesQuotationsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalesQuotationsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalesQuotationsService.Count
         */
        count: {
            name: "Count",
            I: sales_quotations_scailo_pb_js_1.SalesQuotationsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
