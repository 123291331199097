import { protoInt64 } from "@bufbuild/protobuf";
import { Associate, Family, GoodsDispatch, InwardJob, InwardJobFreeIssueMaterial, InwardJobFreeIssueMaterialReturn, SalesEnquiry, SalesOrder, SaleReceipt, SalesReturn, QCSample, SalesInvoice, CreditNote, WorkOrder, ProductionPlan, ClientStream, getClientForSalesOrdersService, getClientForCurrenciesService, getClientForGoodsDispatchesService, getClientForSalesReturnsService, getClientForInwardJobsService } from "@kernelminds/scailo-sdk";
import { convertAndColorizeBigint, convertAndColorizeBigintInverted, convertBigIntTimestampToDate, convertBigIntTimestampToDateTime, convertCentsToMoney, downloadButtonClass, internationalizeMoney, renderTableWithCSVHeadersAndRows, toTitleCase } from "./utilities";
import * as fetches from "./fetches";
import { colorizeCompletionPercentage, colorizeQCSampleLifecycleStatus, colorizeStandardLifecycleStatus, getLinkForClientStream, getLinkForCreditNote, getLinkForGoodsDispatch, getLinkForInwardJob, getLinkForInwardJobFreeIssueMaterial, getLinkForInwardJobFreeIssueMaterialReturn, getLinkForProductionPlan, getLinkForSalesEnquiry, getLinkForSalesInvoice, getLinkForSalesOrder, getLinkForSalesReturn, getLinkForWorkOrder } from "./ui";
import { getTransport } from "./clients";

/**Denotes a success icon */
export const successIcon = `<i class="text-green-700 text-2xl bx bx-check-double"></i>`;
/**Denotes a failure icon */
export const failureIcon = `<i class="text-red-500 text-2xl bx bx-x"></i>`;

/**Returns the table for sales orders */
export async function viewSalesOrders(salesorders: SalesOrder[], opts: {
    inventoryMatchClass: string
}) {
    let tableHeader: string[] = [];

    tableHeader = ["Sales Order Id", "Line Items", "Price", "Created/Approved", "Inventory Progress", "Billing Progress", "Status", "View", "Inventory Match", "Download"];
    const transport = getTransport();
    const salesorderClient = getClientForSalesOrdersService(transport);
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = salesorders.map(async function (salesorder) {
        let displayName = salesorder.approvalMetadata!.approvedOn > 0 ? salesorder.finalRefNumber : salesorder.referenceId

        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForSalesOrder(salesorder)}"><i class="bx bx-door-open"></i></a>`;

        let [currency, inventoryStats, billingStats] = await Promise.all([
            fetches.currency(salesorder.currencyId, currenciesClient),
            fetches.salesorderInventoryStatistics(salesorder.metadata!.uuid, salesorderClient),
            fetches.salesorderBillingStatistics(salesorder.metadata!.uuid, salesorderClient),
        ]);

        if (inventoryStats.ordered == protoInt64.zero) {
            inventoryStats.ordered = protoInt64.parse(1);
        }
        if (billingStats.ordered == protoInt64.zero) {
            billingStats.ordered = protoInt64.parse(1);
        }

        const inventoryStatsSpan = colorizeCompletionPercentage(protoInt64.parse(Math.round(parseFloat(String(inventoryStats.dispatched - inventoryStats.returned)) / parseFloat(String(inventoryStats.ordered)) * 100 * 100)));
        const billingStatsSpan = colorizeCompletionPercentage(protoInt64.parse(Math.round(parseFloat(String(billingStats.invoiced - billingStats.credited)) / parseFloat(String(billingStats.ordered)) * 100 * 100)));

        return [
            displayName,
            (salesorder.list.length).toString(),
            `${currency.symbol} ${internationalizeMoney(salesorder.totalValue)}`,
            `${convertBigIntTimestampToDate(salesorder.metadata!.createdAt)}/${convertBigIntTimestampToDate(salesorder.approvalMetadata!.approvedOn)}`,
            `${inventoryStatsSpan.outerHTML}`, `${billingStatsSpan.outerHTML}`,
            colorizeStandardLifecycleStatus(salesorder.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${opts.inventoryMatchClass}" data-name="${displayName}" data-uuid="${salesorder.metadata?.uuid}"><i class="bx bx-calculator"></i></a>`,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-name="${displayName}" data-uuid="${salesorder.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Sales Orders", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for goods dispatches */
export async function viewGoodsDispatches(goodsdispatches: GoodsDispatch[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Goods Dispatch ID", "Line Items", "Invoiced", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForGoodsDispatchesService(transport);

    let tableRowsPromises: Promise<string[]>[] = goodsdispatches.map(async function (goodsdispatch) {
        let displayName = goodsdispatch.approvalMetadata!.approvedOn > 0 ? goodsdispatch.finalRefNumber : goodsdispatch.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForGoodsDispatch(goodsdispatch)}"><i class="bx bx-door-open"></i></a>`;

        let invoiced = await fetches.goodsdispatchBillingStatus(goodsdispatch.metadata!.id, client);
        return [
            displayName,
            (goodsdispatch.list.length).toString(),
            invoiced ? successIcon : failureIcon,
            `${convertBigIntTimestampToDate(goodsdispatch.metadata!.createdAt)}/${convertBigIntTimestampToDate(goodsdispatch.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(goodsdispatch.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${goodsdispatch.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Goods Dispatches", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for sales invoices */
export async function viewSalesInvoices(salesinvoices: SalesInvoice[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Sales Invoice ID", "Line Items", "Value", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = salesinvoices.map(async function (salesinvoice) {
        let displayName = salesinvoice.approvalMetadata!.approvedOn > 0 ? salesinvoice.finalRefNumber : salesinvoice.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForSalesInvoice(salesinvoice)}"><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(salesinvoice.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            (salesinvoice.list.length).toString(),
            `${currency.symbol} ${internationalizeMoney(salesinvoice.totalValue)}`,

            `${convertBigIntTimestampToDate(salesinvoice.metadata!.createdAt)}/${convertBigIntTimestampToDate(salesinvoice.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(salesinvoice.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${salesinvoice.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Sales Invoices", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for work orders */
export async function viewWorkOrders(workorders: WorkOrder[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Work Order ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = workorders.map(async function (workorder) {
        let displayName = workorder.approvalMetadata!.approvedOn > 0 ? workorder.finalRefNumber : workorder.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForWorkOrder(workorder)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (workorder.list.length).toString(),            

            `${convertBigIntTimestampToDate(workorder.metadata!.createdAt)}/${convertBigIntTimestampToDate(workorder.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(workorder.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${workorder.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Work Orders", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for credit notes */
export async function viewCreditNotes(creditnotes: CreditNote[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Credit Note ID", "Line Items", "Value", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = creditnotes.map(async function (creditnote) {
        let displayName = creditnote.approvalMetadata!.approvedOn > 0 ? creditnote.finalRefNumber : creditnote.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForCreditNote(creditnote)}"><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(creditnote.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            (creditnote.list.length).toString(),
            `${currency.symbol} ${internationalizeMoney(creditnote.totalValue)}`,

            `${convertBigIntTimestampToDate(creditnote.metadata!.createdAt)}/${convertBigIntTimestampToDate(creditnote.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(creditnote.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${creditnote.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Credit Notes", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for sales returns */
export async function viewSalesReturns(salesreturns: SalesReturn[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Sales Return ID", "Line Items", "Invoiced", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForSalesReturnsService(transport);

    let tableRowsPromises: Promise<string[]>[] = salesreturns.map(async function (salesreturn) {
        let displayName = salesreturn.approvalMetadata!.approvedOn > 0 ? salesreturn.finalRefNumber : salesreturn.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForSalesReturn(salesreturn)}"><i class="bx bx-door-open"></i></a>`;

        let invoiced = await fetches.salesreturnBillingStatus(salesreturn.metadata!.id, client);
        return [
            displayName,
            (salesreturn.list.length).toString(),
            invoiced ? successIcon : failureIcon,
            `${convertBigIntTimestampToDate(salesreturn.metadata!.createdAt)}/${convertBigIntTimestampToDate(salesreturn.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(salesreturn.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${salesreturn.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Sales Returns", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for sales receipts */
export async function viewSalesReceipts(salesreceipts: SaleReceipt[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Payment ID", "Trans Type", "Amount", "Payment Date", "Created/Approved", "Status", "View"];
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = salesreceipts.map(async function (salesreceipt) {
        let displayName = salesreceipt.approvalMetadata!.approvedOn > 0 ? salesreceipt.finalRefNumber : salesreceipt.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(salesreceipt.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            toTitleCase(salesreceipt.transactionType),
            `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(salesreceipt.amountNet)))}`,
            convertBigIntTimestampToDate(salesreceipt.paymentTimestamp),
            `${convertBigIntTimestampToDate(salesreceipt.metadata!.createdAt)}/${convertBigIntTimestampToDate(salesreceipt.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(salesreceipt.status).outerHTML,
            individualLink
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Payments", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for inward jobs */
export async function viewInwardJobs(inwardjobs: InwardJob[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Inward Job ID", "Line Items (Inward/Outward)", "Ordered/Completable", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForInwardJobsService(transport);

    let tableRowsPromises: Promise<string[]>[] = inwardjobs.map(async function (inwardjob) {
        let displayName = inwardjob.approvalMetadata!.approvedOn > 0 ? inwardjob.finalRefNumber : inwardjob.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForInwardJob(inwardjob)}"><i class="bx bx-door-open"></i></a>`;

        let [ordered, completable] = await Promise.all([
            fetches.inwardjobOrderedStatus(inwardjob.metadata!.id, client),
            fetches.inwardjobCompletableStatus(inwardjob.metadata!.id, client),
        ]);

        return [
            displayName,
            (inwardjob.inwardItemsList.length).toString() + '/' + (inwardjob.outwardItemsList.length).toString(),
            
            `${ordered ? successIcon : failureIcon}/${completable ? successIcon : failureIcon}`,

            `${convertBigIntTimestampToDate(inwardjob.metadata!.createdAt)}/${convertBigIntTimestampToDate(inwardjob.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(inwardjob.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${inwardjob.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Inward Jobs", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for inward jobs free issue materials */
export async function viewInwardJobsFreeIssueMaterials(inwardjobsfreeissuematerials: InwardJobFreeIssueMaterial[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Free Issue Material ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = inwardjobsfreeissuematerials.map(async function (inwardjobfreeissuematerial) {
        let displayName = inwardjobfreeissuematerial.approvalMetadata!.approvedOn > 0 ? inwardjobfreeissuematerial.finalRefNumber : inwardjobfreeissuematerial.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForInwardJobFreeIssueMaterial(inwardjobfreeissuematerial)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (inwardjobfreeissuematerial.list.length).toString(),
            `${convertBigIntTimestampToDate(inwardjobfreeissuematerial.metadata!.createdAt)}/${convertBigIntTimestampToDate(inwardjobfreeissuematerial.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(inwardjobfreeissuematerial.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${inwardjobfreeissuematerial.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Free Issue Materials", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for inward jobs free issue material returns */
export async function viewInwardJobsFreeIssueMaterialReturns(inwardjobsfreeissuematerialreturns: InwardJobFreeIssueMaterialReturn[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Free Issue Material Return ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = inwardjobsfreeissuematerialreturns.map(async function (inwardjobfreeissuematerialreturn) {
        let displayName = inwardjobfreeissuematerialreturn.approvalMetadata!.approvedOn > 0 ? inwardjobfreeissuematerialreturn.finalRefNumber : inwardjobfreeissuematerialreturn.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForInwardJobFreeIssueMaterialReturn(inwardjobfreeissuematerialreturn)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (inwardjobfreeissuematerialreturn.list.length).toString(),
            `${convertBigIntTimestampToDate(inwardjobfreeissuematerialreturn.metadata!.createdAt)}/${convertBigIntTimestampToDate(inwardjobfreeissuematerialreturn.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(inwardjobfreeissuematerialreturn.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${inwardjobfreeissuematerialreturn.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Free Issue Material Returns", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for production plans */
export async function viewProductionPlans(productionplans: ProductionPlan[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Production Plan ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = productionplans.map(async function (productionplan) {
        let displayName = productionplan.approvalMetadata!.approvedOn > 0 ? productionplan.finalRefNumber : productionplan.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForProductionPlan(productionplan)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (productionplan.list.length).toString(),
            `${convertBigIntTimestampToDate(productionplan.metadata!.createdAt)}/${convertBigIntTimestampToDate(productionplan.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(productionplan.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${productionplan.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Production Plans", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for sales enquiries */
export async function viewSalesEnquiries(salesenquiries: SalesEnquiry[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Sales Enquiry ID", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = salesenquiries.map(async function (salesenquiry) {
        let displayName = salesenquiry.approvalMetadata!.approvedOn > 0 ? salesenquiry.finalRefNumber : salesenquiry.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForSalesEnquiry(salesenquiry)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,

            `${convertBigIntTimestampToDate(salesenquiry.metadata!.createdAt)}/${convertBigIntTimestampToDate(salesenquiry.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(salesenquiry.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${salesenquiry.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Sales Enquiries", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for qc samples */
export async function viewQCSamples(qcsamples: QCSample[]) {
    let tableHeader: string[] = [];
    tableHeader = ["Sample Name", "Material", "Created/Approved", "Status", "View", "Download"];

    let familiesList = await fetches.familiesListFromIDs(Array.from(new Set(qcsamples.map(s => s.familyId))));
    let familiesMap: Map<bigint, Family> = new Map();
    familiesList.forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });
    
    let tableRowsPromises: Promise<string[]>[] = qcsamples.map(async function (qcsample) {
        let displayName = qcsample.name;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;
        let family = familiesMap.get(qcsample.familyId) as Family;

        return [
            displayName,
            `(${family.code}) ${family.name}`,
            `${convertBigIntTimestampToDate(qcsample.metadata!.createdAt)}/${convertBigIntTimestampToDate(qcsample.approvalMetadata!.approvedOn)}`,
            colorizeQCSampleLifecycleStatus(qcsample.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${qcsample.metadata?.uuid}" data-family-type="${family.familyType.toString()}" data-inventory-ref-uuid="${qcsample.inventoryItemUuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "QA Samples", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for associates */
export function viewAssociates(associates: Associate[]) {
    let tableHeader = ["Name", "Phone", "Email", "Created", "View"];
    let tableRows: string[][] = associates.map(associate => {
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;
        return [
            associate.firstName + " " + associate.lastName, associate.workPhone, associate.workEmail, convertBigIntTimestampToDate(associate.metadata!.createdAt), individualLink
        ]
    });
    return renderTableWithCSVHeadersAndRows({ title: "Associates", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for client streams */
export async function viewClientStreams(clientstreams: ClientStream[], title: string = "Streams") {
    let tableHeader = ["Internal Ref", "Title", "Unread/Total Count", "Created", "Last Updated/By", "View"];
    let tableRowsPromises: Promise<string[]>[] = clientstreams.map(async function (clientstream) {
        let displayName = clientstream.title;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForClientStream(clientstream)}"><i class="bx bx-door-open"></i></a>`;

        return [
            clientstream.internalRef,
            displayName,

            `${convertAndColorizeBigintInverted(clientstream.unreadCount)}/${convertAndColorizeBigint(clientstream.messageCount)}`,
            convertBigIntTimestampToDate(clientstream.metadata!.createdAt),
            `${convertBigIntTimestampToDateTime(clientstream.metadata!.modifiedAt)}/${clientstream.lastMessageBy}`,
            individualLink
        ]
    });
    
    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title, headers_array: tableHeader, rows_array: tableRows });
}