"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file asset_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetIndentsServiceSearchAllReq = exports.AssetIndentsServiceCountReq = exports.AssetIndentsServiceFilterReq = exports.AssetIndentsServicePaginationResponse = exports.AssetIndentsServicePaginationReq = exports.AssetIndentItemProspectiveInfoRequest = exports.AssetIndentsServicePaginatedItemsResponse = exports.AssetIndentItemsSearchRequest = exports.AssetIndentItemHistoryRequest = exports.AssetIndentsItemsList = exports.AssetIndentsList = exports.AssetIndentItem = exports.AssetIndentsServiceItemUpdateRequest = exports.AssetIndentsServiceItemCreateRequest = exports.AssetIndent = exports.AssetIndentsServiceUpdateRequest = exports.AssetIndentsServiceCreateRequest = exports.ASSET_INDENT_SORT_KEY = exports.ASSET_INDENT_ITEM_STATUS = exports.ASSET_INDENT_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ASSET_INDENT_ITEM_SORT_KEY
 */
var ASSET_INDENT_ITEM_SORT_KEY;
(function (ASSET_INDENT_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ASSET_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "ASSET_INDENT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "ASSET_INDENT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "ASSET_INDENT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "ASSET_INDENT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ASSET_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "ASSET_INDENT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the quantity
     *
     * @generated from enum value: ASSET_INDENT_ITEM_SORT_KEY_QUANTITY = 11;
     */
    ASSET_INDENT_ITEM_SORT_KEY[ASSET_INDENT_ITEM_SORT_KEY["ASSET_INDENT_ITEM_SORT_KEY_QUANTITY"] = 11] = "ASSET_INDENT_ITEM_SORT_KEY_QUANTITY";
})(ASSET_INDENT_ITEM_SORT_KEY || (exports.ASSET_INDENT_ITEM_SORT_KEY = ASSET_INDENT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ASSET_INDENT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ASSET_INDENT_ITEM_SORT_KEY, "Scailo.ASSET_INDENT_ITEM_SORT_KEY", [
    { no: 0, name: "ASSET_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ASSET_INDENT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ASSET_INDENT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ASSET_INDENT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ASSET_INDENT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ASSET_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "ASSET_INDENT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "ASSET_INDENT_ITEM_SORT_KEY_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of asset indent items
 *
 * @generated from enum Scailo.ASSET_INDENT_ITEM_STATUS
 */
var ASSET_INDENT_ITEM_STATUS;
(function (ASSET_INDENT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: ASSET_INDENT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    ASSET_INDENT_ITEM_STATUS[ASSET_INDENT_ITEM_STATUS["ASSET_INDENT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "ASSET_INDENT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the asset indent items must have been approved
     *
     * @generated from enum value: ASSET_INDENT_ITEM_STATUS_APPROVED = 1;
     */
    ASSET_INDENT_ITEM_STATUS[ASSET_INDENT_ITEM_STATUS["ASSET_INDENT_ITEM_STATUS_APPROVED"] = 1] = "ASSET_INDENT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the asset indent items must be waiting for approval
     *
     * @generated from enum value: ASSET_INDENT_ITEM_STATUS_UNAPPROVED = 2;
     */
    ASSET_INDENT_ITEM_STATUS[ASSET_INDENT_ITEM_STATUS["ASSET_INDENT_ITEM_STATUS_UNAPPROVED"] = 2] = "ASSET_INDENT_ITEM_STATUS_UNAPPROVED";
})(ASSET_INDENT_ITEM_STATUS || (exports.ASSET_INDENT_ITEM_STATUS = ASSET_INDENT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(ASSET_INDENT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(ASSET_INDENT_ITEM_STATUS, "Scailo.ASSET_INDENT_ITEM_STATUS", [
    { no: 0, name: "ASSET_INDENT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "ASSET_INDENT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "ASSET_INDENT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ASSET_INDENT_SORT_KEY
 */
var ASSET_INDENT_SORT_KEY;
(function (ASSET_INDENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_CREATED_AT = 1;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_CREATED_AT"] = 1] = "ASSET_INDENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_MODIFIED_AT = 2;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_MODIFIED_AT"] = 2] = "ASSET_INDENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_APPROVED_ON = 3;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_APPROVED_ON"] = 3] = "ASSET_INDENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_APPROVED_BY = 4;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_APPROVED_BY"] = 4] = "ASSET_INDENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ASSET_INDENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_COMPLETED_ON = 6;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_COMPLETED_ON"] = 6] = "ASSET_INDENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_REFERENCE_ID = 10;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_REFERENCE_ID"] = 10] = "ASSET_INDENT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "ASSET_INDENT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: ASSET_INDENT_SORT_KEY_LOCATION_ID = 12;
     */
    ASSET_INDENT_SORT_KEY[ASSET_INDENT_SORT_KEY["ASSET_INDENT_SORT_KEY_LOCATION_ID"] = 12] = "ASSET_INDENT_SORT_KEY_LOCATION_ID";
})(ASSET_INDENT_SORT_KEY || (exports.ASSET_INDENT_SORT_KEY = ASSET_INDENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ASSET_INDENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ASSET_INDENT_SORT_KEY, "Scailo.ASSET_INDENT_SORT_KEY", [
    { no: 0, name: "ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ASSET_INDENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ASSET_INDENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ASSET_INDENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ASSET_INDENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ASSET_INDENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "ASSET_INDENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ASSET_INDENT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "ASSET_INDENT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "ASSET_INDENT_SORT_KEY_LOCATION_ID" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.AssetIndentsServiceCreateRequest
 */
class AssetIndentsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the asset indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 15;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceCreateRequest, a, b);
    }
}
exports.AssetIndentsServiceCreateRequest = AssetIndentsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.AssetIndentsServiceUpdateRequest
 */
class AssetIndentsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the asset indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 15;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceUpdateRequest, a, b);
    }
}
exports.AssetIndentsServiceUpdateRequest = AssetIndentsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.AssetIndent
 */
class AssetIndent extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this asset indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this asset indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this asset indent
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this asset indent was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the asset indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 15;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated asset indent items
     *
     * @generated from field: repeated Scailo.AssetIndentItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndent";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: AssetIndentItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndent().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndent().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndent().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndent, a, b);
    }
}
exports.AssetIndent = AssetIndent;
/**
 *
 * Describes the parameters required to add an item to a asset indent
 *
 * @generated from message Scailo.AssetIndentsServiceItemCreateRequest
 */
class AssetIndentsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the asset indent ID
     *
     * @generated from field: uint64 asset_indent_id = 10;
     */
    assetIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "asset_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceItemCreateRequest, a, b);
    }
}
exports.AssetIndentsServiceItemCreateRequest = AssetIndentsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a asset indent
 *
 * @generated from message Scailo.AssetIndentsServiceItemUpdateRequest
 */
class AssetIndentsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceItemUpdateRequest, a, b);
    }
}
exports.AssetIndentsServiceItemUpdateRequest = AssetIndentsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a asset indent
 *
 * @generated from message Scailo.AssetIndentItem
 */
class AssetIndentItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this asset indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the asset indent ID
     *
     * @generated from field: uint64 asset_indent_id = 10;
     */
    assetIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "asset_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentItem, a, b);
    }
}
exports.AssetIndentItem = AssetIndentItem;
/**
 *
 * Describes the message consisting of the list of asset indents
 *
 * @generated from message Scailo.AssetIndentsList
 */
class AssetIndentsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.AssetIndent list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: AssetIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsList, a, b);
    }
}
exports.AssetIndentsList = AssetIndentsList;
/**
 *
 * Describes the message consisting of the list of asset indent items
 *
 * @generated from message Scailo.AssetIndentsItemsList
 */
class AssetIndentsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.AssetIndentItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: AssetIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsItemsList, a, b);
    }
}
exports.AssetIndentsItemsList = AssetIndentsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.AssetIndentItemHistoryRequest
 */
class AssetIndentItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the asset indent ID
     *
     * @generated from field: uint64 asset_indent_id = 10;
     */
    assetIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "asset_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentItemHistoryRequest, a, b);
    }
}
exports.AssetIndentItemHistoryRequest = AssetIndentItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.AssetIndentItemsSearchRequest
 */
class AssetIndentItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSET_INDENT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = ASSET_INDENT_ITEM_SORT_KEY.ASSET_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.ASSET_INDENT_ITEM_STATUS status = 7;
     */
    status = ASSET_INDENT_ITEM_STATUS.ASSET_INDENT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the asset indent
     *
     * @generated from field: uint64 asset_indent_id = 20;
     */
    assetIndentId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSET_INDENT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSET_INDENT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "asset_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentItemsSearchRequest, a, b);
    }
}
exports.AssetIndentItemsSearchRequest = AssetIndentItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.AssetIndentsServicePaginatedItemsResponse
 */
class AssetIndentsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.AssetIndentItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: AssetIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServicePaginatedItemsResponse, a, b);
    }
}
exports.AssetIndentsServicePaginatedItemsResponse = AssetIndentsServicePaginatedItemsResponse;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective asset indent item
 *
 * @generated from message Scailo.AssetIndentItemProspectiveInfoRequest
 */
class AssetIndentItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the asset indent ID
     *
     * @generated from field: uint64 asset_indent_id = 10;
     */
    assetIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "asset_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentItemProspectiveInfoRequest, a, b);
    }
}
exports.AssetIndentItemProspectiveInfoRequest = AssetIndentItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.AssetIndentsServicePaginationReq
 */
class AssetIndentsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSET_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = ASSET_INDENT_SORT_KEY.ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this asset indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSET_INDENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServicePaginationReq, a, b);
    }
}
exports.AssetIndentsServicePaginationReq = AssetIndentsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.AssetIndentsServicePaginationResponse
 */
class AssetIndentsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.AssetIndent payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: AssetIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServicePaginationResponse, a, b);
    }
}
exports.AssetIndentsServicePaginationResponse = AssetIndentsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.AssetIndentsServiceFilterReq
 */
class AssetIndentsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSET_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = ASSET_INDENT_SORT_KEY.ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this asset indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the asset indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 25;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an asset indent
     *
     * @generated from field: uint64 constituent_family_id = 40;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 50;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSET_INDENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceFilterReq, a, b);
    }
}
exports.AssetIndentsServiceFilterReq = AssetIndentsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.AssetIndentsServiceCountReq
 */
class AssetIndentsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this asset indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the asset indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 25;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an asset indent
     *
     * @generated from field: uint64 constituent_family_id = 40;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 50;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceCountReq, a, b);
    }
}
exports.AssetIndentsServiceCountReq = AssetIndentsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.AssetIndentsServiceSearchAllReq
 */
class AssetIndentsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSET_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = ASSET_INDENT_SORT_KEY.ASSET_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated user
     *
     * @generated from field: uint64 user_id = 25;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssetIndentsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSET_INDENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssetIndentsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssetIndentsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssetIndentsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssetIndentsServiceSearchAllReq, a, b);
    }
}
exports.AssetIndentsServiceSearchAllReq = AssetIndentsServiceSearchAllReq;
