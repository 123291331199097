"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file generalstreams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralStreamsServiceImportInternalSubscribersRequest = exports.GeneralStreamInternalSubscribersList = exports.GeneralStreamInternalSubscriber = exports.GeneralStreamsServiceInternalSubscriberCreateRequest = exports.GeneralStreamMessageReceiptsList = exports.GeneralStreamMessageReceipt = exports.GeneralStreamsServicePaginatedMessagesResponse = exports.GeneralStreamMessagesSearchRequest = exports.GeneralStreamMessagesList = exports.GeneralStreamMessage = exports.GeneralStreamsServiceMessageCreateRequest = exports.GeneralStreamsServiceSearchAllReq = exports.GeneralStreamsServiceCountReq = exports.GeneralStreamsServiceFilterReq = exports.GeneralStreamsServicePaginationResponse = exports.GeneralStreamsServicePaginationReq = exports.GeneralStreamsList = exports.GeneralStream = exports.GeneralStreamsServiceUpdateRequest = exports.GeneralStreamsServiceCreateRequest = exports.LogbookLogGeneralStreamLC = exports.GENERAL_STREAM_MESSAGE_SORT_KEY = exports.GENERAL_STREAM_MESSAGE_TYPE = exports.GENERAL_STREAM_SORT_KEY = exports.GENERAL_STREAM_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each general stream
 *
 * @generated from enum Scailo.GENERAL_STREAM_LIFECYCLE
 */
var GENERAL_STREAM_LIFECYCLE;
(function (GENERAL_STREAM_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    GENERAL_STREAM_LIFECYCLE[GENERAL_STREAM_LIFECYCLE["GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the general stream is open
     *
     * @generated from enum value: GENERAL_STREAM_LIFECYCLE_OPEN = 1;
     */
    GENERAL_STREAM_LIFECYCLE[GENERAL_STREAM_LIFECYCLE["GENERAL_STREAM_LIFECYCLE_OPEN"] = 1] = "GENERAL_STREAM_LIFECYCLE_OPEN";
    /**
     * Denotes that the general stream has completed
     *
     * @generated from enum value: GENERAL_STREAM_LIFECYCLE_COMPLETED = 2;
     */
    GENERAL_STREAM_LIFECYCLE[GENERAL_STREAM_LIFECYCLE["GENERAL_STREAM_LIFECYCLE_COMPLETED"] = 2] = "GENERAL_STREAM_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the general stream has been cancelled
     *
     * @generated from enum value: GENERAL_STREAM_LIFECYCLE_CANCELLED = 3;
     */
    GENERAL_STREAM_LIFECYCLE[GENERAL_STREAM_LIFECYCLE["GENERAL_STREAM_LIFECYCLE_CANCELLED"] = 3] = "GENERAL_STREAM_LIFECYCLE_CANCELLED";
})(GENERAL_STREAM_LIFECYCLE || (exports.GENERAL_STREAM_LIFECYCLE = GENERAL_STREAM_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(GENERAL_STREAM_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(GENERAL_STREAM_LIFECYCLE, "Scailo.GENERAL_STREAM_LIFECYCLE", [
    { no: 0, name: "GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "GENERAL_STREAM_LIFECYCLE_OPEN" },
    { no: 2, name: "GENERAL_STREAM_LIFECYCLE_COMPLETED" },
    { no: 3, name: "GENERAL_STREAM_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GENERAL_STREAM_SORT_KEY
 */
var GENERAL_STREAM_SORT_KEY;
(function (GENERAL_STREAM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GENERAL_STREAM_SORT_KEY[GENERAL_STREAM_SORT_KEY["GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GENERAL_STREAM_SORT_KEY_CREATED_AT = 1;
     */
    GENERAL_STREAM_SORT_KEY[GENERAL_STREAM_SORT_KEY["GENERAL_STREAM_SORT_KEY_CREATED_AT"] = 1] = "GENERAL_STREAM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GENERAL_STREAM_SORT_KEY_MODIFIED_AT = 2;
     */
    GENERAL_STREAM_SORT_KEY[GENERAL_STREAM_SORT_KEY["GENERAL_STREAM_SORT_KEY_MODIFIED_AT"] = 2] = "GENERAL_STREAM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: GENERAL_STREAM_SORT_KEY_COMPLETED_ON = 6;
     */
    GENERAL_STREAM_SORT_KEY[GENERAL_STREAM_SORT_KEY["GENERAL_STREAM_SORT_KEY_COMPLETED_ON"] = 6] = "GENERAL_STREAM_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: GENERAL_STREAM_SORT_KEY_TITLE = 10;
     */
    GENERAL_STREAM_SORT_KEY[GENERAL_STREAM_SORT_KEY["GENERAL_STREAM_SORT_KEY_TITLE"] = 10] = "GENERAL_STREAM_SORT_KEY_TITLE";
})(GENERAL_STREAM_SORT_KEY || (exports.GENERAL_STREAM_SORT_KEY = GENERAL_STREAM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GENERAL_STREAM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GENERAL_STREAM_SORT_KEY, "Scailo.GENERAL_STREAM_SORT_KEY", [
    { no: 0, name: "GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GENERAL_STREAM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GENERAL_STREAM_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "GENERAL_STREAM_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "GENERAL_STREAM_SORT_KEY_TITLE" },
]);
/**
 *
 * Describes the available message types for a general stream message
 *
 * @generated from enum Scailo.GENERAL_STREAM_MESSAGE_TYPE
 */
var GENERAL_STREAM_MESSAGE_TYPE;
(function (GENERAL_STREAM_MESSAGE_TYPE) {
    /**
     * Used only for filters
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED = 0;
     */
    GENERAL_STREAM_MESSAGE_TYPE[GENERAL_STREAM_MESSAGE_TYPE["GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED"] = 0] = "GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED";
    /**
     * Denotes that the message was sent by a user
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_TYPE_USER = 1;
     */
    GENERAL_STREAM_MESSAGE_TYPE[GENERAL_STREAM_MESSAGE_TYPE["GENERAL_STREAM_MESSAGE_TYPE_USER"] = 1] = "GENERAL_STREAM_MESSAGE_TYPE_USER";
    /**
     * Denotes that the message was sent by the system
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_TYPE_SYSTEM = 2;
     */
    GENERAL_STREAM_MESSAGE_TYPE[GENERAL_STREAM_MESSAGE_TYPE["GENERAL_STREAM_MESSAGE_TYPE_SYSTEM"] = 2] = "GENERAL_STREAM_MESSAGE_TYPE_SYSTEM";
})(GENERAL_STREAM_MESSAGE_TYPE || (exports.GENERAL_STREAM_MESSAGE_TYPE = GENERAL_STREAM_MESSAGE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(GENERAL_STREAM_MESSAGE_TYPE)
protobuf_1.proto3.util.setEnumType(GENERAL_STREAM_MESSAGE_TYPE, "Scailo.GENERAL_STREAM_MESSAGE_TYPE", [
    { no: 0, name: "GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "GENERAL_STREAM_MESSAGE_TYPE_USER" },
    { no: 2, name: "GENERAL_STREAM_MESSAGE_TYPE_SYSTEM" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GENERAL_STREAM_MESSAGE_SORT_KEY
 */
var GENERAL_STREAM_MESSAGE_SORT_KEY;
(function (GENERAL_STREAM_MESSAGE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GENERAL_STREAM_MESSAGE_SORT_KEY[GENERAL_STREAM_MESSAGE_SORT_KEY["GENERAL_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GENERAL_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_SORT_KEY_CREATED_AT = 1;
     */
    GENERAL_STREAM_MESSAGE_SORT_KEY[GENERAL_STREAM_MESSAGE_SORT_KEY["GENERAL_STREAM_MESSAGE_SORT_KEY_CREATED_AT"] = 1] = "GENERAL_STREAM_MESSAGE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GENERAL_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT = 2;
     */
    GENERAL_STREAM_MESSAGE_SORT_KEY[GENERAL_STREAM_MESSAGE_SORT_KEY["GENERAL_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT"] = 2] = "GENERAL_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT";
})(GENERAL_STREAM_MESSAGE_SORT_KEY || (exports.GENERAL_STREAM_MESSAGE_SORT_KEY = GENERAL_STREAM_MESSAGE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GENERAL_STREAM_MESSAGE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GENERAL_STREAM_MESSAGE_SORT_KEY, "Scailo.GENERAL_STREAM_MESSAGE_SORT_KEY", [
    { no: 0, name: "GENERAL_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GENERAL_STREAM_MESSAGE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GENERAL_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being an general stream lifecycle status
 *
 * @generated from message Scailo.LogbookLogGeneralStreamLC
 */
class LogbookLogGeneralStreamLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE operation = 11;
     */
    operation = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogGeneralStreamLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogGeneralStreamLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogGeneralStreamLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogGeneralStreamLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogGeneralStreamLC, a, b);
    }
}
exports.LogbookLogGeneralStreamLC = LogbookLogGeneralStreamLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.GeneralStreamsServiceCreateRequest
 */
class GeneralStreamsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the general stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * Assign self (the user creating the stream) as an internal subscriber
     *
     * @generated from field: bool assign_self_as_internal_subscriber = 80;
     */
    assignSelfAsInternalSubscriber = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 80, name: "assign_self_as_internal_subscriber", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceCreateRequest, a, b);
    }
}
exports.GeneralStreamsServiceCreateRequest = GeneralStreamsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.GeneralStreamsServiceUpdateRequest
 */
class GeneralStreamsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the general stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceUpdateRequest, a, b);
    }
}
exports.GeneralStreamsServiceUpdateRequest = GeneralStreamsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.GeneralStream
 */
class GeneralStream extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this general stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this general stream
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE status = 4;
     */
    status = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this general stream
     *
     * @generated from field: repeated Scailo.LogbookLogGeneralStreamLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this general stream was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the general stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores the number of unread messages in this stream for the specific user (on the basis of the auth token)
     *
     * @generated from field: uint64 unread_count = 60;
     */
    unreadCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of messages in the stream
     *
     * @generated from field: uint64 message_count = 61;
     */
    messageCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who added the last message
     *
     * @generated from field: string last_message_by = 62;
     */
    lastMessageBy = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStream";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogGeneralStreamLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "unread_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "message_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "last_message_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStream().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStream().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStream().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStream, a, b);
    }
}
exports.GeneralStream = GeneralStream;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.GeneralStreamsList
 */
class GeneralStreamsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GeneralStream list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GeneralStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsList, a, b);
    }
}
exports.GeneralStreamsList = GeneralStreamsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.GeneralStreamsServicePaginationReq
 */
class GeneralStreamsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GENERAL_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = GENERAL_STREAM_SORT_KEY.GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this general stream
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE status = 6;
     */
    status = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServicePaginationReq, a, b);
    }
}
exports.GeneralStreamsServicePaginationReq = GeneralStreamsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.GeneralStreamsServicePaginationResponse
 */
class GeneralStreamsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GeneralStream payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GeneralStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServicePaginationResponse, a, b);
    }
}
exports.GeneralStreamsServicePaginationResponse = GeneralStreamsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.GeneralStreamsServiceFilterReq
 */
class GeneralStreamsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GENERAL_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = GENERAL_STREAM_SORT_KEY.GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this general stream
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE status = 10;
     */
    status = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the general stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceFilterReq, a, b);
    }
}
exports.GeneralStreamsServiceFilterReq = GeneralStreamsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.GeneralStreamsServiceCountReq
 */
class GeneralStreamsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this general stream
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE status = 10;
     */
    status = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the general stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceCountReq, a, b);
    }
}
exports.GeneralStreamsServiceCountReq = GeneralStreamsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.GeneralStreamsServiceSearchAllReq
 */
class GeneralStreamsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GENERAL_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = GENERAL_STREAM_SORT_KEY.GENERAL_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.GENERAL_STREAM_LIFECYCLE status = 10;
     */
    status = GENERAL_STREAM_LIFECYCLE.GENERAL_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceSearchAllReq, a, b);
    }
}
exports.GeneralStreamsServiceSearchAllReq = GeneralStreamsServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add a message to an general stream
 *
 * @generated from message Scailo.GeneralStreamsServiceMessageCreateRequest
 */
class GeneralStreamsServiceMessageCreateRequest extends protobuf_1.Message {
    /**
     * The type of the message
     *
     * @generated from field: Scailo.GENERAL_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = GENERAL_STREAM_MESSAGE_TYPE.GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the general stream UUID
     *
     * @generated from field: string general_stream_uuid = 10;
     */
    generalStreamUuid = "";
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceMessageCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "general_stream_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceMessageCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceMessageCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceMessageCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceMessageCreateRequest, a, b);
    }
}
exports.GeneralStreamsServiceMessageCreateRequest = GeneralStreamsServiceMessageCreateRequest;
/**
 *
 * Describes the parameters that constitute a message associated to an general stream
 *
 * @generated from message Scailo.GeneralStreamMessage
 */
class GeneralStreamMessage extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this general stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The type of the message
     *
     * @generated from field: Scailo.GENERAL_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = GENERAL_STREAM_MESSAGE_TYPE.GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the general stream ID
     *
     * @generated from field: uint64 general_stream_id = 10;
     */
    generalStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 60;
     */
    isRead = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamMessage";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "general_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamMessage().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamMessage().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamMessage().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamMessage, a, b);
    }
}
exports.GeneralStreamMessage = GeneralStreamMessage;
/**
 *
 * Describes the message consisting of the list of general stream messages
 *
 * @generated from message Scailo.GeneralStreamMessagesList
 */
class GeneralStreamMessagesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GeneralStreamMessage list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamMessagesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GeneralStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamMessagesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamMessagesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamMessagesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamMessagesList, a, b);
    }
}
exports.GeneralStreamMessagesList = GeneralStreamMessagesList;
/**
 *
 * Describes the request payload to retrieve messages.
 *
 * @generated from message Scailo.GeneralStreamMessagesSearchRequest
 */
class GeneralStreamMessagesSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GENERAL_STREAM_MESSAGE_SORT_KEY sort_key = 5;
     */
    sortKey = GENERAL_STREAM_MESSAGE_SORT_KEY.GENERAL_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The type of the message
     *
     * @generated from field: Scailo.GENERAL_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = GENERAL_STREAM_MESSAGE_TYPE.GENERAL_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the general stream
     *
     * @generated from field: uint64 general_stream_id = 20;
     */
    generalStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 30;
     */
    responseToMessageUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamMessagesSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_MESSAGE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GENERAL_STREAM_MESSAGE_TYPE) },
        { no: 20, name: "general_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamMessagesSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamMessagesSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamMessagesSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamMessagesSearchRequest, a, b);
    }
}
exports.GeneralStreamMessagesSearchRequest = GeneralStreamMessagesSearchRequest;
/**
 *
 * Describes the response to a pagination messages request
 *
 * @generated from message Scailo.GeneralStreamsServicePaginatedMessagesResponse
 */
class GeneralStreamsServicePaginatedMessagesResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GeneralStreamMessage payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServicePaginatedMessagesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GeneralStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServicePaginatedMessagesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServicePaginatedMessagesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServicePaginatedMessagesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServicePaginatedMessagesResponse, a, b);
    }
}
exports.GeneralStreamsServicePaginatedMessagesResponse = GeneralStreamsServicePaginatedMessagesResponse;
/**
 *
 * Describes the parameters that constitute a message receipt
 *
 * @generated from message Scailo.GeneralStreamMessageReceipt
 */
class GeneralStreamMessageReceipt extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this general stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the general stream message UUID
     *
     * @generated from field: string general_stream_message_uuid = 10;
     */
    generalStreamMessageUuid = "";
    /**
     * The ID of the user who read this
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 12;
     */
    isRead = false;
    /**
     * Stores the timestamp of when the message was read
     *
     * @generated from field: uint64 read_at = 13;
     */
    readAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamMessageReceipt";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "general_stream_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 13, name: "read_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamMessageReceipt().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamMessageReceipt().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamMessageReceipt().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamMessageReceipt, a, b);
    }
}
exports.GeneralStreamMessageReceipt = GeneralStreamMessageReceipt;
/**
 *
 * Describes the message consisting of the list of general stream message receipts
 *
 * @generated from message Scailo.GeneralStreamMessageReceiptsList
 */
class GeneralStreamMessageReceiptsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GeneralStreamMessageReceipt list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamMessageReceiptsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GeneralStreamMessageReceipt, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamMessageReceiptsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamMessageReceiptsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamMessageReceiptsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamMessageReceiptsList, a, b);
    }
}
exports.GeneralStreamMessageReceiptsList = GeneralStreamMessageReceiptsList;
/**
 *
 * Describes the parameters necessary to create an internal subscriber
 *
 * @generated from message Scailo.GeneralStreamsServiceInternalSubscriberCreateRequest
 */
class GeneralStreamsServiceInternalSubscriberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the general stream ID
     *
     * @generated from field: uint64 general_stream_id = 10;
     */
    generalStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceInternalSubscriberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "general_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceInternalSubscriberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceInternalSubscriberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceInternalSubscriberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceInternalSubscriberCreateRequest, a, b);
    }
}
exports.GeneralStreamsServiceInternalSubscriberCreateRequest = GeneralStreamsServiceInternalSubscriberCreateRequest;
/**
 *
 * Describes the parameters that constitute an internal subscriber
 *
 * @generated from message Scailo.GeneralStreamInternalSubscriber
 */
class GeneralStreamInternalSubscriber extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this general stream internal subscriber
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the general stream ID
     *
     * @generated from field: uint64 general_stream_id = 10;
     */
    generalStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamInternalSubscriber";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "general_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamInternalSubscriber().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamInternalSubscriber().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamInternalSubscriber().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamInternalSubscriber, a, b);
    }
}
exports.GeneralStreamInternalSubscriber = GeneralStreamInternalSubscriber;
/**
 *
 * Describes the message consisting of the list of internal subscribers
 *
 * @generated from message Scailo.GeneralStreamInternalSubscribersList
 */
class GeneralStreamInternalSubscribersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GeneralStreamInternalSubscriber list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamInternalSubscribersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GeneralStreamInternalSubscriber, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamInternalSubscribersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamInternalSubscribersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamInternalSubscribersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamInternalSubscribersList, a, b);
    }
}
exports.GeneralStreamInternalSubscribersList = GeneralStreamInternalSubscribersList;
/**
 *
 * Describes the data model to handle importing of internal subscribers from the given identifier representing a team or a department
 *
 * @generated from message Scailo.GeneralStreamsServiceImportInternalSubscribersRequest
 */
class GeneralStreamsServiceImportInternalSubscribersRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the general stream
     *
     * @generated from field: uint64 general_stream_id = 2;
     */
    generalStreamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team or the department from which internal subscribers need to be added to the general stream
     *
     * @generated from field: uint64 resource_id = 3;
     */
    resourceId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing internal subscribers in the general stream need to be deleted before adding from the source record
     *
     * @generated from field: bool delete_existing = 4;
     */
    deleteExisting = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralStreamsServiceImportInternalSubscribersRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "general_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "resource_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralStreamsServiceImportInternalSubscribersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralStreamsServiceImportInternalSubscribersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralStreamsServiceImportInternalSubscribersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralStreamsServiceImportInternalSubscribersRequest, a, b);
    }
}
exports.GeneralStreamsServiceImportInternalSubscribersRequest = GeneralStreamsServiceImportInternalSubscribersRequest;
