"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file salaries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalariesServiceSearchAllReq = exports.SalariesServiceCountReq = exports.SalariesServiceFilterReq = exports.SalariesServicePaginationResponse = exports.SalariesServicePaginationReq = exports.SalaryReimbursementItemProspectiveInfoRequest = exports.SalaryReimbursementItemHistoryRequest = exports.SalariesReimbursementItemsList = exports.SalaryReimbursementItem = exports.SalariesServiceReimbursementItemUpdateRequest = exports.SalariesServiceReimbursementItemCreateRequest = exports.SalaryDeductionItemProspectiveInfoRequest = exports.SalaryDeductionItemHistoryRequest = exports.SalariesDeductionItemsList = exports.SalaryDeductionItem = exports.SalariesServiceDeductionItemUpdateRequest = exports.SalariesServiceDeductionItemCreateRequest = exports.SalaryAdditionItemProspectiveInfoRequest = exports.SalaryAdditionItemHistoryRequest = exports.SalariesAdditionItemsList = exports.SalaryAdditionItem = exports.SalariesServiceAdditionItemUpdateRequest = exports.SalariesServiceAdditionItemCreateRequest = exports.SalariesList = exports.Salary = exports.SalariesServiceAutofillRequest = exports.SalariesServiceUpdateRequest = exports.SalariesServiceCreateRequest = exports.SALARY_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALARY_SORT_KEY
 */
var SALARY_SORT_KEY;
(function (SALARY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALARY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALARY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALARY_SORT_KEY_CREATED_AT = 1;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_CREATED_AT"] = 1] = "SALARY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALARY_SORT_KEY_MODIFIED_AT = 2;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_MODIFIED_AT"] = 2] = "SALARY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALARY_SORT_KEY_APPROVED_ON = 3;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_APPROVED_ON"] = 3] = "SALARY_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALARY_SORT_KEY_APPROVED_BY = 4;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_APPROVED_BY"] = 4] = "SALARY_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALARY_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALARY_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SALARY_SORT_KEY_COMPLETED_ON = 6;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_COMPLETED_ON"] = 6] = "SALARY_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SALARY_SORT_KEY_REFERENCE_ID = 10;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_REFERENCE_ID"] = 10] = "SALARY_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SALARY_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALARY_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the employee ID
     *
     * @generated from enum value: SALARY_SORT_KEY_EMPLOYEE_ID = 12;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_EMPLOYEE_ID"] = 12] = "SALARY_SORT_KEY_EMPLOYEE_ID";
    /**
     * Fetch ordered results by the bank account ID
     *
     * @generated from enum value: SALARY_SORT_KEY_BANK_ACCOUNT_ID = 13;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_BANK_ACCOUNT_ID"] = 13] = "SALARY_SORT_KEY_BANK_ACCOUNT_ID";
    /**
     * Fetch ordered results by the currency ID
     *
     * @generated from enum value: SALARY_SORT_KEY_CURRENCY_ID = 14;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_CURRENCY_ID"] = 14] = "SALARY_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch ordered results by the payroll group ID
     *
     * @generated from enum value: SALARY_SORT_KEY_PAYROLL_GROUP_ID = 15;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_PAYROLL_GROUP_ID"] = 15] = "SALARY_SORT_KEY_PAYROLL_GROUP_ID";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: SALARY_SORT_KEY_TAX_GROUP_ID = 16;
     */
    SALARY_SORT_KEY[SALARY_SORT_KEY["SALARY_SORT_KEY_TAX_GROUP_ID"] = 16] = "SALARY_SORT_KEY_TAX_GROUP_ID";
})(SALARY_SORT_KEY || (exports.SALARY_SORT_KEY = SALARY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALARY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALARY_SORT_KEY, "Scailo.SALARY_SORT_KEY", [
    { no: 0, name: "SALARY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALARY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALARY_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALARY_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALARY_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALARY_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALARY_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALARY_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALARY_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "SALARY_SORT_KEY_EMPLOYEE_ID" },
    { no: 13, name: "SALARY_SORT_KEY_BANK_ACCOUNT_ID" },
    { no: 14, name: "SALARY_SORT_KEY_CURRENCY_ID" },
    { no: 15, name: "SALARY_SORT_KEY_PAYROLL_GROUP_ID" },
    { no: 16, name: "SALARY_SORT_KEY_TAX_GROUP_ID" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalariesServiceCreateRequest
 */
class SalariesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: int64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the salary
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the associated employee
     *
     * @generated from field: int64 employee_id = 12;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The date from which the salary is being calculated
     *
     * @generated from field: string from_date = 13;
     */
    fromDate = "";
    /**
     * The date until which the salary is being calculated
     *
     * @generated from field: string to_date = 14;
     */
    toDate = "";
    /**
     * The ID of the bank account (belonging to the organization) from which the salary will be debited
     *
     * @generated from field: int64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: int64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount
     *
     * @generated from field: int64 basic_pay_amount = 17;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The unit of the basic pay
     *
     * @generated from field: int64 basic_pay_uom_id = 18;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The number of attendance records being considered
     *
     * @generated from field: int64 applicable_attendance_records_count = 19;
     */
    applicableAttendanceRecordsCount = protobuf_1.protoInt64.zero;
    /**
     * The unit of attendance
     *
     * @generated from field: int64 applicable_attendance_uom_id = 20;
     */
    applicableAttendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The numerator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator = 21;
     */
    cfFromAttendanceUomIdToBasicPayUomIdNumerator = protobuf_1.protoInt64.zero;
    /**
     * The denominator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator = 22;
     */
    cfFromAttendanceUomIdToBasicPayUomIdDenominator = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated payroll group
     *
     * @generated from field: int64 payroll_group_id = 23;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: int64 tax_group_id = 24;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Any additional description
     *
     * @generated from field: string description = 25;
     */
    description = "";
    /**
     * Stores any roundoff amount
     *
     * @generated from field: int64 round_off = 30;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "employee_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "from_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "to_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 17, name: "basic_pay_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "basic_pay_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "applicable_attendance_records_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "applicable_attendance_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 21, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "payroll_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "tax_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceCreateRequest, a, b);
    }
}
exports.SalariesServiceCreateRequest = SalariesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalariesServiceUpdateRequest
 */
class SalariesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: int64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the salary
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The date from which the salary is being calculated
     *
     * @generated from field: string from_date = 13;
     */
    fromDate = "";
    /**
     * The date until which the salary is being calculated
     *
     * @generated from field: string to_date = 14;
     */
    toDate = "";
    /**
     * The ID of the bank account (belonging to the organization) from which the salary will be debited
     *
     * @generated from field: int64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: int64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount
     *
     * @generated from field: int64 basic_pay_amount = 17;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The unit of the basic pay
     *
     * @generated from field: int64 basic_pay_uom_id = 18;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The number of attendance records being considered
     *
     * @generated from field: int64 applicable_attendance_records_count = 19;
     */
    applicableAttendanceRecordsCount = protobuf_1.protoInt64.zero;
    /**
     * The unit of attendance
     *
     * @generated from field: int64 applicable_attendance_uom_id = 20;
     */
    applicableAttendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The numerator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator = 21;
     */
    cfFromAttendanceUomIdToBasicPayUomIdNumerator = protobuf_1.protoInt64.zero;
    /**
     * The denominator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator = 22;
     */
    cfFromAttendanceUomIdToBasicPayUomIdDenominator = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated payroll group
     *
     * @generated from field: int64 payroll_group_id = 23;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: int64 tax_group_id = 24;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Any additional description
     *
     * @generated from field: string description = 25;
     */
    description = "";
    /**
     * Stores any roundoff amount
     *
     * @generated from field: int64 round_off = 30;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "from_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "to_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 17, name: "basic_pay_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "basic_pay_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "applicable_attendance_records_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "applicable_attendance_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 21, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "payroll_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "tax_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceUpdateRequest, a, b);
    }
}
exports.SalariesServiceUpdateRequest = SalariesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.SalariesServiceAutofillRequest
 */
class SalariesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceAutofillRequest, a, b);
    }
}
exports.SalariesServiceAutofillRequest = SalariesServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Salary
 */
class Salary extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this salary
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this salary
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this salary
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this salary was marked as completed
     *
     * @generated from field: int64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: int64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the salary
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the associated employee
     *
     * @generated from field: int64 employee_id = 12;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The date from which the salary is being calculated
     *
     * @generated from field: string from_date = 13;
     */
    fromDate = "";
    /**
     * The date until which the salary is being calculated
     *
     * @generated from field: string to_date = 14;
     */
    toDate = "";
    /**
     * The ID of the bank account (belonging to the organization) from which the salary will be debited
     *
     * @generated from field: int64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: int64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount
     *
     * @generated from field: int64 basic_pay_amount = 17;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The unit of the basic pay
     *
     * @generated from field: int64 basic_pay_uom_id = 18;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The number of attendance records being considered
     *
     * @generated from field: int64 applicable_attendance_records_count = 19;
     */
    applicableAttendanceRecordsCount = protobuf_1.protoInt64.zero;
    /**
     * The unit of attendance
     *
     * @generated from field: int64 applicable_attendance_uom_id = 20;
     */
    applicableAttendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The numerator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator = 21;
     */
    cfFromAttendanceUomIdToBasicPayUomIdNumerator = protobuf_1.protoInt64.zero;
    /**
     * The denominator for the conversion factor from attendance unit to basic pay unit
     *
     * @generated from field: int64 cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator = 22;
     */
    cfFromAttendanceUomIdToBasicPayUomIdDenominator = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated payroll group
     *
     * @generated from field: int64 payroll_group_id = 23;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: int64 tax_group_id = 24;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Any additional description
     *
     * @generated from field: string description = 25;
     */
    description = "";
    /**
     * Stores any roundoff amount
     *
     * @generated from field: int64 round_off = 30;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of associated addition items
     *
     * @generated from field: repeated Scailo.SalaryAdditionItem addition_items_list = 40;
     */
    additionItemsList = [];
    /**
     * The list of associated deduction items
     *
     * @generated from field: repeated Scailo.SalaryDeductionItem deduction_items_list = 41;
     */
    deductionItemsList = [];
    /**
     * The list of associated reimbursement items
     *
     * @generated from field: repeated Scailo.SalaryReimbursementItem reimbursement_items_list = 42;
     */
    reimbursementItemsList = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    /**
     * The total amount of the salary
     *
     * @generated from field: double total_amount = 60;
     */
    totalAmount = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Salary";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "employee_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "from_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "to_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 17, name: "basic_pay_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "basic_pay_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "applicable_attendance_records_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "applicable_attendance_uom_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 21, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_numerator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "cf_from_attendance_uom_id_to_basic_pay_uom_id_denominator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "payroll_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "tax_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 40, name: "addition_items_list", kind: "message", T: SalaryAdditionItem, repeated: true },
        { no: 41, name: "deduction_items_list", kind: "message", T: SalaryDeductionItem, repeated: true },
        { no: 42, name: "reimbursement_items_list", kind: "message", T: SalaryReimbursementItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
        { no: 60, name: "total_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new Salary().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Salary().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Salary().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Salary, a, b);
    }
}
exports.Salary = Salary;
/**
 *
 * Describes the message consisting of the list of salaries
 *
 * @generated from message Scailo.SalariesList
 */
class SalariesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Salary list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Salary, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesList, a, b);
    }
}
exports.SalariesList = SalariesList;
/**
 *
 * Describes the parameters required to add an addition item to a salary
 *
 * @generated from message Scailo.SalariesServiceAdditionItemCreateRequest
 */
class SalariesServiceAdditionItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceAdditionItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceAdditionItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceAdditionItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceAdditionItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceAdditionItemCreateRequest, a, b);
    }
}
exports.SalariesServiceAdditionItemCreateRequest = SalariesServiceAdditionItemCreateRequest;
/**
 *
 * Describes the parameters required to update an addition item in a salary
 *
 * @generated from message Scailo.SalariesServiceAdditionItemUpdateRequest
 */
class SalariesServiceAdditionItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceAdditionItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceAdditionItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceAdditionItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceAdditionItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceAdditionItemUpdateRequest, a, b);
    }
}
exports.SalariesServiceAdditionItemUpdateRequest = SalariesServiceAdditionItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an addition item associated to a salary
 *
 * @generated from message Scailo.SalaryAdditionItem
 */
class SalaryAdditionItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this salary
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryAdditionItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryAdditionItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryAdditionItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryAdditionItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryAdditionItem, a, b);
    }
}
exports.SalaryAdditionItem = SalaryAdditionItem;
/**
 *
 * Describes the message consisting of the list of salary addition items
 *
 * @generated from message Scailo.SalariesAdditionItemsList
 */
class SalariesAdditionItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalaryAdditionItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesAdditionItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalaryAdditionItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesAdditionItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesAdditionItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesAdditionItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesAdditionItemsList, a, b);
    }
}
exports.SalariesAdditionItemsList = SalariesAdditionItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the addition record
 *
 * @generated from message Scailo.SalaryAdditionItemHistoryRequest
 */
class SalaryAdditionItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryAdditionItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryAdditionItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryAdditionItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryAdditionItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryAdditionItemHistoryRequest, a, b);
    }
}
exports.SalaryAdditionItemHistoryRequest = SalaryAdditionItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective salary addition item
 *
 * @generated from message Scailo.SalaryAdditionItemProspectiveInfoRequest
 */
class SalaryAdditionItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryAdditionItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryAdditionItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryAdditionItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryAdditionItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryAdditionItemProspectiveInfoRequest, a, b);
    }
}
exports.SalaryAdditionItemProspectiveInfoRequest = SalaryAdditionItemProspectiveInfoRequest;
/**
 *
 * Describes the parameters required to add an deduction item to a salary
 *
 * @generated from message Scailo.SalariesServiceDeductionItemCreateRequest
 */
class SalariesServiceDeductionItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceDeductionItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceDeductionItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceDeductionItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceDeductionItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceDeductionItemCreateRequest, a, b);
    }
}
exports.SalariesServiceDeductionItemCreateRequest = SalariesServiceDeductionItemCreateRequest;
/**
 *
 * Describes the parameters required to update an deduction item in a salary
 *
 * @generated from message Scailo.SalariesServiceDeductionItemUpdateRequest
 */
class SalariesServiceDeductionItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceDeductionItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceDeductionItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceDeductionItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceDeductionItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceDeductionItemUpdateRequest, a, b);
    }
}
exports.SalariesServiceDeductionItemUpdateRequest = SalariesServiceDeductionItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an deduction item associated to a salary
 *
 * @generated from message Scailo.SalaryDeductionItem
 */
class SalaryDeductionItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this salary
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total quantity of this item (in cents)
     *
     * @generated from field: int64 quantity = 13;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryDeductionItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryDeductionItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryDeductionItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryDeductionItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryDeductionItem, a, b);
    }
}
exports.SalaryDeductionItem = SalaryDeductionItem;
/**
 *
 * Describes the message consisting of the list of salary deduction items
 *
 * @generated from message Scailo.SalariesDeductionItemsList
 */
class SalariesDeductionItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalaryDeductionItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesDeductionItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalaryDeductionItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesDeductionItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesDeductionItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesDeductionItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesDeductionItemsList, a, b);
    }
}
exports.SalariesDeductionItemsList = SalariesDeductionItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the deduction record
 *
 * @generated from message Scailo.SalaryDeductionItemHistoryRequest
 */
class SalaryDeductionItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryDeductionItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryDeductionItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryDeductionItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryDeductionItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryDeductionItemHistoryRequest, a, b);
    }
}
exports.SalaryDeductionItemHistoryRequest = SalaryDeductionItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective salary deduction item
 *
 * @generated from message Scailo.SalaryDeductionItemProspectiveInfoRequest
 */
class SalaryDeductionItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryDeductionItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryDeductionItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryDeductionItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryDeductionItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryDeductionItemProspectiveInfoRequest, a, b);
    }
}
exports.SalaryDeductionItemProspectiveInfoRequest = SalaryDeductionItemProspectiveInfoRequest;
/**
 *
 * Describes the parameters required to add an reimbursement item to a salary
 *
 * @generated from message Scailo.SalariesServiceReimbursementItemCreateRequest
 */
class SalariesServiceReimbursementItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: int64 amount = 13;
     */
    amount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceReimbursementItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceReimbursementItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceReimbursementItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceReimbursementItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceReimbursementItemCreateRequest, a, b);
    }
}
exports.SalariesServiceReimbursementItemCreateRequest = SalariesServiceReimbursementItemCreateRequest;
/**
 *
 * Describes the parameters required to update an reimbursement item in a salary
 *
 * @generated from message Scailo.SalariesServiceReimbursementItemUpdateRequest
 */
class SalariesServiceReimbursementItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: int64 amount = 13;
     */
    amount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceReimbursementItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceReimbursementItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceReimbursementItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceReimbursementItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceReimbursementItemUpdateRequest, a, b);
    }
}
exports.SalariesServiceReimbursementItemUpdateRequest = SalariesServiceReimbursementItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an reimbursement item associated to a salary
 *
 * @generated from message Scailo.SalaryReimbursementItem
 */
class SalaryReimbursementItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this salary
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: int64 amount = 13;
     */
    amount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryReimbursementItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryReimbursementItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryReimbursementItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryReimbursementItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryReimbursementItem, a, b);
    }
}
exports.SalaryReimbursementItem = SalaryReimbursementItem;
/**
 *
 * Describes the message consisting of the list of salary reimbursement items
 *
 * @generated from message Scailo.SalariesReimbursementItemsList
 */
class SalariesReimbursementItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalaryReimbursementItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesReimbursementItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalaryReimbursementItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesReimbursementItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesReimbursementItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesReimbursementItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesReimbursementItemsList, a, b);
    }
}
exports.SalariesReimbursementItemsList = SalariesReimbursementItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the reimbursement record
 *
 * @generated from message Scailo.SalaryReimbursementItemHistoryRequest
 */
class SalaryReimbursementItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: int64 ref_id = 12;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryReimbursementItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryReimbursementItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryReimbursementItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryReimbursementItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryReimbursementItemHistoryRequest, a, b);
    }
}
exports.SalaryReimbursementItemHistoryRequest = SalaryReimbursementItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective salary reimbursement item
 *
 * @generated from message Scailo.SalaryReimbursementItemProspectiveInfoRequest
 */
class SalaryReimbursementItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the salary ID
     *
     * @generated from field: int64 salary_id = 10;
     */
    salaryId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 11;
     */
    refFrom = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalaryReimbursementItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "salary_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 11, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalaryReimbursementItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalaryReimbursementItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalaryReimbursementItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalaryReimbursementItemProspectiveInfoRequest, a, b);
    }
}
exports.SalaryReimbursementItemProspectiveInfoRequest = SalaryReimbursementItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalariesServicePaginationReq
 */
class SalariesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: int64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALARY_SORT_KEY sort_key = 5;
     */
    sortKey = SALARY_SORT_KEY.SALARY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this salary
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALARY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServicePaginationReq, a, b);
    }
}
exports.SalariesServicePaginationReq = SalariesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalariesServicePaginationResponse
 */
class SalariesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: int64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: int64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Salary payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 4, name: "payload", kind: "message", T: Salary, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServicePaginationResponse, a, b);
    }
}
exports.SalariesServicePaginationResponse = SalariesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalariesServiceFilterReq
 */
class SalariesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: int64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALARY_SORT_KEY sort_key = 5;
     */
    sortKey = SALARY_SORT_KEY.SALARY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this salary
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: int64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: int64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: int64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: int64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: int64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: int64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the salary
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The employee ID of the salary
     *
     * @generated from field: int64 employee_id = 22;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The bank account ID of the salary
     *
     * @generated from field: int64 bank_account_id = 23;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The currency ID of the salary
     *
     * @generated from field: int64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The payroll group ID of the salary
     *
     * @generated from field: int64 payroll_group_id = 25;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The tax group ID of the salary
     *
     * @generated from field: int64 tax_group_id = 26;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALARY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "employee_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "bank_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "payroll_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 26, name: "tax_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceFilterReq, a, b);
    }
}
exports.SalariesServiceFilterReq = SalariesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalariesServiceCountReq
 */
class SalariesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this salary
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: int64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: int64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: int64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: int64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: int64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: int64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the salary
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The employee ID of the salary
     *
     * @generated from field: int64 employee_id = 22;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The bank account ID of the salary
     *
     * @generated from field: int64 bank_account_id = 23;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The currency ID of the salary
     *
     * @generated from field: int64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The payroll group ID of the salary
     *
     * @generated from field: int64 payroll_group_id = 25;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The tax group ID of the salary
     *
     * @generated from field: int64 tax_group_id = 26;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "employee_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "bank_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "payroll_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 26, name: "tax_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceCountReq, a, b);
    }
}
exports.SalariesServiceCountReq = SalariesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalariesServiceSearchAllReq
 */
class SalariesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: int64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALARY_SORT_KEY sort_key = 5;
     */
    sortKey = SALARY_SORT_KEY.SALARY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalariesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALARY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalariesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalariesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalariesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalariesServiceSearchAllReq, a, b);
    }
}
exports.SalariesServiceSearchAllReq = SalariesServiceSearchAllReq;
