"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file users.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersServiceRegisterMobileDeviceRequest = exports.UsersServiceSearchAllReq = exports.UsersServiceCountReq = exports.UsersServiceFilterReq = exports.UsersServicePaginationResponse = exports.UsersServicePaginationReq = exports.UsersList = exports.UserPrimaryInfo = exports.User = exports.UsersServiceUpdateRequest = exports.UsersServiceCreateRequest = exports.USER_SORT_KEY = exports.USER_TYPE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available user types
 *
 * @generated from enum Scailo.USER_TYPE
 */
var USER_TYPE;
(function (USER_TYPE) {
    /**
     * Useful for filter and count operation when this field needs to be ignored
     *
     * @generated from enum value: USER_TYPE_ANY_UNSPECIFIED = 0;
     */
    USER_TYPE[USER_TYPE["USER_TYPE_ANY_UNSPECIFIED"] = 0] = "USER_TYPE_ANY_UNSPECIFIED";
    /**
     * Denotes that the user is an employee
     *
     * @generated from enum value: USER_TYPE_EMPLOYEE = 1;
     */
    USER_TYPE[USER_TYPE["USER_TYPE_EMPLOYEE"] = 1] = "USER_TYPE_EMPLOYEE";
    /**
     * Denotes that the user is a client
     *
     * @generated from enum value: USER_TYPE_CLIENT = 2;
     */
    USER_TYPE[USER_TYPE["USER_TYPE_CLIENT"] = 2] = "USER_TYPE_CLIENT";
    /**
     * Denotes that the user is a vendor
     *
     * @generated from enum value: USER_TYPE_VENDOR = 3;
     */
    USER_TYPE[USER_TYPE["USER_TYPE_VENDOR"] = 3] = "USER_TYPE_VENDOR";
})(USER_TYPE || (exports.USER_TYPE = USER_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(USER_TYPE)
protobuf_1.proto3.util.setEnumType(USER_TYPE, "Scailo.USER_TYPE", [
    { no: 0, name: "USER_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "USER_TYPE_EMPLOYEE" },
    { no: 2, name: "USER_TYPE_CLIENT" },
    { no: 3, name: "USER_TYPE_VENDOR" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.USER_SORT_KEY
 */
var USER_SORT_KEY;
(function (USER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: USER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "USER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: USER_SORT_KEY_CREATED_AT = 1;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_CREATED_AT"] = 1] = "USER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: USER_SORT_KEY_MODIFIED_AT = 2;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_MODIFIED_AT"] = 2] = "USER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: USER_SORT_KEY_APPROVED_ON = 3;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_APPROVED_ON"] = 3] = "USER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: USER_SORT_KEY_APPROVED_BY = 4;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_APPROVED_BY"] = 4] = "USER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: USER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "USER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the username
     *
     * @generated from enum value: USER_SORT_KEY_USERNAME = 10;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_USERNAME"] = 10] = "USER_SORT_KEY_USERNAME";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: USER_SORT_KEY_NAME = 11;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_NAME"] = 11] = "USER_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: USER_SORT_KEY_CODE = 12;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_CODE"] = 12] = "USER_SORT_KEY_CODE";
    /**
     * Fetch ordered results by the email address
     *
     * @generated from enum value: USER_SORT_KEY_EMAIL = 13;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_EMAIL"] = 13] = "USER_SORT_KEY_EMAIL";
    /**
     * Fetch ordered results by the phone number
     *
     * @generated from enum value: USER_SORT_KEY_PHONE = 14;
     */
    USER_SORT_KEY[USER_SORT_KEY["USER_SORT_KEY_PHONE"] = 14] = "USER_SORT_KEY_PHONE";
})(USER_SORT_KEY || (exports.USER_SORT_KEY = USER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(USER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(USER_SORT_KEY, "Scailo.USER_SORT_KEY", [
    { no: 0, name: "USER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "USER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "USER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "USER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "USER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "USER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "USER_SORT_KEY_USERNAME" },
    { no: 11, name: "USER_SORT_KEY_NAME" },
    { no: 12, name: "USER_SORT_KEY_CODE" },
    { no: 13, name: "USER_SORT_KEY_EMAIL" },
    { no: 14, name: "USER_SORT_KEY_PHONE" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.UsersServiceCreateRequest
 */
class UsersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The username of the user
     *
     * @generated from field: string username = 10;
     */
    username = "";
    /**
     * The unique employee code by which the user is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * The plain text password using which the user can login
     *
     * @generated from field: string plain_text_password = 13;
     */
    plainTextPassword = "";
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 14;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated mobile role ID
     *
     * @generated from field: uint64 mobile_role_id = 15;
     */
    mobileRoleId = protobuf_1.protoInt64.zero;
    /**
     * The primary email of the user
     *
     * @generated from field: string email = 16;
     */
    email = "";
    /**
     * The optional work email of the user
     *
     * @generated from field: string work_email = 17;
     */
    workEmail = "";
    /**
     * The primary contact number of the user
     *
     * @generated from field: string phone = 18;
     */
    phone = "";
    /**
     * The birthday of the user
     *
     * @generated from field: string birthday = 30;
     */
    birthday = "";
    /**
     * The joining date of the user
     *
     * @generated from field: string joining_date = 31;
     */
    joiningDate = "";
    /**
     * The address of the user
     *
     * @generated from field: string address = 32;
     */
    address = "";
    /**
     * The city of residence
     *
     * @generated from field: string city = 33;
     */
    city = "";
    /**
     * The state of residence
     *
     * @generated from field: string state = 34;
     */
    state = "";
    /**
     * The country of residence
     *
     * @generated from field: string country = 35;
     */
    country = "";
    /**
     * THe PIN Code of residence
     *
     * @generated from field: string pin_code = 36;
     */
    pinCode = "";
    /**
     * THe Blood Group of the user
     *
     * @generated from field: string blood_group = 37;
     */
    bloodGroup = "";
    /**
     * The associated shift group ID
     *
     * @generated from field: uint64 shift_group_id = 50;
     */
    shiftGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material of the user's attendance record
     *
     * @generated from field: uint64 attendance_uom_id = 51;
     */
    attendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated department (can be 0 to allow seamless transition)
     *
     * @generated from field: uint64 department_id = 52;
     */
    departmentId = protobuf_1.protoInt64.zero;
    /**
     * The associated payroll group ID of the user
     *
     * @generated from field: uint64 payroll_group_id = 53;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID using which the user's payroll needs to be calculated
     *
     * @generated from field: uint64 payroll_tax_group_id = 54;
     */
    payrollTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency ID of the user's payroll
     *
     * @generated from field: uint64 payroll_currency_id = 55;
     */
    payrollCurrencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount of the user (in cents)
     *
     * @generated from field: uint64 basic_pay_amount = 56;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material for storing the basic pay amount
     *
     * @generated from field: uint64 basic_pay_uom_id = 57;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 70;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "mobile_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "joining_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 33, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 34, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "pin_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "blood_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "shift_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "attendance_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "department_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 54, name: "payroll_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "payroll_currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 56, name: "basic_pay_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 57, name: "basic_pay_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceCreateRequest, a, b);
    }
}
exports.UsersServiceCreateRequest = UsersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.UsersServiceUpdateRequest
 */
class UsersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The unique employee code by which the user is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 14;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated mobile role ID
     *
     * @generated from field: uint64 mobile_role_id = 15;
     */
    mobileRoleId = protobuf_1.protoInt64.zero;
    /**
     * The primary email of the user
     *
     * @generated from field: string email = 16;
     */
    email = "";
    /**
     * The optional work email of the user
     *
     * @generated from field: string work_email = 17;
     */
    workEmail = "";
    /**
     * The primary contact number of the user
     *
     * @generated from field: string phone = 18;
     */
    phone = "";
    /**
     * The birthday of the user
     *
     * @generated from field: string birthday = 30;
     */
    birthday = "";
    /**
     * The joining date of the user
     *
     * @generated from field: string joining_date = 31;
     */
    joiningDate = "";
    /**
     * The address of the user
     *
     * @generated from field: string address = 32;
     */
    address = "";
    /**
     * The city of residence
     *
     * @generated from field: string city = 33;
     */
    city = "";
    /**
     * The state of residence
     *
     * @generated from field: string state = 34;
     */
    state = "";
    /**
     * The country of residence
     *
     * @generated from field: string country = 35;
     */
    country = "";
    /**
     * THe PIN Code of residence
     *
     * @generated from field: string pin_code = 36;
     */
    pinCode = "";
    /**
     * THe Blood Group of the user
     *
     * @generated from field: string blood_group = 37;
     */
    bloodGroup = "";
    /**
     * The associated shift group ID
     *
     * @generated from field: uint64 shift_group_id = 50;
     */
    shiftGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material of the user's attendance record
     *
     * @generated from field: uint64 attendance_uom_id = 51;
     */
    attendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated department (can be 0 to allow seamless transition)
     *
     * @generated from field: uint64 department_id = 52;
     */
    departmentId = protobuf_1.protoInt64.zero;
    /**
     * The associated payroll group ID of the user
     *
     * @generated from field: uint64 payroll_group_id = 53;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID using which the user's payroll needs to be calculated
     *
     * @generated from field: uint64 payroll_tax_group_id = 54;
     */
    payrollTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency ID of the user's payroll
     *
     * @generated from field: uint64 payroll_currency_id = 55;
     */
    payrollCurrencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount of the user (in cents)
     *
     * @generated from field: uint64 basic_pay_amount = 56;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material for storing the basic pay amount
     *
     * @generated from field: uint64 basic_pay_uom_id = 57;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 70;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "mobile_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "joining_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 33, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 34, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "pin_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "blood_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "shift_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "attendance_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "department_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 54, name: "payroll_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "payroll_currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 56, name: "basic_pay_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 57, name: "basic_pay_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceUpdateRequest, a, b);
    }
}
exports.UsersServiceUpdateRequest = UsersServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.User
 */
class User extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this user
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this user
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The username of the user
     *
     * @generated from field: string username = 10;
     */
    username = "";
    /**
     * The unique employee code by which the user is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 14;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated mobile role ID
     *
     * @generated from field: uint64 mobile_role_id = 15;
     */
    mobileRoleId = protobuf_1.protoInt64.zero;
    /**
     * The primary email of the user
     *
     * @generated from field: string email = 16;
     */
    email = "";
    /**
     * The optional work email of the user
     *
     * @generated from field: string work_email = 17;
     */
    workEmail = "";
    /**
     * The primary contact number of the user
     *
     * @generated from field: string phone = 18;
     */
    phone = "";
    /**
     * The birthday of the user
     *
     * @generated from field: string birthday = 30;
     */
    birthday = "";
    /**
     * The joining date of the user
     *
     * @generated from field: string joining_date = 31;
     */
    joiningDate = "";
    /**
     * The address of the user
     *
     * @generated from field: string address = 32;
     */
    address = "";
    /**
     * The city of residence
     *
     * @generated from field: string city = 33;
     */
    city = "";
    /**
     * The state of residence
     *
     * @generated from field: string state = 34;
     */
    state = "";
    /**
     * The country of residence
     *
     * @generated from field: string country = 35;
     */
    country = "";
    /**
     * THe PIN Code of residence
     *
     * @generated from field: string pin_code = 36;
     */
    pinCode = "";
    /**
     * THe Blood Group of the user
     *
     * @generated from field: string blood_group = 37;
     */
    bloodGroup = "";
    /**
     * The associated shift group ID
     *
     * @generated from field: uint64 shift_group_id = 50;
     */
    shiftGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material of the user's attendance record
     *
     * @generated from field: uint64 attendance_uom_id = 51;
     */
    attendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated department
     *
     * @generated from field: uint64 department_id = 52;
     */
    departmentId = protobuf_1.protoInt64.zero;
    /**
     * The associated payroll group ID of the user
     *
     * @generated from field: uint64 payroll_group_id = 53;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID using which the user's payroll needs to be calculated
     *
     * @generated from field: uint64 payroll_tax_group_id = 54;
     */
    payrollTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency ID of the user's payroll
     *
     * @generated from field: uint64 payroll_currency_id = 55;
     */
    payrollCurrencyId = protobuf_1.protoInt64.zero;
    /**
     * The basic pay amount of the user (in cents)
     *
     * @generated from field: uint64 basic_pay_amount = 56;
     */
    basicPayAmount = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material for storing the basic pay amount
     *
     * @generated from field: uint64 basic_pay_uom_id = 57;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores if MFA has been enabled by the user
     *
     * @generated from field: bool mfa_status = 60;
     */
    mfaStatus = false;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 70;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.User";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "mobile_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "joining_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 33, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 34, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 35, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "pin_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "blood_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "shift_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "attendance_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "department_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 54, name: "payroll_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 55, name: "payroll_currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 56, name: "basic_pay_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 57, name: "basic_pay_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "mfa_status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 70, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new User().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new User().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new User().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(User, a, b);
    }
}
exports.User = User;
/**
 *
 * Describes the message that is used internally to validate user
 *
 * @generated from message Scailo.UserPrimaryInfo
 */
class UserPrimaryInfo extends protobuf_1.Message {
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * The username of the user
     *
     * @generated from field: string username = 10;
     */
    username = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores the hashed password
     *
     * @generated from field: bytes password = 12;
     */
    password = new Uint8Array(0);
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 13;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated mobile role ID
     *
     * @generated from field: uint64 mobile_role_id = 14;
     */
    mobileRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores if MFA has been enabled by the user
     *
     * @generated from field: bool mfa_status = 15;
     */
    mfaStatus = false;
    /**
     * Stores the MFA secret
     *
     * @generated from field: bytes mfa_secret = 16;
     */
    mfaSecret = new Uint8Array(0);
    /**
     * THe Blood Group of the user
     *
     * @generated from field: string blood_group = 20;
     */
    bloodGroup = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UserPrimaryInfo";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 10, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "password", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 13, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "mobile_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "mfa_status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 16, name: "mfa_secret", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 20, name: "blood_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UserPrimaryInfo().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UserPrimaryInfo().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UserPrimaryInfo().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UserPrimaryInfo, a, b);
    }
}
exports.UserPrimaryInfo = UserPrimaryInfo;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.UsersList
 */
class UsersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.User list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: User, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UsersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersList, a, b);
    }
}
exports.UsersList = UsersList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.UsersServicePaginationReq
 */
class UsersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.USER_SORT_KEY sort_key = 5;
     */
    sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this user
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServicePaginationReq, a, b);
    }
}
exports.UsersServicePaginationReq = UsersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.UsersServicePaginationResponse
 */
class UsersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.User payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: User, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServicePaginationResponse, a, b);
    }
}
exports.UsersServicePaginationResponse = UsersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.UsersServiceFilterReq
 */
class UsersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.USER_SORT_KEY sort_key = 5;
     */
    sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this user
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The username of the user
     *
     * @generated from field: string username = 20;
     */
    username = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 21;
     */
    name = "";
    /**
     * The unique code by which the user is classified
     *
     * @generated from field: string code = 22;
     */
    code = "";
    /**
     * The primary email of the user
     *
     * @generated from field: string email = 23;
     */
    email = "";
    /**
     * The primary contact number of the user
     *
     * @generated from field: string phone = 24;
     */
    phone = "";
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 25;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated shift group ID
     *
     * @generated from field: uint64 shift_group_id = 26;
     */
    shiftGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material of the user's attendance record
     *
     * @generated from field: uint64 attendance_uom_id = 27;
     */
    attendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated department
     *
     * @generated from field: uint64 department_id = 28;
     */
    departmentId = protobuf_1.protoInt64.zero;
    /**
     * The associated payroll group ID of the user
     *
     * @generated from field: uint64 payroll_group_id = 29;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID using which the user's payroll needs to be calculated
     *
     * @generated from field: uint64 payroll_tax_group_id = 30;
     */
    payrollTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency ID of the user's payroll
     *
     * @generated from field: uint64 payroll_currency_id = 31;
     */
    payrollCurrencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material for storing the basic pay amount
     *
     * @generated from field: uint64 basic_pay_uom_id = 32;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The optional work email of the user
     *
     * @generated from field: string work_email = 33;
     */
    workEmail = "";
    /**
     * --------------------------------------------------------------------------------
     * Filter by the associated vendor ID (return all the users that belong to this vendor)
     *
     * @generated from field: uint64 vendor_id = 70;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client ID (return all the users that belong to this client)
     *
     * --------------------------------------------------------------------------------
     *
     * @generated from field: uint64 client_id = 71;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "shift_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "attendance_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "department_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "payroll_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "payroll_currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "basic_pay_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 71, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceFilterReq, a, b);
    }
}
exports.UsersServiceFilterReq = UsersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.UsersServiceCountReq
 */
class UsersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this user
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The username of the user
     *
     * @generated from field: string username = 20;
     */
    username = "";
    /**
     * The name of the user
     *
     * @generated from field: string name = 21;
     */
    name = "";
    /**
     * The unique code by which the user is classified
     *
     * @generated from field: string code = 22;
     */
    code = "";
    /**
     * The primary email of the user
     *
     * @generated from field: string email = 23;
     */
    email = "";
    /**
     * The primary contact number of the user
     *
     * @generated from field: string phone = 24;
     */
    phone = "";
    /**
     * The associated role ID
     *
     * @generated from field: uint64 role_id = 25;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The associated shift group ID
     *
     * @generated from field: uint64 shift_group_id = 26;
     */
    shiftGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material of the user's attendance record
     *
     * @generated from field: uint64 attendance_uom_id = 27;
     */
    attendanceUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated department
     *
     * @generated from field: uint64 department_id = 28;
     */
    departmentId = protobuf_1.protoInt64.zero;
    /**
     * The associated payroll group ID of the user
     *
     * @generated from field: uint64 payroll_group_id = 29;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID using which the user's payroll needs to be calculated
     *
     * @generated from field: uint64 payroll_tax_group_id = 30;
     */
    payrollTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency ID of the user's payroll
     *
     * @generated from field: uint64 payroll_currency_id = 31;
     */
    payrollCurrencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated unit of material for storing the basic pay amount
     *
     * @generated from field: uint64 basic_pay_uom_id = 32;
     */
    basicPayUomId = protobuf_1.protoInt64.zero;
    /**
     * The optional work email of the user
     *
     * @generated from field: string work_email = 33;
     */
    workEmail = "";
    /**
     * --------------------------------------------------------------------------------
     * Filter by the associated vendor ID (return all the users that belong to this vendor)
     *
     * @generated from field: uint64 vendor_id = 70;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client ID (return all the users that belong to this client)
     *
     * --------------------------------------------------------------------------------
     *
     * @generated from field: uint64 client_id = 71;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "shift_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "attendance_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "department_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "payroll_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "payroll_currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "basic_pay_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 71, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceCountReq, a, b);
    }
}
exports.UsersServiceCountReq = UsersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.UsersServiceSearchAllReq
 */
class UsersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.USER_SORT_KEY sort_key = 5;
     */
    sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Stores the user type
     *
     * @generated from field: Scailo.USER_TYPE user_type = 7;
     */
    userType = USER_TYPE.USER_TYPE_ANY_UNSPECIFIED;
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * --------------------------------------------------------------------------------
     * Filter by the associated vendor ID (return all the users that belong to this vendor)
     *
     * @generated from field: uint64 vendor_id = 70;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client ID (return all the users that belong to this client)
     *
     * --------------------------------------------------------------------------------
     *
     * @generated from field: uint64 client_id = 71;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "user_type", kind: "enum", T: protobuf_1.proto3.getEnumType(USER_TYPE) },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 71, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceSearchAllReq, a, b);
    }
}
exports.UsersServiceSearchAllReq = UsersServiceSearchAllReq;
/**
 *
 * Describes the message that is required to register a user's device
 *
 * @generated from message Scailo.UsersServiceRegisterMobileDeviceRequest
 */
class UsersServiceRegisterMobileDeviceRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * The ID of the user who has possession of the device
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The operating system of the device
     *
     * @generated from field: string device_os = 12;
     */
    deviceOs = "";
    /**
     * The generated FCM Token
     *
     * @generated from field: string device_token = 13;
     */
    deviceToken = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UsersServiceRegisterMobileDeviceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "device_os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UsersServiceRegisterMobileDeviceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsersServiceRegisterMobileDeviceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsersServiceRegisterMobileDeviceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UsersServiceRegisterMobileDeviceRequest, a, b);
    }
}
exports.UsersServiceRegisterMobileDeviceRequest = UsersServiceRegisterMobileDeviceRequest;
