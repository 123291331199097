"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file quotations_responses.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotationsResponsesService = void 0;
const quotations_responses_scailo_pb_js_1 = require("./quotations_responses.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each quotation response
 *
 * @generated from service Scailo.QuotationsResponsesService
 */
exports.QuotationsResponsesService = {
    typeName: "Scailo.QuotationsResponsesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Create
         */
        create: {
            name: "Create",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Draft
         */
        draft: {
            name: "Draft",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.QuotationsResponsesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.QuotationsResponsesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.QuotationsResponsesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.QuotationsResponsesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.QuotationsResponsesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a quotation response
         *
         * @generated from rpc Scailo.QuotationsResponsesService.AddQuotationResponseItem
         */
        addQuotationResponseItem: {
            name: "AddQuotationResponseItem",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a quotation response
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ModifyQuotationResponseItem
         */
        modifyQuotationResponseItem: {
            name: "ModifyQuotationResponseItem",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a quotation response
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ApproveQuotationResponseItem
         */
        approveQuotationResponseItem: {
            name: "ApproveQuotationResponseItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a quotation response
         *
         * @generated from rpc Scailo.QuotationsResponsesService.DeleteQuotationResponseItem
         */
        deleteQuotationResponseItem: {
            name: "DeleteQuotationResponseItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a quotation response
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ReorderQuotationResponseItems
         */
        reorderQuotationResponseItems: {
            name: "ReorderQuotationResponseItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View quotation response Item by ID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewQuotationResponseItemByID
         */
        viewQuotationResponseItemByID: {
            name: "ViewQuotationResponseItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: quotations_responses_scailo_pb_js_1.QuotationResponseItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved quotation response items for given quotation response ID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewApprovedQuotationResponseItems
         */
        viewApprovedQuotationResponseItems: {
            name: "ViewApprovedQuotationResponseItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved quotation response items for given quotation response ID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewUnapprovedQuotationResponseItems
         */
        viewUnapprovedQuotationResponseItems: {
            name: "ViewUnapprovedQuotationResponseItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the quotation response item
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewQuotationResponseItemHistory
         */
        viewQuotationResponseItemHistory: {
            name: "ViewQuotationResponseItemHistory",
            I: quotations_responses_scailo_pb_js_1.QuotationResponseItemHistoryRequest,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the quotation response items that match the given filter criteria
         *
         * @generated from rpc Scailo.QuotationsResponsesService.FilterItems
         */
        filterItems: {
            name: "FilterItems",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceItemsFilterReq,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved quotation response items for given quotation response ID with pagination
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewPaginatedApprovedQuotationResponseItems
         */
        viewPaginatedApprovedQuotationResponseItems: {
            name: "ViewPaginatedApprovedQuotationResponseItems",
            I: quotations_responses_scailo_pb_js_1.QuotationResponseItemsSearchRequest,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved quotation response items for given quotation response ID with pagination
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewPaginatedUnapprovedQuotationResponseItems
         */
        viewPaginatedUnapprovedQuotationResponseItems: {
            name: "ViewPaginatedUnapprovedQuotationResponseItems",
            I: quotations_responses_scailo_pb_js_1.QuotationResponseItemsSearchRequest,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through quotation response items with pagination
         *
         * @generated from rpc Scailo.QuotationsResponsesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: quotations_responses_scailo_pb_js_1.QuotationResponseItemsSearchRequest,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: quotations_responses_scailo_pb_js_1.QuotationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_responses_scailo_pb_js_1.QuotationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_responses_scailo_pb_js_1.QuotationResponseAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.QuotationsResponsesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServicePaginationReq,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download quotation response with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.QuotationsResponsesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.QuotationsResponsesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceSearchAllReq,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Filter
         */
        filter: {
            name: "Filter",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceFilterReq,
            O: quotations_responses_scailo_pb_js_1.QuotationsResponsesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.QuotationsResponsesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.QuotationsResponsesService.Count
         */
        count: {
            name: "Count",
            I: quotations_responses_scailo_pb_js_1.QuotationsResponsesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
