"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file work_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrdersServicePaginatedItemsResponse = exports.WorkOrderItemsSearchRequest = exports.WorkOrdersServiceSearchAllReq = exports.WorkOrdersServiceCountReq = exports.WorkOrdersServiceFilterReq = exports.WorkOrdersServicePaginationResponse = exports.WorkOrdersServicePaginationReq = exports.WorkOrderItemProspectiveInfoRequest = exports.WorkOrderItemHistoryRequest = exports.WorkOrdersItemsList = exports.WorkOrdersList = exports.WorkOrderItem = exports.WorkOrdersServiceItemUpdateRequest = exports.WorkOrdersServiceItemCreateRequest = exports.WorkOrder = exports.WorkOrdersServiceAutofillRequest = exports.WorkOrdersServiceUpdateRequest = exports.WorkOrdersServiceCreateRequest = exports.WORK_ORDER_ITEM_STATUS = exports.WORK_ORDER_ITEM_SORT_KEY = exports.WORK_ORDER_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.WORK_ORDER_SORT_KEY
 */
var WORK_ORDER_SORT_KEY;
(function (WORK_ORDER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "WORK_ORDER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_CREATED_AT = 1;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_CREATED_AT"] = 1] = "WORK_ORDER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_MODIFIED_AT = 2;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_MODIFIED_AT"] = 2] = "WORK_ORDER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_APPROVED_ON = 3;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_APPROVED_ON"] = 3] = "WORK_ORDER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_APPROVED_BY = 4;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_APPROVED_BY"] = 4] = "WORK_ORDER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_COMPLETED_ON = 6;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_COMPLETED_ON"] = 6] = "WORK_ORDER_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_REFERENCE_ID = 10;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_REFERENCE_ID"] = 10] = "WORK_ORDER_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "WORK_ORDER_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: WORK_ORDER_SORT_KEY_LOCATION_ID = 12;
     */
    WORK_ORDER_SORT_KEY[WORK_ORDER_SORT_KEY["WORK_ORDER_SORT_KEY_LOCATION_ID"] = 12] = "WORK_ORDER_SORT_KEY_LOCATION_ID";
})(WORK_ORDER_SORT_KEY || (exports.WORK_ORDER_SORT_KEY = WORK_ORDER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORK_ORDER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(WORK_ORDER_SORT_KEY, "Scailo.WORK_ORDER_SORT_KEY", [
    { no: 0, name: "WORK_ORDER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "WORK_ORDER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "WORK_ORDER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "WORK_ORDER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "WORK_ORDER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "WORK_ORDER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "WORK_ORDER_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "WORK_ORDER_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "WORK_ORDER_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "WORK_ORDER_SORT_KEY_LOCATION_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.WORK_ORDER_ITEM_SORT_KEY
 */
var WORK_ORDER_ITEM_SORT_KEY;
(function (WORK_ORDER_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_CREATED_AT"] = 1] = "WORK_ORDER_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the quantity
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_QUANTITY = 11;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_QUANTITY"] = 11] = "WORK_ORDER_ITEM_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: WORK_ORDER_ITEM_SORT_KEY_DELIVERY_DATE = 12;
     */
    WORK_ORDER_ITEM_SORT_KEY[WORK_ORDER_ITEM_SORT_KEY["WORK_ORDER_ITEM_SORT_KEY_DELIVERY_DATE"] = 12] = "WORK_ORDER_ITEM_SORT_KEY_DELIVERY_DATE";
})(WORK_ORDER_ITEM_SORT_KEY || (exports.WORK_ORDER_ITEM_SORT_KEY = WORK_ORDER_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORK_ORDER_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(WORK_ORDER_ITEM_SORT_KEY, "Scailo.WORK_ORDER_ITEM_SORT_KEY", [
    { no: 0, name: "WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "WORK_ORDER_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "WORK_ORDER_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "WORK_ORDER_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "WORK_ORDER_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "WORK_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "WORK_ORDER_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "WORK_ORDER_ITEM_SORT_KEY_QUANTITY" },
    { no: 12, name: "WORK_ORDER_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of work order items
 *
 * @generated from enum Scailo.WORK_ORDER_ITEM_STATUS
 */
var WORK_ORDER_ITEM_STATUS;
(function (WORK_ORDER_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    WORK_ORDER_ITEM_STATUS[WORK_ORDER_ITEM_STATUS["WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the work order items must have been approved
     *
     * @generated from enum value: WORK_ORDER_ITEM_STATUS_APPROVED = 1;
     */
    WORK_ORDER_ITEM_STATUS[WORK_ORDER_ITEM_STATUS["WORK_ORDER_ITEM_STATUS_APPROVED"] = 1] = "WORK_ORDER_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the work order items must be waiting for approval
     *
     * @generated from enum value: WORK_ORDER_ITEM_STATUS_UNAPPROVED = 2;
     */
    WORK_ORDER_ITEM_STATUS[WORK_ORDER_ITEM_STATUS["WORK_ORDER_ITEM_STATUS_UNAPPROVED"] = 2] = "WORK_ORDER_ITEM_STATUS_UNAPPROVED";
})(WORK_ORDER_ITEM_STATUS || (exports.WORK_ORDER_ITEM_STATUS = WORK_ORDER_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORK_ORDER_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(WORK_ORDER_ITEM_STATUS, "Scailo.WORK_ORDER_ITEM_STATUS", [
    { no: 0, name: "WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "WORK_ORDER_ITEM_STATUS_APPROVED" },
    { no: 2, name: "WORK_ORDER_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.WorkOrdersServiceCreateRequest
 */
class WorkOrdersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the work order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceCreateRequest, a, b);
    }
}
exports.WorkOrdersServiceCreateRequest = WorkOrdersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.WorkOrdersServiceUpdateRequest
 */
class WorkOrdersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the work order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceUpdateRequest, a, b);
    }
}
exports.WorkOrdersServiceUpdateRequest = WorkOrdersServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.WorkOrdersServiceAutofillRequest
 */
class WorkOrdersServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Denotes if all the equation dependencies (if applicable) should also be automatically added to the work order
     *
     * @generated from field: bool populate_using_equation_dependencies = 3;
     */
    populateUsingEquationDependencies = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "populate_using_equation_dependencies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceAutofillRequest, a, b);
    }
}
exports.WorkOrdersServiceAutofillRequest = WorkOrdersServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.WorkOrder
 */
class WorkOrder extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this work order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this work order
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this work order was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the work order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated work order items
     *
     * @generated from field: repeated Scailo.WorkOrderItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrder";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: WorkOrderItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrder, a, b);
    }
}
exports.WorkOrder = WorkOrder;
/**
 *
 * Describes the parameters required to add an item to a work order
 *
 * @generated from message Scailo.WorkOrdersServiceItemCreateRequest
 */
class WorkOrdersServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the work order ID
     *
     * @generated from field: uint64 work_order_id = 10;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceItemCreateRequest, a, b);
    }
}
exports.WorkOrdersServiceItemCreateRequest = WorkOrdersServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a work order
 *
 * @generated from message Scailo.WorkOrdersServiceItemUpdateRequest
 */
class WorkOrdersServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceItemUpdateRequest, a, b);
    }
}
exports.WorkOrdersServiceItemUpdateRequest = WorkOrdersServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a work order
 *
 * @generated from message Scailo.WorkOrderItem
 */
class WorkOrderItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this work order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the work order ID
     *
     * @generated from field: uint64 work_order_id = 10;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrderItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrderItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrderItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrderItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrderItem, a, b);
    }
}
exports.WorkOrderItem = WorkOrderItem;
/**
 *
 * Describes the message consisting of the list of work orders
 *
 * @generated from message Scailo.WorkOrdersList
 */
class WorkOrdersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.WorkOrder list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: WorkOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersList, a, b);
    }
}
exports.WorkOrdersList = WorkOrdersList;
/**
 *
 * Describes the message consisting of the list of work order items
 *
 * @generated from message Scailo.WorkOrdersItemsList
 */
class WorkOrdersItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.WorkOrderItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: WorkOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersItemsList, a, b);
    }
}
exports.WorkOrdersItemsList = WorkOrdersItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.WorkOrderItemHistoryRequest
 */
class WorkOrderItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the work order ID
     *
     * @generated from field: uint64 work_order_id = 10;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrderItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrderItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrderItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrderItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrderItemHistoryRequest, a, b);
    }
}
exports.WorkOrderItemHistoryRequest = WorkOrderItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective work order item
 *
 * @generated from message Scailo.WorkOrderItemProspectiveInfoRequest
 */
class WorkOrderItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the work order ID
     *
     * @generated from field: uint64 work_order_id = 10;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrderItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrderItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrderItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrderItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrderItemProspectiveInfoRequest, a, b);
    }
}
exports.WorkOrderItemProspectiveInfoRequest = WorkOrderItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.WorkOrdersServicePaginationReq
 */
class WorkOrdersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = WORK_ORDER_SORT_KEY.WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORK_ORDER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServicePaginationReq, a, b);
    }
}
exports.WorkOrdersServicePaginationReq = WorkOrdersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.WorkOrdersServicePaginationResponse
 */
class WorkOrdersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.WorkOrder payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: WorkOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServicePaginationResponse, a, b);
    }
}
exports.WorkOrdersServicePaginationResponse = WorkOrdersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.WorkOrdersServiceFilterReq
 */
class WorkOrdersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = WORK_ORDER_SORT_KEY.WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the work order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORK_ORDER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceFilterReq, a, b);
    }
}
exports.WorkOrdersServiceFilterReq = WorkOrdersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.WorkOrdersServiceCountReq
 */
class WorkOrdersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this work order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the work order
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the work order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceCountReq, a, b);
    }
}
exports.WorkOrdersServiceCountReq = WorkOrdersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.WorkOrdersServiceSearchAllReq
 */
class WorkOrdersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORK_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = WORK_ORDER_SORT_KEY.WORK_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORK_ORDER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServiceSearchAllReq, a, b);
    }
}
exports.WorkOrdersServiceSearchAllReq = WorkOrdersServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.WorkOrderItemsSearchRequest
 */
class WorkOrderItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORK_ORDER_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = WORK_ORDER_ITEM_SORT_KEY.WORK_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.WORK_ORDER_ITEM_STATUS status = 7;
     */
    status = WORK_ORDER_ITEM_STATUS.WORK_ORDER_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the work order
     *
     * @generated from field: uint64 work_order_id = 20;
     */
    workOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the work order
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the work order
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the work order
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrderItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORK_ORDER_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(WORK_ORDER_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "work_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrderItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrderItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrderItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrderItemsSearchRequest, a, b);
    }
}
exports.WorkOrderItemsSearchRequest = WorkOrderItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.WorkOrdersServicePaginatedItemsResponse
 */
class WorkOrdersServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.WorkOrderItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkOrdersServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: WorkOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkOrdersServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkOrdersServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkOrdersServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkOrdersServicePaginatedItemsResponse, a, b);
    }
}
exports.WorkOrdersServicePaginatedItemsResponse = WorkOrdersServicePaginatedItemsResponse;
