"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file forms_fields_data.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsFieldsDataService = void 0;
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each form
 *
 * @generated from service Scailo.FormsFieldsDataService
 */
exports.FormsFieldsDataService = {
    typeName: "Scailo.FormsFieldsDataService",
    methods: {
        /**
         * View the historical values of the given form data
         *
         * @generated from rpc Scailo.FormsFieldsDataService.ViewFormFieldDataHistory
         */
        viewFormFieldDataHistory: {
            name: "ViewFormFieldDataHistory",
            I: forms_fields_data_scailo_pb_js_1.FormFieldDatumHistoryRequest,
            O: forms_fields_data_scailo_pb_js_1.FormFieldDatumList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
