import { inwardJobFiltersMenu, inwardJobInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleInwardJobFilters } from "./inwardjobs.filters";
import { handleInwardJobInsights } from "./inwardjobs.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(inwardJobFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFiltersMenu)) {
            handleInwardJobFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(inwardJobInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobInsightsMenu)) {
            handleInwardJobInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}