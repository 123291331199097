"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file skills_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkillsGroupsService = void 0;
const skills_groups_scailo_pb_js_1 = require("./skills_groups.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each skill group
 *
 * @generated from service Scailo.SkillsGroupsService
 */
exports.SkillsGroupsService = {
    typeName: "Scailo.SkillsGroupsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SkillsGroupsService.Create
         */
        create: {
            name: "Create",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SkillsGroupsService.Draft
         */
        draft: {
            name: "Draft",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SkillsGroupsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SkillsGroupsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SkillsGroupsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SkillsGroupsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SkillsGroupsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SkillsGroupsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SkillsGroupsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SkillsGroupsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SkillsGroupsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SkillsGroupsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SkillsGroupsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SkillsGroupsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (Identifier);
         *
         * @generated from rpc Scailo.SkillsGroupsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone skill group from an existing skill group (denoted by the identifier)
         *
         * @generated from rpc Scailo.SkillsGroupsService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a param to a skill group
         *
         * @generated from rpc Scailo.SkillsGroupsService.AddSkillGroupItem
         */
        addSkillGroupItem: {
            name: "AddSkillGroupItem",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a param in a skill group
         *
         * @generated from rpc Scailo.SkillsGroupsService.ModifySkillGroupItem
         */
        modifySkillGroupItem: {
            name: "ModifySkillGroupItem",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a param in a skill group
         *
         * @generated from rpc Scailo.SkillsGroupsService.ApproveSkillGroupItem
         */
        approveSkillGroupItem: {
            name: "ApproveSkillGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a param in a skill group
         *
         * @generated from rpc Scailo.SkillsGroupsService.DeleteSkillGroupItem
         */
        deleteSkillGroupItem: {
            name: "DeleteSkillGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder params in a skill group
         *
         * @generated from rpc Scailo.SkillsGroupsService.ReorderSkillGroupItems
         */
        reorderSkillGroupItems: {
            name: "ReorderSkillGroupItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Skill Group Item by ID
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewSkillGroupItemByID
         */
        viewSkillGroupItemByID: {
            name: "ViewSkillGroupItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: skills_groups_scailo_pb_js_1.SkillGroupItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved skill group params for given skill group ID
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewApprovedSkillGroupItems
         */
        viewApprovedSkillGroupItems: {
            name: "ViewApprovedSkillGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved skill group params for given skill group ID
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewUnapprovedSkillGroupItems
         */
        viewUnapprovedSkillGroupItems: {
            name: "ViewUnapprovedSkillGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the skill group item
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewSkillGroupItemHistory
         */
        viewSkillGroupItemHistory: {
            name: "ViewSkillGroupItemHistory",
            I: skills_groups_scailo_pb_js_1.SkillGroupItemHistoryRequest,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: skills_groups_scailo_pb_js_1.SkillGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServicePaginationReq,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the latest skill group for a role (denoted by the given identifier)
         *
         * @generated from rpc Scailo.SkillsGroupsService.ViewForRoleID
         */
        viewForRoleID: {
            name: "ViewForRoleID",
            I: base_scailo_pb_js_1.Identifier,
            O: skills_groups_scailo_pb_js_1.SkillGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SkillsGroupsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceSearchAllReq,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SkillsGroupsService.Filter
         */
        filter: {
            name: "Filter",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceFilterReq,
            O: skills_groups_scailo_pb_js_1.SkillsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SkillsGroupsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SkillsGroupsService.Count
         */
        count: {
            name: "Count",
            I: skills_groups_scailo_pb_js_1.SkillsGroupsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
