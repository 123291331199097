"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vault_folders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultFolderDownload = exports.VaultFoldersList = exports.VaultFolder = exports.VaultFolderRenameFolderRequest = exports.VaultFolderMoveFolderRequest = exports.VaultFolderAddRequest = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const vault_commons_scailo_pb_js_1 = require("./vault_commons.scailo_pb.js");
/**
 *
 * Describes the parameters necessary to add a vault folder
 *
 * @generated from message Scailo.VaultFolderAddRequest
 */
class VaultFolderAddRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the folder
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The UUID of the parent folder
     *
     * @generated from field: string parent_folder_uuid = 11;
     */
    parentFolderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFolderAddRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "parent_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFolderAddRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFolderAddRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFolderAddRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFolderAddRequest, a, b);
    }
}
exports.VaultFolderAddRequest = VaultFolderAddRequest;
/**
 *
 * Describes the parameters necessary to move a vault folder
 *
 * @generated from message Scailo.VaultFolderMoveFolderRequest
 */
class VaultFolderMoveFolderRequest extends protobuf_1.Message {
    /**
     * The UUID of the folder
     *
     * @generated from field: string source_folder_uuid = 1;
     */
    sourceFolderUuid = "";
    /**
     * The UUID of the destination folder that the folder needs to be moved into
     *
     * @generated from field: string destination_folder_uuid = 13;
     */
    destinationFolderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFolderMoveFolderRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "source_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "destination_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFolderMoveFolderRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFolderMoveFolderRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFolderMoveFolderRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFolderMoveFolderRequest, a, b);
    }
}
exports.VaultFolderMoveFolderRequest = VaultFolderMoveFolderRequest;
/**
 *
 * Describes the parameters necessary to rename a vault folder
 *
 * @generated from message Scailo.VaultFolderRenameFolderRequest
 */
class VaultFolderRenameFolderRequest extends protobuf_1.Message {
    /**
     * The UUID of the folder
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The new name of the folder
     *
     * @generated from field: string name = 10;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFolderRenameFolderRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFolderRenameFolderRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFolderRenameFolderRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFolderRenameFolderRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFolderRenameFolderRequest, a, b);
    }
}
exports.VaultFolderRenameFolderRequest = VaultFolderRenameFolderRequest;
/**
 *
 * Describes the parameters of a vault folder
 *
 * @generated from message Scailo.VaultFolder
 */
class VaultFolder extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The name of the folder
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the parent folder
     *
     * @generated from field: uint64 parent_folder_id = 11;
     */
    parentFolderId = protobuf_1.protoInt64.zero;
    /**
     * The path of the folder
     *
     * @generated from field: string path_tree = 12;
     */
    pathTree = "";
    /**
     * The list of permissions
     *
     * @generated from field: repeated Scailo.VaultPermission permissions = 20;
     */
    permissions = [];
    /**
     * The list of all the parent folders
     *
     * @generated from field: repeated Scailo.VaultFolder parent_folders = 30;
     */
    parentFolders = [];
    /**
     * The UUID of the parent folder
     *
     * @generated from field: string parent_folder_uuid = 50;
     */
    parentFolderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFolder";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "parent_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "path_tree", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "permissions", kind: "message", T: vault_commons_scailo_pb_js_1.VaultPermission, repeated: true },
        { no: 30, name: "parent_folders", kind: "message", T: VaultFolder, repeated: true },
        { no: 50, name: "parent_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFolder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFolder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFolder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFolder, a, b);
    }
}
exports.VaultFolder = VaultFolder;
/**
 *
 * Describes the message consisting of the list of vault folders
 *
 * @generated from message Scailo.VaultFoldersList
 */
class VaultFoldersList extends protobuf_1.Message {
    /**
     * List of folders
     *
     * @generated from field: repeated Scailo.VaultFolder list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFoldersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VaultFolder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFoldersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFoldersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFoldersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFoldersList, a, b);
    }
}
exports.VaultFoldersList = VaultFoldersList;
/**
 *
 * Describes the parameters of a vault folder download
 *
 * @generated from message Scailo.VaultFolderDownload
 */
class VaultFolderDownload extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The ID of the folder that is being downloaded
     *
     * @generated from field: uint64 folder_id = 10;
     */
    folderId = protobuf_1.protoInt64.zero;
    /**
     * The path to the generated zip file
     *
     * @generated from field: string zip_file_path = 11;
     */
    zipFilePath = "";
    /**
     * Stores if the folder has been zipped
     *
     * @generated from field: bool is_zipped = 12;
     */
    isZipped = false;
    /**
     * Stores the username of the user who requested this download
     *
     * @generated from field: string requested_by = 13;
     */
    requestedBy = "";
    /**
     * Stores the timestamp of when the download of the folder began
     *
     * @generated from field: uint64 download_started_at = 14;
     */
    downloadStartedAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the timestamp of when the download of the folder finished
     *
     * @generated from field: uint64 download_ended_at = 15;
     */
    downloadEndedAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who downloaded this folder
     *
     * @generated from field: string downloaded_by = 16;
     */
    downloadedBy = "";
    /**
     * Stores an error encountered, if any
     *
     * @generated from field: string error = 17;
     */
    error = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultFolderDownload";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "zip_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "is_zipped", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 13, name: "requested_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "download_started_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "download_ended_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "downloaded_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultFolderDownload().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultFolderDownload().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultFolderDownload().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultFolderDownload, a, b);
    }
}
exports.VaultFolderDownload = VaultFolderDownload;
