"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file inventory.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryService = void 0;
const inventory_scailo_pb_js_1 = require("./inventory.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on inventory
 *
 * @generated from service Scailo.InventoryService
 */
exports.InventoryService = {
    typeName: "Scailo.InventoryService",
    methods: {
        /**
         * View inventory item by hash
         *
         * @generated from rpc Scailo.InventoryService.ViewByHash
         */
        viewByHash: {
            name: "ViewByHash",
            I: inventory_scailo_pb_js_1.InventoryHashSearchReq,
            O: inventory_scailo_pb_js_1.GenericInventory,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory item by short URL
         *
         * @generated from rpc Scailo.InventoryService.ViewByShortURL
         */
        viewByShortURL: {
            name: "ViewByShortURL",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: inventory_scailo_pb_js_1.GenericInventory,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View issuable inventory
         *
         * @generated from rpc Scailo.InventoryService.ViewIssuable
         */
        viewIssuable: {
            name: "ViewIssuable",
            I: inventory_scailo_pb_js_1.IssuableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View issued inventory for a Goods Dispatch represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewIssuedForGoodsDispatch
         */
        viewIssuedForGoodsDispatch: {
            name: "ViewIssuedForGoodsDispatch",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View issued inventory for an Outward Job Free Issue Material represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewIssuedForOutwardJobFreeIssueMaterial
         */
        viewIssuedForOutwardJobFreeIssueMaterial: {
            name: "ViewIssuedForOutwardJobFreeIssueMaterial",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View admitted inventory from a Goods Receipt represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewAdmittedFromGoodsReceipt
         */
        viewAdmittedFromGoodsReceipt: {
            name: "ViewAdmittedFromGoodsReceipt",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View admitted inventory from an Inward Job Free Issue Material represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewAdmittedFromInwardJobFreeIssueMaterial
         */
        viewAdmittedFromInwardJobFreeIssueMaterial: {
            name: "ViewAdmittedFromInwardJobFreeIssueMaterial",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View admitted inventory from a Production Plan represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewAdmittedFromProductionPlan
         */
        viewAdmittedFromProductionPlan: {
            name: "ViewAdmittedFromProductionPlan",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory that is returnable from a purchase order
         *
         * @generated from rpc Scailo.InventoryService.ViewReturnableForPurchaseOrder
         */
        viewReturnableForPurchaseOrder: {
            name: "ViewReturnableForPurchaseOrder",
            I: inventory_scailo_pb_js_1.ReturnableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory that is returnable from an inward job
         *
         * @generated from rpc Scailo.InventoryService.ViewReturnableForInwardJob
         */
        viewReturnableForInwardJob: {
            name: "ViewReturnableForInwardJob",
            I: inventory_scailo_pb_js_1.ReturnableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory that is returnable from a stock issuance
         *
         * @generated from rpc Scailo.InventoryService.ViewReturnableForStockIssuance
         */
        viewReturnableForStockIssuance: {
            name: "ViewReturnableForStockIssuance",
            I: inventory_scailo_pb_js_1.ReturnableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory that is returnable from a sales order
         *
         * @generated from rpc Scailo.InventoryService.ViewReturnableForSalesOrder
         */
        viewReturnableForSalesOrder: {
            name: "ViewReturnableForSalesOrder",
            I: inventory_scailo_pb_js_1.ReturnableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory that is returnable from an outward job
         *
         * @generated from rpc Scailo.InventoryService.ViewReturnableForOutwardJob
         */
        viewReturnableForOutwardJob: {
            name: "ViewReturnableForOutwardJob",
            I: inventory_scailo_pb_js_1.ReturnableInventorySearchReq,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View quantity remaining of the family in the given status
         *
         * @generated from rpc Scailo.InventoryService.ViewQuantityRemaining
         */
        viewQuantityRemaining: {
            name: "ViewQuantityRemaining",
            I: inventory_scailo_pb_js_1.InventoryServiceFamilyQuantityReq,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Quantity of inventory items of the family with the given Identifier that are being manufactured
         *
         * @generated from rpc Scailo.InventoryService.CountWorkInProgress
         */
        countWorkInProgress: {
            name: "CountWorkInProgress",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Quantity of inventory items of the family with the given Identifier that have been indented
         *
         * @generated from rpc Scailo.InventoryService.CountIndented
         */
        countIndented: {
            name: "CountIndented",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Quantity of inventory items of the family with the given Identifier that have been ordered
         *
         * @generated from rpc Scailo.InventoryService.CountOrdered
         */
        countOrdered: {
            name: "CountOrdered",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View base demand quantity of the family with the given Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewBaseDemandQuantity
         */
        viewBaseDemandQuantity: {
            name: "ViewBaseDemandQuantity",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View adjusted demand quantity of the family with the given Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewAdjustedDemandQuantity
         */
        viewAdjustedDemandQuantity: {
            name: "ViewAdjustedDemandQuantity",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View required quantity of the family with the given Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewRequiredQuantity
         */
        viewRequiredQuantity: {
            name: "ViewRequiredQuantity",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View inventory placed in storage represented by the Identifier
         *
         * @generated from rpc Scailo.InventoryService.ViewInStorage
         */
        viewInStorage: {
            name: "ViewInStorage",
            I: base_scailo_pb_js_1.Identifier,
            O: inventory_scailo_pb_js_1.GenericInventoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
