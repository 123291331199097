"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file genesis.validate.scailo.proto (package genesis_validate, syntax proto2)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimestampRules = exports.DurationRules = exports.AnyRules = exports.MapRules = exports.RepeatedRules = exports.MessageRules = exports.EnumRules = exports.BytesRules = exports.StringRules = exports.BoolRules = exports.SFixed64Rules = exports.SFixed32Rules = exports.Fixed64Rules = exports.Fixed32Rules = exports.SInt64Rules = exports.SInt32Rules = exports.UInt64Rules = exports.UInt32Rules = exports.Int64Rules = exports.Int32Rules = exports.DoubleRules = exports.FloatRules = exports.FieldRules = exports.KnownRegex = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const duration_pb_js_1 = require("./google/protobuf/duration_pb.js");
const timestamp_pb_js_1 = require("./google/protobuf/timestamp_pb.js");
/**
 * WellKnownRegex contain some well-known patterns.
 *
 * @generated from enum genesis_validate.KnownRegex
 */
var KnownRegex;
(function (KnownRegex) {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    KnownRegex[KnownRegex["UNKNOWN"] = 0] = "UNKNOWN";
    /**
     * HTTP header name as defined by RFC 7230.
     *
     * @generated from enum value: HTTP_HEADER_NAME = 1;
     */
    KnownRegex[KnownRegex["HTTP_HEADER_NAME"] = 1] = "HTTP_HEADER_NAME";
    /**
     * HTTP header value as defined by RFC 7230.
     *
     * @generated from enum value: HTTP_HEADER_VALUE = 2;
     */
    KnownRegex[KnownRegex["HTTP_HEADER_VALUE"] = 2] = "HTTP_HEADER_VALUE";
})(KnownRegex || (exports.KnownRegex = KnownRegex = {}));
// Retrieve enum metadata with: proto2.getEnumType(KnownRegex)
protobuf_1.proto2.util.setEnumType(KnownRegex, "genesis_validate.KnownRegex", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "HTTP_HEADER_NAME" },
    { no: 2, name: "HTTP_HEADER_VALUE" },
]);
/**
 * FieldRules encapsulates the rules for each type of field. Depending on the
 * field, the correct set should be used to ensure proper validations.
 *
 * @generated from message genesis_validate.FieldRules
 */
class FieldRules extends protobuf_1.Message {
    /**
     * @generated from field: optional genesis_validate.MessageRules message = 17;
     */
    message;
    /**
     * @generated from oneof genesis_validate.FieldRules.type
     */
    type = { case: undefined };
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.FieldRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 17, name: "message", kind: "message", T: MessageRules, opt: true },
        { no: 1, name: "float", kind: "message", T: FloatRules, oneof: "type" },
        { no: 2, name: "double", kind: "message", T: DoubleRules, oneof: "type" },
        { no: 3, name: "int32", kind: "message", T: Int32Rules, oneof: "type" },
        { no: 4, name: "int64", kind: "message", T: Int64Rules, oneof: "type" },
        { no: 5, name: "uint32", kind: "message", T: UInt32Rules, oneof: "type" },
        { no: 6, name: "uint64", kind: "message", T: UInt64Rules, oneof: "type" },
        { no: 7, name: "sint32", kind: "message", T: SInt32Rules, oneof: "type" },
        { no: 8, name: "sint64", kind: "message", T: SInt64Rules, oneof: "type" },
        { no: 9, name: "fixed32", kind: "message", T: Fixed32Rules, oneof: "type" },
        { no: 10, name: "fixed64", kind: "message", T: Fixed64Rules, oneof: "type" },
        { no: 11, name: "sfixed32", kind: "message", T: SFixed32Rules, oneof: "type" },
        { no: 12, name: "sfixed64", kind: "message", T: SFixed64Rules, oneof: "type" },
        { no: 13, name: "bool", kind: "message", T: BoolRules, oneof: "type" },
        { no: 14, name: "string", kind: "message", T: StringRules, oneof: "type" },
        { no: 15, name: "bytes", kind: "message", T: BytesRules, oneof: "type" },
        { no: 16, name: "enum", kind: "message", T: EnumRules, oneof: "type" },
        { no: 18, name: "repeated", kind: "message", T: RepeatedRules, oneof: "type" },
        { no: 19, name: "map", kind: "message", T: MapRules, oneof: "type" },
        { no: 20, name: "any", kind: "message", T: AnyRules, oneof: "type" },
        { no: 21, name: "duration", kind: "message", T: DurationRules, oneof: "type" },
        { no: 22, name: "timestamp", kind: "message", T: TimestampRules, oneof: "type" },
    ]);
    static fromBinary(bytes, options) {
        return new FieldRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FieldRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FieldRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(FieldRules, a, b);
    }
}
exports.FieldRules = FieldRules;
/**
 * FloatRules describes the constraints applied to `float` values
 *
 * @generated from message genesis_validate.FloatRules
 */
class FloatRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional float const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional float lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional float lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional float gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional float gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated float in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated float not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.FloatRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new FloatRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FloatRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FloatRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(FloatRules, a, b);
    }
}
exports.FloatRules = FloatRules;
/**
 * DoubleRules describes the constraints applied to `double` values
 *
 * @generated from message genesis_validate.DoubleRules
 */
class DoubleRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional double const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional double lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional double lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional double gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional double gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated double in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated double not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.DoubleRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new DoubleRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DoubleRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DoubleRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(DoubleRules, a, b);
    }
}
exports.DoubleRules = DoubleRules;
/**
 * Int32Rules describes the constraints applied to `int32` values
 *
 * @generated from message genesis_validate.Int32Rules
 */
class Int32Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional int32 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional int32 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional int32 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional int32 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional int32 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated int32 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated int32 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.Int32Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new Int32Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Int32Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Int32Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(Int32Rules, a, b);
    }
}
exports.Int32Rules = Int32Rules;
/**
 * Int64Rules describes the constraints applied to `int64` values
 *
 * @generated from message genesis_validate.Int64Rules
 */
class Int64Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional int64 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional int64 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional int64 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional int64 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional int64 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated int64 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated int64 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.Int64Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new Int64Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Int64Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Int64Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(Int64Rules, a, b);
    }
}
exports.Int64Rules = Int64Rules;
/**
 * UInt32Rules describes the constraints applied to `uint32` values
 *
 * @generated from message genesis_validate.UInt32Rules
 */
class UInt32Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional uint32 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional uint32 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional uint32 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional uint32 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional uint32 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated uint32 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated uint32 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.UInt32Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new UInt32Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UInt32Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UInt32Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(UInt32Rules, a, b);
    }
}
exports.UInt32Rules = UInt32Rules;
/**
 * UInt64Rules describes the constraints applied to `uint64` values
 *
 * @generated from message genesis_validate.UInt64Rules
 */
class UInt64Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional uint64 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional uint64 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional uint64 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional uint64 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional uint64 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated uint64 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated uint64 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.UInt64Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new UInt64Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UInt64Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UInt64Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(UInt64Rules, a, b);
    }
}
exports.UInt64Rules = UInt64Rules;
/**
 * SInt32Rules describes the constraints applied to `sint32` values
 *
 * @generated from message genesis_validate.SInt32Rules
 */
class SInt32Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional sint32 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional sint32 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional sint32 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional sint32 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional sint32 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated sint32 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated sint32 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.SInt32Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 17 /* ScalarType.SINT32 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 17 /* ScalarType.SINT32 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new SInt32Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SInt32Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SInt32Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(SInt32Rules, a, b);
    }
}
exports.SInt32Rules = SInt32Rules;
/**
 * SInt64Rules describes the constraints applied to `sint64` values
 *
 * @generated from message genesis_validate.SInt64Rules
 */
class SInt64Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional sint64 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional sint64 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional sint64 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional sint64 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional sint64 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated sint64 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated sint64 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.SInt64Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 18 /* ScalarType.SINT64 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 18 /* ScalarType.SINT64 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new SInt64Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SInt64Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SInt64Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(SInt64Rules, a, b);
    }
}
exports.SInt64Rules = SInt64Rules;
/**
 * Fixed32Rules describes the constraints applied to `fixed32` values
 *
 * @generated from message genesis_validate.Fixed32Rules
 */
class Fixed32Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional fixed32 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional fixed32 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional fixed32 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional fixed32 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional fixed32 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated fixed32 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated fixed32 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.Fixed32Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new Fixed32Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Fixed32Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Fixed32Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(Fixed32Rules, a, b);
    }
}
exports.Fixed32Rules = Fixed32Rules;
/**
 * Fixed64Rules describes the constraints applied to `fixed64` values
 *
 * @generated from message genesis_validate.Fixed64Rules
 */
class Fixed64Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional fixed64 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional fixed64 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional fixed64 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional fixed64 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional fixed64 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated fixed64 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated fixed64 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.Fixed64Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new Fixed64Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Fixed64Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Fixed64Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(Fixed64Rules, a, b);
    }
}
exports.Fixed64Rules = Fixed64Rules;
/**
 * SFixed32Rules describes the constraints applied to `sfixed32` values
 *
 * @generated from message genesis_validate.SFixed32Rules
 */
class SFixed32Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional sfixed32 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional sfixed32 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional sfixed32 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional sfixed32 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional sfixed32 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated sfixed32 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated sfixed32 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.SFixed32Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 15 /* ScalarType.SFIXED32 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new SFixed32Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SFixed32Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SFixed32Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(SFixed32Rules, a, b);
    }
}
exports.SFixed32Rules = SFixed32Rules;
/**
 * SFixed64Rules describes the constraints applied to `sfixed64` values
 *
 * @generated from message genesis_validate.SFixed64Rules
 */
class SFixed64Rules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional sfixed64 const = 1;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional sfixed64 lt = 2;
     */
    lt;
    /**
     * Lte specifies that this field must be less than or equal to the
     * specified value, inclusive
     *
     * @generated from field: optional sfixed64 lte = 3;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive. If the value of Gt is larger than a specified Lt or Lte, the
     * range is reversed.
     *
     * @generated from field: optional sfixed64 gt = 4;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than or equal to the
     * specified value, inclusive. If the value of Gte is larger than a
     * specified Lt or Lte, the range is reversed.
     *
     * @generated from field: optional sfixed64 gte = 5;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated sfixed64 in = 6;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated sfixed64 not_in = 7;
     */
    notIn = [];
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 8;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.SFixed64Rules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
        { no: 2, name: "lt", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
        { no: 3, name: "lte", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
        { no: 4, name: "gt", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
        { no: 5, name: "gte", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, opt: true },
        { no: 6, name: "in", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, repeated: true },
        { no: 7, name: "not_in", kind: "scalar", T: 16 /* ScalarType.SFIXED64 */, repeated: true },
        { no: 8, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new SFixed64Rules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SFixed64Rules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SFixed64Rules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(SFixed64Rules, a, b);
    }
}
exports.SFixed64Rules = SFixed64Rules;
/**
 * BoolRules describes the constraints applied to `bool` values
 *
 * @generated from message genesis_validate.BoolRules
 */
class BoolRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional bool const = 1;
     */
    const;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.BoolRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new BoolRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new BoolRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new BoolRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(BoolRules, a, b);
    }
}
exports.BoolRules = BoolRules;
/**
 * StringRules describe the constraints applied to `string` values
 *
 * @generated from message genesis_validate.StringRules
 */
class StringRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional string const = 1;
     */
    const;
    /**
     * Len specifies that this field must be the specified number of
     * characters (Unicode code points). Note that the number of
     * characters may differ from the number of bytes in the string.
     *
     * @generated from field: optional uint64 len = 19;
     */
    len;
    /**
     * MinLen specifies that this field must be the specified number of
     * characters (Unicode code points) at a minimum. Note that the number of
     * characters may differ from the number of bytes in the string.
     *
     * @generated from field: optional uint64 min_len = 2;
     */
    minLen;
    /**
     * MaxLen specifies that this field must be the specified number of
     * characters (Unicode code points) at a maximum. Note that the number of
     * characters may differ from the number of bytes in the string.
     *
     * @generated from field: optional uint64 max_len = 3;
     */
    maxLen;
    /**
     * LenBytes specifies that this field must be the specified number of bytes
     *
     * @generated from field: optional uint64 len_bytes = 20;
     */
    lenBytes;
    /**
     * MinBytes specifies that this field must be the specified number of bytes
     * at a minimum
     *
     * @generated from field: optional uint64 min_bytes = 4;
     */
    minBytes;
    /**
     * MaxBytes specifies that this field must be the specified number of bytes
     * at a maximum
     *
     * @generated from field: optional uint64 max_bytes = 5;
     */
    maxBytes;
    /**
     * Pattern specifes that this field must match against the specified
     * regular expression (RE2 syntax). The included expression should elide
     * any delimiters.
     *
     * @generated from field: optional string pattern = 6;
     */
    pattern;
    /**
     * Prefix specifies that this field must have the specified substring at
     * the beginning of the string.
     *
     * @generated from field: optional string prefix = 7;
     */
    prefix;
    /**
     * Suffix specifies that this field must have the specified substring at
     * the end of the string.
     *
     * @generated from field: optional string suffix = 8;
     */
    suffix;
    /**
     * Contains specifies that this field must have the specified substring
     * anywhere in the string.
     *
     * @generated from field: optional string contains = 9;
     */
    contains;
    /**
     * NotContains specifies that this field cannot have the specified substring
     * anywhere in the string.
     *
     * @generated from field: optional string not_contains = 23;
     */
    notContains;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated string in = 10;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated string not_in = 11;
     */
    notIn = [];
    /**
     * WellKnown rules provide advanced constraints against common string
     * patterns
     *
     * @generated from oneof genesis_validate.StringRules.well_known
     */
    wellKnown = { case: undefined };
    /**
     * This applies to regexes HTTP_HEADER_NAME and HTTP_HEADER_VALUE to enable
     * strict header validation.
     * By default, this is true, and HTTP header validations are RFC-compliant.
     * Setting to false will enable a looser validations that only disallows
     * \r\n\0 characters, which can be used to bypass header matching rules.
     *
     * @generated from field: optional bool strict = 25 [default = true];
     */
    strict;
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 26;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.StringRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 19, name: "len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 2, name: "min_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 3, name: "max_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 20, name: "len_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 4, name: "min_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 5, name: "max_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 6, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 7, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 8, name: "suffix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 9, name: "contains", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 23, name: "not_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 10, name: "in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 11, name: "not_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 12, name: "email", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 13, name: "hostname", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 14, name: "ip", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 15, name: "ipv4", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 16, name: "ipv6", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 17, name: "uri", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 18, name: "uri_ref", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 21, name: "address", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 22, name: "uuid", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 24, name: "well_known_regex", kind: "enum", T: protobuf_1.proto2.getEnumType(KnownRegex), oneof: "well_known" },
        { no: 25, name: "strict", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: true },
        { no: 26, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new StringRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StringRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StringRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(StringRules, a, b);
    }
}
exports.StringRules = StringRules;
/**
 * BytesRules describe the constraints applied to `bytes` values
 *
 * @generated from message genesis_validate.BytesRules
 */
class BytesRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional bytes const = 1;
     */
    const;
    /**
     * Len specifies that this field must be the specified number of bytes
     *
     * @generated from field: optional uint64 len = 13;
     */
    len;
    /**
     * MinLen specifies that this field must be the specified number of bytes
     * at a minimum
     *
     * @generated from field: optional uint64 min_len = 2;
     */
    minLen;
    /**
     * MaxLen specifies that this field must be the specified number of bytes
     * at a maximum
     *
     * @generated from field: optional uint64 max_len = 3;
     */
    maxLen;
    /**
     * Pattern specifes that this field must match against the specified
     * regular expression (RE2 syntax). The included expression should elide
     * any delimiters.
     *
     * @generated from field: optional string pattern = 4;
     */
    pattern;
    /**
     * Prefix specifies that this field must have the specified bytes at the
     * beginning of the string.
     *
     * @generated from field: optional bytes prefix = 5;
     */
    prefix;
    /**
     * Suffix specifies that this field must have the specified bytes at the
     * end of the string.
     *
     * @generated from field: optional bytes suffix = 6;
     */
    suffix;
    /**
     * Contains specifies that this field must have the specified bytes
     * anywhere in the string.
     *
     * @generated from field: optional bytes contains = 7;
     */
    contains;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated bytes in = 8;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated bytes not_in = 9;
     */
    notIn = [];
    /**
     * WellKnown rules provide advanced constraints against common byte
     * patterns
     *
     * @generated from oneof genesis_validate.BytesRules.well_known
     */
    wellKnown = { case: undefined };
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 14;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.BytesRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
        { no: 13, name: "len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 2, name: "min_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 3, name: "max_len", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 4, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
        { no: 5, name: "prefix", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
        { no: 6, name: "suffix", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
        { no: 7, name: "contains", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
        { no: 8, name: "in", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
        { no: 9, name: "not_in", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
        { no: 10, name: "ip", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 11, name: "ipv4", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 12, name: "ipv6", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "well_known" },
        { no: 14, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new BytesRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new BytesRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new BytesRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(BytesRules, a, b);
    }
}
exports.BytesRules = BytesRules;
/**
 * EnumRules describe the constraints applied to enum values
 *
 * @generated from message genesis_validate.EnumRules
 */
class EnumRules extends protobuf_1.Message {
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional int32 const = 1;
     */
    const;
    /**
     * DefinedOnly specifies that this field must be only one of the defined
     * values for this enum, failing on any undefined value.
     *
     * @generated from field: optional bool defined_only = 2;
     */
    definedOnly;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated int32 in = 3;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated int32 not_in = 4;
     */
    notIn = [];
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.EnumRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "const", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
        { no: 2, name: "defined_only", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 3, name: "in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
        { no: 4, name: "not_in", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EnumRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(EnumRules, a, b);
    }
}
exports.EnumRules = EnumRules;
/**
 * MessageRules describe the constraints applied to embedded message values.
 * For message-type fields, validation is performed recursively.
 *
 * @generated from message genesis_validate.MessageRules
 */
class MessageRules extends protobuf_1.Message {
    /**
     * Skip specifies that the validation rules of this field should not be
     * evaluated
     *
     * @generated from field: optional bool skip = 1;
     */
    skip;
    /**
     * Required specifies that this field must be set
     *
     * @generated from field: optional bool required = 2;
     */
    required;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.MessageRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "skip", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 2, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new MessageRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MessageRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MessageRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(MessageRules, a, b);
    }
}
exports.MessageRules = MessageRules;
/**
 * RepeatedRules describe the constraints applied to `repeated` values
 *
 * @generated from message genesis_validate.RepeatedRules
 */
class RepeatedRules extends protobuf_1.Message {
    /**
     * MinItems specifies that this field must have the specified number of
     * items at a minimum
     *
     * @generated from field: optional uint64 min_items = 1;
     */
    minItems;
    /**
     * MaxItems specifies that this field must have the specified number of
     * items at a maximum
     *
     * @generated from field: optional uint64 max_items = 2;
     */
    maxItems;
    /**
     * Unique specifies that all elements in this field must be unique. This
     * contraint is only applicable to scalar and enum types (messages are not
     * supported).
     *
     * @generated from field: optional bool unique = 3;
     */
    unique;
    /**
     * Items specifies the contraints to be applied to each item in the field.
     * Repeated message fields will still execute validation against each item
     * unless skip is specified here.
     *
     * @generated from field: optional genesis_validate.FieldRules items = 4;
     */
    items;
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 5;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.RepeatedRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "min_items", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 2, name: "max_items", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 3, name: "unique", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 4, name: "items", kind: "message", T: FieldRules, opt: true },
        { no: 5, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new RepeatedRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RepeatedRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RepeatedRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(RepeatedRules, a, b);
    }
}
exports.RepeatedRules = RepeatedRules;
/**
 * MapRules describe the constraints applied to `map` values
 *
 * @generated from message genesis_validate.MapRules
 */
class MapRules extends protobuf_1.Message {
    /**
     * MinPairs specifies that this field must have the specified number of
     * KVs at a minimum
     *
     * @generated from field: optional uint64 min_pairs = 1;
     */
    minPairs;
    /**
     * MaxPairs specifies that this field must have the specified number of
     * KVs at a maximum
     *
     * @generated from field: optional uint64 max_pairs = 2;
     */
    maxPairs;
    /**
     * NoSparse specifies values in this field cannot be unset. This only
     * applies to map's with message value types.
     *
     * @generated from field: optional bool no_sparse = 3;
     */
    noSparse;
    /**
     * Keys specifies the constraints to be applied to each key in the field.
     *
     * @generated from field: optional genesis_validate.FieldRules keys = 4;
     */
    keys;
    /**
     * Values specifies the constraints to be applied to the value of each key
     * in the field. Message values will still have their validations evaluated
     * unless skip is specified here.
     *
     * @generated from field: optional genesis_validate.FieldRules values = 5;
     */
    values;
    /**
     * IgnoreEmpty specifies that the validation rules of this field should be
     * evaluated only if the field is not empty
     *
     * @generated from field: optional bool ignore_empty = 6;
     */
    ignoreEmpty;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.MapRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "min_pairs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 2, name: "max_pairs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
        { no: 3, name: "no_sparse", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 4, name: "keys", kind: "message", T: FieldRules, opt: true },
        { no: 5, name: "values", kind: "message", T: FieldRules, opt: true },
        { no: 6, name: "ignore_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new MapRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MapRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MapRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(MapRules, a, b);
    }
}
exports.MapRules = MapRules;
/**
 * AnyRules describe constraints applied exclusively to the
 * `google.protobuf.Any` well-known type
 *
 * @generated from message genesis_validate.AnyRules
 */
class AnyRules extends protobuf_1.Message {
    /**
     * Required specifies that this field must be set
     *
     * @generated from field: optional bool required = 1;
     */
    required;
    /**
     * In specifies that this field's `type_url` must be equal to one of the
     * specified values.
     *
     * @generated from field: repeated string in = 2;
     */
    in = [];
    /**
     * NotIn specifies that this field's `type_url` must not be equal to any of
     * the specified values.
     *
     * @generated from field: repeated string not_in = 3;
     */
    notIn = [];
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.AnyRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 2, name: "in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 3, name: "not_in", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AnyRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnyRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnyRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(AnyRules, a, b);
    }
}
exports.AnyRules = AnyRules;
/**
 * DurationRules describe the constraints applied exclusively to the
 * `google.protobuf.Duration` well-known type
 *
 * @generated from message genesis_validate.DurationRules
 */
class DurationRules extends protobuf_1.Message {
    /**
     * Required specifies that this field must be set
     *
     * @generated from field: optional bool required = 1;
     */
    required;
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional google.protobuf.Duration const = 2;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional google.protobuf.Duration lt = 3;
     */
    lt;
    /**
     * Lt specifies that this field must be less than the specified value,
     * inclusive
     *
     * @generated from field: optional google.protobuf.Duration lte = 4;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive
     *
     * @generated from field: optional google.protobuf.Duration gt = 5;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than the specified value,
     * inclusive
     *
     * @generated from field: optional google.protobuf.Duration gte = 6;
     */
    gte;
    /**
     * In specifies that this field must be equal to one of the specified
     * values
     *
     * @generated from field: repeated google.protobuf.Duration in = 7;
     */
    in = [];
    /**
     * NotIn specifies that this field cannot be equal to one of the specified
     * values
     *
     * @generated from field: repeated google.protobuf.Duration not_in = 8;
     */
    notIn = [];
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.DurationRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 2, name: "const", kind: "message", T: duration_pb_js_1.Duration, opt: true },
        { no: 3, name: "lt", kind: "message", T: duration_pb_js_1.Duration, opt: true },
        { no: 4, name: "lte", kind: "message", T: duration_pb_js_1.Duration, opt: true },
        { no: 5, name: "gt", kind: "message", T: duration_pb_js_1.Duration, opt: true },
        { no: 6, name: "gte", kind: "message", T: duration_pb_js_1.Duration, opt: true },
        { no: 7, name: "in", kind: "message", T: duration_pb_js_1.Duration, repeated: true },
        { no: 8, name: "not_in", kind: "message", T: duration_pb_js_1.Duration, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DurationRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DurationRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DurationRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(DurationRules, a, b);
    }
}
exports.DurationRules = DurationRules;
/**
 * TimestampRules describe the constraints applied exclusively to the
 * `google.protobuf.Timestamp` well-known type
 *
 * @generated from message genesis_validate.TimestampRules
 */
class TimestampRules extends protobuf_1.Message {
    /**
     * Required specifies that this field must be set
     *
     * @generated from field: optional bool required = 1;
     */
    required;
    /**
     * Const specifies that this field must be exactly the specified value
     *
     * @generated from field: optional google.protobuf.Timestamp const = 2;
     */
    const;
    /**
     * Lt specifies that this field must be less than the specified value,
     * exclusive
     *
     * @generated from field: optional google.protobuf.Timestamp lt = 3;
     */
    lt;
    /**
     * Lte specifies that this field must be less than the specified value,
     * inclusive
     *
     * @generated from field: optional google.protobuf.Timestamp lte = 4;
     */
    lte;
    /**
     * Gt specifies that this field must be greater than the specified value,
     * exclusive
     *
     * @generated from field: optional google.protobuf.Timestamp gt = 5;
     */
    gt;
    /**
     * Gte specifies that this field must be greater than the specified value,
     * inclusive
     *
     * @generated from field: optional google.protobuf.Timestamp gte = 6;
     */
    gte;
    /**
     * LtNow specifies that this must be less than the current time. LtNow
     * can only be used with the Within rule.
     *
     * @generated from field: optional bool lt_now = 7;
     */
    ltNow;
    /**
     * GtNow specifies that this must be greater than the current time. GtNow
     * can only be used with the Within rule.
     *
     * @generated from field: optional bool gt_now = 8;
     */
    gtNow;
    /**
     * Within specifies that this field must be within this duration of the
     * current time. This constraint can be used alone or with the LtNow and
     * GtNow rules.
     *
     * @generated from field: optional google.protobuf.Duration within = 9;
     */
    within;
    constructor(data) {
        super();
        protobuf_1.proto2.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto2;
    static typeName = "genesis_validate.TimestampRules";
    static fields = protobuf_1.proto2.util.newFieldList(() => [
        { no: 1, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 2, name: "const", kind: "message", T: timestamp_pb_js_1.Timestamp, opt: true },
        { no: 3, name: "lt", kind: "message", T: timestamp_pb_js_1.Timestamp, opt: true },
        { no: 4, name: "lte", kind: "message", T: timestamp_pb_js_1.Timestamp, opt: true },
        { no: 5, name: "gt", kind: "message", T: timestamp_pb_js_1.Timestamp, opt: true },
        { no: 6, name: "gte", kind: "message", T: timestamp_pb_js_1.Timestamp, opt: true },
        { no: 7, name: "lt_now", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 8, name: "gt_now", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
        { no: 9, name: "within", kind: "message", T: duration_pb_js_1.Duration, opt: true },
    ]);
    static fromBinary(bytes, options) {
        return new TimestampRules().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TimestampRules().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TimestampRules().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto2.util.equals(TimestampRules, a, b);
    }
}
exports.TimestampRules = TimestampRules;
