import { getClientsServiceClient } from "../clients";
import { profileMenu } from "../menus";
import { Router, context } from "./../router";
import { downloadData, handlePageForInvalidPermissions, interceptPage, randomId, setupSidebarMenus } from "./../utilities";
import * as ui from "../ui";
import { Client } from "@kernelminds/scailo-sdk";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(profileMenu.href, async (ctx) => {
        if (await interceptPage(ctx, profileMenu)) {
            handleProfilePage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}

async function handleProfilePage(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }

    let clientUuid = localStorage.getItem("client_uuid") || "";
    let client = getClientsServiceClient();
    let clientInfo = await client.viewByUUID({ uuid: clientUuid });

    let { html, formId } = _renderPageFilters(clientInfo);
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    // let downloadButton = <HTMLButtonElement>document.getElementById(downloadButtonId);

    // downloadButton.addEventListener("click", async evt => {
    //     evt.preventDefault();

    //     const originalButtonHTML = downloadButton.innerHTML;
    //     downloadButton.disabled = true;
    //     downloadButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
    //     let file = await client.downloadByUUID({ uuid: clientUuid });

    //     downloadButton.disabled = false;
    //     downloadButton.innerHTML = originalButtonHTML;

    //     downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
    // });
}

function renderPrimaryFiltersSection(client: Client) {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Info", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Name", inputType: "text", dataMapper: "name", dataType: "string", value: client.name, mdColSpan: 12, helpText: "The name of the client." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Code", inputType: "text", dataMapper: "code", dataType: "string", value: client.code, mdColSpan: 12, helpText: "The code of the client." }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Email", inputType: "email", dataMapper: "email", dataType: "string", value: client.email, mdColSpan: 6, helpText: "The primary email address of the client." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Phone", inputType: "tel", dataMapper: "phone", dataType: "string", value: client.phone, mdColSpan: 6, helpText: "The primary phone number of the client." }));

    return grid
}

export function _renderPageFilters(client: Client) {
    let primaryFiltersSection = renderPrimaryFiltersSection(client);


    // let buttonsDiv = document.createElement("div");
    // buttonsDiv.className = `col-span-12 pl-4 float-right`;

    // let downloadButtonId = randomId();
    // let downloadButton = document.createElement("button");
    // downloadButton.id = downloadButtonId;
    // downloadButton.className = "btn btn-outline btn-success btn-md mr-4";
    // downloadButton.innerText = `Download`;
    // buttonsDiv.append(downloadButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Client Profile" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        // downloadButtonId
    }
}