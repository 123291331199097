"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file equations_replaceables.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsReplaceablesService = void 0;
const equations_replaceables_scailo_pb_js_1 = require("./equations_replaceables.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each equation replaceable
 *
 * @generated from service Scailo.EquationsReplaceablesService
 */
exports.EquationsReplaceablesService = {
    typeName: "Scailo.EquationsReplaceablesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Create
         */
        create: {
            name: "Create",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Draft
         */
        draft: {
            name: "Draft",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (Identifier);
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone equation from an existing equation (denoted by the identifier)
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a equation replaceable
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.AddEquationReplaceableItem
         */
        addEquationReplaceableItem: {
            name: "AddEquationReplaceableItem",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a equation replaceable
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ModifyEquationReplaceableItem
         */
        modifyEquationReplaceableItem: {
            name: "ModifyEquationReplaceableItem",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a equation replaceable
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ApproveEquationReplaceableItem
         */
        approveEquationReplaceableItem: {
            name: "ApproveEquationReplaceableItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a equation replaceable
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.DeleteEquationReplaceableItem
         */
        deleteEquationReplaceableItem: {
            name: "DeleteEquationReplaceableItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a equation replaceable
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ReorderEquationReplaceableItems
         */
        reorderEquationReplaceableItems: {
            name: "ReorderEquationReplaceableItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Equation Replaceable Item by ID
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewEquationReplaceableItemByID
         */
        viewEquationReplaceableItemByID: {
            name: "ViewEquationReplaceableItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_replaceables_scailo_pb_js_1.EquationReplaceableItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation replaceable items for given equation replaceable ID
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewApprovedEquationReplaceableItems
         */
        viewApprovedEquationReplaceableItems: {
            name: "ViewApprovedEquationReplaceableItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation replaceable items for given equation replaceable ID
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewUnapprovedEquationReplaceableItems
         */
        viewUnapprovedEquationReplaceableItems: {
            name: "ViewUnapprovedEquationReplaceableItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the equation replaceable item
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewEquationReplaceableItemHistory
         */
        viewEquationReplaceableItemHistory: {
            name: "ViewEquationReplaceableItemHistory",
            I: equations_replaceables_scailo_pb_js_1.EquationReplaceableItemHistoryRequest,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation replaceable items for given equation replaceable ID with pagination
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewPaginatedApprovedEquationReplaceableItems
         */
        viewPaginatedApprovedEquationReplaceableItems: {
            name: "ViewPaginatedApprovedEquationReplaceableItems",
            I: equations_replaceables_scailo_pb_js_1.EquationReplaceableItemsSearchRequest,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation replaceable items for given equation replaceable ID with pagination
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewPaginatedUnapprovedEquationReplaceableItems
         */
        viewPaginatedUnapprovedEquationReplaceableItems: {
            name: "ViewPaginatedUnapprovedEquationReplaceableItems",
            I: equations_replaceables_scailo_pb_js_1.EquationReplaceableItemsSearchRequest,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through equation replaceable items with pagination
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: equations_replaceables_scailo_pb_js_1.EquationReplaceableItemsSearchRequest,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV file with the entire dependency tree. Useful for identifying quantities necessary from all the dependencies.
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.DownloadTreeAsCSV
         */
        downloadTreeAsCSV: {
            name: "DownloadTreeAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.UploadEquationReplaceableItems
         */
        uploadEquationReplaceableItems: {
            name: "UploadEquationReplaceableItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_replaceables_scailo_pb_js_1.EquationReplaceable,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServicePaginationReq,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the latest equation for a family (denoted by the given identifier)
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.ViewForFamilyID
         */
        viewForFamilyID: {
            name: "ViewForFamilyID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_replaceables_scailo_pb_js_1.EquationReplaceable,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceSearchAllReq,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Filter
         */
        filter: {
            name: "Filter",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceFilterReq,
            O: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.EquationsReplaceablesService.Count
         */
        count: {
            name: "Count",
            I: equations_replaceables_scailo_pb_js_1.EquationsReplaceablesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
