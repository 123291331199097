"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file stock_audits.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockAuditsServicePaginatedItemsResponse = exports.StockAuditItemsSearchRequest = exports.StockAuditsServiceSearchAllReq = exports.StockAuditsServiceCountReq = exports.StockAuditsServiceFilterReq = exports.StockAuditsServicePaginationResponse = exports.StockAuditsServicePaginationReq = exports.StockAuditItemProspectiveInfoRequest = exports.StockAuditItemHistoryRequest = exports.StockAuditsItemsList = exports.StockAuditsList = exports.StockAuditItem = exports.StockAuditsServiceItemUpdateRequest = exports.StockAuditsServiceItemCreateRequest = exports.StockAudit = exports.StockAuditsServiceUpdateRequest = exports.StockAuditsServiceCreateRequest = exports.STOCK_AUDIT_ITEM_STATUS = exports.STOCK_AUDIT_ITEM_SORT_KEY = exports.STOCK_AUDIT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.STOCK_AUDIT_SORT_KEY
 */
var STOCK_AUDIT_SORT_KEY;
(function (STOCK_AUDIT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_CREATED_AT = 1;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_CREATED_AT"] = 1] = "STOCK_AUDIT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_MODIFIED_AT = 2;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_MODIFIED_AT"] = 2] = "STOCK_AUDIT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_APPROVED_ON = 3;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_APPROVED_ON"] = 3] = "STOCK_AUDIT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_APPROVED_BY = 4;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_APPROVED_BY"] = 4] = "STOCK_AUDIT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "STOCK_AUDIT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_COMPLETED_ON = 6;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_COMPLETED_ON"] = 6] = "STOCK_AUDIT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_REFERENCE_ID = 10;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_REFERENCE_ID"] = 10] = "STOCK_AUDIT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: STOCK_AUDIT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    STOCK_AUDIT_SORT_KEY[STOCK_AUDIT_SORT_KEY["STOCK_AUDIT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "STOCK_AUDIT_SORT_KEY_FINAL_REF_NUMBER";
})(STOCK_AUDIT_SORT_KEY || (exports.STOCK_AUDIT_SORT_KEY = STOCK_AUDIT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(STOCK_AUDIT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(STOCK_AUDIT_SORT_KEY, "Scailo.STOCK_AUDIT_SORT_KEY", [
    { no: 0, name: "STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "STOCK_AUDIT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "STOCK_AUDIT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "STOCK_AUDIT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "STOCK_AUDIT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "STOCK_AUDIT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "STOCK_AUDIT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "STOCK_AUDIT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "STOCK_AUDIT_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.STOCK_AUDIT_ITEM_SORT_KEY
 */
var STOCK_AUDIT_ITEM_SORT_KEY;
(function (STOCK_AUDIT_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "STOCK_AUDIT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "STOCK_AUDIT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "STOCK_AUDIT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "STOCK_AUDIT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "STOCK_AUDIT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the physical quantity
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_PHYSICAL_QUANTITY = 11;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_PHYSICAL_QUANTITY"] = 11] = "STOCK_AUDIT_ITEM_SORT_KEY_PHYSICAL_QUANTITY";
    /**
     * Fetch results by the digital quantity
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_SORT_KEY_DIGITAL_QUANTITY = 12;
     */
    STOCK_AUDIT_ITEM_SORT_KEY[STOCK_AUDIT_ITEM_SORT_KEY["STOCK_AUDIT_ITEM_SORT_KEY_DIGITAL_QUANTITY"] = 12] = "STOCK_AUDIT_ITEM_SORT_KEY_DIGITAL_QUANTITY";
})(STOCK_AUDIT_ITEM_SORT_KEY || (exports.STOCK_AUDIT_ITEM_SORT_KEY = STOCK_AUDIT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(STOCK_AUDIT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(STOCK_AUDIT_ITEM_SORT_KEY, "Scailo.STOCK_AUDIT_ITEM_SORT_KEY", [
    { no: 0, name: "STOCK_AUDIT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "STOCK_AUDIT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "STOCK_AUDIT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "STOCK_AUDIT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "STOCK_AUDIT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "STOCK_AUDIT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "STOCK_AUDIT_ITEM_SORT_KEY_PHYSICAL_QUANTITY" },
    { no: 12, name: "STOCK_AUDIT_ITEM_SORT_KEY_DIGITAL_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of stock audit items
 *
 * @generated from enum Scailo.STOCK_AUDIT_ITEM_STATUS
 */
var STOCK_AUDIT_ITEM_STATUS;
(function (STOCK_AUDIT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    STOCK_AUDIT_ITEM_STATUS[STOCK_AUDIT_ITEM_STATUS["STOCK_AUDIT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "STOCK_AUDIT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the stock audit items must have been approved
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_STATUS_APPROVED = 1;
     */
    STOCK_AUDIT_ITEM_STATUS[STOCK_AUDIT_ITEM_STATUS["STOCK_AUDIT_ITEM_STATUS_APPROVED"] = 1] = "STOCK_AUDIT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the stock audit items must be waiting for approval
     *
     * @generated from enum value: STOCK_AUDIT_ITEM_STATUS_UNAPPROVED = 2;
     */
    STOCK_AUDIT_ITEM_STATUS[STOCK_AUDIT_ITEM_STATUS["STOCK_AUDIT_ITEM_STATUS_UNAPPROVED"] = 2] = "STOCK_AUDIT_ITEM_STATUS_UNAPPROVED";
})(STOCK_AUDIT_ITEM_STATUS || (exports.STOCK_AUDIT_ITEM_STATUS = STOCK_AUDIT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(STOCK_AUDIT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(STOCK_AUDIT_ITEM_STATUS, "Scailo.STOCK_AUDIT_ITEM_STATUS", [
    { no: 0, name: "STOCK_AUDIT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "STOCK_AUDIT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "STOCK_AUDIT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.StockAuditsServiceCreateRequest
 */
class StockAuditsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the stock audit
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceCreateRequest, a, b);
    }
}
exports.StockAuditsServiceCreateRequest = StockAuditsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.StockAuditsServiceUpdateRequest
 */
class StockAuditsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the stock audit
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceUpdateRequest, a, b);
    }
}
exports.StockAuditsServiceUpdateRequest = StockAuditsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.StockAudit
 */
class StockAudit extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this stock audit
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this stock audit
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this stock audit
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this stock audit was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the stock audit
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated stock audit items
     *
     * @generated from field: repeated Scailo.StockAuditItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAudit";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: StockAuditItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAudit().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAudit().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAudit().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAudit, a, b);
    }
}
exports.StockAudit = StockAudit;
/**
 *
 * Describes the parameters required to add an item to a stock audit
 *
 * @generated from message Scailo.StockAuditsServiceItemCreateRequest
 */
class StockAuditsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the stock audit ID
     *
     * @generated from field: uint64 stock_audit_id = 10;
     */
    stockAuditId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The physically available quantity (in cents)
     *
     * @generated from field: uint64 physical_quantity = 13;
     */
    physicalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The digitally available quantity (in cents)
     * uint64 digital_quantity = 14 [(genesis_validate.rules).uint64.gt = 0];
     * The reason for reconciliation
     *
     * @generated from field: string reconciliation_reason = 15;
     */
    reconciliationReason = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "stock_audit_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "physical_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "reconciliation_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceItemCreateRequest, a, b);
    }
}
exports.StockAuditsServiceItemCreateRequest = StockAuditsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a stock audit
 *
 * @generated from message Scailo.StockAuditsServiceItemUpdateRequest
 */
class StockAuditsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The physically available quantity (in cents)
     *
     * @generated from field: uint64 physical_quantity = 13;
     */
    physicalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The digitally available quantity (in cents)
     * uint64 digital_quantity = 14 [(genesis_validate.rules).uint64.gt = 0];
     * The reason for reconciliation
     *
     * @generated from field: string reconciliation_reason = 15;
     */
    reconciliationReason = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "physical_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "reconciliation_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceItemUpdateRequest, a, b);
    }
}
exports.StockAuditsServiceItemUpdateRequest = StockAuditsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a stock audit
 *
 * @generated from message Scailo.StockAuditItem
 */
class StockAuditItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this stock audit
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the stock audit ID
     *
     * @generated from field: uint64 stock_audit_id = 10;
     */
    stockAuditId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The hash of the inventory item
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The physically available quantity (in cents)
     *
     * @generated from field: uint64 physical_quantity = 13;
     */
    physicalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The digitally available quantity (in cents)
     *
     * @generated from field: uint64 digital_quantity = 14;
     */
    digitalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The reason for reconciliation
     *
     * @generated from field: string reconciliation_reason = 15;
     */
    reconciliationReason = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "stock_audit_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "physical_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "digital_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "reconciliation_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditItem, a, b);
    }
}
exports.StockAuditItem = StockAuditItem;
/**
 *
 * Describes the message consisting of the list of stock audits
 *
 * @generated from message Scailo.StockAuditsList
 */
class StockAuditsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.StockAudit list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: StockAudit, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsList, a, b);
    }
}
exports.StockAuditsList = StockAuditsList;
/**
 *
 * Describes the message consisting of the list of stock audit items
 *
 * @generated from message Scailo.StockAuditsItemsList
 */
class StockAuditsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.StockAuditItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: StockAuditItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsItemsList, a, b);
    }
}
exports.StockAuditsItemsList = StockAuditsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.StockAuditItemHistoryRequest
 */
class StockAuditItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the stock audit ID
     *
     * @generated from field: uint64 stock_audit_id = 10;
     */
    stockAuditId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "stock_audit_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditItemHistoryRequest, a, b);
    }
}
exports.StockAuditItemHistoryRequest = StockAuditItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective stock audit item
 *
 * @generated from message Scailo.StockAuditItemProspectiveInfoRequest
 */
class StockAuditItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the stock audit ID
     *
     * @generated from field: uint64 stock_audit_id = 10;
     */
    stockAuditId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "stock_audit_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditItemProspectiveInfoRequest, a, b);
    }
}
exports.StockAuditItemProspectiveInfoRequest = StockAuditItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.StockAuditsServicePaginationReq
 */
class StockAuditsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.STOCK_AUDIT_SORT_KEY sort_key = 5;
     */
    sortKey = STOCK_AUDIT_SORT_KEY.STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this stock audit
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(STOCK_AUDIT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServicePaginationReq, a, b);
    }
}
exports.StockAuditsServicePaginationReq = StockAuditsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.StockAuditsServicePaginationResponse
 */
class StockAuditsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.StockAudit payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: StockAudit, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServicePaginationResponse, a, b);
    }
}
exports.StockAuditsServicePaginationResponse = StockAuditsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.StockAuditsServiceFilterReq
 */
class StockAuditsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.STOCK_AUDIT_SORT_KEY sort_key = 5;
     */
    sortKey = STOCK_AUDIT_SORT_KEY.STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this stock audit
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the stock audit
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(STOCK_AUDIT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceFilterReq, a, b);
    }
}
exports.StockAuditsServiceFilterReq = StockAuditsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.StockAuditsServiceCountReq
 */
class StockAuditsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this stock audit
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the stock audit
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceCountReq, a, b);
    }
}
exports.StockAuditsServiceCountReq = StockAuditsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.StockAuditsServiceSearchAllReq
 */
class StockAuditsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.STOCK_AUDIT_SORT_KEY sort_key = 5;
     */
    sortKey = STOCK_AUDIT_SORT_KEY.STOCK_AUDIT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(STOCK_AUDIT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServiceSearchAllReq, a, b);
    }
}
exports.StockAuditsServiceSearchAllReq = StockAuditsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.StockAuditItemsSearchRequest
 */
class StockAuditItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.STOCK_AUDIT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = STOCK_AUDIT_ITEM_SORT_KEY.STOCK_AUDIT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.STOCK_AUDIT_ITEM_STATUS status = 7;
     */
    status = STOCK_AUDIT_ITEM_STATUS.STOCK_AUDIT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the stock audit
     *
     * @generated from field: uint64 stock_audit_id = 20;
     */
    stockAuditId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash
     *
     * @generated from field: string item_hash = 22;
     */
    itemHash = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(STOCK_AUDIT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(STOCK_AUDIT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "stock_audit_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditItemsSearchRequest, a, b);
    }
}
exports.StockAuditItemsSearchRequest = StockAuditItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.StockAuditsServicePaginatedItemsResponse
 */
class StockAuditsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.StockAuditItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StockAuditsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: StockAuditItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StockAuditsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StockAuditsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StockAuditsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StockAuditsServicePaginatedItemsResponse, a, b);
    }
}
exports.StockAuditsServicePaginatedItemsResponse = StockAuditsServicePaginatedItemsResponse;
