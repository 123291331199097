"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file forms_fields_data.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldDatumHistoryRequest = exports.FormFieldDatumList = exports.FormFieldDatumCreateRequest = exports.FormFieldDatum = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const forms_fields_scailo_pb_js_1 = require("./forms_fields.scailo_pb.js");
/**
 *
 * Describes the data stored in an individual form field data row of each dynamic form
 *
 * @generated from message Scailo.FormFieldDatum
 */
class FormFieldDatum extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The ID of the form field
     *
     * @generated from field: uint64 form_field_id = 10;
     */
    formFieldId = protobuf_1.protoInt64.zero;
    /**
     * The underlying form field (from the form_field_id)
     *
     * @generated from field: Scailo.FormField form_field = 20;
     */
    formField;
    /**
     * The UUID that this field belongs to
     *
     * @generated from field: string ref_uuid = 21;
     */
    refUuid = "";
    /**
     * The value entered by the user
     *
     * @generated from field: string value = 22;
     */
    value = "";
    /**
     * The list of values that have been selected by the user (in case of a checkbox or a dropdown)
     *
     * @generated from field: repeated string selected_values = 23;
     */
    selectedValues = [];
    /**
     * Denotes if this field requires approval by a competent authority
     *
     * @generated from field: bool need_approval = 24;
     */
    needApproval = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormFieldDatum";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "form_field_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "form_field", kind: "message", T: forms_fields_scailo_pb_js_1.FormField },
        { no: 21, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "selected_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 24, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormFieldDatum().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormFieldDatum().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormFieldDatum().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormFieldDatum, a, b);
    }
}
exports.FormFieldDatum = FormFieldDatum;
/**
 *
 * Describes the data required for creating a dynamic form field entry
 *
 * @generated from message Scailo.FormFieldDatumCreateRequest
 */
class FormFieldDatumCreateRequest extends protobuf_1.Message {
    /**
     * The ID of the corresponding form field
     *
     * @generated from field: uint64 form_field_id = 1;
     */
    formFieldId = protobuf_1.protoInt64.zero;
    /**
     * The value to be stored (for textarea and input)
     *
     * @generated from field: string value = 2;
     */
    value = "";
    /**
     * The list of selected values (for radio, checkbox, and select)
     *
     * @generated from field: repeated string selected_values = 3;
     */
    selectedValues = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormFieldDatumCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "form_field_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "selected_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormFieldDatumCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormFieldDatumCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormFieldDatumCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormFieldDatumCreateRequest, a, b);
    }
}
exports.FormFieldDatumCreateRequest = FormFieldDatumCreateRequest;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.FormFieldDatumList
 */
class FormFieldDatumList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.FormFieldDatum list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormFieldDatumList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FormFieldDatumList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormFieldDatumList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormFieldDatumList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormFieldDatumList, a, b);
    }
}
exports.FormFieldDatumList = FormFieldDatumList;
/**
 *
 * Describes the data required to fetch history of the given form field and the specified resource
 *
 * @generated from message Scailo.FormFieldDatumHistoryRequest
 */
class FormFieldDatumHistoryRequest extends protobuf_1.Message {
    /**
     * The ID of the corresponding form field
     *
     * @generated from field: uint64 form_field_id = 1;
     */
    formFieldId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the corresponding resource
     *
     * @generated from field: string ref_uuid = 2;
     */
    refUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FormFieldDatumHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "form_field_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new FormFieldDatumHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FormFieldDatumHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FormFieldDatumHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FormFieldDatumHistoryRequest, a, b);
    }
}
exports.FormFieldDatumHistoryRequest = FormFieldDatumHistoryRequest;
