"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file notes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesService = void 0;
const notes_scailo_pb_js_1 = require("./notes.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each note
 *
 * @generated from service Scailo.NotesService
 */
exports.NotesService = {
    typeName: "Scailo.NotesService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.NotesService.Create
         */
        create: {
            name: "Create",
            I: notes_scailo_pb_js_1.NotesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.NotesService.Update
         */
        update: {
            name: "Update",
            I: notes_scailo_pb_js_1.NotesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.NotesService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.NotesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.NotesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.NotesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.NotesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.NotesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.NotesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: notes_scailo_pb_js_1.Note,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.NotesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: notes_scailo_pb_js_1.Note,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.NotesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: notes_scailo_pb_js_1.NotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.NotesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: notes_scailo_pb_js_1.NotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.NotesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: notes_scailo_pb_js_1.NotesServicePaginationReq,
            O: notes_scailo_pb_js_1.NotesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.NotesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: notes_scailo_pb_js_1.NotesServiceSearchAllReq,
            O: notes_scailo_pb_js_1.NotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.NotesService.Filter
         */
        filter: {
            name: "Filter",
            I: notes_scailo_pb_js_1.NotesServiceFilterReq,
            O: notes_scailo_pb_js_1.NotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.NotesService.Count
         */
        count: {
            name: "Count",
            I: notes_scailo_pb_js_1.NotesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
