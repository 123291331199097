"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file inward_jobs_free_issue_materials.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InwardJobsFreeIssueMaterialsService = void 0;
const inward_jobs_free_issue_materials_scailo_pb_js_1 = require("./inward_jobs_free_issue_materials.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each inward job free issue material
 *
 * @generated from service Scailo.InwardJobsFreeIssueMaterialsService
 */
exports.InwardJobsFreeIssueMaterialsService = {
    typeName: "Scailo.InwardJobsFreeIssueMaterialsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Create
         */
        create: {
            name: "Create",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Draft
         */
        draft: {
            name: "Draft",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Inward Job Free Issue Material can be marked as completed
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.AddInwardJobFreeIssueMaterialItem
         */
        addInwardJobFreeIssueMaterialItem: {
            name: "AddInwardJobFreeIssueMaterialItem",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ModifyInwardJobFreeIssueMaterialItem
         */
        modifyInwardJobFreeIssueMaterialItem: {
            name: "ModifyInwardJobFreeIssueMaterialItem",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ApproveInwardJobFreeIssueMaterialItem
         */
        approveInwardJobFreeIssueMaterialItem: {
            name: "ApproveInwardJobFreeIssueMaterialItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.DeleteInwardJobFreeIssueMaterialItem
         */
        deleteInwardJobFreeIssueMaterialItem: {
            name: "DeleteInwardJobFreeIssueMaterialItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ReorderInwardJobFreeIssueMaterialItems
         */
        reorderInwardJobFreeIssueMaterialItems: {
            name: "ReorderInwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inward Job Free Issue Material Item by ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewInwardJobFreeIssueMaterialItemByID
         */
        viewInwardJobFreeIssueMaterialItemByID: {
            name: "ViewInwardJobFreeIssueMaterialItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job free issue material items for given inward job free issue material ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewApprovedInwardJobFreeIssueMaterialItems
         */
        viewApprovedInwardJobFreeIssueMaterialItems: {
            name: "ViewApprovedInwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job free issue material items for given inward job free issue material ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewUnapprovedInwardJobFreeIssueMaterialItems
         */
        viewUnapprovedInwardJobFreeIssueMaterialItems: {
            name: "ViewUnapprovedInwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the inward job free issue material item
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewInwardJobFreeIssueMaterialItemHistory
         */
        viewInwardJobFreeIssueMaterialItemHistory: {
            name: "ViewInwardJobFreeIssueMaterialItemHistory",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItemHistoryRequest,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job free issue material items for given inward job free issue material ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewPaginatedApprovedInwardJobFreeIssueMaterialItems
         */
        viewPaginatedApprovedInwardJobFreeIssueMaterialItems: {
            name: "ViewPaginatedApprovedInwardJobFreeIssueMaterialItems",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItemsSearchRequest,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job free issue material items for given inward job free issue material ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewPaginatedUnapprovedInwardJobFreeIssueMaterialItems
         */
        viewPaginatedUnapprovedInwardJobFreeIssueMaterialItems: {
            name: "ViewPaginatedUnapprovedInwardJobFreeIssueMaterialItems",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItemsSearchRequest,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through inward job free issue material items with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItemsSearchRequest,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterial,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterial,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServicePaginationReq,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given inward job free issue material
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward job free issue material item info for the given family ID and inward job free issue material ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewProspectiveInwardJobFreeIssueMaterialItem
         */
        viewProspectiveInwardJobFreeIssueMaterialItem: {
            name: "ViewProspectiveInwardJobFreeIssueMaterialItem",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobFreeIssueMaterialItemProspectiveInfoRequest,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download inward job free issue material with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the inward job free issue material with the given IdentifierUUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceSearchAllReq,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Filter
         */
        filter: {
            name: "Filter",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceFilterReq,
            O: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsService.Count
         */
        count: {
            name: "Count",
            I: inward_jobs_free_issue_materials_scailo_pb_js_1.InwardJobsFreeIssueMaterialsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
