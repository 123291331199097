import { setupLogoutHandler, setupUsernameDisplay } from "./login";
import * as routes from "./routes";

window.addEventListener("load", async (evt) => {
    evt.preventDefault();
    routes.start();
    if (location.pathname != "/") {
        setupLogoutHandler();
        setupUsernameDisplay();
    }
});