
import { Router } from "./../router";
import * as goodsdispatches from "./goodsdispatches/routes";
import * as salesreturns from "./salesreturns/routes";
import * as freeissues from "./freeissues/routes";
import * as freeissuereturns from "./freeissuereturns/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    goodsdispatches.Routes(r);
    salesreturns.Routes(r);
    freeissues.Routes(r);
    freeissuereturns.Routes(r);
}