"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file sales_quotations.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesQuotationContactsList = exports.SalesQuotationContact = exports.SalesQuotationsServiceContactCreateRequest = exports.SalesQuotationsServicePaginatedItemsResponse = exports.SalesQuotationItemsSearchRequest = exports.SalesQuotationReferencesList = exports.SalesQuotationReference = exports.SalesQuotationsServiceReferenceCreateRequest = exports.SalesQuotationsServiceSearchAllReq = exports.SalesQuotationsServiceCountReq = exports.SalesQuotationsServiceFilterReq = exports.SalesQuotationsServicePaginationResponse = exports.SalesQuotationsServicePaginationReq = exports.SalesQuotationItemProspectiveInfoRequest = exports.SalesQuotationItemHistoryRequest = exports.SalesQuotationItemsList = exports.SalesQuotationsList = exports.SalesQuotationItem = exports.SalesQuotationsServiceItemUpdateRequest = exports.SalesQuotationsServiceItemCreateRequest = exports.SalesQuotation = exports.SalesQuotationsServiceAutofillRequest = exports.SalesQuotationsServiceUpdateRequest = exports.SalesQuotationsServiceCreateRequest = exports.SALES_QUOTATION_ITEM_STATUS = exports.SALES_QUOTATION_ITEM_SORT_KEY = exports.SALES_QUOTATION_REFERENCE_REF_FROM = exports.SALES_QUOTATION_REFERENCE_CONTEXT = exports.SALES_QUOTATION_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_QUOTATION_SORT_KEY
 */
var SALES_QUOTATION_SORT_KEY;
(function (SALES_QUOTATION_SORT_KEY) {
    /**
     * Fetch quotationed results by id
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch quotationed results by the creation timestamp
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_CREATED_AT = 1;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_CREATED_AT"] = 1] = "SALES_QUOTATION_SORT_KEY_CREATED_AT";
    /**
     * Fetch quotationed results by the modified timestamp
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_QUOTATION_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch quotationed results by the approved on timestamp
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_APPROVED_ON"] = 3] = "SALES_QUOTATION_SORT_KEY_APPROVED_ON";
    /**
     * Fetch quotationed results by the approved by field
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_APPROVED_BY"] = 4] = "SALES_QUOTATION_SORT_KEY_APPROVED_BY";
    /**
     * Fetch quotationed results by the approver's role ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_QUOTATION_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch quotationed results by the approver's completed on timestamp
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_COMPLETED_ON = 6;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_COMPLETED_ON"] = 6] = "SALES_QUOTATION_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch quotationed results by the reference ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_REFERENCE_ID = 10;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_REFERENCE_ID"] = 10] = "SALES_QUOTATION_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch quotationed results by the final ref number
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALES_QUOTATION_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch quotationed results by the consignee client ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_CONSIGNEE_CLIENT_ID = 12;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_CONSIGNEE_CLIENT_ID"] = 12] = "SALES_QUOTATION_SORT_KEY_CONSIGNEE_CLIENT_ID";
    /**
     * Fetch quotationed results by the buyer client ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_BUYER_CLIENT_ID = 13;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_BUYER_CLIENT_ID"] = 13] = "SALES_QUOTATION_SORT_KEY_BUYER_CLIENT_ID";
    /**
     * Fetch quotationed results by the location ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_LOCATION_ID = 14;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_LOCATION_ID"] = 14] = "SALES_QUOTATION_SORT_KEY_LOCATION_ID";
    /**
     * Fetch quotationed results by the currency ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_CURRENCY_ID = 15;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_CURRENCY_ID"] = 15] = "SALES_QUOTATION_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch quotationed results by the project ID
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_PROJECT_ID = 16;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_PROJECT_ID"] = 16] = "SALES_QUOTATION_SORT_KEY_PROJECT_ID";
    /**
     * Fetch quotationed results by the advance payment
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_PAYMENT_ADVANCE = 17;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_PAYMENT_ADVANCE"] = 17] = "SALES_QUOTATION_SORT_KEY_PAYMENT_ADVANCE";
    /**
     * Fetch quotationed results by the amendment count
     *
     * @generated from enum value: SALES_QUOTATION_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    SALES_QUOTATION_SORT_KEY[SALES_QUOTATION_SORT_KEY["SALES_QUOTATION_SORT_KEY_AMENDMENT_COUNT"] = 18] = "SALES_QUOTATION_SORT_KEY_AMENDMENT_COUNT";
})(SALES_QUOTATION_SORT_KEY || (exports.SALES_QUOTATION_SORT_KEY = SALES_QUOTATION_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_QUOTATION_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_QUOTATION_SORT_KEY, "Scailo.SALES_QUOTATION_SORT_KEY", [
    { no: 0, name: "SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_QUOTATION_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_QUOTATION_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_QUOTATION_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_QUOTATION_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_QUOTATION_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALES_QUOTATION_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALES_QUOTATION_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALES_QUOTATION_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "SALES_QUOTATION_SORT_KEY_CONSIGNEE_CLIENT_ID" },
    { no: 13, name: "SALES_QUOTATION_SORT_KEY_BUYER_CLIENT_ID" },
    { no: 14, name: "SALES_QUOTATION_SORT_KEY_LOCATION_ID" },
    { no: 15, name: "SALES_QUOTATION_SORT_KEY_CURRENCY_ID" },
    { no: 16, name: "SALES_QUOTATION_SORT_KEY_PROJECT_ID" },
    { no: 17, name: "SALES_QUOTATION_SORT_KEY_PAYMENT_ADVANCE" },
    { no: 18, name: "SALES_QUOTATION_SORT_KEY_AMENDMENT_COUNT" },
]);
/**
 *
 * Describes the sales quotation reference context
 *
 * @generated from enum Scailo.SALES_QUOTATION_REFERENCE_CONTEXT
 */
var SALES_QUOTATION_REFERENCE_CONTEXT;
(function (SALES_QUOTATION_REFERENCE_CONTEXT) {
    /**
     * Denotes that context be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED = 0;
     */
    SALES_QUOTATION_REFERENCE_CONTEXT[SALES_QUOTATION_REFERENCE_CONTEXT["SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED"] = 0] = "SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED";
    /**
     * Denotes billing context
     *
     * @generated from enum value: SALES_QUOTATION_REFERENCE_CONTEXT_BILLING = 1;
     */
    SALES_QUOTATION_REFERENCE_CONTEXT[SALES_QUOTATION_REFERENCE_CONTEXT["SALES_QUOTATION_REFERENCE_CONTEXT_BILLING"] = 1] = "SALES_QUOTATION_REFERENCE_CONTEXT_BILLING";
})(SALES_QUOTATION_REFERENCE_CONTEXT || (exports.SALES_QUOTATION_REFERENCE_CONTEXT = SALES_QUOTATION_REFERENCE_CONTEXT = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_QUOTATION_REFERENCE_CONTEXT)
protobuf_1.proto3.util.setEnumType(SALES_QUOTATION_REFERENCE_CONTEXT, "Scailo.SALES_QUOTATION_REFERENCE_CONTEXT", [
    { no: 0, name: "SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_QUOTATION_REFERENCE_CONTEXT_BILLING" },
]);
/**
 *
 * Describes the possible options for ref_from field for a sales quotation reference
 *
 * @generated from enum Scailo.SALES_QUOTATION_REFERENCE_REF_FROM
 */
var SALES_QUOTATION_REFERENCE_REF_FROM;
(function (SALES_QUOTATION_REFERENCE_REF_FROM) {
    /**
     * Denotes that the ref from be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    SALES_QUOTATION_REFERENCE_REF_FROM[SALES_QUOTATION_REFERENCE_REF_FROM["SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED"] = 0] = "SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED";
    /**
     *   // Denotes inward job
     *   SALES_QUOTATION_REFERENCE_REF_FROM_INWARD_JOB = 1;
     * Denotes sales enquiry
     *
     * @generated from enum value: SALES_QUOTATION_REFERENCE_REF_FROM_SALES_ENQUIRY = 2;
     */
    SALES_QUOTATION_REFERENCE_REF_FROM[SALES_QUOTATION_REFERENCE_REF_FROM["SALES_QUOTATION_REFERENCE_REF_FROM_SALES_ENQUIRY"] = 2] = "SALES_QUOTATION_REFERENCE_REF_FROM_SALES_ENQUIRY";
})(SALES_QUOTATION_REFERENCE_REF_FROM || (exports.SALES_QUOTATION_REFERENCE_REF_FROM = SALES_QUOTATION_REFERENCE_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_QUOTATION_REFERENCE_REF_FROM)
protobuf_1.proto3.util.setEnumType(SALES_QUOTATION_REFERENCE_REF_FROM, "Scailo.SALES_QUOTATION_REFERENCE_REF_FROM", [
    { no: 0, name: "SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED" },
    { no: 2, name: "SALES_QUOTATION_REFERENCE_REF_FROM_SALES_ENQUIRY" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_QUOTATION_ITEM_SORT_KEY
 */
var SALES_QUOTATION_ITEM_SORT_KEY;
(function (SALES_QUOTATION_ITEM_SORT_KEY) {
    /**
     * Fetch quotationed results by id
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_QUOTATION_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch quotationed results by the creation timestamp
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SALES_QUOTATION_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch quotationed results by the modified timestamp
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_QUOTATION_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch quotationed results by the approved on timestamp
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch quotationed results by the approved by field
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch quotationed results by the approver's role ID
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_QUOTATION_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch quotationed results by the family ID
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "SALES_QUOTATION_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch quotationed results by the internal quantity
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SALES_QUOTATION_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch quotationed results by the client unit of material ID
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch quotationed results by the client quantity
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch quotationed results by the client family code
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
    /**
     * Fetch quotationed results by the unit price
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_UNIT_PRICE = 15;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_UNIT_PRICE"] = 15] = "SALES_QUOTATION_ITEM_SORT_KEY_UNIT_PRICE";
    /**
     * Fetch quotationed results by the tax group ID
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_TAX_GROUP_ID = 16;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_TAX_GROUP_ID"] = 16] = "SALES_QUOTATION_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch quotationed results by the discount
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_DISCOUNT = 17;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_DISCOUNT"] = 17] = "SALES_QUOTATION_ITEM_SORT_KEY_DISCOUNT";
    /**
     * Fetch quotationed results by the delivery date
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_SORT_KEY_DELIVERY_DATE = 18;
     */
    SALES_QUOTATION_ITEM_SORT_KEY[SALES_QUOTATION_ITEM_SORT_KEY["SALES_QUOTATION_ITEM_SORT_KEY_DELIVERY_DATE"] = 18] = "SALES_QUOTATION_ITEM_SORT_KEY_DELIVERY_DATE";
})(SALES_QUOTATION_ITEM_SORT_KEY || (exports.SALES_QUOTATION_ITEM_SORT_KEY = SALES_QUOTATION_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_QUOTATION_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_QUOTATION_ITEM_SORT_KEY, "Scailo.SALES_QUOTATION_ITEM_SORT_KEY", [
    { no: 0, name: "SALES_QUOTATION_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_QUOTATION_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_QUOTATION_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_QUOTATION_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_QUOTATION_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SALES_QUOTATION_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "SALES_QUOTATION_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "SALES_QUOTATION_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
    { no: 15, name: "SALES_QUOTATION_ITEM_SORT_KEY_UNIT_PRICE" },
    { no: 16, name: "SALES_QUOTATION_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 17, name: "SALES_QUOTATION_ITEM_SORT_KEY_DISCOUNT" },
    { no: 18, name: "SALES_QUOTATION_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of sales quotation items
 *
 * @generated from enum Scailo.SALES_QUOTATION_ITEM_STATUS
 */
var SALES_QUOTATION_ITEM_STATUS;
(function (SALES_QUOTATION_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_QUOTATION_ITEM_STATUS[SALES_QUOTATION_ITEM_STATUS["SALES_QUOTATION_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_QUOTATION_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the sales quotation items must have been approved
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_STATUS_APPROVED = 1;
     */
    SALES_QUOTATION_ITEM_STATUS[SALES_QUOTATION_ITEM_STATUS["SALES_QUOTATION_ITEM_STATUS_APPROVED"] = 1] = "SALES_QUOTATION_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the sales quotation items must be waiting for approval
     *
     * @generated from enum value: SALES_QUOTATION_ITEM_STATUS_UNAPPROVED = 2;
     */
    SALES_QUOTATION_ITEM_STATUS[SALES_QUOTATION_ITEM_STATUS["SALES_QUOTATION_ITEM_STATUS_UNAPPROVED"] = 2] = "SALES_QUOTATION_ITEM_STATUS_UNAPPROVED";
})(SALES_QUOTATION_ITEM_STATUS || (exports.SALES_QUOTATION_ITEM_STATUS = SALES_QUOTATION_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_QUOTATION_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_QUOTATION_ITEM_STATUS, "Scailo.SALES_QUOTATION_ITEM_STATUS", [
    { no: 0, name: "SALES_QUOTATION_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_QUOTATION_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SALES_QUOTATION_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalesQuotationsServiceCreateRequest
 */
class SalesQuotationsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales quotation
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceCreateRequest, a, b);
    }
}
exports.SalesQuotationsServiceCreateRequest = SalesQuotationsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalesQuotationsServiceUpdateRequest
 */
class SalesQuotationsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales quotation
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceUpdateRequest, a, b);
    }
}
exports.SalesQuotationsServiceUpdateRequest = SalesQuotationsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.SalesQuotationsServiceAutofillRequest
 */
class SalesQuotationsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceAutofillRequest, a, b);
    }
}
exports.SalesQuotationsServiceAutofillRequest = SalesQuotationsServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SalesQuotation
 */
class SalesQuotation extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales quotation
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this sales quotation
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this sales quotation
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this sales quotation was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales quotation
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The number of times that the sales quotation has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the sales quotation (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 30;
     */
    totalValue = 0;
    /**
     * The list of associated sales quotation items
     *
     * @generated from field: repeated Scailo.SalesQuotationItem list = 40;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotation";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 40, name: "list", kind: "message", T: SalesQuotationItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotation().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotation().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotation().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotation, a, b);
    }
}
exports.SalesQuotation = SalesQuotation;
/**
 *
 * Describes the parameters required to add an item to a sales quotation
 *
 * @generated from message Scailo.SalesQuotationsServiceItemCreateRequest
 */
class SalesQuotationsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being quotationed in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being quotationed in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceItemCreateRequest, a, b);
    }
}
exports.SalesQuotationsServiceItemCreateRequest = SalesQuotationsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a sales quotation
 *
 * @generated from message Scailo.SalesQuotationsServiceItemUpdateRequest
 */
class SalesQuotationsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being quotationed in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being quotationed in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceItemUpdateRequest, a, b);
    }
}
exports.SalesQuotationsServiceItemUpdateRequest = SalesQuotationsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a sales quotation
 *
 * @generated from message Scailo.SalesQuotationItem
 */
class SalesQuotationItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales quotation
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the sales squotation item with which this item is bundled with
     *
     * @generated from field: uint64 bundled_with_id = 11;
     */
    bundledWithId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being quotationed in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being quotationed in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "bundled_with_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationItem, a, b);
    }
}
exports.SalesQuotationItem = SalesQuotationItem;
/**
 *
 * Describes the message consisting of the list of sales quotations
 *
 * @generated from message Scailo.SalesQuotationsList
 */
class SalesQuotationsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesQuotation list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesQuotation, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsList, a, b);
    }
}
exports.SalesQuotationsList = SalesQuotationsList;
/**
 *
 * Describes the message consisting of the list of sales quotation items
 *
 * @generated from message Scailo.SalesQuotationItemsList
 */
class SalesQuotationItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesQuotationItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesQuotationItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationItemsList, a, b);
    }
}
exports.SalesQuotationItemsList = SalesQuotationItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SalesQuotationItemHistoryRequest
 */
class SalesQuotationItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationItemHistoryRequest, a, b);
    }
}
exports.SalesQuotationItemHistoryRequest = SalesQuotationItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective sales quotation item
 *
 * @generated from message Scailo.SalesQuotationItemProspectiveInfoRequest
 */
class SalesQuotationItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material (if 0, the value is ignored while retrieving records)
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationItemProspectiveInfoRequest, a, b);
    }
}
exports.SalesQuotationItemProspectiveInfoRequest = SalesQuotationItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalesQuotationsServicePaginationReq
 */
class SalesQuotationsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_QUOTATION_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_QUOTATION_SORT_KEY.SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this sales quotation
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServicePaginationReq, a, b);
    }
}
exports.SalesQuotationsServicePaginationReq = SalesQuotationsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalesQuotationsServicePaginationResponse
 */
class SalesQuotationsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesQuotation payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesQuotation, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServicePaginationResponse, a, b);
    }
}
exports.SalesQuotationsServicePaginationResponse = SalesQuotationsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalesQuotationsServiceFilterReq
 */
class SalesQuotationsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_QUOTATION_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_QUOTATION_SORT_KEY.SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales quotation
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales quotation
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceFilterReq, a, b);
    }
}
exports.SalesQuotationsServiceFilterReq = SalesQuotationsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalesQuotationsServiceCountReq
 */
class SalesQuotationsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales quotation
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales quotation
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceCountReq, a, b);
    }
}
exports.SalesQuotationsServiceCountReq = SalesQuotationsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalesQuotationsServiceSearchAllReq
 */
class SalesQuotationsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_QUOTATION_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_QUOTATION_SORT_KEY.SALES_QUOTATION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceSearchAllReq, a, b);
    }
}
exports.SalesQuotationsServiceSearchAllReq = SalesQuotationsServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a sales quotation reference
 *
 * @generated from message Scailo.SalesQuotationsServiceReferenceCreateRequest
 */
class SalesQuotationsServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.SALES_QUOTATION_REFERENCE_CONTEXT context = 11;
     */
    context = SALES_QUOTATION_REFERENCE_CONTEXT.SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.SALES_QUOTATION_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = SALES_QUOTATION_REFERENCE_REF_FROM.SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceReferenceCreateRequest, a, b);
    }
}
exports.SalesQuotationsServiceReferenceCreateRequest = SalesQuotationsServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales quotation reference
 *
 * @generated from message Scailo.SalesQuotationReference
 */
class SalesQuotationReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales quotation
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.SALES_QUOTATION_REFERENCE_CONTEXT context = 11;
     */
    context = SALES_QUOTATION_REFERENCE_CONTEXT.SALES_QUOTATION_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.SALES_QUOTATION_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = SALES_QUOTATION_REFERENCE_REF_FROM.SALES_QUOTATION_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationReference, a, b);
    }
}
exports.SalesQuotationReference = SalesQuotationReference;
/**
 *
 * Describes the message consisting of the list of sales quotation references
 *
 * @generated from message Scailo.SalesQuotationReferencesList
 */
class SalesQuotationReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesQuotationReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesQuotationReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationReferencesList, a, b);
    }
}
exports.SalesQuotationReferencesList = SalesQuotationReferencesList;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SalesQuotationItemsSearchRequest
 */
class SalesQuotationItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_QUOTATION_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_QUOTATION_ITEM_SORT_KEY.SALES_QUOTATION_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SALES_QUOTATION_ITEM_STATUS status = 7;
     */
    status = SALES_QUOTATION_ITEM_STATUS.SALES_QUOTATION_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the sales quotation
     *
     * @generated from field: uint64 sales_quotation_id = 20;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the sales squotation item with which this item is bundled with
     *
     * @generated from field: uint64 bundled_with_id = 21;
     */
    bundledWithId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 26;
     */
    clientFamilyCode = "";
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 27;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales quotation
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_QUOTATION_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "bundled_with_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationItemsSearchRequest, a, b);
    }
}
exports.SalesQuotationItemsSearchRequest = SalesQuotationItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SalesQuotationsServicePaginatedItemsResponse
 */
class SalesQuotationsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesQuotationItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesQuotationItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServicePaginatedItemsResponse, a, b);
    }
}
exports.SalesQuotationsServicePaginatedItemsResponse = SalesQuotationsServicePaginatedItemsResponse;
/**
 *
 * Describes the parameters necessary to create a sales quotation contact
 *
 * @generated from message Scailo.SalesQuotationsServiceContactCreateRequest
 */
class SalesQuotationsServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationsServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationsServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationsServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationsServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationsServiceContactCreateRequest, a, b);
    }
}
exports.SalesQuotationsServiceContactCreateRequest = SalesQuotationsServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales quotation contact
 *
 * @generated from message Scailo.SalesQuotationContact
 */
class SalesQuotationContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales quotation
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales quotation ID
     *
     * @generated from field: uint64 sales_quotation_id = 10;
     */
    salesQuotationId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_quotation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationContact, a, b);
    }
}
exports.SalesQuotationContact = SalesQuotationContact;
/**
 *
 * Describes the message consisting of the list of sales quotation contacts
 *
 * @generated from message Scailo.SalesQuotationContactsList
 */
class SalesQuotationContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesQuotationContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesQuotationContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesQuotationContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesQuotationContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesQuotationContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesQuotationContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesQuotationContactsList, a, b);
    }
}
exports.SalesQuotationContactsList = SalesQuotationContactsList;
