"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file attendances.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendancesService = void 0;
const attendances_scailo_pb_js_1 = require("./attendances.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each attendance
 *
 * @generated from service Scailo.AttendancesService
 */
exports.AttendancesService = {
    typeName: "Scailo.AttendancesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.AttendancesService.Create
         */
        create: {
            name: "Create",
            I: attendances_scailo_pb_js_1.AttendancesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.AttendancesService.Draft
         */
        draft: {
            name: "Draft",
            I: attendances_scailo_pb_js_1.AttendancesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.AttendancesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: attendances_scailo_pb_js_1.AttendancesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.AttendancesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.AttendancesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.AttendancesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.AttendancesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.AttendancesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: attendances_scailo_pb_js_1.AttendancesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.AttendancesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.AttendancesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.AttendancesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.AttendancesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.AttendancesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (Identifier);
         *
         * @generated from rpc Scailo.AttendancesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Store entry time
         *
         * @generated from rpc Scailo.AttendancesService.RecordImageEntry
         */
        recordImageEntry: {
            name: "RecordImageEntry",
            I: attendances_scailo_pb_js_1.AttendancesServiceImageEntryRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Store exit time
         *
         * @generated from rpc Scailo.AttendancesService.RecordImageExit
         */
        recordImageExit: {
            name: "RecordImageExit",
            I: attendances_scailo_pb_js_1.AttendancesServiceImageExitRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.AttendancesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: attendances_scailo_pb_js_1.Attendance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.AttendancesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: attendances_scailo_pb_js_1.AttendancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.AttendancesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: attendances_scailo_pb_js_1.AttendancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.AttendancesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: attendances_scailo_pb_js_1.AttendancesServicePaginationReq,
            O: attendances_scailo_pb_js_1.AttendancesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View entry image for attendance ID
         *
         * @generated from rpc Scailo.AttendancesService.ViewEntryImage
         */
        viewEntryImage: {
            name: "ViewEntryImage",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Confirm that the entry image of the given attendance ID belongs to the user
         *
         * @generated from rpc Scailo.AttendancesService.ConfirmEntryImage
         */
        confirmEntryImage: {
            name: "ConfirmEntryImage",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View exit image for attendance ID
         *
         * @generated from rpc Scailo.AttendancesService.ViewExitImage
         */
        viewExitImage: {
            name: "ViewExitImage",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Confirm that the exit image of the given attendance ID belongs to the user
         *
         * @generated from rpc Scailo.AttendancesService.ConfirmExitImage
         */
        confirmExitImage: {
            name: "ConfirmExitImage",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View entry GPS coordinates for attendance ID
         *
         * @generated from rpc Scailo.AttendancesService.ViewEntryGPSCoordinates
         */
        viewEntryGPSCoordinates: {
            name: "ViewEntryGPSCoordinates",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.GPSCoordinatesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View exit GPS coordinates for attendance ID
         *
         * @generated from rpc Scailo.AttendancesService.ViewExitGPSCoordinates
         */
        viewExitGPSCoordinates: {
            name: "ViewExitGPSCoordinates",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.GPSCoordinatesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the open attendance for a user (with the given identifier), if available. Returns an empty instance if no attendance is available
         *
         * @generated from rpc Scailo.AttendancesService.ViewOpenAttendanceForUser
         */
        viewOpenAttendanceForUser: {
            name: "ViewOpenAttendanceForUser",
            I: base_scailo_pb_js_1.Identifier,
            O: attendances_scailo_pb_js_1.Attendance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.AttendancesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: attendances_scailo_pb_js_1.AttendancesServiceSearchAllReq,
            O: attendances_scailo_pb_js_1.AttendancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.AttendancesService.Filter
         */
        filter: {
            name: "Filter",
            I: attendances_scailo_pb_js_1.AttendancesServiceFilterReq,
            O: attendances_scailo_pb_js_1.AttendancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if a user (with the given identifier) has an open attendance (attendance with only entry and no exit)
         *
         * @generated from rpc Scailo.AttendancesService.EmployeeHasOpenAttendance
         */
        employeeHasOpenAttendance: {
            name: "EmployeeHasOpenAttendance",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.AttendancesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.AttendancesService.Count
         */
        count: {
            name: "Count",
            I: attendances_scailo_pb_js_1.AttendancesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
