"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file production_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductionIndentIssuedStatisticsList = exports.ProductionIndentIssuedStatistics = exports.ProductionIndentsServicePaginatedItemsResponse = exports.ProductionIndentItemsSearchRequest = exports.ProductionIndentsServiceSearchAllReq = exports.ProductionIndentsServiceCountReq = exports.ProductionIndentsServiceFilterReq = exports.ProductionIndentsServicePaginationResponse = exports.ProductionIndentsServicePaginationReq = exports.ProductionIndentItemProspectiveInfoRequest = exports.ProductionIndentItemHistoryRequest = exports.ProductionIndentsItemsList = exports.ProductionIndentsList = exports.ProductionIndentItem = exports.ProductionIndentsServiceItemUpdateRequest = exports.ProductionIndentsServiceItemCreateRequest = exports.ProductionIndent = exports.ProductionIndentsServiceAutofillRequest = exports.ProductionIndentsServiceUpdateRequest = exports.ProductionIndentsServiceCreateRequest = exports.PRODUCTION_INDENT_ITEM_STATUS = exports.PRODUCTION_INDENT_ITEM_SORT_KEY = exports.PRODUCTION_INDENT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PRODUCTION_INDENT_SORT_KEY
 */
var PRODUCTION_INDENT_SORT_KEY;
(function (PRODUCTION_INDENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_CREATED_AT = 1;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_CREATED_AT"] = 1] = "PRODUCTION_INDENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_MODIFIED_AT = 2;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_MODIFIED_AT"] = 2] = "PRODUCTION_INDENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_APPROVED_ON = 3;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_APPROVED_ON"] = 3] = "PRODUCTION_INDENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_APPROVED_BY = 4;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_APPROVED_BY"] = 4] = "PRODUCTION_INDENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PRODUCTION_INDENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_COMPLETED_ON = 6;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_COMPLETED_ON"] = 6] = "PRODUCTION_INDENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_REFERENCE_ID = 10;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_REFERENCE_ID"] = 10] = "PRODUCTION_INDENT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PRODUCTION_INDENT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: PRODUCTION_INDENT_SORT_KEY_LOCATION_ID = 12;
     */
    PRODUCTION_INDENT_SORT_KEY[PRODUCTION_INDENT_SORT_KEY["PRODUCTION_INDENT_SORT_KEY_LOCATION_ID"] = 12] = "PRODUCTION_INDENT_SORT_KEY_LOCATION_ID";
})(PRODUCTION_INDENT_SORT_KEY || (exports.PRODUCTION_INDENT_SORT_KEY = PRODUCTION_INDENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_INDENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PRODUCTION_INDENT_SORT_KEY, "Scailo.PRODUCTION_INDENT_SORT_KEY", [
    { no: 0, name: "PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_INDENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PRODUCTION_INDENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PRODUCTION_INDENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PRODUCTION_INDENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PRODUCTION_INDENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PRODUCTION_INDENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PRODUCTION_INDENT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PRODUCTION_INDENT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "PRODUCTION_INDENT_SORT_KEY_LOCATION_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PRODUCTION_INDENT_ITEM_SORT_KEY
 */
var PRODUCTION_INDENT_ITEM_SORT_KEY;
(function (PRODUCTION_INDENT_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PRODUCTION_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PRODUCTION_INDENT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PRODUCTION_INDENT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PRODUCTION_INDENT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the internal quantity
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    PRODUCTION_INDENT_ITEM_SORT_KEY[PRODUCTION_INDENT_ITEM_SORT_KEY["PRODUCTION_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "PRODUCTION_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY";
})(PRODUCTION_INDENT_ITEM_SORT_KEY || (exports.PRODUCTION_INDENT_ITEM_SORT_KEY = PRODUCTION_INDENT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_INDENT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PRODUCTION_INDENT_ITEM_SORT_KEY, "Scailo.PRODUCTION_INDENT_ITEM_SORT_KEY", [
    { no: 0, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PRODUCTION_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of production indent items
 *
 * @generated from enum Scailo.PRODUCTION_INDENT_ITEM_STATUS
 */
var PRODUCTION_INDENT_ITEM_STATUS;
(function (PRODUCTION_INDENT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PRODUCTION_INDENT_ITEM_STATUS[PRODUCTION_INDENT_ITEM_STATUS["PRODUCTION_INDENT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PRODUCTION_INDENT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the production indent items must have been approved
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_STATUS_APPROVED = 1;
     */
    PRODUCTION_INDENT_ITEM_STATUS[PRODUCTION_INDENT_ITEM_STATUS["PRODUCTION_INDENT_ITEM_STATUS_APPROVED"] = 1] = "PRODUCTION_INDENT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the production indent items must be waiting for approval
     *
     * @generated from enum value: PRODUCTION_INDENT_ITEM_STATUS_UNAPPROVED = 2;
     */
    PRODUCTION_INDENT_ITEM_STATUS[PRODUCTION_INDENT_ITEM_STATUS["PRODUCTION_INDENT_ITEM_STATUS_UNAPPROVED"] = 2] = "PRODUCTION_INDENT_ITEM_STATUS_UNAPPROVED";
})(PRODUCTION_INDENT_ITEM_STATUS || (exports.PRODUCTION_INDENT_ITEM_STATUS = PRODUCTION_INDENT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_INDENT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PRODUCTION_INDENT_ITEM_STATUS, "Scailo.PRODUCTION_INDENT_ITEM_STATUS", [
    { no: 0, name: "PRODUCTION_INDENT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_INDENT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PRODUCTION_INDENT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ProductionIndentsServiceCreateRequest
 */
class ProductionIndentsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * Stores if the indent is associated to a production plan
     *
     * @generated from field: string production_ref_for = 16;
     */
    productionRefFor = "";
    /**
     * Stores the production plan ID if production_ref_for is production-plan
     *
     * @generated from field: uint64 production_ref_id = 17;
     */
    productionRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the indent should be created on the basis of a work order or a family
     *
     * @generated from field: string indent_ref_for = 18;
     */
    indentRefFor = "";
    /**
     * Stores the ID of the associated family or work order
     *
     * @generated from field: uint64 indent_ref_id = 19;
     */
    indentRefId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "production_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "production_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "indent_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "indent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceCreateRequest, a, b);
    }
}
exports.ProductionIndentsServiceCreateRequest = ProductionIndentsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ProductionIndentsServiceUpdateRequest
 */
class ProductionIndentsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceUpdateRequest, a, b);
    }
}
exports.ProductionIndentsServiceUpdateRequest = ProductionIndentsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.ProductionIndentsServiceAutofillRequest
 */
class ProductionIndentsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Denotes if all the equation dependencies (if applicable) should also be automatically added to the production indent
     *
     * @generated from field: bool populate_using_equation_dependencies = 3;
     */
    populateUsingEquationDependencies = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "populate_using_equation_dependencies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceAutofillRequest, a, b);
    }
}
exports.ProductionIndentsServiceAutofillRequest = ProductionIndentsServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ProductionIndent
 */
class ProductionIndent extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this production indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this production indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this production indent
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this production indent was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * Stores if the indent is associated to a production plan
     *
     * @generated from field: string production_ref_for = 16;
     */
    productionRefFor = "";
    /**
     * Stores the production plan ID if production_ref_for is production-plan
     *
     * @generated from field: uint64 production_ref_id = 17;
     */
    productionRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the indent should be created on the basis of a work order or a family
     *
     * @generated from field: string indent_ref_for = 18;
     */
    indentRefFor = "";
    /**
     * Stores the ID of the associated family or work order
     *
     * @generated from field: uint64 indent_ref_id = 19;
     */
    indentRefId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated production indent items
     *
     * @generated from field: repeated Scailo.ProductionIndentItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndent";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "production_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "production_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "indent_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "indent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "list", kind: "message", T: ProductionIndentItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndent().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndent().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndent().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndent, a, b);
    }
}
exports.ProductionIndent = ProductionIndent;
/**
 *
 * Describes the parameters required to add an item to a production indent
 *
 * @generated from message Scailo.ProductionIndentsServiceItemCreateRequest
 */
class ProductionIndentsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the production indent ID
     *
     * @generated from field: uint64 production_indent_id = 10;
     */
    productionIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "production_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceItemCreateRequest, a, b);
    }
}
exports.ProductionIndentsServiceItemCreateRequest = ProductionIndentsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a production indent
 *
 * @generated from message Scailo.ProductionIndentsServiceItemUpdateRequest
 */
class ProductionIndentsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceItemUpdateRequest, a, b);
    }
}
exports.ProductionIndentsServiceItemUpdateRequest = ProductionIndentsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a production indent
 *
 * @generated from message Scailo.ProductionIndentItem
 */
class ProductionIndentItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this production indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the production indent ID
     *
     * @generated from field: uint64 production_indent_id = 10;
     */
    productionIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity required
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "production_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentItem, a, b);
    }
}
exports.ProductionIndentItem = ProductionIndentItem;
/**
 *
 * Describes the message consisting of the list of production indents
 *
 * @generated from message Scailo.ProductionIndentsList
 */
class ProductionIndentsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProductionIndent list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProductionIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsList, a, b);
    }
}
exports.ProductionIndentsList = ProductionIndentsList;
/**
 *
 * Describes the message consisting of the list of production indent items
 *
 * @generated from message Scailo.ProductionIndentsItemsList
 */
class ProductionIndentsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProductionIndentItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProductionIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsItemsList, a, b);
    }
}
exports.ProductionIndentsItemsList = ProductionIndentsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ProductionIndentItemHistoryRequest
 */
class ProductionIndentItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the production indent ID
     *
     * @generated from field: uint64 production_indent_id = 10;
     */
    productionIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "production_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentItemHistoryRequest, a, b);
    }
}
exports.ProductionIndentItemHistoryRequest = ProductionIndentItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective production indent item
 *
 * @generated from message Scailo.ProductionIndentItemProspectiveInfoRequest
 */
class ProductionIndentItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the production indent ID
     *
     * @generated from field: uint64 production_indent_id = 10;
     */
    productionIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "production_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentItemProspectiveInfoRequest, a, b);
    }
}
exports.ProductionIndentItemProspectiveInfoRequest = ProductionIndentItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ProductionIndentsServicePaginationReq
 */
class ProductionIndentsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_INDENT_SORT_KEY.PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this production indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_INDENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServicePaginationReq, a, b);
    }
}
exports.ProductionIndentsServicePaginationReq = ProductionIndentsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ProductionIndentsServicePaginationResponse
 */
class ProductionIndentsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProductionIndent payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProductionIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServicePaginationResponse, a, b);
    }
}
exports.ProductionIndentsServicePaginationResponse = ProductionIndentsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ProductionIndentsServiceFilterReq
 */
class ProductionIndentsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_INDENT_SORT_KEY.PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this production indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    /**
     * Stores if the indent is associated to a production plan
     *
     * @generated from field: string production_ref_for = 26;
     */
    productionRefFor = "";
    /**
     * Stores the production plan ID if production_ref_for is production-plan
     *
     * @generated from field: uint64 production_ref_id = 27;
     */
    productionRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the indent should be created on the basis of a work order or a family
     *
     * @generated from field: string indent_ref_for = 28;
     */
    indentRefFor = "";
    /**
     * Stores the ID of the associated family or work order
     *
     * @generated from field: uint64 indent_ref_id = 29;
     */
    indentRefId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_INDENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "production_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "production_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "indent_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "indent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceFilterReq, a, b);
    }
}
exports.ProductionIndentsServiceFilterReq = ProductionIndentsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ProductionIndentsServiceCountReq
 */
class ProductionIndentsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this production indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    /**
     * Stores if the indent is associated to a production plan
     *
     * @generated from field: string production_ref_for = 26;
     */
    productionRefFor = "";
    /**
     * Stores the production plan ID if production_ref_for is production-plan
     *
     * @generated from field: uint64 production_ref_id = 27;
     */
    productionRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the indent should be created on the basis of a work order or a family
     *
     * @generated from field: string indent_ref_for = 28;
     */
    indentRefFor = "";
    /**
     * Stores the ID of the associated family or work order
     *
     * @generated from field: uint64 indent_ref_id = 29;
     */
    indentRefId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "production_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "production_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "indent_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "indent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceCountReq, a, b);
    }
}
exports.ProductionIndentsServiceCountReq = ProductionIndentsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ProductionIndentsServiceSearchAllReq
 */
class ProductionIndentsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_INDENT_SORT_KEY.PRODUCTION_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    /**
     * Stores if the indent is associated to a production plan
     *
     * @generated from field: string production_ref_for = 26;
     */
    productionRefFor = "";
    /**
     * Stores the production plan ID if production_ref_for is production-plan
     *
     * @generated from field: uint64 production_ref_id = 27;
     */
    productionRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the indent should be created on the basis of a work order or a family
     *
     * @generated from field: string indent_ref_for = 28;
     */
    indentRefFor = "";
    /**
     * Stores the ID of the associated family or work order
     *
     * @generated from field: uint64 indent_ref_id = 29;
     */
    indentRefId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_INDENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "production_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "production_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "indent_ref_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "indent_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServiceSearchAllReq, a, b);
    }
}
exports.ProductionIndentsServiceSearchAllReq = ProductionIndentsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.ProductionIndentItemsSearchRequest
 */
class ProductionIndentItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_INDENT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_INDENT_ITEM_SORT_KEY.PRODUCTION_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PRODUCTION_INDENT_ITEM_STATUS status = 7;
     */
    status = PRODUCTION_INDENT_ITEM_STATUS.PRODUCTION_INDENT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the production indent
     *
     * @generated from field: uint64 production_indent_id = 20;
     */
    productionIndentId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_INDENT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_INDENT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "production_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentItemsSearchRequest, a, b);
    }
}
exports.ProductionIndentItemsSearchRequest = ProductionIndentItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.ProductionIndentsServicePaginatedItemsResponse
 */
class ProductionIndentsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProductionIndentItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProductionIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentsServicePaginatedItemsResponse, a, b);
    }
}
exports.ProductionIndentsServicePaginatedItemsResponse = ProductionIndentsServicePaginatedItemsResponse;
/**
 *
 * Describes the issued statistics of the production indent
 *
 * @generated from message Scailo.ProductionIndentIssuedStatistics
 */
class ProductionIndentIssuedStatistics extends protobuf_1.Message {
    /**
     * Stores the ID of the family
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the indented quantity
     *
     * @generated from field: uint64 indented_quantity = 2;
     */
    indentedQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the unapproved issued quantity
     *
     * @generated from field: uint64 unapproved_issued_quantity = 3;
     */
    unapprovedIssuedQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the approved issued quantity
     *
     * @generated from field: uint64 approved_issued_quantity = 4;
     */
    approvedIssuedQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentIssuedStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "indented_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "unapproved_issued_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "approved_issued_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentIssuedStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentIssuedStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentIssuedStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentIssuedStatistics, a, b);
    }
}
exports.ProductionIndentIssuedStatistics = ProductionIndentIssuedStatistics;
/**
 *
 * Describes the list of issued statistics of the production indent
 *
 * @generated from message Scailo.ProductionIndentIssuedStatisticsList
 */
class ProductionIndentIssuedStatisticsList extends protobuf_1.Message {
    /**
     * @generated from field: repeated Scailo.ProductionIndentIssuedStatistics list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionIndentIssuedStatisticsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProductionIndentIssuedStatistics, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionIndentIssuedStatisticsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionIndentIssuedStatisticsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionIndentIssuedStatisticsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionIndentIssuedStatisticsList, a, b);
    }
}
exports.ProductionIndentIssuedStatisticsList = ProductionIndentIssuedStatisticsList;
