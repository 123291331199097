"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file inward_jobs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InwardJobContactsList = exports.InwardJobContact = exports.InwardJobsServiceContactCreateRequest = exports.InwardJobsServicePaginatedOutwardItemsResponse = exports.InwardJobOutwardItemsSearchRequest = exports.InwardJobOutwardItemProspectiveInfoRequest = exports.InwardJobOutwardItemHistoryRequest = exports.InwardJobsOutwardItemsList = exports.InwardJobOutwardItem = exports.InwardJobsServiceOutwardItemUpdateRequest = exports.InwardJobsServiceMultipleOutwardItemsCreateRequest = exports.InwardJobsServiceMultipleOutwardItemsSingleton = exports.InwardJobsServiceOutwardItemCreateRequest = exports.InwardJobsServicePaginatedInwardItemsResponse = exports.InwardJobInwardItemsSearchRequest = exports.InwardJobsServiceSearchAllReq = exports.InwardJobsServiceCountReq = exports.InwardJobsServiceFilterReq = exports.InwardJobsServicePaginationResponse = exports.InwardJobsServicePaginationReq = exports.InwardJobInwardItemProspectiveInfoRequest = exports.InwardJobInwardItemHistoryRequest = exports.InwardJobsInwardItemsList = exports.InwardJobsList = exports.InwardJobInwardItem = exports.InwardJobsServiceInwardItemUpdateRequest = exports.InwardJobsServiceMultipleInwardItemsCreateRequest = exports.InwardJobsServiceMultipleInwardItemsSingleton = exports.InwardJobsServiceInwardItemCreateRequest = exports.InwardJob = exports.InwardJobAncillaryParameters = exports.InwardJobsServiceAutofillRequest = exports.InwardJobsServiceUpdateRequest = exports.InwardJobsServiceCreateRequest = exports.INWARD_JOB_OUTWARD_ITEM_STATUS = exports.INWARD_JOB_OUTWARD_ITEM_SORT_KEY = exports.INWARD_JOB_INWARD_ITEM_STATUS = exports.INWARD_JOB_INWARD_ITEM_SORT_KEY = exports.INWARD_JOB_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INWARD_JOB_SORT_KEY
 */
var INWARD_JOB_SORT_KEY;
(function (INWARD_JOB_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INWARD_JOB_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_CREATED_AT = 1;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_CREATED_AT"] = 1] = "INWARD_JOB_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_MODIFIED_AT = 2;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_MODIFIED_AT"] = 2] = "INWARD_JOB_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_APPROVED_ON = 3;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_APPROVED_ON"] = 3] = "INWARD_JOB_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_APPROVED_BY = 4;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_APPROVED_BY"] = 4] = "INWARD_JOB_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "INWARD_JOB_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_COMPLETED_ON = 6;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_COMPLETED_ON"] = 6] = "INWARD_JOB_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_REFERENCE_ID = 10;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_REFERENCE_ID"] = 10] = "INWARD_JOB_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "INWARD_JOB_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the consignee client ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_CONSIGNEE_CLIENT_ID = 12;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_CONSIGNEE_CLIENT_ID"] = 12] = "INWARD_JOB_SORT_KEY_CONSIGNEE_CLIENT_ID";
    /**
     * Fetch ordered results by the buyer client ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_BUYER_CLIENT_ID = 13;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_BUYER_CLIENT_ID"] = 13] = "INWARD_JOB_SORT_KEY_BUYER_CLIENT_ID";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_LOCATION_ID = 14;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_LOCATION_ID"] = 14] = "INWARD_JOB_SORT_KEY_LOCATION_ID";
    /**
     * Fetch ordered results by the project ID
     *
     * @generated from enum value: INWARD_JOB_SORT_KEY_PROJECT_ID = 15;
     */
    INWARD_JOB_SORT_KEY[INWARD_JOB_SORT_KEY["INWARD_JOB_SORT_KEY_PROJECT_ID"] = 15] = "INWARD_JOB_SORT_KEY_PROJECT_ID";
})(INWARD_JOB_SORT_KEY || (exports.INWARD_JOB_SORT_KEY = INWARD_JOB_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_SORT_KEY, "Scailo.INWARD_JOB_SORT_KEY", [
    { no: 0, name: "INWARD_JOB_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INWARD_JOB_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "INWARD_JOB_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "INWARD_JOB_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "INWARD_JOB_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "INWARD_JOB_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "INWARD_JOB_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "INWARD_JOB_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "INWARD_JOB_SORT_KEY_CONSIGNEE_CLIENT_ID" },
    { no: 13, name: "INWARD_JOB_SORT_KEY_BUYER_CLIENT_ID" },
    { no: 14, name: "INWARD_JOB_SORT_KEY_LOCATION_ID" },
    { no: 15, name: "INWARD_JOB_SORT_KEY_PROJECT_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INWARD_JOB_INWARD_ITEM_SORT_KEY
 */
var INWARD_JOB_INWARD_ITEM_SORT_KEY;
(function (INWARD_JOB_INWARD_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT"] = 1] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the internal quantity
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch results by the client uom ID
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch results by the client quantity
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch results by the client family code
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    INWARD_JOB_INWARD_ITEM_SORT_KEY[INWARD_JOB_INWARD_ITEM_SORT_KEY["INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
})(INWARD_JOB_INWARD_ITEM_SORT_KEY || (exports.INWARD_JOB_INWARD_ITEM_SORT_KEY = INWARD_JOB_INWARD_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_INWARD_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_INWARD_ITEM_SORT_KEY, "Scailo.INWARD_JOB_INWARD_ITEM_SORT_KEY", [
    { no: 0, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "INWARD_JOB_INWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
]);
/**
 *
 * Describes the applicable statuses of inward job inward items
 *
 * @generated from enum Scailo.INWARD_JOB_INWARD_ITEM_STATUS
 */
var INWARD_JOB_INWARD_ITEM_STATUS;
(function (INWARD_JOB_INWARD_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    INWARD_JOB_INWARD_ITEM_STATUS[INWARD_JOB_INWARD_ITEM_STATUS["INWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "INWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the inward job inward items must have been approved
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_STATUS_APPROVED = 1;
     */
    INWARD_JOB_INWARD_ITEM_STATUS[INWARD_JOB_INWARD_ITEM_STATUS["INWARD_JOB_INWARD_ITEM_STATUS_APPROVED"] = 1] = "INWARD_JOB_INWARD_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the inward job inward items must be waiting for approval
     *
     * @generated from enum value: INWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED = 2;
     */
    INWARD_JOB_INWARD_ITEM_STATUS[INWARD_JOB_INWARD_ITEM_STATUS["INWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED"] = 2] = "INWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED";
})(INWARD_JOB_INWARD_ITEM_STATUS || (exports.INWARD_JOB_INWARD_ITEM_STATUS = INWARD_JOB_INWARD_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_INWARD_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_INWARD_ITEM_STATUS, "Scailo.INWARD_JOB_INWARD_ITEM_STATUS", [
    { no: 0, name: "INWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_INWARD_ITEM_STATUS_APPROVED" },
    { no: 2, name: "INWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INWARD_JOB_OUTWARD_ITEM_SORT_KEY
 */
var INWARD_JOB_OUTWARD_ITEM_SORT_KEY;
(function (INWARD_JOB_OUTWARD_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT"] = 1] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the internal quantity
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the client unit of material ID
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch ordered results by the client quantity
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch ordered results by the client family code
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_SORT_KEY_DELIVERY_DATE = 15;
     */
    INWARD_JOB_OUTWARD_ITEM_SORT_KEY[INWARD_JOB_OUTWARD_ITEM_SORT_KEY["INWARD_JOB_OUTWARD_ITEM_SORT_KEY_DELIVERY_DATE"] = 15] = "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_DELIVERY_DATE";
})(INWARD_JOB_OUTWARD_ITEM_SORT_KEY || (exports.INWARD_JOB_OUTWARD_ITEM_SORT_KEY = INWARD_JOB_OUTWARD_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_OUTWARD_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_OUTWARD_ITEM_SORT_KEY, "Scailo.INWARD_JOB_OUTWARD_ITEM_SORT_KEY", [
    { no: 0, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
    { no: 15, name: "INWARD_JOB_OUTWARD_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of inward job outward items
 *
 * @generated from enum Scailo.INWARD_JOB_OUTWARD_ITEM_STATUS
 */
var INWARD_JOB_OUTWARD_ITEM_STATUS;
(function (INWARD_JOB_OUTWARD_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    INWARD_JOB_OUTWARD_ITEM_STATUS[INWARD_JOB_OUTWARD_ITEM_STATUS["INWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "INWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the inward job outward items must have been approved
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED = 1;
     */
    INWARD_JOB_OUTWARD_ITEM_STATUS[INWARD_JOB_OUTWARD_ITEM_STATUS["INWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED"] = 1] = "INWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the inward job outward items must be waiting for approval
     *
     * @generated from enum value: INWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED = 2;
     */
    INWARD_JOB_OUTWARD_ITEM_STATUS[INWARD_JOB_OUTWARD_ITEM_STATUS["INWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED"] = 2] = "INWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED";
})(INWARD_JOB_OUTWARD_ITEM_STATUS || (exports.INWARD_JOB_OUTWARD_ITEM_STATUS = INWARD_JOB_OUTWARD_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_OUTWARD_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_OUTWARD_ITEM_STATUS, "Scailo.INWARD_JOB_OUTWARD_ITEM_STATUS", [
    { no: 0, name: "INWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED" },
    { no: 2, name: "INWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.InwardJobsServiceCreateRequest
 */
class InwardJobsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 15;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceCreateRequest, a, b);
    }
}
exports.InwardJobsServiceCreateRequest = InwardJobsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.InwardJobsServiceUpdateRequest
 */
class InwardJobsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 15;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceUpdateRequest, a, b);
    }
}
exports.InwardJobsServiceUpdateRequest = InwardJobsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.InwardJobsServiceAutofillRequest
 */
class InwardJobsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceAutofillRequest, a, b);
    }
}
exports.InwardJobsServiceAutofillRequest = InwardJobsServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.InwardJobAncillaryParameters
 */
class InwardJobAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the consignee client (the UUID of the associated consignee_client_id)
     *
     * @generated from field: string consignee_client_uuid = 212;
     */
    consigneeClientUuid = "";
    /**
     * The UUID of the buyer client (the UUID of the associated buyer_client_id)
     *
     * @generated from field: string buyer_client_uuid = 213;
     */
    buyerClientUuid = "";
    /**
     * The UUID of the location (the UUID of the associated location_uuid)
     *
     * @generated from field: string location_uuid = 214;
     */
    locationUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "consignee_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 213, name: "buyer_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobAncillaryParameters, a, b);
    }
}
exports.InwardJobAncillaryParameters = InwardJobAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.InwardJob
 */
class InwardJob extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this inward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this inward job
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this inward job was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 15;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated inward job inward items
     *
     * @generated from field: repeated Scailo.InwardJobInwardItem inward_items_list = 20;
     */
    inwardItemsList = [];
    /**
     * The list of associated inward job outward items
     *
     * @generated from field: repeated Scailo.InwardJobOutwardItem outward_items_list = 21;
     */
    outwardItemsList = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJob";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inward_items_list", kind: "message", T: InwardJobInwardItem, repeated: true },
        { no: 21, name: "outward_items_list", kind: "message", T: InwardJobOutwardItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJob().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJob().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJob().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJob, a, b);
    }
}
exports.InwardJob = InwardJob;
/**
 *
 * Describes the parameters required to add an inward item to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceInwardItemCreateRequest
 */
class InwardJobsServiceInwardItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 16;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceInwardItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceInwardItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceInwardItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceInwardItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceInwardItemCreateRequest, a, b);
    }
}
exports.InwardJobsServiceInwardItemCreateRequest = InwardJobsServiceInwardItemCreateRequest;
/**
 *
 * Describes the parameters required to add an individual item as part of multiple item addition to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceMultipleInwardItemsSingleton
 */
class InwardJobsServiceMultipleInwardItemsSingleton extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 16;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceMultipleInwardItemsSingleton";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceMultipleInwardItemsSingleton().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceMultipleInwardItemsSingleton().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceMultipleInwardItemsSingleton().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceMultipleInwardItemsSingleton, a, b);
    }
}
exports.InwardJobsServiceMultipleInwardItemsSingleton = InwardJobsServiceMultipleInwardItemsSingleton;
/**
 *
 * Describes the parameters required to add multiple items to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceMultipleInwardItemsCreateRequest
 */
class InwardJobsServiceMultipleInwardItemsCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * List of items
     *
     * @generated from field: repeated Scailo.InwardJobsServiceMultipleInwardItemsSingleton list = 11;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceMultipleInwardItemsCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "list", kind: "message", T: InwardJobsServiceMultipleInwardItemsSingleton, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceMultipleInwardItemsCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceMultipleInwardItemsCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceMultipleInwardItemsCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceMultipleInwardItemsCreateRequest, a, b);
    }
}
exports.InwardJobsServiceMultipleInwardItemsCreateRequest = InwardJobsServiceMultipleInwardItemsCreateRequest;
/**
 *
 * Describes the parameters required to update an inward item in a inward job
 *
 * @generated from message Scailo.InwardJobsServiceInwardItemUpdateRequest
 */
class InwardJobsServiceInwardItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 16;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceInwardItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceInwardItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceInwardItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceInwardItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceInwardItemUpdateRequest, a, b);
    }
}
exports.InwardJobsServiceInwardItemUpdateRequest = InwardJobsServiceInwardItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an inward item associated to a inward job
 *
 * @generated from message Scailo.InwardJobInwardItem
 */
class InwardJobInwardItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 16;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobInwardItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobInwardItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobInwardItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobInwardItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobInwardItem, a, b);
    }
}
exports.InwardJobInwardItem = InwardJobInwardItem;
/**
 *
 * Describes the message consisting of the list of inward jobs
 *
 * @generated from message Scailo.InwardJobsList
 */
class InwardJobsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJob list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJob, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsList, a, b);
    }
}
exports.InwardJobsList = InwardJobsList;
/**
 *
 * Describes the message consisting of the list of inward job inward items
 *
 * @generated from message Scailo.InwardJobsInwardItemsList
 */
class InwardJobsInwardItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJobInwardItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsInwardItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJobInwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsInwardItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsInwardItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsInwardItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsInwardItemsList, a, b);
    }
}
exports.InwardJobsInwardItemsList = InwardJobsInwardItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.InwardJobInwardItemHistoryRequest
 */
class InwardJobInwardItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobInwardItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobInwardItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobInwardItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobInwardItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobInwardItemHistoryRequest, a, b);
    }
}
exports.InwardJobInwardItemHistoryRequest = InwardJobInwardItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective inward job inward item
 *
 * @generated from message Scailo.InwardJobInwardItemProspectiveInfoRequest
 */
class InwardJobInwardItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobInwardItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobInwardItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobInwardItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobInwardItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobInwardItemProspectiveInfoRequest, a, b);
    }
}
exports.InwardJobInwardItemProspectiveInfoRequest = InwardJobInwardItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.InwardJobsServicePaginationReq
 */
class InwardJobsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_SORT_KEY.INWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this inward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServicePaginationReq, a, b);
    }
}
exports.InwardJobsServicePaginationReq = InwardJobsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.InwardJobsServicePaginationResponse
 */
class InwardJobsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.InwardJob payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: InwardJob, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServicePaginationResponse, a, b);
    }
}
exports.InwardJobsServicePaginationResponse = InwardJobsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.InwardJobsServiceFilterReq
 */
class InwardJobsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_SORT_KEY.INWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this inward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the inward job
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the outward family
     *
     * @generated from field: uint64 outward_family_id = 30;
     */
    outwardFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "outward_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceFilterReq, a, b);
    }
}
exports.InwardJobsServiceFilterReq = InwardJobsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.InwardJobsServiceCountReq
 */
class InwardJobsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this inward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the inward job (on the basis of the delivery dates of the outward items)
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the inward job
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the outward family
     *
     * @generated from field: uint64 outward_family_id = 30;
     */
    outwardFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "outward_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceCountReq, a, b);
    }
}
exports.InwardJobsServiceCountReq = InwardJobsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.InwardJobsServiceSearchAllReq
 */
class InwardJobsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_SORT_KEY.INWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceSearchAllReq, a, b);
    }
}
exports.InwardJobsServiceSearchAllReq = InwardJobsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.InwardJobInwardItemsSearchRequest
 */
class InwardJobInwardItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_INWARD_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_INWARD_ITEM_SORT_KEY.INWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.INWARD_JOB_INWARD_ITEM_STATUS status = 7;
     */
    status = INWARD_JOB_INWARD_ITEM_STATUS.INWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the inward job
     *
     * @generated from field: uint64 inward_job_id = 20;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 25;
     */
    clientFamilyCode = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobInwardItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_INWARD_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_INWARD_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobInwardItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobInwardItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobInwardItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobInwardItemsSearchRequest, a, b);
    }
}
exports.InwardJobInwardItemsSearchRequest = InwardJobInwardItemsSearchRequest;
/**
 *
 * Describes the response to a pagination inward items request
 *
 * @generated from message Scailo.InwardJobsServicePaginatedInwardItemsResponse
 */
class InwardJobsServicePaginatedInwardItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.InwardJobInwardItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServicePaginatedInwardItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: InwardJobInwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServicePaginatedInwardItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServicePaginatedInwardItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServicePaginatedInwardItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServicePaginatedInwardItemsResponse, a, b);
    }
}
exports.InwardJobsServicePaginatedInwardItemsResponse = InwardJobsServicePaginatedInwardItemsResponse;
/**
 *
 * Describes the parameters required to add an outward item to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceOutwardItemCreateRequest
 */
class InwardJobsServiceOutwardItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceOutwardItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceOutwardItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceOutwardItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceOutwardItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceOutwardItemCreateRequest, a, b);
    }
}
exports.InwardJobsServiceOutwardItemCreateRequest = InwardJobsServiceOutwardItemCreateRequest;
/**
 *
 * Describes the parameters required to add an individual item as part of multiple item addition to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceMultipleOutwardItemsSingleton
 */
class InwardJobsServiceMultipleOutwardItemsSingleton extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceMultipleOutwardItemsSingleton";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceMultipleOutwardItemsSingleton().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceMultipleOutwardItemsSingleton().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceMultipleOutwardItemsSingleton().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceMultipleOutwardItemsSingleton, a, b);
    }
}
exports.InwardJobsServiceMultipleOutwardItemsSingleton = InwardJobsServiceMultipleOutwardItemsSingleton;
/**
 *
 * Describes the parameters required to add multiple items to a inward job
 *
 * @generated from message Scailo.InwardJobsServiceMultipleOutwardItemsCreateRequest
 */
class InwardJobsServiceMultipleOutwardItemsCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * List of items
     *
     * @generated from field: repeated Scailo.InwardJobsServiceMultipleOutwardItemsSingleton list = 11;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceMultipleOutwardItemsCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "list", kind: "message", T: InwardJobsServiceMultipleOutwardItemsSingleton, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceMultipleOutwardItemsCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceMultipleOutwardItemsCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceMultipleOutwardItemsCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceMultipleOutwardItemsCreateRequest, a, b);
    }
}
exports.InwardJobsServiceMultipleOutwardItemsCreateRequest = InwardJobsServiceMultipleOutwardItemsCreateRequest;
/**
 *
 * Describes the parameters required to update an outward item in a inward job
 *
 * @generated from message Scailo.InwardJobsServiceOutwardItemUpdateRequest
 */
class InwardJobsServiceOutwardItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceOutwardItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceOutwardItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceOutwardItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceOutwardItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceOutwardItemUpdateRequest, a, b);
    }
}
exports.InwardJobsServiceOutwardItemUpdateRequest = InwardJobsServiceOutwardItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an outward item associated to a inward job
 *
 * @generated from message Scailo.InwardJobOutwardItem
 */
class InwardJobOutwardItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobOutwardItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobOutwardItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobOutwardItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobOutwardItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobOutwardItem, a, b);
    }
}
exports.InwardJobOutwardItem = InwardJobOutwardItem;
/**
 *
 * Describes the message consisting of the list of inward job outward items
 *
 * @generated from message Scailo.InwardJobsOutwardItemsList
 */
class InwardJobsOutwardItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJobOutwardItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsOutwardItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJobOutwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsOutwardItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsOutwardItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsOutwardItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsOutwardItemsList, a, b);
    }
}
exports.InwardJobsOutwardItemsList = InwardJobsOutwardItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.InwardJobOutwardItemHistoryRequest
 */
class InwardJobOutwardItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobOutwardItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobOutwardItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobOutwardItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobOutwardItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobOutwardItemHistoryRequest, a, b);
    }
}
exports.InwardJobOutwardItemHistoryRequest = InwardJobOutwardItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective inward job outward item
 *
 * @generated from message Scailo.InwardJobOutwardItemProspectiveInfoRequest
 */
class InwardJobOutwardItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobOutwardItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobOutwardItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobOutwardItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobOutwardItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobOutwardItemProspectiveInfoRequest, a, b);
    }
}
exports.InwardJobOutwardItemProspectiveInfoRequest = InwardJobOutwardItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.InwardJobOutwardItemsSearchRequest
 */
class InwardJobOutwardItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_OUTWARD_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_OUTWARD_ITEM_SORT_KEY.INWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.INWARD_JOB_OUTWARD_ITEM_STATUS status = 7;
     */
    status = INWARD_JOB_OUTWARD_ITEM_STATUS.INWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the inward job
     *
     * @generated from field: uint64 inward_job_id = 20;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 25;
     */
    clientFamilyCode = "";
    /**
     * The exact delivery date of the item in the inward job
     *
     * @generated from field: string delivery_date_exact = 26;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the inward job
     *
     * @generated from field: string delivery_date_start = 27;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the inward job
     *
     * @generated from field: string delivery_date_end = 28;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobOutwardItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_OUTWARD_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_OUTWARD_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 28, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobOutwardItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobOutwardItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobOutwardItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobOutwardItemsSearchRequest, a, b);
    }
}
exports.InwardJobOutwardItemsSearchRequest = InwardJobOutwardItemsSearchRequest;
/**
 *
 * Describes the response to a pagination outward items request
 *
 * @generated from message Scailo.InwardJobsServicePaginatedOutwardItemsResponse
 */
class InwardJobsServicePaginatedOutwardItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.InwardJobOutwardItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServicePaginatedOutwardItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: InwardJobOutwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServicePaginatedOutwardItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServicePaginatedOutwardItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServicePaginatedOutwardItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServicePaginatedOutwardItemsResponse, a, b);
    }
}
exports.InwardJobsServicePaginatedOutwardItemsResponse = InwardJobsServicePaginatedOutwardItemsResponse;
/**
 *
 * Describes the parameters necessary to create a inward job contact
 *
 * @generated from message Scailo.InwardJobsServiceContactCreateRequest
 */
class InwardJobsServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsServiceContactCreateRequest, a, b);
    }
}
exports.InwardJobsServiceContactCreateRequest = InwardJobsServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a inward job contact
 *
 * @generated from message Scailo.InwardJobContact
 */
class InwardJobContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the inward job ID
     *
     * @generated from field: uint64 inward_job_id = 10;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the UUID of the associate
     *
     * @generated from field: string associate_uuid = 211;
     */
    associateUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 211, name: "associate_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobContact, a, b);
    }
}
exports.InwardJobContact = InwardJobContact;
/**
 *
 * Describes the message consisting of the list of inward job contacts
 *
 * @generated from message Scailo.InwardJobContactsList
 */
class InwardJobContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJobContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJobContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobContactsList, a, b);
    }
}
exports.InwardJobContactsList = InwardJobContactsList;
