"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vendors.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorsServicePaginatedUsersResponse = exports.VendorUsersSearchRequest = exports.VendorUsersList = exports.VendorUser = exports.VendorsServiceUserCreateRequest = exports.VendorsServicePaginatedItemsResponse = exports.VendorItemsSearchRequest = exports.VendorsServiceSearchAllReq = exports.VendorsServiceCountReq = exports.VendorsServiceFilterReq = exports.VendorsServicePaginationResponse = exports.VendorsServicePaginationReq = exports.VendorItemHistoryRequest = exports.VendorItemsList = exports.VendorsList = exports.VendorItem = exports.VendorsServiceItemUpdateRequest = exports.VendorsServiceItemCreateRequest = exports.Vendor = exports.VendorsServiceUpdateRequest = exports.VendorsServiceCreateRequest = exports.VENDOR_USER_STATUS = exports.VENDOR_ITEM_STATUS = exports.VENDOR_ITEM_SORT_KEY = exports.VENDOR_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_SORT_KEY
 */
var VENDOR_SORT_KEY;
(function (VENDOR_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VENDOR_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VENDOR_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VENDOR_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: VENDOR_SORT_KEY_APPROVED_ON = 3;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_APPROVED_ON"] = 3] = "VENDOR_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: VENDOR_SORT_KEY_APPROVED_BY = 4;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_APPROVED_BY"] = 4] = "VENDOR_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: VENDOR_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "VENDOR_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: VENDOR_SORT_KEY_NAME = 10;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_NAME"] = 10] = "VENDOR_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: VENDOR_SORT_KEY_CODE = 11;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_CODE"] = 11] = "VENDOR_SORT_KEY_CODE";
    /**
     * Fetch ordered results by the email address
     *
     * @generated from enum value: VENDOR_SORT_KEY_EMAIL = 12;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_EMAIL"] = 12] = "VENDOR_SORT_KEY_EMAIL";
    /**
     * Fetch ordered results by the phone number
     *
     * @generated from enum value: VENDOR_SORT_KEY_PHONE = 13;
     */
    VENDOR_SORT_KEY[VENDOR_SORT_KEY["VENDOR_SORT_KEY_PHONE"] = 13] = "VENDOR_SORT_KEY_PHONE";
})(VENDOR_SORT_KEY || (exports.VENDOR_SORT_KEY = VENDOR_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_SORT_KEY, "Scailo.VENDOR_SORT_KEY", [
    { no: 0, name: "VENDOR_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "VENDOR_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "VENDOR_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "VENDOR_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "VENDOR_SORT_KEY_NAME" },
    { no: 11, name: "VENDOR_SORT_KEY_CODE" },
    { no: 12, name: "VENDOR_SORT_KEY_EMAIL" },
    { no: 13, name: "VENDOR_SORT_KEY_PHONE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_ITEM_SORT_KEY
 */
var VENDOR_ITEM_SORT_KEY;
(function (VENDOR_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "VENDOR_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "VENDOR_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "VENDOR_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "VENDOR_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the vendor family code
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE = 11;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE"] = 11] = "VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE";
    /**
     * Fetch ordered results by the unit of material ID
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_UOM_ID = 12;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_UOM_ID"] = 12] = "VENDOR_ITEM_SORT_KEY_UOM_ID";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_TAX_GROUP_ID = 13;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_TAX_GROUP_ID"] = 13] = "VENDOR_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch ordered results by the price
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_PRICE = 14;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_PRICE"] = 14] = "VENDOR_ITEM_SORT_KEY_PRICE";
    /**
     * Fetch ordered results by the minimum order quantity
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY = 15;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY"] = 15] = "VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY";
    /**
     * Fetch ordered results by the maximum order quantity
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY = 16;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY"] = 16] = "VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY";
    /**
     * Fetch ordered results by the step interval
     *
     * @generated from enum value: VENDOR_ITEM_SORT_KEY_STEP_INTERVAL = 17;
     */
    VENDOR_ITEM_SORT_KEY[VENDOR_ITEM_SORT_KEY["VENDOR_ITEM_SORT_KEY_STEP_INTERVAL"] = 17] = "VENDOR_ITEM_SORT_KEY_STEP_INTERVAL";
})(VENDOR_ITEM_SORT_KEY || (exports.VENDOR_ITEM_SORT_KEY = VENDOR_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_ITEM_SORT_KEY, "Scailo.VENDOR_ITEM_SORT_KEY", [
    { no: 0, name: "VENDOR_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "VENDOR_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "VENDOR_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "VENDOR_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "VENDOR_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE" },
    { no: 12, name: "VENDOR_ITEM_SORT_KEY_UOM_ID" },
    { no: 13, name: "VENDOR_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 14, name: "VENDOR_ITEM_SORT_KEY_PRICE" },
    { no: 15, name: "VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY" },
    { no: 16, name: "VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY" },
    { no: 17, name: "VENDOR_ITEM_SORT_KEY_STEP_INTERVAL" },
]);
/**
 *
 * Describes the applicable statuses of vendor items
 *
 * @generated from enum Scailo.VENDOR_ITEM_STATUS
 */
var VENDOR_ITEM_STATUS;
(function (VENDOR_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: VENDOR_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    VENDOR_ITEM_STATUS[VENDOR_ITEM_STATUS["VENDOR_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "VENDOR_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the vendor items must have been approved
     *
     * @generated from enum value: VENDOR_ITEM_STATUS_APPROVED = 1;
     */
    VENDOR_ITEM_STATUS[VENDOR_ITEM_STATUS["VENDOR_ITEM_STATUS_APPROVED"] = 1] = "VENDOR_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the vendor items must be waiting for approval
     *
     * @generated from enum value: VENDOR_ITEM_STATUS_UNAPPROVED = 2;
     */
    VENDOR_ITEM_STATUS[VENDOR_ITEM_STATUS["VENDOR_ITEM_STATUS_UNAPPROVED"] = 2] = "VENDOR_ITEM_STATUS_UNAPPROVED";
})(VENDOR_ITEM_STATUS || (exports.VENDOR_ITEM_STATUS = VENDOR_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(VENDOR_ITEM_STATUS, "Scailo.VENDOR_ITEM_STATUS", [
    { no: 0, name: "VENDOR_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_ITEM_STATUS_APPROVED" },
    { no: 2, name: "VENDOR_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the applicable statuses of vendor users
 *
 * @generated from enum Scailo.VENDOR_USER_STATUS
 */
var VENDOR_USER_STATUS;
(function (VENDOR_USER_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: VENDOR_USER_STATUS_ANY_UNSPECIFIED = 0;
     */
    VENDOR_USER_STATUS[VENDOR_USER_STATUS["VENDOR_USER_STATUS_ANY_UNSPECIFIED"] = 0] = "VENDOR_USER_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the vendor items must have been approved
     *
     * @generated from enum value: VENDOR_USER_STATUS_APPROVED = 1;
     */
    VENDOR_USER_STATUS[VENDOR_USER_STATUS["VENDOR_USER_STATUS_APPROVED"] = 1] = "VENDOR_USER_STATUS_APPROVED";
    /**
     * Denotes that the vendor items must be waiting for approval
     *
     * @generated from enum value: VENDOR_USER_STATUS_UNAPPROVED = 2;
     */
    VENDOR_USER_STATUS[VENDOR_USER_STATUS["VENDOR_USER_STATUS_UNAPPROVED"] = 2] = "VENDOR_USER_STATUS_UNAPPROVED";
})(VENDOR_USER_STATUS || (exports.VENDOR_USER_STATUS = VENDOR_USER_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_USER_STATUS)
protobuf_1.proto3.util.setEnumType(VENDOR_USER_STATUS, "Scailo.VENDOR_USER_STATUS", [
    { no: 0, name: "VENDOR_USER_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_USER_STATUS_APPROVED" },
    { no: 2, name: "VENDOR_USER_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.VendorsServiceCreateRequest
 */
class VendorsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the vendor
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the vendor is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The primary email of the vendor
     *
     * @generated from field: string email = 12;
     */
    email = "";
    /**
     * The primary contact number of the vendor
     *
     * @generated from field: string phone = 13;
     */
    phone = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceCreateRequest, a, b);
    }
}
exports.VendorsServiceCreateRequest = VendorsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.VendorsServiceUpdateRequest
 */
class VendorsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the vendor
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the vendor is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The primary email of the vendor
     *
     * @generated from field: string email = 12;
     */
    email = "";
    /**
     * The primary contact number of the vendor
     *
     * @generated from field: string phone = 13;
     */
    phone = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceUpdateRequest, a, b);
    }
}
exports.VendorsServiceUpdateRequest = VendorsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Vendor
 */
class Vendor extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this vendor
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this vendor
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the vendor
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the vendor is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The primary email of the vendor
     *
     * @generated from field: string email = 12;
     */
    email = "";
    /**
     * The primary contact number of the vendor
     *
     * @generated from field: string phone = 13;
     */
    phone = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Vendor";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Vendor().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Vendor().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Vendor().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Vendor, a, b);
    }
}
exports.Vendor = Vendor;
/**
 *
 * Describes the parameters required to add an item to a vendor
 *
 * @generated from message Scailo.VendorsServiceItemCreateRequest
 */
class VendorsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional family code as represented by the vendor
     *
     * @generated from field: string vendor_family_code = 12;
     */
    vendorFamilyCode = "";
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 14;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 price = 15;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * The minimum order quantity that needs to be placed (in cents) (0.01 is the minimum)
     *
     * @generated from field: uint64 min_order_qty = 20;
     */
    minOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The maximum order quantity that can be placed (in cents) (0 represents unlimited max quantity)
     *
     * @generated from field: uint64 max_order_qty = 21;
     */
    maxOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The incremental count by which the order quantity can be increased (in cents)
     *
     * @generated from field: uint64 step_interval = 22;
     */
    stepInterval = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "vendor_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "min_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "max_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "step_interval", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceItemCreateRequest, a, b);
    }
}
exports.VendorsServiceItemCreateRequest = VendorsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a vendor
 *
 * @generated from message Scailo.VendorsServiceItemUpdateRequest
 */
class VendorsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional family code as represented by the vendor
     *
     * @generated from field: string vendor_family_code = 12;
     */
    vendorFamilyCode = "";
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 14;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 price = 15;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * The minimum order quantity that needs to be placed (in cents) (0.01 is the minimum)
     *
     * @generated from field: uint64 min_order_qty = 20;
     */
    minOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The maximum order quantity that can be placed (in cents) (0 represents unlimited max quantity)
     *
     * @generated from field: uint64 max_order_qty = 21;
     */
    maxOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The incremental count by which the order quantity can be increased (in cents)
     *
     * @generated from field: uint64 step_interval = 22;
     */
    stepInterval = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "vendor_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "min_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "max_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "step_interval", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceItemUpdateRequest, a, b);
    }
}
exports.VendorsServiceItemUpdateRequest = VendorsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a vendor
 *
 * @generated from message Scailo.VendorItem
 */
class VendorItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional family code as represented by the vendor
     *
     * @generated from field: string vendor_family_code = 12;
     */
    vendorFamilyCode = "";
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 14;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 price = 15;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * The minimum order quantity that needs to be placed (in cents) (0.01 is the minimum)
     *
     * @generated from field: uint64 min_order_qty = 20;
     */
    minOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The maximum order quantity that can be placed (in cents) (0 represents unlimited max quantity)
     *
     * @generated from field: uint64 max_order_qty = 21;
     */
    maxOrderQty = protobuf_1.protoInt64.zero;
    /**
     * The incremental count by which the order quantity can be increased (in cents)
     *
     * @generated from field: uint64 step_interval = 22;
     */
    stepInterval = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "vendor_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "min_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "max_order_qty", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "step_interval", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorItem, a, b);
    }
}
exports.VendorItem = VendorItem;
/**
 *
 * Describes the message consisting of the list of vendors
 *
 * @generated from message Scailo.VendorsList
 */
class VendorsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Vendor list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Vendor, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsList, a, b);
    }
}
exports.VendorsList = VendorsList;
/**
 *
 * Describes the message consisting of the list of vendor items
 *
 * @generated from message Scailo.VendorItemsList
 */
class VendorItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorItemsList, a, b);
    }
}
exports.VendorItemsList = VendorItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.VendorItemHistoryRequest
 */
class VendorItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorItemHistoryRequest, a, b);
    }
}
exports.VendorItemHistoryRequest = VendorItemHistoryRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.VendorsServicePaginationReq
 */
class VendorsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_SORT_KEY.VENDOR_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this vendor
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServicePaginationReq, a, b);
    }
}
exports.VendorsServicePaginationReq = VendorsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.VendorsServicePaginationResponse
 */
class VendorsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Vendor payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Vendor, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServicePaginationResponse, a, b);
    }
}
exports.VendorsServicePaginationResponse = VendorsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.VendorsServiceFilterReq
 */
class VendorsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_SORT_KEY.VENDOR_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the vendor
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the vendor is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The primary email of the vendor
     *
     * @generated from field: string email = 22;
     */
    email = "";
    /**
     * The primary contact number of the vendor
     *
     * @generated from field: string phone = 23;
     */
    phone = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceFilterReq, a, b);
    }
}
exports.VendorsServiceFilterReq = VendorsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.VendorsServiceCountReq
 */
class VendorsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the vendor
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the vendor is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The primary email of the vendor
     *
     * @generated from field: string email = 22;
     */
    email = "";
    /**
     * The primary contact number of the vendor
     *
     * @generated from field: string phone = 23;
     */
    phone = "";
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceCountReq, a, b);
    }
}
exports.VendorsServiceCountReq = VendorsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.VendorsServiceSearchAllReq
 */
class VendorsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_SORT_KEY.VENDOR_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceSearchAllReq, a, b);
    }
}
exports.VendorsServiceSearchAllReq = VendorsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.VendorItemsSearchRequest
 */
class VendorItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.VENDOR_ITEM_STATUS status = 7;
     */
    status = VENDOR_ITEM_STATUS.VENDOR_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's family code
     *
     * @generated from field: string vendor_family_code = 12;
     */
    vendorFamilyCode = "";
    /**
     * The ID of the unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 14;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 20;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_ITEM_STATUS) },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "vendor_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorItemsSearchRequest, a, b);
    }
}
exports.VendorItemsSearchRequest = VendorItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.VendorsServicePaginatedItemsResponse
 */
class VendorsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServicePaginatedItemsResponse, a, b);
    }
}
exports.VendorsServicePaginatedItemsResponse = VendorsServicePaginatedItemsResponse;
/**
 *
 * Describes the parameters necessary to create a vendor user
 *
 * @generated from message Scailo.VendorsServiceUserCreateRequest
 */
class VendorsServiceUserCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional associate ID
     *
     * @generated from field: uint64 associate_id = 12;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServiceUserCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServiceUserCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServiceUserCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServiceUserCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServiceUserCreateRequest, a, b);
    }
}
exports.VendorsServiceUserCreateRequest = VendorsServiceUserCreateRequest;
/**
 *
 * Describes the parameters that constitute a vendor user
 *
 * @generated from message Scailo.VendorUser
 */
class VendorUser extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional associate ID
     *
     * @generated from field: uint64 associate_id = 12;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorUser";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorUser().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorUser().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorUser().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorUser, a, b);
    }
}
exports.VendorUser = VendorUser;
/**
 *
 * Describes the message consisting of the list of vendor users
 *
 * @generated from message Scailo.VendorUsersList
 */
class VendorUsersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorUser list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorUsersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorUser, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorUsersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorUsersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorUsersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorUsersList, a, b);
    }
}
exports.VendorUsersList = VendorUsersList;
/**
 *
 * Describes the request payload to search vendor users
 *
 * @generated from message Scailo.VendorUsersSearchRequest
 */
class VendorUsersSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the users
     *
     * @generated from field: Scailo.VENDOR_USER_STATUS status = 7;
     */
    status = VENDOR_USER_STATUS.VENDOR_USER_STATUS_ANY_UNSPECIFIED;
    /**
     * Stores the vendor ID
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional associate ID
     *
     * @generated from field: uint64 associate_id = 12;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 20;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorUsersSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_USER_STATUS) },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorUsersSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorUsersSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorUsersSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorUsersSearchRequest, a, b);
    }
}
exports.VendorUsersSearchRequest = VendorUsersSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.VendorsServicePaginatedUsersResponse
 */
class VendorsServicePaginatedUsersResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorUser payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorsServicePaginatedUsersResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorUser, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorsServicePaginatedUsersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorsServicePaginatedUsersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorsServicePaginatedUsersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorsServicePaginatedUsersResponse, a, b);
    }
}
exports.VendorsServicePaginatedUsersResponse = VendorsServicePaginatedUsersResponse;
