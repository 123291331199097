"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file purchases_enquiries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesEnquiriesServiceSearchAllReq = exports.PurchasesEnquiriesServiceCountReq = exports.PurchasesEnquiriesServiceFilterReq = exports.PurchasesEnquiriesServicePaginationResponse = exports.PurchasesEnquiriesServicePaginationReq = exports.PurchaseEnquiriesServicePaginatedItemsResponse = exports.PurchaseEnquiryItemsSearchRequest = exports.PurchaseEnquiryItemHistoryRequest = exports.PurchasesEnquiriesItemsList = exports.PurchasesEnquiriesList = exports.PurchaseEnquiryItem = exports.PurchasesEnquiriesServiceItemUpdateRequest = exports.PurchasesEnquiriesServiceItemCreateRequest = exports.PurchaseEnquiry = exports.PurchasesEnquiriesServiceUpdateRequest = exports.PurchasesEnquiriesServiceCreateRequest = exports.PURCHASE_ENQUIRY_SORT_KEY = exports.PURCHASE_ENQUIRY_ITEM_STATUS = exports.PURCHASE_ENQUIRY_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_ENQUIRY_ITEM_SORT_KEY
 */
var PURCHASE_ENQUIRY_ITEM_SORT_KEY;
(function (PURCHASE_ENQUIRY_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_NAME = 10;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_NAME"] = 10] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the quantity
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_QUANTITY = 11;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_QUANTITY"] = 11] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the required by date
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_SORT_KEY_REQUIRED_BY_DATE = 12;
     */
    PURCHASE_ENQUIRY_ITEM_SORT_KEY[PURCHASE_ENQUIRY_ITEM_SORT_KEY["PURCHASE_ENQUIRY_ITEM_SORT_KEY_REQUIRED_BY_DATE"] = 12] = "PURCHASE_ENQUIRY_ITEM_SORT_KEY_REQUIRED_BY_DATE";
})(PURCHASE_ENQUIRY_ITEM_SORT_KEY || (exports.PURCHASE_ENQUIRY_ITEM_SORT_KEY = PURCHASE_ENQUIRY_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ENQUIRY_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_ENQUIRY_ITEM_SORT_KEY, "Scailo.PURCHASE_ENQUIRY_ITEM_SORT_KEY", [
    { no: 0, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_NAME" },
    { no: 11, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_QUANTITY" },
    { no: 12, name: "PURCHASE_ENQUIRY_ITEM_SORT_KEY_REQUIRED_BY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of purchase enquiry items
 *
 * @generated from enum Scailo.PURCHASE_ENQUIRY_ITEM_STATUS
 */
var PURCHASE_ENQUIRY_ITEM_STATUS;
(function (PURCHASE_ENQUIRY_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_ENQUIRY_ITEM_STATUS[PURCHASE_ENQUIRY_ITEM_STATUS["PURCHASE_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PURCHASE_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the purchase enquiry items must have been approved
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_STATUS_APPROVED = 1;
     */
    PURCHASE_ENQUIRY_ITEM_STATUS[PURCHASE_ENQUIRY_ITEM_STATUS["PURCHASE_ENQUIRY_ITEM_STATUS_APPROVED"] = 1] = "PURCHASE_ENQUIRY_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the purchase enquiry items must be waiting for approval
     *
     * @generated from enum value: PURCHASE_ENQUIRY_ITEM_STATUS_UNAPPROVED = 2;
     */
    PURCHASE_ENQUIRY_ITEM_STATUS[PURCHASE_ENQUIRY_ITEM_STATUS["PURCHASE_ENQUIRY_ITEM_STATUS_UNAPPROVED"] = 2] = "PURCHASE_ENQUIRY_ITEM_STATUS_UNAPPROVED";
})(PURCHASE_ENQUIRY_ITEM_STATUS || (exports.PURCHASE_ENQUIRY_ITEM_STATUS = PURCHASE_ENQUIRY_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ENQUIRY_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PURCHASE_ENQUIRY_ITEM_STATUS, "Scailo.PURCHASE_ENQUIRY_ITEM_STATUS", [
    { no: 0, name: "PURCHASE_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ENQUIRY_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PURCHASE_ENQUIRY_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_ENQUIRY_SORT_KEY
 */
var PURCHASE_ENQUIRY_SORT_KEY;
(function (PURCHASE_ENQUIRY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_ENQUIRY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_ENQUIRY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_ENQUIRY_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_ENQUIRY_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_COMPLETED_ON = 6;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_COMPLETED_ON"] = 6] = "PURCHASE_ENQUIRY_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_REFERENCE_ID = 10;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_REFERENCE_ID"] = 10] = "PURCHASE_ENQUIRY_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PURCHASE_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the priority
     *
     * @generated from enum value: PURCHASE_ENQUIRY_SORT_KEY_PRIORITY = 14;
     */
    PURCHASE_ENQUIRY_SORT_KEY[PURCHASE_ENQUIRY_SORT_KEY["PURCHASE_ENQUIRY_SORT_KEY_PRIORITY"] = 14] = "PURCHASE_ENQUIRY_SORT_KEY_PRIORITY";
})(PURCHASE_ENQUIRY_SORT_KEY || (exports.PURCHASE_ENQUIRY_SORT_KEY = PURCHASE_ENQUIRY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ENQUIRY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_ENQUIRY_SORT_KEY, "Scailo.PURCHASE_ENQUIRY_SORT_KEY", [
    { no: 0, name: "PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ENQUIRY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_ENQUIRY_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_ENQUIRY_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_ENQUIRY_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PURCHASE_ENQUIRY_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PURCHASE_ENQUIRY_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PURCHASE_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 14, name: "PURCHASE_ENQUIRY_SORT_KEY_PRIORITY" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceCreateRequest
 */
class PurchasesEnquiriesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The priority of the purchase enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The description of the purchase enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceCreateRequest, a, b);
    }
}
exports.PurchasesEnquiriesServiceCreateRequest = PurchasesEnquiriesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceUpdateRequest
 */
class PurchasesEnquiriesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The priority of the purchase enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The description of the purchase enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceUpdateRequest, a, b);
    }
}
exports.PurchasesEnquiriesServiceUpdateRequest = PurchasesEnquiriesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PurchaseEnquiry
 */
class PurchaseEnquiry extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase enquiry
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this purchase enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase enquiry
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this purchase enquiry was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The priority of the purchase enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The description of the purchase enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of associated purchase enquiry items
     *
     * @generated from field: repeated Scailo.PurchaseEnquiryItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseEnquiry";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: PurchaseEnquiryItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseEnquiry().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseEnquiry().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseEnquiry().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseEnquiry, a, b);
    }
}
exports.PurchaseEnquiry = PurchaseEnquiry;
/**
 *
 * Describes the parameters required to add an item to a purchase enquiry
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceItemCreateRequest
 */
class PurchasesEnquiriesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase enquiry ID
     *
     * @generated from field: uint64 purchase_enquiry_id = 10;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The required by date
     *
     * @generated from field: string required_by = 18;
     */
    requiredBy = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 19;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "required_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceItemCreateRequest, a, b);
    }
}
exports.PurchasesEnquiriesServiceItemCreateRequest = PurchasesEnquiriesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a purchase enquiry
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceItemUpdateRequest
 */
class PurchasesEnquiriesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The required by date
     *
     * @generated from field: string required_by = 18;
     */
    requiredBy = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 19;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "required_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceItemUpdateRequest, a, b);
    }
}
exports.PurchasesEnquiriesServiceItemUpdateRequest = PurchasesEnquiriesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a purchase enquiry
 *
 * @generated from message Scailo.PurchaseEnquiryItem
 */
class PurchaseEnquiryItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase enquiry
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase enquiry ID
     *
     * @generated from field: uint64 purchase_enquiry_id = 10;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The required by date
     *
     * @generated from field: string required_by = 18;
     */
    requiredBy = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 19;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseEnquiryItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "required_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseEnquiryItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseEnquiryItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseEnquiryItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseEnquiryItem, a, b);
    }
}
exports.PurchaseEnquiryItem = PurchaseEnquiryItem;
/**
 *
 * Describes the message consisting of the list of purchases enquiries
 *
 * @generated from message Scailo.PurchasesEnquiriesList
 */
class PurchasesEnquiriesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseEnquiry list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseEnquiry, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesList, a, b);
    }
}
exports.PurchasesEnquiriesList = PurchasesEnquiriesList;
/**
 *
 * Describes the message consisting of the list of purchase enquiry items
 *
 * @generated from message Scailo.PurchasesEnquiriesItemsList
 */
class PurchasesEnquiriesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseEnquiryItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseEnquiryItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesItemsList, a, b);
    }
}
exports.PurchasesEnquiriesItemsList = PurchasesEnquiriesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.PurchaseEnquiryItemHistoryRequest
 */
class PurchaseEnquiryItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the purchase enquiry ID
     *
     * @generated from field: uint64 purchase_enquiry_id = 10;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseEnquiryItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseEnquiryItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseEnquiryItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseEnquiryItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseEnquiryItemHistoryRequest, a, b);
    }
}
exports.PurchaseEnquiryItemHistoryRequest = PurchaseEnquiryItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.PurchaseEnquiryItemsSearchRequest
 */
class PurchaseEnquiryItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ENQUIRY_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ENQUIRY_ITEM_SORT_KEY.PURCHASE_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PURCHASE_ENQUIRY_ITEM_STATUS status = 7;
     */
    status = PURCHASE_ENQUIRY_ITEM_STATUS.PURCHASE_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the purchase enquiry ID
     *
     * @generated from field: uint64 purchase_enquiry_id = 20;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the unit of material
     *
     * @generated from field: uint64 uom_id = 21;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The exact required by date of the item in the purchase enquiry
     *
     * @generated from field: string required_by_date_exact = 28;
     */
    requiredByDateExact = "";
    /**
     * The start required by date of the item in the purchase enquiry
     *
     * @generated from field: string required_by_date_start = 29;
     */
    requiredByDateStart = "";
    /**
     * The end required by date of the item in the purchase enquiry
     *
     * @generated from field: string required_by_date_end = 30;
     */
    requiredByDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseEnquiryItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ENQUIRY_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ENQUIRY_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "required_by_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "required_by_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "required_by_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseEnquiryItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseEnquiryItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseEnquiryItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseEnquiryItemsSearchRequest, a, b);
    }
}
exports.PurchaseEnquiryItemsSearchRequest = PurchaseEnquiryItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.PurchaseEnquiriesServicePaginatedItemsResponse
 */
class PurchaseEnquiriesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseEnquiryItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseEnquiriesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseEnquiryItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseEnquiriesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseEnquiriesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseEnquiriesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseEnquiriesServicePaginatedItemsResponse, a, b);
    }
}
exports.PurchaseEnquiriesServicePaginatedItemsResponse = PurchaseEnquiriesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PurchasesEnquiriesServicePaginationReq
 */
class PurchasesEnquiriesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ENQUIRY_SORT_KEY.PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this purchase enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ENQUIRY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServicePaginationReq, a, b);
    }
}
exports.PurchasesEnquiriesServicePaginationReq = PurchasesEnquiriesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PurchasesEnquiriesServicePaginationResponse
 */
class PurchasesEnquiriesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseEnquiry payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseEnquiry, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServicePaginationResponse, a, b);
    }
}
exports.PurchasesEnquiriesServicePaginationResponse = PurchasesEnquiriesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceFilterReq
 */
class PurchasesEnquiriesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ENQUIRY_SORT_KEY.PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase enquiry
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The priority of the purchase enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 24;
     */
    priority = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ENQUIRY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceFilterReq, a, b);
    }
}
exports.PurchasesEnquiriesServiceFilterReq = PurchasesEnquiriesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceCountReq
 */
class PurchasesEnquiriesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase enquiry
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The priority of the purchase enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 24;
     */
    priority = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceCountReq, a, b);
    }
}
exports.PurchasesEnquiriesServiceCountReq = PurchasesEnquiriesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PurchasesEnquiriesServiceSearchAllReq
 */
class PurchasesEnquiriesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ENQUIRY_SORT_KEY.PURCHASE_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesEnquiriesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ENQUIRY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesEnquiriesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesEnquiriesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesEnquiriesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesEnquiriesServiceSearchAllReq, a, b);
    }
}
exports.PurchasesEnquiriesServiceSearchAllReq = PurchasesEnquiriesServiceSearchAllReq;
