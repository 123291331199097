"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file announcements.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnouncementsServiceSearchAllReq = exports.AnnouncementsServiceCountReq = exports.AnnouncementsServiceFilterReq = exports.AnnouncementsServicePaginationResponse = exports.AnnouncementsServicePaginationReq = exports.AnnouncementsList = exports.Announcement = exports.AnnouncementsServiceUpdateRequest = exports.AnnouncementsServiceCreateRequest = exports.ANNOUNCEMENT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ANNOUNCEMENT_SORT_KEY
 */
var ANNOUNCEMENT_SORT_KEY;
(function (ANNOUNCEMENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_CREATED_AT = 1;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_CREATED_AT"] = 1] = "ANNOUNCEMENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_MODIFIED_AT = 2;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_MODIFIED_AT"] = 2] = "ANNOUNCEMENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_APPROVED_ON = 3;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_APPROVED_ON"] = 3] = "ANNOUNCEMENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_APPROVED_BY = 4;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_APPROVED_BY"] = 4] = "ANNOUNCEMENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ANNOUNCEMENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_COMPLETED_ON = 6;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_COMPLETED_ON"] = 6] = "ANNOUNCEMENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_TITLE = 10;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_TITLE"] = 10] = "ANNOUNCEMENT_SORT_KEY_TITLE";
    /**
     * Fetch ordered results by the description
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_DESCRIPTION = 11;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_DESCRIPTION"] = 11] = "ANNOUNCEMENT_SORT_KEY_DESCRIPTION";
    /**
     * Fetch ordered results by the start on timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_START_ON = 12;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_START_ON"] = 12] = "ANNOUNCEMENT_SORT_KEY_START_ON";
    /**
     * Fetch ordered results by the end on timestamp
     *
     * @generated from enum value: ANNOUNCEMENT_SORT_KEY_END_ON = 13;
     */
    ANNOUNCEMENT_SORT_KEY[ANNOUNCEMENT_SORT_KEY["ANNOUNCEMENT_SORT_KEY_END_ON"] = 13] = "ANNOUNCEMENT_SORT_KEY_END_ON";
})(ANNOUNCEMENT_SORT_KEY || (exports.ANNOUNCEMENT_SORT_KEY = ANNOUNCEMENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ANNOUNCEMENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ANNOUNCEMENT_SORT_KEY, "Scailo.ANNOUNCEMENT_SORT_KEY", [
    { no: 0, name: "ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ANNOUNCEMENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ANNOUNCEMENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ANNOUNCEMENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ANNOUNCEMENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ANNOUNCEMENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "ANNOUNCEMENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ANNOUNCEMENT_SORT_KEY_TITLE" },
    { no: 11, name: "ANNOUNCEMENT_SORT_KEY_DESCRIPTION" },
    { no: 12, name: "ANNOUNCEMENT_SORT_KEY_START_ON" },
    { no: 13, name: "ANNOUNCEMENT_SORT_KEY_END_ON" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.AnnouncementsServiceCreateRequest
 */
class AnnouncementsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the announcement
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the announcement
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The start timestamp
     *
     * @generated from field: uint64 start_on = 12;
     */
    startOn = protobuf_1.protoInt64.zero;
    /**
     * The end timestamp
     *
     * @generated from field: uint64 end_on = 13;
     */
    endOn = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "start_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "end_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServiceCreateRequest, a, b);
    }
}
exports.AnnouncementsServiceCreateRequest = AnnouncementsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.AnnouncementsServiceUpdateRequest
 */
class AnnouncementsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the announcement
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the announcement
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The start timestamp
     *
     * @generated from field: uint64 start_on = 12;
     */
    startOn = protobuf_1.protoInt64.zero;
    /**
     * The end timestamp
     *
     * @generated from field: uint64 end_on = 13;
     */
    endOn = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "start_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "end_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServiceUpdateRequest, a, b);
    }
}
exports.AnnouncementsServiceUpdateRequest = AnnouncementsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Announcement
 */
class Announcement extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this announcement
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this announcement
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this announcement
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this announcement was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the announcement
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the announcement
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The start timestamp
     *
     * @generated from field: uint64 start_on = 12;
     */
    startOn = protobuf_1.protoInt64.zero;
    /**
     * The end timestamp
     *
     * @generated from field: uint64 end_on = 13;
     */
    endOn = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Announcement";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "start_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "end_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new Announcement().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Announcement().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Announcement().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Announcement, a, b);
    }
}
exports.Announcement = Announcement;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.AnnouncementsList
 */
class AnnouncementsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Announcement list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Announcement, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsList, a, b);
    }
}
exports.AnnouncementsList = AnnouncementsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.AnnouncementsServicePaginationReq
 */
class AnnouncementsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ANNOUNCEMENT_SORT_KEY sort_key = 5;
     */
    sortKey = ANNOUNCEMENT_SORT_KEY.ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this announcement
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ANNOUNCEMENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServicePaginationReq, a, b);
    }
}
exports.AnnouncementsServicePaginationReq = AnnouncementsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.AnnouncementsServicePaginationResponse
 */
class AnnouncementsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Announcement payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Announcement, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServicePaginationResponse, a, b);
    }
}
exports.AnnouncementsServicePaginationResponse = AnnouncementsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.AnnouncementsServiceFilterReq
 */
class AnnouncementsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ANNOUNCEMENT_SORT_KEY sort_key = 5;
     */
    sortKey = ANNOUNCEMENT_SORT_KEY.ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this announcement
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the announcement
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The description of the announcement
     *
     * @generated from field: string description = 21;
     */
    description = "";
    /**
     * The start range of start timestamp
     *
     * @generated from field: uint64 start_on_start = 22;
     */
    startOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of start timestamp
     *
     * @generated from field: uint64 start_on_end = 23;
     */
    startOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of end timestamp
     *
     * @generated from field: uint64 end_on_start = 24;
     */
    endOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of end timestamp
     *
     * @generated from field: uint64 end_on_end = 25;
     */
    endOnEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ANNOUNCEMENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "start_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "start_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "end_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "end_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServiceFilterReq, a, b);
    }
}
exports.AnnouncementsServiceFilterReq = AnnouncementsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.AnnouncementsServiceCountReq
 */
class AnnouncementsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this announcement
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the announcement
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The description of the announcement
     *
     * @generated from field: string description = 21;
     */
    description = "";
    /**
     * The start range of start timestamp
     *
     * @generated from field: uint64 start_on_start = 22;
     */
    startOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of start timestamp
     *
     * @generated from field: uint64 start_on_end = 23;
     */
    startOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of end timestamp
     *
     * @generated from field: uint64 end_on_start = 24;
     */
    endOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of end timestamp
     *
     * @generated from field: uint64 end_on_end = 25;
     */
    endOnEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "start_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "start_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "end_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "end_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServiceCountReq, a, b);
    }
}
exports.AnnouncementsServiceCountReq = AnnouncementsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.AnnouncementsServiceSearchAllReq
 */
class AnnouncementsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ANNOUNCEMENT_SORT_KEY sort_key = 5;
     */
    sortKey = ANNOUNCEMENT_SORT_KEY.ANNOUNCEMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AnnouncementsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ANNOUNCEMENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AnnouncementsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AnnouncementsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AnnouncementsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AnnouncementsServiceSearchAllReq, a, b);
    }
}
exports.AnnouncementsServiceSearchAllReq = AnnouncementsServiceSearchAllReq;
