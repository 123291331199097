"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file absences.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbsencesServiceSearchAllReq = exports.AbsencesServiceCountReq = exports.AbsencesServiceFilterReq = exports.AbsencesServicePaginationResponse = exports.AbsencesServicePaginationReq = exports.AbsencesList = exports.Absence = exports.AbsencesServiceUpdateRequest = exports.AbsencesServiceCreateRequest = exports.ABSENCE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ABSENCE_SORT_KEY
 */
var ABSENCE_SORT_KEY;
(function (ABSENCE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ABSENCE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ABSENCE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ABSENCE_SORT_KEY_CREATED_AT = 1;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_CREATED_AT"] = 1] = "ABSENCE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ABSENCE_SORT_KEY_MODIFIED_AT = 2;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_MODIFIED_AT"] = 2] = "ABSENCE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ABSENCE_SORT_KEY_APPROVED_ON = 3;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_APPROVED_ON"] = 3] = "ABSENCE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ABSENCE_SORT_KEY_APPROVED_BY = 4;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_APPROVED_BY"] = 4] = "ABSENCE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ABSENCE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ABSENCE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ABSENCE_SORT_KEY_COMPLETED_ON = 6;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_COMPLETED_ON"] = 6] = "ABSENCE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: ABSENCE_SORT_KEY_REFERENCE_ID = 10;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_REFERENCE_ID"] = 10] = "ABSENCE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: ABSENCE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "ABSENCE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the user ID
     *
     * @generated from enum value: ABSENCE_SORT_KEY_USER_ID = 12;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_USER_ID"] = 12] = "ABSENCE_SORT_KEY_USER_ID";
    /**
     * Fetch ordered results by the leave request ID
     *
     * @generated from enum value: ABSENCE_SORT_KEY_LEAVE_REQUEST_ID = 13;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_LEAVE_REQUEST_ID"] = 13] = "ABSENCE_SORT_KEY_LEAVE_REQUEST_ID";
    /**
     * Fetch ordered results by the "from timestamp"
     *
     * @generated from enum value: ABSENCE_SORT_KEY_FROM_TIMESTAMP = 14;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_FROM_TIMESTAMP"] = 14] = "ABSENCE_SORT_KEY_FROM_TIMESTAMP";
    /**
     * Fetch ordered results by the "to timestamp"
     *
     * @generated from enum value: ABSENCE_SORT_KEY_TO_TIMESTAMP = 15;
     */
    ABSENCE_SORT_KEY[ABSENCE_SORT_KEY["ABSENCE_SORT_KEY_TO_TIMESTAMP"] = 15] = "ABSENCE_SORT_KEY_TO_TIMESTAMP";
})(ABSENCE_SORT_KEY || (exports.ABSENCE_SORT_KEY = ABSENCE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ABSENCE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ABSENCE_SORT_KEY, "Scailo.ABSENCE_SORT_KEY", [
    { no: 0, name: "ABSENCE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ABSENCE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ABSENCE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ABSENCE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ABSENCE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ABSENCE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "ABSENCE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ABSENCE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "ABSENCE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "ABSENCE_SORT_KEY_USER_ID" },
    { no: 13, name: "ABSENCE_SORT_KEY_LEAVE_REQUEST_ID" },
    { no: 14, name: "ABSENCE_SORT_KEY_FROM_TIMESTAMP" },
    { no: 15, name: "ABSENCE_SORT_KEY_TO_TIMESTAMP" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.AbsencesServiceCreateRequest
 */
class AbsencesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the absence
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the user who has been marked as absent
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the optional associated leave request
     *
     * @generated from field: uint64 leave_request_id = 12;
     */
    leaveRequestId = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the user is absent
     *
     * @generated from field: uint64 from_timestamp = 13;
     */
    fromTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp until when the user is absent
     *
     * @generated from field: uint64 to_timestamp = 14;
     */
    toTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 15;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity = 16;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The description of the absence
     *
     * @generated from field: string description = 17;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "leave_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "from_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "to_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServiceCreateRequest, a, b);
    }
}
exports.AbsencesServiceCreateRequest = AbsencesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.AbsencesServiceUpdateRequest
 */
class AbsencesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the absence
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the optional associated leave request
     *
     * @generated from field: uint64 leave_request_id = 12;
     */
    leaveRequestId = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the user is absent
     *
     * @generated from field: uint64 from_timestamp = 13;
     */
    fromTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp until when the user is absent
     *
     * @generated from field: uint64 to_timestamp = 14;
     */
    toTimestamp = protobuf_1.protoInt64.zero;
    /**
     * // Stores the unit of material ID
     * uint64 uom_id = 15 [(genesis_validate.rules).uint64.gt = 0];
     * Stores the quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity = 16;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The description of the absence
     *
     * @generated from field: string description = 17;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "leave_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "from_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "to_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServiceUpdateRequest, a, b);
    }
}
exports.AbsencesServiceUpdateRequest = AbsencesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Absence
 */
class Absence extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this absence
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this absence
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this absence
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this absence was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the absence
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the user who has been marked as absent
     *
     * @generated from field: uint64 user_id = 12;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the optional associated leave request
     *
     * @generated from field: uint64 leave_request_id = 13;
     */
    leaveRequestId = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp from when the user is absent
     *
     * @generated from field: uint64 from_timestamp = 14;
     */
    fromTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp until when the user is absent
     *
     * @generated from field: uint64 to_timestamp = 15;
     */
    toTimestamp = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 16;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity = 17;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The description of the absence
     *
     * @generated from field: string description = 18;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Absence";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "leave_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "from_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "to_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Absence().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Absence().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Absence().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Absence, a, b);
    }
}
exports.Absence = Absence;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.AbsencesList
 */
class AbsencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Absence list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Absence, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesList, a, b);
    }
}
exports.AbsencesList = AbsencesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.AbsencesServicePaginationReq
 */
class AbsencesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ABSENCE_SORT_KEY sort_key = 5;
     */
    sortKey = ABSENCE_SORT_KEY.ABSENCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this absence
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ABSENCE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServicePaginationReq, a, b);
    }
}
exports.AbsencesServicePaginationReq = AbsencesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.AbsencesServicePaginationResponse
 */
class AbsencesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Absence payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Absence, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServicePaginationResponse, a, b);
    }
}
exports.AbsencesServicePaginationResponse = AbsencesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.AbsencesServiceFilterReq
 */
class AbsencesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ABSENCE_SORT_KEY sort_key = 5;
     */
    sortKey = ABSENCE_SORT_KEY.ABSENCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this absence
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the absence
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the user who has been marked as absent
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the optionally associated leave request
     *
     * @generated from field: uint64 leave_request_id = 23;
     */
    leaveRequestId = protobuf_1.protoInt64.zero;
    /**
     * The start range of "from timestamp"
     *
     * @generated from field: uint64 from_timestamp_start = 24;
     */
    fromTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "from timestamp"
     *
     * @generated from field: uint64 from_timestamp_end = 25;
     */
    fromTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "to timestamp"
     *
     * @generated from field: uint64 to_timestamp_start = 26;
     */
    toTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "to timestamp"
     *
     * @generated from field: uint64 to_timestamp_end = 27;
     */
    toTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 28;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The min quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity_min = 29;
     */
    quantityMin = protobuf_1.protoInt64.zero;
    /**
     * The max quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity_max = 30;
     */
    quantityMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ABSENCE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "leave_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "from_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "from_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "to_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "to_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "quantity_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "quantity_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServiceFilterReq, a, b);
    }
}
exports.AbsencesServiceFilterReq = AbsencesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.AbsencesServiceCountReq
 */
class AbsencesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this absence
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the absence
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the user who has been marked as absent
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the optionally associated leave request
     *
     * @generated from field: uint64 leave_request_id = 23;
     */
    leaveRequestId = protobuf_1.protoInt64.zero;
    /**
     * The start range of "from timestamp"
     *
     * @generated from field: uint64 from_timestamp_start = 24;
     */
    fromTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "from timestamp"
     *
     * @generated from field: uint64 from_timestamp_end = 25;
     */
    fromTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "to timestamp"
     *
     * @generated from field: uint64 to_timestamp_start = 26;
     */
    toTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "to timestamp"
     *
     * @generated from field: uint64 to_timestamp_end = 27;
     */
    toTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 28;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The min quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity_min = 29;
     */
    quantityMin = protobuf_1.protoInt64.zero;
    /**
     * The max quantity of absence (in cents)
     *
     * @generated from field: uint64 quantity_max = 30;
     */
    quantityMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "leave_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "from_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "from_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "to_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "to_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "quantity_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "quantity_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServiceCountReq, a, b);
    }
}
exports.AbsencesServiceCountReq = AbsencesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.AbsencesServiceSearchAllReq
 */
class AbsencesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ABSENCE_SORT_KEY sort_key = 5;
     */
    sortKey = ABSENCE_SORT_KEY.ABSENCE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the user who has been marked as absent
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AbsencesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ABSENCE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AbsencesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AbsencesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AbsencesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AbsencesServiceSearchAllReq, a, b);
    }
}
exports.AbsencesServiceSearchAllReq = AbsencesServiceSearchAllReq;
