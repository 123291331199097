"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file production_plans.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductionPlansServicePaginatedItemsResponse = exports.ProductionPlanItemsSearchRequest = exports.ProductionPlansServiceSearchAllReq = exports.ProductionPlansServiceCountReq = exports.ProductionPlansServiceFilterReq = exports.ProductionPlansServicePaginationResponse = exports.ProductionPlansServicePaginationReq = exports.ProductionPlansServiceAlreadyAddedQuantityForSourceRequest = exports.ProductionPlanItemProspectiveInfoRequest = exports.ProductionPlanItemHistoryRequest = exports.ProductionPlansItemsList = exports.ProductionPlansList = exports.ProductionPlanItem = exports.ProductionPlansServiceItemUpdateRequest = exports.ProductionPlansServiceItemCreateRequest = exports.ProductionPlan = exports.ProductionPlansServiceAutofillRequest = exports.ProductionPlansServiceUpdateRequest = exports.ProductionPlansServiceCreateRequest = exports.PRODUCTION_PLAN_ITEM_STATUS = exports.PRODUCTION_PLAN_ITEM_SORT_KEY = exports.PRODUCTION_PLAN_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PRODUCTION_PLAN_SORT_KEY
 */
var PRODUCTION_PLAN_SORT_KEY;
(function (PRODUCTION_PLAN_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_CREATED_AT = 1;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_CREATED_AT"] = 1] = "PRODUCTION_PLAN_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_MODIFIED_AT = 2;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_MODIFIED_AT"] = 2] = "PRODUCTION_PLAN_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_APPROVED_ON = 3;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_APPROVED_ON"] = 3] = "PRODUCTION_PLAN_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_APPROVED_BY = 4;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_APPROVED_BY"] = 4] = "PRODUCTION_PLAN_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PRODUCTION_PLAN_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_COMPLETED_ON = 6;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_COMPLETED_ON"] = 6] = "PRODUCTION_PLAN_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_REFERENCE_ID = 10;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_REFERENCE_ID"] = 10] = "PRODUCTION_PLAN_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PRODUCTION_PLAN_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_LOCATION_ID = 12;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_LOCATION_ID"] = 12] = "PRODUCTION_PLAN_SORT_KEY_LOCATION_ID";
    /**
     * Fetch ordered results by the start time
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_STARTS_AT = 13;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_STARTS_AT"] = 13] = "PRODUCTION_PLAN_SORT_KEY_STARTS_AT";
    /**
     * Fetch ordered results by the end time
     *
     * @generated from enum value: PRODUCTION_PLAN_SORT_KEY_ENDS_AT = 14;
     */
    PRODUCTION_PLAN_SORT_KEY[PRODUCTION_PLAN_SORT_KEY["PRODUCTION_PLAN_SORT_KEY_ENDS_AT"] = 14] = "PRODUCTION_PLAN_SORT_KEY_ENDS_AT";
})(PRODUCTION_PLAN_SORT_KEY || (exports.PRODUCTION_PLAN_SORT_KEY = PRODUCTION_PLAN_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_PLAN_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PRODUCTION_PLAN_SORT_KEY, "Scailo.PRODUCTION_PLAN_SORT_KEY", [
    { no: 0, name: "PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_PLAN_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PRODUCTION_PLAN_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PRODUCTION_PLAN_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PRODUCTION_PLAN_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PRODUCTION_PLAN_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PRODUCTION_PLAN_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PRODUCTION_PLAN_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PRODUCTION_PLAN_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "PRODUCTION_PLAN_SORT_KEY_LOCATION_ID" },
    { no: 13, name: "PRODUCTION_PLAN_SORT_KEY_STARTS_AT" },
    { no: 14, name: "PRODUCTION_PLAN_SORT_KEY_ENDS_AT" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PRODUCTION_PLAN_ITEM_SORT_KEY
 */
var PRODUCTION_PLAN_ITEM_SORT_KEY;
(function (PRODUCTION_PLAN_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PRODUCTION_PLAN_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PRODUCTION_PLAN_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PRODUCTION_PLAN_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PRODUCTION_PLAN_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the quantity
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_SORT_KEY_QUANTITY = 11;
     */
    PRODUCTION_PLAN_ITEM_SORT_KEY[PRODUCTION_PLAN_ITEM_SORT_KEY["PRODUCTION_PLAN_ITEM_SORT_KEY_QUANTITY"] = 11] = "PRODUCTION_PLAN_ITEM_SORT_KEY_QUANTITY";
})(PRODUCTION_PLAN_ITEM_SORT_KEY || (exports.PRODUCTION_PLAN_ITEM_SORT_KEY = PRODUCTION_PLAN_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_PLAN_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PRODUCTION_PLAN_ITEM_SORT_KEY, "Scailo.PRODUCTION_PLAN_ITEM_SORT_KEY", [
    { no: 0, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PRODUCTION_PLAN_ITEM_SORT_KEY_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of production plan items
 *
 * @generated from enum Scailo.PRODUCTION_PLAN_ITEM_STATUS
 */
var PRODUCTION_PLAN_ITEM_STATUS;
(function (PRODUCTION_PLAN_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PRODUCTION_PLAN_ITEM_STATUS[PRODUCTION_PLAN_ITEM_STATUS["PRODUCTION_PLAN_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PRODUCTION_PLAN_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the production plan items must have been approved
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_STATUS_APPROVED = 1;
     */
    PRODUCTION_PLAN_ITEM_STATUS[PRODUCTION_PLAN_ITEM_STATUS["PRODUCTION_PLAN_ITEM_STATUS_APPROVED"] = 1] = "PRODUCTION_PLAN_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the production plan items must be waiting for approval
     *
     * @generated from enum value: PRODUCTION_PLAN_ITEM_STATUS_UNAPPROVED = 2;
     */
    PRODUCTION_PLAN_ITEM_STATUS[PRODUCTION_PLAN_ITEM_STATUS["PRODUCTION_PLAN_ITEM_STATUS_UNAPPROVED"] = 2] = "PRODUCTION_PLAN_ITEM_STATUS_UNAPPROVED";
})(PRODUCTION_PLAN_ITEM_STATUS || (exports.PRODUCTION_PLAN_ITEM_STATUS = PRODUCTION_PLAN_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PRODUCTION_PLAN_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PRODUCTION_PLAN_ITEM_STATUS, "Scailo.PRODUCTION_PLAN_ITEM_STATUS", [
    { no: 0, name: "PRODUCTION_PLAN_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PRODUCTION_PLAN_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PRODUCTION_PLAN_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ProductionPlansServiceCreateRequest
 */
class ProductionPlansServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production plan
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 16;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 17;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceCreateRequest, a, b);
    }
}
exports.ProductionPlansServiceCreateRequest = ProductionPlansServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ProductionPlansServiceUpdateRequest
 */
class ProductionPlansServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production plan
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 16;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 17;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceUpdateRequest, a, b);
    }
}
exports.ProductionPlansServiceUpdateRequest = ProductionPlansServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.ProductionPlansServiceAutofillRequest
 */
class ProductionPlansServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Denotes if all the equation dependencies (if applicable) should also be automatically added to the production plan
     *
     * @generated from field: bool populate_using_equation_dependencies = 3;
     */
    populateUsingEquationDependencies = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "populate_using_equation_dependencies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceAutofillRequest, a, b);
    }
}
exports.ProductionPlansServiceAutofillRequest = ProductionPlansServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ProductionPlan
 */
class ProductionPlan extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this production plan
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this production plan
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this production plan
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this production plan was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production plan
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 15;
     */
    supervisor = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 16;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The end UNIX timestamp
     *
     * @generated from field: uint64 ends_at = 17;
     */
    endsAt = protobuf_1.protoInt64.zero;
    /**
     * The list of associated production plan items
     *
     * @generated from field: repeated Scailo.ProductionPlanItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlan";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "ends_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: ProductionPlanItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlan().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlan().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlan().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlan, a, b);
    }
}
exports.ProductionPlan = ProductionPlan;
/**
 *
 * Describes the parameters required to add an item to a production plan
 *
 * @generated from message Scailo.ProductionPlansServiceItemCreateRequest
 */
class ProductionPlansServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the production plan ID
     *
     * @generated from field: uint64 production_plan_id = 10;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceItemCreateRequest, a, b);
    }
}
exports.ProductionPlansServiceItemCreateRequest = ProductionPlansServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a production plan
 *
 * @generated from message Scailo.ProductionPlansServiceItemUpdateRequest
 */
class ProductionPlansServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceItemUpdateRequest, a, b);
    }
}
exports.ProductionPlansServiceItemUpdateRequest = ProductionPlansServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a production plan
 *
 * @generated from message Scailo.ProductionPlanItem
 */
class ProductionPlanItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this production plan
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the production plan ID
     *
     * @generated from field: uint64 production_plan_id = 10;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity being manufactured
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlanItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlanItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlanItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlanItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlanItem, a, b);
    }
}
exports.ProductionPlanItem = ProductionPlanItem;
/**
 *
 * Describes the message consisting of the list of production plans
 *
 * @generated from message Scailo.ProductionPlansList
 */
class ProductionPlansList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProductionPlan list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProductionPlan, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansList, a, b);
    }
}
exports.ProductionPlansList = ProductionPlansList;
/**
 *
 * Describes the message consisting of the list of production plan items
 *
 * @generated from message Scailo.ProductionPlansItemsList
 */
class ProductionPlansItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProductionPlanItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProductionPlanItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansItemsList, a, b);
    }
}
exports.ProductionPlansItemsList = ProductionPlansItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ProductionPlanItemHistoryRequest
 */
class ProductionPlanItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the production plan ID
     *
     * @generated from field: uint64 production_plan_id = 10;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlanItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlanItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlanItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlanItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlanItemHistoryRequest, a, b);
    }
}
exports.ProductionPlanItemHistoryRequest = ProductionPlanItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective production plan item
 *
 * @generated from message Scailo.ProductionPlanItemProspectiveInfoRequest
 */
class ProductionPlanItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the production plan ID
     *
     * @generated from field: uint64 production_plan_id = 10;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlanItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlanItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlanItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlanItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlanItemProspectiveInfoRequest, a, b);
    }
}
exports.ProductionPlanItemProspectiveInfoRequest = ProductionPlanItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.ProductionPlansServiceAlreadyAddedQuantityForSourceRequest
 */
class ProductionPlansServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.ProductionPlansServiceAlreadyAddedQuantityForSourceRequest = ProductionPlansServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ProductionPlansServicePaginationReq
 */
class ProductionPlansServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_PLAN_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_PLAN_SORT_KEY.PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this production plan
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_PLAN_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServicePaginationReq, a, b);
    }
}
exports.ProductionPlansServicePaginationReq = ProductionPlansServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ProductionPlansServicePaginationResponse
 */
class ProductionPlansServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProductionPlan payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProductionPlan, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServicePaginationResponse, a, b);
    }
}
exports.ProductionPlansServicePaginationResponse = ProductionPlansServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ProductionPlansServiceFilterReq
 */
class ProductionPlansServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_PLAN_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_PLAN_SORT_KEY.PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this production plan
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production plan
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 30;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 31;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "ends at"
     *
     * @generated from field: uint64 ends_at_start = 32;
     */
    endsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "ends at"
     *
     * @generated from field: uint64 ends_at_end = 33;
     */
    endsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 50;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 60;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 80;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_PLAN_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "ends_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "ends_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceFilterReq, a, b);
    }
}
exports.ProductionPlansServiceFilterReq = ProductionPlansServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ProductionPlansServiceCountReq
 */
class ProductionPlansServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this production plan
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the production plan
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 30;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 31;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "ends at"
     *
     * @generated from field: uint64 ends_at_start = 32;
     */
    endsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "ends at"
     *
     * @generated from field: uint64 ends_at_end = 33;
     */
    endsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 50;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 60;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 80;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "ends_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "ends_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceCountReq, a, b);
    }
}
exports.ProductionPlansServiceCountReq = ProductionPlansServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ProductionPlansServiceSearchAllReq
 */
class ProductionPlansServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_PLAN_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_PLAN_SORT_KEY.PRODUCTION_PLAN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The username of the supervisor
     *
     * @generated from field: string supervisor = 25;
     */
    supervisor = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_PLAN_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "supervisor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServiceSearchAllReq, a, b);
    }
}
exports.ProductionPlansServiceSearchAllReq = ProductionPlansServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.ProductionPlanItemsSearchRequest
 */
class ProductionPlanItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PRODUCTION_PLAN_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PRODUCTION_PLAN_ITEM_SORT_KEY.PRODUCTION_PLAN_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PRODUCTION_PLAN_ITEM_STATUS status = 7;
     */
    status = PRODUCTION_PLAN_ITEM_STATUS.PRODUCTION_PLAN_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the production plan
     *
     * @generated from field: uint64 production_plan_id = 20;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 30;
     */
    familyType = families_scailo_pb_js_1.FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlanItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_PLAN_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PRODUCTION_PLAN_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(families_scailo_pb_js_1.FAMILY_TYPE) },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlanItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlanItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlanItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlanItemsSearchRequest, a, b);
    }
}
exports.ProductionPlanItemsSearchRequest = ProductionPlanItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.ProductionPlansServicePaginatedItemsResponse
 */
class ProductionPlansServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProductionPlanItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProductionPlansServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProductionPlanItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProductionPlansServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProductionPlansServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProductionPlansServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProductionPlansServicePaginatedItemsResponse, a, b);
    }
}
exports.ProductionPlansServicePaginatedItemsResponse = ProductionPlansServicePaginatedItemsResponse;
