"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file debit_notes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebitNoteReferencesList = exports.DebitNoteReference = exports.DebitNotesServiceReferenceCreateRequest = exports.DebitNotesServiceSearchAllReq = exports.DebitNotesServiceCountReq = exports.DebitNotesServiceFilterReq = exports.DebitNotesServicePaginationResponse = exports.DebitNotesServicePaginationReq = exports.DebitNotesServicePaginatedItemsResponse = exports.DebitNoteItemsSearchRequest = exports.DebitNotesServiceAlreadyAddedQuantityForSourceRequest = exports.DebitNoteItemProspectiveInfoRequest = exports.DebitNoteItemHistoryRequest = exports.DebitNoteItemsList = exports.DebitNotesList = exports.DebitNoteItem = exports.DebitNotesServiceItemUpdateRequest = exports.DebitNotesServiceItemCreateRequest = exports.DebitNote = exports.DebitNoteAncillaryParameters = exports.DebitNotesServiceAutofillRequest = exports.DebitNotesServiceUpdateRequest = exports.DebitNotesServiceCreateRequest = exports.DEBIT_NOTE_SORT_KEY = exports.DEBIT_NOTE_ITEM_STATUS = exports.DEBIT_NOTE_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.DEBIT_NOTE_ITEM_SORT_KEY
 */
var DEBIT_NOTE_ITEM_SORT_KEY;
(function (DEBIT_NOTE_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "DEBIT_NOTE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "DEBIT_NOTE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "DEBIT_NOTE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "DEBIT_NOTE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "DEBIT_NOTE_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "DEBIT_NOTE_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit of material ID
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit price
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE = 14;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE"] = 14] = "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_SORT_KEY_TAX_GROUP_ID = 16;
     */
    DEBIT_NOTE_ITEM_SORT_KEY[DEBIT_NOTE_ITEM_SORT_KEY["DEBIT_NOTE_ITEM_SORT_KEY_TAX_GROUP_ID"] = 16] = "DEBIT_NOTE_ITEM_SORT_KEY_TAX_GROUP_ID";
})(DEBIT_NOTE_ITEM_SORT_KEY || (exports.DEBIT_NOTE_ITEM_SORT_KEY = DEBIT_NOTE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(DEBIT_NOTE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(DEBIT_NOTE_ITEM_SORT_KEY, "Scailo.DEBIT_NOTE_ITEM_SORT_KEY", [
    { no: 0, name: "DEBIT_NOTE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "DEBIT_NOTE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "DEBIT_NOTE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "DEBIT_NOTE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "DEBIT_NOTE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "DEBIT_NOTE_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "DEBIT_NOTE_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_QUANTITY" },
    { no: 14, name: "DEBIT_NOTE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE" },
    { no: 16, name: "DEBIT_NOTE_ITEM_SORT_KEY_TAX_GROUP_ID" },
]);
/**
 *
 * Describes the applicable statuses of debit note items
 *
 * @generated from enum Scailo.DEBIT_NOTE_ITEM_STATUS
 */
var DEBIT_NOTE_ITEM_STATUS;
(function (DEBIT_NOTE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    DEBIT_NOTE_ITEM_STATUS[DEBIT_NOTE_ITEM_STATUS["DEBIT_NOTE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "DEBIT_NOTE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the debit note items must have been approved
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_STATUS_APPROVED = 1;
     */
    DEBIT_NOTE_ITEM_STATUS[DEBIT_NOTE_ITEM_STATUS["DEBIT_NOTE_ITEM_STATUS_APPROVED"] = 1] = "DEBIT_NOTE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the debit note items must be waiting for approval
     *
     * @generated from enum value: DEBIT_NOTE_ITEM_STATUS_UNAPPROVED = 2;
     */
    DEBIT_NOTE_ITEM_STATUS[DEBIT_NOTE_ITEM_STATUS["DEBIT_NOTE_ITEM_STATUS_UNAPPROVED"] = 2] = "DEBIT_NOTE_ITEM_STATUS_UNAPPROVED";
})(DEBIT_NOTE_ITEM_STATUS || (exports.DEBIT_NOTE_ITEM_STATUS = DEBIT_NOTE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(DEBIT_NOTE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(DEBIT_NOTE_ITEM_STATUS, "Scailo.DEBIT_NOTE_ITEM_STATUS", [
    { no: 0, name: "DEBIT_NOTE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "DEBIT_NOTE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "DEBIT_NOTE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.DEBIT_NOTE_SORT_KEY
 */
var DEBIT_NOTE_SORT_KEY;
(function (DEBIT_NOTE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_CREATED_AT = 1;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_CREATED_AT"] = 1] = "DEBIT_NOTE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_MODIFIED_AT = 2;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_MODIFIED_AT"] = 2] = "DEBIT_NOTE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_APPROVED_ON = 3;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_APPROVED_ON"] = 3] = "DEBIT_NOTE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_APPROVED_BY = 4;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_APPROVED_BY"] = 4] = "DEBIT_NOTE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "DEBIT_NOTE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_COMPLETED_ON = 6;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_COMPLETED_ON"] = 6] = "DEBIT_NOTE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_REFERENCE_ID = 10;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_REFERENCE_ID"] = 10] = "DEBIT_NOTE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: DEBIT_NOTE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    DEBIT_NOTE_SORT_KEY[DEBIT_NOTE_SORT_KEY["DEBIT_NOTE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "DEBIT_NOTE_SORT_KEY_FINAL_REF_NUMBER";
})(DEBIT_NOTE_SORT_KEY || (exports.DEBIT_NOTE_SORT_KEY = DEBIT_NOTE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(DEBIT_NOTE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(DEBIT_NOTE_SORT_KEY, "Scailo.DEBIT_NOTE_SORT_KEY", [
    { no: 0, name: "DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "DEBIT_NOTE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "DEBIT_NOTE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "DEBIT_NOTE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "DEBIT_NOTE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "DEBIT_NOTE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "DEBIT_NOTE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "DEBIT_NOTE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "DEBIT_NOTE_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.DebitNotesServiceCreateRequest
 */
class DebitNotesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the debit note
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 15;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 16;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceCreateRequest, a, b);
    }
}
exports.DebitNotesServiceCreateRequest = DebitNotesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.DebitNotesServiceUpdateRequest
 */
class DebitNotesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the debit note
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 15;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 16;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceUpdateRequest, a, b);
    }
}
exports.DebitNotesServiceUpdateRequest = DebitNotesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.DebitNotesServiceAutofillRequest
 */
class DebitNotesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceAutofillRequest, a, b);
    }
}
exports.DebitNotesServiceAutofillRequest = DebitNotesServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.DebitNoteAncillaryParameters
 */
class DebitNoteAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 214;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteAncillaryParameters, a, b);
    }
}
exports.DebitNoteAncillaryParameters = DebitNoteAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.DebitNote
 */
class DebitNote extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this debit note
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this debit note
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this debit note
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this debit note was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the debit note
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 15;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 16;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the debit note (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 20;
     */
    totalValue = 0;
    /**
     * The list of associated debit note items
     *
     * @generated from field: repeated Scailo.DebitNoteItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNote";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 30, name: "list", kind: "message", T: DebitNoteItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNote().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNote().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNote().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNote, a, b);
    }
}
exports.DebitNote = DebitNote;
/**
 *
 * Describes the parameters required to add an item to a debit note
 *
 * @generated from message Scailo.DebitNotesServiceItemCreateRequest
 */
class DebitNotesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceItemCreateRequest, a, b);
    }
}
exports.DebitNotesServiceItemCreateRequest = DebitNotesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a debit note
 *
 * @generated from message Scailo.DebitNotesServiceItemUpdateRequest
 */
class DebitNotesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceItemUpdateRequest, a, b);
    }
}
exports.DebitNotesServiceItemUpdateRequest = DebitNotesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a debit note
 *
 * @generated from message Scailo.DebitNoteItem
 */
class DebitNoteItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this debit note
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteItem, a, b);
    }
}
exports.DebitNoteItem = DebitNoteItem;
/**
 *
 * Describes the message consisting of the list of debit notes
 *
 * @generated from message Scailo.DebitNotesList
 */
class DebitNotesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.DebitNote list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: DebitNote, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesList, a, b);
    }
}
exports.DebitNotesList = DebitNotesList;
/**
 *
 * Describes the message consisting of the list of debit note items
 *
 * @generated from message Scailo.DebitNoteItemsList
 */
class DebitNoteItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.DebitNoteItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: DebitNoteItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteItemsList, a, b);
    }
}
exports.DebitNoteItemsList = DebitNoteItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.DebitNoteItemHistoryRequest
 */
class DebitNoteItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteItemHistoryRequest, a, b);
    }
}
exports.DebitNoteItemHistoryRequest = DebitNoteItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective debit note item
 *
 * @generated from message Scailo.DebitNoteItemProspectiveInfoRequest
 */
class DebitNoteItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteItemProspectiveInfoRequest, a, b);
    }
}
exports.DebitNoteItemProspectiveInfoRequest = DebitNoteItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.DebitNotesServiceAlreadyAddedQuantityForSourceRequest
 */
class DebitNotesServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.DebitNotesServiceAlreadyAddedQuantityForSourceRequest = DebitNotesServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.DebitNoteItemsSearchRequest
 */
class DebitNoteItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.DEBIT_NOTE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = DEBIT_NOTE_ITEM_SORT_KEY.DEBIT_NOTE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.DEBIT_NOTE_ITEM_STATUS status = 7;
     */
    status = DEBIT_NOTE_ITEM_STATUS.DEBIT_NOTE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the debit note
     *
     * @generated from field: uint64 debit_note_id = 20;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 23;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 27;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(DEBIT_NOTE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(DEBIT_NOTE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteItemsSearchRequest, a, b);
    }
}
exports.DebitNoteItemsSearchRequest = DebitNoteItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.DebitNotesServicePaginatedItemsResponse
 */
class DebitNotesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.DebitNoteItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: DebitNoteItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServicePaginatedItemsResponse, a, b);
    }
}
exports.DebitNotesServicePaginatedItemsResponse = DebitNotesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.DebitNotesServicePaginationReq
 */
class DebitNotesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.DEBIT_NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = DEBIT_NOTE_SORT_KEY.DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this debit note
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(DEBIT_NOTE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServicePaginationReq, a, b);
    }
}
exports.DebitNotesServicePaginationReq = DebitNotesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.DebitNotesServicePaginationResponse
 */
class DebitNotesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.DebitNote payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: DebitNote, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServicePaginationResponse, a, b);
    }
}
exports.DebitNotesServicePaginationResponse = DebitNotesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.DebitNotesServiceFilterReq
 */
class DebitNotesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.DEBIT_NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = DEBIT_NOTE_SORT_KEY.DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this debit note
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the debit note
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 30;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 31;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 32;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(DEBIT_NOTE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceFilterReq, a, b);
    }
}
exports.DebitNotesServiceFilterReq = DebitNotesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.DebitNotesServiceCountReq
 */
class DebitNotesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this debit note
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the debit note
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 30;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 31;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 32;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceCountReq, a, b);
    }
}
exports.DebitNotesServiceCountReq = DebitNotesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.DebitNotesServiceSearchAllReq
 */
class DebitNotesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.DEBIT_NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = DEBIT_NOTE_SORT_KEY.DEBIT_NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 25;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(DEBIT_NOTE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceSearchAllReq, a, b);
    }
}
exports.DebitNotesServiceSearchAllReq = DebitNotesServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a debit note reference
 *
 * @generated from message Scailo.DebitNotesServiceReferenceCreateRequest
 */
class DebitNotesServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 11;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNotesServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNotesServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNotesServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNotesServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNotesServiceReferenceCreateRequest, a, b);
    }
}
exports.DebitNotesServiceReferenceCreateRequest = DebitNotesServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a debit note reference
 *
 * @generated from message Scailo.DebitNoteReference
 */
class DebitNoteReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this debit note
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the debit note ID
     *
     * @generated from field: uint64 debit_note_id = 10;
     */
    debitNoteId = protobuf_1.protoInt64.zero;
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 11;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "debit_note_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteReference, a, b);
    }
}
exports.DebitNoteReference = DebitNoteReference;
/**
 *
 * Describes the message consisting of the list of debit note references
 *
 * @generated from message Scailo.DebitNoteReferencesList
 */
class DebitNoteReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.DebitNoteReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DebitNoteReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: DebitNoteReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new DebitNoteReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DebitNoteReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DebitNoteReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DebitNoteReferencesList, a, b);
    }
}
exports.DebitNoteReferencesList = DebitNoteReferencesList;
