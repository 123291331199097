import { createPromiseClient, Interceptor, UnaryResponse, StreamResponse, ConnectError, Code } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AssociatesService, ClientsService, ClientStreamsService, ComponentsService, CreditNotesService, CurrenciesService, DebitNotesService, EquipmentsService, FamiliesService, FeedstocksService, GeneralSettingsService, GoodsDispatchesService, GoodsReceiptsService, InfrastructuresService, InwardJobsFreeIssueMaterialsReturnsService, InwardJobsFreeIssueMaterialsService, InwardJobsService, MerchandisesService, OutwardJobsFreeIssueMaterialsReturnsService, OutwardJobsFreeIssueMaterialsService, OutwardJobsService, ProductionPlansService, ProductsService, PurchasesEnquiriesService, PurchasesOrdersService, PurchasesPaymentsService, PurchasesReturnsService, QCSamplesService, QuotationsRequestsService, QuotationsResponsesService, SalesEnquiriesService, SalesInvoicesService, SalesOrdersService, SalesQuotationsService, SalesReceiptsService, SalesReturnsService, UnitsOfMaterialsService, UsersService, VaultService, VendorInvoicesService, VendorsService, WorkOrdersService } from "@kernelminds/scailo-sdk";
import { showFailureAlert } from "./utilities";

// appendAuthToken is an interceptor that appends the auth token to the request prior to sending the request
const appendAuthToken: Interceptor = (next) => async (req) => {
    return await next(req).catch((err: ConnectError) => {
        if (err) {
            if (err.code == Code.Unauthenticated) {
                location.href = "/";
                return;
            }
            showFailureAlert(err.message);
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

function getTransport() {
    return createConnectTransport({
        // Need to use binary format (at least for the time being)
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken
        ]
    });
}

/**Returns the client for the sales order service */
export function getSalesOrderServiceClient() {
    return createPromiseClient(SalesOrdersService, getTransport());
}

/**Returns the client for the work order service */
export function getWorkOrderServiceClient() {
    return createPromiseClient(WorkOrdersService, getTransport());
}

/**Returns the client for the goods dispatch service */
export function getGoodsDispatchServiceClient() {
    return createPromiseClient(GoodsDispatchesService, getTransport());
}

/**Returns the client for the sales invoice service */
export function getSalesInvoiceServiceClient() {
    return createPromiseClient(SalesInvoicesService, getTransport());
}

/**Returns the client for the credit note service */
export function getCreditNoteServiceClient() {
    return createPromiseClient(CreditNotesService, getTransport());
}

/**Returns the client for the sales return service */
export function getSalesReturnServiceClient() {
    return createPromiseClient(SalesReturnsService, getTransport());
}

/**Returns the client for the sales receipt service */
export function getSalesReceiptServiceClient() {
    return createPromiseClient(SalesReceiptsService, getTransport());
}

/**Returns the client for the production plan service */
export function getProductionPlanServiceClient() {
    return createPromiseClient(ProductionPlansService, getTransport());
}

/**Returns the client for the inward job service */
export function getInwardJobServiceClient() {
    return createPromiseClient(InwardJobsService, getTransport());
}

/**Returns the client for the qc samples service */
export function getQCSamplesServiceClient() {
    return createPromiseClient(QCSamplesService, getTransport());
}

/**Returns the client for the inward job free issue material service */
export function getInwardJobFreeIssueMaterialServiceClient() {
    return createPromiseClient(InwardJobsFreeIssueMaterialsService, getTransport());
}

/**Returns the client for the inward job free issue material return service */
export function getInwardJobFreeIssueMaterialReturnServiceClient() {
    return createPromiseClient(InwardJobsFreeIssueMaterialsReturnsService, getTransport());
}

/**Returns the client for the sales enquiry service */
export function getSalesEnquiriesServiceClient() {
    return createPromiseClient(SalesEnquiriesService, getTransport());
}

/**Returns the client for the sales quotation service */
export function getSalesQuotationsServiceClient() {
    return createPromiseClient(SalesQuotationsService, getTransport());
}

/**Returns the client for currencies service */
export function getCurrenciesServiceClient() {
    return createPromiseClient(CurrenciesService, getTransport());
}

/**Returns the client for units of materials service */
export function getUnitsOfMaterialsServiceClient() {
    return createPromiseClient(UnitsOfMaterialsService, getTransport());
}

/**Returns the client for families service */
export function getFamiliesServiceClient() {
    return createPromiseClient(FamiliesService, getTransport());
}

/**Returns the client for components service */
export function getComponentsServiceClient() {
    return createPromiseClient(ComponentsService, getTransport());
}

/**Returns the client for feedstock service */
export function getFeedstockServiceClient() {
    return createPromiseClient(FeedstocksService, getTransport());
}

/**Returns the client for merchandise service */
export function getMerchandiseServiceClient() {
    return createPromiseClient(MerchandisesService, getTransport());
}

/**Returns the client for infrastructure service */
export function getInfrastructureServiceClient() {
    return createPromiseClient(InfrastructuresService, getTransport());
}

/**Returns the client for equipment service */
export function getEquipmentServiceClient() {
    return createPromiseClient(EquipmentsService, getTransport());
}

/**Returns the client for products service */
export function getProductsServiceClient() {
    return createPromiseClient(ProductsService, getTransport());
}

/**Returns the client for clients service */
export function getClientsServiceClient() {
    return createPromiseClient(ClientsService, getTransport());
}

/**Returns the client for associates service */
export function getAssociatesServiceClient() {
    return createPromiseClient(AssociatesService, getTransport());
}

/**Returns the client for client streams service */
export function getClientStreamsServiceClient() {
    return createPromiseClient(ClientStreamsService, getTransport());
}

/**Returns the client for users service */
export function getUsersServiceClient() {
    return createPromiseClient(UsersService, getTransport());   
}

/**Returns the client for general settings service */
export function getGeneralSettingsServiceClient() {
    return createPromiseClient(GeneralSettingsService, getTransport());
}

/**Returns the client for vault service */
export function getVaultServiceClient() {
    return createPromiseClient(VaultService, getTransport());   
}