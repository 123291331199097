"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file associates.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssociatesService = void 0;
const associates_scailo_pb_js_1 = require("./associates.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the methods applicable on each associate
 *
 * @generated from service Scailo.AssociatesService
 */
exports.AssociatesService = {
    typeName: "Scailo.AssociatesService",
    methods: {
        /**
         * Import associates
         *
         * @generated from rpc Scailo.AssociatesService.Import
         */
        import: {
            name: "Import",
            I: associates_scailo_pb_js_1.AssociatesServiceImportRequest,
            O: associates_scailo_pb_js_1.AssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create a associate
         *
         * @generated from rpc Scailo.AssociatesService.Create
         */
        create: {
            name: "Create",
            I: associates_scailo_pb_js_1.AssociatesServiceCreateRequest,
            O: associates_scailo_pb_js_1.Associate,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update a associate
         *
         * @generated from rpc Scailo.AssociatesService.Update
         */
        update: {
            name: "Update",
            I: associates_scailo_pb_js_1.AssociatesServiceUpdateRequest,
            O: associates_scailo_pb_js_1.Associate,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard the associate
         *
         * @generated from rpc Scailo.AssociatesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore the associate
         *
         * @generated from rpc Scailo.AssociatesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.AssociatesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: associates_scailo_pb_js_1.Associate,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.AssociatesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: associates_scailo_pb_js_1.Associate,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Associate by ID as a vCard
         *
         * @generated from rpc Scailo.AssociatesService.DownloadVCard
         */
        downloadVCard: {
            name: "DownloadVCard",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BytesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all associates
         *
         * @generated from rpc Scailo.AssociatesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: associates_scailo_pb_js_1.AssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all associates with the given entity UUID
         *
         * @generated from rpc Scailo.AssociatesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: associates_scailo_pb_js_1.AssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View associates with pagination
         *
         * @generated from rpc Scailo.AssociatesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: associates_scailo_pb_js_1.AssociatesServicePaginationReq,
            O: associates_scailo_pb_js_1.AssociatePaginationResp,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if the user has permission to modify an associate
         *
         * @generated from rpc Scailo.AssociatesService.CheckModifyPermission
         */
        checkModifyPermission: {
            name: "CheckModifyPermission",
            I: base_scailo_pb_js_1.Empty,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if the user has permission to add an associate
         *
         * @generated from rpc Scailo.AssociatesService.CheckAddPermission
         */
        checkAddPermission: {
            name: "CheckAddPermission",
            I: base_scailo_pb_js_1.Empty,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all associates that match the given search key
         *
         * @generated from rpc Scailo.AssociatesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: associates_scailo_pb_js_1.AssociatesServiceSearchAllReq,
            O: associates_scailo_pb_js_1.AssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.AssociatesService.Filter
         */
        filter: {
            name: "Filter",
            I: associates_scailo_pb_js_1.AssociatesServiceFilterReq,
            O: associates_scailo_pb_js_1.AssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.AssociatesService.Count
         */
        count: {
            name: "Count",
            I: associates_scailo_pb_js_1.AssociatesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
