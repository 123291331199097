"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file vault_services.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultService = void 0;
const vault_files_scailo_pb_js_1 = require("./vault_files.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const vault_commons_scailo_pb_js_1 = require("./vault_commons.scailo_pb.js");
const vault_folders_scailo_pb_js_1 = require("./vault_folders.scailo_pb.js");
const vault_services_scailo_pb_js_1 = require("./vault_services.scailo_pb.js");
const roles_scailo_pb_js_1 = require("./roles.scailo_pb.js");
/**
 *
 * Describes the available services in Vault
 *
 * File APIs
 *
 * @generated from service Scailo.VaultService
 */
exports.VaultService = {
    typeName: "Scailo.VaultService",
    methods: {
        /**
         * Initiate file upload
         *
         * @generated from rpc Scailo.VaultService.InitiateFile
         */
        initiateFile: {
            name: "InitiateFile",
            I: vault_files_scailo_pb_js_1.VaultFileInitiateFileRequest,
            O: vault_files_scailo_pb_js_1.VaultFileInitiateFileResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add chunk to a file version and returns the UUID of the added chunk
         *
         * @generated from rpc Scailo.VaultService.AddFileChunk
         */
        addFileChunk: {
            name: "AddFileChunk",
            I: vault_files_scailo_pb_js_1.VaultFileAddChunkRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete file upload
         *
         * @generated from rpc Scailo.VaultService.CompleteFile
         */
        completeFile: {
            name: "CompleteFile",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Rename file
         *
         * @generated from rpc Scailo.VaultService.RenameFile
         */
        renameFile: {
            name: "RenameFile",
            I: vault_files_scailo_pb_js_1.VaultFileRenameFileRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Unzip file
         *
         * @generated from rpc Scailo.VaultService.UnzipFile
         */
        unzipFile: {
            name: "UnzipFile",
            I: vault_files_scailo_pb_js_1.VaultFileUnzipRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Persist file
         *
         * @generated from rpc Scailo.VaultService.PersistFile
         */
        persistFile: {
            name: "PersistFile",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Move file
         *
         * @generated from rpc Scailo.VaultService.MoveFile
         */
        moveFile: {
            name: "MoveFile",
            I: vault_files_scailo_pb_js_1.VaultFileMoveFileRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete file
         *
         * @generated from rpc Scailo.VaultService.DeleteFile
         */
        deleteFile: {
            name: "DeleteFile",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Permission APIs
         * Add permission and returns the UUID of the added permission
         *
         * @generated from rpc Scailo.VaultService.AddFilePermission
         */
        addFilePermission: {
            name: "AddFilePermission",
            I: vault_commons_scailo_pb_js_1.VaultPermissionAddRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify permission and returns the UUID of the added permission
         *
         * @generated from rpc Scailo.VaultService.ModifyFilePermission
         */
        modifyFilePermission: {
            name: "ModifyFilePermission",
            I: vault_commons_scailo_pb_js_1.VaultPermissionModifyRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete permission (returns the UUID of the file)
         *
         * @generated from rpc Scailo.VaultService.DeleteFilePermission
         */
        deleteFilePermission: {
            name: "DeleteFilePermission",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Downloads
         * Download file with the given UUID
         *
         * @generated from rpc Scailo.VaultService.DownloadFile
         */
        downloadFile: {
            name: "DownloadFile",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download file version with the given UUID
         *
         * @generated from rpc Scailo.VaultService.DownloadFileVersion
         */
        downloadFileVersion: {
            name: "DownloadFileVersion",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View logo
         *
         * @generated from rpc Scailo.VaultService.ViewFileLogo
         */
        viewFileLogo: {
            name: "ViewFileLogo",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View file by UUID
         *
         * @generated from rpc Scailo.VaultService.ViewFileByUUID
         */
        viewFileByUUID: {
            name: "ViewFileByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_files_scailo_pb_js_1.VaultFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View file by ID
         *
         * @generated from rpc Scailo.VaultService.ViewFileByID
         */
        viewFileByID: {
            name: "ViewFileByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vault_files_scailo_pb_js_1.VaultFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View chunk with the given UUID
         *
         * @generated from rpc Scailo.VaultService.ViewFileChunk
         */
        viewFileChunk: {
            name: "ViewFileChunk",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_files_scailo_pb_js_1.VaultFileVersionChunk,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View chunk metadata
         *
         * @generated from rpc Scailo.VaultService.ViewFileChunkMetadata
         */
        viewFileChunkMetadata: {
            name: "ViewFileChunkMetadata",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_files_scailo_pb_js_1.VaultFileVersionChunk,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View permission for file
         *
         * @generated from rpc Scailo.VaultService.ViewFilePermission
         */
        viewFilePermission: {
            name: "ViewFilePermission",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_commons_scailo_pb_js_1.VaultPermission,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if file already exists
         *
         * @generated from rpc Scailo.VaultService.DoesFileExist
         */
        doesFileExist: {
            name: "DoesFileExist",
            I: vault_commons_scailo_pb_js_1.VaultDuplicateCheckReq,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all versions of file
         *
         * @generated from rpc Scailo.VaultService.ViewFileVersions
         */
        viewFileVersions: {
            name: "ViewFileVersions",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_files_scailo_pb_js_1.VaultFileVersionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View access logs of file
         *
         * @generated from rpc Scailo.VaultService.ViewFileAccessLogs
         */
        viewFileAccessLogs: {
            name: "ViewFileAccessLogs",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_commons_scailo_pb_js_1.VaultAccessLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add folder
         *
         * @generated from rpc Scailo.VaultService.AddFolder
         */
        addFolder: {
            name: "AddFolder",
            I: vault_folders_scailo_pb_js_1.VaultFolderAddRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Move folder
         *
         * @generated from rpc Scailo.VaultService.MoveFolder
         */
        moveFolder: {
            name: "MoveFolder",
            I: vault_folders_scailo_pb_js_1.VaultFolderMoveFolderRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Rename folder
         *
         * @generated from rpc Scailo.VaultService.RenameFolder
         */
        renameFolder: {
            name: "RenameFolder",
            I: vault_folders_scailo_pb_js_1.VaultFolderRenameFolderRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete folder
         *
         * @generated from rpc Scailo.VaultService.DeleteFolder
         */
        deleteFolder: {
            name: "DeleteFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Zip folder and returns the UUID of the vault folder download
         *
         * @generated from rpc Scailo.VaultService.ZipFolder
         */
        zipFolder: {
            name: "ZipFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Permission APIs
         * Add permission and returns the UUID of the added permission
         *
         * @generated from rpc Scailo.VaultService.AddFolderPermission
         */
        addFolderPermission: {
            name: "AddFolderPermission",
            I: vault_commons_scailo_pb_js_1.VaultPermissionAddRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify permission and returns the UUID of the modified permission
         *
         * @generated from rpc Scailo.VaultService.ModifyFolderPermission
         */
        modifyFolderPermission: {
            name: "ModifyFolderPermission",
            I: vault_commons_scailo_pb_js_1.VaultPermissionModifyRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete permission (returns the UUID of the folder)
         *
         * @generated from rpc Scailo.VaultService.DeleteFolderPermission
         */
        deleteFolderPermission: {
            name: "DeleteFolderPermission",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View folder by ID
         *
         * @generated from rpc Scailo.VaultService.ViewFolderByID
         */
        viewFolderByID: {
            name: "ViewFolderByID",
            I: base_scailo_pb_js_1.IdentifierZeroable,
            O: vault_folders_scailo_pb_js_1.VaultFolder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View folder by UUID
         *
         * @generated from rpc Scailo.VaultService.ViewFolderByUUID
         */
        viewFolderByUUID: {
            name: "ViewFolderByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_folders_scailo_pb_js_1.VaultFolder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the status of the folder download
         *
         * @generated from rpc Scailo.VaultService.ViewFolderDownloadStatus
         */
        viewFolderDownloadStatus: {
            name: "ViewFolderDownloadStatus",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_folders_scailo_pb_js_1.VaultFolderDownload,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the vault folder
         *
         * @generated from rpc Scailo.VaultService.DownloadFolder
         */
        downloadFolder: {
            name: "DownloadFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all files accessible by self in the given folder
         *
         * @generated from rpc Scailo.VaultService.ViewAccessibleFilesInFolder
         */
        viewAccessibleFilesInFolder: {
            name: "ViewAccessibleFilesInFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_files_scailo_pb_js_1.VaultFilesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all folders accessible by self in the given folder
         *
         * @generated from rpc Scailo.VaultService.ViewAccessibleFoldersInFolder
         */
        viewAccessibleFoldersInFolder: {
            name: "ViewAccessibleFoldersInFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_folders_scailo_pb_js_1.VaultFoldersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the resources accessible by self in the given folder
         *
         * @generated from rpc Scailo.VaultService.ViewAccessibleResourcesInFolder
         */
        viewAccessibleResourcesInFolder: {
            name: "ViewAccessibleResourcesInFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_services_scailo_pb_js_1.VaultResourcesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View permission for folder
         *
         * @generated from rpc Scailo.VaultService.ViewFolderPermission
         */
        viewFolderPermission: {
            name: "ViewFolderPermission",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_commons_scailo_pb_js_1.VaultPermission,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if folder already exists
         *
         * @generated from rpc Scailo.VaultService.DoesFolderExist
         */
        doesFolderExist: {
            name: "DoesFolderExist",
            I: vault_commons_scailo_pb_js_1.VaultDuplicateCheckReq,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View access logs of folder
         *
         * @generated from rpc Scailo.VaultService.ViewFolderAccessLogs
         */
        viewFolderAccessLogs: {
            name: "ViewFolderAccessLogs",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_commons_scailo_pb_js_1.VaultAccessLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View passthrough roles for folder
         *
         * @generated from rpc Scailo.VaultService.ViewPassthroughRolesForFolder
         */
        viewPassthroughRolesForFolder: {
            name: "ViewPassthroughRolesForFolder",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: roles_scailo_pb_js_1.RolesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search for files and folders
         *
         * @generated from rpc Scailo.VaultService.Search
         */
        search: {
            name: "Search",
            I: vault_commons_scailo_pb_js_1.VaultSearchReq,
            O: vault_commons_scailo_pb_js_1.VaultSearchResponsesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * ------------------------------------------
         * GiX apps routes
         * Setup GiX app
         *
         * @generated from rpc Scailo.VaultService.SetupGiX
         */
        setupGiX: {
            name: "SetupGiX",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vault_commons_scailo_pb_js_1.GiXAppRun,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a DELETE API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayDELETE
         */
        giXRelayDELETE: {
            name: "GiXRelayDELETE",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithoutBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a GET API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayGET
         */
        giXRelayGET: {
            name: "GiXRelayGET",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithoutBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a HEAD API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayHEAD
         */
        giXRelayHEAD: {
            name: "GiXRelayHEAD",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithoutBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a PATCH API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayPATCH
         */
        giXRelayPATCH: {
            name: "GiXRelayPATCH",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a POST API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayPOST
         */
        giXRelayPOST: {
            name: "GiXRelayPOST",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Relay a PUT API
         *
         * @generated from rpc Scailo.VaultService.GiXRelayPUT
         */
        giXRelayPUT: {
            name: "GiXRelayPUT",
            I: vault_services_scailo_pb_js_1.GiXRelayReqWithBody,
            O: vault_services_scailo_pb_js_1.GiXRelayResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all runs that match the given filter criteria
         *
         * @generated from rpc Scailo.VaultService.GiXFilter
         */
        giXFilter: {
            name: "GiXFilter",
            I: vault_commons_scailo_pb_js_1.GiXAppRunFilterReq,
            O: vault_commons_scailo_pb_js_1.GiXAppRunsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all runs that match the given filter criteria
         *
         * @generated from rpc Scailo.VaultService.GiXCount
         */
        giXCount: {
            name: "GiXCount",
            I: vault_commons_scailo_pb_js_1.GiXAppRunCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
