"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file goals.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalsServiceSearchAllReq = exports.GoalsServiceCountReq = exports.GoalsServiceFilterReq = exports.GoalsServicePaginationResponse = exports.GoalsServicePaginationReq = exports.GoalsServicePaginatedItemsResponse = exports.GoalItemsSearchRequest = exports.GoalItemHistoryRequest = exports.GoalsItemsList = exports.GoalsList = exports.GoalItem = exports.GoalsServiceItemUpdateRequest = exports.GoalsServiceItemCreateRequest = exports.Goal = exports.GoalsServiceUpdateRequest = exports.GoalsServiceCreateRequest = exports.GOAL_SORT_KEY = exports.GOAL_ITEM_STATUS = exports.GOAL_ITEM_SORT_KEY = exports.GOAL_ITEM_INPUT_VALUE_TYPE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available input types for a goal item (with possible vbalues being number-absolute, number-percentage, text-input, text-dropdown)
 *
 * @generated from enum Scailo.GOAL_ITEM_INPUT_VALUE_TYPE
 */
var GOAL_ITEM_INPUT_VALUE_TYPE;
(function (GOAL_ITEM_INPUT_VALUE_TYPE) {
    /**
     * Input value type is an absolute number (with min and max defined in number_min_value and number_max_value)
     *
     * @generated from enum value: GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED = 0;
     */
    GOAL_ITEM_INPUT_VALUE_TYPE[GOAL_ITEM_INPUT_VALUE_TYPE["GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED"] = 0] = "GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED";
    /**
     * Input value type is a percentage
     *
     * @generated from enum value: GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_PERCENTAGE = 1;
     */
    GOAL_ITEM_INPUT_VALUE_TYPE[GOAL_ITEM_INPUT_VALUE_TYPE["GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_PERCENTAGE"] = 1] = "GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_PERCENTAGE";
    /**
     * Input value type is a textual input
     *
     * @generated from enum value: GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_INPUT = 2;
     */
    GOAL_ITEM_INPUT_VALUE_TYPE[GOAL_ITEM_INPUT_VALUE_TYPE["GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_INPUT"] = 2] = "GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_INPUT";
    /**
     * Input value type is a dropdown (from the values as defined in text_values)
     *
     * @generated from enum value: GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_DROPDOWN = 3;
     */
    GOAL_ITEM_INPUT_VALUE_TYPE[GOAL_ITEM_INPUT_VALUE_TYPE["GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_DROPDOWN"] = 3] = "GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_DROPDOWN";
})(GOAL_ITEM_INPUT_VALUE_TYPE || (exports.GOAL_ITEM_INPUT_VALUE_TYPE = GOAL_ITEM_INPUT_VALUE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOAL_ITEM_INPUT_VALUE_TYPE)
protobuf_1.proto3.util.setEnumType(GOAL_ITEM_INPUT_VALUE_TYPE, "Scailo.GOAL_ITEM_INPUT_VALUE_TYPE", [
    { no: 0, name: "GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED" },
    { no: 1, name: "GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_PERCENTAGE" },
    { no: 2, name: "GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_INPUT" },
    { no: 3, name: "GOAL_ITEM_INPUT_VALUE_TYPE_TEXT_DROPDOWN" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOAL_ITEM_SORT_KEY
 */
var GOAL_ITEM_SORT_KEY;
(function (GOAL_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOAL_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_CREATED_AT"] = 1] = "GOAL_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "GOAL_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "GOAL_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "GOAL_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOAL_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the label ID
     *
     * @generated from enum value: GOAL_ITEM_SORT_KEY_LABEL_ID = 10;
     */
    GOAL_ITEM_SORT_KEY[GOAL_ITEM_SORT_KEY["GOAL_ITEM_SORT_KEY_LABEL_ID"] = 10] = "GOAL_ITEM_SORT_KEY_LABEL_ID";
})(GOAL_ITEM_SORT_KEY || (exports.GOAL_ITEM_SORT_KEY = GOAL_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOAL_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOAL_ITEM_SORT_KEY, "Scailo.GOAL_ITEM_SORT_KEY", [
    { no: 0, name: "GOAL_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOAL_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOAL_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOAL_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOAL_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOAL_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "GOAL_ITEM_SORT_KEY_LABEL_ID" },
]);
/**
 *
 * Describes the applicable statuses of goal items
 *
 * @generated from enum Scailo.GOAL_ITEM_STATUS
 */
var GOAL_ITEM_STATUS;
(function (GOAL_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: GOAL_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    GOAL_ITEM_STATUS[GOAL_ITEM_STATUS["GOAL_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "GOAL_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the goal items must have been approved
     *
     * @generated from enum value: GOAL_ITEM_STATUS_APPROVED = 1;
     */
    GOAL_ITEM_STATUS[GOAL_ITEM_STATUS["GOAL_ITEM_STATUS_APPROVED"] = 1] = "GOAL_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the goal items must be waiting for approval
     *
     * @generated from enum value: GOAL_ITEM_STATUS_UNAPPROVED = 2;
     */
    GOAL_ITEM_STATUS[GOAL_ITEM_STATUS["GOAL_ITEM_STATUS_UNAPPROVED"] = 2] = "GOAL_ITEM_STATUS_UNAPPROVED";
})(GOAL_ITEM_STATUS || (exports.GOAL_ITEM_STATUS = GOAL_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOAL_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(GOAL_ITEM_STATUS, "Scailo.GOAL_ITEM_STATUS", [
    { no: 0, name: "GOAL_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "GOAL_ITEM_STATUS_APPROVED" },
    { no: 2, name: "GOAL_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOAL_SORT_KEY
 */
var GOAL_SORT_KEY;
(function (GOAL_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOAL_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOAL_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOAL_SORT_KEY_CREATED_AT = 1;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_CREATED_AT"] = 1] = "GOAL_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOAL_SORT_KEY_MODIFIED_AT = 2;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_MODIFIED_AT"] = 2] = "GOAL_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOAL_SORT_KEY_APPROVED_ON = 3;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_APPROVED_ON"] = 3] = "GOAL_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOAL_SORT_KEY_APPROVED_BY = 4;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_APPROVED_BY"] = 4] = "GOAL_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOAL_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOAL_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: GOAL_SORT_KEY_COMPLETED_ON = 6;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_COMPLETED_ON"] = 6] = "GOAL_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: GOAL_SORT_KEY_REFERENCE_ID = 10;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_REFERENCE_ID"] = 10] = "GOAL_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: GOAL_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    GOAL_SORT_KEY[GOAL_SORT_KEY["GOAL_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "GOAL_SORT_KEY_FINAL_REF_NUMBER";
})(GOAL_SORT_KEY || (exports.GOAL_SORT_KEY = GOAL_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOAL_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOAL_SORT_KEY, "Scailo.GOAL_SORT_KEY", [
    { no: 0, name: "GOAL_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOAL_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOAL_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOAL_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOAL_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOAL_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "GOAL_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "GOAL_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "GOAL_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.GoalsServiceCreateRequest
 */
class GoalsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goal
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated user ID
     *
     * @generated from field: uint64 user_id = 12;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The date from which the goal comes into force
     *
     * @generated from field: string start_date = 13;
     */
    startDate = "";
    /**
     * The date until which the goal remains into force
     *
     * @generated from field: string end_date = 14;
     */
    endDate = "";
    /**
     * The description of the goal
     *
     * @generated from field: string description = 20;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceCreateRequest, a, b);
    }
}
exports.GoalsServiceCreateRequest = GoalsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.GoalsServiceUpdateRequest
 */
class GoalsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goal
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The date from which the goal comes into force
     *
     * @generated from field: string start_date = 13;
     */
    startDate = "";
    /**
     * The date until which the goal remains into force
     *
     * @generated from field: string end_date = 14;
     */
    endDate = "";
    /**
     * The description of the goal
     *
     * @generated from field: string description = 20;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceUpdateRequest, a, b);
    }
}
exports.GoalsServiceUpdateRequest = GoalsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Goal
 */
class Goal extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goal
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this goal
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this goal
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this goal was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goal
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated user ID
     *
     * @generated from field: uint64 user_id = 12;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The date from which the goal comes into force
     *
     * @generated from field: string start_date = 13;
     */
    startDate = "";
    /**
     * The date until which the goal remains into force
     *
     * @generated from field: string end_date = 14;
     */
    endDate = "";
    /**
     * The description of the goal
     *
     * @generated from field: string description = 20;
     */
    description = "";
    /**
     * The list of associated goal items
     *
     * @generated from field: repeated Scailo.GoalItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Goal";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "list", kind: "message", T: GoalItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Goal().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Goal().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Goal().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Goal, a, b);
    }
}
exports.Goal = Goal;
/**
 *
 * Describes the parameters required to add an item to a goal
 *
 * @generated from message Scailo.GoalsServiceItemCreateRequest
 */
class GoalsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the goal ID
     *
     * @generated from field: uint64 goal_id = 10;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores an optional label
     *
     * @generated from field: uint64 label_id = 12;
     */
    labelId = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 13;
     */
    specifications = "";
    /**
     * The input value type of the item
     *
     * @generated from field: Scailo.GOAL_ITEM_INPUT_VALUE_TYPE input_value_type = 20;
     */
    inputValueType = GOAL_ITEM_INPUT_VALUE_TYPE.GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The minimum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_min_value = 21;
     */
    numberMinValue = protobuf_1.protoInt64.zero;
    /**
     * The maximum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_max_value = 22;
     */
    numberMaxValue = protobuf_1.protoInt64.zero;
    /**
     * The acceptable value of the item in case input_value_type is of number type
     *
     * @generated from field: uint64 number_acceptable_value = 23;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The possible values from which a user can choose in case the input type is text-dropdown
     *
     * @generated from field: repeated string text_values = 30;
     */
    textValues = [];
    /**
     * The acceptable value of the item in case input_value_type is of text type
     *
     * @generated from field: string text_acceptable_value = 31;
     */
    textAcceptableValue = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "input_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_ITEM_INPUT_VALUE_TYPE) },
        { no: 21, name: "number_min_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "number_max_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "number_acceptable_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "text_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceItemCreateRequest, a, b);
    }
}
exports.GoalsServiceItemCreateRequest = GoalsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a goal
 *
 * @generated from message Scailo.GoalsServiceItemUpdateRequest
 */
class GoalsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores an optional label
     *
     * @generated from field: uint64 label_id = 12;
     */
    labelId = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 13;
     */
    specifications = "";
    /**
     * The input value type of the item
     *
     * @generated from field: Scailo.GOAL_ITEM_INPUT_VALUE_TYPE input_value_type = 20;
     */
    inputValueType = GOAL_ITEM_INPUT_VALUE_TYPE.GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The minimum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_min_value = 21;
     */
    numberMinValue = protobuf_1.protoInt64.zero;
    /**
     * The maximum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_max_value = 22;
     */
    numberMaxValue = protobuf_1.protoInt64.zero;
    /**
     * The acceptable value of the item in case input_value_type is of number type
     *
     * @generated from field: uint64 number_acceptable_value = 23;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The possible values from which a user can choose in case the input type is text-dropdown
     *
     * @generated from field: repeated string text_values = 30;
     */
    textValues = [];
    /**
     * The acceptable value of the item in case input_value_type is of text type
     *
     * @generated from field: string text_acceptable_value = 31;
     */
    textAcceptableValue = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "input_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_ITEM_INPUT_VALUE_TYPE) },
        { no: 21, name: "number_min_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "number_max_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "number_acceptable_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "text_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceItemUpdateRequest, a, b);
    }
}
exports.GoalsServiceItemUpdateRequest = GoalsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a goal
 *
 * @generated from message Scailo.GoalItem
 */
class GoalItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goal
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the goal ID
     *
     * @generated from field: uint64 goal_id = 10;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores an optional label
     *
     * @generated from field: uint64 label_id = 12;
     */
    labelId = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 13;
     */
    specifications = "";
    /**
     * The input value type of the item
     *
     * @generated from field: Scailo.GOAL_ITEM_INPUT_VALUE_TYPE input_value_type = 20;
     */
    inputValueType = GOAL_ITEM_INPUT_VALUE_TYPE.GOAL_ITEM_INPUT_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The minimum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_min_value = 21;
     */
    numberMinValue = protobuf_1.protoInt64.zero;
    /**
     * The maximum value (in cents) in case the input value type is number-absolute or number-percentage
     *
     * @generated from field: uint64 number_max_value = 22;
     */
    numberMaxValue = protobuf_1.protoInt64.zero;
    /**
     * The acceptable value of the item in case input_value_type is of number type
     *
     * @generated from field: uint64 number_acceptable_value = 23;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The possible values from which a user can choose in case the input type is text-dropdown
     *
     * @generated from field: repeated string text_values = 30;
     */
    textValues = [];
    /**
     * The acceptable value of the item in case input_value_type is of text type
     *
     * @generated from field: string text_acceptable_value = 31;
     */
    textAcceptableValue = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "input_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_ITEM_INPUT_VALUE_TYPE) },
        { no: 21, name: "number_min_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "number_max_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "number_acceptable_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "text_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalItem, a, b);
    }
}
exports.GoalItem = GoalItem;
/**
 *
 * Describes the message consisting of the list of goals
 *
 * @generated from message Scailo.GoalsList
 */
class GoalsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Goal list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Goal, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsList, a, b);
    }
}
exports.GoalsList = GoalsList;
/**
 *
 * Describes the message consisting of the list of goal items
 *
 * @generated from message Scailo.GoalsItemsList
 */
class GoalsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GoalItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GoalItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsItemsList, a, b);
    }
}
exports.GoalsItemsList = GoalsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.GoalItemHistoryRequest
 */
class GoalItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the goal ID
     *
     * @generated from field: uint64 goal_id = 10;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalItemHistoryRequest, a, b);
    }
}
exports.GoalItemHistoryRequest = GoalItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.GoalItemsSearchRequest
 */
class GoalItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOAL_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = GOAL_ITEM_SORT_KEY.GOAL_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.GOAL_ITEM_STATUS status = 7;
     */
    status = GOAL_ITEM_STATUS.GOAL_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the goal ID
     *
     * @generated from field: uint64 goal_id = 20;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional label
     *
     * @generated from field: uint64 label_id = 21;
     */
    labelId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalItemsSearchRequest, a, b);
    }
}
exports.GoalItemsSearchRequest = GoalItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.GoalsServicePaginatedItemsResponse
 */
class GoalsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GoalItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GoalItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServicePaginatedItemsResponse, a, b);
    }
}
exports.GoalsServicePaginatedItemsResponse = GoalsServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.GoalsServicePaginationReq
 */
class GoalsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOAL_SORT_KEY sort_key = 5;
     */
    sortKey = GOAL_SORT_KEY.GOAL_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this goal
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServicePaginationReq, a, b);
    }
}
exports.GoalsServicePaginationReq = GoalsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.GoalsServicePaginationResponse
 */
class GoalsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Goal payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Goal, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServicePaginationResponse, a, b);
    }
}
exports.GoalsServicePaginationResponse = GoalsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.GoalsServiceFilterReq
 */
class GoalsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOAL_SORT_KEY sort_key = 5;
     */
    sortKey = GOAL_SORT_KEY.GOAL_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goal
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goal
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated user ID
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The min start date of the plan (in string)
     *
     * @generated from field: string start_date_start = 30;
     */
    startDateStart = "";
    /**
     * The max start date of the plan (in string)
     *
     * @generated from field: string start_date_end = 31;
     */
    startDateEnd = "";
    /**
     * The exact start date of the plan (in string)
     *
     * @generated from field: string start_date_exact = 32;
     */
    startDateExact = "";
    /**
     * The min end date of the plan (in string)
     *
     * @generated from field: string end_date_start = 36;
     */
    endDateStart = "";
    /**
     * The max end date of the plan (in string)
     *
     * @generated from field: string end_date_end = 37;
     */
    endDateEnd = "";
    /**
     * The exact end date of the plan (in string)
     *
     * @generated from field: string end_date_exact = 38;
     */
    endDateExact = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "start_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "start_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "start_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "end_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "end_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 38, name: "end_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceFilterReq, a, b);
    }
}
exports.GoalsServiceFilterReq = GoalsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.GoalsServiceCountReq
 */
class GoalsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goal
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goal
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated user ID
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The min start date of the plan (in string)
     *
     * @generated from field: string start_date_start = 30;
     */
    startDateStart = "";
    /**
     * The max start date of the plan (in string)
     *
     * @generated from field: string start_date_end = 31;
     */
    startDateEnd = "";
    /**
     * The exact start date of the plan (in string)
     *
     * @generated from field: string start_date_exact = 32;
     */
    startDateExact = "";
    /**
     * The min end date of the plan (in string)
     *
     * @generated from field: string end_date_start = 36;
     */
    endDateStart = "";
    /**
     * The max end date of the plan (in string)
     *
     * @generated from field: string end_date_end = 37;
     */
    endDateEnd = "";
    /**
     * The exact end date of the plan (in string)
     *
     * @generated from field: string end_date_exact = 38;
     */
    endDateExact = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "start_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "start_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "start_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 36, name: "end_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 37, name: "end_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 38, name: "end_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceCountReq, a, b);
    }
}
exports.GoalsServiceCountReq = GoalsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.GoalsServiceSearchAllReq
 */
class GoalsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOAL_SORT_KEY sort_key = 5;
     */
    sortKey = GOAL_SORT_KEY.GOAL_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated user ID
     *
     * @generated from field: uint64 user_id = 22;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoalsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOAL_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoalsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoalsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoalsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoalsServiceSearchAllReq, a, b);
    }
}
exports.GoalsServiceSearchAllReq = GoalsServiceSearchAllReq;
