"use strict";
// addLeaveAdjustmentRecord
// modifyLeaveAdjustmentRecord
// reorderLeaveAdjustmentRecords
// deleteLeaveAdjustmentRecord
// approveLeaveAdjustmentRecord
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeavesAdjustmentsService = void 0;
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file leaves_adjustments.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
const leaves_adjustments_scailo_pb_js_1 = require("./leaves_adjustments.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each record
 *
 * @generated from service Scailo.LeavesAdjustmentsService
 */
exports.LeavesAdjustmentsService = {
    typeName: "Scailo.LeavesAdjustmentsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Create
         */
        create: {
            name: "Create",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Draft
         */
        draft: {
            name: "Draft",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (Identifier);
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a record to a record
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.AddLeaveAdjustmentRecord
         */
        addLeaveAdjustmentRecord: {
            name: "AddLeaveAdjustmentRecord",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceRecordCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a record in a record
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ModifyLeaveAdjustmentRecord
         */
        modifyLeaveAdjustmentRecord: {
            name: "ModifyLeaveAdjustmentRecord",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceRecordUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a record in a record
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ApproveLeaveAdjustmentRecord
         */
        approveLeaveAdjustmentRecord: {
            name: "ApproveLeaveAdjustmentRecord",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a record in a record
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.DeleteLeaveAdjustmentRecord
         */
        deleteLeaveAdjustmentRecord: {
            name: "DeleteLeaveAdjustmentRecord",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder records
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ReorderLeaveAdjustmentRecords
         */
        reorderLeaveAdjustmentRecords: {
            name: "ReorderLeaveAdjustmentRecords",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Leave Adjustment Record by ID
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewLeaveAdjustmentRecordByID
         */
        viewLeaveAdjustmentRecordByID: {
            name: "ViewLeaveAdjustmentRecordByID",
            I: base_scailo_pb_js_1.Identifier,
            O: leaves_adjustments_scailo_pb_js_1.LeaveAdjustmentRecord,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved leave adjustment records for given record ID
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewApprovedLeaveAdjustmentRecords
         */
        viewApprovedLeaveAdjustmentRecords: {
            name: "ViewApprovedLeaveAdjustmentRecords",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsRecordsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved leave adjustment records for given record ID
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewUnapprovedLeaveAdjustmentRecords
         */
        viewUnapprovedLeaveAdjustmentRecords: {
            name: "ViewUnapprovedLeaveAdjustmentRecords",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsRecordsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the record
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewLeaveAdjustmentRecordHistory
         */
        viewLeaveAdjustmentRecordHistory: {
            name: "ViewLeaveAdjustmentRecordHistory",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsRecordsHistoryRequest,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsRecordsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved leave adjustment records for given leave adjustment ID with pagination
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewPaginatedApprovedLeaveAdjustmentRecords
         */
        viewPaginatedApprovedLeaveAdjustmentRecords: {
            name: "ViewPaginatedApprovedLeaveAdjustmentRecords",
            I: leaves_adjustments_scailo_pb_js_1.LeaveAdjustmentRecordsSearchRequest,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServicePaginatedRecordsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved leave adjustment records for given leave adjustment ID with pagination
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewPaginatedUnapprovedLeaveAdjustmentRecords
         */
        viewPaginatedUnapprovedLeaveAdjustmentRecords: {
            name: "ViewPaginatedUnapprovedLeaveAdjustmentRecords",
            I: leaves_adjustments_scailo_pb_js_1.LeaveAdjustmentRecordsSearchRequest,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServicePaginatedRecordsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through leave adjustment records with pagination
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.SearchRecordsWithPagination
         */
        searchRecordsWithPagination: {
            name: "SearchRecordsWithPagination",
            I: leaves_adjustments_scailo_pb_js_1.LeaveAdjustmentRecordsSearchRequest,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServicePaginatedRecordsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: leaves_adjustments_scailo_pb_js_1.LeaveAdjustment,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServicePaginationReq,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceSearchAllReq,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Filter
         */
        filter: {
            name: "Filter",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceFilterReq,
            O: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.LeavesAdjustmentsService.Count
         */
        count: {
            name: "Count",
            I: leaves_adjustments_scailo_pb_js_1.LeavesAdjustmentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
