"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file base.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloneRequest = exports.ReorderItemsRequest = exports.LogbookLogComplete = exports.LogbookLogConciseGenericCreateRequest = exports.LogbookLogConciseSLCCreateRequest = exports.LogbookLogConciseSLC = exports.IdentifierUUIDsList = exports.IdentifierUUID = exports.IdentifierWithFile = exports.IdentifierWithSearchKey = exports.IdentifierWithEmailAttributes = exports.IdentifierWithUserComment = exports.StringsList = exports.IdentifiersList = exports.IdentifierWithSearch = exports.IdentifierZeroable = exports.Identifier = exports.RepeatWithDeliveryDate = exports.SimpleSearchReq = exports.UploadPictureReq = exports.UpdateOwnPasswordReq = exports.UpdatePasswordReq = exports.ActiveStatus = exports.ApprovalMetadata = exports.EmployeeMetadata = exports.DualQuantitiesResponse = exports.QuantityResponse = exports.SumResponse = exports.CountResponse = exports.CountInSLCStatusRequest = exports.GPSCoordinatesResponse = exports.MonthAndDayFilter = exports.Base64String = exports.ImageResponse = exports.PriceResponse = exports.StringResponse = exports.BytesResponse = exports.BooleanResponse = exports.Empty = exports.AMENDMENT_LOG_REF_FOR = exports.INVENTORY_ISSUED_PURPOSE = exports.INVENTORY_INTERACTION_CATEGORY = exports.INVENTORY_SORT_KEY = exports.INVENTORY_LIFECYCLE = exports.BOOL_FILTER = exports.LOGBOOK_OPERATION = exports.FORM_FIELD_ELEMENT = exports.FORM_TYPE = exports.STANDARD_LIFECYCLE_STATUS = exports.SORT_ORDER = void 0;
exports.AmendmentLogsList = exports.AmendmentLog = exports.InventoryInteractionsList = exports.InventoryInteraction = exports.InventoryPartitionRequest = exports.LogbookLogInventoryLC = exports.StandardFile = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the order in which the records need to be returned
 *
 * @generated from enum Scailo.SORT_ORDER
 */
var SORT_ORDER;
(function (SORT_ORDER) {
    /**
     * Fetch results in the ascending order of the provided sort key
     *
     * @generated from enum value: ASCENDING_UNSPECIFIED = 0;
     */
    SORT_ORDER[SORT_ORDER["ASCENDING_UNSPECIFIED"] = 0] = "ASCENDING_UNSPECIFIED";
    /**
     * Fetch results in the descending order of the provided sort key
     *
     * @generated from enum value: DESCENDING = 1;
     */
    SORT_ORDER[SORT_ORDER["DESCENDING"] = 1] = "DESCENDING";
})(SORT_ORDER || (exports.SORT_ORDER = SORT_ORDER = {}));
// Retrieve enum metadata with: proto3.getEnumType(SORT_ORDER)
protobuf_1.proto3.util.setEnumType(SORT_ORDER, "Scailo.SORT_ORDER", [
    { no: 0, name: "ASCENDING_UNSPECIFIED" },
    { no: 1, name: "DESCENDING" },
]);
/**
 *
 * Describes the standard lifecycle status of each record
 *
 * @generated from enum Scailo.STANDARD_LIFECYCLE_STATUS
 */
var STANDARD_LIFECYCLE_STATUS;
(function (STANDARD_LIFECYCLE_STATUS) {
    /**
     * Use this only in filter and search queries so as to retrieve all the records regardless of the status that they are in
     *
     * @generated from enum value: ANY_UNSPECIFIED = 0;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["ANY_UNSPECIFIED"] = 0] = "ANY_UNSPECIFIED";
    /**
     * The resource has just been created, and has been sent for verification
     *
     * @generated from enum value: PREVERIFY = 1;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["PREVERIFY"] = 1] = "PREVERIFY";
    /**
     * The resource has been saved as a draft
     *
     * @generated from enum value: DRAFT = 2;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["DRAFT"] = 2] = "DRAFT";
    /**
     * The resource has been verified
     *
     * @generated from enum value: VERIFIED = 3;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["VERIFIED"] = 3] = "VERIFIED";
    /**
     * The resource has been approved
     *
     * @generated from enum value: STANDING = 4;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["STANDING"] = 4] = "STANDING";
    /**
     * The resource has been sent for revision
     *
     * @generated from enum value: REVISION = 5;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["REVISION"] = 5] = "REVISION";
    /**
     * The resource has been halted
     *
     * @generated from enum value: HALTED = 6;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["HALTED"] = 6] = "HALTED";
    /**
     * The resource has been marked as completed
     *
     * @generated from enum value: COMPLETED = 7;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["COMPLETED"] = 7] = "COMPLETED";
    /**
     * The resource has been discarded
     *
     * @generated from enum value: DISCARDED = 8;
     */
    STANDARD_LIFECYCLE_STATUS[STANDARD_LIFECYCLE_STATUS["DISCARDED"] = 8] = "DISCARDED";
})(STANDARD_LIFECYCLE_STATUS || (exports.STANDARD_LIFECYCLE_STATUS = STANDARD_LIFECYCLE_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(STANDARD_LIFECYCLE_STATUS)
protobuf_1.proto3.util.setEnumType(STANDARD_LIFECYCLE_STATUS, "Scailo.STANDARD_LIFECYCLE_STATUS", [
    { no: 0, name: "ANY_UNSPECIFIED" },
    { no: 1, name: "PREVERIFY" },
    { no: 2, name: "DRAFT" },
    { no: 3, name: "VERIFIED" },
    { no: 4, name: "STANDING" },
    { no: 5, name: "REVISION" },
    { no: 6, name: "HALTED" },
    { no: 7, name: "COMPLETED" },
    { no: 8, name: "DISCARDED" },
]);
/**
 *
 * Describes the available form types
 *
 * @generated from enum Scailo.FORM_TYPE
 */
var FORM_TYPE;
(function (FORM_TYPE) {
    /**
     * Use this only in filter and search queries so as to retrieve all the records regardless of the form type that they are in
     *
     * @generated from enum value: FORM_TYPE_ANY_UNSPECIFIED = 0;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ANY_UNSPECIFIED"] = 0] = "FORM_TYPE_ANY_UNSPECIFIED";
    /**
     * The form type is "sales-enquiry-form"
     *
     * @generated from enum value: FORM_TYPE_SALES_ENQUIRY_FORM = 10;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALES_ENQUIRY_FORM"] = 10] = "FORM_TYPE_SALES_ENQUIRY_FORM";
    /**
     * The form type is "purchase-enquiry-form"
     *
     * @generated from enum value: FORM_TYPE_PURCHASE_ENQUIRY_FORM = 20;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PURCHASE_ENQUIRY_FORM"] = 20] = "FORM_TYPE_PURCHASE_ENQUIRY_FORM";
    /**
     * The form type is "project-form"
     *
     * @generated from enum value: FORM_TYPE_PROJECT_FORM = 30;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PROJECT_FORM"] = 30] = "FORM_TYPE_PROJECT_FORM";
    /**
     * The form type is "sales-order-form"
     *
     * @generated from enum value: FORM_TYPE_SALES_ORDER_FORM = 40;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALES_ORDER_FORM"] = 40] = "FORM_TYPE_SALES_ORDER_FORM";
    /**
     * The form type is "sales-quotation-form"
     *
     * @generated from enum value: FORM_TYPE_SALES_QUOTATION_FORM = 50;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALES_QUOTATION_FORM"] = 50] = "FORM_TYPE_SALES_QUOTATION_FORM";
    /**
     * The form type is "client-form"
     *
     * @generated from enum value: FORM_TYPE_CLIENT_FORM = 60;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_CLIENT_FORM"] = 60] = "FORM_TYPE_CLIENT_FORM";
    /**
     * The form type is "user-form"
     *
     * @generated from enum value: FORM_TYPE_USER_FORM = 70;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_USER_FORM"] = 70] = "FORM_TYPE_USER_FORM";
    /**
     * The form type is "purchase-order-form"
     *
     * @generated from enum value: FORM_TYPE_PURCHASE_ORDER_FORM = 80;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PURCHASE_ORDER_FORM"] = 80] = "FORM_TYPE_PURCHASE_ORDER_FORM";
    /**
     * The form type is "purchase-indent-form"
     *
     * @generated from enum value: FORM_TYPE_PURCHASE_INDENT_FORM = 90;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PURCHASE_INDENT_FORM"] = 90] = "FORM_TYPE_PURCHASE_INDENT_FORM";
    /**
     * The form type is "sales-invoice-form"
     *
     * @generated from enum value: FORM_TYPE_SALES_INVOICE_FORM = 100;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALES_INVOICE_FORM"] = 100] = "FORM_TYPE_SALES_INVOICE_FORM";
    /**
     * The form type is "goods-dispatch-form"
     *
     * @generated from enum value: FORM_TYPE_GOODS_DISPATCH_FORM = 110;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_GOODS_DISPATCH_FORM"] = 110] = "FORM_TYPE_GOODS_DISPATCH_FORM";
    /**
     * The form type is "vendor-invoice-form"
     *
     * @generated from enum value: FORM_TYPE_VENDOR_INVOICE_FORM = 120;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_VENDOR_INVOICE_FORM"] = 120] = "FORM_TYPE_VENDOR_INVOICE_FORM";
    /**
     * The form type is "goods-receipt-form"
     *
     * @generated from enum value: FORM_TYPE_GOODS_RECEIPT_FORM = 130;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_GOODS_RECEIPT_FORM"] = 130] = "FORM_TYPE_GOODS_RECEIPT_FORM";
    /**
     * The form type is "work-order-form"
     *
     * @generated from enum value: FORM_TYPE_WORK_ORDER_FORM = 140;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_WORK_ORDER_FORM"] = 140] = "FORM_TYPE_WORK_ORDER_FORM";
    /**
     * The form type is "vendor-form"
     *
     * @generated from enum value: FORM_TYPE_VENDOR_FORM = 150;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_VENDOR_FORM"] = 150] = "FORM_TYPE_VENDOR_FORM";
    /**
     * The form type is "stock-issuance-form"
     *
     * @generated from enum value: FORM_TYPE_STOCK_ISSUANCE_FORM = 160;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_STOCK_ISSUANCE_FORM"] = 160] = "FORM_TYPE_STOCK_ISSUANCE_FORM";
    /**
     * The form type is "stock-audit-form"
     *
     * @generated from enum value: FORM_TYPE_STOCK_AUDIT_FORM = 170;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_STOCK_AUDIT_FORM"] = 170] = "FORM_TYPE_STOCK_AUDIT_FORM";
    /**
     * The form type is "stock-return-form"
     *
     * @generated from enum value: FORM_TYPE_STOCK_RETURN_FORM = 180;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_STOCK_RETURN_FORM"] = 180] = "FORM_TYPE_STOCK_RETURN_FORM";
    /**
     * The form type is "expense-form"
     *
     * @generated from enum value: FORM_TYPE_EXPENSE_FORM = 190;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_EXPENSE_FORM"] = 190] = "FORM_TYPE_EXPENSE_FORM";
    /**
     * The form type is "location-form"
     *
     * @generated from enum value: FORM_TYPE_LOCATION_FORM = 200;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_LOCATION_FORM"] = 200] = "FORM_TYPE_LOCATION_FORM";
    /**
     * The form type is "bank-account-form"
     *
     * @generated from enum value: FORM_TYPE_BANK_ACCOUNT_FORM = 210;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_BANK_ACCOUNT_FORM"] = 210] = "FORM_TYPE_BANK_ACCOUNT_FORM";
    /**
     * The form type is "qc-group-form"
     *
     * @generated from enum value: FORM_TYPE_QC_GROUP_FORM = 220;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_QC_GROUP_FORM"] = 220] = "FORM_TYPE_QC_GROUP_FORM";
    /**
     * The form type is "quotation-request-form"
     *
     * @generated from enum value: FORM_TYPE_QUOTATION_REQUEST_FORM = 230;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_QUOTATION_REQUEST_FORM"] = 230] = "FORM_TYPE_QUOTATION_REQUEST_FORM";
    /**
     * The form type is "quotation-response-form"
     *
     * @generated from enum value: FORM_TYPE_QUOTATION_RESPONSE_FORM = 240;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_QUOTATION_RESPONSE_FORM"] = 240] = "FORM_TYPE_QUOTATION_RESPONSE_FORM";
    /**
     * The form type is "production-plan-form"
     *
     * @generated from enum value: FORM_TYPE_PRODUCTION_PLAN_FORM = 250;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PRODUCTION_PLAN_FORM"] = 250] = "FORM_TYPE_PRODUCTION_PLAN_FORM";
    /**
     * The form type is "production-indent-form"
     *
     * @generated from enum value: FORM_TYPE_PRODUCTION_INDENT_FORM = 260;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PRODUCTION_INDENT_FORM"] = 260] = "FORM_TYPE_PRODUCTION_INDENT_FORM";
    /**
     * The form type is "replaceable-indent-form"
     *
     * @generated from enum value: FORM_TYPE_REPLACEABLE_INDENT_FORM = 270;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_REPLACEABLE_INDENT_FORM"] = 270] = "FORM_TYPE_REPLACEABLE_INDENT_FORM";
    /**
     * The form type is "asset-indent-form"
     *
     * @generated from enum value: FORM_TYPE_ASSET_INDENT_FORM = 280;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ASSET_INDENT_FORM"] = 280] = "FORM_TYPE_ASSET_INDENT_FORM";
    /**
     * The form type is "purchase-return-form"
     *
     * @generated from enum value: FORM_TYPE_PURCHASE_RETURN_FORM = 290;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_PURCHASE_RETURN_FORM"] = 290] = "FORM_TYPE_PURCHASE_RETURN_FORM";
    /**
     * The form type is "sales-return-form"
     *
     * @generated from enum value: FORM_TYPE_SALES_RETURN_FORM = 300;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALES_RETURN_FORM"] = 300] = "FORM_TYPE_SALES_RETURN_FORM";
    /**
     * The form type is "debit-note-form"
     *
     * @generated from enum value: FORM_TYPE_DEBIT_NOTE_FORM = 310;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_DEBIT_NOTE_FORM"] = 310] = "FORM_TYPE_DEBIT_NOTE_FORM";
    /**
     * The form type is "credit-note-form"
     *
     * @generated from enum value: FORM_TYPE_CREDIT_NOTE_FORM = 320;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_CREDIT_NOTE_FORM"] = 320] = "FORM_TYPE_CREDIT_NOTE_FORM";
    /**
     * The form type is "inward-job-form"
     *
     * @generated from enum value: FORM_TYPE_INWARD_JOB_FORM = 330;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_INWARD_JOB_FORM"] = 330] = "FORM_TYPE_INWARD_JOB_FORM";
    /**
     * The form type is "inward-job-free-issue-material-form"
     *
     * @generated from enum value: FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_FORM = 340;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_FORM"] = 340] = "FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_FORM";
    /**
     * The form type is "inward-job-free-issue-material-return-form"
     *
     * @generated from enum value: FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM = 350;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM"] = 350] = "FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM";
    /**
     * The form type is "outward-job-form"
     *
     * @generated from enum value: FORM_TYPE_OUTWARD_JOB_FORM = 360;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_OUTWARD_JOB_FORM"] = 360] = "FORM_TYPE_OUTWARD_JOB_FORM";
    /**
     * The form type is "outward-job-free-issue-material-form"
     *
     * @generated from enum value: FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_FORM = 370;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_FORM"] = 370] = "FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_FORM";
    /**
     * The form type is "outward-job-free-issue-material-return-form"
     *
     * @generated from enum value: FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM = 380;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM"] = 380] = "FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM";
    /**
     * The form type is "leave-request-form"
     *
     * @generated from enum value: FORM_TYPE_LEAVE_REQUEST_FORM = 390;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_LEAVE_REQUEST_FORM"] = 390] = "FORM_TYPE_LEAVE_REQUEST_FORM";
    /**
     * The form type is "overtime-form"
     *
     * @generated from enum value: FORM_TYPE_OVERTIME_FORM = 400;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_OVERTIME_FORM"] = 400] = "FORM_TYPE_OVERTIME_FORM";
    /**
     * The form type is "attendance-form"
     *
     * @generated from enum value: FORM_TYPE_ATTENDANCE_FORM = 410;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ATTENDANCE_FORM"] = 410] = "FORM_TYPE_ATTENDANCE_FORM";
    /**
     * The form type is "absence-form"
     *
     * @generated from enum value: FORM_TYPE_ABSENCE_FORM = 420;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ABSENCE_FORM"] = 420] = "FORM_TYPE_ABSENCE_FORM";
    /**
     * The form type is "onduty-form"
     *
     * @generated from enum value: FORM_TYPE_ONDUTY_FORM = 430;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ONDUTY_FORM"] = 430] = "FORM_TYPE_ONDUTY_FORM";
    /**
     * The form type is "attendanceamendment-form"
     *
     * @generated from enum value: FORM_TYPE_ATTENDANCE_AMENDMENT_FORM = 440;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_ATTENDANCE_AMENDMENT_FORM"] = 440] = "FORM_TYPE_ATTENDANCE_AMENDMENT_FORM";
    /**
     * The form type is "visitation-form"
     *
     * @generated from enum value: FORM_TYPE_VISITATION_FORM = 450;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_VISITATION_FORM"] = 450] = "FORM_TYPE_VISITATION_FORM";
    /**
     * The form type is "salary-form"
     *
     * @generated from enum value: FORM_TYPE_SALARY_FORM = 490;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SALARY_FORM"] = 490] = "FORM_TYPE_SALARY_FORM";
    /**
     * The form type is "skill-param-form"
     *
     * @generated from enum value: FORM_TYPE_SKILL_PARAM_FORM = 500;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SKILL_PARAM_FORM"] = 500] = "FORM_TYPE_SKILL_PARAM_FORM";
    /**
     * The form type is "skill-group-form"
     *
     * @generated from enum value: FORM_TYPE_SKILL_GROUP_FORM = 510;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_SKILL_GROUP_FORM"] = 510] = "FORM_TYPE_SKILL_GROUP_FORM";
    /**
     * The form type is "goal-form"
     *
     * @generated from enum value: FORM_TYPE_GOAL_FORM = 520;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_GOAL_FORM"] = 520] = "FORM_TYPE_GOAL_FORM";
    /**
     * The form type is "meeting-form"
     *
     * @generated from enum value: FORM_TYPE_MEETING_FORM = 530;
     */
    FORM_TYPE[FORM_TYPE["FORM_TYPE_MEETING_FORM"] = 530] = "FORM_TYPE_MEETING_FORM";
})(FORM_TYPE || (exports.FORM_TYPE = FORM_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(FORM_TYPE)
protobuf_1.proto3.util.setEnumType(FORM_TYPE, "Scailo.FORM_TYPE", [
    { no: 0, name: "FORM_TYPE_ANY_UNSPECIFIED" },
    { no: 10, name: "FORM_TYPE_SALES_ENQUIRY_FORM" },
    { no: 20, name: "FORM_TYPE_PURCHASE_ENQUIRY_FORM" },
    { no: 30, name: "FORM_TYPE_PROJECT_FORM" },
    { no: 40, name: "FORM_TYPE_SALES_ORDER_FORM" },
    { no: 50, name: "FORM_TYPE_SALES_QUOTATION_FORM" },
    { no: 60, name: "FORM_TYPE_CLIENT_FORM" },
    { no: 70, name: "FORM_TYPE_USER_FORM" },
    { no: 80, name: "FORM_TYPE_PURCHASE_ORDER_FORM" },
    { no: 90, name: "FORM_TYPE_PURCHASE_INDENT_FORM" },
    { no: 100, name: "FORM_TYPE_SALES_INVOICE_FORM" },
    { no: 110, name: "FORM_TYPE_GOODS_DISPATCH_FORM" },
    { no: 120, name: "FORM_TYPE_VENDOR_INVOICE_FORM" },
    { no: 130, name: "FORM_TYPE_GOODS_RECEIPT_FORM" },
    { no: 140, name: "FORM_TYPE_WORK_ORDER_FORM" },
    { no: 150, name: "FORM_TYPE_VENDOR_FORM" },
    { no: 160, name: "FORM_TYPE_STOCK_ISSUANCE_FORM" },
    { no: 170, name: "FORM_TYPE_STOCK_AUDIT_FORM" },
    { no: 180, name: "FORM_TYPE_STOCK_RETURN_FORM" },
    { no: 190, name: "FORM_TYPE_EXPENSE_FORM" },
    { no: 200, name: "FORM_TYPE_LOCATION_FORM" },
    { no: 210, name: "FORM_TYPE_BANK_ACCOUNT_FORM" },
    { no: 220, name: "FORM_TYPE_QC_GROUP_FORM" },
    { no: 230, name: "FORM_TYPE_QUOTATION_REQUEST_FORM" },
    { no: 240, name: "FORM_TYPE_QUOTATION_RESPONSE_FORM" },
    { no: 250, name: "FORM_TYPE_PRODUCTION_PLAN_FORM" },
    { no: 260, name: "FORM_TYPE_PRODUCTION_INDENT_FORM" },
    { no: 270, name: "FORM_TYPE_REPLACEABLE_INDENT_FORM" },
    { no: 280, name: "FORM_TYPE_ASSET_INDENT_FORM" },
    { no: 290, name: "FORM_TYPE_PURCHASE_RETURN_FORM" },
    { no: 300, name: "FORM_TYPE_SALES_RETURN_FORM" },
    { no: 310, name: "FORM_TYPE_DEBIT_NOTE_FORM" },
    { no: 320, name: "FORM_TYPE_CREDIT_NOTE_FORM" },
    { no: 330, name: "FORM_TYPE_INWARD_JOB_FORM" },
    { no: 340, name: "FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_FORM" },
    { no: 350, name: "FORM_TYPE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM" },
    { no: 360, name: "FORM_TYPE_OUTWARD_JOB_FORM" },
    { no: 370, name: "FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_FORM" },
    { no: 380, name: "FORM_TYPE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_FORM" },
    { no: 390, name: "FORM_TYPE_LEAVE_REQUEST_FORM" },
    { no: 400, name: "FORM_TYPE_OVERTIME_FORM" },
    { no: 410, name: "FORM_TYPE_ATTENDANCE_FORM" },
    { no: 420, name: "FORM_TYPE_ABSENCE_FORM" },
    { no: 430, name: "FORM_TYPE_ONDUTY_FORM" },
    { no: 440, name: "FORM_TYPE_ATTENDANCE_AMENDMENT_FORM" },
    { no: 450, name: "FORM_TYPE_VISITATION_FORM" },
    { no: 490, name: "FORM_TYPE_SALARY_FORM" },
    { no: 500, name: "FORM_TYPE_SKILL_PARAM_FORM" },
    { no: 510, name: "FORM_TYPE_SKILL_GROUP_FORM" },
    { no: 520, name: "FORM_TYPE_GOAL_FORM" },
    { no: 530, name: "FORM_TYPE_MEETING_FORM" },
]);
/**
 *
 * Describes the available form field elements
 *
 * @generated from enum Scailo.FORM_FIELD_ELEMENT
 */
var FORM_FIELD_ELEMENT;
(function (FORM_FIELD_ELEMENT) {
    /**
     * Use this only in filter and search queries so as to retrieve all the records regardless of the form type that they are in
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_ANY_UNSPECIFIED = 0;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_ANY_UNSPECIFIED"] = 0] = "FORM_FIELD_ELEMENT_ANY_UNSPECIFIED";
    /**
     * The element is an input
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_INPUT = 10;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_INPUT"] = 10] = "FORM_FIELD_ELEMENT_INPUT";
    /**
     * The element is a radio button
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_RADIO = 20;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_RADIO"] = 20] = "FORM_FIELD_ELEMENT_RADIO";
    /**
     * The element is a checkbox
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_CHECKBOX = 30;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_CHECKBOX"] = 30] = "FORM_FIELD_ELEMENT_CHECKBOX";
    /**
     * The element is a dropdown
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_SELECT = 40;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_SELECT"] = 40] = "FORM_FIELD_ELEMENT_SELECT";
    /**
     * The element is a multiline textarea
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_TEXTAREA = 50;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_TEXTAREA"] = 50] = "FORM_FIELD_ELEMENT_TEXTAREA";
    /**
     * The element is a date input
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_DATE = 60;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_DATE"] = 60] = "FORM_FIELD_ELEMENT_DATE";
    /**
     * The element is an email address input
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_EMAIL = 70;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_EMAIL"] = 70] = "FORM_FIELD_ELEMENT_EMAIL";
    /**
     * The element is a phone number input
     *
     * @generated from enum value: FORM_FIELD_ELEMENT_PHONE = 80;
     */
    FORM_FIELD_ELEMENT[FORM_FIELD_ELEMENT["FORM_FIELD_ELEMENT_PHONE"] = 80] = "FORM_FIELD_ELEMENT_PHONE";
})(FORM_FIELD_ELEMENT || (exports.FORM_FIELD_ELEMENT = FORM_FIELD_ELEMENT = {}));
// Retrieve enum metadata with: proto3.getEnumType(FORM_FIELD_ELEMENT)
protobuf_1.proto3.util.setEnumType(FORM_FIELD_ELEMENT, "Scailo.FORM_FIELD_ELEMENT", [
    { no: 0, name: "FORM_FIELD_ELEMENT_ANY_UNSPECIFIED" },
    { no: 10, name: "FORM_FIELD_ELEMENT_INPUT" },
    { no: 20, name: "FORM_FIELD_ELEMENT_RADIO" },
    { no: 30, name: "FORM_FIELD_ELEMENT_CHECKBOX" },
    { no: 40, name: "FORM_FIELD_ELEMENT_SELECT" },
    { no: 50, name: "FORM_FIELD_ELEMENT_TEXTAREA" },
    { no: 60, name: "FORM_FIELD_ELEMENT_DATE" },
    { no: 70, name: "FORM_FIELD_ELEMENT_EMAIL" },
    { no: 80, name: "FORM_FIELD_ELEMENT_PHONE" },
]);
/**
 *
 * Describes all the possible values within a logbook operation
 *
 * @generated from enum Scailo.LOGBOOK_OPERATION
 */
var LOGBOOK_OPERATION;
(function (LOGBOOK_OPERATION) {
    /**
     * Create a resource
     *
     * @generated from enum value: CREATE_UNSPECIFIED = 0;
     */
    LOGBOOK_OPERATION[LOGBOOK_OPERATION["CREATE_UNSPECIFIED"] = 0] = "CREATE_UNSPECIFIED";
    /**
     * Update a resource
     *
     * @generated from enum value: UPDATE = 1;
     */
    LOGBOOK_OPERATION[LOGBOOK_OPERATION["UPDATE"] = 1] = "UPDATE";
    /**
     * Archive a resource
     *
     * @generated from enum value: ARCHIVE = 2;
     */
    LOGBOOK_OPERATION[LOGBOOK_OPERATION["ARCHIVE"] = 2] = "ARCHIVE";
    /**
     * Restore a resource
     *
     * @generated from enum value: RESTORE = 3;
     */
    LOGBOOK_OPERATION[LOGBOOK_OPERATION["RESTORE"] = 3] = "RESTORE";
})(LOGBOOK_OPERATION || (exports.LOGBOOK_OPERATION = LOGBOOK_OPERATION = {}));
// Retrieve enum metadata with: proto3.getEnumType(LOGBOOK_OPERATION)
protobuf_1.proto3.util.setEnumType(LOGBOOK_OPERATION, "Scailo.LOGBOOK_OPERATION", [
    { no: 0, name: "CREATE_UNSPECIFIED" },
    { no: 1, name: "UPDATE" },
    { no: 2, name: "ARCHIVE" },
    { no: 3, name: "RESTORE" },
]);
/**
 *
 * Describes the filter applicable on boolean properties (true, false, or any)
 *
 * @generated from enum Scailo.BOOL_FILTER
 */
var BOOL_FILTER;
(function (BOOL_FILTER) {
    /**
     * Disregard the property
     *
     * @generated from enum value: BOOL_FILTER_ANY_UNSPECIFIED = 0;
     */
    BOOL_FILTER[BOOL_FILTER["BOOL_FILTER_ANY_UNSPECIFIED"] = 0] = "BOOL_FILTER_ANY_UNSPECIFIED";
    /**
     * Filter records that have the property set to true
     *
     * @generated from enum value: BOOL_FILTER_TRUE = 1;
     */
    BOOL_FILTER[BOOL_FILTER["BOOL_FILTER_TRUE"] = 1] = "BOOL_FILTER_TRUE";
    /**
     * Filter records that have the property set to false
     *
     * @generated from enum value: BOOL_FILTER_FALSE = 2;
     */
    BOOL_FILTER[BOOL_FILTER["BOOL_FILTER_FALSE"] = 2] = "BOOL_FILTER_FALSE";
})(BOOL_FILTER || (exports.BOOL_FILTER = BOOL_FILTER = {}));
// Retrieve enum metadata with: proto3.getEnumType(BOOL_FILTER)
protobuf_1.proto3.util.setEnumType(BOOL_FILTER, "Scailo.BOOL_FILTER", [
    { no: 0, name: "BOOL_FILTER_ANY_UNSPECIFIED" },
    { no: 1, name: "BOOL_FILTER_TRUE" },
    { no: 2, name: "BOOL_FILTER_FALSE" },
]);
/**
 *
 * Describes the lifecycle status of each inventory
 *
 * @generated from enum Scailo.INVENTORY_LIFECYCLE
 */
var INVENTORY_LIFECYCLE;
(function (INVENTORY_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "INVENTORY_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the inventory is in QC
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_QC = 1;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_QC"] = 1] = "INVENTORY_LIFECYCLE_QC";
    /**
     * Denotes that the inventory is in store
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_STORE = 2;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_STORE"] = 2] = "INVENTORY_LIFECYCLE_STORE";
    /**
     * Denotes that the inventory is in reqork
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_REWORK = 3;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_REWORK"] = 3] = "INVENTORY_LIFECYCLE_REWORK";
    /**
     * Denotes that the inventory has been consumed
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_CONSUMED = 4;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_CONSUMED"] = 4] = "INVENTORY_LIFECYCLE_CONSUMED";
    /**
     * Denotes that the inventory has been rejected
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_REJECTED = 5;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_REJECTED"] = 5] = "INVENTORY_LIFECYCLE_REJECTED";
    /**
     * Denotes that the inventory has been scrapped
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_SCRAP = 6;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_SCRAP"] = 6] = "INVENTORY_LIFECYCLE_SCRAP";
    /**
     * Denotes that the inventory is meant to be returned
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_RETURNABLE = 7;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_RETURNABLE"] = 7] = "INVENTORY_LIFECYCLE_RETURNABLE";
    /**
     * Denotes that the inventory has been discarded
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_DISCARDED = 8;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_DISCARDED"] = 8] = "INVENTORY_LIFECYCLE_DISCARDED";
    /**
     * Denotes that the inventory has been issued
     *
     * @generated from enum value: INVENTORY_LIFECYCLE_ISSUED = 9;
     */
    INVENTORY_LIFECYCLE[INVENTORY_LIFECYCLE["INVENTORY_LIFECYCLE_ISSUED"] = 9] = "INVENTORY_LIFECYCLE_ISSUED";
})(INVENTORY_LIFECYCLE || (exports.INVENTORY_LIFECYCLE = INVENTORY_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(INVENTORY_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(INVENTORY_LIFECYCLE, "Scailo.INVENTORY_LIFECYCLE", [
    { no: 0, name: "INVENTORY_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "INVENTORY_LIFECYCLE_QC" },
    { no: 2, name: "INVENTORY_LIFECYCLE_STORE" },
    { no: 3, name: "INVENTORY_LIFECYCLE_REWORK" },
    { no: 4, name: "INVENTORY_LIFECYCLE_CONSUMED" },
    { no: 5, name: "INVENTORY_LIFECYCLE_REJECTED" },
    { no: 6, name: "INVENTORY_LIFECYCLE_SCRAP" },
    { no: 7, name: "INVENTORY_LIFECYCLE_RETURNABLE" },
    { no: 8, name: "INVENTORY_LIFECYCLE_DISCARDED" },
    { no: 9, name: "INVENTORY_LIFECYCLE_ISSUED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INVENTORY_SORT_KEY
 */
var INVENTORY_SORT_KEY;
(function (INVENTORY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: INVENTORY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INVENTORY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_CREATED_AT = 1;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_CREATED_AT"] = 1] = "INVENTORY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_MODIFIED_AT = 2;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_MODIFIED_AT"] = 2] = "INVENTORY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the store intake timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_STORE_INTAKE_AT = 10;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_STORE_INTAKE_AT"] = 10] = "INVENTORY_SORT_KEY_STORE_INTAKE_AT";
    /**
     * Fetch ordered results by the discarded timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_DISCARDED_AT = 11;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_DISCARDED_AT"] = 11] = "INVENTORY_SORT_KEY_DISCARDED_AT";
    /**
     * Fetch ordered results by the shelf timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_SHELF_TIMESTAMP = 12;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_SHELF_TIMESTAMP"] = 12] = "INVENTORY_SORT_KEY_SHELF_TIMESTAMP";
    /**
     * Fetch ordered results by the warranty timestamp
     *
     * @generated from enum value: INVENTORY_SORT_KEY_WARRANTY_TIMESTAMP = 13;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_WARRANTY_TIMESTAMP"] = 13] = "INVENTORY_SORT_KEY_WARRANTY_TIMESTAMP";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: INVENTORY_SORT_KEY_FAMILY_ID = 14;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_FAMILY_ID"] = 14] = "INVENTORY_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal item code
     *
     * @generated from enum value: INVENTORY_SORT_KEY_INTERNAL_ITEM_CODE = 20;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_INTERNAL_ITEM_CODE"] = 20] = "INVENTORY_SORT_KEY_INTERNAL_ITEM_CODE";
    /**
     * Fetch ordered results by the primary quantity
     *
     * @generated from enum value: INVENTORY_SORT_KEY_PRIMARY_QUANTITY = 21;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_PRIMARY_QUANTITY"] = 21] = "INVENTORY_SORT_KEY_PRIMARY_QUANTITY";
    /**
     * Fetch ordered results by the remaining primary quantity
     *
     * @generated from enum value: INVENTORY_SORT_KEY_PRIMARY_QUANTITY_REMAINING = 22;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_PRIMARY_QUANTITY_REMAINING"] = 22] = "INVENTORY_SORT_KEY_PRIMARY_QUANTITY_REMAINING";
    /**
     * Fetch ordered results by the secondary quantity
     *
     * @generated from enum value: INVENTORY_SORT_KEY_SECONDARY_QUANTITY = 23;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_SECONDARY_QUANTITY"] = 23] = "INVENTORY_SORT_KEY_SECONDARY_QUANTITY";
    /**
     * Fetch ordered results by the store ID
     *
     * @generated from enum value: INVENTORY_SORT_KEY_STORE_ID = 24;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_STORE_ID"] = 24] = "INVENTORY_SORT_KEY_STORE_ID";
    /**
     * Fetch ordered results by the storage ID
     *
     * @generated from enum value: INVENTORY_SORT_KEY_STORAGE_ID = 25;
     */
    INVENTORY_SORT_KEY[INVENTORY_SORT_KEY["INVENTORY_SORT_KEY_STORAGE_ID"] = 25] = "INVENTORY_SORT_KEY_STORAGE_ID";
})(INVENTORY_SORT_KEY || (exports.INVENTORY_SORT_KEY = INVENTORY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INVENTORY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INVENTORY_SORT_KEY, "Scailo.INVENTORY_SORT_KEY", [
    { no: 0, name: "INVENTORY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INVENTORY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INVENTORY_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "INVENTORY_SORT_KEY_STORE_INTAKE_AT" },
    { no: 11, name: "INVENTORY_SORT_KEY_DISCARDED_AT" },
    { no: 12, name: "INVENTORY_SORT_KEY_SHELF_TIMESTAMP" },
    { no: 13, name: "INVENTORY_SORT_KEY_WARRANTY_TIMESTAMP" },
    { no: 14, name: "INVENTORY_SORT_KEY_FAMILY_ID" },
    { no: 20, name: "INVENTORY_SORT_KEY_INTERNAL_ITEM_CODE" },
    { no: 21, name: "INVENTORY_SORT_KEY_PRIMARY_QUANTITY" },
    { no: 22, name: "INVENTORY_SORT_KEY_PRIMARY_QUANTITY_REMAINING" },
    { no: 23, name: "INVENTORY_SORT_KEY_SECONDARY_QUANTITY" },
    { no: 24, name: "INVENTORY_SORT_KEY_STORE_ID" },
    { no: 25, name: "INVENTORY_SORT_KEY_STORAGE_ID" },
]);
/**
 *
 * Stores all the possible categories of inventory interactions
 *
 * @generated from enum Scailo.INVENTORY_INTERACTION_CATEGORY
 */
var INVENTORY_INTERACTION_CATEGORY;
(function (INVENTORY_INTERACTION_CATEGORY) {
    /**
     * Only used within filter operations
     *
     * @generated from enum value: INVENTORY_INTERACTION_CATEGORY_ANY_UNSPECIFIED = 0;
     */
    INVENTORY_INTERACTION_CATEGORY[INVENTORY_INTERACTION_CATEGORY["INVENTORY_INTERACTION_CATEGORY_ANY_UNSPECIFIED"] = 0] = "INVENTORY_INTERACTION_CATEGORY_ANY_UNSPECIFIED";
    /**
     * Denotes interactions that are classified as issued
     *
     * @generated from enum value: INVENTORY_INTERACTION_CATEGORY_ISSUED = 1;
     */
    INVENTORY_INTERACTION_CATEGORY[INVENTORY_INTERACTION_CATEGORY["INVENTORY_INTERACTION_CATEGORY_ISSUED"] = 1] = "INVENTORY_INTERACTION_CATEGORY_ISSUED";
    /**
     * Denotes interactions that are classified as returned
     *
     * @generated from enum value: INVENTORY_INTERACTION_CATEGORY_RETURNED = 2;
     */
    INVENTORY_INTERACTION_CATEGORY[INVENTORY_INTERACTION_CATEGORY["INVENTORY_INTERACTION_CATEGORY_RETURNED"] = 2] = "INVENTORY_INTERACTION_CATEGORY_RETURNED";
    /**
     * Denotes interactions that are classified as adjusted
     *
     * @generated from enum value: INVENTORY_INTERACTION_CATEGORY_ADJUSTED = 3;
     */
    INVENTORY_INTERACTION_CATEGORY[INVENTORY_INTERACTION_CATEGORY["INVENTORY_INTERACTION_CATEGORY_ADJUSTED"] = 3] = "INVENTORY_INTERACTION_CATEGORY_ADJUSTED";
})(INVENTORY_INTERACTION_CATEGORY || (exports.INVENTORY_INTERACTION_CATEGORY = INVENTORY_INTERACTION_CATEGORY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INVENTORY_INTERACTION_CATEGORY)
protobuf_1.proto3.util.setEnumType(INVENTORY_INTERACTION_CATEGORY, "Scailo.INVENTORY_INTERACTION_CATEGORY", [
    { no: 0, name: "INVENTORY_INTERACTION_CATEGORY_ANY_UNSPECIFIED" },
    { no: 1, name: "INVENTORY_INTERACTION_CATEGORY_ISSUED" },
    { no: 2, name: "INVENTORY_INTERACTION_CATEGORY_RETURNED" },
    { no: 3, name: "INVENTORY_INTERACTION_CATEGORY_ADJUSTED" },
]);
/**
 *
 * Stores all the possible issued purposes
 *
 * @generated from enum Scailo.INVENTORY_ISSUED_PURPOSE
 */
var INVENTORY_ISSUED_PURPOSE;
(function (INVENTORY_ISSUED_PURPOSE) {
    /**
     * Only used within filter operations
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_ANY_UNSPECIFIED = 0;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_ANY_UNSPECIFIED"] = 0] = "INVENTORY_ISSUED_PURPOSE_ANY_UNSPECIFIED";
    /**
     * Issued for Stock Issuance
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_STOCK_ISSUANCE = 1;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_STOCK_ISSUANCE"] = 1] = "INVENTORY_ISSUED_PURPOSE_STOCK_ISSUANCE";
    /**
     * Issued for Stock Audit
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_STOCK_AUDIT = 2;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_STOCK_AUDIT"] = 2] = "INVENTORY_ISSUED_PURPOSE_STOCK_AUDIT";
    /**
     * Issued for Stock Split
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_STOCK_SPLIT = 3;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_STOCK_SPLIT"] = 3] = "INVENTORY_ISSUED_PURPOSE_STOCK_SPLIT";
    /**
     * Returned using Stock Return
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_STOCK_RETURN = 4;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_STOCK_RETURN"] = 4] = "INVENTORY_ISSUED_PURPOSE_STOCK_RETURN";
    /**
     * Issued for Goods Dispatch
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_GOODS_DISPATCH = 5;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_GOODS_DISPATCH"] = 5] = "INVENTORY_ISSUED_PURPOSE_GOODS_DISPATCH";
    /**
     * Issued as intake
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_INTAKE = 6;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_INTAKE"] = 6] = "INVENTORY_ISSUED_PURPOSE_INTAKE";
    /**
     * Initial Stock
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_INITIAL_STOCK = 7;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_INITIAL_STOCK"] = 7] = "INVENTORY_ISSUED_PURPOSE_INITIAL_STOCK";
    /**
     * Returned using Sales Return
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_SALES_RETURN = 8;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_SALES_RETURN"] = 8] = "INVENTORY_ISSUED_PURPOSE_SALES_RETURN";
    /**
     * Returned using Purchase Return
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_PURCHASE_RETURN = 9;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_PURCHASE_RETURN"] = 9] = "INVENTORY_ISSUED_PURPOSE_PURCHASE_RETURN";
    /**
     * Issued for Outward Job Free Issue Material
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL = 10;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL"] = 10] = "INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL";
    /**
     * Returned using Outward Job Free Issue Material Return
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN = 11;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN"] = 11] = "INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN";
    /**
     * Issued for Inward Job Free Issue Material Return
     *
     * @generated from enum value: INVENTORY_ISSUED_PURPOSE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN = 12;
     */
    INVENTORY_ISSUED_PURPOSE[INVENTORY_ISSUED_PURPOSE["INVENTORY_ISSUED_PURPOSE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN"] = 12] = "INVENTORY_ISSUED_PURPOSE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN";
})(INVENTORY_ISSUED_PURPOSE || (exports.INVENTORY_ISSUED_PURPOSE = INVENTORY_ISSUED_PURPOSE = {}));
// Retrieve enum metadata with: proto3.getEnumType(INVENTORY_ISSUED_PURPOSE)
protobuf_1.proto3.util.setEnumType(INVENTORY_ISSUED_PURPOSE, "Scailo.INVENTORY_ISSUED_PURPOSE", [
    { no: 0, name: "INVENTORY_ISSUED_PURPOSE_ANY_UNSPECIFIED" },
    { no: 1, name: "INVENTORY_ISSUED_PURPOSE_STOCK_ISSUANCE" },
    { no: 2, name: "INVENTORY_ISSUED_PURPOSE_STOCK_AUDIT" },
    { no: 3, name: "INVENTORY_ISSUED_PURPOSE_STOCK_SPLIT" },
    { no: 4, name: "INVENTORY_ISSUED_PURPOSE_STOCK_RETURN" },
    { no: 5, name: "INVENTORY_ISSUED_PURPOSE_GOODS_DISPATCH" },
    { no: 6, name: "INVENTORY_ISSUED_PURPOSE_INTAKE" },
    { no: 7, name: "INVENTORY_ISSUED_PURPOSE_INITIAL_STOCK" },
    { no: 8, name: "INVENTORY_ISSUED_PURPOSE_SALES_RETURN" },
    { no: 9, name: "INVENTORY_ISSUED_PURPOSE_PURCHASE_RETURN" },
    { no: 10, name: "INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL" },
    { no: 11, name: "INVENTORY_ISSUED_PURPOSE_OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN" },
    { no: 12, name: "INVENTORY_ISSUED_PURPOSE_INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN" },
]);
/**
 *
 * Stores the possible values of an amendment log reference
 *
 * @generated from enum Scailo.AMENDMENT_LOG_REF_FOR
 */
var AMENDMENT_LOG_REF_FOR;
(function (AMENDMENT_LOG_REF_FOR) {
    /**
     * Only used for filter operations
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_ANY_UNSPECIFIED = 0;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_ANY_UNSPECIFIED"] = 0] = "AMENDMENT_LOG_REF_FOR_ANY_UNSPECIFIED";
    /**
     * Denotes that the log is made while amending a family
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_FAMILY = 1;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_FAMILY"] = 1] = "AMENDMENT_LOG_REF_FOR_FAMILY";
    /**
     * Denotes that the log is made while amending a purchase order
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_PURCHASE_ORDER = 2;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_PURCHASE_ORDER"] = 2] = "AMENDMENT_LOG_REF_FOR_PURCHASE_ORDER";
    /**
     * Denotes that the log is made while amending a sales order
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_SALES_ORDER = 3;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_SALES_ORDER"] = 3] = "AMENDMENT_LOG_REF_FOR_SALES_ORDER";
    /**
     * Denotes that the log is made while amending a sales enquiry
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_SALES_ENQUIRY = 4;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_SALES_ENQUIRY"] = 4] = "AMENDMENT_LOG_REF_FOR_SALES_ENQUIRY";
    /**
     * Denotes that the log is made while amending a sales quotation
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_SALES_QUOTATION = 5;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_SALES_QUOTATION"] = 5] = "AMENDMENT_LOG_REF_FOR_SALES_QUOTATION";
    /**
     * Denotes that the log is made while amending a sales invoice
     *
     * @generated from enum value: AMENDMENT_LOG_REF_FOR_SALES_INVOICE = 6;
     */
    AMENDMENT_LOG_REF_FOR[AMENDMENT_LOG_REF_FOR["AMENDMENT_LOG_REF_FOR_SALES_INVOICE"] = 6] = "AMENDMENT_LOG_REF_FOR_SALES_INVOICE";
})(AMENDMENT_LOG_REF_FOR || (exports.AMENDMENT_LOG_REF_FOR = AMENDMENT_LOG_REF_FOR = {}));
// Retrieve enum metadata with: proto3.getEnumType(AMENDMENT_LOG_REF_FOR)
protobuf_1.proto3.util.setEnumType(AMENDMENT_LOG_REF_FOR, "Scailo.AMENDMENT_LOG_REF_FOR", [
    { no: 0, name: "AMENDMENT_LOG_REF_FOR_ANY_UNSPECIFIED" },
    { no: 1, name: "AMENDMENT_LOG_REF_FOR_FAMILY" },
    { no: 2, name: "AMENDMENT_LOG_REF_FOR_PURCHASE_ORDER" },
    { no: 3, name: "AMENDMENT_LOG_REF_FOR_SALES_ORDER" },
    { no: 4, name: "AMENDMENT_LOG_REF_FOR_SALES_ENQUIRY" },
    { no: 5, name: "AMENDMENT_LOG_REF_FOR_SALES_QUOTATION" },
    { no: 6, name: "AMENDMENT_LOG_REF_FOR_SALES_INVOICE" },
]);
/**
 *
 * Describes an empty object
 *
 * @generated from message Scailo.Empty
 */
class Empty extends protobuf_1.Message {
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Empty";
    static fields = protobuf_1.proto3.util.newFieldList(() => []);
    static fromBinary(bytes, options) {
        return new Empty().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Empty().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Empty().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Empty, a, b);
    }
}
exports.Empty = Empty;
/**
 *
 * Describes the boolean response
 *
 * @generated from message Scailo.BooleanResponse
 */
class BooleanResponse extends protobuf_1.Message {
    /**
     * Stores if the value is true or false
     *
     * @generated from field: bool value = 1;
     */
    value = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.BooleanResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new BooleanResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new BooleanResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new BooleanResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(BooleanResponse, a, b);
    }
}
exports.BooleanResponse = BooleanResponse;
/**
 *
 * Describes a generic response that consists of bytes as payload
 *
 * @generated from message Scailo.BytesResponse
 */
class BytesResponse extends protobuf_1.Message {
    /**
     * Stores the response content in bytes
     *
     * @generated from field: bytes value = 1;
     */
    value = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.BytesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new BytesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new BytesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new BytesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(BytesResponse, a, b);
    }
}
exports.BytesResponse = BytesResponse;
/**
 *
 * Describes a generic response that consists of string as payload
 *
 * @generated from message Scailo.StringResponse
 */
class StringResponse extends protobuf_1.Message {
    /**
     * Stores the reponse content in string
     *
     * @generated from field: string value = 1;
     */
    value = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StringResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new StringResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StringResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StringResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StringResponse, a, b);
    }
}
exports.StringResponse = StringResponse;
/**
 *
 * Describes a generic response that consists of price as payload
 *
 * @generated from message Scailo.PriceResponse
 */
class PriceResponse extends protobuf_1.Message {
    /**
     * Stores the price as an integer (in cents)
     *
     * @generated from field: int64 value = 1;
     */
    value = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PriceResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PriceResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PriceResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PriceResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PriceResponse, a, b);
    }
}
exports.PriceResponse = PriceResponse;
/**
 *
 * Describes a generic response that consists of info of an image
 *
 * @generated from message Scailo.ImageResponse
 */
class ImageResponse extends protobuf_1.Message {
    /**
     * Stores the raw image content
     *
     * @generated from field: bytes image = 1;
     */
    image = new Uint8Array(0);
    /**
     * Stores the MIME type of the image
     *
     * @generated from field: string mime_type = 2;
     */
    mimeType = "";
    /**
     * The timestamp of when the image was captured
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ImageResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ImageResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ImageResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ImageResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ImageResponse, a, b);
    }
}
exports.ImageResponse = ImageResponse;
/**
 *
 * Describes the message that consists of a base64 string. Useful for relaying an image as a base64 string.
 *
 * @generated from message Scailo.Base64String
 */
class Base64String extends protobuf_1.Message {
    /**
     * Stores the image as a base64 string
     *
     * @generated from field: string image = 1;
     */
    image = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Base64String";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new Base64String().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Base64String().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Base64String().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Base64String, a, b);
    }
}
exports.Base64String = Base64String;
/**
 *
 * Describes the payload that will be used to filter records on the basis of the given month and day
 *
 * @generated from message Scailo.MonthAndDayFilter
 */
class MonthAndDayFilter extends protobuf_1.Message {
    /**
     * Stores the month (1 for Jan, 2 for Feb, etc.)
     *
     * @generated from field: uint64 month = 1;
     */
    month = protobuf_1.protoInt64.zero;
    /**
     * Stores the day
     *
     * @generated from field: uint64 day = 2;
     */
    day = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.MonthAndDayFilter";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "month", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "day", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new MonthAndDayFilter().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MonthAndDayFilter().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MonthAndDayFilter().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(MonthAndDayFilter, a, b);
    }
}
exports.MonthAndDayFilter = MonthAndDayFilter;
/**
 *
 * Describes a generic response that represents a GPS coordinate
 *
 * @generated from message Scailo.GPSCoordinatesResponse
 */
class GPSCoordinatesResponse extends protobuf_1.Message {
    /**
     * Stores the latitude
     *
     * @generated from field: double latitude = 1;
     */
    latitude = 0;
    /**
     * Stores the longitude
     *
     * @generated from field: double longitude = 2;
     */
    longitude = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GPSCoordinatesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 2, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new GPSCoordinatesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GPSCoordinatesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GPSCoordinatesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GPSCoordinatesResponse, a, b);
    }
}
exports.GPSCoordinatesResponse = GPSCoordinatesResponse;
/**
 *
 * Describes the payload for a request to determine the count of records in the given status
 *
 * @generated from message Scailo.CountInSLCStatusRequest
 */
class CountInSLCStatusRequest extends protobuf_1.Message {
    /**
     * Denote if only active records need to be returned
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The standard lifecycle status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 2;
     */
    status = STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.CountInSLCStatusRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(BOOL_FILTER) },
        { no: 2, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new CountInSLCStatusRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CountInSLCStatusRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CountInSLCStatusRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(CountInSLCStatusRequest, a, b);
    }
}
exports.CountInSLCStatusRequest = CountInSLCStatusRequest;
/**
 *
 * Describes the count response
 *
 * @generated from message Scailo.CountResponse
 */
class CountResponse extends protobuf_1.Message {
    /**
     * The number of records
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.CountResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new CountResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CountResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CountResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(CountResponse, a, b);
    }
}
exports.CountResponse = CountResponse;
/**
 *
 * Describes the sum reponse
 *
 * @generated from message Scailo.SumResponse
 */
class SumResponse extends protobuf_1.Message {
    /**
     * The sum of all the records
     *
     * @generated from field: double sum = 1;
     */
    sum = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SumResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "sum", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new SumResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SumResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SumResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SumResponse, a, b);
    }
}
exports.SumResponse = SumResponse;
/**
 *
 * Describes the quantity response
 *
 * @generated from message Scailo.QuantityResponse
 */
class QuantityResponse extends protobuf_1.Message {
    /**
     * The quantity (has to be int currently, since certain locations might need negative values, such as "required quantity" responses)
     *
     * @generated from field: int64 quantity = 1;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuantityResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuantityResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuantityResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuantityResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuantityResponse, a, b);
    }
}
exports.QuantityResponse = QuantityResponse;
/**
 *
 * Describes the dual quantity response. Primary quantity represents quantity in internal UoM and secondary quantity represents quantity in either vendor UoM or client UoM, depending on the context
 *
 * @generated from message Scailo.DualQuantitiesResponse
 */
class DualQuantitiesResponse extends protobuf_1.Message {
    /**
     * Quantity in internal UoM
     *
     * @generated from field: uint64 primary_quantity = 1;
     */
    primaryQuantity = protobuf_1.protoInt64.zero;
    /**
     * Quantity in either vendor or client UoM
     *
     * @generated from field: uint64 secondary_quantity = 2;
     */
    secondaryQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.DualQuantitiesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "primary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new DualQuantitiesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DualQuantitiesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DualQuantitiesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(DualQuantitiesResponse, a, b);
    }
}
exports.DualQuantitiesResponse = DualQuantitiesResponse;
/**
 *
 * Describes the employee metadata of each resource
 *
 * @generated from message Scailo.EmployeeMetadata
 */
class EmployeeMetadata extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * UUID of the resource
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    /**
     * Stores the username of the user who added this resource
     *
     * @generated from field: string added_by = 3;
     */
    addedBy = "";
    /**
     * Represents if the resource is active
     *
     * @generated from field: bool is_active = 4;
     */
    isActive = false;
    /**
     * Stores the timestamp of when the resource was created
     *
     * @generated from field: uint64 created_at = 5;
     */
    createdAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the timestamp of when the resource was last modified
     *
     * @generated from field: uint64 modified_at = 6;
     */
    modifiedAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EmployeeMetadata";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "added_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 4, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "created_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 6, name: "modified_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EmployeeMetadata().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EmployeeMetadata().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EmployeeMetadata().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EmployeeMetadata, a, b);
    }
}
exports.EmployeeMetadata = EmployeeMetadata;
/**
 *
 * Describes the approval metadata of each resource
 *
 * @generated from message Scailo.ApprovalMetadata
 */
class ApprovalMetadata extends protobuf_1.Message {
    /**
     * Stores the UNIX timestamp of when a resource was approved
     *
     * @generated from field: uint64 approved_on = 1;
     */
    approvedOn = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the user who approved this resource
     *
     * @generated from field: uint64 approved_by_user_id = 2;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * Stores the Role ID of the user who approved this resource at the time of approval. This needs to be stored, as the user's role is fluid
     *
     * @generated from field: uint64 approver_role_id = 3;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ApprovalMetadata";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "approved_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ApprovalMetadata().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ApprovalMetadata().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ApprovalMetadata().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ApprovalMetadata, a, b);
    }
}
exports.ApprovalMetadata = ApprovalMetadata;
/**
 *
 * Describes the status of the records returned to a request
 *
 * @generated from message Scailo.ActiveStatus
 */
class ActiveStatus extends protobuf_1.Message {
    /**
     * Used in ViewAll procedural calls, to denote if only active records need to be returned
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActiveStatus";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new ActiveStatus().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActiveStatus().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActiveStatus().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActiveStatus, a, b);
    }
}
exports.ActiveStatus = ActiveStatus;
/**
 *
 * Describes the payload that is required to update the password of a user (by an administrator)
 *
 * @generated from message Scailo.UpdatePasswordReq
 */
class UpdatePasswordReq extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The plain text password using which the user can login
     *
     * @generated from field: string plain_text_password = 10;
     */
    plainTextPassword = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UpdatePasswordReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UpdatePasswordReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdatePasswordReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdatePasswordReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UpdatePasswordReq, a, b);
    }
}
exports.UpdatePasswordReq = UpdatePasswordReq;
/**
 *
 * Describes the payload that is requred to update the user's own password
 *
 * @generated from message Scailo.UpdateOwnPasswordReq
 */
class UpdateOwnPasswordReq extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The old login password
     *
     * @generated from field: string old_plain_text_password = 3;
     */
    oldPlainTextPassword = "";
    /**
     * The new password
     *
     * @generated from field: string plain_text_password = 4;
     */
    plainTextPassword = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UpdateOwnPasswordReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "old_plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 4, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UpdateOwnPasswordReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateOwnPasswordReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateOwnPasswordReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UpdateOwnPasswordReq, a, b);
    }
}
exports.UpdateOwnPasswordReq = UpdateOwnPasswordReq;
/**
 *
 * Describes the payload that is required to upload a picture associated to a resource (such as profile picture or signature)
 *
 * @generated from message Scailo.UploadPictureReq
 */
class UploadPictureReq extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The base64 encoded image that is to be set as the profile picture
     *
     * @generated from field: string img = 10;
     */
    img = "";
    /**
     * The MIME type of the profile picture
     *
     * @generated from field: string mime_type = 11;
     */
    mimeType = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UploadPictureReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "img", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UploadPictureReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UploadPictureReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UploadPictureReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UploadPictureReq, a, b);
    }
}
exports.UploadPictureReq = UploadPictureReq;
/**
 *
 * Describes a simple search request
 *
 * @generated from message Scailo.SimpleSearchReq
 */
class SimpleSearchReq extends protobuf_1.Message {
    /**
     * @generated from field: string search_key = 1;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SimpleSearchReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SimpleSearchReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SimpleSearchReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SimpleSearchReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SimpleSearchReq, a, b);
    }
}
exports.SimpleSearchReq = SimpleSearchReq;
/**
 *
 * Describes the payload necessary for performing Repeat requests with a specific delivery date
 *
 * @generated from message Scailo.RepeatWithDeliveryDate
 */
class RepeatWithDeliveryDate extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The reference ID of the repeated record
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The common delivery date
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RepeatWithDeliveryDate";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new RepeatWithDeliveryDate().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RepeatWithDeliveryDate().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RepeatWithDeliveryDate().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RepeatWithDeliveryDate, a, b);
    }
}
exports.RepeatWithDeliveryDate = RepeatWithDeliveryDate;
/**
 *
 * Describes the standard identifier
 *
 * @generated from message Scailo.Identifier
 */
class Identifier extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Identifier";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new Identifier().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Identifier().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Identifier().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Identifier, a, b);
    }
}
exports.Identifier = Identifier;
/**
 *
 * Describes the standard identifier that can also be a zero
 *
 * @generated from message Scailo.IdentifierZeroable
 */
class IdentifierZeroable extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierZeroable";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierZeroable().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierZeroable().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierZeroable().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierZeroable, a, b);
    }
}
exports.IdentifierZeroable = IdentifierZeroable;
/**
 *
 * Describes the standard identifier along with a search key
 *
 * @generated from message Scailo.IdentifierWithSearch
 */
class IdentifierWithSearch extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 2;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierWithSearch";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierWithSearch().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierWithSearch().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierWithSearch().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierWithSearch, a, b);
    }
}
exports.IdentifierWithSearch = IdentifierWithSearch;
/**
 *
 * Describes the list of standard identifiers, used for identifying associated items
 *
 * @generated from message Scailo.IdentifiersList
 */
class IdentifiersList extends protobuf_1.Message {
    /**
     * List of identifiers
     *
     * @generated from field: repeated uint64 list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifiersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifiersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifiersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifiersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifiersList, a, b);
    }
}
exports.IdentifiersList = IdentifiersList;
/**
 *
 * Describes the list of simple strings, used for underlying associated items
 *
 * @generated from message Scailo.StringsList
 */
class StringsList extends protobuf_1.Message {
    /**
     * List of strings
     *
     * @generated from field: repeated string list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StringsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new StringsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StringsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StringsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StringsList, a, b);
    }
}
exports.StringsList = StringsList;
/**
 *
 * Describes the standard identifier with a user comment. Useful when an operation needs to be performed, and a user comment needs to be recorded
 *
 * @generated from message Scailo.IdentifierWithUserComment
 */
class IdentifierWithUserComment extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierWithUserComment";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierWithUserComment().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierWithUserComment().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierWithUserComment().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierWithUserComment, a, b);
    }
}
exports.IdentifierWithUserComment = IdentifierWithUserComment;
/**
 *
 * Describes the standard identifier with email attributes. Useful when a record (identified by the identifier) needs to be mailed with the given subject, and the list of email addresses.
 *
 * @generated from message Scailo.IdentifierWithEmailAttributes
 */
class IdentifierWithEmailAttributes extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The subject of the email
     *
     * @generated from field: string subject = 10;
     */
    subject = "";
    /**
     * The list of email addresses of the recipients
     *
     * @generated from field: repeated string recipients = 11;
     */
    recipients = [];
    /**
     * The body of the email
     *
     * @generated from field: string body = 12;
     */
    body = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierWithEmailAttributes";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 12, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierWithEmailAttributes().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierWithEmailAttributes().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierWithEmailAttributes().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierWithEmailAttributes, a, b);
    }
}
exports.IdentifierWithEmailAttributes = IdentifierWithEmailAttributes;
/**
 *
 * Describes the standard identifier with a search key. Useful when searching within sub records of a parent item (such as shift group shifts, etc)
 *
 * @generated from message Scailo.IdentifierWithSearchKey
 */
class IdentifierWithSearchKey extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The search key
     *
     * @generated from field: string search_key = 2;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierWithSearchKey";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierWithSearchKey().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierWithSearchKey().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierWithSearchKey().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierWithSearchKey, a, b);
    }
}
exports.IdentifierWithSearchKey = IdentifierWithSearchKey;
/**
 *
 * Describes the standard identifier with a file. Useful for uploading CSV files to records identified by the identifier
 *
 * @generated from message Scailo.IdentifierWithFile
 */
class IdentifierWithFile extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The content of the file
     *
     * @generated from field: bytes file_content = 10;
     */
    fileContent = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierWithFile";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "file_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierWithFile().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierWithFile().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierWithFile().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierWithFile, a, b);
    }
}
exports.IdentifierWithFile = IdentifierWithFile;
/**
 *
 * Describes the UUID identifier
 *
 * @generated from message Scailo.IdentifierUUID
 */
class IdentifierUUID extends protobuf_1.Message {
    /**
     * UUID of the resource
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierUUID";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierUUID().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierUUID().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierUUID().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierUUID, a, b);
    }
}
exports.IdentifierUUID = IdentifierUUID;
/**
 *
 * Describes the list of UUID identifiers, used for identifying associated items
 *
 * @generated from message Scailo.IdentifierUUIDsList
 */
class IdentifierUUIDsList extends protobuf_1.Message {
    /**
     * List of UUID identifiers
     *
     * @generated from field: repeated Scailo.IdentifierUUID list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.IdentifierUUIDsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: IdentifierUUID, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new IdentifierUUIDsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new IdentifierUUIDsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new IdentifierUUIDsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(IdentifierUUIDsList, a, b);
    }
}
exports.IdentifierUUIDsList = IdentifierUUIDsList;
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being a standard lifecycle status
 *
 * @generated from message Scailo.LogbookLogConciseSLC
 */
class LogbookLogConciseSLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS operation = 11;
     */
    operation = STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogConciseSLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(STANDARD_LIFECYCLE_STATUS) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogConciseSLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogConciseSLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogConciseSLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogConciseSLC, a, b);
    }
}
exports.LogbookLogConciseSLC = LogbookLogConciseSLC;
/**
 *
 * Describes the parameters that are required to create a standard lifecycle log entry
 *
 * @generated from message Scailo.LogbookLogConciseSLCCreateRequest
 */
class LogbookLogConciseSLCCreateRequest extends protobuf_1.Message {
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS operation = 11;
     */
    operation = STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 13;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 14;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogConciseSLCCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(STANDARD_LIFECYCLE_STATUS) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogConciseSLCCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogConciseSLCCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogConciseSLCCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogConciseSLCCreateRequest, a, b);
    }
}
exports.LogbookLogConciseSLCCreateRequest = LogbookLogConciseSLCCreateRequest;
/**
 *
 * Describes the parameters that are required to create a generic lifecycle log entry
 *
 * @generated from message Scailo.LogbookLogConciseGenericCreateRequest
 */
class LogbookLogConciseGenericCreateRequest extends protobuf_1.Message {
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: string operation = 11;
     */
    operation = "";
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 13;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 14;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogConciseGenericCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogConciseGenericCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogConciseGenericCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogConciseGenericCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogConciseGenericCreateRequest, a, b);
    }
}
exports.LogbookLogConciseGenericCreateRequest = LogbookLogConciseGenericCreateRequest;
/**
 *
 * Describes each parameter that's part of the logbook (includes historical data of the object)
 *
 * @generated from message Scailo.LogbookLogComplete
 */
class LogbookLogComplete extends protobuf_1.Message {
    /**
     * Stores the metada of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 1;
     */
    metadata;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.LOGBOOK_OPERATION operation = 11;
     */
    operation = LOGBOOK_OPERATION.CREATE_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 13;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 14;
     */
    userComment = "";
    /**
     * The marshalled object at the time the log was created
     *
     * @generated from field: bytes previous_data = 15;
     */
    previousData = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogComplete";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "metadata", kind: "message", T: EmployeeMetadata },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(LOGBOOK_OPERATION) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "previous_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogComplete().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogComplete().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogComplete().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogComplete, a, b);
    }
}
exports.LogbookLogComplete = LogbookLogComplete;
/**
 *
 * Describes the data model to handle reordering of items
 *
 * @generated from message Scailo.ReorderItemsRequest
 */
class ReorderItemsRequest extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The list of IDs to be stored in the given sequence
     *
     * @generated from field: repeated uint64 sequence = 2;
     */
    sequence = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ReorderItemsRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "sequence", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ReorderItemsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReorderItemsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReorderItemsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ReorderItemsRequest, a, b);
    }
}
exports.ReorderItemsRequest = ReorderItemsRequest;
/**
 *
 * Describes the data model to handle cloning of properties of a source record (denoted by source ID) into a target record (denoted by the target ID)
 *
 * @generated from message Scailo.CloneRequest
 */
class CloneRequest extends protobuf_1.Message {
    /**
     * The ID of the source record whose properties need to be cloned into the target record
     *
     * @generated from field: uint64 source_id = 1;
     */
    sourceId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the target record that will store the cloned properties from the source
     *
     * @generated from field: uint64 target_id = 2;
     */
    targetId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing items in the target resource need to be deleted before cloning from the source resource
     *
     * @generated from field: bool delete_existing = 3;
     */
    deleteExisting = false;
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 4;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.CloneRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "source_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "target_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 4, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new CloneRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CloneRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CloneRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(CloneRequest, a, b);
    }
}
exports.CloneRequest = CloneRequest;
/**
 *
 * Describes the payload that consists of a file
 *
 * @generated from message Scailo.StandardFile
 */
class StandardFile extends protobuf_1.Message {
    /**
     * Stores the name of the file
     *
     * @generated from field: string name = 1;
     */
    name = "";
    /**
     * Stores the MIME type of the file
     *
     * @generated from field: string mime_type = 2;
     */
    mimeType = "";
    /**
     * Stores the raw file content
     *
     * @generated from field: bytes content = 10;
     */
    content = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.StandardFile";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new StandardFile().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StandardFile().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StandardFile().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(StandardFile, a, b);
    }
}
exports.StandardFile = StandardFile;
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being a inventory lifecycle status
 *
 * @generated from message Scailo.LogbookLogInventoryLC
 */
class LogbookLogInventoryLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.INVENTORY_LIFECYCLE operation = 11;
     */
    operation = INVENTORY_LIFECYCLE.INVENTORY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogInventoryLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(INVENTORY_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogInventoryLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogInventoryLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogInventoryLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogInventoryLC, a, b);
    }
}
exports.LogbookLogInventoryLC = LogbookLogInventoryLC;
/**
 *
 * Describes the parameters required to partition an inventory lot
 *
 * @generated from message Scailo.InventoryPartitionRequest
 */
class InventoryPartitionRequest extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The primary quantity of the new partition
     *
     * @generated from field: uint64 partition_quantity = 10;
     */
    partitionQuantity = protobuf_1.protoInt64.zero;
    /**
     * The secondary quantity of the new partition
     *
     * @generated from field: uint64 partition_secondary_quantity = 11;
     */
    partitionSecondaryQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryPartitionRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "partition_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "partition_secondary_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryPartitionRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryPartitionRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryPartitionRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryPartitionRequest, a, b);
    }
}
exports.InventoryPartitionRequest = InventoryPartitionRequest;
/**
 *
 * Stores the parameters present within an inventory interaction
 *
 * @generated from message Scailo.InventoryInteraction
 */
class InventoryInteraction extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this interaction
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the category
     *
     * @generated from field: Scailo.INVENTORY_INTERACTION_CATEGORY category = 10;
     */
    category = INVENTORY_INTERACTION_CATEGORY.INVENTORY_INTERACTION_CATEGORY_ANY_UNSPECIFIED;
    /**
     * Stores the UUID of the inventory item
     *
     * @generated from field: string inventory_ref_uuid = 11;
     */
    inventoryRefUuid = "";
    /**
     * Stores the hash of the inventory item that has been issued from this parent item
     *
     * @generated from field: string issued_inventory_hash = 12;
     */
    issuedInventoryHash = "";
    /**
     * Stores the purpose for which this new item has been issued, for e.g. 'stock-issuance'
     *
     * @generated from field: Scailo.INVENTORY_ISSUED_PURPOSE issued_ref_purpose = 13;
     */
    issuedRefPurpose = INVENTORY_ISSUED_PURPOSE.INVENTORY_ISSUED_PURPOSE_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the corresponding issued_ref_purpose item, for e.g., the stock-issuance ID
     *
     * @generated from field: uint64 issued_ref_id = 14;
     */
    issuedRefId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity of this item (in cents). Can be negative since this could also be an adjustment
     *
     * @generated from field: int64 internal_quantity = 15;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryInteraction";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: EmployeeMetadata },
        { no: 10, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(INVENTORY_INTERACTION_CATEGORY) },
        { no: 11, name: "inventory_ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "issued_inventory_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "issued_ref_purpose", kind: "enum", T: protobuf_1.proto3.getEnumType(INVENTORY_ISSUED_PURPOSE) },
        { no: 14, name: "issued_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "internal_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryInteraction().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryInteraction().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryInteraction().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryInteraction, a, b);
    }
}
exports.InventoryInteraction = InventoryInteraction;
/**
 *
 * Describes the list of inventory interactions
 *
 * @generated from message Scailo.InventoryInteractionsList
 */
class InventoryInteractionsList extends protobuf_1.Message {
    /**
     * List of interactions
     *
     * @generated from field: repeated Scailo.InventoryInteraction list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InventoryInteractionsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InventoryInteraction, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InventoryInteractionsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InventoryInteractionsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InventoryInteractionsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InventoryInteractionsList, a, b);
    }
}
exports.InventoryInteractionsList = InventoryInteractionsList;
/**
 *
 * Describes the data payload within an amendment log
 *
 * @generated from message Scailo.AmendmentLog
 */
class AmendmentLog extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metada of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the reference
     *
     * @generated from field: Scailo.AMENDMENT_LOG_REF_FOR ref_for = 10;
     */
    refFor = AMENDMENT_LOG_REF_FOR.AMENDMENT_LOG_REF_FOR_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the reference
     *
     * @generated from field: uint64 ref_id = 11;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the comment
     *
     * @generated from field: string comment = 20;
     */
    comment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AmendmentLog";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: EmployeeMetadata },
        { no: 10, name: "ref_for", kind: "enum", T: protobuf_1.proto3.getEnumType(AMENDMENT_LOG_REF_FOR) },
        { no: 11, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AmendmentLog().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AmendmentLog().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AmendmentLog().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AmendmentLog, a, b);
    }
}
exports.AmendmentLog = AmendmentLog;
/**
 *
 * Describes the list of amendment logs
 *
 * @generated from message Scailo.AmendmentLogsList
 */
class AmendmentLogsList extends protobuf_1.Message {
    /**
     * List of amendment logs
     *
     * @generated from field: repeated Scailo.AmendmentLog list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AmendmentLogsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: AmendmentLog, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AmendmentLogsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AmendmentLogsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AmendmentLogsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AmendmentLogsList, a, b);
    }
}
exports.AmendmentLogsList = AmendmentLogsList;
