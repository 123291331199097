"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file purchases_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesIndentsService = void 0;
const purchases_indents_scailo_pb_js_1 = require("./purchases_indents.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each purchase indent
 *
 * @generated from service Scailo.PurchasesIndentsService
 */
exports.PurchasesIndentsService = {
    typeName: "Scailo.PurchasesIndentsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Create
         */
        create: {
            name: "Create",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Draft
         */
        draft: {
            name: "Draft",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.PurchasesIndentsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.PurchasesIndentsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.PurchasesIndentsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.PurchasesIndentsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.PurchasesIndentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.PurchasesIndentsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Purchase Indent can be marked as completed
         *
         * @generated from rpc Scailo.PurchasesIndentsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add multiple items to a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.AddMultiplePurchaseIndentItems
         */
        addMultiplePurchaseIndentItems: {
            name: "AddMultiplePurchaseIndentItems",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceMultipleItemsCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.AddPurchaseIndentItem
         */
        addPurchaseIndentItem: {
            name: "AddPurchaseIndentItem",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ModifyPurchaseIndentItem
         */
        modifyPurchaseIndentItem: {
            name: "ModifyPurchaseIndentItem",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ApprovePurchaseIndentItem
         */
        approvePurchaseIndentItem: {
            name: "ApprovePurchaseIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.DeletePurchaseIndentItem
         */
        deletePurchaseIndentItem: {
            name: "DeletePurchaseIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ReorderPurchaseIndentItems
         */
        reorderPurchaseIndentItems: {
            name: "ReorderPurchaseIndentItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Purchase Indent Item by ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewPurchaseIndentItemByID
         */
        viewPurchaseIndentItemByID: {
            name: "ViewPurchaseIndentItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_indents_scailo_pb_js_1.PurchaseIndentItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase indent items for given purchase indent ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewApprovedPurchaseIndentItems
         */
        viewApprovedPurchaseIndentItems: {
            name: "ViewApprovedPurchaseIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase indent items for given purchase indent ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewUnapprovedPurchaseIndentItems
         */
        viewUnapprovedPurchaseIndentItems: {
            name: "ViewUnapprovedPurchaseIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the purchase indent item
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewPurchaseIndentItemHistory
         */
        viewPurchaseIndentItemHistory: {
            name: "ViewPurchaseIndentItemHistory",
            I: purchases_indents_scailo_pb_js_1.PurchaseIndentItemHistoryRequest,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase indent items for given purchase indent ID with pagination
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewPaginatedApprovedPurchaseIndentItems
         */
        viewPaginatedApprovedPurchaseIndentItems: {
            name: "ViewPaginatedApprovedPurchaseIndentItems",
            I: purchases_indents_scailo_pb_js_1.PurchaseIndentItemsSearchRequest,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase indent items for given purchase indent ID with pagination
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewPaginatedUnapprovedPurchaseIndentItems
         */
        viewPaginatedUnapprovedPurchaseIndentItems: {
            name: "ViewPaginatedUnapprovedPurchaseIndentItems",
            I: purchases_indents_scailo_pb_js_1.PurchaseIndentItemsSearchRequest,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through purchase indent items with pagination
         *
         * @generated from rpc Scailo.PurchasesIndentsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: purchases_indents_scailo_pb_js_1.PurchaseIndentItemsSearchRequest,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.PurchasesIndentsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.PurchasesIndentsService.UploadPurchaseIndentItems
         */
        uploadPurchaseIndentItems: {
            name: "UploadPurchaseIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_indents_scailo_pb_js_1.PurchaseIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServicePaginationReq,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all purchase order IDs that are associated with the given purchase indent ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewAssociatedPurchaseOrderIDs
         */
        viewAssociatedPurchaseOrderIDs: {
            name: "ViewAssociatedPurchaseOrderIDs",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective items for the given purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective purchase indent item info for the given family ID and purchase indent ID
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewProspectivePurchaseIndentItem
         */
        viewProspectivePurchaseIndentItem: {
            name: "ViewProspectivePurchaseIndentItem",
            I: purchases_indents_scailo_pb_js_1.PurchaseIndentItemProspectiveInfoRequest,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View ordered (purchase order) statistics of the purchase indent
         *
         * @generated from rpc Scailo.PurchasesIndentsService.ViewOrderedStatistics
         */
        viewOrderedStatistics: {
            name: "ViewOrderedStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_indents_scailo_pb_js_1.PurchaseIndentOrderedStatisticsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.PurchasesIndentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceSearchAllReq,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Filter
         */
        filter: {
            name: "Filter",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceFilterReq,
            O: purchases_indents_scailo_pb_js_1.PurchasesIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.PurchasesIndentsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.PurchasesIndentsService.Count
         */
        count: {
            name: "Count",
            I: purchases_indents_scailo_pb_js_1.PurchasesIndentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
