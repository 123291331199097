"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file activities.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivitiesServicePaginatedTimersResponse = exports.ActivityTimersSearchRequest = exports.ActivityTimersList = exports.ActivityTimer = exports.ActivitiesServiceTimerEndRequest = exports.ActivitiesServiceTimerCreateRequest = exports.ActivitySupervisorsList = exports.ActivitySupervisor = exports.ActivitiesServiceSupervisorCreateRequest = exports.ActivitiesServiceImportOwnersRequest = exports.ActivityOwnersList = exports.ActivityOwner = exports.ActivitiesServiceOwnerCreateRequest = exports.ActivityTagAssociationsList = exports.ActivityTagAssociation = exports.ActivitiesServiceActivityTagAssociationCreateRequest = exports.ActivitiesServiceActionWithTimerCreateRequest = exports.ActivitiesServicePaginatedActionsResponse = exports.ActivityActionsSearchRequest = exports.ActivityActionHistoryRequest = exports.ActivityActionsList = exports.ActivityActionStatistics = exports.ActivityAction = exports.ActivitiesServiceActionUpdateRequest = exports.ActivitiesServiceActionCreateRequest = exports.ActivitiesServiceSearchAllReq = exports.ActivitiesServiceCountReq = exports.ActivitiesServiceFilterReq = exports.ActivitiesServicePaginationResponse = exports.ActivitiesServicePaginationReq = exports.ActivityStatistics = exports.ActivitiesList = exports.Activity = exports.ActivitiesServiceUpdateRequest = exports.ActivitiesServiceCreateRequest = exports.LogbookLogActivityLC = exports.ACTIVITY_TIMER_SORT_KEY = exports.ACTIVITY_ACTION_SORT_KEY = exports.ACTIVITY_SORT_KEY = exports.ACTIVITY_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each activity
 *
 * @generated from enum Scailo.ACTIVITY_LIFECYCLE
 */
var ACTIVITY_LIFECYCLE;
(function (ACTIVITY_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    ACTIVITY_LIFECYCLE[ACTIVITY_LIFECYCLE["ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the activity is open
     *
     * @generated from enum value: ACTIVITY_LIFECYCLE_OPEN = 1;
     */
    ACTIVITY_LIFECYCLE[ACTIVITY_LIFECYCLE["ACTIVITY_LIFECYCLE_OPEN"] = 1] = "ACTIVITY_LIFECYCLE_OPEN";
    /**
     * Denotes that the activity has completed
     *
     * @generated from enum value: ACTIVITY_LIFECYCLE_COMPLETED = 2;
     */
    ACTIVITY_LIFECYCLE[ACTIVITY_LIFECYCLE["ACTIVITY_LIFECYCLE_COMPLETED"] = 2] = "ACTIVITY_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the activity has been cancelled
     *
     * @generated from enum value: ACTIVITY_LIFECYCLE_CANCELLED = 3;
     */
    ACTIVITY_LIFECYCLE[ACTIVITY_LIFECYCLE["ACTIVITY_LIFECYCLE_CANCELLED"] = 3] = "ACTIVITY_LIFECYCLE_CANCELLED";
})(ACTIVITY_LIFECYCLE || (exports.ACTIVITY_LIFECYCLE = ACTIVITY_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTIVITY_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(ACTIVITY_LIFECYCLE, "Scailo.ACTIVITY_LIFECYCLE", [
    { no: 0, name: "ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "ACTIVITY_LIFECYCLE_OPEN" },
    { no: 2, name: "ACTIVITY_LIFECYCLE_COMPLETED" },
    { no: 3, name: "ACTIVITY_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ACTIVITY_SORT_KEY
 */
var ACTIVITY_SORT_KEY;
(function (ACTIVITY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ACTIVITY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_CREATED_AT = 1;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_CREATED_AT"] = 1] = "ACTIVITY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_MODIFIED_AT = 2;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_MODIFIED_AT"] = 2] = "ACTIVITY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_COMPLETED_ON = 6;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_COMPLETED_ON"] = 6] = "ACTIVITY_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_TITLE = 10;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_TITLE"] = 10] = "ACTIVITY_SORT_KEY_TITLE";
    /**
     * Fetch ordered results by the start time
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_STARTS_AT = 11;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_STARTS_AT"] = 11] = "ACTIVITY_SORT_KEY_STARTS_AT";
    /**
     * Fetch ordered results by the due by time
     *
     * @generated from enum value: ACTIVITY_SORT_KEY_DUE_BY = 12;
     */
    ACTIVITY_SORT_KEY[ACTIVITY_SORT_KEY["ACTIVITY_SORT_KEY_DUE_BY"] = 12] = "ACTIVITY_SORT_KEY_DUE_BY";
})(ACTIVITY_SORT_KEY || (exports.ACTIVITY_SORT_KEY = ACTIVITY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTIVITY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ACTIVITY_SORT_KEY, "Scailo.ACTIVITY_SORT_KEY", [
    { no: 0, name: "ACTIVITY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ACTIVITY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ACTIVITY_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "ACTIVITY_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ACTIVITY_SORT_KEY_TITLE" },
    { no: 11, name: "ACTIVITY_SORT_KEY_STARTS_AT" },
    { no: 12, name: "ACTIVITY_SORT_KEY_DUE_BY" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ACTIVITY_ACTION_SORT_KEY
 */
var ACTIVITY_ACTION_SORT_KEY;
(function (ACTIVITY_ACTION_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ACTIVITY_ACTION_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_CREATED_AT = 1;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_CREATED_AT"] = 1] = "ACTIVITY_ACTION_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_MODIFIED_AT = 2;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_MODIFIED_AT"] = 2] = "ACTIVITY_ACTION_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_TITLE = 10;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_TITLE"] = 10] = "ACTIVITY_ACTION_SORT_KEY_TITLE";
    /**
     * Fetch ordered results by the action code ID
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_ACTION_CODE_ID = 11;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_ACTION_CODE_ID"] = 11] = "ACTIVITY_ACTION_SORT_KEY_ACTION_CODE_ID";
    /**
     * Fetch ordered results by the number of points
     *
     * @generated from enum value: ACTIVITY_ACTION_SORT_KEY_POINTS = 12;
     */
    ACTIVITY_ACTION_SORT_KEY[ACTIVITY_ACTION_SORT_KEY["ACTIVITY_ACTION_SORT_KEY_POINTS"] = 12] = "ACTIVITY_ACTION_SORT_KEY_POINTS";
})(ACTIVITY_ACTION_SORT_KEY || (exports.ACTIVITY_ACTION_SORT_KEY = ACTIVITY_ACTION_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTIVITY_ACTION_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ACTIVITY_ACTION_SORT_KEY, "Scailo.ACTIVITY_ACTION_SORT_KEY", [
    { no: 0, name: "ACTIVITY_ACTION_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ACTIVITY_ACTION_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ACTIVITY_ACTION_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "ACTIVITY_ACTION_SORT_KEY_TITLE" },
    { no: 11, name: "ACTIVITY_ACTION_SORT_KEY_ACTION_CODE_ID" },
    { no: 12, name: "ACTIVITY_ACTION_SORT_KEY_POINTS" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ACTIVITY_TIMER_SORT_KEY
 */
var ACTIVITY_TIMER_SORT_KEY;
(function (ACTIVITY_TIMER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ACTIVITY_TIMER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_CREATED_AT = 1;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_CREATED_AT"] = 1] = "ACTIVITY_TIMER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_MODIFIED_AT = 2;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_MODIFIED_AT"] = 2] = "ACTIVITY_TIMER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the activity ID
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ID = 10;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ID"] = 10] = "ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ID";
    /**
     * Fetch ordered results by the activity action ID
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ACTION_ID = 11;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ACTION_ID"] = 11] = "ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ACTION_ID";
    /**
     * Fetch ordered results by the employee ID
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_EMPLOYEE_ID = 12;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_EMPLOYEE_ID"] = 12] = "ACTIVITY_TIMER_SORT_KEY_EMPLOYEE_ID";
    /**
     * Fetch ordered results by the start timestamp
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_START_AT = 13;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_START_AT"] = 13] = "ACTIVITY_TIMER_SORT_KEY_START_AT";
    /**
     * Fetch ordered results by the end timestamp
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_END_AT = 14;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_END_AT"] = 14] = "ACTIVITY_TIMER_SORT_KEY_END_AT";
    /**
     * Fetch ordered results by the completion percentage
     *
     * @generated from enum value: ACTIVITY_TIMER_SORT_KEY_COMPLETION_PERCENTAGE = 15;
     */
    ACTIVITY_TIMER_SORT_KEY[ACTIVITY_TIMER_SORT_KEY["ACTIVITY_TIMER_SORT_KEY_COMPLETION_PERCENTAGE"] = 15] = "ACTIVITY_TIMER_SORT_KEY_COMPLETION_PERCENTAGE";
})(ACTIVITY_TIMER_SORT_KEY || (exports.ACTIVITY_TIMER_SORT_KEY = ACTIVITY_TIMER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTIVITY_TIMER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ACTIVITY_TIMER_SORT_KEY, "Scailo.ACTIVITY_TIMER_SORT_KEY", [
    { no: 0, name: "ACTIVITY_TIMER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ACTIVITY_TIMER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ACTIVITY_TIMER_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ID" },
    { no: 11, name: "ACTIVITY_TIMER_SORT_KEY_ACTIVITY_ACTION_ID" },
    { no: 12, name: "ACTIVITY_TIMER_SORT_KEY_EMPLOYEE_ID" },
    { no: 13, name: "ACTIVITY_TIMER_SORT_KEY_START_AT" },
    { no: 14, name: "ACTIVITY_TIMER_SORT_KEY_END_AT" },
    { no: 15, name: "ACTIVITY_TIMER_SORT_KEY_COMPLETION_PERCENTAGE" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being an activity lifecycle status
 *
 * @generated from message Scailo.LogbookLogActivityLC
 */
class LogbookLogActivityLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE operation = 11;
     */
    operation = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogActivityLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogActivityLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogActivityLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogActivityLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogActivityLC, a, b);
    }
}
exports.LogbookLogActivityLC = LogbookLogActivityLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ActivitiesServiceCreateRequest
 */
class ActivitiesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 8;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 9;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * The title of the activity
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp of when the activity needs to be completed
     *
     * @generated from field: uint64 due_by = 12;
     */
    dueBy = protobuf_1.protoInt64.zero;
    /**
     * The description of the activity
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * Assign self (the user creating the activity) as an owner
     *
     * @generated from field: bool assign_self_as_owner = 80;
     */
    assignSelfAsOwner = false;
    /**
     * Assign self (the user creating the activity) as a supervisor
     *
     * @generated from field: bool assign_self_as_supervisor = 81;
     */
    assignSelfAsSupervisor = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "due_by", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 80, name: "assign_self_as_owner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 81, name: "assign_self_as_supervisor", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceCreateRequest, a, b);
    }
}
exports.ActivitiesServiceCreateRequest = ActivitiesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ActivitiesServiceUpdateRequest
 */
class ActivitiesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 8;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 9;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * The title of the activity
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp of when the activity needs to be completed
     *
     * @generated from field: uint64 due_by = 12;
     */
    dueBy = protobuf_1.protoInt64.zero;
    /**
     * The description of the activity
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "due_by", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceUpdateRequest, a, b);
    }
}
exports.ActivitiesServiceUpdateRequest = ActivitiesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Activity
 */
class Activity extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this activity
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE status = 4;
     */
    status = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this activity
     *
     * @generated from field: repeated Scailo.LogbookLogActivityLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this activity was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 8;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 9;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * The title of the activity
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The start UNIX timestamp
     *
     * @generated from field: uint64 starts_at = 11;
     */
    startsAt = protobuf_1.protoInt64.zero;
    /**
     * The UNIX timestamp of when the activity needs to be completed
     *
     * @generated from field: uint64 due_by = 12;
     */
    dueBy = protobuf_1.protoInt64.zero;
    /**
     * The description of the activity
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Activity";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogActivityLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "starts_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "due_by", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new Activity().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Activity().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Activity().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Activity, a, b);
    }
}
exports.Activity = Activity;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ActivitiesList
 */
class ActivitiesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Activity list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Activity, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesList, a, b);
    }
}
exports.ActivitiesList = ActivitiesList;
/**
 *
 * Describes the parameters that are part of an activity's statistics payload
 *
 * @generated from message Scailo.ActivityStatistics
 */
class ActivityStatistics extends protobuf_1.Message {
    /**
     * Stores the total amount of time spent on the activity
     *
     * @generated from field: uint64 total_duration = 1;
     */
    totalDuration = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage of the activity
     *
     * @generated from field: uint64 total_completion_percentage = 2;
     */
    totalCompletionPercentage = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of points
     *
     * @generated from field: uint64 total_points = 3;
     */
    totalPoints = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "total_duration", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "total_completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total_points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityStatistics, a, b);
    }
}
exports.ActivityStatistics = ActivityStatistics;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ActivitiesServicePaginationReq
 */
class ActivitiesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_SORT_KEY.ACTIVITY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this activity
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE status = 6;
     */
    status = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServicePaginationReq, a, b);
    }
}
exports.ActivitiesServicePaginationReq = ActivitiesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ActivitiesServicePaginationResponse
 */
class ActivitiesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Activity payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Activity, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServicePaginationResponse, a, b);
    }
}
exports.ActivitiesServicePaginationResponse = ActivitiesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ActivitiesServiceFilterReq
 */
class ActivitiesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_SORT_KEY.ACTIVITY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this activity
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE status = 10;
     */
    status = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the activity
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 24;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 25;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "due by"
     *
     * @generated from field: uint64 due_by_start = 26;
     */
    dueByStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "due by"
     *
     * @generated from field: uint64 due_by_end = 27;
     */
    dueByEnd = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 40;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 41;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * ------------------------------------------------
     * Filter by the associated project ID
     *
     * @generated from field: uint64 project_id = 50;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated owner employee
     *
     * @generated from field: uint64 owner_employee_id = 51;
     */
    ownerEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated supervisor employee
     *
     * @generated from field: uint64 supervisor_employee_id = 52;
     */
    supervisorEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated goal
     *
     * @generated from field: uint64 goal_id = 53;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated action code
     *
     * @generated from field: uint64 action_code_id = 60;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity tag
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 activity_tag_id = 70;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "due_by_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "due_by_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "owner_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "supervisor_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceFilterReq, a, b);
    }
}
exports.ActivitiesServiceFilterReq = ActivitiesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ActivitiesServiceCountReq
 */
class ActivitiesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this activity
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE status = 10;
     */
    status = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the activity
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 24;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 25;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "due by"
     *
     * @generated from field: uint64 due_by_start = 26;
     */
    dueByStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "due by"
     *
     * @generated from field: uint64 due_by_end = 27;
     */
    dueByEnd = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 40;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 41;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * ------------------------------------------------
     * Filter by the associated project ID
     *
     * @generated from field: uint64 project_id = 50;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated owner employee
     *
     * @generated from field: uint64 owner_employee_id = 51;
     */
    ownerEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated supervisor employee
     *
     * @generated from field: uint64 supervisor_employee_id = 52;
     */
    supervisorEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated goal
     *
     * @generated from field: uint64 goal_id = 53;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated action code
     *
     * @generated from field: uint64 action_code_id = 60;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity tag
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 activity_tag_id = 70;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "due_by_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "due_by_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "owner_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "supervisor_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceCountReq, a, b);
    }
}
exports.ActivitiesServiceCountReq = ActivitiesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ActivitiesServiceSearchAllReq
 */
class ActivitiesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_SORT_KEY.ACTIVITY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.ACTIVITY_LIFECYCLE status = 10;
     */
    status = ACTIVITY_LIFECYCLE.ACTIVITY_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * Filter by the associated activity group ID
     *
     * @generated from field: uint64 activity_group_id = 40;
     */
    activityGroupId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 41;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    /**
     * ------------------------------------------------
     * Filter by the associated project ID
     *
     * @generated from field: uint64 project_id = 50;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated owner employee
     *
     * @generated from field: uint64 owner_employee_id = 51;
     */
    ownerEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated supervisor employee
     *
     * @generated from field: uint64 supervisor_employee_id = 52;
     */
    supervisorEmployeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated goal
     *
     * @generated from field: uint64 goal_id = 53;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated action code
     *
     * @generated from field: uint64 action_code_id = 60;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity tag
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 activity_tag_id = 70;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "owner_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "supervisor_employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 53, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceSearchAllReq, a, b);
    }
}
exports.ActivitiesServiceSearchAllReq = ActivitiesServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add an action to an activity
 *
 * @generated from message Scailo.ActivitiesServiceActionCreateRequest
 */
class ActivitiesServiceActionCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the optional employee ID
     *
     * @generated from field: uint64 employee_id = 9;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The title of the action
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the action
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 13;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the number of points assigned to this action (should be greater than 0)
     *
     * @generated from field: uint64 points = 14;
     */
    points = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceActionCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceActionCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceActionCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceActionCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceActionCreateRequest, a, b);
    }
}
exports.ActivitiesServiceActionCreateRequest = ActivitiesServiceActionCreateRequest;
/**
 *
 * Describes the parameters required to update an action in an activity
 *
 * @generated from message Scailo.ActivitiesServiceActionUpdateRequest
 */
class ActivitiesServiceActionUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional employee ID
     *
     * @generated from field: uint64 employee_id = 9;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The title of the action
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the action
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 13;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the number of points assigned to this action (should be greater than 0)
     *
     * @generated from field: uint64 points = 14;
     */
    points = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceActionUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceActionUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceActionUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceActionUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceActionUpdateRequest, a, b);
    }
}
exports.ActivitiesServiceActionUpdateRequest = ActivitiesServiceActionUpdateRequest;
/**
 *
 * Describes the parameters that constitute an action associated to an activity
 *
 * @generated from message Scailo.ActivityAction
 */
class ActivityAction extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the optional employee ID
     *
     * @generated from field: uint64 employee_id = 9;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The title of the action
     *
     * @generated from field: string title = 11;
     */
    title = "";
    /**
     * The content of the action
     *
     * @generated from field: string content = 12;
     */
    content = "";
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 13;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the number of points assigned to this action
     *
     * @generated from field: uint64 points = 14;
     */
    points = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityAction";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityAction().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityAction().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityAction().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityAction, a, b);
    }
}
exports.ActivityAction = ActivityAction;
/**
 *
 * Describes the parameters that are part of an activity action's statistics payload
 *
 * @generated from message Scailo.ActivityActionStatistics
 */
class ActivityActionStatistics extends protobuf_1.Message {
    /**
     * Stores the total amount of time spent on the activity action
     *
     * @generated from field: uint64 total_duration = 1;
     */
    totalDuration = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage of the activity action
     *
     * @generated from field: uint64 total_completion_percentage = 2;
     */
    totalCompletionPercentage = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityActionStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "total_duration", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "total_completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityActionStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityActionStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityActionStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityActionStatistics, a, b);
    }
}
exports.ActivityActionStatistics = ActivityActionStatistics;
/**
 *
 * Describes the message consisting of the list of activity actions
 *
 * @generated from message Scailo.ActivityActionsList
 */
class ActivityActionsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivityAction list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityActionsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivityAction, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityActionsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityActionsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityActionsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityActionsList, a, b);
    }
}
exports.ActivityActionsList = ActivityActionsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ActivityActionHistoryRequest
 */
class ActivityActionHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 13;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityActionHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityActionHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityActionHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityActionHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityActionHistoryRequest, a, b);
    }
}
exports.ActivityActionHistoryRequest = ActivityActionHistoryRequest;
/**
 *
 * Describes the request payload to retrieve actions.
 *
 * @generated from message Scailo.ActivityActionsSearchRequest
 */
class ActivityActionsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_ACTION_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_ACTION_SORT_KEY.ACTIVITY_ACTION_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Stores the optional employee ID
     *
     * @generated from field: uint64 employee_id = 9;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the activity
     *
     * @generated from field: uint64 activity_id = 20;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 23;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityActionsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_ACTION_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityActionsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityActionsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityActionsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityActionsSearchRequest, a, b);
    }
}
exports.ActivityActionsSearchRequest = ActivityActionsSearchRequest;
/**
 *
 * Describes the response to a pagination actions request
 *
 * @generated from message Scailo.ActivitiesServicePaginatedActionsResponse
 */
class ActivitiesServicePaginatedActionsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ActivityAction payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServicePaginatedActionsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ActivityAction, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServicePaginatedActionsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServicePaginatedActionsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServicePaginatedActionsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServicePaginatedActionsResponse, a, b);
    }
}
exports.ActivitiesServicePaginatedActionsResponse = ActivitiesServicePaginatedActionsResponse;
/**
 *
 * Describes the parameters required to add an action along with an activity timer
 *
 * @generated from message Scailo.ActivitiesServiceActionWithTimerCreateRequest
 */
class ActivitiesServiceActionWithTimerCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional goal ID
     *
     * @generated from field: uint64 goal_id = 11;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 12;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the number of points assigned to this action (should be greater than 0)
     *
     * @generated from field: uint64 points = 13;
     */
    points = protobuf_1.protoInt64.zero;
    /**
     * Stores the start time
     *
     * @generated from field: uint64 start_at = 14;
     */
    startAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the end time (can be 0)
     *
     * @generated from field: uint64 end_at = 15;
     */
    endAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage (this will be diffed at the API and stored only as a diff. E.g., if this is set to 4000, and the cumulative percentage for the same action has been 3500, then the API would store this as 500)
     *
     * @generated from field: uint64 completion_percentage = 16;
     */
    completionPercentage = protobuf_1.protoInt64.zero;
    /**
     * Stores the action code ID
     *
     * @generated from field: uint64 action_code_id = 17;
     */
    actionCodeId = protobuf_1.protoInt64.zero;
    /**
     * The title of the action
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The content of the action
     *
     * @generated from field: string content = 21;
     */
    content = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceActionWithTimerCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "start_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceActionWithTimerCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceActionWithTimerCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceActionWithTimerCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceActionWithTimerCreateRequest, a, b);
    }
}
exports.ActivitiesServiceActionWithTimerCreateRequest = ActivitiesServiceActionWithTimerCreateRequest;
/**
 *
 * Describes the parameters necessary to create an activity tag association
 *
 * @generated from message Scailo.ActivitiesServiceActivityTagAssociationCreateRequest
 */
class ActivitiesServiceActivityTagAssociationCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 11;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceActivityTagAssociationCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceActivityTagAssociationCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceActivityTagAssociationCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceActivityTagAssociationCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceActivityTagAssociationCreateRequest, a, b);
    }
}
exports.ActivitiesServiceActivityTagAssociationCreateRequest = ActivitiesServiceActivityTagAssociationCreateRequest;
/**
 *
 * Describes the parameters that constitute an activity tag association
 *
 * @generated from message Scailo.ActivityTagAssociation
 */
class ActivityTagAssociation extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity tag ID
     *
     * @generated from field: uint64 activity_tag_id = 11;
     */
    activityTagId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityTagAssociation";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "activity_tag_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityTagAssociation().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityTagAssociation().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityTagAssociation().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityTagAssociation, a, b);
    }
}
exports.ActivityTagAssociation = ActivityTagAssociation;
/**
 *
 * Describes the message consisting of the list of activity tag associations
 *
 * @generated from message Scailo.ActivityTagAssociationsList
 */
class ActivityTagAssociationsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivityTagAssociation list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityTagAssociationsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivityTagAssociation, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityTagAssociationsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityTagAssociationsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityTagAssociationsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityTagAssociationsList, a, b);
    }
}
exports.ActivityTagAssociationsList = ActivityTagAssociationsList;
/**
 *
 * Describes the parameters necessary to create an activity owner
 *
 * @generated from message Scailo.ActivitiesServiceOwnerCreateRequest
 */
class ActivitiesServiceOwnerCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceOwnerCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceOwnerCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceOwnerCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceOwnerCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceOwnerCreateRequest, a, b);
    }
}
exports.ActivitiesServiceOwnerCreateRequest = ActivitiesServiceOwnerCreateRequest;
/**
 *
 * Describes the parameters that constitute an activity owner
 *
 * @generated from message Scailo.ActivityOwner
 */
class ActivityOwner extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityOwner";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityOwner().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityOwner().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityOwner().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityOwner, a, b);
    }
}
exports.ActivityOwner = ActivityOwner;
/**
 *
 * Describes the message consisting of the list of activity owners
 *
 * @generated from message Scailo.ActivityOwnersList
 */
class ActivityOwnersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivityOwner list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityOwnersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivityOwner, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityOwnersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityOwnersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityOwnersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityOwnersList, a, b);
    }
}
exports.ActivityOwnersList = ActivityOwnersList;
/**
 *
 * Describes the data model to handle importing of employees from the given identifier representing a team or a department
 *
 * @generated from message Scailo.ActivitiesServiceImportOwnersRequest
 */
class ActivitiesServiceImportOwnersRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the activity
     *
     * @generated from field: uint64 activity_id = 2;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team or the department from which employees need to be added to the activity
     *
     * @generated from field: uint64 resource_id = 3;
     */
    resourceId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing employees in the activity need to be deleted before adding from the source record
     *
     * @generated from field: bool delete_existing = 4;
     */
    deleteExisting = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceImportOwnersRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "resource_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceImportOwnersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceImportOwnersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceImportOwnersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceImportOwnersRequest, a, b);
    }
}
exports.ActivitiesServiceImportOwnersRequest = ActivitiesServiceImportOwnersRequest;
/**
 *
 * Describes the parameters necessary to create an activity supervisor
 *
 * @generated from message Scailo.ActivitiesServiceSupervisorCreateRequest
 */
class ActivitiesServiceSupervisorCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceSupervisorCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceSupervisorCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceSupervisorCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceSupervisorCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceSupervisorCreateRequest, a, b);
    }
}
exports.ActivitiesServiceSupervisorCreateRequest = ActivitiesServiceSupervisorCreateRequest;
/**
 *
 * Describes the parameters that constitute an activity supervisor
 *
 * @generated from message Scailo.ActivitySupervisor
 */
class ActivitySupervisor extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitySupervisor";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitySupervisor().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitySupervisor().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitySupervisor().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitySupervisor, a, b);
    }
}
exports.ActivitySupervisor = ActivitySupervisor;
/**
 *
 * Describes the message consisting of the list of activity supervisors
 *
 * @generated from message Scailo.ActivitySupervisorsList
 */
class ActivitySupervisorsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivitySupervisor list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitySupervisorsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivitySupervisor, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitySupervisorsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitySupervisorsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitySupervisorsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitySupervisorsList, a, b);
    }
}
exports.ActivitySupervisorsList = ActivitySupervisorsList;
/**
 *
 * Describes the parameters necessary to create an activity timer
 *
 * @generated from message Scailo.ActivitiesServiceTimerCreateRequest
 */
class ActivitiesServiceTimerCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the activity action ID
     *
     * @generated from field: uint64 activity_action_id = 11;
     */
    activityActionId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional goal ID
     *
     * @generated from field: uint64 goal_id = 12;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 13;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the start time
     *
     * @generated from field: uint64 start_at = 14;
     */
    startAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the end time (can be 0)
     *
     * @generated from field: uint64 end_at = 15;
     */
    endAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage (this will be diffed at the API and stored only as a diff. E.g., if this is set to 4000, and the cumulative percentage for the same action has been 3500, then the API would store this as 500)
     *
     * @generated from field: uint64 completion_percentage = 16;
     */
    completionPercentage = protobuf_1.protoInt64.zero;
    /**
     * The description of the timer
     *
     * @generated from field: string description = 17;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceTimerCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "activity_action_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "start_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceTimerCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceTimerCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceTimerCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceTimerCreateRequest, a, b);
    }
}
exports.ActivitiesServiceTimerCreateRequest = ActivitiesServiceTimerCreateRequest;
/**
 *
 * Describes the parameters necessary to end an activity timer
 *
 * @generated from message Scailo.ActivitiesServiceTimerEndRequest
 */
class ActivitiesServiceTimerEndRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional goal ID
     *
     * @generated from field: uint64 goal_id = 12;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the end time
     *
     * @generated from field: uint64 end_at = 15;
     */
    endAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage (this will be diffed at the API and stored only as a diff. E.g., if this is set to 4000, and the cumulative percentage for the same action has been 3500, then the API would store this as 500)
     *
     * @generated from field: uint64 completion_percentage = 16;
     */
    completionPercentage = protobuf_1.protoInt64.zero;
    /**
     * The description of the timer
     *
     * @generated from field: string description = 17;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServiceTimerEndRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServiceTimerEndRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServiceTimerEndRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServiceTimerEndRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServiceTimerEndRequest, a, b);
    }
}
exports.ActivitiesServiceTimerEndRequest = ActivitiesServiceTimerEndRequest;
/**
 *
 * Describes the parameters that constitute an activity timer
 *
 * @generated from message Scailo.ActivityTimer
 */
class ActivityTimer extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this activity
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the activity ID
     *
     * @generated from field: uint64 activity_id = 10;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity action ID
     *
     * @generated from field: uint64 activity_action_id = 11;
     */
    activityActionId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional goal ID
     *
     * @generated from field: uint64 goal_id = 12;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 13;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Stores the start time
     *
     * @generated from field: uint64 start_at = 14;
     */
    startAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the end time
     *
     * @generated from field: uint64 end_at = 15;
     */
    endAt = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage (this will be diffed at the API and stored only as a diff. E.g., if this is set to 4000, and the cumulative percentage for the same action has been 3500, then the API would store this as 500)
     *
     * @generated from field: uint64 completion_percentage = 16;
     */
    completionPercentage = protobuf_1.protoInt64.zero;
    /**
     * The description of the timer
     *
     * @generated from field: string description = 17;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityTimer";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "activity_action_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "start_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "end_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityTimer().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityTimer().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityTimer().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityTimer, a, b);
    }
}
exports.ActivityTimer = ActivityTimer;
/**
 *
 * Describes the message consisting of the list of activity timers
 *
 * @generated from message Scailo.ActivityTimersList
 */
class ActivityTimersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivityTimer list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityTimersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivityTimer, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityTimersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityTimersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityTimersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityTimersList, a, b);
    }
}
exports.ActivityTimersList = ActivityTimersList;
/**
 *
 * Describes the request payload to retrieve timers.
 *
 * @generated from message Scailo.ActivityTimersSearchRequest
 */
class ActivityTimersSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_TIMER_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_TIMER_SORT_KEY.ACTIVITY_TIMER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The ID of the activity
     *
     * @generated from field: uint64 activity_id = 20;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Stores the activity action ID
     *
     * @generated from field: uint64 activity_action_id = 21;
     */
    activityActionId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional goal ID
     *
     * @generated from field: uint64 goal_id = 22;
     */
    goalId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 23;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The start range of "starts at"
     *
     * @generated from field: uint64 starts_at_start = 24;
     */
    startsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "starts at"
     *
     * @generated from field: uint64 starts_at_end = 25;
     */
    startsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * The start range of "ends at"
     *
     * @generated from field: uint64 ends_at_start = 26;
     */
    endsAtStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of "ends at"
     *
     * @generated from field: uint64 ends_at_end = 27;
     */
    endsAtEnd = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityTimersSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_TIMER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "activity_action_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "goal_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "starts_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "starts_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "ends_at_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "ends_at_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityTimersSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityTimersSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityTimersSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityTimersSearchRequest, a, b);
    }
}
exports.ActivityTimersSearchRequest = ActivityTimersSearchRequest;
/**
 *
 * Describes the response to a pagination timers request
 *
 * @generated from message Scailo.ActivitiesServicePaginatedTimersResponse
 */
class ActivitiesServicePaginatedTimersResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ActivityTimer payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesServicePaginatedTimersResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ActivityTimer, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesServicePaginatedTimersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesServicePaginatedTimersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesServicePaginatedTimersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesServicePaginatedTimersResponse, a, b);
    }
}
exports.ActivitiesServicePaginatedTimersResponse = ActivitiesServicePaginatedTimersResponse;
