"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file activities_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivitiesGroupsServiceSearchAllReq = exports.ActivitiesGroupsServiceCountReq = exports.ActivitiesGroupsServiceFilterReq = exports.ActivitiesGroupsServicePaginationResponse = exports.ActivitiesGroupsServicePaginationReq = exports.ActivityGroupStatistics = exports.ActivitiesGroupsList = exports.ActivityGroup = exports.ActivitiesGroupsServiceUpdateRequest = exports.ActivitiesGroupsServiceCreateRequest = exports.ACTIVITY_GROUP_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ACTIVITY_GROUP_SORT_KEY
 */
var ACTIVITY_GROUP_SORT_KEY;
(function (ACTIVITY_GROUP_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_CREATED_AT = 1;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_CREATED_AT"] = 1] = "ACTIVITY_GROUP_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_MODIFIED_AT = 2;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_MODIFIED_AT"] = 2] = "ACTIVITY_GROUP_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_APPROVED_ON = 3;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_APPROVED_ON"] = 3] = "ACTIVITY_GROUP_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_APPROVED_BY = 4;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_APPROVED_BY"] = 4] = "ACTIVITY_GROUP_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ACTIVITY_GROUP_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_NAME = 10;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_NAME"] = 10] = "ACTIVITY_GROUP_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: ACTIVITY_GROUP_SORT_KEY_CODE = 11;
     */
    ACTIVITY_GROUP_SORT_KEY[ACTIVITY_GROUP_SORT_KEY["ACTIVITY_GROUP_SORT_KEY_CODE"] = 11] = "ACTIVITY_GROUP_SORT_KEY_CODE";
})(ACTIVITY_GROUP_SORT_KEY || (exports.ACTIVITY_GROUP_SORT_KEY = ACTIVITY_GROUP_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTIVITY_GROUP_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ACTIVITY_GROUP_SORT_KEY, "Scailo.ACTIVITY_GROUP_SORT_KEY", [
    { no: 0, name: "ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ACTIVITY_GROUP_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ACTIVITY_GROUP_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ACTIVITY_GROUP_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ACTIVITY_GROUP_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ACTIVITY_GROUP_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "ACTIVITY_GROUP_SORT_KEY_NAME" },
    { no: 11, name: "ACTIVITY_GROUP_SORT_KEY_CODE" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ActivitiesGroupsServiceCreateRequest
 */
class ActivitiesGroupsServiceCreateRequest extends protobuf_1.Message {
    /**
     * ActivitiesGroups a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * ActivitiesGroups any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The name of the activity group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the activity group is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the associated non-leaf parent activity group (0, if the first activity group that is being created is a leaf activity group)
     *
     * @generated from field: uint64 parent_activity_group_id = 12;
     */
    parentActivityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The description of the activity group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "parent_activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServiceCreateRequest, a, b);
    }
}
exports.ActivitiesGroupsServiceCreateRequest = ActivitiesGroupsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ActivitiesGroupsServiceUpdateRequest
 */
class ActivitiesGroupsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * ActivitiesGroups any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that activities groups if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The name of the activity group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The description of the activity group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServiceUpdateRequest, a, b);
    }
}
exports.ActivitiesGroupsServiceUpdateRequest = ActivitiesGroupsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ActivityGroup
 */
class ActivityGroup extends protobuf_1.Message {
    /**
     * ActivitiesGroups a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * ActivitiesGroups the metadata of this activity group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * ActivitiesGroups the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this activity group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * ActivitiesGroups the logs of every operation performed on this activity group
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The name of the activity group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the activity group is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the associated non-leaf parent activity group (0, if the first activity group that is being created is a leaf activity group)
     *
     * @generated from field: uint64 parent_activity_group_id = 12;
     */
    parentActivityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The description of the activity group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityGroup";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "parent_activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityGroup().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityGroup().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityGroup().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityGroup, a, b);
    }
}
exports.ActivityGroup = ActivityGroup;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ActivitiesGroupsList
 */
class ActivitiesGroupsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActivityGroup list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActivityGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsList, a, b);
    }
}
exports.ActivitiesGroupsList = ActivitiesGroupsList;
/**
 *
 * Describes the parameters that are part of an activity group's statistics payload
 *
 * @generated from message Scailo.ActivityGroupStatistics
 */
class ActivityGroupStatistics extends protobuf_1.Message {
    /**
     * Stores the total amount of time spent on the activity group
     *
     * @generated from field: uint64 total_duration = 1;
     */
    totalDuration = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage of the activity group
     *
     * @generated from field: uint64 total_completion_percentage = 2;
     */
    totalCompletionPercentage = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of points
     *
     * @generated from field: uint64 total_points = 3;
     */
    totalPoints = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivityGroupStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "total_duration", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "total_completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total_points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivityGroupStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivityGroupStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivityGroupStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivityGroupStatistics, a, b);
    }
}
exports.ActivityGroupStatistics = ActivityGroupStatistics;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ActivitiesGroupsServicePaginationReq
 */
class ActivitiesGroupsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_GROUP_SORT_KEY.ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this activity group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_GROUP_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServicePaginationReq, a, b);
    }
}
exports.ActivitiesGroupsServicePaginationReq = ActivitiesGroupsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ActivitiesGroupsServicePaginationResponse
 */
class ActivitiesGroupsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ActivityGroup payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ActivityGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServicePaginationResponse, a, b);
    }
}
exports.ActivitiesGroupsServicePaginationResponse = ActivitiesGroupsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ActivitiesGroupsServiceFilterReq
 */
class ActivitiesGroupsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_GROUP_SORT_KEY.ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this activity group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the activity group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the activity group is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the associated non-leaf parent activity group (0, if the first activity group that is being created is a leaf activity group)
     *
     * @generated from field: uint64 parent_activity_group_id = 22;
     */
    parentActivityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 30;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_GROUP_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "parent_activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServiceFilterReq, a, b);
    }
}
exports.ActivitiesGroupsServiceFilterReq = ActivitiesGroupsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ActivitiesGroupsServiceCountReq
 */
class ActivitiesGroupsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this activity group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the activity group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the activity group is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the associated non-leaf parent activity group (0, if the first activity group that is being created is a leaf activity group)
     *
     * @generated from field: uint64 parent_activity_group_id = 22;
     */
    parentActivityGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 30;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "parent_activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServiceCountReq, a, b);
    }
}
exports.ActivitiesGroupsServiceCountReq = ActivitiesGroupsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ActivitiesGroupsServiceSearchAllReq
 */
class ActivitiesGroupsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTIVITY_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = ACTIVITY_GROUP_SORT_KEY.ACTIVITY_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the associated non-leaf parent activity group (0, if the first activity group that is being created is a leaf activity group)
     *
     * @generated from field: uint64 parent_activity_group_id = 22;
     */
    parentActivityGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActivitiesGroupsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTIVITY_GROUP_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "parent_activity_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActivitiesGroupsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActivitiesGroupsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActivitiesGroupsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActivitiesGroupsServiceSearchAllReq, a, b);
    }
}
exports.ActivitiesGroupsServiceSearchAllReq = ActivitiesGroupsServiceSearchAllReq;
