"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file purchases_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesReturnsServiceSearchAllReq = exports.PurchasesReturnsServiceCountReq = exports.PurchasesReturnsServiceFilterReq = exports.PurchasesReturnsServicePaginationResponse = exports.PurchasesReturnsServicePaginationReq = exports.PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest = exports.PurchasesReturnsServicePaginatedItemsResponse = exports.PurchaseReturnItemsSearchRequest = exports.PurchaseReturnItemProspectiveInfoRequest = exports.PurchaseReturnItemHistoryRequest = exports.PurchasesReturnsItemsList = exports.PurchasesReturnsList = exports.PurchaseReturnItem = exports.PurchasesReturnsServiceItemUpdateRequest = exports.PurchasesReturnsServiceItemCreateRequest = exports.PurchaseReturn = exports.PurchasesReturnsServiceUpdateRequest = exports.PurchasesReturnsServiceCreateRequest = exports.PURCHASE_RETURN_BILLING_STATUS = exports.PURCHASE_RETURN_SORT_KEY = exports.PURCHASE_RETURN_ITEM_STATUS = exports.PURCHASE_RETURN_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_RETURN_ITEM_SORT_KEY
 */
var PURCHASE_RETURN_ITEM_SORT_KEY;
(function (PURCHASE_RETURN_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_RETURN_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_RETURN_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PURCHASE_RETURN_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "PURCHASE_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit of material ID
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    PURCHASE_RETURN_ITEM_SORT_KEY[PURCHASE_RETURN_ITEM_SORT_KEY["PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_QUANTITY";
})(PURCHASE_RETURN_ITEM_SORT_KEY || (exports.PURCHASE_RETURN_ITEM_SORT_KEY = PURCHASE_RETURN_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_RETURN_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_RETURN_ITEM_SORT_KEY, "Scailo.PURCHASE_RETURN_ITEM_SORT_KEY", [
    { no: 0, name: "PURCHASE_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_RETURN_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_RETURN_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PURCHASE_RETURN_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PURCHASE_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "PURCHASE_RETURN_ITEM_SORT_KEY_VENDOR_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of purchase return items
 *
 * @generated from enum Scailo.PURCHASE_RETURN_ITEM_STATUS
 */
var PURCHASE_RETURN_ITEM_STATUS;
(function (PURCHASE_RETURN_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_RETURN_ITEM_STATUS[PURCHASE_RETURN_ITEM_STATUS["PURCHASE_RETURN_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PURCHASE_RETURN_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the purchase return items must have been approved
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_STATUS_APPROVED = 1;
     */
    PURCHASE_RETURN_ITEM_STATUS[PURCHASE_RETURN_ITEM_STATUS["PURCHASE_RETURN_ITEM_STATUS_APPROVED"] = 1] = "PURCHASE_RETURN_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the purchase return items must be waiting for approval
     *
     * @generated from enum value: PURCHASE_RETURN_ITEM_STATUS_UNAPPROVED = 2;
     */
    PURCHASE_RETURN_ITEM_STATUS[PURCHASE_RETURN_ITEM_STATUS["PURCHASE_RETURN_ITEM_STATUS_UNAPPROVED"] = 2] = "PURCHASE_RETURN_ITEM_STATUS_UNAPPROVED";
})(PURCHASE_RETURN_ITEM_STATUS || (exports.PURCHASE_RETURN_ITEM_STATUS = PURCHASE_RETURN_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_RETURN_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PURCHASE_RETURN_ITEM_STATUS, "Scailo.PURCHASE_RETURN_ITEM_STATUS", [
    { no: 0, name: "PURCHASE_RETURN_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_RETURN_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PURCHASE_RETURN_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_RETURN_SORT_KEY
 */
var PURCHASE_RETURN_SORT_KEY;
(function (PURCHASE_RETURN_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_RETURN_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_RETURN_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_RETURN_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_RETURN_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_RETURN_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_COMPLETED_ON = 6;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_COMPLETED_ON"] = 6] = "PURCHASE_RETURN_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_REFERENCE_ID = 10;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_REFERENCE_ID"] = 10] = "PURCHASE_RETURN_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PURCHASE_RETURN_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PURCHASE_RETURN_SORT_KEY[PURCHASE_RETURN_SORT_KEY["PURCHASE_RETURN_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PURCHASE_RETURN_SORT_KEY_FINAL_REF_NUMBER";
})(PURCHASE_RETURN_SORT_KEY || (exports.PURCHASE_RETURN_SORT_KEY = PURCHASE_RETURN_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_RETURN_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_RETURN_SORT_KEY, "Scailo.PURCHASE_RETURN_SORT_KEY", [
    { no: 0, name: "PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_RETURN_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_RETURN_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_RETURN_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_RETURN_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_RETURN_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PURCHASE_RETURN_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PURCHASE_RETURN_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PURCHASE_RETURN_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the available billing statuses
 *
 * @generated from enum Scailo.PURCHASE_RETURN_BILLING_STATUS
 */
var PURCHASE_RETURN_BILLING_STATUS;
(function (PURCHASE_RETURN_BILLING_STATUS) {
    /**
     * Any billing status
     *
     * @generated from enum value: PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_RETURN_BILLING_STATUS[PURCHASE_RETURN_BILLING_STATUS["PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED"] = 0] = "PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED";
    /**
     * Purchase return is billed
     *
     * @generated from enum value: PURCHASE_RETURN_BILLING_STATUS_BILLED = 1;
     */
    PURCHASE_RETURN_BILLING_STATUS[PURCHASE_RETURN_BILLING_STATUS["PURCHASE_RETURN_BILLING_STATUS_BILLED"] = 1] = "PURCHASE_RETURN_BILLING_STATUS_BILLED";
    /**
     * Purchase return is unbilled
     *
     * @generated from enum value: PURCHASE_RETURN_BILLING_STATUS_UNBILLED = 2;
     */
    PURCHASE_RETURN_BILLING_STATUS[PURCHASE_RETURN_BILLING_STATUS["PURCHASE_RETURN_BILLING_STATUS_UNBILLED"] = 2] = "PURCHASE_RETURN_BILLING_STATUS_UNBILLED";
})(PURCHASE_RETURN_BILLING_STATUS || (exports.PURCHASE_RETURN_BILLING_STATUS = PURCHASE_RETURN_BILLING_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_RETURN_BILLING_STATUS)
protobuf_1.proto3.util.setEnumType(PURCHASE_RETURN_BILLING_STATUS, "Scailo.PURCHASE_RETURN_BILLING_STATUS", [
    { no: 0, name: "PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_RETURN_BILLING_STATUS_BILLED" },
    { no: 2, name: "PURCHASE_RETURN_BILLING_STATUS_UNBILLED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PurchasesReturnsServiceCreateRequest
 */
class PurchasesReturnsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceCreateRequest, a, b);
    }
}
exports.PurchasesReturnsServiceCreateRequest = PurchasesReturnsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PurchasesReturnsServiceUpdateRequest
 */
class PurchasesReturnsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceUpdateRequest, a, b);
    }
}
exports.PurchasesReturnsServiceUpdateRequest = PurchasesReturnsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PurchaseReturn
 */
class PurchaseReturn extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this purchase return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase return
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this purchase return was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated purchase return items
     *
     * @generated from field: repeated Scailo.PurchaseReturnItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseReturn";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: PurchaseReturnItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseReturn().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseReturn().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseReturn().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseReturn, a, b);
    }
}
exports.PurchaseReturn = PurchaseReturn;
/**
 *
 * Describes the parameters required to add an item to a purchase return
 *
 * @generated from message Scailo.PurchasesReturnsServiceItemCreateRequest
 */
class PurchasesReturnsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 10;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 14;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 15;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceItemCreateRequest, a, b);
    }
}
exports.PurchasesReturnsServiceItemCreateRequest = PurchasesReturnsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a purchase return
 *
 * @generated from message Scailo.PurchasesReturnsServiceItemUpdateRequest
 */
class PurchasesReturnsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 14;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 15;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceItemUpdateRequest, a, b);
    }
}
exports.PurchasesReturnsServiceItemUpdateRequest = PurchasesReturnsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a purchase return
 *
 * @generated from message Scailo.PurchaseReturnItem
 */
class PurchaseReturnItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 10;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 14;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 15;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseReturnItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseReturnItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseReturnItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseReturnItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseReturnItem, a, b);
    }
}
exports.PurchaseReturnItem = PurchaseReturnItem;
/**
 *
 * Describes the message consisting of the list of purchases returns
 *
 * @generated from message Scailo.PurchasesReturnsList
 */
class PurchasesReturnsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseReturn list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsList, a, b);
    }
}
exports.PurchasesReturnsList = PurchasesReturnsList;
/**
 *
 * Describes the message consisting of the list of purchase return items
 *
 * @generated from message Scailo.PurchasesReturnsItemsList
 */
class PurchasesReturnsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseReturnItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsItemsList, a, b);
    }
}
exports.PurchasesReturnsItemsList = PurchasesReturnsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.PurchaseReturnItemHistoryRequest
 */
class PurchaseReturnItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 10;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseReturnItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseReturnItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseReturnItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseReturnItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseReturnItemHistoryRequest, a, b);
    }
}
exports.PurchaseReturnItemHistoryRequest = PurchaseReturnItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective purchase return item
 *
 * @generated from message Scailo.PurchaseReturnItemProspectiveInfoRequest
 */
class PurchaseReturnItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the purchase return ID
     *
     * @generated from field: uint64 purchase_return_id = 10;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseReturnItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseReturnItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseReturnItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseReturnItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseReturnItemProspectiveInfoRequest, a, b);
    }
}
exports.PurchaseReturnItemProspectiveInfoRequest = PurchaseReturnItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.PurchaseReturnItemsSearchRequest
 */
class PurchaseReturnItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_RETURN_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_RETURN_ITEM_SORT_KEY.PURCHASE_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PURCHASE_RETURN_ITEM_STATUS status = 7;
     */
    status = PURCHASE_RETURN_ITEM_STATUS.PURCHASE_RETURN_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the purchase return
     *
     * @generated from field: uint64 purchase_return_id = 20;
     */
    purchaseReturnId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 23;
     */
    itemHash = "";
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 24;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseReturnItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "purchase_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseReturnItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseReturnItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseReturnItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseReturnItemsSearchRequest, a, b);
    }
}
exports.PurchaseReturnItemsSearchRequest = PurchaseReturnItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.PurchasesReturnsServicePaginatedItemsResponse
 */
class PurchasesReturnsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseReturnItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServicePaginatedItemsResponse, a, b);
    }
}
exports.PurchasesReturnsServicePaginatedItemsResponse = PurchasesReturnsServicePaginatedItemsResponse;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest
 */
class PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest = PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PurchasesReturnsServicePaginationReq
 */
class PurchasesReturnsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_RETURN_SORT_KEY.PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this purchase return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServicePaginationReq, a, b);
    }
}
exports.PurchasesReturnsServicePaginationReq = PurchasesReturnsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PurchasesReturnsServicePaginationResponse
 */
class PurchasesReturnsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseReturn payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServicePaginationResponse, a, b);
    }
}
exports.PurchasesReturnsServicePaginationResponse = PurchasesReturnsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PurchasesReturnsServiceFilterReq
 */
class PurchasesReturnsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_RETURN_SORT_KEY.PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The status of the purchase return bill
     *
     * @generated from field: Scailo.PURCHASE_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = PURCHASE_RETURN_BILLING_STATUS.PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 51;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 60;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_BILLING_STATUS) },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceFilterReq, a, b);
    }
}
exports.PurchasesReturnsServiceFilterReq = PurchasesReturnsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PurchasesReturnsServiceCountReq
 */
class PurchasesReturnsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The status of the purchase return bill
     *
     * @generated from field: Scailo.PURCHASE_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = PURCHASE_RETURN_BILLING_STATUS.PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 51;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 60;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_BILLING_STATUS) },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceCountReq, a, b);
    }
}
exports.PurchasesReturnsServiceCountReq = PurchasesReturnsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PurchasesReturnsServiceSearchAllReq
 */
class PurchasesReturnsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_RETURN_SORT_KEY.PURCHASE_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The status of the purchase return bill
     *
     * @generated from field: Scailo.PURCHASE_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = PURCHASE_RETURN_BILLING_STATUS.PURCHASE_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesReturnsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_RETURN_BILLING_STATUS) },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesReturnsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesReturnsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesReturnsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesReturnsServiceSearchAllReq, a, b);
    }
}
exports.PurchasesReturnsServiceSearchAllReq = PurchasesReturnsServiceSearchAllReq;
