"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file equations_families.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsFamiliesServiceSearchAllReq = exports.EquationsFamiliesServiceCountReq = exports.EquationsFamiliesServiceFilterReq = exports.EquationsFamiliesServicePaginationResponse = exports.EquationsFamiliesServicePaginationReq = exports.EquationsFamiliesServicePaginatedItemsResponse = exports.EquationFamilyItemsSearchRequest = exports.EquationFamilyItemHistoryRequest = exports.EquationsFamiliesItemsList = exports.EquationsFamiliesList = exports.EquationFamilyItem = exports.EquationsFamiliesServiceItemUpdateRequest = exports.EquationsFamiliesServiceItemCreateRequest = exports.EquationFamily = exports.EquationsFamiliesServiceUpdateRequest = exports.EquationsFamiliesServiceCreateRequest = exports.EQUATION_FAMILY_SORT_KEY = exports.EQUATION_FAMILY_ITEM_STATUS = exports.EQUATION_FAMILY_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EQUATION_FAMILY_ITEM_SORT_KEY
 */
var EQUATION_FAMILY_ITEM_SORT_KEY;
(function (EQUATION_FAMILY_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EQUATION_FAMILY_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_CREATED_AT"] = 1] = "EQUATION_FAMILY_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "EQUATION_FAMILY_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "EQUATION_FAMILY_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the quantity
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_QUANTITY = 11;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_QUANTITY"] = 11] = "EQUATION_FAMILY_ITEM_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the unit price
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_SORT_KEY_UNIT_PRICE = 12;
     */
    EQUATION_FAMILY_ITEM_SORT_KEY[EQUATION_FAMILY_ITEM_SORT_KEY["EQUATION_FAMILY_ITEM_SORT_KEY_UNIT_PRICE"] = 12] = "EQUATION_FAMILY_ITEM_SORT_KEY_UNIT_PRICE";
})(EQUATION_FAMILY_ITEM_SORT_KEY || (exports.EQUATION_FAMILY_ITEM_SORT_KEY = EQUATION_FAMILY_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_FAMILY_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EQUATION_FAMILY_ITEM_SORT_KEY, "Scailo.EQUATION_FAMILY_ITEM_SORT_KEY", [
    { no: 0, name: "EQUATION_FAMILY_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EQUATION_FAMILY_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EQUATION_FAMILY_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EQUATION_FAMILY_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "EQUATION_FAMILY_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "EQUATION_FAMILY_ITEM_SORT_KEY_QUANTITY" },
    { no: 12, name: "EQUATION_FAMILY_ITEM_SORT_KEY_UNIT_PRICE" },
]);
/**
 *
 * Describes the applicable statuses of equation family items
 *
 * @generated from enum Scailo.EQUATION_FAMILY_ITEM_STATUS
 */
var EQUATION_FAMILY_ITEM_STATUS;
(function (EQUATION_FAMILY_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    EQUATION_FAMILY_ITEM_STATUS[EQUATION_FAMILY_ITEM_STATUS["EQUATION_FAMILY_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "EQUATION_FAMILY_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the equation family items must have been approved
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_STATUS_APPROVED = 1;
     */
    EQUATION_FAMILY_ITEM_STATUS[EQUATION_FAMILY_ITEM_STATUS["EQUATION_FAMILY_ITEM_STATUS_APPROVED"] = 1] = "EQUATION_FAMILY_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the equation family items must be waiting for approval
     *
     * @generated from enum value: EQUATION_FAMILY_ITEM_STATUS_UNAPPROVED = 2;
     */
    EQUATION_FAMILY_ITEM_STATUS[EQUATION_FAMILY_ITEM_STATUS["EQUATION_FAMILY_ITEM_STATUS_UNAPPROVED"] = 2] = "EQUATION_FAMILY_ITEM_STATUS_UNAPPROVED";
})(EQUATION_FAMILY_ITEM_STATUS || (exports.EQUATION_FAMILY_ITEM_STATUS = EQUATION_FAMILY_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_FAMILY_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(EQUATION_FAMILY_ITEM_STATUS, "Scailo.EQUATION_FAMILY_ITEM_STATUS", [
    { no: 0, name: "EQUATION_FAMILY_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "EQUATION_FAMILY_ITEM_STATUS_APPROVED" },
    { no: 2, name: "EQUATION_FAMILY_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EQUATION_FAMILY_SORT_KEY
 */
var EQUATION_FAMILY_SORT_KEY;
(function (EQUATION_FAMILY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_CREATED_AT = 1;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_CREATED_AT"] = 1] = "EQUATION_FAMILY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_MODIFIED_AT = 2;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_MODIFIED_AT"] = 2] = "EQUATION_FAMILY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_APPROVED_ON = 3;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_APPROVED_ON"] = 3] = "EQUATION_FAMILY_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_APPROVED_BY = 4;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_APPROVED_BY"] = 4] = "EQUATION_FAMILY_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EQUATION_FAMILY_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_COMPLETED_ON = 6;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_COMPLETED_ON"] = 6] = "EQUATION_FAMILY_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_NAME = 10;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_NAME"] = 10] = "EQUATION_FAMILY_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: EQUATION_FAMILY_SORT_KEY_FAMILY_ID = 11;
     */
    EQUATION_FAMILY_SORT_KEY[EQUATION_FAMILY_SORT_KEY["EQUATION_FAMILY_SORT_KEY_FAMILY_ID"] = 11] = "EQUATION_FAMILY_SORT_KEY_FAMILY_ID";
})(EQUATION_FAMILY_SORT_KEY || (exports.EQUATION_FAMILY_SORT_KEY = EQUATION_FAMILY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EQUATION_FAMILY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EQUATION_FAMILY_SORT_KEY, "Scailo.EQUATION_FAMILY_SORT_KEY", [
    { no: 0, name: "EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EQUATION_FAMILY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EQUATION_FAMILY_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EQUATION_FAMILY_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EQUATION_FAMILY_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EQUATION_FAMILY_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "EQUATION_FAMILY_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "EQUATION_FAMILY_SORT_KEY_NAME" },
    { no: 11, name: "EQUATION_FAMILY_SORT_KEY_FAMILY_ID" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.EquationsFamiliesServiceCreateRequest
 */
class EquationsFamiliesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the family that this equation belongs to
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the equation family
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceCreateRequest, a, b);
    }
}
exports.EquationsFamiliesServiceCreateRequest = EquationsFamiliesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.EquationsFamiliesServiceUpdateRequest
 */
class EquationsFamiliesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The description of the equation family
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceUpdateRequest, a, b);
    }
}
exports.EquationsFamiliesServiceUpdateRequest = EquationsFamiliesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.EquationFamily
 */
class EquationFamily extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this equation family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this equation family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this equation family
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this equation family was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the family that this equation belongs to
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the equation family
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of associated equation family items
     *
     * @generated from field: repeated Scailo.EquationFamilyItem list = 20;
     */
    list = [];
    /**
     * The total price of the equation family
     *
     * @generated from field: double total_price = 40;
     */
    totalPrice = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationFamily";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: EquationFamilyItem, repeated: true },
        { no: 40, name: "total_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationFamily().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationFamily().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationFamily().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationFamily, a, b);
    }
}
exports.EquationFamily = EquationFamily;
/**
 *
 * Describes the parameters required to add an item to a equation family
 *
 * @generated from message Scailo.EquationsFamiliesServiceItemCreateRequest
 */
class EquationsFamiliesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the equation family
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceItemCreateRequest, a, b);
    }
}
exports.EquationsFamiliesServiceItemCreateRequest = EquationsFamiliesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a equation family
 *
 * @generated from message Scailo.EquationsFamiliesServiceItemUpdateRequest
 */
class EquationsFamiliesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceItemUpdateRequest, a, b);
    }
}
exports.EquationsFamiliesServiceItemUpdateRequest = EquationsFamiliesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a equation family
 *
 * @generated from message Scailo.EquationFamilyItem
 */
class EquationFamilyItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this equation family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the equation family
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the associated family to be used as the multiplier
     *
     * @generated from field: uint64 quantity = 12;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The optional unit price of this family
     *
     * @generated from field: uint64 unit_price = 13;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The optional specifications
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationFamilyItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationFamilyItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationFamilyItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationFamilyItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationFamilyItem, a, b);
    }
}
exports.EquationFamilyItem = EquationFamilyItem;
/**
 *
 * Describes the message consisting of the list of equations families
 *
 * @generated from message Scailo.EquationsFamiliesList
 */
class EquationsFamiliesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.EquationFamily list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: EquationFamily, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesList, a, b);
    }
}
exports.EquationsFamiliesList = EquationsFamiliesList;
/**
 *
 * Describes the message consisting of the list of equation family items
 *
 * @generated from message Scailo.EquationsFamiliesItemsList
 */
class EquationsFamiliesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.EquationFamilyItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: EquationFamilyItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesItemsList, a, b);
    }
}
exports.EquationsFamiliesItemsList = EquationsFamiliesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.EquationFamilyItemHistoryRequest
 */
class EquationFamilyItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the equation family
     *
     * @generated from field: uint64 equation_id = 10;
     */
    equationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family that the equation depends upon
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationFamilyItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "equation_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationFamilyItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationFamilyItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationFamilyItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationFamilyItemHistoryRequest, a, b);
    }
}
exports.EquationFamilyItemHistoryRequest = EquationFamilyItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.EquationFamilyItemsSearchRequest
 */
class EquationFamilyItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_FAMILY_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_FAMILY_ITEM_SORT_KEY.EQUATION_FAMILY_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.EQUATION_FAMILY_ITEM_STATUS status = 7;
     */
    status = EQUATION_FAMILY_ITEM_STATUS.EQUATION_FAMILY_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the equation family
     *
     * @generated from field: uint64 equation_family_id = 20;
     */
    equationFamilyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationFamilyItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_FAMILY_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_FAMILY_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "equation_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationFamilyItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationFamilyItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationFamilyItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationFamilyItemsSearchRequest, a, b);
    }
}
exports.EquationFamilyItemsSearchRequest = EquationFamilyItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.EquationsFamiliesServicePaginatedItemsResponse
 */
class EquationsFamiliesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.EquationFamilyItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: EquationFamilyItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServicePaginatedItemsResponse, a, b);
    }
}
exports.EquationsFamiliesServicePaginatedItemsResponse = EquationsFamiliesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.EquationsFamiliesServicePaginationReq
 */
class EquationsFamiliesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_FAMILY_SORT_KEY.EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this equation family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_FAMILY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServicePaginationReq, a, b);
    }
}
exports.EquationsFamiliesServicePaginationReq = EquationsFamiliesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.EquationsFamiliesServicePaginationResponse
 */
class EquationsFamiliesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.EquationFamily payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: EquationFamily, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServicePaginationResponse, a, b);
    }
}
exports.EquationsFamiliesServicePaginationResponse = EquationsFamiliesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.EquationsFamiliesServiceFilterReq
 */
class EquationsFamiliesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_FAMILY_SORT_KEY.EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this equation family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation family
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The ID of the family that this equation belongs to
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an equation
     *
     * @generated from field: uint64 constituent_family_id = 30;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_FAMILY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceFilterReq, a, b);
    }
}
exports.EquationsFamiliesServiceFilterReq = EquationsFamiliesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.EquationsFamiliesServiceCountReq
 */
class EquationsFamiliesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this equation family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the equation family
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The ID of the family that this equation belongs to
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the constituent family that is part of an equation
     *
     * @generated from field: uint64 constituent_family_id = 30;
     */
    constituentFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "constituent_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceCountReq, a, b);
    }
}
exports.EquationsFamiliesServiceCountReq = EquationsFamiliesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.EquationsFamiliesServiceSearchAllReq
 */
class EquationsFamiliesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EQUATION_FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = EQUATION_FAMILY_SORT_KEY.EQUATION_FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.EquationsFamiliesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EQUATION_FAMILY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new EquationsFamiliesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EquationsFamiliesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EquationsFamiliesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(EquationsFamiliesServiceSearchAllReq, a, b);
    }
}
exports.EquationsFamiliesServiceSearchAllReq = EquationsFamiliesServiceSearchAllReq;
