"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file payroll_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayrollGroupsServiceSearchAllReq = exports.PayrollGroupsServiceCountReq = exports.PayrollGroupsServiceFilterReq = exports.PayrollGroupsServicePaginationResponse = exports.PayrollGroupsServicePaginationReq = exports.PayrollGroupItemHistoryRequest = exports.PayrollGroupsItemsList = exports.PayrollGroupsList = exports.PayrollGroupItem = exports.PayrollGroupsServiceItemUpdateRequest = exports.PayrollGroupsServiceItemCreateRequest = exports.PayrollGroup = exports.PayrollGroupsServiceUpdateRequest = exports.PayrollGroupsServiceCreateRequest = exports.PAYROLL_GROUP_SORT_KEY = exports.PAYROLL_GROUP_ITEM_VALUE_TYPE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available input types for a payroll group item (with possible vbalues being percentage or fixed)
 *
 * @generated from enum Scailo.PAYROLL_GROUP_ITEM_VALUE_TYPE
 */
var PAYROLL_GROUP_ITEM_VALUE_TYPE;
(function (PAYROLL_GROUP_ITEM_VALUE_TYPE) {
    /**
     * Acceptable value type is any (usefil only for filters and search)
     *
     * @generated from enum value: PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED = 0;
     */
    PAYROLL_GROUP_ITEM_VALUE_TYPE[PAYROLL_GROUP_ITEM_VALUE_TYPE["PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED"] = 0] = "PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED";
    /**
     * Acceptable value type is a percentage
     *
     * @generated from enum value: PAYROLL_GROUP_ITEM_VALUE_TYPE_PERCENTAGE = 1;
     */
    PAYROLL_GROUP_ITEM_VALUE_TYPE[PAYROLL_GROUP_ITEM_VALUE_TYPE["PAYROLL_GROUP_ITEM_VALUE_TYPE_PERCENTAGE"] = 1] = "PAYROLL_GROUP_ITEM_VALUE_TYPE_PERCENTAGE";
    /**
     * Acceptable value type is fixed
     *
     * @generated from enum value: PAYROLL_GROUP_ITEM_VALUE_TYPE_FIXED = 2;
     */
    PAYROLL_GROUP_ITEM_VALUE_TYPE[PAYROLL_GROUP_ITEM_VALUE_TYPE["PAYROLL_GROUP_ITEM_VALUE_TYPE_FIXED"] = 2] = "PAYROLL_GROUP_ITEM_VALUE_TYPE_FIXED";
})(PAYROLL_GROUP_ITEM_VALUE_TYPE || (exports.PAYROLL_GROUP_ITEM_VALUE_TYPE = PAYROLL_GROUP_ITEM_VALUE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(PAYROLL_GROUP_ITEM_VALUE_TYPE)
protobuf_1.proto3.util.setEnumType(PAYROLL_GROUP_ITEM_VALUE_TYPE, "Scailo.PAYROLL_GROUP_ITEM_VALUE_TYPE", [
    { no: 0, name: "PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "PAYROLL_GROUP_ITEM_VALUE_TYPE_PERCENTAGE" },
    { no: 2, name: "PAYROLL_GROUP_ITEM_VALUE_TYPE_FIXED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PAYROLL_GROUP_SORT_KEY
 */
var PAYROLL_GROUP_SORT_KEY;
(function (PAYROLL_GROUP_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_CREATED_AT = 1;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_CREATED_AT"] = 1] = "PAYROLL_GROUP_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_MODIFIED_AT = 2;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_MODIFIED_AT"] = 2] = "PAYROLL_GROUP_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_APPROVED_ON = 3;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_APPROVED_ON"] = 3] = "PAYROLL_GROUP_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_APPROVED_BY = 4;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_APPROVED_BY"] = 4] = "PAYROLL_GROUP_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PAYROLL_GROUP_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_COMPLETED_ON = 6;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_COMPLETED_ON"] = 6] = "PAYROLL_GROUP_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: PAYROLL_GROUP_SORT_KEY_NAME = 10;
     */
    PAYROLL_GROUP_SORT_KEY[PAYROLL_GROUP_SORT_KEY["PAYROLL_GROUP_SORT_KEY_NAME"] = 10] = "PAYROLL_GROUP_SORT_KEY_NAME";
})(PAYROLL_GROUP_SORT_KEY || (exports.PAYROLL_GROUP_SORT_KEY = PAYROLL_GROUP_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PAYROLL_GROUP_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PAYROLL_GROUP_SORT_KEY, "Scailo.PAYROLL_GROUP_SORT_KEY", [
    { no: 0, name: "PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PAYROLL_GROUP_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PAYROLL_GROUP_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PAYROLL_GROUP_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PAYROLL_GROUP_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PAYROLL_GROUP_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PAYROLL_GROUP_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PAYROLL_GROUP_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PayrollGroupsServiceCreateRequest
 */
class PayrollGroupsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the payroll group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The payroll group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the payroll group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceCreateRequest, a, b);
    }
}
exports.PayrollGroupsServiceCreateRequest = PayrollGroupsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PayrollGroupsServiceUpdateRequest
 */
class PayrollGroupsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The name of the payroll group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The payroll group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the payroll group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceUpdateRequest, a, b);
    }
}
exports.PayrollGroupsServiceUpdateRequest = PayrollGroupsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PayrollGroup
 */
class PayrollGroup extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this payroll group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this payroll group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this payroll group
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this payroll group was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The name of the payroll group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The payroll group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the payroll group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of associated payroll group params
     *
     * @generated from field: repeated Scailo.PayrollGroupItem list = 20;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroup";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: PayrollGroupItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroup().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroup().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroup().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroup, a, b);
    }
}
exports.PayrollGroup = PayrollGroup;
/**
 *
 * Describes the parameters required to add a param to a payroll group
 *
 * @generated from message Scailo.PayrollGroupsServiceItemCreateRequest
 */
class PayrollGroupsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the payroll group
     *
     * @generated from field: uint64 payroll_group_id = 10;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the payroll param that is a part of the payroll group
     *
     * @generated from field: uint64 payroll_param_id = 11;
     */
    payrollParamId = protobuf_1.protoInt64.zero;
    /**
     * The value type of the item
     *
     * @generated from field: Scailo.PAYROLL_GROUP_ITEM_VALUE_TYPE value_type = 20;
     */
    valueType = PAYROLL_GROUP_ITEM_VALUE_TYPE.PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the base pay addition amount in terms of value_type (if value_type is percentage, then base_pay determines the percentage)
     *
     * @generated from field: uint64 base_pay_addition = 21;
     */
    basePayAddition = protobuf_1.protoInt64.zero;
    /**
     * Stores the divisor with which the base_pay_addition needs to be divided by, in order to get the actual base pay value
     *
     * @generated from field: uint64 divisor = 22;
     */
    divisor = protobuf_1.protoInt64.zero;
    /**
     * The minimum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 min_amount = 23;
     */
    minAmount = protobuf_1.protoInt64.zero;
    /**
     * The maximum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 max_amount = 24;
     */
    maxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the amount (in terms of percentage) on which any associated payroll tax is applicable
     *
     * @generated from field: uint64 amount_percentage_on_which_tax_applicable = 25;
     */
    amountPercentageOnWhichTaxApplicable = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "payroll_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_ITEM_VALUE_TYPE) },
        { no: 21, name: "base_pay_addition", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "divisor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "min_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "max_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "amount_percentage_on_which_tax_applicable", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceItemCreateRequest, a, b);
    }
}
exports.PayrollGroupsServiceItemCreateRequest = PayrollGroupsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update a param in a payroll group
 *
 * @generated from message Scailo.PayrollGroupsServiceItemUpdateRequest
 */
class PayrollGroupsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The value type of the item
     *
     * @generated from field: Scailo.PAYROLL_GROUP_ITEM_VALUE_TYPE value_type = 20;
     */
    valueType = PAYROLL_GROUP_ITEM_VALUE_TYPE.PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the base pay addition amount in terms of value_type (if value_type is percentage, then base_pay determines the percentage)
     *
     * @generated from field: uint64 base_pay_addition = 21;
     */
    basePayAddition = protobuf_1.protoInt64.zero;
    /**
     * Stores the divisor with which the base_pay_addition needs to be divided by, in order to get the actual base pay value
     *
     * @generated from field: uint64 divisor = 22;
     */
    divisor = protobuf_1.protoInt64.zero;
    /**
     * The minimum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 min_amount = 23;
     */
    minAmount = protobuf_1.protoInt64.zero;
    /**
     * The maximum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 max_amount = 24;
     */
    maxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the amount (in terms of percentage) on which any associated payroll tax is applicable
     *
     * @generated from field: uint64 amount_percentage_on_which_tax_applicable = 25;
     */
    amountPercentageOnWhichTaxApplicable = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_ITEM_VALUE_TYPE) },
        { no: 21, name: "base_pay_addition", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "divisor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "min_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "max_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "amount_percentage_on_which_tax_applicable", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceItemUpdateRequest, a, b);
    }
}
exports.PayrollGroupsServiceItemUpdateRequest = PayrollGroupsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute a param associated to a payroll group
 *
 * @generated from message Scailo.PayrollGroupItem
 */
class PayrollGroupItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this payroll group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the payroll group
     *
     * @generated from field: uint64 payroll_group_id = 10;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the payroll param that is a part of the payroll group
     *
     * @generated from field: uint64 payroll_param_id = 11;
     */
    payrollParamId = protobuf_1.protoInt64.zero;
    /**
     * The value type of the item
     *
     * @generated from field: Scailo.PAYROLL_GROUP_ITEM_VALUE_TYPE value_type = 20;
     */
    valueType = PAYROLL_GROUP_ITEM_VALUE_TYPE.PAYROLL_GROUP_ITEM_VALUE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the base pay addition amount in terms of value_type (if value_type is percentage, then base_pay determines the percentage)
     *
     * @generated from field: uint64 base_pay_addition = 21;
     */
    basePayAddition = protobuf_1.protoInt64.zero;
    /**
     * Stores the divisor with which the base_pay_addition needs to be divided by, in order to get the actual base pay value
     *
     * @generated from field: uint64 divisor = 22;
     */
    divisor = protobuf_1.protoInt64.zero;
    /**
     * The minimum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 min_amount = 23;
     */
    minAmount = protobuf_1.protoInt64.zero;
    /**
     * The maximum amount of applicable tax (will be disregarded if value is -100)
     *
     * @generated from field: int64 max_amount = 24;
     */
    maxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the amount (in terms of percentage) on which any associated payroll tax is applicable
     *
     * @generated from field: uint64 amount_percentage_on_which_tax_applicable = 25;
     */
    amountPercentageOnWhichTaxApplicable = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "payroll_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_ITEM_VALUE_TYPE) },
        { no: 21, name: "base_pay_addition", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "divisor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "min_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 24, name: "max_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 25, name: "amount_percentage_on_which_tax_applicable", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupItem, a, b);
    }
}
exports.PayrollGroupItem = PayrollGroupItem;
/**
 *
 * Describes the message consisting of the list of payroll groups
 *
 * @generated from message Scailo.PayrollGroupsList
 */
class PayrollGroupsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PayrollGroup list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PayrollGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsList, a, b);
    }
}
exports.PayrollGroupsList = PayrollGroupsList;
/**
 *
 * Describes the message consisting of the list of payroll group params
 *
 * @generated from message Scailo.PayrollGroupsItemsList
 */
class PayrollGroupsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PayrollGroupItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PayrollGroupItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsItemsList, a, b);
    }
}
exports.PayrollGroupsItemsList = PayrollGroupsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.PayrollGroupItemHistoryRequest
 */
class PayrollGroupItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the payroll group
     *
     * @generated from field: uint64 payroll_group_id = 10;
     */
    payrollGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the payroll param that is a part of the payroll group
     *
     * @generated from field: uint64 payroll_param_id = 11;
     */
    payrollParamId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "payroll_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "payroll_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupItemHistoryRequest, a, b);
    }
}
exports.PayrollGroupItemHistoryRequest = PayrollGroupItemHistoryRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PayrollGroupsServicePaginationReq
 */
class PayrollGroupsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PAYROLL_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = PAYROLL_GROUP_SORT_KEY.PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this payroll group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServicePaginationReq, a, b);
    }
}
exports.PayrollGroupsServicePaginationReq = PayrollGroupsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PayrollGroupsServicePaginationResponse
 */
class PayrollGroupsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PayrollGroup payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PayrollGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServicePaginationResponse, a, b);
    }
}
exports.PayrollGroupsServicePaginationResponse = PayrollGroupsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PayrollGroupsServiceFilterReq
 */
class PayrollGroupsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PAYROLL_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = PAYROLL_GROUP_SORT_KEY.PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this payroll group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the payroll group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The payroll param code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceFilterReq, a, b);
    }
}
exports.PayrollGroupsServiceFilterReq = PayrollGroupsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PayrollGroupsServiceCountReq
 */
class PayrollGroupsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this payroll group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the payroll group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The payroll param code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceCountReq, a, b);
    }
}
exports.PayrollGroupsServiceCountReq = PayrollGroupsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PayrollGroupsServiceSearchAllReq
 */
class PayrollGroupsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PAYROLL_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = PAYROLL_GROUP_SORT_KEY.PAYROLL_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PayrollGroupsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PAYROLL_GROUP_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PayrollGroupsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PayrollGroupsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PayrollGroupsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PayrollGroupsServiceSearchAllReq, a, b);
    }
}
exports.PayrollGroupsServiceSearchAllReq = PayrollGroupsServiceSearchAllReq;
