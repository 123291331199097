"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file leaves_logs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeavesLogsServiceCountReq = exports.LeavesLogsServiceFilterReq = exports.LeavesLogsList = exports.LeavesLogsCountEmployeeLeavesRequest = exports.LeaveLog = exports.LeavesLogsServiceCreateRequest = exports.LEAVE_LOG_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.LEAVE_LOG_SORT_KEY
 */
var LEAVE_LOG_SORT_KEY;
(function (LEAVE_LOG_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_ID_UNSPECIFIED"] = 0] = "LEAVE_LOG_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_CREATED_AT = 1;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_CREATED_AT"] = 1] = "LEAVE_LOG_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_MODIFIED_AT = 2;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_MODIFIED_AT"] = 2] = "LEAVE_LOG_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the user ID
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_USER_ID = 10;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_USER_ID"] = 10] = "LEAVE_LOG_SORT_KEY_USER_ID";
    /**
     * Fetch ordered results by the uom ID
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_UOM_ID = 11;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_UOM_ID"] = 11] = "LEAVE_LOG_SORT_KEY_UOM_ID";
    /**
     * Fetch ordered results by the leave type ID
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_LEAVE_TYPE_ID = 12;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_LEAVE_TYPE_ID"] = 12] = "LEAVE_LOG_SORT_KEY_LEAVE_TYPE_ID";
    /**
     * Fetch ordered results by the quantity
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_QUANTITY = 13;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_QUANTITY"] = 13] = "LEAVE_LOG_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the reference from attribute
     *
     * @generated from enum value: LEAVE_LOG_SORT_KEY_REF_FROM = 14;
     */
    LEAVE_LOG_SORT_KEY[LEAVE_LOG_SORT_KEY["LEAVE_LOG_SORT_KEY_REF_FROM"] = 14] = "LEAVE_LOG_SORT_KEY_REF_FROM";
})(LEAVE_LOG_SORT_KEY || (exports.LEAVE_LOG_SORT_KEY = LEAVE_LOG_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(LEAVE_LOG_SORT_KEY)
protobuf_1.proto3.util.setEnumType(LEAVE_LOG_SORT_KEY, "Scailo.LEAVE_LOG_SORT_KEY", [
    { no: 0, name: "LEAVE_LOG_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "LEAVE_LOG_SORT_KEY_CREATED_AT" },
    { no: 2, name: "LEAVE_LOG_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "LEAVE_LOG_SORT_KEY_USER_ID" },
    { no: 11, name: "LEAVE_LOG_SORT_KEY_UOM_ID" },
    { no: 12, name: "LEAVE_LOG_SORT_KEY_LEAVE_TYPE_ID" },
    { no: 13, name: "LEAVE_LOG_SORT_KEY_QUANTITY" },
    { no: 14, name: "LEAVE_LOG_SORT_KEY_REF_FROM" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.LeavesLogsServiceCreateRequest
 */
class LeavesLogsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * The ID of the user for whom this log needs to be made
     *
     * @generated from field: uint64 user_id = 10;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the uom
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The reference on the basis of which this record is created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The corresponding reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The leave type ID
     *
     * @generated from field: uint64 leave_type_id = 14;
     */
    leaveTypeId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity = 15;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeavesLogsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "leave_type_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new LeavesLogsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeavesLogsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeavesLogsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeavesLogsServiceCreateRequest, a, b);
    }
}
exports.LeavesLogsServiceCreateRequest = LeavesLogsServiceCreateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.LeaveLog
 */
class LeaveLog extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this record
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The ID of the user for whom this log needs to be made
     *
     * @generated from field: uint64 user_id = 10;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the uom
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The reference on the basis of which this record is created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The corresponding reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The leave type ID
     *
     * @generated from field: uint64 leave_type_id = 14;
     */
    leaveTypeId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity = 15;
     */
    quantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeaveLog";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "leave_type_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new LeaveLog().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeaveLog().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeaveLog().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeaveLog, a, b);
    }
}
exports.LeaveLog = LeaveLog;
/**
 *
 * Describes the parameters that are required to fetch the number of leaves available for an employee
 *
 * @generated from message Scailo.LeavesLogsCountEmployeeLeavesRequest
 */
class LeavesLogsCountEmployeeLeavesRequest extends protobuf_1.Message {
    /**
     * The ID of the user for whom this log needs to be made
     *
     * @generated from field: uint64 user_id = 10;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The leave type ID
     *
     * @generated from field: uint64 leave_type_id = 14;
     */
    leaveTypeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeavesLogsCountEmployeeLeavesRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "leave_type_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new LeavesLogsCountEmployeeLeavesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeavesLogsCountEmployeeLeavesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeavesLogsCountEmployeeLeavesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeavesLogsCountEmployeeLeavesRequest, a, b);
    }
}
exports.LeavesLogsCountEmployeeLeavesRequest = LeavesLogsCountEmployeeLeavesRequest;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.LeavesLogsList
 */
class LeavesLogsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.LeaveLog list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeavesLogsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: LeaveLog, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new LeavesLogsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeavesLogsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeavesLogsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeavesLogsList, a, b);
    }
}
exports.LeavesLogsList = LeavesLogsList;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.LeavesLogsServiceFilterReq
 */
class LeavesLogsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.LEAVE_LOG_SORT_KEY sort_key = 5;
     */
    sortKey = LEAVE_LOG_SORT_KEY.LEAVE_LOG_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The ID of the user for whom this log needs to be made
     *
     * @generated from field: uint64 user_id = 10;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the uom
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The reference on the basis of which this record is created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The corresponding reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The leave type ID
     *
     * @generated from field: uint64 leave_type_id = 14;
     */
    leaveTypeId = protobuf_1.protoInt64.zero;
    /**
     * The minimum quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity_min = 15;
     */
    quantityMin = protobuf_1.protoInt64.zero;
    /**
     * The maximum quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity_max = 16;
     */
    quantityMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeavesLogsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(LEAVE_LOG_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "leave_type_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "quantity_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "quantity_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new LeavesLogsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeavesLogsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeavesLogsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeavesLogsServiceFilterReq, a, b);
    }
}
exports.LeavesLogsServiceFilterReq = LeavesLogsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.LeavesLogsServiceCountReq
 */
class LeavesLogsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The ID of the user for whom this log needs to be made
     *
     * @generated from field: uint64 user_id = 10;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the uom
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The reference on the basis of which this record is created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The corresponding reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The leave type ID
     *
     * @generated from field: uint64 leave_type_id = 14;
     */
    leaveTypeId = protobuf_1.protoInt64.zero;
    /**
     * The minimum quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity_min = 15;
     */
    quantityMin = protobuf_1.protoInt64.zero;
    /**
     * The maximum quantity of leaves (in cents)
     *
     * @generated from field: uint64 quantity_max = 16;
     */
    quantityMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LeavesLogsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "leave_type_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "quantity_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "quantity_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new LeavesLogsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LeavesLogsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LeavesLogsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LeavesLogsServiceCountReq, a, b);
    }
}
exports.LeavesLogsServiceCountReq = LeavesLogsServiceCountReq;
