"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file families.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FamiliesService = void 0;
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each family
 *
 * @generated from service Scailo.FamiliesService
 */
exports.FamiliesService = {
    typeName: "Scailo.FamiliesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.FamiliesService.Create
         */
        create: {
            name: "Create",
            I: families_scailo_pb_js_1.FamiliesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.FamiliesService.Draft
         */
        draft: {
            name: "Draft",
            I: families_scailo_pb_js_1.FamiliesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.FamiliesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: families_scailo_pb_js_1.FamiliesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.FamiliesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.FamiliesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.FamiliesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.FamiliesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.FamiliesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: families_scailo_pb_js_1.FamiliesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.FamiliesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.FamiliesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.FamiliesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (Identifier);
         *
         * @generated from rpc Scailo.FamiliesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the family and send for revision
         *
         * @generated from rpc Scailo.FamiliesService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.FamiliesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file (duplicate codes will be skipped)
         *
         * @generated from rpc Scailo.FamiliesService.UploadFamilies
         */
        uploadFamilies: {
            name: "UploadFamilies",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a storage
         *
         * @generated from rpc Scailo.FamiliesService.AddStorage
         */
        addStorage: {
            name: "AddStorage",
            I: families_scailo_pb_js_1.FamiliesServiceStorageCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a storage
         *
         * @generated from rpc Scailo.FamiliesService.ApproveStorage
         */
        approveStorage: {
            name: "ApproveStorage",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a storage
         *
         * @generated from rpc Scailo.FamiliesService.DeleteStorage
         */
        deleteStorage: {
            name: "DeleteStorage",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a storage for the given ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewStorageByID
         */
        viewStorageByID: {
            name: "ViewStorageByID",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyStorage,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all storages for given family ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewStorages
         */
        viewStorages: {
            name: "ViewStorages",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyStoragesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a unit conversion
         *
         * @generated from rpc Scailo.FamiliesService.AddUnitConversion
         */
        addUnitConversion: {
            name: "AddUnitConversion",
            I: families_scailo_pb_js_1.FamiliesServiceUnitConversionCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a unit conversion
         *
         * @generated from rpc Scailo.FamiliesService.ApproveUnitConversion
         */
        approveUnitConversion: {
            name: "ApproveUnitConversion",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a unit conversion
         *
         * @generated from rpc Scailo.FamiliesService.DeleteUnitConversion
         */
        deleteUnitConversion: {
            name: "DeleteUnitConversion",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a unit conversion for the given ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewUnitConversionByID
         */
        viewUnitConversionByID: {
            name: "ViewUnitConversionByID",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyUnitConversion,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all unit conversions for given family ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewUnitConversions
         */
        viewUnitConversions: {
            name: "ViewUnitConversions",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyUnitConversionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the unit conversion for the given family ID and the given uom ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewUnitConversionFor
         */
        viewUnitConversionFor: {
            name: "ViewUnitConversionFor",
            I: families_scailo_pb_js_1.FamiliesServiceUnitConversionPresenceRequest,
            O: families_scailo_pb_js_1.FamilyUnitConversion,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a qc group
         *
         * @generated from rpc Scailo.FamiliesService.AddQCGroup
         */
        addQCGroup: {
            name: "AddQCGroup",
            I: families_scailo_pb_js_1.FamiliesServiceQCGroupCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a qc group
         *
         * @generated from rpc Scailo.FamiliesService.ApproveQCGroup
         */
        approveQCGroup: {
            name: "ApproveQCGroup",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a qc group
         *
         * @generated from rpc Scailo.FamiliesService.DeleteQCGroup
         */
        deleteQCGroup: {
            name: "DeleteQCGroup",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a qc group for the given ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewQCGroupByID
         */
        viewQCGroupByID: {
            name: "ViewQCGroupByID",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyQCGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all qc groups for given family ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewQCGroups
         */
        viewQCGroups: {
            name: "ViewQCGroups",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.FamilyQCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.FamiliesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.Family,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components of the family (without logs)
         *
         * @generated from rpc Scailo.FamiliesService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: families_scailo_pb_js_1.Family,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components of the family (without logs) that matches the given code
         *
         * @generated from rpc Scailo.FamiliesService.ViewEssentialByCode
         */
        viewEssentialByCode: {
            name: "ViewEssentialByCode",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.Family,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.FamiliesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.FamiliesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.FamiliesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: families_scailo_pb_js_1.FamiliesServicePaginationReq,
            O: families_scailo_pb_js_1.FamiliesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families with the given IDs
         *
         * @generated from rpc Scailo.FamiliesService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families with required quantity greater than 0 from the given family types
         *
         * @generated from rpc Scailo.FamiliesService.ViewAllRequirable
         */
        viewAllRequirable: {
            name: "ViewAllRequirable",
            I: families_scailo_pb_js_1.FamilyTypesList,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.FamiliesService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: families_scailo_pb_js_1.FamiliesServiceSearchAllReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.FamiliesService.Filter
         */
        filter: {
            name: "Filter",
            I: families_scailo_pb_js_1.FamiliesServiceFilterReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for purchase that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForPurchase
         */
        searchForPurchase: {
            name: "SearchForPurchase",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for sale that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForSale
         */
        searchForSale: {
            name: "SearchForSale",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for production that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForProduction
         */
        searchForProduction: {
            name: "SearchForProduction",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for replaceables that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForReplaceables
         */
        searchForReplaceables: {
            name: "SearchForReplaceables",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for equation family that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForEquationFamily
         */
        searchForEquationFamily: {
            name: "SearchForEquationFamily",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for equation replaceable that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForEquationReplaceable
         */
        searchForEquationReplaceable: {
            name: "SearchForEquationReplaceable",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all families for equation sales bundle that match the given search key
         *
         * @generated from rpc Scailo.FamiliesService.SearchForEquationSalesBundle
         */
        searchForEquationSalesBundle: {
            name: "SearchForEquationSalesBundle",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.FamiliesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.FamiliesService.Count
         */
        count: {
            name: "Count",
            I: families_scailo_pb_js_1.FamiliesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
