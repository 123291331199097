"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vendor_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorInvoiceReceiptStatisticsList = exports.VendorInvoiceReceiptStatistics = exports.VendorInvoicesServicePaginatedItemsResponse = exports.VendorInvoiceItemsSearchRequest = exports.VendorInvoiceReferencesList = exports.VendorInvoiceReference = exports.VendorInvoicesServiceReferenceCreateRequest = exports.VendorInvoicesServiceSearchAllReq = exports.VendorInvoicesServiceCountReq = exports.VendorInvoicesServiceFilterReq = exports.VendorInvoicesServicePaginationResponse = exports.VendorInvoicesServicePaginationReq = exports.VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest = exports.VendorInvoiceItemProspectiveInfoRequest = exports.VendorInvoiceItemHistoryRequest = exports.VendorInvoiceItemsList = exports.VendorInvoicesList = exports.VendorInvoiceItem = exports.VendorInvoicesServiceItemUpdateRequest = exports.VendorInvoicesServiceItemCreateRequest = exports.VendorInvoice = exports.VendorInvoicesServiceAutofillRequest = exports.VendorInvoicesServiceUpdateRequest = exports.VendorInvoicesServiceCreateRequest = exports.VENDOR_INVOICE_ITEM_STATUS = exports.VENDOR_INVOICE_ITEM_SORT_KEY = exports.VENDOR_INVOICE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_INVOICE_SORT_KEY
 */
var VENDOR_INVOICE_SORT_KEY;
(function (VENDOR_INVOICE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_INVOICE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_INVOICE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_APPROVED_ON = 3;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_APPROVED_ON"] = 3] = "VENDOR_INVOICE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_APPROVED_BY = 4;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_APPROVED_BY"] = 4] = "VENDOR_INVOICE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "VENDOR_INVOICE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_COMPLETED_ON = 6;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_COMPLETED_ON"] = 6] = "VENDOR_INVOICE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_REFERENCE_ID = 10;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_REFERENCE_ID"] = 10] = "VENDOR_INVOICE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "VENDOR_INVOICE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: VENDOR_INVOICE_SORT_KEY_TOTAL_VALUE = 30;
     */
    VENDOR_INVOICE_SORT_KEY[VENDOR_INVOICE_SORT_KEY["VENDOR_INVOICE_SORT_KEY_TOTAL_VALUE"] = 30] = "VENDOR_INVOICE_SORT_KEY_TOTAL_VALUE";
})(VENDOR_INVOICE_SORT_KEY || (exports.VENDOR_INVOICE_SORT_KEY = VENDOR_INVOICE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_INVOICE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_INVOICE_SORT_KEY, "Scailo.VENDOR_INVOICE_SORT_KEY", [
    { no: 0, name: "VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_INVOICE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_INVOICE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "VENDOR_INVOICE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "VENDOR_INVOICE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "VENDOR_INVOICE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "VENDOR_INVOICE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "VENDOR_INVOICE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "VENDOR_INVOICE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 30, name: "VENDOR_INVOICE_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_INVOICE_ITEM_SORT_KEY
 */
var VENDOR_INVOICE_ITEM_SORT_KEY;
(function (VENDOR_INVOICE_ITEM_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_INVOICE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_INVOICE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the family ID
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "VENDOR_INVOICE_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch invoiced results by the internal quantity
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "VENDOR_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch invoiced results by the vendor unit of material ID
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch invoiced results by the vendor quantity
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_QUANTITY";
    /**
     * Fetch invoiced results by the unit price
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE = 14;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE"] = 14] = "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE";
    /**
     * Fetch invoiced results by the tax group ID
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID = 15;
     */
    VENDOR_INVOICE_ITEM_SORT_KEY[VENDOR_INVOICE_ITEM_SORT_KEY["VENDOR_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID"] = 15] = "VENDOR_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID";
})(VENDOR_INVOICE_ITEM_SORT_KEY || (exports.VENDOR_INVOICE_ITEM_SORT_KEY = VENDOR_INVOICE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_INVOICE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_INVOICE_ITEM_SORT_KEY, "Scailo.VENDOR_INVOICE_ITEM_SORT_KEY", [
    { no: 0, name: "VENDOR_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_INVOICE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_INVOICE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "VENDOR_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "VENDOR_INVOICE_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "VENDOR_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_QUANTITY" },
    { no: 14, name: "VENDOR_INVOICE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE" },
    { no: 15, name: "VENDOR_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID" },
]);
/**
 *
 * Describes the applicable statuses of vendor invoice items
 *
 * @generated from enum Scailo.VENDOR_INVOICE_ITEM_STATUS
 */
var VENDOR_INVOICE_ITEM_STATUS;
(function (VENDOR_INVOICE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    VENDOR_INVOICE_ITEM_STATUS[VENDOR_INVOICE_ITEM_STATUS["VENDOR_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "VENDOR_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the vendor invoice items must have been approved
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_STATUS_APPROVED = 1;
     */
    VENDOR_INVOICE_ITEM_STATUS[VENDOR_INVOICE_ITEM_STATUS["VENDOR_INVOICE_ITEM_STATUS_APPROVED"] = 1] = "VENDOR_INVOICE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the vendor invoice items must be waiting for approval
     *
     * @generated from enum value: VENDOR_INVOICE_ITEM_STATUS_UNAPPROVED = 2;
     */
    VENDOR_INVOICE_ITEM_STATUS[VENDOR_INVOICE_ITEM_STATUS["VENDOR_INVOICE_ITEM_STATUS_UNAPPROVED"] = 2] = "VENDOR_INVOICE_ITEM_STATUS_UNAPPROVED";
})(VENDOR_INVOICE_ITEM_STATUS || (exports.VENDOR_INVOICE_ITEM_STATUS = VENDOR_INVOICE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_INVOICE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(VENDOR_INVOICE_ITEM_STATUS, "Scailo.VENDOR_INVOICE_ITEM_STATUS", [
    { no: 0, name: "VENDOR_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_INVOICE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "VENDOR_INVOICE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.VendorInvoicesServiceCreateRequest
 */
class VendorInvoicesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the vendor invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 15;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 16;
     */
    vendorBillDate = "";
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 20;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 21;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceCreateRequest, a, b);
    }
}
exports.VendorInvoicesServiceCreateRequest = VendorInvoicesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.VendorInvoicesServiceUpdateRequest
 */
class VendorInvoicesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the vendor invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 15;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 16;
     */
    vendorBillDate = "";
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 20;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 21;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceUpdateRequest, a, b);
    }
}
exports.VendorInvoicesServiceUpdateRequest = VendorInvoicesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.VendorInvoicesServiceAutofillRequest
 */
class VendorInvoicesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if services should also be autofilled
     *
     * @generated from field: bool include_services = 10;
     */
    includeServices = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "include_services", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceAutofillRequest, a, b);
    }
}
exports.VendorInvoicesServiceAutofillRequest = VendorInvoicesServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.VendorInvoice
 */
class VendorInvoice extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this vendor invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this vendor invoice
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this vendor invoice was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the vendor invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 15;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 16;
     */
    vendorBillDate = "";
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 20;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 21;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the vendor invoice (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 30;
     */
    totalValue = 0;
    /**
     * The list of associated vendor invoice items
     *
     * @generated from field: repeated Scailo.VendorInvoiceItem list = 40;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoice";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 40, name: "list", kind: "message", T: VendorInvoiceItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoice().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoice().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoice().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoice, a, b);
    }
}
exports.VendorInvoice = VendorInvoice;
/**
 *
 * Describes the parameters required to add an item to a vendor invoice
 *
 * @generated from message Scailo.VendorInvoicesServiceItemCreateRequest
 */
class VendorInvoicesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceItemCreateRequest, a, b);
    }
}
exports.VendorInvoicesServiceItemCreateRequest = VendorInvoicesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a vendor invoice
 *
 * @generated from message Scailo.VendorInvoicesServiceItemUpdateRequest
 */
class VendorInvoicesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceItemUpdateRequest, a, b);
    }
}
exports.VendorInvoicesServiceItemUpdateRequest = VendorInvoicesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a vendor invoice
 *
 * @generated from message Scailo.VendorInvoiceItem
 */
class VendorInvoiceItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 18;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceItem, a, b);
    }
}
exports.VendorInvoiceItem = VendorInvoiceItem;
/**
 *
 * Describes the message consisting of the list of vendor invoices
 *
 * @generated from message Scailo.VendorInvoicesList
 */
class VendorInvoicesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorInvoice list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesList, a, b);
    }
}
exports.VendorInvoicesList = VendorInvoicesList;
/**
 *
 * Describes the message consisting of the list of vendor invoice items
 *
 * @generated from message Scailo.VendorInvoiceItemsList
 */
class VendorInvoiceItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorInvoiceItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceItemsList, a, b);
    }
}
exports.VendorInvoiceItemsList = VendorInvoiceItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.VendorInvoiceItemHistoryRequest
 */
class VendorInvoiceItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceItemHistoryRequest, a, b);
    }
}
exports.VendorInvoiceItemHistoryRequest = VendorInvoiceItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective vendor invoice item
 *
 * @generated from message Scailo.VendorInvoiceItemProspectiveInfoRequest
 */
class VendorInvoiceItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceItemProspectiveInfoRequest, a, b);
    }
}
exports.VendorInvoiceItemProspectiveInfoRequest = VendorInvoiceItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest
 */
class VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest = VendorInvoicesServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.VendorInvoicesServicePaginationReq
 */
class VendorInvoicesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this vendor invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_INVOICE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServicePaginationReq, a, b);
    }
}
exports.VendorInvoicesServicePaginationReq = VendorInvoicesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.VendorInvoicesServicePaginationResponse
 */
class VendorInvoicesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorInvoice payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServicePaginationResponse, a, b);
    }
}
exports.VendorInvoicesServicePaginationResponse = VendorInvoicesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.VendorInvoicesServiceFilterReq
 */
class VendorInvoicesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the vendor invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 24;
     */
    vendorBillNo = "";
    /**
     * The minimum vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_start = 25;
     */
    vendorBillDateStart = "";
    /**
     * The maximum vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_end = 26;
     */
    vendorBillDateEnd = "";
    /**
     * The exact vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_exact = 27;
     */
    vendorBillDateExact = "";
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 30;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 31;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 32;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the vendor invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the vendor invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_INVOICE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "vendor_bill_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "vendor_bill_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "vendor_bill_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceFilterReq, a, b);
    }
}
exports.VendorInvoicesServiceFilterReq = VendorInvoicesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.VendorInvoicesServiceCountReq
 */
class VendorInvoicesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the vendor invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 24;
     */
    vendorBillNo = "";
    /**
     * The minimum vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_start = 25;
     */
    vendorBillDateStart = "";
    /**
     * The maximum vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_end = 26;
     */
    vendorBillDateEnd = "";
    /**
     * The exact vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_exact = 27;
     */
    vendorBillDateExact = "";
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 30;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 31;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 32;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the vendor invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the vendor invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "vendor_bill_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "vendor_bill_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "vendor_bill_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceCountReq, a, b);
    }
}
exports.VendorInvoicesServiceCountReq = VendorInvoicesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.VendorInvoicesServiceSearchAllReq
 */
class VendorInvoicesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 25;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_INVOICE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceSearchAllReq, a, b);
    }
}
exports.VendorInvoicesServiceSearchAllReq = VendorInvoicesServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a vendor invoice reference
 *
 * @generated from message Scailo.VendorInvoicesServiceReferenceCreateRequest
 */
class VendorInvoicesServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 11;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServiceReferenceCreateRequest, a, b);
    }
}
exports.VendorInvoicesServiceReferenceCreateRequest = VendorInvoicesServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a vendor invoice reference
 *
 * @generated from message Scailo.VendorInvoiceReference
 */
class VendorInvoiceReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor invoice ID
     *
     * @generated from field: uint64 vendor_invoice_id = 10;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 11;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceReference, a, b);
    }
}
exports.VendorInvoiceReference = VendorInvoiceReference;
/**
 *
 * Describes the message consisting of the list of vendor invoice references
 *
 * @generated from message Scailo.VendorInvoiceReferencesList
 */
class VendorInvoiceReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorInvoiceReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorInvoiceReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceReferencesList, a, b);
    }
}
exports.VendorInvoiceReferencesList = VendorInvoiceReferencesList;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.VendorInvoiceItemsSearchRequest
 */
class VendorInvoiceItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_INVOICE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_INVOICE_ITEM_SORT_KEY.VENDOR_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.VENDOR_INVOICE_ITEM_STATUS status = 7;
     */
    status = VENDOR_INVOICE_ITEM_STATUS.VENDOR_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor invoice
     *
     * @generated from field: uint64 vendor_invoice_id = 20;
     */
    vendorInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 23;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 26;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_INVOICE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_INVOICE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "vendor_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceItemsSearchRequest, a, b);
    }
}
exports.VendorInvoiceItemsSearchRequest = VendorInvoiceItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.VendorInvoicesServicePaginatedItemsResponse
 */
class VendorInvoicesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorInvoiceItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoicesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoicesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoicesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoicesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoicesServicePaginatedItemsResponse, a, b);
    }
}
exports.VendorInvoicesServicePaginatedItemsResponse = VendorInvoicesServicePaginatedItemsResponse;
/**
 *
 * Describes the receipt statistics of the vendor invoice
 *
 * @generated from message Scailo.VendorInvoiceReceiptStatistics
 */
class VendorInvoiceReceiptStatistics extends protobuf_1.Message {
    /**
     * Stores the ID of the family
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity
     *
     * @generated from field: uint64 invoiced_quantity = 2;
     */
    invoicedQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the receipt quantity
     *
     * @generated from field: uint64 receipt_quantity = 3;
     */
    receiptQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceReceiptStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "invoiced_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "receipt_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceReceiptStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceReceiptStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceReceiptStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceReceiptStatistics, a, b);
    }
}
exports.VendorInvoiceReceiptStatistics = VendorInvoiceReceiptStatistics;
/**
 *
 * Describes the list of receipt statistics of the vendor invoice
 *
 * @generated from message Scailo.VendorInvoiceReceiptStatisticsList
 */
class VendorInvoiceReceiptStatisticsList extends protobuf_1.Message {
    /**
     * @generated from field: repeated Scailo.VendorInvoiceReceiptStatistics list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorInvoiceReceiptStatisticsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorInvoiceReceiptStatistics, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorInvoiceReceiptStatisticsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorInvoiceReceiptStatisticsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorInvoiceReceiptStatisticsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorInvoiceReceiptStatisticsList, a, b);
    }
}
exports.VendorInvoiceReceiptStatisticsList = VendorInvoiceReceiptStatisticsList;
