"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file outward_jobs_free_issue_materials.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutwardJobsFreeIssueMaterialsService = void 0;
const outward_jobs_free_issue_materials_scailo_pb_js_1 = require("./outward_jobs_free_issue_materials.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each outward job free issue material
 *
 * @generated from service Scailo.OutwardJobsFreeIssueMaterialsService
 */
exports.OutwardJobsFreeIssueMaterialsService = {
    typeName: "Scailo.OutwardJobsFreeIssueMaterialsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Create
         */
        create: {
            name: "Create",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Draft
         */
        draft: {
            name: "Draft",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Outward Job Free Issue Material can be marked as completed
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.AddOutwardJobFreeIssueMaterialItem
         */
        addOutwardJobFreeIssueMaterialItem: {
            name: "AddOutwardJobFreeIssueMaterialItem",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ModifyOutwardJobFreeIssueMaterialItem
         */
        modifyOutwardJobFreeIssueMaterialItem: {
            name: "ModifyOutwardJobFreeIssueMaterialItem",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ApproveOutwardJobFreeIssueMaterialItem
         */
        approveOutwardJobFreeIssueMaterialItem: {
            name: "ApproveOutwardJobFreeIssueMaterialItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.DeleteOutwardJobFreeIssueMaterialItem
         */
        deleteOutwardJobFreeIssueMaterialItem: {
            name: "DeleteOutwardJobFreeIssueMaterialItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ReorderOutwardJobFreeIssueMaterialItems
         */
        reorderOutwardJobFreeIssueMaterialItems: {
            name: "ReorderOutwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Outward Job Free Issue Material Item by ID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewOutwardJobFreeIssueMaterialItemByID
         */
        viewOutwardJobFreeIssueMaterialItemByID: {
            name: "ViewOutwardJobFreeIssueMaterialItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Outward Job Free Issue Material Item by Inventory Hash
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewOutwardJobFreeIssueMaterialItemByInventoryHash
         */
        viewOutwardJobFreeIssueMaterialItemByInventoryHash: {
            name: "ViewOutwardJobFreeIssueMaterialItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job free issue material items for given outward job free issue material ID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewApprovedOutwardJobFreeIssueMaterialItems
         */
        viewApprovedOutwardJobFreeIssueMaterialItems: {
            name: "ViewApprovedOutwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job free issue material items for given outward job free issue material ID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewUnapprovedOutwardJobFreeIssueMaterialItems
         */
        viewUnapprovedOutwardJobFreeIssueMaterialItems: {
            name: "ViewUnapprovedOutwardJobFreeIssueMaterialItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the outward job free issue material item
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewOutwardJobFreeIssueMaterialItemHistory
         */
        viewOutwardJobFreeIssueMaterialItemHistory: {
            name: "ViewOutwardJobFreeIssueMaterialItemHistory",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItemHistoryRequest,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved outward job free issue material items for given outward job free issue material ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewPaginatedApprovedOutwardJobFreeIssueMaterialItems
         */
        viewPaginatedApprovedOutwardJobFreeIssueMaterialItems: {
            name: "ViewPaginatedApprovedOutwardJobFreeIssueMaterialItems",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItemsSearchRequest,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved outward job free issue material items for given outward job free issue material ID with pagination
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewPaginatedUnapprovedOutwardJobFreeIssueMaterialItems
         */
        viewPaginatedUnapprovedOutwardJobFreeIssueMaterialItems: {
            name: "ViewPaginatedUnapprovedOutwardJobFreeIssueMaterialItems",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItemsSearchRequest,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through outward job free issue material items with pagination
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItemsSearchRequest,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterial,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterial,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServicePaginationReq,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given outward job free issue material
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective outward job free issue material item info for the given family ID and outward job free issue material ID
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewProspectiveOutwardJobFreeIssueMaterialItem
         */
        viewProspectiveOutwardJobFreeIssueMaterialItem: {
            name: "ViewProspectiveOutwardJobFreeIssueMaterialItem",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobFreeIssueMaterialItemProspectiveInfoRequest,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download outward job free issue material with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceSearchAllReq,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Filter
         */
        filter: {
            name: "Filter",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceFilterReq,
            O: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.OutwardJobsFreeIssueMaterialsService.Count
         */
        count: {
            name: "Count",
            I: outward_jobs_free_issue_materials_scailo_pb_js_1.OutwardJobsFreeIssueMaterialsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
