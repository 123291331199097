"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file forms_sections.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsSectionsService = void 0;
const forms_sections_scailo_pb_js_1 = require("./forms_sections.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the methods applicable on each form section
 *
 * @generated from service Scailo.FormsSectionsService
 */
exports.FormsSectionsService = {
    typeName: "Scailo.FormsSectionsService",
    methods: {
        /**
         * Create a form section
         *
         * @generated from rpc Scailo.FormsSectionsService.Create
         */
        create: {
            name: "Create",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServiceCreateRequest,
            O: forms_sections_scailo_pb_js_1.FormSection,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update a form section
         *
         * @generated from rpc Scailo.FormsSectionsService.Update
         */
        update: {
            name: "Update",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServiceUpdateRequest,
            O: forms_sections_scailo_pb_js_1.FormSection,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard the form section
         *
         * @generated from rpc Scailo.FormsSectionsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore the form section
         *
         * @generated from rpc Scailo.FormsSectionsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.FormsSectionsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: forms_sections_scailo_pb_js_1.FormSection,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms sections
         *
         * @generated from rpc Scailo.FormsSectionsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: forms_sections_scailo_pb_js_1.FormsSectionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms sections with the given entity UUID
         *
         * @generated from rpc Scailo.FormsSectionsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: forms_sections_scailo_pb_js_1.FormsSectionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View forms sections with pagination
         *
         * @generated from rpc Scailo.FormsSectionsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServicePaginationReq,
            O: forms_sections_scailo_pb_js_1.FormSectionPaginationResp,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms sections that match the given search key
         *
         * @generated from rpc Scailo.FormsSectionsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServiceSearchAllReq,
            O: forms_sections_scailo_pb_js_1.FormsSectionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.FormsSectionsService.Filter
         */
        filter: {
            name: "Filter",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServiceFilterReq,
            O: forms_sections_scailo_pb_js_1.FormsSectionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.FormsSectionsService.Count
         */
        count: {
            name: "Count",
            I: forms_sections_scailo_pb_js_1.FormsSectionsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
