"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vendor_streams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorStreamVendorSubscribersList = exports.VendorStreamVendorSubscriber = exports.VendorStreamsServiceVendorSubscriberCreateRequest = exports.VendorStreamsServiceImportInternalSubscribersRequest = exports.VendorStreamInternalSubscribersList = exports.VendorStreamInternalSubscriber = exports.VendorStreamsServiceInternalSubscriberCreateRequest = exports.VendorStreamMessageReceiptsList = exports.VendorStreamMessageReceipt = exports.VendorStreamsServicePaginatedMessagesResponse = exports.VendorStreamMessagesSearchRequest = exports.VendorStreamMessagesList = exports.VendorStreamMessage = exports.VendorStreamsServiceMessageCreateRequest = exports.VendorStreamsServiceSearchAllReq = exports.VendorStreamsServiceCountReq = exports.VendorStreamsServiceFilterReq = exports.VendorStreamsServicePaginationResponse = exports.VendorStreamsServicePaginationReq = exports.VendorStreamsList = exports.VendorStream = exports.VendorStreamsServiceUpdateRequest = exports.VendorStreamsServiceCreateRequest = exports.LogbookLogVendorStreamLC = exports.VENDOR_STREAM_MESSAGE_SORT_KEY = exports.VENDOR_STREAM_MESSAGE_TYPE = exports.VENDOR_STREAM_SORT_KEY = exports.VENDOR_STREAM_REF_FROM = exports.VENDOR_STREAM_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each vendor stream
 *
 * @generated from enum Scailo.VENDOR_STREAM_LIFECYCLE
 */
var VENDOR_STREAM_LIFECYCLE;
(function (VENDOR_STREAM_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    VENDOR_STREAM_LIFECYCLE[VENDOR_STREAM_LIFECYCLE["VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the vendor stream is open
     *
     * @generated from enum value: VENDOR_STREAM_LIFECYCLE_OPEN = 1;
     */
    VENDOR_STREAM_LIFECYCLE[VENDOR_STREAM_LIFECYCLE["VENDOR_STREAM_LIFECYCLE_OPEN"] = 1] = "VENDOR_STREAM_LIFECYCLE_OPEN";
    /**
     * Denotes that the vendor stream has completed
     *
     * @generated from enum value: VENDOR_STREAM_LIFECYCLE_COMPLETED = 2;
     */
    VENDOR_STREAM_LIFECYCLE[VENDOR_STREAM_LIFECYCLE["VENDOR_STREAM_LIFECYCLE_COMPLETED"] = 2] = "VENDOR_STREAM_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the vendor stream has been cancelled
     *
     * @generated from enum value: VENDOR_STREAM_LIFECYCLE_CANCELLED = 3;
     */
    VENDOR_STREAM_LIFECYCLE[VENDOR_STREAM_LIFECYCLE["VENDOR_STREAM_LIFECYCLE_CANCELLED"] = 3] = "VENDOR_STREAM_LIFECYCLE_CANCELLED";
})(VENDOR_STREAM_LIFECYCLE || (exports.VENDOR_STREAM_LIFECYCLE = VENDOR_STREAM_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_STREAM_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(VENDOR_STREAM_LIFECYCLE, "Scailo.VENDOR_STREAM_LIFECYCLE", [
    { no: 0, name: "VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_STREAM_LIFECYCLE_OPEN" },
    { no: 2, name: "VENDOR_STREAM_LIFECYCLE_COMPLETED" },
    { no: 3, name: "VENDOR_STREAM_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available vendor stream references
 *
 * @generated from enum Scailo.VENDOR_STREAM_REF_FROM
 */
var VENDOR_STREAM_REF_FROM;
(function (VENDOR_STREAM_REF_FROM) {
    /**
     * Used only in filters
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED"] = 0] = "VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes that the reference is from a purchase order
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_PURCHASE_ORDER = 1;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_PURCHASE_ORDER"] = 1] = "VENDOR_STREAM_REF_FROM_PURCHASE_ORDER";
    /**
     * Denotes that the reference is from a goods receipt
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_GOODS_RECEIPT = 10;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_GOODS_RECEIPT"] = 10] = "VENDOR_STREAM_REF_FROM_GOODS_RECEIPT";
    /**
     * Denotes that the reference is from a vendor invoice
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_VENDOR_INVOICE = 11;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_VENDOR_INVOICE"] = 11] = "VENDOR_STREAM_REF_FROM_VENDOR_INVOICE";
    /**
     * Denotes that the reference is from a purchase return
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_PURCHASE_RETURN = 20;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_PURCHASE_RETURN"] = 20] = "VENDOR_STREAM_REF_FROM_PURCHASE_RETURN";
    /**
     * Denotes that the reference is from a debit note
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_DEBIT_NOTE = 21;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_DEBIT_NOTE"] = 21] = "VENDOR_STREAM_REF_FROM_DEBIT_NOTE";
    /**
     * Denotes that the reference is from a purchase payment
     *
     * @generated from enum value: VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT = 30;
     */
    VENDOR_STREAM_REF_FROM[VENDOR_STREAM_REF_FROM["VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT"] = 30] = "VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT";
})(VENDOR_STREAM_REF_FROM || (exports.VENDOR_STREAM_REF_FROM = VENDOR_STREAM_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_STREAM_REF_FROM)
protobuf_1.proto3.util.setEnumType(VENDOR_STREAM_REF_FROM, "Scailo.VENDOR_STREAM_REF_FROM", [
    { no: 0, name: "VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_STREAM_REF_FROM_PURCHASE_ORDER" },
    { no: 10, name: "VENDOR_STREAM_REF_FROM_GOODS_RECEIPT" },
    { no: 11, name: "VENDOR_STREAM_REF_FROM_VENDOR_INVOICE" },
    { no: 20, name: "VENDOR_STREAM_REF_FROM_PURCHASE_RETURN" },
    { no: 21, name: "VENDOR_STREAM_REF_FROM_DEBIT_NOTE" },
    { no: 30, name: "VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_STREAM_SORT_KEY
 */
var VENDOR_STREAM_SORT_KEY;
(function (VENDOR_STREAM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_STREAM_SORT_KEY[VENDOR_STREAM_SORT_KEY["VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VENDOR_STREAM_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_STREAM_SORT_KEY[VENDOR_STREAM_SORT_KEY["VENDOR_STREAM_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_STREAM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VENDOR_STREAM_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_STREAM_SORT_KEY[VENDOR_STREAM_SORT_KEY["VENDOR_STREAM_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_STREAM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: VENDOR_STREAM_SORT_KEY_COMPLETED_ON = 6;
     */
    VENDOR_STREAM_SORT_KEY[VENDOR_STREAM_SORT_KEY["VENDOR_STREAM_SORT_KEY_COMPLETED_ON"] = 6] = "VENDOR_STREAM_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: VENDOR_STREAM_SORT_KEY_TITLE = 10;
     */
    VENDOR_STREAM_SORT_KEY[VENDOR_STREAM_SORT_KEY["VENDOR_STREAM_SORT_KEY_TITLE"] = 10] = "VENDOR_STREAM_SORT_KEY_TITLE";
})(VENDOR_STREAM_SORT_KEY || (exports.VENDOR_STREAM_SORT_KEY = VENDOR_STREAM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_STREAM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_STREAM_SORT_KEY, "Scailo.VENDOR_STREAM_SORT_KEY", [
    { no: 0, name: "VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_STREAM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_STREAM_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "VENDOR_STREAM_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "VENDOR_STREAM_SORT_KEY_TITLE" },
]);
/**
 *
 * Describes the available message types for a vendor stream message
 *
 * @generated from enum Scailo.VENDOR_STREAM_MESSAGE_TYPE
 */
var VENDOR_STREAM_MESSAGE_TYPE;
(function (VENDOR_STREAM_MESSAGE_TYPE) {
    /**
     * Used only for filters
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED = 0;
     */
    VENDOR_STREAM_MESSAGE_TYPE[VENDOR_STREAM_MESSAGE_TYPE["VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED"] = 0] = "VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED";
    /**
     * Denotes that the message was sent by a user
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_TYPE_USER = 1;
     */
    VENDOR_STREAM_MESSAGE_TYPE[VENDOR_STREAM_MESSAGE_TYPE["VENDOR_STREAM_MESSAGE_TYPE_USER"] = 1] = "VENDOR_STREAM_MESSAGE_TYPE_USER";
    /**
     * Denotes that the message was sent by the system
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_TYPE_SYSTEM = 2;
     */
    VENDOR_STREAM_MESSAGE_TYPE[VENDOR_STREAM_MESSAGE_TYPE["VENDOR_STREAM_MESSAGE_TYPE_SYSTEM"] = 2] = "VENDOR_STREAM_MESSAGE_TYPE_SYSTEM";
})(VENDOR_STREAM_MESSAGE_TYPE || (exports.VENDOR_STREAM_MESSAGE_TYPE = VENDOR_STREAM_MESSAGE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_STREAM_MESSAGE_TYPE)
protobuf_1.proto3.util.setEnumType(VENDOR_STREAM_MESSAGE_TYPE, "Scailo.VENDOR_STREAM_MESSAGE_TYPE", [
    { no: 0, name: "VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "VENDOR_STREAM_MESSAGE_TYPE_USER" },
    { no: 2, name: "VENDOR_STREAM_MESSAGE_TYPE_SYSTEM" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VENDOR_STREAM_MESSAGE_SORT_KEY
 */
var VENDOR_STREAM_MESSAGE_SORT_KEY;
(function (VENDOR_STREAM_MESSAGE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VENDOR_STREAM_MESSAGE_SORT_KEY[VENDOR_STREAM_MESSAGE_SORT_KEY["VENDOR_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VENDOR_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_SORT_KEY_CREATED_AT = 1;
     */
    VENDOR_STREAM_MESSAGE_SORT_KEY[VENDOR_STREAM_MESSAGE_SORT_KEY["VENDOR_STREAM_MESSAGE_SORT_KEY_CREATED_AT"] = 1] = "VENDOR_STREAM_MESSAGE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VENDOR_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT = 2;
     */
    VENDOR_STREAM_MESSAGE_SORT_KEY[VENDOR_STREAM_MESSAGE_SORT_KEY["VENDOR_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT"] = 2] = "VENDOR_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT";
})(VENDOR_STREAM_MESSAGE_SORT_KEY || (exports.VENDOR_STREAM_MESSAGE_SORT_KEY = VENDOR_STREAM_MESSAGE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VENDOR_STREAM_MESSAGE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VENDOR_STREAM_MESSAGE_SORT_KEY, "Scailo.VENDOR_STREAM_MESSAGE_SORT_KEY", [
    { no: 0, name: "VENDOR_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VENDOR_STREAM_MESSAGE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VENDOR_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being an vendor stream lifecycle status
 *
 * @generated from message Scailo.LogbookLogVendorStreamLC
 */
class LogbookLogVendorStreamLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE operation = 11;
     */
    operation = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogVendorStreamLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogVendorStreamLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogVendorStreamLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogVendorStreamLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogVendorStreamLC, a, b);
    }
}
exports.LogbookLogVendorStreamLC = LogbookLogVendorStreamLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.VendorStreamsServiceCreateRequest
 */
class VendorStreamsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the vendor stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 11;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: Scailo.VENDOR_STREAM_REF_FROM ref_from = 12;
     */
    refFrom = VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The associated reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Assign self (the user creating the stream) as an internal subscriber
     *
     * @generated from field: bool assign_self_as_internal_subscriber = 80;
     */
    assignSelfAsInternalSubscriber = false;
    /**
     * Assign self (the user creating the stream) as a vendor subscriber (this would allow vendor users to also create a stream)
     *
     * @generated from field: bool assign_self_as_vendor_subscriber = 81;
     */
    assignSelfAsVendorSubscriber = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "assign_self_as_internal_subscriber", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 81, name: "assign_self_as_vendor_subscriber", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceCreateRequest, a, b);
    }
}
exports.VendorStreamsServiceCreateRequest = VendorStreamsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.VendorStreamsServiceUpdateRequest
 */
class VendorStreamsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the vendor stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceUpdateRequest, a, b);
    }
}
exports.VendorStreamsServiceUpdateRequest = VendorStreamsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.VendorStream
 */
class VendorStream extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this vendor stream
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE status = 4;
     */
    status = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this vendor stream
     *
     * @generated from field: repeated Scailo.LogbookLogVendorStreamLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this vendor stream was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder UUID (will be empty if vault_folder_id is 0)
     *
     * @generated from field: string vault_folder_uuid = 107;
     */
    vaultFolderUuid = "";
    /**
     * The title of the vendor stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 11;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: Scailo.VENDOR_STREAM_REF_FROM ref_from = 12;
     */
    refFrom = VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The associated reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference UUID
     *
     * @generated from field: string ref_uuid = 14;
     */
    refUuid = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores the number of unread messages in this stream for the specific user (on the basis of the auth token)
     *
     * @generated from field: uint64 unread_count = 60;
     */
    unreadCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of messages in the stream
     *
     * @generated from field: uint64 message_count = 61;
     */
    messageCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who added the last message
     *
     * @generated from field: string last_message_by = 62;
     */
    lastMessageBy = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStream";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogVendorStreamLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 107, name: "vault_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "unread_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "message_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "last_message_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStream().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStream().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStream().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStream, a, b);
    }
}
exports.VendorStream = VendorStream;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.VendorStreamsList
 */
class VendorStreamsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorStream list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsList, a, b);
    }
}
exports.VendorStreamsList = VendorStreamsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.VendorStreamsServicePaginationReq
 */
class VendorStreamsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this vendor stream
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE status = 6;
     */
    status = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServicePaginationReq, a, b);
    }
}
exports.VendorStreamsServicePaginationReq = VendorStreamsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.VendorStreamsServicePaginationResponse
 */
class VendorStreamsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorStream payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServicePaginationResponse, a, b);
    }
}
exports.VendorStreamsServicePaginationResponse = VendorStreamsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.VendorStreamsServiceFilterReq
 */
class VendorStreamsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor stream
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE status = 10;
     */
    status = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the vendor stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * ------------------------------------------------
     * Filter by the associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 40;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated reference
     *
     * @generated from field: Scailo.VENDOR_STREAM_REF_FROM ref_from = 41;
     */
    refFrom = VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Filter by the associated reference ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 ref_id = 42;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated vendor subscriber user ID
     *
     * @generated from field: uint64 vendor_subscriber_user_id = 61;
     */
    vendorSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_REF_FROM) },
        { no: 42, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "vendor_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceFilterReq, a, b);
    }
}
exports.VendorStreamsServiceFilterReq = VendorStreamsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.VendorStreamsServiceCountReq
 */
class VendorStreamsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this vendor stream
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE status = 10;
     */
    status = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the vendor stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * ------------------------------------------------
     * Filter by the associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 40;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated reference
     *
     * @generated from field: Scailo.VENDOR_STREAM_REF_FROM ref_from = 41;
     */
    refFrom = VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Filter by the associated reference ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 ref_id = 42;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated vendor subscriber user ID
     *
     * @generated from field: uint64 vendor_subscriber_user_id = 61;
     */
    vendorSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_REF_FROM) },
        { no: 42, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "vendor_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceCountReq, a, b);
    }
}
exports.VendorStreamsServiceCountReq = VendorStreamsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.VendorStreamsServiceSearchAllReq
 */
class VendorStreamsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.VENDOR_STREAM_LIFECYCLE status = 10;
     */
    status = VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * ------------------------------------------------
     * Filter by the associated vendor ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 vendor_id = 40;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated vendor subscriber user ID
     *
     * @generated from field: uint64 vendor_subscriber_user_id = 61;
     */
    vendorSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "vendor_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceSearchAllReq, a, b);
    }
}
exports.VendorStreamsServiceSearchAllReq = VendorStreamsServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add a message to an vendor stream
 *
 * @generated from message Scailo.VendorStreamsServiceMessageCreateRequest
 */
class VendorStreamsServiceMessageCreateRequest extends protobuf_1.Message {
    /**
     * The type of the message
     *
     * @generated from field: Scailo.VENDOR_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = VENDOR_STREAM_MESSAGE_TYPE.VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the vendor stream UUID
     *
     * @generated from field: string vendor_stream_uuid = 10;
     */
    vendorStreamUuid = "";
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceMessageCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "vendor_stream_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceMessageCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceMessageCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceMessageCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceMessageCreateRequest, a, b);
    }
}
exports.VendorStreamsServiceMessageCreateRequest = VendorStreamsServiceMessageCreateRequest;
/**
 *
 * Describes the parameters that constitute a message associated to an vendor stream
 *
 * @generated from message Scailo.VendorStreamMessage
 */
class VendorStreamMessage extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The type of the message
     *
     * @generated from field: Scailo.VENDOR_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = VENDOR_STREAM_MESSAGE_TYPE.VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the vendor stream ID
     *
     * @generated from field: uint64 vendor_stream_id = 10;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 60;
     */
    isRead = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamMessage";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamMessage().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamMessage().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamMessage().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamMessage, a, b);
    }
}
exports.VendorStreamMessage = VendorStreamMessage;
/**
 *
 * Describes the message consisting of the list of vendor stream messages
 *
 * @generated from message Scailo.VendorStreamMessagesList
 */
class VendorStreamMessagesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorStreamMessage list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamMessagesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamMessagesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamMessagesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamMessagesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamMessagesList, a, b);
    }
}
exports.VendorStreamMessagesList = VendorStreamMessagesList;
/**
 *
 * Describes the request payload to retrieve messages.
 *
 * @generated from message Scailo.VendorStreamMessagesSearchRequest
 */
class VendorStreamMessagesSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VENDOR_STREAM_MESSAGE_SORT_KEY sort_key = 5;
     */
    sortKey = VENDOR_STREAM_MESSAGE_SORT_KEY.VENDOR_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The type of the message
     *
     * @generated from field: Scailo.VENDOR_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = VENDOR_STREAM_MESSAGE_TYPE.VENDOR_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the vendor stream
     *
     * @generated from field: uint64 vendor_stream_id = 20;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 30;
     */
    responseToMessageUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamMessagesSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_MESSAGE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(VENDOR_STREAM_MESSAGE_TYPE) },
        { no: 20, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamMessagesSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamMessagesSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamMessagesSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamMessagesSearchRequest, a, b);
    }
}
exports.VendorStreamMessagesSearchRequest = VendorStreamMessagesSearchRequest;
/**
 *
 * Describes the response to a pagination messages request
 *
 * @generated from message Scailo.VendorStreamsServicePaginatedMessagesResponse
 */
class VendorStreamsServicePaginatedMessagesResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.VendorStreamMessage payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServicePaginatedMessagesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: VendorStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServicePaginatedMessagesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServicePaginatedMessagesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServicePaginatedMessagesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServicePaginatedMessagesResponse, a, b);
    }
}
exports.VendorStreamsServicePaginatedMessagesResponse = VendorStreamsServicePaginatedMessagesResponse;
/**
 *
 * Describes the parameters that constitute a message receipt
 *
 * @generated from message Scailo.VendorStreamMessageReceipt
 */
class VendorStreamMessageReceipt extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the vendor stream message UUID
     *
     * @generated from field: string vendor_stream_message_uuid = 10;
     */
    vendorStreamMessageUuid = "";
    /**
     * The ID of the user who read this
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 12;
     */
    isRead = false;
    /**
     * Stores the timestamp of when the message was read
     *
     * @generated from field: uint64 read_at = 13;
     */
    readAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamMessageReceipt";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "vendor_stream_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 13, name: "read_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamMessageReceipt().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamMessageReceipt().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamMessageReceipt().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamMessageReceipt, a, b);
    }
}
exports.VendorStreamMessageReceipt = VendorStreamMessageReceipt;
/**
 *
 * Describes the message consisting of the list of vendor stream message receipts
 *
 * @generated from message Scailo.VendorStreamMessageReceiptsList
 */
class VendorStreamMessageReceiptsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorStreamMessageReceipt list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamMessageReceiptsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorStreamMessageReceipt, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamMessageReceiptsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamMessageReceiptsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamMessageReceiptsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamMessageReceiptsList, a, b);
    }
}
exports.VendorStreamMessageReceiptsList = VendorStreamMessageReceiptsList;
/**
 *
 * Describes the parameters necessary to create an internal subscriber
 *
 * @generated from message Scailo.VendorStreamsServiceInternalSubscriberCreateRequest
 */
class VendorStreamsServiceInternalSubscriberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor stream ID
     *
     * @generated from field: uint64 vendor_stream_id = 10;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceInternalSubscriberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceInternalSubscriberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceInternalSubscriberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceInternalSubscriberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceInternalSubscriberCreateRequest, a, b);
    }
}
exports.VendorStreamsServiceInternalSubscriberCreateRequest = VendorStreamsServiceInternalSubscriberCreateRequest;
/**
 *
 * Describes the parameters that constitute an internal subscriber
 *
 * @generated from message Scailo.VendorStreamInternalSubscriber
 */
class VendorStreamInternalSubscriber extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor stream internal subscriber
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor stream ID
     *
     * @generated from field: uint64 vendor_stream_id = 10;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user UUID (determined by the user_id)
     *
     * @generated from field: string user_uuid = 30;
     */
    userUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamInternalSubscriber";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamInternalSubscriber().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamInternalSubscriber().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamInternalSubscriber().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamInternalSubscriber, a, b);
    }
}
exports.VendorStreamInternalSubscriber = VendorStreamInternalSubscriber;
/**
 *
 * Describes the message consisting of the list of internal subscribers
 *
 * @generated from message Scailo.VendorStreamInternalSubscribersList
 */
class VendorStreamInternalSubscribersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorStreamInternalSubscriber list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamInternalSubscribersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorStreamInternalSubscriber, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamInternalSubscribersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamInternalSubscribersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamInternalSubscribersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamInternalSubscribersList, a, b);
    }
}
exports.VendorStreamInternalSubscribersList = VendorStreamInternalSubscribersList;
/**
 *
 * Describes the data model to handle importing of internal subscribers from the given identifier representing a team or a department
 *
 * @generated from message Scailo.VendorStreamsServiceImportInternalSubscribersRequest
 */
class VendorStreamsServiceImportInternalSubscribersRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the vendor stream
     *
     * @generated from field: uint64 vendor_stream_id = 2;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team or the department from which internal subscribers need to be added to the vendor stream
     *
     * @generated from field: uint64 resource_id = 3;
     */
    resourceId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing internal subscribers in the vendor stream need to be deleted before adding from the source record
     *
     * @generated from field: bool delete_existing = 4;
     */
    deleteExisting = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceImportInternalSubscribersRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "resource_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceImportInternalSubscribersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceImportInternalSubscribersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceImportInternalSubscribersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceImportInternalSubscribersRequest, a, b);
    }
}
exports.VendorStreamsServiceImportInternalSubscribersRequest = VendorStreamsServiceImportInternalSubscribersRequest;
/**
 *
 * Describes the parameters necessary to create a vendor stream vendor subscriber
 *
 * @generated from message Scailo.VendorStreamsServiceVendorSubscriberCreateRequest
 */
class VendorStreamsServiceVendorSubscriberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the vendor stream ID
     *
     * @generated from field: uint64 vendor_stream_id = 10;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamsServiceVendorSubscriberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamsServiceVendorSubscriberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamsServiceVendorSubscriberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamsServiceVendorSubscriberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamsServiceVendorSubscriberCreateRequest, a, b);
    }
}
exports.VendorStreamsServiceVendorSubscriberCreateRequest = VendorStreamsServiceVendorSubscriberCreateRequest;
/**
 *
 * Describes the parameters that constitute a vendor stream vendor subscriber
 *
 * @generated from message Scailo.VendorStreamVendorSubscriber
 */
class VendorStreamVendorSubscriber extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this vendor stream vendor subscriber
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the vendor stream ID
     *
     * @generated from field: uint64 vendor_stream_id = 10;
     */
    vendorStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user UUID (determined by the user_id)
     *
     * @generated from field: string user_uuid = 30;
     */
    userUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamVendorSubscriber";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "vendor_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamVendorSubscriber().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamVendorSubscriber().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamVendorSubscriber().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamVendorSubscriber, a, b);
    }
}
exports.VendorStreamVendorSubscriber = VendorStreamVendorSubscriber;
/**
 *
 * Describes the message consisting of the list of vendor stream vendor subscribers
 *
 * @generated from message Scailo.VendorStreamVendorSubscribersList
 */
class VendorStreamVendorSubscribersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.VendorStreamVendorSubscriber list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorStreamVendorSubscribersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VendorStreamVendorSubscriber, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VendorStreamVendorSubscribersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorStreamVendorSubscribersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorStreamVendorSubscribersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorStreamVendorSubscribersList, a, b);
    }
}
exports.VendorStreamVendorSubscribersList = VendorStreamVendorSubscribersList;
