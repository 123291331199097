"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file expenses.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpensesService = void 0;
const expenses_scailo_pb_js_1 = require("./expenses.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each expense
 *
 * @generated from service Scailo.ExpensesService
 */
exports.ExpensesService = {
    typeName: "Scailo.ExpensesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ExpensesService.Create
         */
        create: {
            name: "Create",
            I: expenses_scailo_pb_js_1.ExpensesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ExpensesService.Draft
         */
        draft: {
            name: "Draft",
            I: expenses_scailo_pb_js_1.ExpensesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ExpensesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: expenses_scailo_pb_js_1.ExpensesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ExpensesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ExpensesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ExpensesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ExpensesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ExpensesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: expenses_scailo_pb_js_1.ExpensesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ExpensesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ExpensesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ExpensesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ExpensesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ExpensesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ExpensesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ExpensesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ExpensesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a expense
         *
         * @generated from rpc Scailo.ExpensesService.AddExpenseItem
         */
        addExpenseItem: {
            name: "AddExpenseItem",
            I: expenses_scailo_pb_js_1.ExpensesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a expense
         *
         * @generated from rpc Scailo.ExpensesService.ModifyExpenseItem
         */
        modifyExpenseItem: {
            name: "ModifyExpenseItem",
            I: expenses_scailo_pb_js_1.ExpensesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a expense
         *
         * @generated from rpc Scailo.ExpensesService.ApproveExpenseItem
         */
        approveExpenseItem: {
            name: "ApproveExpenseItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a expense
         *
         * @generated from rpc Scailo.ExpensesService.DeleteExpenseItem
         */
        deleteExpenseItem: {
            name: "DeleteExpenseItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a expense
         *
         * @generated from rpc Scailo.ExpensesService.ReorderExpenseItems
         */
        reorderExpenseItems: {
            name: "ReorderExpenseItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Expense Item by ID
         *
         * @generated from rpc Scailo.ExpensesService.ViewExpenseItemByID
         */
        viewExpenseItemByID: {
            name: "ViewExpenseItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: expenses_scailo_pb_js_1.ExpenseItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved expense items for given expense ID
         *
         * @generated from rpc Scailo.ExpensesService.ViewApprovedExpenseItems
         */
        viewApprovedExpenseItems: {
            name: "ViewApprovedExpenseItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: expenses_scailo_pb_js_1.ExpensesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved expense items for given expense ID
         *
         * @generated from rpc Scailo.ExpensesService.ViewUnapprovedExpenseItems
         */
        viewUnapprovedExpenseItems: {
            name: "ViewUnapprovedExpenseItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: expenses_scailo_pb_js_1.ExpensesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the expense item
         *
         * @generated from rpc Scailo.ExpensesService.ViewExpenseItemHistory
         */
        viewExpenseItemHistory: {
            name: "ViewExpenseItemHistory",
            I: expenses_scailo_pb_js_1.ExpenseItemHistoryRequest,
            O: expenses_scailo_pb_js_1.ExpensesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved expense items for given expense ID with pagination
         *
         * @generated from rpc Scailo.ExpensesService.ViewPaginatedApprovedExpenseItems
         */
        viewPaginatedApprovedExpenseItems: {
            name: "ViewPaginatedApprovedExpenseItems",
            I: expenses_scailo_pb_js_1.ExpenseItemsSearchRequest,
            O: expenses_scailo_pb_js_1.ExpensesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved expense items for given expense ID with pagination
         *
         * @generated from rpc Scailo.ExpensesService.ViewPaginatedUnapprovedExpenseItems
         */
        viewPaginatedUnapprovedExpenseItems: {
            name: "ViewPaginatedUnapprovedExpenseItems",
            I: expenses_scailo_pb_js_1.ExpenseItemsSearchRequest,
            O: expenses_scailo_pb_js_1.ExpensesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through expense items with pagination
         *
         * @generated from rpc Scailo.ExpensesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: expenses_scailo_pb_js_1.ExpenseItemsSearchRequest,
            O: expenses_scailo_pb_js_1.ExpensesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ExpensesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: expenses_scailo_pb_js_1.Expense,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ExpensesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: expenses_scailo_pb_js_1.ExpensesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ExpensesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: expenses_scailo_pb_js_1.ExpensesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ExpensesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: expenses_scailo_pb_js_1.ExpensesServicePaginationReq,
            O: expenses_scailo_pb_js_1.ExpensesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ExpensesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: expenses_scailo_pb_js_1.ExpensesServiceSearchAllReq,
            O: expenses_scailo_pb_js_1.ExpensesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ExpensesService.Filter
         */
        filter: {
            name: "Filter",
            I: expenses_scailo_pb_js_1.ExpensesServiceFilterReq,
            O: expenses_scailo_pb_js_1.ExpensesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ExpensesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.ExpensesService.Count
         */
        count: {
            name: "Count",
            I: expenses_scailo_pb_js_1.ExpensesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
