"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file vendor_streams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorStreamsService = void 0;
const vendor_streams_scailo_pb_js_1 = require("./vendor_streams.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each vendor stream
 *
 * @generated from service Scailo.VendorStreamsService
 */
exports.VendorStreamsService = {
    typeName: "Scailo.VendorStreamsService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.VendorStreamsService.Create
         */
        create: {
            name: "Create",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.VendorStreamsService.Update
         */
        update: {
            name: "Update",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.VendorStreamsService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.VendorStreamsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.VendorStreamsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.VendorStreamsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail(IdentifierWithEmailAttributes) returns (IdentifierUUID);
         *
         * @generated from rpc Scailo.VendorStreamsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a message to an vendor stream
         *
         * @generated from rpc Scailo.VendorStreamsService.AddMessage
         */
        addMessage: {
            name: "AddMessage",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceMessageCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Saves a message for viewing it later
         *
         * @generated from rpc Scailo.VendorStreamsService.SaveMessageForLater
         */
        saveMessageForLater: {
            name: "SaveMessageForLater",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a message in an vendor stream
         *
         * @generated from rpc Scailo.VendorStreamsService.DeleteMessage
         */
        deleteMessage: {
            name: "DeleteMessage",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Vendor Stream Message by UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewMessageByUUID
         */
        viewMessageByUUID: {
            name: "ViewMessageByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamMessage,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all messages for given vendor stream UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewMessages
         */
        viewMessages: {
            name: "ViewMessages",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamMessagesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View vendor stream messages for given vendor stream ID with pagination
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewPaginatedMessages
         */
        viewPaginatedMessages: {
            name: "ViewPaginatedMessages",
            I: vendor_streams_scailo_pb_js_1.VendorStreamMessagesSearchRequest,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsServicePaginatedMessagesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through vendor stream messages with pagination
         *
         * @generated from rpc Scailo.VendorStreamsService.SearchMessagesWithPagination
         */
        searchMessagesWithPagination: {
            name: "SearchMessagesWithPagination",
            I: vendor_streams_scailo_pb_js_1.VendorStreamMessagesSearchRequest,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsServicePaginatedMessagesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all receipts for the given message UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewMessageReceipts
         */
        viewMessageReceipts: {
            name: "ViewMessageReceipts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamMessageReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an internal subscriber
         *
         * @generated from rpc Scailo.VendorStreamsService.AddInternalSubscriber
         */
        addInternalSubscriber: {
            name: "AddInternalSubscriber",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceInternalSubscriberCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an internal subscriber
         *
         * @generated from rpc Scailo.VendorStreamsService.DeleteInternalSubscriber
         */
        deleteInternalSubscriber: {
            name: "DeleteInternalSubscriber",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View an internal subscriber for the given ID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewInternalSubscriberByID
         */
        viewInternalSubscriberByID: {
            name: "ViewInternalSubscriberByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_streams_scailo_pb_js_1.VendorStreamInternalSubscriber,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all internal subscribers for given vendor stream UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewInternalSubscribers
         */
        viewInternalSubscribers: {
            name: "ViewInternalSubscribers",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamInternalSubscribersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import users as internal subscribers from team and returns the ID of the vendor stream
         *
         * @generated from rpc Scailo.VendorStreamsService.ImportInternalSubscribersFromTeam
         */
        importInternalSubscribersFromTeam: {
            name: "ImportInternalSubscribersFromTeam",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceImportInternalSubscribersRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import users as internal subscribers from department and returns the ID of the vendor stream
         *
         * @generated from rpc Scailo.VendorStreamsService.ImportInternalSubscribersFromDepartment
         */
        importInternalSubscribersFromDepartment: {
            name: "ImportInternalSubscribersFromDepartment",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceImportInternalSubscribersRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a vendor subscriber
         *
         * @generated from rpc Scailo.VendorStreamsService.AddVendorSubscriber
         */
        addVendorSubscriber: {
            name: "AddVendorSubscriber",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceVendorSubscriberCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a vendor subscriber
         *
         * @generated from rpc Scailo.VendorStreamsService.DeleteVendorSubscriber
         */
        deleteVendorSubscriber: {
            name: "DeleteVendorSubscriber",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View vendor subscriber for the given ID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewVendorSubscriberByID
         */
        viewVendorSubscriberByID: {
            name: "ViewVendorSubscriberByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_streams_scailo_pb_js_1.VendorStreamVendorSubscriber,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all vendor subscribers for given vendor stream UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewVendorSubscribers
         */
        viewVendorSubscribers: {
            name: "ViewVendorSubscribers",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamVendorSubscribersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendor_streams_scailo_pb_js_1.VendorStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.VendorStreamsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServicePaginationReq,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.VendorStreamsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceSearchAllReq,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.VendorStreamsService.Filter
         */
        filter: {
            name: "Filter",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceFilterReq,
            O: vendor_streams_scailo_pb_js_1.VendorStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.VendorStreamsService.Count
         */
        count: {
            name: "Count",
            I: vendor_streams_scailo_pb_js_1.VendorStreamsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
