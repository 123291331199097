"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file sales_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesReturnsService = void 0;
const sales_returns_scailo_pb_js_1 = require("./sales_returns.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each sales return
 *
 * @generated from service Scailo.SalesReturnsService
 */
exports.SalesReturnsService = {
    typeName: "Scailo.SalesReturnsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalesReturnsService.Create
         */
        create: {
            name: "Create",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalesReturnsService.Draft
         */
        draft: {
            name: "Draft",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalesReturnsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalesReturnsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalesReturnsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalesReturnsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalesReturnsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalesReturnsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalesReturnsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalesReturnsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalesReturnsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalesReturnsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalesReturnsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalesReturnsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalesReturnsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalesReturnsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Sales Return can be marked as completed
         *
         * @generated from rpc Scailo.SalesReturnsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.AddSalesReturnItem
         */
        addSalesReturnItem: {
            name: "AddSalesReturnItem",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.ModifySalesReturnItem
         */
        modifySalesReturnItem: {
            name: "ModifySalesReturnItem",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.ApproveSalesReturnItem
         */
        approveSalesReturnItem: {
            name: "ApproveSalesReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.DeleteSalesReturnItem
         */
        deleteSalesReturnItem: {
            name: "DeleteSalesReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.ReorderSalesReturnItems
         */
        reorderSalesReturnItems: {
            name: "ReorderSalesReturnItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Return Item by ID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewSalesReturnItemByID
         */
        viewSalesReturnItemByID: {
            name: "ViewSalesReturnItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_returns_scailo_pb_js_1.SalesReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Return Item by Inventory Hash
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewSalesReturnItemByInventoryHash
         */
        viewSalesReturnItemByInventoryHash: {
            name: "ViewSalesReturnItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: sales_returns_scailo_pb_js_1.SalesReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales return items for given sales return ID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewApprovedSalesReturnItems
         */
        viewApprovedSalesReturnItems: {
            name: "ViewApprovedSalesReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_returns_scailo_pb_js_1.SalesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales return items for given sales return ID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewUnapprovedSalesReturnItems
         */
        viewUnapprovedSalesReturnItems: {
            name: "ViewUnapprovedSalesReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_returns_scailo_pb_js_1.SalesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the sales return item
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewSalesReturnItemHistory
         */
        viewSalesReturnItemHistory: {
            name: "ViewSalesReturnItemHistory",
            I: sales_returns_scailo_pb_js_1.SalesReturnItemHistoryRequest,
            O: sales_returns_scailo_pb_js_1.SalesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales return items for given sales return ID with pagination
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewPaginatedApprovedSalesReturnItems
         */
        viewPaginatedApprovedSalesReturnItems: {
            name: "ViewPaginatedApprovedSalesReturnItems",
            I: sales_returns_scailo_pb_js_1.SalesReturnItemsSearchRequest,
            O: sales_returns_scailo_pb_js_1.SalesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales return items for given sales return ID with pagination
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewPaginatedUnapprovedSalesReturnItems
         */
        viewPaginatedUnapprovedSalesReturnItems: {
            name: "ViewPaginatedUnapprovedSalesReturnItems",
            I: sales_returns_scailo_pb_js_1.SalesReturnItemsSearchRequest,
            O: sales_returns_scailo_pb_js_1.SalesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales return items with pagination
         *
         * @generated from rpc Scailo.SalesReturnsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: sales_returns_scailo_pb_js_1.SalesReturnItemsSearchRequest,
            O: sales_returns_scailo_pb_js_1.SalesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_returns_scailo_pb_js_1.SalesReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_returns_scailo_pb_js_1.SalesReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_returns_scailo_pb_js_1.SalesReturnAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: sales_returns_scailo_pb_js_1.SalesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_returns_scailo_pb_js_1.SalesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServicePaginationReq,
            O: sales_returns_scailo_pb_js_1.SalesReturnsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given sales return
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective sales return item info for the given family ID and sales return ID
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewProspectiveSalesReturnItem
         */
        viewProspectiveSalesReturnItem: {
            name: "ViewProspectiveSalesReturnItem",
            I: sales_returns_scailo_pb_js_1.SalesReturnItemProspectiveInfoRequest,
            O: sales_returns_scailo_pb_js_1.SalesReturnsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Sales Return has been billed
         *
         * @generated from rpc Scailo.SalesReturnsService.IsBilled
         */
        isBilled: {
            name: "IsBilled",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.SalesReturnsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download sales return with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.SalesReturnsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the sales return with the given IdentifierUUID
         *
         * @generated from rpc Scailo.SalesReturnsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalesReturnsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceSearchAllReq,
            O: sales_returns_scailo_pb_js_1.SalesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalesReturnsService.Filter
         */
        filter: {
            name: "Filter",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceFilterReq,
            O: sales_returns_scailo_pb_js_1.SalesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalesReturnsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalesReturnsService.Count
         */
        count: {
            name: "Count",
            I: sales_returns_scailo_pb_js_1.SalesReturnsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
