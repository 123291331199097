/**Stores the information of each menu item */
export interface MenuItem {
    menu_uid: string
    menu_name: string
    menu_icon: string
    href: string
    parent_menu: MenuItem | null
    /**Denotes if this value will be displayed on the UI */
    visibleOnUI: boolean
    /**Stores the list of URLs that will be affected when a notification with this menu_uid is received by the client */
    affected_urls: string[]
}

// Stores all the primary sidebar menus
export let sidebarMenusList: MenuItem[] = [];
// Map from menu_uid to the menu item of the sidebarMenusList
export const sidebarMenusMap: { [name: string]: MenuItem } = {};

// Stores all the vertical menus
export let verticalMenusList: MenuItem[] = [];
// Map from menu_uid to the menu item of the verticalMenusList
export const verticalMenusMap: { [name: string]: MenuItem } = {};

export let mainMenusClassifications: MenuItem[] = [];
export const adminMenu: MenuItem = {
    menu_uid: "Admin", menu_name: "Admin", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const ordersMenu: MenuItem = {
    menu_uid: "Orders", menu_name: "Orders", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const inventoryMenu: MenuItem = {
    menu_uid: "Inventory", menu_name: "Inventory", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const productionMenu: MenuItem = {
    menu_uid: "Production", menu_name: "Production", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
}
export const paymentsMenu: MenuItem = { 
    menu_uid: "Payments", menu_name: "Payments", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const quotationsMenu: MenuItem = {
    menu_uid: "Quotations", menu_name: "Quotations", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const qaMenu: MenuItem = {
    menu_uid: "QA", menu_name: "QA", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};

mainMenusClassifications.push(adminMenu);
mainMenusClassifications.push(ordersMenu);
mainMenusClassifications.push(inventoryMenu);
mainMenusClassifications.push(productionMenu);
mainMenusClassifications.push(paymentsMenu);
mainMenusClassifications.push(quotationsMenu);
mainMenusClassifications.push(qaMenu);

export let secondaryMenusList: MenuItem[] = [];
export let tertiaryMenusList: MenuItem[] = [];

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Admin
export const dashboardMenu: MenuItem = {
    menu_uid: "dashboard", menu_name: "Dashboard", menu_icon: "bx-home", href: "/ui/dash", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(dashboardMenu);

export const profileMenu: MenuItem = {
    menu_uid: "profile", menu_name: "Profile", menu_icon: "bx-user", href: "/ui/profile", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(profileMenu);

export const associatesMenu: MenuItem = {
    menu_uid: "associates", menu_name: "Associates", menu_icon: "bx-phone-call", href: "/ui/associates", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(associatesMenu);
export const associateFiltersMenu: MenuItem = {
    menu_uid: "associate-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/associates/filters", parent_menu: associatesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(associateFiltersMenu);

// Streams menus
export const streamsMenu: MenuItem = {
    menu_uid: "streams", menu_name: "Streams", menu_icon: "bx-message", href: "/ui/streams", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(streamsMenu);


export const streamOpenMenu: MenuItem = {
    menu_uid: "stream-open", menu_name: "Open", menu_icon: "bx-envelope-open", href: "/ui/streams/open", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(streamOpenMenu);

export const streamIndividualMenu: MenuItem = {
    menu_uid: "stream-individual", menu_name: "Individual", menu_icon: "bx-envelope-open", href: "/ui/streams/i/:uuid", parent_menu: streamsMenu, visibleOnUI: false, affected_urls: [],
}
tertiaryMenusList.push(streamIndividualMenu);

// export const streamCompletedMenu: MenuItem = {
//     menu_uid: "stream-completed", menu_name: "Completed", menu_icon: "bx-lock", href: "/ui/streams/completed", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
// }
// tertiaryMenusList.push(streamCompletedMenu);

// export const streamCancelledMenu: MenuItem = {
//     menu_uid: "stream-cancelled", menu_name: "Cancelled", menu_icon: "bx-window-close", href: "/ui/streams/cancelled", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
// }
// tertiaryMenusList.push(streamCancelledMenu);

export const streamFiltersMenu: MenuItem = {
    menu_uid: "stream-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/streams/filters", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(streamFiltersMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Orders
export const salesOrdersMenu: MenuItem = {
    menu_uid: "sales-orders", menu_name: "Sales Orders", menu_icon: "bx-purchase-tag", href: "/ui/sales-orders", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}
export const salesInvoicesMenu: MenuItem = {
    menu_uid: "sales-invoices", menu_name: "Sales Invoices", menu_icon: "bx-list-check", href: "/ui/sales-invoices", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}
export const inwardJobsMenu: MenuItem = {
    menu_uid: "inward-jobs", menu_name: "Inward Jobs", menu_icon: "bx-horizontal-right", href: "/ui/inward-jobs", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}
export const workOrdersMenu: MenuItem = {
    menu_uid: "work-orders", menu_name: "Work Orders", menu_icon: "bxs-package", href: "/ui/work-orders", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(salesOrdersMenu);
secondaryMenusList.push(salesInvoicesMenu);
secondaryMenusList.push(inwardJobsMenu);
secondaryMenusList.push(workOrdersMenu);


export const salesOrderFiltersMenu: MenuItem = {
    menu_uid: "sales-order-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/sales-orders/filters", parent_menu: salesOrdersMenu, visibleOnUI: true, affected_urls: [],
}
export const salesOrderInsightsMenu: MenuItem = {
    menu_uid: "sales-order-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/sales-orders/insights", parent_menu: salesOrdersMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(salesOrderFiltersMenu);
tertiaryMenusList.push(salesOrderInsightsMenu);

export const salesInvoiceFiltersMenu: MenuItem = {
    menu_uid: "sales-invoice-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/sales-invoices/filters", parent_menu: salesInvoicesMenu, visibleOnUI: true, affected_urls: [],
}
export const salesInvoiceInsightsMenu: MenuItem = {
    menu_uid: "sales-invoice-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/sales-invoices/insights", parent_menu: salesInvoicesMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(salesInvoiceFiltersMenu);
tertiaryMenusList.push(salesInvoiceInsightsMenu);

export const inwardJobFiltersMenu: MenuItem = {
    menu_uid: "inward-job-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/inward-jobs/filters", parent_menu: inwardJobsMenu, visibleOnUI: true, affected_urls: [],
}
export const inwardJobInsightsMenu: MenuItem = {
    menu_uid: "inward-job-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/inward-jobs/insights", parent_menu: inwardJobsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(inwardJobFiltersMenu);
tertiaryMenusList.push(inwardJobInsightsMenu);

export const workOrderFiltersMenu: MenuItem = {
    menu_uid: "work-order-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/work-orders/filters", parent_menu: workOrdersMenu, visibleOnUI: true, affected_urls: [],
}
export const workOrderInsightsMenu: MenuItem = {
    menu_uid: "work-order-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/work-orders/insights", parent_menu: workOrdersMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(workOrderFiltersMenu);
tertiaryMenusList.push(workOrderInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Inventory
export const goodsDispatchMenu: MenuItem = {
    menu_uid: "goods-dispatches", menu_name: "Goods Dispatches", menu_icon: "bx-receipt", href: "/ui/goods-dispatches", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const inwardJobFIM: MenuItem = {
    menu_uid: "inward-jobs-free-issue-materials", menu_name: "Free Issues", menu_icon: "bxs-send", href: "/ui/inward-jobs-free-issue-materials", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const inwardJobFIMR: MenuItem = {
    menu_uid: "inward-jobs-free-issue-materials-returns", menu_name: "Free Issue Returns", menu_icon: "bx-horizontal-left", href: "/ui/inward-jobs-free-issue-materials-returns", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const salesReturnMenu: MenuItem = {
    menu_uid: "sales-returns", menu_name: "Sales Returns", menu_icon: "bx-rotate-left", href: "/ui/sales-returns", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(goodsDispatchMenu);
secondaryMenusList.push(inwardJobFIM);
secondaryMenusList.push(inwardJobFIMR);
secondaryMenusList.push(salesReturnMenu);


export const goodsDispatchFiltersMenu: MenuItem = {
    menu_uid: "goods-dispatch-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/goods-dispatches/filters", parent_menu: goodsDispatchMenu, visibleOnUI: true, affected_urls: [],
}
export const goodsDispatchInsightsMenu: MenuItem = {
    menu_uid: "goods-dispatch-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/goods-dispatches/insights", parent_menu: goodsDispatchMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(goodsDispatchFiltersMenu);
tertiaryMenusList.push(goodsDispatchInsightsMenu);

export const inwardJobFIMFiltersMenu: MenuItem = {
    menu_uid: "inward-jobs-free-issue-material-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/inward-jobs-free-issue-materials/filters", parent_menu: inwardJobFIM, visibleOnUI: true, affected_urls: [],
}
export const inwardJobFIMInsightsMenu: MenuItem = {
    menu_uid: "inward-jobs-free-issue-material-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/inward-jobs-free-issue-materials/insights", parent_menu: inwardJobFIM, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(inwardJobFIMFiltersMenu);
tertiaryMenusList.push(inwardJobFIMInsightsMenu);


export const inwardJobFIMRFiltersMenu: MenuItem = {
    menu_uid: "inward-jobs-free-issue-materials-returns-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/inward-jobs-free-issue-materials-returns/filters", parent_menu: inwardJobFIMR, visibleOnUI: true, affected_urls: [],
}
export const inwardJobFIMRInsightsMenu: MenuItem = {
    menu_uid: "inward-jobs-free-issue-materials-returns-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/inward-jobs-free-issue-materials-returns/insights", parent_menu: inwardJobFIMR, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(inwardJobFIMRFiltersMenu);
tertiaryMenusList.push(inwardJobFIMRInsightsMenu);

export const salesReturnFiltersMenu: MenuItem = {
    menu_uid: "sales-returns-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/sales-returns/filters", parent_menu: salesReturnMenu, visibleOnUI: true, affected_urls: [],
}
export const salesReturnInsightsMenu: MenuItem = {
    menu_uid: "sales-returns-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/sales-returns/insights", parent_menu: salesReturnMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(salesReturnFiltersMenu);
tertiaryMenusList.push(salesReturnInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Production
export const productionPlansMenu: MenuItem = {
    menu_uid: "production-plans", menu_name: "Production Plans", menu_icon: "bxs-wrench", href: "/ui/production-plans", parent_menu: productionMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(productionPlansMenu);

export const productionPlanFiltersMenu: MenuItem = {
    menu_uid: "production-plan-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/production-plans/filters", parent_menu: productionPlansMenu, visibleOnUI: true, affected_urls: [],
}
export const productionPlanInsightsMenu: MenuItem = {
    menu_uid: "production-plan-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/production-plans/insights", parent_menu: productionPlansMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(productionPlanFiltersMenu);
tertiaryMenusList.push(productionPlanInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Payments

export const creditNotesMenu: MenuItem = {
    menu_uid: "credit-notes", menu_name: "Credit Notes", menu_icon: "bx-credit-card-front", href: "/ui/credit-notes", parent_menu: paymentsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(creditNotesMenu);

export const creditNoteFiltersMenu: MenuItem = {
    menu_uid: "credit-note-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/credit-notes/filters", parent_menu: creditNotesMenu, visibleOnUI: true, affected_urls: [],
}
export const creditNoteInsightsMenu: MenuItem = {
    menu_uid: "credit-note-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/credit-notes/insights", parent_menu: creditNotesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(creditNoteFiltersMenu);
tertiaryMenusList.push(creditNoteInsightsMenu);

export const salesReceiptsMenu: MenuItem = {
    menu_uid: "sales-receipts", menu_name: "Payments", menu_icon: "bx-money", href: "/ui/sales-receipts", parent_menu: paymentsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(salesReceiptsMenu);

export const salesReceiptFiltersMenu: MenuItem = {
    menu_uid: "sales-receipt-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/sales-receipts/filters", parent_menu: salesReceiptsMenu, visibleOnUI: true, affected_urls: [],
}
export const salesReceiptInsightsMenu: MenuItem = {
    menu_uid: "sales-receipt-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/sales-receipts/insights", parent_menu: salesReceiptsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(salesReceiptFiltersMenu);
tertiaryMenusList.push(salesReceiptInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Quotations

export const salesEnquiriesMenu: MenuItem = {
    menu_uid: "sales-enquiries", menu_name: "Sales Enquiries", menu_icon: "bx-question-mark", href: "/ui/sales-enquiries", parent_menu: quotationsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(salesEnquiriesMenu);

export const salesEnquiryFiltersMenu: MenuItem = {
    menu_uid: "sales-enquiry-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/sales-enquiries/filters", parent_menu: salesEnquiriesMenu, visibleOnUI: true, affected_urls: [],
}
// export const salesEnquiryInsightsMenu: MenuItem = {
//     menu_uid: "sales-enquiry-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/sales-enquiries/insights", parent_menu: salesEnquiriesMenu, visibleOnUI: true, affected_urls: [],
// }
tertiaryMenusList.push(salesEnquiryFiltersMenu);
// tertiaryMenusList.push(salesEnquiryInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// QA

export const qaSamplesMenu: MenuItem = {
    menu_uid: "qa-samples", menu_name: "QA Samples", menu_icon: "bx-test-tube", href: "/ui/qa-samples", parent_menu: qaMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(qaSamplesMenu);

export const qaSampleFiltersMenu: MenuItem = {
    menu_uid: "qa-sample-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/qa-samples/filters", parent_menu: qaSamplesMenu, visibleOnUI: true, affected_urls: [],
}
export const qaSampleInsightsMenu: MenuItem = {
    menu_uid: "qa-sample-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/qa-samples/insights", parent_menu: qaSamplesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(qaSampleFiltersMenu);
tertiaryMenusList.push(qaSampleInsightsMenu);