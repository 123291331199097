"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file tax_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxGroupItemHistoryRequest = exports.TaxGroupsItemsList = exports.TaxGroupItem = exports.TaxGroupsServiceItemUpdateRequest = exports.TaxGroupsServiceItemCreateRequest = exports.TaxGroupsServiceSearchAllReq = exports.TaxGroupsServiceCountReq = exports.TaxGroupsServiceFilterReq = exports.TaxGroupsServicePaginationResponse = exports.TaxGroupsServicePaginationReq = exports.TaxGroupsList = exports.TaxGroup = exports.TaxGroupsServiceUpdateRequest = exports.TaxGroupsServiceCreateRequest = exports.TAX_GROUP_SORT_KEY = exports.TAX_GROUP_CATEGORY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const tax_params_scailo_pb_js_1 = require("./tax_params.scailo_pb.js");
/**
 *
 * Describes the available categories for a tax group
 *
 * @generated from enum Scailo.TAX_GROUP_CATEGORY
 */
var TAX_GROUP_CATEGORY;
(function (TAX_GROUP_CATEGORY) {
    /**
     * The default category, and is useful only for filter and search queries when the category needs to be disregarded
     *
     * @generated from enum value: TAX_GROUP_CATEGORY_ANY_UNSPECIFIED = 0;
     */
    TAX_GROUP_CATEGORY[TAX_GROUP_CATEGORY["TAX_GROUP_CATEGORY_ANY_UNSPECIFIED"] = 0] = "TAX_GROUP_CATEGORY_ANY_UNSPECIFIED";
    /**
     * Denotes that the tax group belongs to the general category (applicable on purchase and sales)
     *
     * @generated from enum value: TAX_GROUP_CATEGORY_GENERAL = 1;
     */
    TAX_GROUP_CATEGORY[TAX_GROUP_CATEGORY["TAX_GROUP_CATEGORY_GENERAL"] = 1] = "TAX_GROUP_CATEGORY_GENERAL";
    /**
     * Denotes that the tax group belongs to the payroll category (applicable when computing payroll)
     *
     * @generated from enum value: TAX_GROUP_CATEGORY_PAYROLL = 2;
     */
    TAX_GROUP_CATEGORY[TAX_GROUP_CATEGORY["TAX_GROUP_CATEGORY_PAYROLL"] = 2] = "TAX_GROUP_CATEGORY_PAYROLL";
    /**
     * Denotes that the tax group belongs to the cumulative excess on goods category (applicable when calculating the excess tax on goods)
     *
     * @generated from enum value: TAX_GROUP_CATEGORY_CUMULATIVE_EXCESS_ON_GOODS = 3;
     */
    TAX_GROUP_CATEGORY[TAX_GROUP_CATEGORY["TAX_GROUP_CATEGORY_CUMULATIVE_EXCESS_ON_GOODS"] = 3] = "TAX_GROUP_CATEGORY_CUMULATIVE_EXCESS_ON_GOODS";
})(TAX_GROUP_CATEGORY || (exports.TAX_GROUP_CATEGORY = TAX_GROUP_CATEGORY = {}));
// Retrieve enum metadata with: proto3.getEnumType(TAX_GROUP_CATEGORY)
protobuf_1.proto3.util.setEnumType(TAX_GROUP_CATEGORY, "Scailo.TAX_GROUP_CATEGORY", [
    { no: 0, name: "TAX_GROUP_CATEGORY_ANY_UNSPECIFIED" },
    { no: 1, name: "TAX_GROUP_CATEGORY_GENERAL" },
    { no: 2, name: "TAX_GROUP_CATEGORY_PAYROLL" },
    { no: 3, name: "TAX_GROUP_CATEGORY_CUMULATIVE_EXCESS_ON_GOODS" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.TAX_GROUP_SORT_KEY
 */
var TAX_GROUP_SORT_KEY;
(function (TAX_GROUP_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_ID_UNSPECIFIED"] = 0] = "TAX_GROUP_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_CREATED_AT = 1;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_CREATED_AT"] = 1] = "TAX_GROUP_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_MODIFIED_AT = 2;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_MODIFIED_AT"] = 2] = "TAX_GROUP_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_APPROVED_ON = 3;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_APPROVED_ON"] = 3] = "TAX_GROUP_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_APPROVED_BY = 4;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_APPROVED_BY"] = 4] = "TAX_GROUP_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "TAX_GROUP_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: TAX_GROUP_SORT_KEY_NAME = 10;
     */
    TAX_GROUP_SORT_KEY[TAX_GROUP_SORT_KEY["TAX_GROUP_SORT_KEY_NAME"] = 10] = "TAX_GROUP_SORT_KEY_NAME";
})(TAX_GROUP_SORT_KEY || (exports.TAX_GROUP_SORT_KEY = TAX_GROUP_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(TAX_GROUP_SORT_KEY)
protobuf_1.proto3.util.setEnumType(TAX_GROUP_SORT_KEY, "Scailo.TAX_GROUP_SORT_KEY", [
    { no: 0, name: "TAX_GROUP_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "TAX_GROUP_SORT_KEY_CREATED_AT" },
    { no: 2, name: "TAX_GROUP_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "TAX_GROUP_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "TAX_GROUP_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "TAX_GROUP_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "TAX_GROUP_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the groups necessary to create a record
 *
 * @generated from message Scailo.TaxGroupsServiceCreateRequest
 */
class TaxGroupsServiceCreateRequest extends protobuf_1.Message {
    /**
     * TaxGroups a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * TaxGroups any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the tax group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The category of the tax group
     *
     * @generated from field: Scailo.TAX_GROUP_CATEGORY category = 11;
     */
    category = TAX_GROUP_CATEGORY.TAX_GROUP_CATEGORY_ANY_UNSPECIFIED;
    /**
     * The description of the tax group
     *
     * @generated from field: string description = 12;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_CATEGORY) },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceCreateRequest, a, b);
    }
}
exports.TaxGroupsServiceCreateRequest = TaxGroupsServiceCreateRequest;
/**
 *
 * Describes the groups necessary to update a record
 *
 * @generated from message Scailo.TaxGroupsServiceUpdateRequest
 */
class TaxGroupsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * TaxGroups any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that denotes if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The name of the tax group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The description of the tax group
     *
     * @generated from field: string description = 12;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceUpdateRequest, a, b);
    }
}
exports.TaxGroupsServiceUpdateRequest = TaxGroupsServiceUpdateRequest;
/**
 *
 * Describes the groups that are part of a standard response
 *
 * @generated from message Scailo.TaxGroup
 */
class TaxGroup extends protobuf_1.Message {
    /**
     * TaxGroups a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * TaxGroups the metadata of this tax group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * TaxGroups the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this tax group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * TaxGroups the logs of every operation performed on this tax group
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The name of the tax group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The category of the tax group
     *
     * @generated from field: Scailo.TAX_GROUP_CATEGORY category = 11;
     */
    category = TAX_GROUP_CATEGORY.TAX_GROUP_CATEGORY_ANY_UNSPECIFIED;
    /**
     * The description of the tax group
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * The list of associated tax group items
     *
     * @generated from field: repeated Scailo.TaxGroupItem list = 30;
     */
    list = [];
    /**
     * The list of associated tax parameters
     *
     * @generated from field: repeated Scailo.TaxParam tax_params = 50;
     */
    taxParams = [];
    /**
     * The cumulative tax percentage
     *
     * @generated from field: double cumulative_tax_percentage = 60;
     */
    cumulativeTaxPercentage = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroup";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_CATEGORY) },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "list", kind: "message", T: TaxGroupItem, repeated: true },
        { no: 50, name: "tax_params", kind: "message", T: tax_params_scailo_pb_js_1.TaxParam, repeated: true },
        { no: 60, name: "cumulative_tax_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroup().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroup().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroup().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroup, a, b);
    }
}
exports.TaxGroup = TaxGroup;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.TaxGroupsList
 */
class TaxGroupsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.TaxGroup list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: TaxGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsList, a, b);
    }
}
exports.TaxGroupsList = TaxGroupsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.TaxGroupsServicePaginationReq
 */
class TaxGroupsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TAX_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = TAX_GROUP_SORT_KEY.TAX_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this tax group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServicePaginationReq, a, b);
    }
}
exports.TaxGroupsServicePaginationReq = TaxGroupsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.TaxGroupsServicePaginationResponse
 */
class TaxGroupsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.TaxGroup payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: TaxGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServicePaginationResponse, a, b);
    }
}
exports.TaxGroupsServicePaginationResponse = TaxGroupsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.TaxGroupsServiceFilterReq
 */
class TaxGroupsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TAX_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = TAX_GROUP_SORT_KEY.TAX_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this tax group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the tax group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The category of the tax group
     *
     * @generated from field: Scailo.TAX_GROUP_CATEGORY category = 32;
     */
    category = TAX_GROUP_CATEGORY.TAX_GROUP_CATEGORY_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_CATEGORY) },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceFilterReq, a, b);
    }
}
exports.TaxGroupsServiceFilterReq = TaxGroupsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.TaxGroupsServiceCountReq
 */
class TaxGroupsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this tax group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the tax group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The category of the tax group
     *
     * @generated from field: Scailo.TAX_GROUP_CATEGORY category = 32;
     */
    category = TAX_GROUP_CATEGORY.TAX_GROUP_CATEGORY_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_CATEGORY) },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceCountReq, a, b);
    }
}
exports.TaxGroupsServiceCountReq = TaxGroupsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.TaxGroupsServiceSearchAllReq
 */
class TaxGroupsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TAX_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = TAX_GROUP_SORT_KEY.TAX_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The category of the tax group
     *
     * @generated from field: Scailo.TAX_GROUP_CATEGORY category = 32;
     */
    category = TAX_GROUP_CATEGORY.TAX_GROUP_CATEGORY_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 32, name: "category", kind: "enum", T: protobuf_1.proto3.getEnumType(TAX_GROUP_CATEGORY) },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceSearchAllReq, a, b);
    }
}
exports.TaxGroupsServiceSearchAllReq = TaxGroupsServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add a param to a tax group
 *
 * @generated from message Scailo.TaxGroupsServiceItemCreateRequest
 */
class TaxGroupsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 10;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax param that is a part of the tax group
     *
     * @generated from field: uint64 tax_param_id = 11;
     */
    taxParamId = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "tax_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceItemCreateRequest, a, b);
    }
}
exports.TaxGroupsServiceItemCreateRequest = TaxGroupsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update a param in a tax group
 *
 * @generated from message Scailo.TaxGroupsServiceItemUpdateRequest
 */
class TaxGroupsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsServiceItemUpdateRequest, a, b);
    }
}
exports.TaxGroupsServiceItemUpdateRequest = TaxGroupsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute a param associated to a tax group
 *
 * @generated from message Scailo.TaxGroupItem
 */
class TaxGroupItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this tax group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 10;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax param that is a part of the tax group
     *
     * @generated from field: uint64 tax_param_id = 11;
     */
    taxParamId = protobuf_1.protoInt64.zero;
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "tax_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupItem, a, b);
    }
}
exports.TaxGroupItem = TaxGroupItem;
/**
 *
 * Describes the message consisting of the list of tax group params
 *
 * @generated from message Scailo.TaxGroupsItemsList
 */
class TaxGroupsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.TaxGroupItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: TaxGroupItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupsItemsList, a, b);
    }
}
exports.TaxGroupsItemsList = TaxGroupsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.TaxGroupItemHistoryRequest
 */
class TaxGroupItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 10;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax param that is a part of the tax group
     *
     * @generated from field: uint64 tax_param_id = 11;
     */
    taxParamId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TaxGroupItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "tax_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TaxGroupItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TaxGroupItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TaxGroupItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TaxGroupItemHistoryRequest, a, b);
    }
}
exports.TaxGroupItemHistoryRequest = TaxGroupItemHistoryRequest;
