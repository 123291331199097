import { showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { BOOL_FILTER, SORT_ORDER, CLIENT_STREAM_LIFECYCLE, CLIENT_STREAM_SORT_KEY, ClientStreamsServiceCountReq, ClientStreamsServiceFilterReq } from "@kernelminds/scailo-sdk";
import { getUsersServiceClient, getClientStreamsServiceClient } from "../../clients";
import { viewClientStreams } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";

export async function handleStreamOpen(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let client = getClientStreamsServiceClient();
    let usersClient = getUsersServiceClient();

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded";
    tableContainer.appendChild(tableDiv);

    let userInfo = await usersClient.viewByUsername({ searchKey: localStorage.getItem("username") || "" });

    let [filterResp] = await Promise.all([
        client.filter(new ClientStreamsServiceFilterReq({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: CLIENT_STREAM_SORT_KEY.CLIENT_STREAM_SORT_KEY_MODIFIED_AT,
            status: CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_OPEN,
            clientSubscriberUserId: userInfo.metadata?.id,
        }))
    ]);

    const filterRecords = filterResp.list;

    if (!filterRecords.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const recordsDiv = await viewClientStreams(filterRecords, "Open Streams");
    tableDiv.appendChild(recordsDiv.table);
}