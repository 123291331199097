import { salesInvoiceFiltersMenu, salesInvoiceInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleSalesInvoiceFilters } from "./salesinvoices.filters";
import { handleSalesInvoiceInsights } from "./salesinvoices.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesInvoiceFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesInvoiceFiltersMenu)) {
            handleSalesInvoiceFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesInvoiceInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesInvoiceInsightsMenu)) {
            handleSalesInvoiceInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}