"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file teams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamsServicePaginatedMembersResponse = exports.TeamMembersSearchRequest = exports.TeamsServiceSearchAllReq = exports.TeamsServiceCountReq = exports.TeamsServiceFilterReq = exports.TeamsServicePaginationResponse = exports.TeamsServicePaginationReq = exports.TeamMemberHistoryRequest = exports.TeamsMembersList = exports.TeamsList = exports.TeamMember = exports.TeamsServiceMemberUpdateRequest = exports.TeamsServiceMemberCreateRequest = exports.Team = exports.TeamsServiceUpdateRequest = exports.TeamsServiceCreateRequest = exports.TEAM_MEMBER_STATUS = exports.TEAM_MEMBER_SORT_KEY = exports.TEAM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.TEAM_SORT_KEY
 */
var TEAM_SORT_KEY;
(function (TEAM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: TEAM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "TEAM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: TEAM_SORT_KEY_CREATED_AT = 1;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_CREATED_AT"] = 1] = "TEAM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: TEAM_SORT_KEY_MODIFIED_AT = 2;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_MODIFIED_AT"] = 2] = "TEAM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: TEAM_SORT_KEY_APPROVED_ON = 3;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_APPROVED_ON"] = 3] = "TEAM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: TEAM_SORT_KEY_APPROVED_BY = 4;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_APPROVED_BY"] = 4] = "TEAM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: TEAM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "TEAM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: TEAM_SORT_KEY_COMPLETED_ON = 6;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_COMPLETED_ON"] = 6] = "TEAM_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: TEAM_SORT_KEY_NAME = 10;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_NAME"] = 10] = "TEAM_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: TEAM_SORT_KEY_CODE = 11;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_CODE"] = 11] = "TEAM_SORT_KEY_CODE";
    /**
     * Fetch ordered results by the leader ID
     *
     * @generated from enum value: TEAM_SORT_KEY_LEAD_USER_ID = 12;
     */
    TEAM_SORT_KEY[TEAM_SORT_KEY["TEAM_SORT_KEY_LEAD_USER_ID"] = 12] = "TEAM_SORT_KEY_LEAD_USER_ID";
})(TEAM_SORT_KEY || (exports.TEAM_SORT_KEY = TEAM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(TEAM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(TEAM_SORT_KEY, "Scailo.TEAM_SORT_KEY", [
    { no: 0, name: "TEAM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "TEAM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "TEAM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "TEAM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "TEAM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "TEAM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "TEAM_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "TEAM_SORT_KEY_NAME" },
    { no: 11, name: "TEAM_SORT_KEY_CODE" },
    { no: 12, name: "TEAM_SORT_KEY_LEAD_USER_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.TEAM_MEMBER_SORT_KEY
 */
var TEAM_MEMBER_SORT_KEY;
(function (TEAM_MEMBER_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "TEAM_MEMBER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_CREATED_AT = 1;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_CREATED_AT"] = 1] = "TEAM_MEMBER_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_MODIFIED_AT = 2;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_MODIFIED_AT"] = 2] = "TEAM_MEMBER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_APPROVED_ON = 3;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_APPROVED_ON"] = 3] = "TEAM_MEMBER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_APPROVED_BY = 4;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_APPROVED_BY"] = 4] = "TEAM_MEMBER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "TEAM_MEMBER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the team ID
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_TEAM_ID = 10;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_TEAM_ID"] = 10] = "TEAM_MEMBER_SORT_KEY_TEAM_ID";
    /**
     * Fetch invoiced results by the member ID
     *
     * @generated from enum value: TEAM_MEMBER_SORT_KEY_MEMBER_ID = 11;
     */
    TEAM_MEMBER_SORT_KEY[TEAM_MEMBER_SORT_KEY["TEAM_MEMBER_SORT_KEY_MEMBER_ID"] = 11] = "TEAM_MEMBER_SORT_KEY_MEMBER_ID";
})(TEAM_MEMBER_SORT_KEY || (exports.TEAM_MEMBER_SORT_KEY = TEAM_MEMBER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(TEAM_MEMBER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(TEAM_MEMBER_SORT_KEY, "Scailo.TEAM_MEMBER_SORT_KEY", [
    { no: 0, name: "TEAM_MEMBER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "TEAM_MEMBER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "TEAM_MEMBER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "TEAM_MEMBER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "TEAM_MEMBER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "TEAM_MEMBER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "TEAM_MEMBER_SORT_KEY_TEAM_ID" },
    { no: 11, name: "TEAM_MEMBER_SORT_KEY_MEMBER_ID" },
]);
/**
 *
 * Describes the applicable statuses of team members
 *
 * @generated from enum Scailo.TEAM_MEMBER_STATUS
 */
var TEAM_MEMBER_STATUS;
(function (TEAM_MEMBER_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: TEAM_MEMBER_STATUS_ANY_UNSPECIFIED = 0;
     */
    TEAM_MEMBER_STATUS[TEAM_MEMBER_STATUS["TEAM_MEMBER_STATUS_ANY_UNSPECIFIED"] = 0] = "TEAM_MEMBER_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the team members must have been approved
     *
     * @generated from enum value: TEAM_MEMBER_STATUS_APPROVED = 1;
     */
    TEAM_MEMBER_STATUS[TEAM_MEMBER_STATUS["TEAM_MEMBER_STATUS_APPROVED"] = 1] = "TEAM_MEMBER_STATUS_APPROVED";
    /**
     * Denotes that the team members must be waiting for approval
     *
     * @generated from enum value: TEAM_MEMBER_STATUS_UNAPPROVED = 2;
     */
    TEAM_MEMBER_STATUS[TEAM_MEMBER_STATUS["TEAM_MEMBER_STATUS_UNAPPROVED"] = 2] = "TEAM_MEMBER_STATUS_UNAPPROVED";
})(TEAM_MEMBER_STATUS || (exports.TEAM_MEMBER_STATUS = TEAM_MEMBER_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(TEAM_MEMBER_STATUS)
protobuf_1.proto3.util.setEnumType(TEAM_MEMBER_STATUS, "Scailo.TEAM_MEMBER_STATUS", [
    { no: 0, name: "TEAM_MEMBER_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "TEAM_MEMBER_STATUS_APPROVED" },
    { no: 2, name: "TEAM_MEMBER_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.TeamsServiceCreateRequest
 */
class TeamsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the team
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the team
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the user who is the team lead of this team
     *
     * @generated from field: uint64 lead_user_id = 12;
     */
    leadUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the team
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "lead_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceCreateRequest, a, b);
    }
}
exports.TeamsServiceCreateRequest = TeamsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.TeamsServiceUpdateRequest
 */
class TeamsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the team
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the team
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the user who is the team lead of this team
     *
     * @generated from field: uint64 lead_user_id = 12;
     */
    leadUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the team
     *
     * @generated from field: string description = 13;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "lead_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceUpdateRequest, a, b);
    }
}
exports.TeamsServiceUpdateRequest = TeamsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Team
 */
class Team extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this team
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this team
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this team
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this team was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the team
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the team
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the user who is the team lead of this team
     *
     * @generated from field: uint64 lead_user_id = 12;
     */
    leadUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the team
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of associated team members
     *
     * @generated from field: repeated Scailo.TeamMember list = 20;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Team";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "lead_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: TeamMember, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Team().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Team().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Team().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Team, a, b);
    }
}
exports.Team = Team;
/**
 *
 * Describes the parameters required to add a member to a team
 *
 * @generated from message Scailo.TeamsServiceMemberCreateRequest
 */
class TeamsServiceMemberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the team
     *
     * @generated from field: uint64 team_id = 10;
     */
    teamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user that is part of the team
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceMemberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "team_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceMemberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceMemberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceMemberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceMemberCreateRequest, a, b);
    }
}
exports.TeamsServiceMemberCreateRequest = TeamsServiceMemberCreateRequest;
/**
 *
 * Describes the parameters required to update a member in a team
 *
 * @generated from message Scailo.TeamsServiceMemberUpdateRequest
 */
class TeamsServiceMemberUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceMemberUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceMemberUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceMemberUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceMemberUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceMemberUpdateRequest, a, b);
    }
}
exports.TeamsServiceMemberUpdateRequest = TeamsServiceMemberUpdateRequest;
/**
 *
 * Describes the parameters that constitute a member associated to a team
 *
 * @generated from message Scailo.TeamMember
 */
class TeamMember extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this team
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the team
     *
     * @generated from field: uint64 team_id = 10;
     */
    teamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user that is part of the team
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamMember";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "team_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamMember().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamMember().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamMember().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamMember, a, b);
    }
}
exports.TeamMember = TeamMember;
/**
 *
 * Describes the message consisting of the list of teams
 *
 * @generated from message Scailo.TeamsList
 */
class TeamsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Team list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Team, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsList, a, b);
    }
}
exports.TeamsList = TeamsList;
/**
 *
 * Describes the message consisting of the list of team members
 *
 * @generated from message Scailo.TeamsMembersList
 */
class TeamsMembersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.TeamMember list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsMembersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: TeamMember, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsMembersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsMembersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsMembersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsMembersList, a, b);
    }
}
exports.TeamsMembersList = TeamsMembersList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.TeamMemberHistoryRequest
 */
class TeamMemberHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the team
     *
     * @generated from field: uint64 team_id = 10;
     */
    teamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user that is part of the team
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamMemberHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "team_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamMemberHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamMemberHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamMemberHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamMemberHistoryRequest, a, b);
    }
}
exports.TeamMemberHistoryRequest = TeamMemberHistoryRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.TeamsServicePaginationReq
 */
class TeamsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TEAM_SORT_KEY sort_key = 5;
     */
    sortKey = TEAM_SORT_KEY.TEAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this team
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TEAM_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServicePaginationReq, a, b);
    }
}
exports.TeamsServicePaginationReq = TeamsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.TeamsServicePaginationResponse
 */
class TeamsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Team payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Team, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServicePaginationResponse, a, b);
    }
}
exports.TeamsServicePaginationResponse = TeamsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.TeamsServiceFilterReq
 */
class TeamsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TEAM_SORT_KEY sort_key = 5;
     */
    sortKey = TEAM_SORT_KEY.TEAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this team
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the team
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The code of the team
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the leader
     *
     * @generated from field: uint64 lead_user_id = 22;
     */
    leadUserId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the member
     *
     * @generated from field: uint64 member_user_id = 23;
     */
    memberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TEAM_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "lead_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "member_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceFilterReq, a, b);
    }
}
exports.TeamsServiceFilterReq = TeamsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.TeamsServiceCountReq
 */
class TeamsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this team
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the team
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The code of the team
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the leader
     *
     * @generated from field: uint64 lead_user_id = 22;
     */
    leadUserId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the member
     *
     * @generated from field: uint64 member_user_id = 23;
     */
    memberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "lead_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "member_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceCountReq, a, b);
    }
}
exports.TeamsServiceCountReq = TeamsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.TeamsServiceSearchAllReq
 */
class TeamsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TEAM_SORT_KEY sort_key = 5;
     */
    sortKey = TEAM_SORT_KEY.TEAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TEAM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServiceSearchAllReq, a, b);
    }
}
exports.TeamsServiceSearchAllReq = TeamsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved members.
 *
 * @generated from message Scailo.TeamMembersSearchRequest
 */
class TeamMembersSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.TEAM_MEMBER_SORT_KEY sort_key = 5;
     */
    sortKey = TEAM_MEMBER_SORT_KEY.TEAM_MEMBER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the members
     *
     * @generated from field: Scailo.TEAM_MEMBER_STATUS status = 7;
     */
    status = TEAM_MEMBER_STATUS.TEAM_MEMBER_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team
     *
     * @generated from field: uint64 team_id = 20;
     */
    teamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the member
     *
     * @generated from field: uint64 user_id = 21;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamMembersSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(TEAM_MEMBER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(TEAM_MEMBER_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "team_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new TeamMembersSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamMembersSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamMembersSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamMembersSearchRequest, a, b);
    }
}
exports.TeamMembersSearchRequest = TeamMembersSearchRequest;
/**
 *
 * Describes the response to a pagination members request
 *
 * @generated from message Scailo.TeamsServicePaginatedMembersResponse
 */
class TeamsServicePaginatedMembersResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.TeamMember payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.TeamsServicePaginatedMembersResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: TeamMember, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new TeamsServicePaginatedMembersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new TeamsServicePaginatedMembersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new TeamsServicePaginatedMembersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(TeamsServicePaginatedMembersResponse, a, b);
    }
}
exports.TeamsServicePaginatedMembersResponse = TeamsServicePaginatedMembersResponse;
