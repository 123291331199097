
import { Router } from "./../router";
import * as dash from "./dash";
import * as profile from "./profile";
import * as associates from "./associates/routes";
import * as streams from "./streams/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    dash.Routes(r);
    profile.Routes(r);
    associates.Routes(r);
    streams.Routes(r);
}