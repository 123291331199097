import { qaSampleFiltersMenu, qaSampleInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleQcSampleFilters } from "./qasamples.filters";
import { handleQcSampleInsights } from "./qasamples.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(qaSampleFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, qaSampleFiltersMenu)) {
            handleQcSampleFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(qaSampleInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, qaSampleInsightsMenu)) {
            handleQcSampleInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}