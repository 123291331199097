"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file equations_work_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsWorkOrdersService = void 0;
const equations_work_orders_scailo_pb_js_1 = require("./equations_work_orders.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each equation work order
 *
 * @generated from service Scailo.EquationsWorkOrdersService
 */
exports.EquationsWorkOrdersService = {
    typeName: "Scailo.EquationsWorkOrdersService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Create
         */
        create: {
            name: "Create",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Draft
         */
        draft: {
            name: "Draft",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (Identifier);
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone equation from an existing equation (denoted by the identifier)
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a equation work order
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.AddEquationWorkOrderItem
         */
        addEquationWorkOrderItem: {
            name: "AddEquationWorkOrderItem",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a equation work order
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ModifyEquationWorkOrderItem
         */
        modifyEquationWorkOrderItem: {
            name: "ModifyEquationWorkOrderItem",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a equation work order
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ApproveEquationWorkOrderItem
         */
        approveEquationWorkOrderItem: {
            name: "ApproveEquationWorkOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a equation work order
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.DeleteEquationWorkOrderItem
         */
        deleteEquationWorkOrderItem: {
            name: "DeleteEquationWorkOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a equation work order
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ReorderEquationWorkOrderItems
         */
        reorderEquationWorkOrderItems: {
            name: "ReorderEquationWorkOrderItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Equation Work Order Item by ID
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewEquationWorkOrderItemByID
         */
        viewEquationWorkOrderItemByID: {
            name: "ViewEquationWorkOrderItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_work_orders_scailo_pb_js_1.EquationWorkOrderItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation work order items for given equation work order ID
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewApprovedEquationWorkOrderItems
         */
        viewApprovedEquationWorkOrderItems: {
            name: "ViewApprovedEquationWorkOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation work order items for given equation work order ID
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewUnapprovedEquationWorkOrderItems
         */
        viewUnapprovedEquationWorkOrderItems: {
            name: "ViewUnapprovedEquationWorkOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the equation work order item
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewEquationWorkOrderItemHistory
         */
        viewEquationWorkOrderItemHistory: {
            name: "ViewEquationWorkOrderItemHistory",
            I: equations_work_orders_scailo_pb_js_1.EquationWorkOrderItemHistoryRequest,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation work order items for given equation work order ID with pagination
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewPaginatedApprovedEquationWorkOrderItems
         */
        viewPaginatedApprovedEquationWorkOrderItems: {
            name: "ViewPaginatedApprovedEquationWorkOrderItems",
            I: equations_work_orders_scailo_pb_js_1.EquationWorkOrderItemsSearchRequest,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation work order items for given equation work order ID with pagination
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewPaginatedUnapprovedEquationWorkOrderItems
         */
        viewPaginatedUnapprovedEquationWorkOrderItems: {
            name: "ViewPaginatedUnapprovedEquationWorkOrderItems",
            I: equations_work_orders_scailo_pb_js_1.EquationWorkOrderItemsSearchRequest,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through equation work order items with pagination
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: equations_work_orders_scailo_pb_js_1.EquationWorkOrderItemsSearchRequest,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV file with the entire dependency tree. Useful for identifying quantities necessary from all the dependencies.
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.DownloadTreeAsCSV
         */
        downloadTreeAsCSV: {
            name: "DownloadTreeAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.UploadEquationWorkOrderItems
         */
        uploadEquationWorkOrderItems: {
            name: "UploadEquationWorkOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_work_orders_scailo_pb_js_1.EquationWorkOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServicePaginationReq,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the latest equation for a work order (denoted by the given identifier)
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.ViewForWorkOrderID
         */
        viewForWorkOrderID: {
            name: "ViewForWorkOrderID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_work_orders_scailo_pb_js_1.EquationWorkOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceSearchAllReq,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Filter
         */
        filter: {
            name: "Filter",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceFilterReq,
            O: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.EquationsWorkOrdersService.Count
         */
        count: {
            name: "Count",
            I: equations_work_orders_scailo_pb_js_1.EquationsWorkOrdersServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
