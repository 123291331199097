"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file goods_receipts.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoodsReceiptsService = void 0;
const goods_receipts_scailo_pb_js_1 = require("./goods_receipts.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each goods receipt
 *
 * @generated from service Scailo.GoodsReceiptsService
 */
exports.GoodsReceiptsService = {
    typeName: "Scailo.GoodsReceiptsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Create
         */
        create: {
            name: "Create",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Draft
         */
        draft: {
            name: "Draft",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.GoodsReceiptsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.GoodsReceiptsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.GoodsReceiptsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.GoodsReceiptsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.GoodsReceiptsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.GoodsReceiptsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Goods Receipt can be marked as completed
         *
         * @generated from rpc Scailo.GoodsReceiptsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.AddGoodsReceiptItem
         */
        addGoodsReceiptItem: {
            name: "AddGoodsReceiptItem",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ModifyGoodsReceiptItem
         */
        modifyGoodsReceiptItem: {
            name: "ModifyGoodsReceiptItem",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ApproveGoodsReceiptItem
         */
        approveGoodsReceiptItem: {
            name: "ApproveGoodsReceiptItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.DeleteGoodsReceiptItem
         */
        deleteGoodsReceiptItem: {
            name: "DeleteGoodsReceiptItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ReorderGoodsReceiptItems
         */
        reorderGoodsReceiptItems: {
            name: "ReorderGoodsReceiptItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Goods Receipt Item by ID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewGoodsReceiptItemByID
         */
        viewGoodsReceiptItemByID: {
            name: "ViewGoodsReceiptItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goods receipt items for given goods receipt ID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewApprovedGoodsReceiptItems
         */
        viewApprovedGoodsReceiptItems: {
            name: "ViewApprovedGoodsReceiptItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goods receipt items for given goods receipt ID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewUnapprovedGoodsReceiptItems
         */
        viewUnapprovedGoodsReceiptItems: {
            name: "ViewUnapprovedGoodsReceiptItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the goods receipt item
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewGoodsReceiptItemHistory
         */
        viewGoodsReceiptItemHistory: {
            name: "ViewGoodsReceiptItemHistory",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptItemHistoryRequest,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goods receipt items for given goods receipt ID with pagination
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewPaginatedApprovedGoodsReceiptItems
         */
        viewPaginatedApprovedGoodsReceiptItems: {
            name: "ViewPaginatedApprovedGoodsReceiptItems",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptItemsSearchRequest,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goods receipt items for given goods receipt ID with pagination
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewPaginatedUnapprovedGoodsReceiptItems
         */
        viewPaginatedUnapprovedGoodsReceiptItems: {
            name: "ViewPaginatedUnapprovedGoodsReceiptItems",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptItemsSearchRequest,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through goods receipt items with pagination
         *
         * @generated from rpc Scailo.GoodsReceiptsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptItemsSearchRequest,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.GoodsReceiptsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.GoodsReceiptsService.UploadGoodsReceiptItems
         */
        uploadGoodsReceiptItems: {
            name: "UploadGoodsReceiptItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goods_receipts_scailo_pb_js_1.GoodsReceipt,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goods_receipts_scailo_pb_js_1.GoodsReceipt,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServicePaginationReq,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given goods receipt
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective goods receipt item info for the given family ID and goods receipt ID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewProspectiveGoodsReceiptItem
         */
        viewProspectiveGoodsReceiptItem: {
            name: "ViewProspectiveGoodsReceiptItem",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptItemProspectiveInfoRequest,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download goods receipt with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.GoodsReceiptsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the goods receipt with the given IdentifierUUID
         *
         * @generated from rpc Scailo.GoodsReceiptsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the associated vendor invoice ID that is denoted by the identifier in the response for the goods receipt that is denoted by the identifier in the request
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewAssociatedVendorInvoiceID
         */
        viewAssociatedVendorInvoiceID: {
            name: "ViewAssociatedVendorInvoiceID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Goods Receipt has been billed
         *
         * @generated from rpc Scailo.GoodsReceiptsService.IsBilled
         */
        isBilled: {
            name: "IsBilled",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.GoodsReceiptsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.GoodsReceiptsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceSearchAllReq,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Filter
         */
        filter: {
            name: "Filter",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceFilterReq,
            O: goods_receipts_scailo_pb_js_1.GoodsReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.GoodsReceiptsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.GoodsReceiptsService.Count
         */
        count: {
            name: "Count",
            I: goods_receipts_scailo_pb_js_1.GoodsReceiptsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
