"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file equations_sales_bundles.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquationsSalesBundlesService = void 0;
const equations_sales_bundles_scailo_pb_js_1 = require("./equations_sales_bundles.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each equation sales bundle
 *
 * @generated from service Scailo.EquationsSalesBundlesService
 */
exports.EquationsSalesBundlesService = {
    typeName: "Scailo.EquationsSalesBundlesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Create
         */
        create: {
            name: "Create",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Draft
         */
        draft: {
            name: "Draft",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (Identifier);
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone equation from an existing equation (denoted by the identifier)
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a equation sales bundle
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.AddEquationSalesBundleItem
         */
        addEquationSalesBundleItem: {
            name: "AddEquationSalesBundleItem",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a equation sales bundle
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ModifyEquationSalesBundleItem
         */
        modifyEquationSalesBundleItem: {
            name: "ModifyEquationSalesBundleItem",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a equation sales bundle
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ApproveEquationSalesBundleItem
         */
        approveEquationSalesBundleItem: {
            name: "ApproveEquationSalesBundleItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a equation sales bundle
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.DeleteEquationSalesBundleItem
         */
        deleteEquationSalesBundleItem: {
            name: "DeleteEquationSalesBundleItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a equation sales bundle
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ReorderEquationSalesBundleItems
         */
        reorderEquationSalesBundleItems: {
            name: "ReorderEquationSalesBundleItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Equation Sales Bundle Item by ID
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewEquationSalesBundleItemByID
         */
        viewEquationSalesBundleItemByID: {
            name: "ViewEquationSalesBundleItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundleItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation sales bundle items for given equation sales bundle ID
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewApprovedEquationSalesBundleItems
         */
        viewApprovedEquationSalesBundleItems: {
            name: "ViewApprovedEquationSalesBundleItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation sales bundle items for given equation sales bundle ID
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewUnapprovedEquationSalesBundleItems
         */
        viewUnapprovedEquationSalesBundleItems: {
            name: "ViewUnapprovedEquationSalesBundleItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the equation sales bundle item
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewEquationSalesBundleItemHistory
         */
        viewEquationSalesBundleItemHistory: {
            name: "ViewEquationSalesBundleItemHistory",
            I: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundleItemHistoryRequest,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved equation sales bundle items for given equation sales bundle ID with pagination
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewPaginatedApprovedEquationSalesBundleItems
         */
        viewPaginatedApprovedEquationSalesBundleItems: {
            name: "ViewPaginatedApprovedEquationSalesBundleItems",
            I: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundleItemsSearchRequest,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved equation sales bundle items for given equation sales bundle ID with pagination
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewPaginatedUnapprovedEquationSalesBundleItems
         */
        viewPaginatedUnapprovedEquationSalesBundleItems: {
            name: "ViewPaginatedUnapprovedEquationSalesBundleItems",
            I: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundleItemsSearchRequest,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through equation sales bundle items with pagination
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundleItemsSearchRequest,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV file with the entire dependency tree. Useful for identifying quantities necessary from all the dependencies.
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.DownloadTreeAsCSV
         */
        downloadTreeAsCSV: {
            name: "DownloadTreeAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.UploadEquationSalesBundleItems
         */
        uploadEquationSalesBundleItems: {
            name: "UploadEquationSalesBundleItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundle,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServicePaginationReq,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the latest equation for a family (denoted by the given identifier)
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.ViewForFamilyID
         */
        viewForFamilyID: {
            name: "ViewForFamilyID",
            I: base_scailo_pb_js_1.Identifier,
            O: equations_sales_bundles_scailo_pb_js_1.EquationSalesBundle,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceSearchAllReq,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Filter
         */
        filter: {
            name: "Filter",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceFilterReq,
            O: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.EquationsSalesBundlesService.Count
         */
        count: {
            name: "Count",
            I: equations_sales_bundles_scailo_pb_js_1.EquationsSalesBundlesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
