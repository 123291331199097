"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file projects.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectContactsList = exports.ProjectContact = exports.ProjectsServiceContactCreateRequest = exports.ProjectsServiceSearchAllReq = exports.ProjectsServiceCountReq = exports.ProjectsServiceFilterReq = exports.ProjectsServicePaginationResponse = exports.ProjectsServicePaginationReq = exports.ProjectStatistics = exports.ProjectsList = exports.Project = exports.ProjectsServiceUpdateRequest = exports.ProjectsServiceCreateRequest = exports.PROJECT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PROJECT_SORT_KEY
 */
var PROJECT_SORT_KEY;
(function (PROJECT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PROJECT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PROJECT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PROJECT_SORT_KEY_CREATED_AT = 1;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_CREATED_AT"] = 1] = "PROJECT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PROJECT_SORT_KEY_MODIFIED_AT = 2;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_MODIFIED_AT"] = 2] = "PROJECT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PROJECT_SORT_KEY_APPROVED_ON = 3;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_APPROVED_ON"] = 3] = "PROJECT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PROJECT_SORT_KEY_APPROVED_BY = 4;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_APPROVED_BY"] = 4] = "PROJECT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PROJECT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PROJECT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PROJECT_SORT_KEY_COMPLETED_ON = 6;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_COMPLETED_ON"] = 6] = "PROJECT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PROJECT_SORT_KEY_REFERENCE_ID = 10;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_REFERENCE_ID"] = 10] = "PROJECT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PROJECT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PROJECT_SORT_KEY[PROJECT_SORT_KEY["PROJECT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PROJECT_SORT_KEY_FINAL_REF_NUMBER";
})(PROJECT_SORT_KEY || (exports.PROJECT_SORT_KEY = PROJECT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PROJECT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PROJECT_SORT_KEY, "Scailo.PROJECT_SORT_KEY", [
    { no: 0, name: "PROJECT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PROJECT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PROJECT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PROJECT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PROJECT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PROJECT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PROJECT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PROJECT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PROJECT_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ProjectsServiceCreateRequest
 */
class ProjectsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the project
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The optional ID of the associated client
     *
     * @generated from field: uint64 client_id = 12;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The description of the project
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceCreateRequest, a, b);
    }
}
exports.ProjectsServiceCreateRequest = ProjectsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ProjectsServiceUpdateRequest
 */
class ProjectsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the project
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The optional ID of the associated client
     *
     * @generated from field: uint64 client_id = 12;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The description of the project
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceUpdateRequest, a, b);
    }
}
exports.ProjectsServiceUpdateRequest = ProjectsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Project
 */
class Project extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this project
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this project
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this project
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this project was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the project
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The optional ID of the associated client
     *
     * @generated from field: uint64 client_id = 12;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The description of the project
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Project";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Project().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Project().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Project().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Project, a, b);
    }
}
exports.Project = Project;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ProjectsList
 */
class ProjectsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Project list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Project, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsList, a, b);
    }
}
exports.ProjectsList = ProjectsList;
/**
 *
 * Describes the parameters that are part of a project's statistics payload
 *
 * @generated from message Scailo.ProjectStatistics
 */
class ProjectStatistics extends protobuf_1.Message {
    /**
     * Stores the total amount of time spent on the project
     *
     * @generated from field: uint64 total_duration = 1;
     */
    totalDuration = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative completion percentage of the project
     *
     * @generated from field: uint64 total_completion_percentage = 2;
     */
    totalCompletionPercentage = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of points
     *
     * @generated from field: uint64 total_points = 3;
     */
    totalPoints = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "total_duration", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "total_completion_percentage", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total_points", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectStatistics, a, b);
    }
}
exports.ProjectStatistics = ProjectStatistics;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ProjectsServicePaginationReq
 */
class ProjectsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROJECT_SORT_KEY sort_key = 5;
     */
    sortKey = PROJECT_SORT_KEY.PROJECT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this project
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROJECT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServicePaginationReq, a, b);
    }
}
exports.ProjectsServicePaginationReq = ProjectsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ProjectsServicePaginationResponse
 */
class ProjectsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Project payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Project, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServicePaginationResponse, a, b);
    }
}
exports.ProjectsServicePaginationResponse = ProjectsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ProjectsServiceFilterReq
 */
class ProjectsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROJECT_SORT_KEY sort_key = 5;
     */
    sortKey = PROJECT_SORT_KEY.PROJECT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this project
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the project
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the associated client. Returns all record if it is set to -1. 0 is a valid filter too.
     *
     * @generated from field: int64 client_id = 22;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated sales order (ignored if 0)
     *
     * @generated from field: uint64 sales_order_id = 40;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated purchase order (ignored if 0)
     *
     * @generated from field: uint64 purchase_order_id = 41;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated outward job (ignored if 0)
     *
     * @generated from field: uint64 outward_job_id = 42;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated inward job (ignored if 0)
     *
     * @generated from field: uint64 inward_job_id = 43;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated production plan (ignored if 0)
     *
     * @generated from field: uint64 production_plan_id = 44;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated meeting (ignored if 0)
     *
     * @generated from field: uint64 meeting_id = 45;
     */
    meetingId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROJECT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "client_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 40, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 43, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 44, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 45, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceFilterReq, a, b);
    }
}
exports.ProjectsServiceFilterReq = ProjectsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ProjectsServiceCountReq
 */
class ProjectsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this project
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the project
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the associated client. Returns all record if it is set to -1. 0 is a valid filter too.
     *
     * @generated from field: int64 client_id = 22;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated sales order (ignored if 0)
     *
     * @generated from field: uint64 sales_order_id = 40;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated purchase order (ignored if 0)
     *
     * @generated from field: uint64 purchase_order_id = 41;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated outward job (ignored if 0)
     *
     * @generated from field: uint64 outward_job_id = 42;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated inward job (ignored if 0)
     *
     * @generated from field: uint64 inward_job_id = 43;
     */
    inwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated production plan (ignored if 0)
     *
     * @generated from field: uint64 production_plan_id = 44;
     */
    productionPlanId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated meeting (ignored if 0)
     *
     * @generated from field: uint64 meeting_id = 45;
     */
    meetingId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "client_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 40, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 42, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 43, name: "inward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 44, name: "production_plan_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 45, name: "meeting_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceCountReq, a, b);
    }
}
exports.ProjectsServiceCountReq = ProjectsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ProjectsServiceSearchAllReq
 */
class ProjectsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROJECT_SORT_KEY sort_key = 5;
     */
    sortKey = PROJECT_SORT_KEY.PROJECT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the associated client. Returns all record if it is set to -1. 0 is a valid filter too.
     *
     * @generated from field: int64 client_id = 21;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROJECT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "client_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceSearchAllReq, a, b);
    }
}
exports.ProjectsServiceSearchAllReq = ProjectsServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a project contact
 *
 * @generated from message Scailo.ProjectsServiceContactCreateRequest
 */
class ProjectsServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the project ID
     *
     * @generated from field: uint64 project_id = 10;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectsServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectsServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectsServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectsServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectsServiceContactCreateRequest, a, b);
    }
}
exports.ProjectsServiceContactCreateRequest = ProjectsServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a project contact
 *
 * @generated from message Scailo.ProjectContact
 */
class ProjectContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this project
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the project ID
     *
     * @generated from field: uint64 project_id = 10;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the employee ID
     *
     * @generated from field: uint64 employee_id = 11;
     */
    employeeId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectContact, a, b);
    }
}
exports.ProjectContact = ProjectContact;
/**
 *
 * Describes the message consisting of the list of project contacts
 *
 * @generated from message Scailo.ProjectContactsList
 */
class ProjectContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProjectContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProjectContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProjectContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProjectContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProjectContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProjectContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProjectContactsList, a, b);
    }
}
exports.ProjectContactsList = ProjectContactsList;
