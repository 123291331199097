"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file purchases_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesReturnsService = void 0;
const purchases_returns_scailo_pb_js_1 = require("./purchases_returns.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each purchase return
 *
 * @generated from service Scailo.PurchasesReturnsService
 */
exports.PurchasesReturnsService = {
    typeName: "Scailo.PurchasesReturnsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Create
         */
        create: {
            name: "Create",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Draft
         */
        draft: {
            name: "Draft",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.PurchasesReturnsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.PurchasesReturnsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.PurchasesReturnsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.PurchasesReturnsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.PurchasesReturnsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.PurchasesReturnsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Purchase Return can be marked as completed
         *
         * @generated from rpc Scailo.PurchasesReturnsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.AddPurchaseReturnItem
         */
        addPurchaseReturnItem: {
            name: "AddPurchaseReturnItem",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ModifyPurchaseReturnItem
         */
        modifyPurchaseReturnItem: {
            name: "ModifyPurchaseReturnItem",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ApprovePurchaseReturnItem
         */
        approvePurchaseReturnItem: {
            name: "ApprovePurchaseReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.DeletePurchaseReturnItem
         */
        deletePurchaseReturnItem: {
            name: "DeletePurchaseReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ReorderPurchaseReturnItems
         */
        reorderPurchaseReturnItems: {
            name: "ReorderPurchaseReturnItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Purchase Return Item by ID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewPurchaseReturnItemByID
         */
        viewPurchaseReturnItemByID: {
            name: "ViewPurchaseReturnItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_returns_scailo_pb_js_1.PurchaseReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Purchase Return Item by Inventory Hash
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewPurchaseReturnItemByInventoryHash
         */
        viewPurchaseReturnItemByInventoryHash: {
            name: "ViewPurchaseReturnItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: purchases_returns_scailo_pb_js_1.PurchaseReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase return items for given purchase return ID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewApprovedPurchaseReturnItems
         */
        viewApprovedPurchaseReturnItems: {
            name: "ViewApprovedPurchaseReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase return items for given purchase return ID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewUnapprovedPurchaseReturnItems
         */
        viewUnapprovedPurchaseReturnItems: {
            name: "ViewUnapprovedPurchaseReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the purchase return item
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewPurchaseReturnItemHistory
         */
        viewPurchaseReturnItemHistory: {
            name: "ViewPurchaseReturnItemHistory",
            I: purchases_returns_scailo_pb_js_1.PurchaseReturnItemHistoryRequest,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales return items for given sales return ID with pagination
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewPaginatedApprovedPurchaseReturnItems
         */
        viewPaginatedApprovedPurchaseReturnItems: {
            name: "ViewPaginatedApprovedPurchaseReturnItems",
            I: purchases_returns_scailo_pb_js_1.PurchaseReturnItemsSearchRequest,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales return items for given sales return ID with pagination
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewPaginatedUnapprovedPurchaseReturnItems
         */
        viewPaginatedUnapprovedPurchaseReturnItems: {
            name: "ViewPaginatedUnapprovedPurchaseReturnItems",
            I: purchases_returns_scailo_pb_js_1.PurchaseReturnItemsSearchRequest,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales return items with pagination
         *
         * @generated from rpc Scailo.PurchasesReturnsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: purchases_returns_scailo_pb_js_1.PurchaseReturnItemsSearchRequest,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_returns_scailo_pb_js_1.PurchaseReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_returns_scailo_pb_js_1.PurchaseReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServicePaginationReq,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given purchase return
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective purchase return item info for the given family ID and purchase return ID
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewProspectivePurchaseReturnItem
         */
        viewProspectivePurchaseReturnItem: {
            name: "ViewProspectivePurchaseReturnItem",
            I: purchases_returns_scailo_pb_js_1.PurchaseReturnItemProspectiveInfoRequest,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Purchase Return has been billed
         *
         * @generated from rpc Scailo.PurchasesReturnsService.IsBilled
         */
        isBilled: {
            name: "IsBilled",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.PurchasesReturnsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download purchase return with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.PurchasesReturnsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.PurchasesReturnsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceSearchAllReq,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Filter
         */
        filter: {
            name: "Filter",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceFilterReq,
            O: purchases_returns_scailo_pb_js_1.PurchasesReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.PurchasesReturnsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.PurchasesReturnsService.Count
         */
        count: {
            name: "Count",
            I: purchases_returns_scailo_pb_js_1.PurchasesReturnsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
