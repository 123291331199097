"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file sales_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesOrdersService = void 0;
const sales_orders_scailo_pb_js_1 = require("./sales_orders.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each sales order
 *
 * @generated from service Scailo.SalesOrdersService
 */
exports.SalesOrdersService = {
    typeName: "Scailo.SalesOrdersService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalesOrdersService.Create
         */
        create: {
            name: "Create",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalesOrdersService.Draft
         */
        draft: {
            name: "Draft",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalesOrdersService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalesOrdersService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalesOrdersService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalesOrdersService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalesOrdersService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalesOrdersService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalesOrdersService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalesOrdersService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalesOrdersService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalesOrdersService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalesOrdersService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalesOrdersService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalesOrdersService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalesOrdersService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the sales order and send for revision
         *
         * @generated from rpc Scailo.SalesOrdersService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.AddSalesOrderItem
         */
        addSalesOrderItem: {
            name: "AddSalesOrderItem",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.ModifySalesOrderItem
         */
        modifySalesOrderItem: {
            name: "ModifySalesOrderItem",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.ApproveSalesOrderItem
         */
        approveSalesOrderItem: {
            name: "ApproveSalesOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.DeleteSalesOrderItem
         */
        deleteSalesOrderItem: {
            name: "DeleteSalesOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.ReorderSalesOrderItems
         */
        reorderSalesOrderItems: {
            name: "ReorderSalesOrderItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Order Item by ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderItemByID
         */
        viewSalesOrderItemByID: {
            name: "ViewSalesOrderItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrderItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Order Item's price after factoring in the discount
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderItemPrice
         */
        viewSalesOrderItemPrice: {
            name: "ViewSalesOrderItemPrice",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.PriceResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales order items for given sales order ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewApprovedSalesOrderItems
         */
        viewApprovedSalesOrderItems: {
            name: "ViewApprovedSalesOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_orders_scailo_pb_js_1.SalesOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales order items for given sales order ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewUnapprovedSalesOrderItems
         */
        viewUnapprovedSalesOrderItems: {
            name: "ViewUnapprovedSalesOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_orders_scailo_pb_js_1.SalesOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the sales order item
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderItemHistory
         */
        viewSalesOrderItemHistory: {
            name: "ViewSalesOrderItemHistory",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemHistoryRequest,
            O: sales_orders_scailo_pb_js_1.SalesOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales order items for given sales order ID with pagination
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewPaginatedApprovedSalesOrderItems
         */
        viewPaginatedApprovedSalesOrderItems: {
            name: "ViewPaginatedApprovedSalesOrderItems",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemsSearchRequest,
            O: sales_orders_scailo_pb_js_1.SalesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales order items for given sales order ID with pagination
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewPaginatedUnapprovedSalesOrderItems
         */
        viewPaginatedUnapprovedSalesOrderItems: {
            name: "ViewPaginatedUnapprovedSalesOrderItems",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemsSearchRequest,
            O: sales_orders_scailo_pb_js_1.SalesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales order items with pagination
         *
         * @generated from rpc Scailo.SalesOrdersService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemsSearchRequest,
            O: sales_orders_scailo_pb_js_1.SalesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.SalesOrdersService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.SalesOrdersService.UploadSalesOrderItems
         */
        uploadSalesOrderItems: {
            name: "UploadSalesOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.SalesOrdersService.AddSalesOrderContact
         */
        addSalesOrderContact: {
            name: "AddSalesOrderContact",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceContactCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.SalesOrdersService.ApproveSalesOrderContact
         */
        approveSalesOrderContact: {
            name: "ApproveSalesOrderContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.SalesOrdersService.DeleteSalesOrderContact
         */
        deleteSalesOrderContact: {
            name: "DeleteSalesOrderContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderContactByID
         */
        viewSalesOrderContactByID: {
            name: "ViewSalesOrderContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrderContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given sales order ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderContacts
         */
        viewSalesOrderContacts: {
            name: "ViewSalesOrderContacts",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrderContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.SalesOrdersService.AddSalesOrderReference
         */
        addSalesOrderReference: {
            name: "AddSalesOrderReference",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.SalesOrdersService.ApproveSalesOrderReference
         */
        approveSalesOrderReference: {
            name: "ApproveSalesOrderReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.SalesOrdersService.DeleteSalesOrderReference
         */
        deleteSalesOrderReference: {
            name: "DeleteSalesOrderReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderReferenceByID
         */
        viewSalesOrderReferenceByID: {
            name: "ViewSalesOrderReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrderReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given sales order ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewSalesOrderReferences
         */
        viewSalesOrderReferences: {
            name: "ViewSalesOrderReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrderReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_orders_scailo_pb_js_1.SalesOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components of the record
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_orders_scailo_pb_js_1.SalesOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: sales_orders_scailo_pb_js_1.SalesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_orders_scailo_pb_js_1.SalesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServicePaginationReq,
            O: sales_orders_scailo_pb_js_1.SalesOrdersServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inventory Statistics of Sales Order
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewInventoryStatistics
         */
        viewInventoryStatistics: {
            name: "ViewInventoryStatistics",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_orders_scailo_pb_js_1.SalesOrderInventoryStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Billing Statistics of Sales Order
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewBillingStatistics
         */
        viewBillingStatistics: {
            name: "ViewBillingStatistics",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_orders_scailo_pb_js_1.SalesOrderBillingStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inventory Match of all the families within a Sales Order
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewInventoryMatch
         */
        viewInventoryMatch: {
            name: "ViewInventoryMatch",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_orders_scailo_pb_js_1.SalesOrderInventoryMatchList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given sales order
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective sales order item info for the given family ID and sales order ID
         *
         * @generated from rpc Scailo.SalesOrdersService.ViewProspectiveSalesOrderItem
         */
        viewProspectiveSalesOrderItem: {
            name: "ViewProspectiveSalesOrderItem",
            I: sales_orders_scailo_pb_js_1.SalesOrderItemProspectiveInfoRequest,
            O: sales_orders_scailo_pb_js_1.SalesOrdersServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download sales order with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.SalesOrdersService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalesOrdersService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceSearchAllReq,
            O: sales_orders_scailo_pb_js_1.SalesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalesOrdersService.Filter
         */
        filter: {
            name: "Filter",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceFilterReq,
            O: sales_orders_scailo_pb_js_1.SalesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalesOrdersService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalesOrdersService.Count
         */
        count: {
            name: "Count",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Returns the sum of the total value of all the records that match the given criteria
         *
         * @generated from rpc Scailo.SalesOrdersService.AccruedValue
         */
        accruedValue: {
            name: "AccruedValue",
            I: sales_orders_scailo_pb_js_1.SalesOrdersServiceCountReq,
            O: base_scailo_pb_js_1.SumResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
