"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file associates.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssociatesServiceSearchAllReq = exports.AssociatesServiceCountReq = exports.AssociatesServiceFilterReq = exports.AssociatesServicePaginationReq = exports.AssociatesServiceUpdateRequest = exports.AssociatesServiceImportRequest = exports.AssociatesServiceCreateRequest = exports.AssociatePaginationResp = exports.AssociatesList = exports.Associate = exports.ASSOCIATE_SORT_KEY = exports.ASSOCIATE_ORG_REF_FROM = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available options that a associate can be associated with
 *
 * @generated from enum Scailo.ASSOCIATE_ORG_REF_FROM
 */
var ASSOCIATE_ORG_REF_FROM;
(function (ASSOCIATE_ORG_REF_FROM) {
    /**
     * Useful only in filter and search requests
     *
     * @generated from enum value: ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    ASSOCIATE_ORG_REF_FROM[ASSOCIATE_ORG_REF_FROM["ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED"] = 0] = "ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED";
    /**
     * When the associate has no association
     *
     * @generated from enum value: ASSOCIATE_ORG_REF_FROM_EMPTY = 1;
     */
    ASSOCIATE_ORG_REF_FROM[ASSOCIATE_ORG_REF_FROM["ASSOCIATE_ORG_REF_FROM_EMPTY"] = 1] = "ASSOCIATE_ORG_REF_FROM_EMPTY";
    /**
     * When the associate has been associated to a client
     *
     * @generated from enum value: ASSOCIATE_ORG_REF_FROM_CLIENT = 2;
     */
    ASSOCIATE_ORG_REF_FROM[ASSOCIATE_ORG_REF_FROM["ASSOCIATE_ORG_REF_FROM_CLIENT"] = 2] = "ASSOCIATE_ORG_REF_FROM_CLIENT";
    /**
     * When the associate has been associated to a vendor
     *
     * @generated from enum value: ASSOCIATE_ORG_REF_FROM_VENDOR = 3;
     */
    ASSOCIATE_ORG_REF_FROM[ASSOCIATE_ORG_REF_FROM["ASSOCIATE_ORG_REF_FROM_VENDOR"] = 3] = "ASSOCIATE_ORG_REF_FROM_VENDOR";
})(ASSOCIATE_ORG_REF_FROM || (exports.ASSOCIATE_ORG_REF_FROM = ASSOCIATE_ORG_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(ASSOCIATE_ORG_REF_FROM)
protobuf_1.proto3.util.setEnumType(ASSOCIATE_ORG_REF_FROM, "Scailo.ASSOCIATE_ORG_REF_FROM", [
    { no: 0, name: "ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "ASSOCIATE_ORG_REF_FROM_EMPTY" },
    { no: 2, name: "ASSOCIATE_ORG_REF_FROM_CLIENT" },
    { no: 3, name: "ASSOCIATE_ORG_REF_FROM_VENDOR" },
]);
/**
 *
 * Describes the available sort keys for retrieving associates
 *
 * @generated from enum Scailo.ASSOCIATE_SORT_KEY
 */
var ASSOCIATE_SORT_KEY;
(function (ASSOCIATE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ASSOCIATE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_CREATED_AT = 1;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_CREATED_AT"] = 1] = "ASSOCIATE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_MODIFIED_AT = 2;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_MODIFIED_AT"] = 2] = "ASSOCIATE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by first name
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_FIRST_NAME = 10;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_FIRST_NAME"] = 10] = "ASSOCIATE_SORT_KEY_FIRST_NAME";
    /**
     * Fetch ordered results by middle name
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_MIDDLE_NAME = 11;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_MIDDLE_NAME"] = 11] = "ASSOCIATE_SORT_KEY_MIDDLE_NAME";
    /**
     * Fetch ordered results by last name
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_LAST_NAME = 12;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_LAST_NAME"] = 12] = "ASSOCIATE_SORT_KEY_LAST_NAME";
    /**
     * Fetch ordered results by organization name
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_ORG_NAME = 13;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_ORG_NAME"] = 13] = "ASSOCIATE_SORT_KEY_ORG_NAME";
    /**
     * Fetch ordered results by job title
     *
     * @generated from enum value: ASSOCIATE_SORT_KEY_JOB_TITLE = 14;
     */
    ASSOCIATE_SORT_KEY[ASSOCIATE_SORT_KEY["ASSOCIATE_SORT_KEY_JOB_TITLE"] = 14] = "ASSOCIATE_SORT_KEY_JOB_TITLE";
})(ASSOCIATE_SORT_KEY || (exports.ASSOCIATE_SORT_KEY = ASSOCIATE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ASSOCIATE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ASSOCIATE_SORT_KEY, "Scailo.ASSOCIATE_SORT_KEY", [
    { no: 0, name: "ASSOCIATE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ASSOCIATE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ASSOCIATE_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "ASSOCIATE_SORT_KEY_FIRST_NAME" },
    { no: 11, name: "ASSOCIATE_SORT_KEY_MIDDLE_NAME" },
    { no: 12, name: "ASSOCIATE_SORT_KEY_LAST_NAME" },
    { no: 13, name: "ASSOCIATE_SORT_KEY_ORG_NAME" },
    { no: 14, name: "ASSOCIATE_SORT_KEY_JOB_TITLE" },
]);
/**
 *
 * Describes the data structure of each associate on the platform
 *
 * @generated from message Scailo.Associate
 */
class Associate extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this resource
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The first name of the associate
     *
     * @generated from field: string first_name = 10;
     */
    firstName = "";
    /**
     * The middle name of the associate
     *
     * @generated from field: string middle_name = 11;
     */
    middleName = "";
    /**
     * The last name of the associate
     *
     * @generated from field: string last_name = 12;
     */
    lastName = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    /**
     * The name of the organization that the associate works in. Will be autofilled if the associate is associated with either a client or a vendor
     *
     * @generated from field: string org_name = 15;
     */
    orgName = "";
    /**
     * The job title of the associate
     *
     * @generated from field: string job_title = 16;
     */
    jobTitle = "";
    /**
     * The department that the associates works in
     *
     * @generated from field: string department = 17;
     */
    department = "";
    /**
     * The work phone number of the associate
     *
     * @generated from field: string work_phone = 18;
     */
    workPhone = "";
    /**
     * The work email address of the associate
     *
     * @generated from field: string work_email = 19;
     */
    workEmail = "";
    /**
     * The personal phone number of the associate
     *
     * @generated from field: string personal_phone = 20;
     */
    personalPhone = "";
    /**
     * The personal email address of the associate
     *
     * @generated from field: string personal_email = 21;
     */
    personalEmail = "";
    /**
     * The associate's birthday
     *
     * @generated from field: string birthday = 22;
     */
    birthday = "";
    /**
     * The associate's anniversary
     *
     * @generated from field: string anniversary = 23;
     */
    anniversary = "";
    /**
     * A short description of the associate
     *
     * @generated from field: string description = 24;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Associate";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "department", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "work_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "personal_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "personal_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "anniversary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new Associate().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Associate().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Associate().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Associate, a, b);
    }
}
exports.Associate = Associate;
/**
 *
 * Describes the data structure that stores a list of associates
 *
 * @generated from message Scailo.AssociatesList
 */
class AssociatesList extends protobuf_1.Message {
    /**
     * List of associates
     *
     * @generated from field: repeated Scailo.Associate list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Associate, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesList, a, b);
    }
}
exports.AssociatesList = AssociatesList;
/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message Scailo.AssociatePaginationResp
 */
class AssociatePaginationResp extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Associate payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatePaginationResp";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Associate, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatePaginationResp().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatePaginationResp().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatePaginationResp().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatePaginationResp, a, b);
    }
}
exports.AssociatePaginationResp = AssociatePaginationResp;
/**
 *
 * Describes the necessary data structure during creation of a associate
 *
 * @generated from message Scailo.AssociatesServiceCreateRequest
 */
class AssociatesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The first name of the associate
     *
     * @generated from field: string first_name = 10;
     */
    firstName = "";
    /**
     * The middle name of the associate
     *
     * @generated from field: string middle_name = 11;
     */
    middleName = "";
    /**
     * The last name of the associate
     *
     * @generated from field: string last_name = 12;
     */
    lastName = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    /**
     * The name of the organization that the associate works in. Will be autofilled if the associate is associated with either a client or a vendor
     *
     * @generated from field: string org_name = 15;
     */
    orgName = "";
    /**
     * The job title of the associate
     *
     * @generated from field: string job_title = 16;
     */
    jobTitle = "";
    /**
     * The department that the associates works in
     *
     * @generated from field: string department = 17;
     */
    department = "";
    /**
     * The work phone number of the associate
     *
     * @generated from field: string work_phone = 18;
     */
    workPhone = "";
    /**
     * The work email address of the associate
     *
     * [(genesis_validate.rules).string.email = true];
     *
     * @generated from field: string work_email = 19;
     */
    workEmail = "";
    /**
     * The personal phone number of the associate
     *
     * @generated from field: string personal_phone = 20;
     */
    personalPhone = "";
    /**
     * The personal email address of the associate
     *
     * [(genesis_validate.rules).string.email = true];
     *
     * @generated from field: string personal_email = 21;
     */
    personalEmail = "";
    /**
     * The associate's birthday
     *
     * @generated from field: string birthday = 22;
     */
    birthday = "";
    /**
     * The associate's anniversary
     *
     * @generated from field: string anniversary = 23;
     */
    anniversary = "";
    /**
     * A short description of the associate
     *
     * @generated from field: string description = 24;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "department", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "work_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "personal_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "personal_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "anniversary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceCreateRequest, a, b);
    }
}
exports.AssociatesServiceCreateRequest = AssociatesServiceCreateRequest;
/**
 *
 * Describes the necessary data structure to import a list of associates
 *
 * @generated from message Scailo.AssociatesServiceImportRequest
 */
class AssociatesServiceImportRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The list of associates that need to be imported
     *
     * @generated from field: repeated Scailo.AssociatesServiceCreateRequest list = 10;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceImportRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "list", kind: "message", T: AssociatesServiceCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceImportRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceImportRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceImportRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceImportRequest, a, b);
    }
}
exports.AssociatesServiceImportRequest = AssociatesServiceImportRequest;
/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a associate
 *
 * @generated from message Scailo.AssociatesServiceUpdateRequest
 */
class AssociatesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the associate that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The first name of the associate
     *
     * @generated from field: string first_name = 10;
     */
    firstName = "";
    /**
     * The middle name of the associate
     *
     * @generated from field: string middle_name = 11;
     */
    middleName = "";
    /**
     * The last name of the associate
     *
     * @generated from field: string last_name = 12;
     */
    lastName = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    /**
     * The name of the organization that the associate works in. Will be autofilled if the associate is associated with either a client or a vendor
     *
     * @generated from field: string org_name = 15;
     */
    orgName = "";
    /**
     * The job title of the associate
     *
     * @generated from field: string job_title = 16;
     */
    jobTitle = "";
    /**
     * The department that the associates works in
     *
     * @generated from field: string department = 17;
     */
    department = "";
    /**
     * The work phone number of the associate
     *
     * @generated from field: string work_phone = 18;
     */
    workPhone = "";
    /**
     * The work email address of the associate
     *
     * [(genesis_validate.rules).string.email = true];
     *
     * @generated from field: string work_email = 19;
     */
    workEmail = "";
    /**
     * The personal phone number of the associate
     *
     * @generated from field: string personal_phone = 20;
     */
    personalPhone = "";
    /**
     * The personal email address of the associate
     *
     * [(genesis_validate.rules).string.email = true];
     *
     * @generated from field: string personal_email = 21;
     */
    personalEmail = "";
    /**
     * The associate's birthday
     *
     * @generated from field: string birthday = 22;
     */
    birthday = "";
    /**
     * The associate's anniversary
     *
     * @generated from field: string anniversary = 23;
     */
    anniversary = "";
    /**
     * A short description of the associate
     *
     * @generated from field: string description = 24;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "department", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "work_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "personal_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "personal_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "anniversary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceUpdateRequest, a, b);
    }
}
exports.AssociatesServiceUpdateRequest = AssociatesServiceUpdateRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.AssociatesServicePaginationReq
 */
class AssociatesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSOCIATE_SORT_KEY sort_key = 5;
     */
    sortKey = ASSOCIATE_SORT_KEY.ASSOCIATE_SORT_KEY_ID_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_SORT_KEY) },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServicePaginationReq, a, b);
    }
}
exports.AssociatesServicePaginationReq = AssociatesServicePaginationReq;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.AssociatesServiceFilterReq
 */
class AssociatesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ASSOCIATE_SORT_KEY sort_key = 5;
     */
    sortKey = ASSOCIATE_SORT_KEY.ASSOCIATE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The first name of the associate
     *
     * @generated from field: string first_name = 10;
     */
    firstName = "";
    /**
     * The middle name of the associate
     *
     * @generated from field: string middle_name = 11;
     */
    middleName = "";
    /**
     * The last name of the associate
     *
     * @generated from field: string last_name = 12;
     */
    lastName = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    /**
     * The name of the organization that the associate works in. Will be autofilled if the associate is associated with either a client or a vendor
     *
     * @generated from field: string org_name = 15;
     */
    orgName = "";
    /**
     * The job title of the associate
     *
     * @generated from field: string job_title = 16;
     */
    jobTitle = "";
    /**
     * The department that the associates works in
     *
     * @generated from field: string department = 17;
     */
    department = "";
    /**
     * The work phone number of the associate
     *
     * @generated from field: string work_phone = 18;
     */
    workPhone = "";
    /**
     * The work email address of the associate
     *
     * @generated from field: string work_email = 19;
     */
    workEmail = "";
    /**
     * The personal phone number of the associate
     *
     * @generated from field: string personal_phone = 20;
     */
    personalPhone = "";
    /**
     * The personal email address of the associate
     *
     * @generated from field: string personal_email = 21;
     */
    personalEmail = "";
    /**
     * The vendor ID of the associate (if this is used, then org_ref_from and org_ref_id combination is not necessary)
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The client ID of the associate (if this is used, then org_ref_from and org_ref_id combination is not necessary)
     *
     * @generated from field: uint64 client_id = 60;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "department", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "work_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "personal_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "personal_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceFilterReq, a, b);
    }
}
exports.AssociatesServiceFilterReq = AssociatesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.AssociatesServiceCountReq
 */
class AssociatesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The first name of the associate
     *
     * @generated from field: string first_name = 10;
     */
    firstName = "";
    /**
     * The middle name of the associate
     *
     * @generated from field: string middle_name = 11;
     */
    middleName = "";
    /**
     * The last name of the associate
     *
     * @generated from field: string last_name = 12;
     */
    lastName = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    /**
     * The name of the organization that the associate works in. Will be autofilled if the associate is associated with either a client or a vendor
     *
     * @generated from field: string org_name = 15;
     */
    orgName = "";
    /**
     * The job title of the associate
     *
     * @generated from field: string job_title = 16;
     */
    jobTitle = "";
    /**
     * The department that the associates works in
     *
     * @generated from field: string department = 17;
     */
    department = "";
    /**
     * The work phone number of the associate
     *
     * @generated from field: string work_phone = 18;
     */
    workPhone = "";
    /**
     * The work email address of the associate
     *
     * @generated from field: string work_email = 19;
     */
    workEmail = "";
    /**
     * The personal phone number of the associate
     *
     * @generated from field: string personal_phone = 20;
     */
    personalPhone = "";
    /**
     * The personal email address of the associate
     *
     * @generated from field: string personal_email = 21;
     */
    personalEmail = "";
    /**
     * The vendor ID of the associate (if this is used, then org_ref_from and org_ref_id combination is not necessary)
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The client ID of the associate (if this is used, then org_ref_from and org_ref_id combination is not necessary)
     *
     * @generated from field: uint64 client_id = 60;
     */
    clientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "department", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "work_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "work_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "personal_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "personal_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceCountReq, a, b);
    }
}
exports.AssociatesServiceCountReq = AssociatesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on associates
 *
 * @generated from message Scailo.AssociatesServiceSearchAllReq
 */
class AssociatesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the response
     *
     * @generated from field: Scailo.ASSOCIATE_SORT_KEY sort_key = 5;
     */
    sortKey = ASSOCIATE_SORT_KEY.ASSOCIATE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter associates
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 10;
     */
    searchKey = "";
    /**
     * The association with a vendor or a client
     *
     * @generated from field: Scailo.ASSOCIATE_ORG_REF_FROM org_ref_from = 13;
     */
    orgRefFrom = ASSOCIATE_ORG_REF_FROM.ASSOCIATE_ORG_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor or client
     *
     * @generated from field: uint64 org_ref_id = 14;
     */
    orgRefId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AssociatesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "org_ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(ASSOCIATE_ORG_REF_FROM) },
        { no: 14, name: "org_ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new AssociatesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AssociatesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AssociatesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AssociatesServiceSearchAllReq, a, b);
    }
}
exports.AssociatesServiceSearchAllReq = AssociatesServiceSearchAllReq;
