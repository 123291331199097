"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file forms_fields.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsFieldsService = void 0;
const forms_fields_scailo_pb_js_1 = require("./forms_fields.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the methods applicable on each form field
 *
 * @generated from service Scailo.FormsFieldsService
 */
exports.FormsFieldsService = {
    typeName: "Scailo.FormsFieldsService",
    methods: {
        /**
         * Create a form field
         *
         * @generated from rpc Scailo.FormsFieldsService.Create
         */
        create: {
            name: "Create",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServiceCreateRequest,
            O: forms_fields_scailo_pb_js_1.FormField,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update a form field
         *
         * @generated from rpc Scailo.FormsFieldsService.Update
         */
        update: {
            name: "Update",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServiceUpdateRequest,
            O: forms_fields_scailo_pb_js_1.FormField,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard the form field
         *
         * @generated from rpc Scailo.FormsFieldsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore the form field
         *
         * @generated from rpc Scailo.FormsFieldsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.FormsFieldsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: forms_fields_scailo_pb_js_1.FormField,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms fields
         *
         * @generated from rpc Scailo.FormsFieldsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: forms_fields_scailo_pb_js_1.FormsFieldsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms fields with the given entity UUID
         *
         * @generated from rpc Scailo.FormsFieldsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: forms_fields_scailo_pb_js_1.FormsFieldsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View forms fields with pagination
         *
         * @generated from rpc Scailo.FormsFieldsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServicePaginationReq,
            O: forms_fields_scailo_pb_js_1.FormFieldPaginationResp,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all forms fields that match the given search key
         *
         * @generated from rpc Scailo.FormsFieldsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServiceSearchAllReq,
            O: forms_fields_scailo_pb_js_1.FormsFieldsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.FormsFieldsService.Filter
         */
        filter: {
            name: "Filter",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServiceFilterReq,
            O: forms_fields_scailo_pb_js_1.FormsFieldsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.FormsFieldsService.Count
         */
        count: {
            name: "Count",
            I: forms_fields_scailo_pb_js_1.FormsFieldsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
