"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file labels.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelsService = void 0;
const labels_scailo_pb_js_1 = require("./labels.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the methods applicable on each label
 *
 * @generated from service Scailo.LabelsService
 */
exports.LabelsService = {
    typeName: "Scailo.LabelsService",
    methods: {
        /**
         * Create a label
         *
         * @generated from rpc Scailo.LabelsService.Create
         */
        create: {
            name: "Create",
            I: labels_scailo_pb_js_1.LabelsServiceCreateRequest,
            O: labels_scailo_pb_js_1.Label,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update a label
         *
         * @generated from rpc Scailo.LabelsService.Update
         */
        update: {
            name: "Update",
            I: labels_scailo_pb_js_1.LabelsServiceUpdateRequest,
            O: labels_scailo_pb_js_1.Label,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard the label
         *
         * @generated from rpc Scailo.LabelsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore the label
         *
         * @generated from rpc Scailo.LabelsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.LabelsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: labels_scailo_pb_js_1.Label,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all labels
         *
         * @generated from rpc Scailo.LabelsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: labels_scailo_pb_js_1.LabelsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all labels with the given entity UUID
         *
         * @generated from rpc Scailo.LabelsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: labels_scailo_pb_js_1.LabelsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View labels with pagination
         *
         * @generated from rpc Scailo.LabelsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: labels_scailo_pb_js_1.LabelsServicePaginationReq,
            O: labels_scailo_pb_js_1.LabelPaginationResp,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if the user has permission to modify an label
         *
         * @generated from rpc Scailo.LabelsService.CheckModifyPermission
         */
        checkModifyPermission: {
            name: "CheckModifyPermission",
            I: base_scailo_pb_js_1.Empty,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if the user has permission to add an label
         *
         * @generated from rpc Scailo.LabelsService.CheckAddPermission
         */
        checkAddPermission: {
            name: "CheckAddPermission",
            I: base_scailo_pb_js_1.Empty,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all labels that match the given search key
         *
         * @generated from rpc Scailo.LabelsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: labels_scailo_pb_js_1.LabelsServiceSearchAllReq,
            O: labels_scailo_pb_js_1.LabelsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.LabelsService.Filter
         */
        filter: {
            name: "Filter",
            I: labels_scailo_pb_js_1.LabelsServiceFilterReq,
            O: labels_scailo_pb_js_1.LabelsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.LabelsService.Count
         */
        count: {
            name: "Count",
            I: labels_scailo_pb_js_1.LabelsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
