"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file expenses.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpensesServiceSearchAllReq = exports.ExpensesServiceCountReq = exports.ExpensesServiceFilterReq = exports.ExpensesServicePaginationResponse = exports.ExpensesServicePaginationReq = exports.ExpensesServicePaginatedItemsResponse = exports.ExpenseItemsSearchRequest = exports.ExpenseItemHistoryRequest = exports.ExpensesItemsList = exports.ExpensesList = exports.ExpenseItem = exports.ExpensesServiceItemUpdateRequest = exports.ExpensesServiceItemCreateRequest = exports.Expense = exports.ExpensesServiceUpdateRequest = exports.ExpensesServiceCreateRequest = exports.EXPENSE_SORT_KEY = exports.EXPENSE_ITEM_STATUS = exports.EXPENSE_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EXPENSE_ITEM_SORT_KEY
 */
var EXPENSE_ITEM_SORT_KEY;
(function (EXPENSE_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EXPENSE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "EXPENSE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "EXPENSE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "EXPENSE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "EXPENSE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EXPENSE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the ledger ID
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_LEDGER_ID = 10;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_LEDGER_ID"] = 10] = "EXPENSE_ITEM_SORT_KEY_LEDGER_ID";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_TAX_GROUP_ID = 11;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_TAX_GROUP_ID"] = 11] = "EXPENSE_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch ordered results by the amount
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_AMOUNT = 12;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_AMOUNT"] = 12] = "EXPENSE_ITEM_SORT_KEY_AMOUNT";
    /**
     * Fetch ordered results by the date of expense
     *
     * @generated from enum value: EXPENSE_ITEM_SORT_KEY_DATE_OF_EXPENSE = 13;
     */
    EXPENSE_ITEM_SORT_KEY[EXPENSE_ITEM_SORT_KEY["EXPENSE_ITEM_SORT_KEY_DATE_OF_EXPENSE"] = 13] = "EXPENSE_ITEM_SORT_KEY_DATE_OF_EXPENSE";
})(EXPENSE_ITEM_SORT_KEY || (exports.EXPENSE_ITEM_SORT_KEY = EXPENSE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EXPENSE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EXPENSE_ITEM_SORT_KEY, "Scailo.EXPENSE_ITEM_SORT_KEY", [
    { no: 0, name: "EXPENSE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EXPENSE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EXPENSE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EXPENSE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EXPENSE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EXPENSE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "EXPENSE_ITEM_SORT_KEY_LEDGER_ID" },
    { no: 11, name: "EXPENSE_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 12, name: "EXPENSE_ITEM_SORT_KEY_AMOUNT" },
    { no: 13, name: "EXPENSE_ITEM_SORT_KEY_DATE_OF_EXPENSE" },
]);
/**
 *
 * Describes the applicable statuses of expense items
 *
 * @generated from enum Scailo.EXPENSE_ITEM_STATUS
 */
var EXPENSE_ITEM_STATUS;
(function (EXPENSE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: EXPENSE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    EXPENSE_ITEM_STATUS[EXPENSE_ITEM_STATUS["EXPENSE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "EXPENSE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the expense items must have been approved
     *
     * @generated from enum value: EXPENSE_ITEM_STATUS_APPROVED = 1;
     */
    EXPENSE_ITEM_STATUS[EXPENSE_ITEM_STATUS["EXPENSE_ITEM_STATUS_APPROVED"] = 1] = "EXPENSE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the expense items must be waiting for approval
     *
     * @generated from enum value: EXPENSE_ITEM_STATUS_UNAPPROVED = 2;
     */
    EXPENSE_ITEM_STATUS[EXPENSE_ITEM_STATUS["EXPENSE_ITEM_STATUS_UNAPPROVED"] = 2] = "EXPENSE_ITEM_STATUS_UNAPPROVED";
})(EXPENSE_ITEM_STATUS || (exports.EXPENSE_ITEM_STATUS = EXPENSE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(EXPENSE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(EXPENSE_ITEM_STATUS, "Scailo.EXPENSE_ITEM_STATUS", [
    { no: 0, name: "EXPENSE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "EXPENSE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "EXPENSE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.EXPENSE_SORT_KEY
 */
var EXPENSE_SORT_KEY;
(function (EXPENSE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: EXPENSE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "EXPENSE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: EXPENSE_SORT_KEY_CREATED_AT = 1;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_CREATED_AT"] = 1] = "EXPENSE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: EXPENSE_SORT_KEY_MODIFIED_AT = 2;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_MODIFIED_AT"] = 2] = "EXPENSE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: EXPENSE_SORT_KEY_APPROVED_ON = 3;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_APPROVED_ON"] = 3] = "EXPENSE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: EXPENSE_SORT_KEY_APPROVED_BY = 4;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_APPROVED_BY"] = 4] = "EXPENSE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: EXPENSE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "EXPENSE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: EXPENSE_SORT_KEY_COMPLETED_ON = 6;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_COMPLETED_ON"] = 6] = "EXPENSE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: EXPENSE_SORT_KEY_REFERENCE_ID = 10;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_REFERENCE_ID"] = 10] = "EXPENSE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: EXPENSE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "EXPENSE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the currency ID
     *
     * @generated from enum value: EXPENSE_SORT_KEY_CURRENCY_ID = 12;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_CURRENCY_ID"] = 12] = "EXPENSE_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch ordered results by the user ID who paid for the expense
     *
     * @generated from enum value: EXPENSE_SORT_KEY_PAID_BY_USER_ID = 13;
     */
    EXPENSE_SORT_KEY[EXPENSE_SORT_KEY["EXPENSE_SORT_KEY_PAID_BY_USER_ID"] = 13] = "EXPENSE_SORT_KEY_PAID_BY_USER_ID";
})(EXPENSE_SORT_KEY || (exports.EXPENSE_SORT_KEY = EXPENSE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(EXPENSE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(EXPENSE_SORT_KEY, "Scailo.EXPENSE_SORT_KEY", [
    { no: 0, name: "EXPENSE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "EXPENSE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "EXPENSE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "EXPENSE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "EXPENSE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "EXPENSE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "EXPENSE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "EXPENSE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "EXPENSE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "EXPENSE_SORT_KEY_CURRENCY_ID" },
    { no: 13, name: "EXPENSE_SORT_KEY_PAID_BY_USER_ID" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ExpensesServiceCreateRequest
 */
class ExpensesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the expense
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The reference on which the expense has been created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The ID of the associated reference (can be 0 in case ref_from is "not-applicable")
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user who paid for this expense (if paid by organization, then this will be 0)
     *
     * @generated from field: uint64 paid_by_user_id = 15;
     */
    paidByUserId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "paid_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceCreateRequest, a, b);
    }
}
exports.ExpensesServiceCreateRequest = ExpensesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ExpensesServiceUpdateRequest
 */
class ExpensesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the expense
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The reference on which the expense has been created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The ID of the associated reference (can be 0 in case ref_from is "not-applicable")
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user who paid for this expense (if paid by organization, then this will be 0)
     *
     * @generated from field: uint64 paid_by_user_id = 15;
     */
    paidByUserId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "paid_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceUpdateRequest, a, b);
    }
}
exports.ExpensesServiceUpdateRequest = ExpensesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Expense
 */
class Expense extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this expense
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this expense
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this expense
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this expense was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 8;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the expense
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The reference on which the expense has been created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The ID of the associated reference (can be 0 in case ref_from is "not-applicable")
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user who paid for this expense (if paid by organization, then this will be 0)
     *
     * @generated from field: uint64 paid_by_user_id = 15;
     */
    paidByUserId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated expense items
     *
     * @generated from field: repeated Scailo.ExpenseItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    /**
     * The total amount of the expense
     *
     * @generated from field: double total_amount = 40;
     */
    totalAmount = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Expense";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "paid_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: ExpenseItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
        { no: 40, name: "total_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new Expense().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Expense().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Expense().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Expense, a, b);
    }
}
exports.Expense = Expense;
/**
 *
 * Describes the parameters required to add an item to a expense
 *
 * @generated from message Scailo.ExpensesServiceItemCreateRequest
 */
class ExpensesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the expense ID
     *
     * @generated from field: uint64 expense_id = 10;
     */
    expenseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The associated bill number
     *
     * @generated from field: string bill_no = 12;
     */
    billNo = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The ID of the associated ledger account (can be 0 temporarily)
     *
     * @generated from field: uint64 ledger_id = 15;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: uint64 amount = 17;
     */
    amount = protobuf_1.protoInt64.zero;
    /**
     * The date on which this expense was incurred
     *
     * @generated from field: string date_of_expense = 18;
     */
    dateOfExpense = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "expense_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "date_of_expense", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceItemCreateRequest, a, b);
    }
}
exports.ExpensesServiceItemCreateRequest = ExpensesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a expense
 *
 * @generated from message Scailo.ExpensesServiceItemUpdateRequest
 */
class ExpensesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The associated bill number
     *
     * @generated from field: string bill_no = 12;
     */
    billNo = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The ID of the associated ledger account (can be 0 temporarily)
     *
     * @generated from field: uint64 ledger_id = 15;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: uint64 amount = 17;
     */
    amount = protobuf_1.protoInt64.zero;
    /**
     * The date on which this expense was incurred
     *
     * @generated from field: string date_of_expense = 18;
     */
    dateOfExpense = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "date_of_expense", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceItemUpdateRequest, a, b);
    }
}
exports.ExpensesServiceItemUpdateRequest = ExpensesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a expense
 *
 * @generated from message Scailo.ExpenseItem
 */
class ExpenseItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this expense
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the expense ID
     *
     * @generated from field: uint64 expense_id = 10;
     */
    expenseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The associated bill number
     *
     * @generated from field: string bill_no = 12;
     */
    billNo = "";
    /**
     * The description of the item
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The ID of the associated ledger account (can be 0 temporarily)
     *
     * @generated from field: uint64 ledger_id = 15;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The total amount of this item (in cents)
     *
     * @generated from field: uint64 amount = 17;
     */
    amount = protobuf_1.protoInt64.zero;
    /**
     * The date on which this expense was incurred
     *
     * @generated from field: string date_of_expense = 18;
     */
    dateOfExpense = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpenseItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "expense_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "date_of_expense", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpenseItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpenseItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpenseItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpenseItem, a, b);
    }
}
exports.ExpenseItem = ExpenseItem;
/**
 *
 * Describes the message consisting of the list of expenses
 *
 * @generated from message Scailo.ExpensesList
 */
class ExpensesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Expense list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Expense, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesList, a, b);
    }
}
exports.ExpensesList = ExpensesList;
/**
 *
 * Describes the message consisting of the list of expense items
 *
 * @generated from message Scailo.ExpensesItemsList
 */
class ExpensesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ExpenseItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ExpenseItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesItemsList, a, b);
    }
}
exports.ExpensesItemsList = ExpensesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ExpenseItemHistoryRequest
 */
class ExpenseItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the expense ID
     *
     * @generated from field: uint64 expense_id = 10;
     */
    expenseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpenseItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "expense_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpenseItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpenseItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpenseItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpenseItemHistoryRequest, a, b);
    }
}
exports.ExpenseItemHistoryRequest = ExpenseItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.ExpenseItemsSearchRequest
 */
class ExpenseItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EXPENSE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = EXPENSE_ITEM_SORT_KEY.EXPENSE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.EXPENSE_ITEM_STATUS status = 7;
     */
    status = EXPENSE_ITEM_STATUS.EXPENSE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the expense ID
     *
     * @generated from field: uint64 expense_id = 20;
     */
    expenseId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated ledger account
     *
     * @generated from field: uint64 ledger_id = 25;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 26;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The date on which this expense was incurred
     *
     * @generated from field: string date_of_expense = 28;
     */
    dateOfExpense = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpenseItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EXPENSE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(EXPENSE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "expense_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "date_of_expense", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpenseItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpenseItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpenseItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpenseItemsSearchRequest, a, b);
    }
}
exports.ExpenseItemsSearchRequest = ExpenseItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.ExpensesServicePaginatedItemsResponse
 */
class ExpensesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ExpenseItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ExpenseItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServicePaginatedItemsResponse, a, b);
    }
}
exports.ExpensesServicePaginatedItemsResponse = ExpensesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ExpensesServicePaginationReq
 */
class ExpensesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EXPENSE_SORT_KEY sort_key = 5;
     */
    sortKey = EXPENSE_SORT_KEY.EXPENSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this expense
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EXPENSE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServicePaginationReq, a, b);
    }
}
exports.ExpensesServicePaginationReq = ExpensesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ExpensesServicePaginationResponse
 */
class ExpensesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Expense payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Expense, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServicePaginationResponse, a, b);
    }
}
exports.ExpensesServicePaginationResponse = ExpensesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ExpensesServiceFilterReq
 */
class ExpensesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EXPENSE_SORT_KEY sort_key = 5;
     */
    sortKey = EXPENSE_SORT_KEY.EXPENSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this expense
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the expense
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The currency ID of the expense
     *
     * @generated from field: uint64 currency_id = 22;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user who paid for this expense (if paid by organization, then this will be 0)
     *
     * @generated from field: uint64 paid_by_user_id = 23;
     */
    paidByUserId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 30;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EXPENSE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "paid_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceFilterReq, a, b);
    }
}
exports.ExpensesServiceFilterReq = ExpensesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ExpensesServiceCountReq
 */
class ExpensesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this expense
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the expense
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The currency ID of the expense
     *
     * @generated from field: uint64 currency_id = 22;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the user who paid for this expense (if paid by organization, then this will be 0)
     *
     * @generated from field: uint64 paid_by_user_id = 23;
     */
    paidByUserId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 30;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "paid_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceCountReq, a, b);
    }
}
exports.ExpensesServiceCountReq = ExpensesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ExpensesServiceSearchAllReq
 */
class ExpensesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.EXPENSE_SORT_KEY sort_key = 5;
     */
    sortKey = EXPENSE_SORT_KEY.EXPENSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ExpensesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(EXPENSE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ExpensesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExpensesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExpensesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ExpensesServiceSearchAllReq, a, b);
    }
}
exports.ExpensesServiceSearchAllReq = ExpensesServiceSearchAllReq;
