"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file general_settings.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralSettings = exports.GeneralSettingsUpdateRequest = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the parameters necessary to update the general settings for the organization
 *
 * @generated from message Scailo.GeneralSettingsUpdateRequest
 */
class GeneralSettingsUpdateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * The name of the organization
     *
     * @generated from field: string company_name = 10;
     */
    companyName = "";
    /**
     * The phone number of the organization
     *
     * @generated from field: string phone = 11;
     */
    phone = "";
    /**
     * The email of the organization
     *
     * @generated from field: string email = 12;
     */
    email = "";
    /**
     * The address of the organization
     *
     * @generated from field: string address = 13;
     */
    address = "";
    /**
     * The city of the organization
     *
     * @generated from field: string city = 14;
     */
    city = "";
    /**
     * The state of the organization
     *
     * @generated from field: string state = 15;
     */
    state = "";
    /**
     * The pin code of the organization
     *
     * @generated from field: string pin_code = 16;
     */
    pinCode = "";
    /**
     * The country of the organization
     *
     * @generated from field: string country = 17;
     */
    country = "";
    /**
     * The Company Identification Number
     *
     * @generated from field: string cin = 18;
     */
    cin = "";
    /**
     * The PAN number of the organization
     *
     * @generated from field: string pan = 19;
     */
    pan = "";
    /**
     * The GSTIN of the organization
     *
     * @generated from field: string gstin = 20;
     */
    gstin = "";
    /**
     * The name of the domain under which this application is run
     *
     * @generated from field: string domain_name = 40;
     */
    domainName = "";
    /**
     * The email address from which purchase orders will be sent
     *
     * @generated from field: string purchase_order_sender_email = 50;
     */
    purchaseOrderSenderEmail = "";
    /**
     * The email address from which sales enquiries will be sent
     *
     * @generated from field: string sales_enquiry_sender_email = 51;
     */
    salesEnquirySenderEmail = "";
    /**
     * The email address from which sales orders will be sent
     *
     * @generated from field: string sales_order_sender_email = 52;
     */
    salesOrderSenderEmail = "";
    /**
     * The email address from which sales invoices will be sent
     *
     * @generated from field: string sales_invoice_sender_email = 53;
     */
    salesInvoiceSenderEmail = "";
    /**
     * The email address from which sales quotations will be sent
     *
     * @generated from field: string sales_quotation_sender_email = 54;
     */
    salesQuotationSenderEmail = "";
    /**
     * The email address from which vendor invoices will be sent
     *
     * @generated from field: string vendor_invoice_sender_email = 55;
     */
    vendorInvoiceSenderEmail = "";
    /**
     * The email address from which work orders will be sent
     *
     * @generated from field: string work_order_sender_email = 56;
     */
    workOrderSenderEmail = "";
    /**
     * The email address from which expenses will be sent
     *
     * @generated from field: string expense_sender_email = 57;
     */
    expenseSenderEmail = "";
    /**
     * Stores if vendor invoices should not be created with bill date that is after the approval of a purchase order
     *
     * @generated from field: bool disable_vi_creation_with_bill_date_after_po_approval = 70;
     */
    disableViCreationWithBillDateAfterPoApproval = false;
    /**
     * Stores if MFA is required for record approval
     *
     * @generated from field: bool require_mfa_for_record_approval = 71;
     */
    requireMfaForRecordApproval = false;
    /**
     * Stores if attendances should be automatically sent for verification on exit record
     *
     * @generated from field: bool auto_req_verify_on_exit_record_entry = 72;
     */
    autoReqVerifyOnExitRecordEntry = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralSettingsUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "pin_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "cin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "pan", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "gstin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "domain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "purchase_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 51, name: "sales_enquiry_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 52, name: "sales_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 53, name: "sales_invoice_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 54, name: "sales_quotation_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 55, name: "vendor_invoice_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 56, name: "work_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 57, name: "expense_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "disable_vi_creation_with_bill_date_after_po_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 71, name: "require_mfa_for_record_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 72, name: "auto_req_verify_on_exit_record_entry", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralSettingsUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralSettingsUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralSettingsUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralSettingsUpdateRequest, a, b);
    }
}
exports.GeneralSettingsUpdateRequest = GeneralSettingsUpdateRequest;
/**
 *
 * Describes the available general settings
 *
 * @generated from message Scailo.GeneralSettings
 */
class GeneralSettings extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The name of the organization
     *
     * @generated from field: string company_name = 10;
     */
    companyName = "";
    /**
     * The phone number of the organization
     *
     * @generated from field: string phone = 11;
     */
    phone = "";
    /**
     * The email of the organization
     *
     * @generated from field: string email = 12;
     */
    email = "";
    /**
     * The address of the organization
     *
     * @generated from field: string address = 13;
     */
    address = "";
    /**
     * The city of the organization
     *
     * @generated from field: string city = 14;
     */
    city = "";
    /**
     * The state of the organization
     *
     * @generated from field: string state = 15;
     */
    state = "";
    /**
     * The pin code of the organization
     *
     * @generated from field: string pin_code = 16;
     */
    pinCode = "";
    /**
     * The country of the organization
     *
     * @generated from field: string country = 17;
     */
    country = "";
    /**
     * The Company Identification Number
     *
     * @generated from field: string cin = 18;
     */
    cin = "";
    /**
     * The PAN number of the organization
     *
     * @generated from field: string pan = 19;
     */
    pan = "";
    /**
     * The GSTIN of the organization
     *
     * @generated from field: string gstin = 20;
     */
    gstin = "";
    /**
     * The name of the domain under which this application is run
     *
     * @generated from field: string domain_name = 40;
     */
    domainName = "";
    /**
     * The email address from which purchase orders will be sent
     *
     * @generated from field: string purchase_order_sender_email = 50;
     */
    purchaseOrderSenderEmail = "";
    /**
     * The email address from which sales enquiries will be sent
     *
     * @generated from field: string sales_enquiry_sender_email = 51;
     */
    salesEnquirySenderEmail = "";
    /**
     * The email address from which sales orders will be sent
     *
     * @generated from field: string sales_order_sender_email = 52;
     */
    salesOrderSenderEmail = "";
    /**
     * The email address from which sales invoices will be sent
     *
     * @generated from field: string sales_invoice_sender_email = 53;
     */
    salesInvoiceSenderEmail = "";
    /**
     * The email address from which sales quotations will be sent
     *
     * @generated from field: string sales_quotation_sender_email = 54;
     */
    salesQuotationSenderEmail = "";
    /**
     * The email address from which vendor invoices will be sent
     *
     * @generated from field: string vendor_invoice_sender_email = 55;
     */
    vendorInvoiceSenderEmail = "";
    /**
     * The email address from which work orders will be sent
     *
     * @generated from field: string work_order_sender_email = 56;
     */
    workOrderSenderEmail = "";
    /**
     * The email address from which expenses will be sent
     *
     * @generated from field: string expense_sender_email = 57;
     */
    expenseSenderEmail = "";
    /**
     * Stores if vendor invoices should not be created with bill date that is after the approval of a purchase order
     *
     * @generated from field: bool disable_vi_creation_with_bill_date_after_po_approval = 70;
     */
    disableViCreationWithBillDateAfterPoApproval = false;
    /**
     * Stores if MFA is required for record approval
     *
     * @generated from field: bool require_mfa_for_record_approval = 71;
     */
    requireMfaForRecordApproval = false;
    /**
     * Stores if attendances should be automatically sent for verification on exit record
     *
     * @generated from field: bool auto_req_verify_on_exit_record_entry = 72;
     */
    autoReqVerifyOnExitRecordEntry = false;
    /**
     * Stores the name of the organization that holds the license
     *
     * @generated from field: string licensed_to = 100;
     */
    licensedTo = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GeneralSettings";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "pin_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "cin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "pan", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "gstin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "domain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "purchase_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 51, name: "sales_enquiry_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 52, name: "sales_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 53, name: "sales_invoice_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 54, name: "sales_quotation_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 55, name: "vendor_invoice_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 56, name: "work_order_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 57, name: "expense_sender_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 70, name: "disable_vi_creation_with_bill_date_after_po_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 71, name: "require_mfa_for_record_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 72, name: "auto_req_verify_on_exit_record_entry", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 100, name: "licensed_to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GeneralSettings().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneralSettings().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneralSettings().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GeneralSettings, a, b);
    }
}
exports.GeneralSettings = GeneralSettings;
