"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file qc_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QCGroupsServiceSearchAllReq = exports.QCGroupsServiceCountReq = exports.QCGroupsServiceFilterReq = exports.QCGroupsServicePaginationResponse = exports.QCGroupsServicePaginationReq = exports.QCGroupItemHistoryRequest = exports.QCGroupsItemsList = exports.QCGroupsList = exports.QCGroupItem = exports.QCGroupsServiceItemUpdateRequest = exports.QCGroupsServiceItemCreateRequest = exports.QCGroup = exports.QCGroupsServiceUpdateRequest = exports.QCGroupsServiceCreateRequest = exports.QC_GROUP_SORT_KEY = exports.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available input types for a qc group item (with possible vbalues being number-absolute, number-percentage, text-input, text-dropdown)
 *
 * @generated from enum Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE
 */
var QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE;
(function (QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE) {
    /**
     * Acceptable value type is an absolute number (with min and max defined in number_min_value and number_max_value)
     *
     * @generated from enum value: QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED = 0;
     */
    QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE[QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE["QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED"] = 0] = "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED";
    /**
     * Acceptable value type is a percentage
     *
     * @generated from enum value: QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_PERCENTAGE = 1;
     */
    QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE[QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE["QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_PERCENTAGE"] = 1] = "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_PERCENTAGE";
    /**
     * Acceptable value type is a textual input
     *
     * @generated from enum value: QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_INPUT = 2;
     */
    QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE[QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE["QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_INPUT"] = 2] = "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_INPUT";
    /**
     * Acceptable value type is a dropdown (from the values as defined in text_values)
     *
     * @generated from enum value: QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_DROPDOWN = 3;
     */
    QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE[QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE["QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_DROPDOWN"] = 3] = "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_DROPDOWN";
})(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE || (exports.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE = QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE)
protobuf_1.proto3.util.setEnumType(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE, "Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE", [
    { no: 0, name: "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED" },
    { no: 1, name: "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_PERCENTAGE" },
    { no: 2, name: "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_INPUT" },
    { no: 3, name: "QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_TEXT_DROPDOWN" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QC_GROUP_SORT_KEY
 */
var QC_GROUP_SORT_KEY;
(function (QC_GROUP_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QC_GROUP_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_CREATED_AT = 1;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_CREATED_AT"] = 1] = "QC_GROUP_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_MODIFIED_AT = 2;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_MODIFIED_AT"] = 2] = "QC_GROUP_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_APPROVED_ON = 3;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_APPROVED_ON"] = 3] = "QC_GROUP_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_APPROVED_BY = 4;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_APPROVED_BY"] = 4] = "QC_GROUP_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QC_GROUP_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_COMPLETED_ON = 6;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_COMPLETED_ON"] = 6] = "QC_GROUP_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: QC_GROUP_SORT_KEY_NAME = 10;
     */
    QC_GROUP_SORT_KEY[QC_GROUP_SORT_KEY["QC_GROUP_SORT_KEY_NAME"] = 10] = "QC_GROUP_SORT_KEY_NAME";
})(QC_GROUP_SORT_KEY || (exports.QC_GROUP_SORT_KEY = QC_GROUP_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QC_GROUP_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QC_GROUP_SORT_KEY, "Scailo.QC_GROUP_SORT_KEY", [
    { no: 0, name: "QC_GROUP_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QC_GROUP_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QC_GROUP_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QC_GROUP_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QC_GROUP_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QC_GROUP_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "QC_GROUP_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "QC_GROUP_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.QCGroupsServiceCreateRequest
 */
class QCGroupsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The qc group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the qc group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceCreateRequest, a, b);
    }
}
exports.QCGroupsServiceCreateRequest = QCGroupsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.QCGroupsServiceUpdateRequest
 */
class QCGroupsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The qc group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the qc group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceUpdateRequest, a, b);
    }
}
exports.QCGroupsServiceUpdateRequest = QCGroupsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.QCGroup
 */
class QCGroup extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this qc group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this qc group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this qc group
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this qc group was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc group
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The qc group code
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the qc group
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The list of associated qc group params
     *
     * @generated from field: repeated Scailo.QCGroupItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroup";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: QCGroupItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroup().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroup().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroup().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroup, a, b);
    }
}
exports.QCGroup = QCGroup;
/**
 *
 * Describes the parameters required to add a param to a qc group
 *
 * @generated from message Scailo.QCGroupsServiceItemCreateRequest
 */
class QCGroupsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 10;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc param that is a part of the qc group
     *
     * @generated from field: uint64 qc_param_id = 11;
     */
    qcParamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 12;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Denotes if this parameter is internal or not (if set to true, then this value will be hidden. If set to false, then this value will be printed)
     *
     * @generated from field: bool is_internal = 19;
     */
    isInternal = false;
    /**
     * The acceptable value type of the item
     *
     * @generated from field: Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE acceptable_value_type = 20;
     */
    acceptableValueType = QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The acceptable value (in cents) in case the acceptable value type is number-absolute or number-percentage
     *
     * @generated from field: int64 number_acceptable_value = 21;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The relative lower bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_lower_bound = 22;
     */
    numberRelativeLowerBound = protobuf_1.protoInt64.zero;
    /**
     * The relative upper bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_upper_bound = 23;
     */
    numberRelativeUpperBound = protobuf_1.protoInt64.zero;
    /**
     * The list of values that are acceptable in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values = 30;
     */
    textAcceptableValues = [];
    /**
     * The list of values that are acceptable (but with deviation) in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values_with_deviation = 31;
     */
    textAcceptableValuesWithDeviation = [];
    /**
     * The list of unacceptable values in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_unacceptable_values = 32;
     */
    textUnacceptableValues = [];
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "is_internal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "acceptable_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE) },
        { no: 21, name: "number_acceptable_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "number_relative_lower_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "number_relative_upper_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "text_acceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_values_with_deviation", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 32, name: "text_unacceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceItemCreateRequest, a, b);
    }
}
exports.QCGroupsServiceItemCreateRequest = QCGroupsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update a param in a qc group
 *
 * @generated from message Scailo.QCGroupsServiceItemUpdateRequest
 */
class QCGroupsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Denotes if this parameter is internal or not (if set to true, then this value will be hidden. If set to false, then this value will be printed)
     *
     * @generated from field: bool is_internal = 19;
     */
    isInternal = false;
    /**
     * The acceptable value type of the item
     *
     * @generated from field: Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE acceptable_value_type = 20;
     */
    acceptableValueType = QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The acceptable value (in cents) in case the acceptable value type is number-absolute or number-percentage
     *
     * @generated from field: int64 number_acceptable_value = 21;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The relative lower bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_lower_bound = 22;
     */
    numberRelativeLowerBound = protobuf_1.protoInt64.zero;
    /**
     * The relative upper bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_upper_bound = 23;
     */
    numberRelativeUpperBound = protobuf_1.protoInt64.zero;
    /**
     * The list of values that are acceptable in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values = 30;
     */
    textAcceptableValues = [];
    /**
     * The list of values that are acceptable (but with deviation) in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values_with_deviation = 31;
     */
    textAcceptableValuesWithDeviation = [];
    /**
     * The list of unacceptable values in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_unacceptable_values = 32;
     */
    textUnacceptableValues = [];
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "is_internal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "acceptable_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE) },
        { no: 21, name: "number_acceptable_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "number_relative_lower_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "number_relative_upper_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "text_acceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_values_with_deviation", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 32, name: "text_unacceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceItemUpdateRequest, a, b);
    }
}
exports.QCGroupsServiceItemUpdateRequest = QCGroupsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute a param associated to a qc group
 *
 * @generated from message Scailo.QCGroupItem
 */
class QCGroupItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this qc group
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 10;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc param that is a part of the qc group
     *
     * @generated from field: uint64 qc_param_id = 11;
     */
    qcParamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 12;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Denotes if this parameter is internal or not (if set to true, then this value will be hidden. If set to false, then this value will be printed)
     *
     * @generated from field: bool is_internal = 19;
     */
    isInternal = false;
    /**
     * The acceptable value type of the item
     *
     * @generated from field: Scailo.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE acceptable_value_type = 20;
     */
    acceptableValueType = QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE_UNSPECIFIED;
    /**
     * The acceptable value (in cents) in case the acceptable value type is number-absolute or number-percentage
     *
     * @generated from field: int64 number_acceptable_value = 21;
     */
    numberAcceptableValue = protobuf_1.protoInt64.zero;
    /**
     * The relative lower bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_lower_bound = 22;
     */
    numberRelativeLowerBound = protobuf_1.protoInt64.zero;
    /**
     * The relative upper bound (in cents) of the number_acceptable_value that serves as a reference point for the test
     *
     * @generated from field: int64 number_relative_upper_bound = 23;
     */
    numberRelativeUpperBound = protobuf_1.protoInt64.zero;
    /**
     * The list of values that are acceptable in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values = 30;
     */
    textAcceptableValues = [];
    /**
     * The list of values that are acceptable (but with deviation) in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_acceptable_values_with_deviation = 31;
     */
    textAcceptableValuesWithDeviation = [];
    /**
     * The list of unacceptable values in case the acceptable value type is text-input or text-dropdown
     *
     * @generated from field: repeated string text_unacceptable_values = 32;
     */
    textUnacceptableValues = [];
    /**
     * An optional description
     *
     * @generated from field: string description = 40;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "is_internal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "acceptable_value_type", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE) },
        { no: 21, name: "number_acceptable_value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 22, name: "number_relative_lower_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 23, name: "number_relative_upper_bound", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "text_acceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 31, name: "text_acceptable_values_with_deviation", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 32, name: "text_unacceptable_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 40, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupItem, a, b);
    }
}
exports.QCGroupItem = QCGroupItem;
/**
 *
 * Describes the message consisting of the list of qc groups
 *
 * @generated from message Scailo.QCGroupsList
 */
class QCGroupsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCGroup list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsList, a, b);
    }
}
exports.QCGroupsList = QCGroupsList;
/**
 *
 * Describes the message consisting of the list of qc group params
 *
 * @generated from message Scailo.QCGroupsItemsList
 */
class QCGroupsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QCGroupItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QCGroupItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsItemsList, a, b);
    }
}
exports.QCGroupsItemsList = QCGroupsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.QCGroupItemHistoryRequest
 */
class QCGroupItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the ID of the qc group
     *
     * @generated from field: uint64 qc_group_id = 10;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the qc param that is a part of the qc group
     *
     * @generated from field: uint64 qc_param_id = 11;
     */
    qcParamId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_param_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupItemHistoryRequest, a, b);
    }
}
exports.QCGroupItemHistoryRequest = QCGroupItemHistoryRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.QCGroupsServicePaginationReq
 */
class QCGroupsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = QC_GROUP_SORT_KEY.QC_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this qc group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServicePaginationReq, a, b);
    }
}
exports.QCGroupsServicePaginationReq = QCGroupsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.QCGroupsServicePaginationResponse
 */
class QCGroupsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QCGroup payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QCGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServicePaginationResponse, a, b);
    }
}
exports.QCGroupsServicePaginationResponse = QCGroupsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.QCGroupsServiceFilterReq
 */
class QCGroupsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = QC_GROUP_SORT_KEY.QC_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this qc group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The qc group code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * Retrieve qc groups that are assigned to the given family ID
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceFilterReq, a, b);
    }
}
exports.QCGroupsServiceFilterReq = QCGroupsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.QCGroupsServiceCountReq
 */
class QCGroupsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this qc group
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the qc group
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The qc group code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * Retrieve qc groups that are assigned to the given family ID
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceCountReq, a, b);
    }
}
exports.QCGroupsServiceCountReq = QCGroupsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.QCGroupsServiceSearchAllReq
 */
class QCGroupsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QC_GROUP_SORT_KEY sort_key = 5;
     */
    sortKey = QC_GROUP_SORT_KEY.QC_GROUP_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * Retrieve qc groups that are assigned to the given family ID
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QCGroupsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QC_GROUP_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QCGroupsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QCGroupsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QCGroupsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QCGroupsServiceSearchAllReq, a, b);
    }
}
exports.QCGroupsServiceSearchAllReq = QCGroupsServiceSearchAllReq;
