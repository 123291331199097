"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file products.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsService = void 0;
const products_scailo_pb_js_1 = require("./products.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each product
 *
 * @generated from service Scailo.ProductsService
 */
exports.ProductsService = {
    typeName: "Scailo.ProductsService",
    methods: {
        /**
         * Create (and Send for QC)
         *
         * @generated from rpc Scailo.ProductsService.Create
         */
        create: {
            name: "Create",
            I: products_scailo_pb_js_1.ProductsServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send to Store
         *
         * @generated from rpc Scailo.ProductsService.SendToStore
         */
        sendToStore: {
            name: "SendToStore",
            I: products_scailo_pb_js_1.ProductsServiceSendToStoreRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.ProductsService.Update
         */
        update: {
            name: "Update",
            I: products_scailo_pb_js_1.ProductsServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for Rework
         *
         * @generated from rpc Scailo.ProductsService.SendForRework
         */
        sendForRework: {
            name: "SendForRework",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for QC
         *
         * @generated from rpc Scailo.ProductsService.SendForQC
         */
        sendForQC: {
            name: "SendForQC",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Split into unit quantities
         *
         * @generated from rpc Scailo.ProductsService.SplitLot
         */
        splitLot: {
            name: "SplitLot",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create a partition from the parent lot (and returns the identifier of the new partition)
         *
         * @generated from rpc Scailo.ProductsService.Partition
         */
        partition: {
            name: "Partition",
            I: base_scailo_pb_js_1.InventoryPartitionRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Consume
         *
         * @generated from rpc Scailo.ProductsService.Consume
         */
        consume: {
            name: "Consume",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reject
         *
         * @generated from rpc Scailo.ProductsService.Reject
         */
        reject: {
            name: "Reject",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Scrap
         *
         * @generated from rpc Scailo.ProductsService.Scrap
         */
        scrap: {
            name: "Scrap",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ProductsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ProductsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ProductsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: products_scailo_pb_js_1.Product,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ProductsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: products_scailo_pb_js_1.Product,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ProductsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: products_scailo_pb_js_1.ProductsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ProductsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: products_scailo_pb_js_1.ProductsServicePaginationReq,
            O: products_scailo_pb_js_1.ProductsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given Identifier
         *
         * @generated from rpc Scailo.ProductsService.DownloadQCReportByID
         */
        downloadQCReportByID: {
            name: "DownloadQCReportByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ProductsService.DownloadQCReportByUUID
         */
        downloadQCReportByUUID: {
            name: "DownloadQCReportByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given Identifier
         *
         * @generated from rpc Scailo.ProductsService.DownloadLabelByID
         */
        downloadLabelByID: {
            name: "DownloadLabelByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ProductsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all inventory interactions for product with the given IdentifierUUID
         *
         * @generated from rpc Scailo.ProductsService.ViewInventoryInteractions
         */
        viewInventoryInteractions: {
            name: "ViewInventoryInteractions",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.InventoryInteractionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ProductsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: products_scailo_pb_js_1.ProductsServiceSearchAllReq,
            O: products_scailo_pb_js_1.ProductsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ProductsService.Filter
         */
        filter: {
            name: "Filter",
            I: products_scailo_pb_js_1.ProductsServiceFilterReq,
            O: products_scailo_pb_js_1.ProductsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ProductsService.Count
         */
        count: {
            name: "Count",
            I: products_scailo_pb_js_1.ProductsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
