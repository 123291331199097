"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file goods_dispatches.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoodsDispatchesService = void 0;
const goods_dispatches_scailo_pb_js_1 = require("./goods_dispatches.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each goods dispatch
 *
 * @generated from service Scailo.GoodsDispatchesService
 */
exports.GoodsDispatchesService = {
    typeName: "Scailo.GoodsDispatchesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Create
         */
        create: {
            name: "Create",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Draft
         */
        draft: {
            name: "Draft",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.GoodsDispatchesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.GoodsDispatchesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.GoodsDispatchesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.GoodsDispatchesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.GoodsDispatchesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.GoodsDispatchesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceAutofillRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Goods Dispatch can be marked as completed
         *
         * @generated from rpc Scailo.GoodsDispatchesService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.AddGoodsDispatchItem
         */
        addGoodsDispatchItem: {
            name: "AddGoodsDispatchItem",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ModifyGoodsDispatchItem
         */
        modifyGoodsDispatchItem: {
            name: "ModifyGoodsDispatchItem",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ApproveGoodsDispatchItem
         */
        approveGoodsDispatchItem: {
            name: "ApproveGoodsDispatchItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.DeleteGoodsDispatchItem
         */
        deleteGoodsDispatchItem: {
            name: "DeleteGoodsDispatchItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ReorderGoodsDispatchItems
         */
        reorderGoodsDispatchItems: {
            name: "ReorderGoodsDispatchItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Goods Dispatch Item by ID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewGoodsDispatchItemByID
         */
        viewGoodsDispatchItemByID: {
            name: "ViewGoodsDispatchItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Goods Dispatch Item by Inventory Hash
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewGoodsDispatchItemByInventoryHash
         */
        viewGoodsDispatchItemByInventoryHash: {
            name: "ViewGoodsDispatchItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goods dispatch items for given goods dispatch ID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewApprovedGoodsDispatchItems
         */
        viewApprovedGoodsDispatchItems: {
            name: "ViewApprovedGoodsDispatchItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goods dispatch items for given goods dispatch ID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewUnapprovedGoodsDispatchItems
         */
        viewUnapprovedGoodsDispatchItems: {
            name: "ViewUnapprovedGoodsDispatchItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the goods dispatch item
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewGoodsDispatchItemHistory
         */
        viewGoodsDispatchItemHistory: {
            name: "ViewGoodsDispatchItemHistory",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchItemHistoryRequest,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved goods dispatch items for given goods dispatch ID with pagination
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewPaginatedApprovedGoodsDispatchItems
         */
        viewPaginatedApprovedGoodsDispatchItems: {
            name: "ViewPaginatedApprovedGoodsDispatchItems",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchItemsSearchRequest,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved goods dispatch items for given goods dispatch ID with pagination
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewPaginatedUnapprovedGoodsDispatchItems
         */
        viewPaginatedUnapprovedGoodsDispatchItems: {
            name: "ViewPaginatedUnapprovedGoodsDispatchItems",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchItemsSearchRequest,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through goods dispatch items with pagination
         *
         * @generated from rpc Scailo.GoodsDispatchesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchItemsSearchRequest,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatch,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatch,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServicePaginationReq,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given goods dispatch
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective goods dispatch item info for the given family ID and goods dispatch ID
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewProspectiveGoodsDispatchItem
         */
        viewProspectiveGoodsDispatchItem: {
            name: "ViewProspectiveGoodsDispatchItem",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchItemProspectiveInfoRequest,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download goods dispatch with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.GoodsDispatchesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the associated sales invoice ID that is denoted by the identifier in the response for the goods dispatch that is denoted by the identifier in the request
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewAssociatedSalesInvoiceID
         */
        viewAssociatedSalesInvoiceID: {
            name: "ViewAssociatedSalesInvoiceID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.Identifier,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Goods Dispatch has been billed
         *
         * @generated from rpc Scailo.GoodsDispatchesService.IsBilled
         */
        isBilled: {
            name: "IsBilled",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.GoodsDispatchesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.GoodsDispatchesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceSearchAllReq,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Filter
         */
        filter: {
            name: "Filter",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceFilterReq,
            O: goods_dispatches_scailo_pb_js_1.GoodsDispatchesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.GoodsDispatchesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.GoodsDispatchesService.Count
         */
        count: {
            name: "Count",
            I: goods_dispatches_scailo_pb_js_1.GoodsDispatchesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
