"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file notes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesServiceSearchAllReq = exports.NotesServiceCountReq = exports.NotesServiceFilterReq = exports.NotesServicePaginationResponse = exports.NotesServicePaginationReq = exports.NotesList = exports.Note = exports.NotesServiceUpdateRequest = exports.NotesServiceCreateRequest = exports.LogbookLogNoteLC = exports.NOTE_SORT_KEY = exports.NOTE_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each note
 *
 * @generated from enum Scailo.NOTE_LIFECYCLE
 */
var NOTE_LIFECYCLE;
(function (NOTE_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: NOTE_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    NOTE_LIFECYCLE[NOTE_LIFECYCLE["NOTE_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "NOTE_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the note is open
     *
     * @generated from enum value: NOTE_LIFECYCLE_OPEN = 1;
     */
    NOTE_LIFECYCLE[NOTE_LIFECYCLE["NOTE_LIFECYCLE_OPEN"] = 1] = "NOTE_LIFECYCLE_OPEN";
    /**
     * Denotes that the note has completed
     *
     * @generated from enum value: NOTE_LIFECYCLE_COMPLETED = 2;
     */
    NOTE_LIFECYCLE[NOTE_LIFECYCLE["NOTE_LIFECYCLE_COMPLETED"] = 2] = "NOTE_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the note has been cancelled
     *
     * @generated from enum value: NOTE_LIFECYCLE_CANCELLED = 3;
     */
    NOTE_LIFECYCLE[NOTE_LIFECYCLE["NOTE_LIFECYCLE_CANCELLED"] = 3] = "NOTE_LIFECYCLE_CANCELLED";
})(NOTE_LIFECYCLE || (exports.NOTE_LIFECYCLE = NOTE_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(NOTE_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(NOTE_LIFECYCLE, "Scailo.NOTE_LIFECYCLE", [
    { no: 0, name: "NOTE_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "NOTE_LIFECYCLE_OPEN" },
    { no: 2, name: "NOTE_LIFECYCLE_COMPLETED" },
    { no: 3, name: "NOTE_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.NOTE_SORT_KEY
 */
var NOTE_SORT_KEY;
(function (NOTE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: NOTE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    NOTE_SORT_KEY[NOTE_SORT_KEY["NOTE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "NOTE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: NOTE_SORT_KEY_CREATED_AT = 1;
     */
    NOTE_SORT_KEY[NOTE_SORT_KEY["NOTE_SORT_KEY_CREATED_AT"] = 1] = "NOTE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: NOTE_SORT_KEY_MODIFIED_AT = 2;
     */
    NOTE_SORT_KEY[NOTE_SORT_KEY["NOTE_SORT_KEY_MODIFIED_AT"] = 2] = "NOTE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: NOTE_SORT_KEY_COMPLETED_ON = 6;
     */
    NOTE_SORT_KEY[NOTE_SORT_KEY["NOTE_SORT_KEY_COMPLETED_ON"] = 6] = "NOTE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: NOTE_SORT_KEY_TITLE = 10;
     */
    NOTE_SORT_KEY[NOTE_SORT_KEY["NOTE_SORT_KEY_TITLE"] = 10] = "NOTE_SORT_KEY_TITLE";
})(NOTE_SORT_KEY || (exports.NOTE_SORT_KEY = NOTE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(NOTE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(NOTE_SORT_KEY, "Scailo.NOTE_SORT_KEY", [
    { no: 0, name: "NOTE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "NOTE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "NOTE_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "NOTE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "NOTE_SORT_KEY_TITLE" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being an note lifecycle status
 *
 * @generated from message Scailo.LogbookLogNoteLC
 */
class LogbookLogNoteLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE operation = 11;
     */
    operation = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogNoteLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogNoteLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogNoteLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogNoteLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogNoteLC, a, b);
    }
}
exports.LogbookLogNoteLC = LogbookLogNoteLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.NotesServiceCreateRequest
 */
class NotesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the note
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the note
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The associated employee ID
     *
     * @generated from field: uint64 employee_id = 20;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity ID
     *
     * @generated from field: uint64 activity_id = 21;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 22;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServiceCreateRequest, a, b);
    }
}
exports.NotesServiceCreateRequest = NotesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.NotesServiceUpdateRequest
 */
class NotesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the note
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the note
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The associated activity ID
     *
     * @generated from field: uint64 activity_id = 21;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 22;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServiceUpdateRequest, a, b);
    }
}
exports.NotesServiceUpdateRequest = NotesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Note
 */
class Note extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this note
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this note
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE status = 4;
     */
    status = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this note
     *
     * @generated from field: repeated Scailo.LogbookLogNoteLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this note was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 8;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated project ID
     *
     * @generated from field: uint64 project_id = 9;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The title of the note
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The description of the note
     *
     * @generated from field: string description = 11;
     */
    description = "";
    /**
     * The associated employee ID
     *
     * @generated from field: uint64 employee_id = 20;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity ID
     *
     * @generated from field: uint64 activity_id = 21;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * The associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 22;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Note";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogNoteLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new Note().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Note().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Note().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Note, a, b);
    }
}
exports.Note = Note;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.NotesList
 */
class NotesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Note list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Note, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new NotesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesList, a, b);
    }
}
exports.NotesList = NotesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.NotesServicePaginationReq
 */
class NotesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = NOTE_SORT_KEY.NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this note
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE status = 6;
     */
    status = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServicePaginationReq, a, b);
    }
}
exports.NotesServicePaginationReq = NotesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.NotesServicePaginationResponse
 */
class NotesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Note payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Note, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServicePaginationResponse, a, b);
    }
}
exports.NotesServicePaginationResponse = NotesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.NotesServiceFilterReq
 */
class NotesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = NOTE_SORT_KEY.NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this note
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE status = 10;
     */
    status = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the note
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the employee
     *
     * @generated from field: uint64 employee_id = 26;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity ID
     *
     * @generated from field: uint64 activity_id = 27;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 28;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServiceFilterReq, a, b);
    }
}
exports.NotesServiceFilterReq = NotesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.NotesServiceCountReq
 */
class NotesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this note
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE status = 10;
     */
    status = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The title of the note
     *
     * @generated from field: string title = 20;
     */
    title = "";
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the employee
     *
     * @generated from field: uint64 employee_id = 26;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity ID
     *
     * @generated from field: uint64 activity_id = 27;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 28;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServiceCountReq, a, b);
    }
}
exports.NotesServiceCountReq = NotesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.NotesServiceSearchAllReq
 */
class NotesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.NOTE_SORT_KEY sort_key = 5;
     */
    sortKey = NOTE_SORT_KEY.NOTE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.NOTE_LIFECYCLE status = 10;
     */
    status = NOTE_LIFECYCLE.NOTE_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 25;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the employee
     *
     * @generated from field: uint64 employee_id = 26;
     */
    employeeId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity ID
     *
     * @generated from field: uint64 activity_id = 27;
     */
    activityId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated activity status ID
     *
     * @generated from field: uint64 activity_status_id = 28;
     */
    activityStatusId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.NotesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(NOTE_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "employee_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "activity_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "activity_status_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new NotesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new NotesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new NotesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(NotesServiceSearchAllReq, a, b);
    }
}
exports.NotesServiceSearchAllReq = NotesServiceSearchAllReq;
