"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file sales_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesInvoiceDispatchedStatisticsList = exports.SalesInvoiceDispatchedStatistics = exports.SalesInvoicesServicePaginatedItemsResponse = exports.SalesInvoiceItemsSearchRequest = exports.SalesInvoiceReferencesList = exports.SalesInvoiceReference = exports.SalesInvoicesServiceReferenceCreateRequest = exports.SalesInvoicesServiceSearchAllReq = exports.SalesInvoicesServiceCountReq = exports.SalesInvoicesServiceFilterReq = exports.SalesInvoicesServicePaginationResponse = exports.SalesInvoicesServicePaginationReq = exports.SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest = exports.SalesInvoiceItemProspectiveInfoRequest = exports.SalesInvoiceItemHistoryRequest = exports.SalesInvoiceItemsList = exports.SalesInvoicesList = exports.SalesInvoiceItem = exports.SalesInvoicesServiceItemUpdateRequest = exports.SalesInvoicesServiceItemCreateRequest = exports.SalesInvoice = exports.SalesInvoiceAncillaryParameters = exports.SalesInvoicesServiceAutofillRequest = exports.SalesInvoicesServiceUpdateRequest = exports.SalesInvoicesServiceCreateRequest = exports.SALES_INVOICE_ITEM_STATUS = exports.SALES_INVOICE_ITEM_SORT_KEY = exports.SALES_INVOICE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_INVOICE_SORT_KEY
 */
var SALES_INVOICE_SORT_KEY;
(function (SALES_INVOICE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_CREATED_AT = 1;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_CREATED_AT"] = 1] = "SALES_INVOICE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_INVOICE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_APPROVED_ON"] = 3] = "SALES_INVOICE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_APPROVED_BY"] = 4] = "SALES_INVOICE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_INVOICE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_COMPLETED_ON = 6;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_COMPLETED_ON"] = 6] = "SALES_INVOICE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_REFERENCE_ID = 10;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_REFERENCE_ID"] = 10] = "SALES_INVOICE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALES_INVOICE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_AMENDMENT_COUNT"] = 18] = "SALES_INVOICE_SORT_KEY_AMENDMENT_COUNT";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: SALES_INVOICE_SORT_KEY_TOTAL_VALUE = 30;
     */
    SALES_INVOICE_SORT_KEY[SALES_INVOICE_SORT_KEY["SALES_INVOICE_SORT_KEY_TOTAL_VALUE"] = 30] = "SALES_INVOICE_SORT_KEY_TOTAL_VALUE";
})(SALES_INVOICE_SORT_KEY || (exports.SALES_INVOICE_SORT_KEY = SALES_INVOICE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_INVOICE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_INVOICE_SORT_KEY, "Scailo.SALES_INVOICE_SORT_KEY", [
    { no: 0, name: "SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_INVOICE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_INVOICE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_INVOICE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_INVOICE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_INVOICE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALES_INVOICE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALES_INVOICE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALES_INVOICE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 18, name: "SALES_INVOICE_SORT_KEY_AMENDMENT_COUNT" },
    { no: 30, name: "SALES_INVOICE_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_INVOICE_ITEM_SORT_KEY
 */
var SALES_INVOICE_ITEM_SORT_KEY;
(function (SALES_INVOICE_ITEM_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SALES_INVOICE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_INVOICE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SALES_INVOICE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SALES_INVOICE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the family ID
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "SALES_INVOICE_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch invoiced results by the internal quantity
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SALES_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch invoiced results by the client unit of material ID
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch invoiced results by the client quantity
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch invoiced results by the client family code
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
    /**
     * Fetch invoiced results by the unit price
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_UNIT_PRICE = 15;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_UNIT_PRICE"] = 15] = "SALES_INVOICE_ITEM_SORT_KEY_UNIT_PRICE";
    /**
     * Fetch invoiced results by the tax group ID
     *
     * @generated from enum value: SALES_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID = 16;
     */
    SALES_INVOICE_ITEM_SORT_KEY[SALES_INVOICE_ITEM_SORT_KEY["SALES_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID"] = 16] = "SALES_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID";
})(SALES_INVOICE_ITEM_SORT_KEY || (exports.SALES_INVOICE_ITEM_SORT_KEY = SALES_INVOICE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_INVOICE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_INVOICE_ITEM_SORT_KEY, "Scailo.SALES_INVOICE_ITEM_SORT_KEY", [
    { no: 0, name: "SALES_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_INVOICE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_INVOICE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_INVOICE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_INVOICE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SALES_INVOICE_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "SALES_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "SALES_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
    { no: 15, name: "SALES_INVOICE_ITEM_SORT_KEY_UNIT_PRICE" },
    { no: 16, name: "SALES_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID" },
]);
/**
 *
 * Describes the applicable statuses of sales invoice items
 *
 * @generated from enum Scailo.SALES_INVOICE_ITEM_STATUS
 */
var SALES_INVOICE_ITEM_STATUS;
(function (SALES_INVOICE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_INVOICE_ITEM_STATUS[SALES_INVOICE_ITEM_STATUS["SALES_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the sales invoice items must have been approved
     *
     * @generated from enum value: SALES_INVOICE_ITEM_STATUS_APPROVED = 1;
     */
    SALES_INVOICE_ITEM_STATUS[SALES_INVOICE_ITEM_STATUS["SALES_INVOICE_ITEM_STATUS_APPROVED"] = 1] = "SALES_INVOICE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the sales invoice items must be waiting for approval
     *
     * @generated from enum value: SALES_INVOICE_ITEM_STATUS_UNAPPROVED = 2;
     */
    SALES_INVOICE_ITEM_STATUS[SALES_INVOICE_ITEM_STATUS["SALES_INVOICE_ITEM_STATUS_UNAPPROVED"] = 2] = "SALES_INVOICE_ITEM_STATUS_UNAPPROVED";
})(SALES_INVOICE_ITEM_STATUS || (exports.SALES_INVOICE_ITEM_STATUS = SALES_INVOICE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_INVOICE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_INVOICE_ITEM_STATUS, "Scailo.SALES_INVOICE_ITEM_STATUS", [
    { no: 0, name: "SALES_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_INVOICE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SALES_INVOICE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalesInvoicesServiceCreateRequest
 */
class SalesInvoicesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceCreateRequest, a, b);
    }
}
exports.SalesInvoicesServiceCreateRequest = SalesInvoicesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalesInvoicesServiceUpdateRequest
 */
class SalesInvoicesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceUpdateRequest, a, b);
    }
}
exports.SalesInvoicesServiceUpdateRequest = SalesInvoicesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.SalesInvoicesServiceAutofillRequest
 */
class SalesInvoicesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if services should also be autofilled
     *
     * @generated from field: bool include_services = 10;
     */
    includeServices = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "include_services", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceAutofillRequest, a, b);
    }
}
exports.SalesInvoicesServiceAutofillRequest = SalesInvoicesServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.SalesInvoiceAncillaryParameters
 */
class SalesInvoiceAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 214;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceAncillaryParameters, a, b);
    }
}
exports.SalesInvoiceAncillaryParameters = SalesInvoiceAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SalesInvoice
 */
class SalesInvoice extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this sales invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this sales invoice
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this sales invoice was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the sales invoice (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 21;
     */
    totalValue = 0;
    /**
     * The number of times that the sales invoice has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * The list of associated sales invoice items
     *
     * @generated from field: repeated Scailo.SalesInvoiceItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoice";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "list", kind: "message", T: SalesInvoiceItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoice().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoice().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoice().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoice, a, b);
    }
}
exports.SalesInvoice = SalesInvoice;
/**
 *
 * Describes the parameters required to add an item to a sales invoice
 *
 * @generated from message Scailo.SalesInvoicesServiceItemCreateRequest
 */
class SalesInvoicesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceItemCreateRequest, a, b);
    }
}
exports.SalesInvoicesServiceItemCreateRequest = SalesInvoicesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a sales invoice
 *
 * @generated from message Scailo.SalesInvoicesServiceItemUpdateRequest
 */
class SalesInvoicesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceItemUpdateRequest, a, b);
    }
}
exports.SalesInvoicesServiceItemUpdateRequest = SalesInvoicesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a sales invoice
 *
 * @generated from message Scailo.SalesInvoiceItem
 */
class SalesInvoiceItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceItem, a, b);
    }
}
exports.SalesInvoiceItem = SalesInvoiceItem;
/**
 *
 * Describes the message consisting of the list of sales invoices
 *
 * @generated from message Scailo.SalesInvoicesList
 */
class SalesInvoicesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesInvoice list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesList, a, b);
    }
}
exports.SalesInvoicesList = SalesInvoicesList;
/**
 *
 * Describes the message consisting of the list of sales invoice items
 *
 * @generated from message Scailo.SalesInvoiceItemsList
 */
class SalesInvoiceItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesInvoiceItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceItemsList, a, b);
    }
}
exports.SalesInvoiceItemsList = SalesInvoiceItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SalesInvoiceItemHistoryRequest
 */
class SalesInvoiceItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceItemHistoryRequest, a, b);
    }
}
exports.SalesInvoiceItemHistoryRequest = SalesInvoiceItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective sales invoice item
 *
 * @generated from message Scailo.SalesInvoiceItemProspectiveInfoRequest
 */
class SalesInvoiceItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceItemProspectiveInfoRequest, a, b);
    }
}
exports.SalesInvoiceItemProspectiveInfoRequest = SalesInvoiceItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest
 */
class SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest = SalesInvoicesServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalesInvoicesServicePaginationReq
 */
class SalesInvoicesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_INVOICE_SORT_KEY.SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this sales invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_INVOICE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServicePaginationReq, a, b);
    }
}
exports.SalesInvoicesServicePaginationReq = SalesInvoicesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalesInvoicesServicePaginationResponse
 */
class SalesInvoicesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesInvoice payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServicePaginationResponse, a, b);
    }
}
exports.SalesInvoicesServicePaginationResponse = SalesInvoicesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalesInvoicesServiceFilterReq
 */
class SalesInvoicesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_INVOICE_SORT_KEY.SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the sales invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 70;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the sales invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 71;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_INVOICE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 71, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceFilterReq, a, b);
    }
}
exports.SalesInvoicesServiceFilterReq = SalesInvoicesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalesInvoicesServiceCountReq
 */
class SalesInvoicesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the sales invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 70;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the sales invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 71;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 70, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 71, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceCountReq, a, b);
    }
}
exports.SalesInvoicesServiceCountReq = SalesInvoicesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalesInvoicesServiceSearchAllReq
 */
class SalesInvoicesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_INVOICE_SORT_KEY.SALES_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_INVOICE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceSearchAllReq, a, b);
    }
}
exports.SalesInvoicesServiceSearchAllReq = SalesInvoicesServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a sales invoice reference
 *
 * @generated from message Scailo.SalesInvoicesServiceReferenceCreateRequest
 */
class SalesInvoicesServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 11;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServiceReferenceCreateRequest, a, b);
    }
}
exports.SalesInvoicesServiceReferenceCreateRequest = SalesInvoicesServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales invoice reference
 *
 * @generated from message Scailo.SalesInvoiceReference
 */
class SalesInvoiceReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales invoice ID
     *
     * @generated from field: uint64 sales_invoice_id = 10;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 11;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceReference, a, b);
    }
}
exports.SalesInvoiceReference = SalesInvoiceReference;
/**
 *
 * Describes the message consisting of the list of sales invoice references
 *
 * @generated from message Scailo.SalesInvoiceReferencesList
 */
class SalesInvoiceReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesInvoiceReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesInvoiceReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceReferencesList, a, b);
    }
}
exports.SalesInvoiceReferencesList = SalesInvoiceReferencesList;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SalesInvoiceItemsSearchRequest
 */
class SalesInvoiceItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_INVOICE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_INVOICE_ITEM_SORT_KEY.SALES_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SALES_INVOICE_ITEM_STATUS status = 7;
     */
    status = SALES_INVOICE_ITEM_STATUS.SALES_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the sales invoice
     *
     * @generated from field: uint64 sales_invoice_id = 20;
     */
    salesInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 25;
     */
    clientFamilyCode = "";
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 27;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_INVOICE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_INVOICE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "sales_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceItemsSearchRequest, a, b);
    }
}
exports.SalesInvoiceItemsSearchRequest = SalesInvoiceItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SalesInvoicesServicePaginatedItemsResponse
 */
class SalesInvoicesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesInvoiceItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoicesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoicesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoicesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoicesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoicesServicePaginatedItemsResponse, a, b);
    }
}
exports.SalesInvoicesServicePaginatedItemsResponse = SalesInvoicesServicePaginatedItemsResponse;
/**
 *
 * Describes the dispatched statistics of the sales invoice
 *
 * @generated from message Scailo.SalesInvoiceDispatchedStatistics
 */
class SalesInvoiceDispatchedStatistics extends protobuf_1.Message {
    /**
     * Stores the ID of the family
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity
     *
     * @generated from field: uint64 invoiced_quantity = 2;
     */
    invoicedQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the dispatched quantity
     *
     * @generated from field: uint64 dispatched_quantity = 3;
     */
    dispatchedQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceDispatchedStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "invoiced_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "dispatched_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceDispatchedStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceDispatchedStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceDispatchedStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceDispatchedStatistics, a, b);
    }
}
exports.SalesInvoiceDispatchedStatistics = SalesInvoiceDispatchedStatistics;
/**
 *
 * Describes the list of dispatched statistics of the sales invoice
 *
 * @generated from message Scailo.SalesInvoiceDispatchedStatisticsList
 */
class SalesInvoiceDispatchedStatisticsList extends protobuf_1.Message {
    /**
     * @generated from field: repeated Scailo.SalesInvoiceDispatchedStatistics list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesInvoiceDispatchedStatisticsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesInvoiceDispatchedStatistics, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesInvoiceDispatchedStatisticsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesInvoiceDispatchedStatisticsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesInvoiceDispatchedStatisticsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesInvoiceDispatchedStatisticsList, a, b);
    }
}
exports.SalesInvoiceDispatchedStatisticsList = SalesInvoiceDispatchedStatisticsList;
