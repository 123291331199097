import { salesReturnFiltersMenu, salesReturnInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleSalesReturnFilters } from "./salesreturns.filters";
import { handleSalesReturnInsights } from "./salesreturns.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesReturnFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesReturnFiltersMenu)) {
            handleSalesReturnFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesReturnInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesReturnInsightsMenu)) {
            handleSalesReturnInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}