
import { Router } from "./../router";
import * as salesorders from "./salesorders/routes";
import * as salesinvoices from "./salesinvoices/routes";
import * as inwardjobs from "./inwardjobs/routes";
import * as workorders from "./workorders/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    salesorders.Routes(r);
    salesinvoices.Routes(r);
    inwardjobs.Routes(r);
    workorders.Routes(r);
}