"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file roles.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolesServiceSearchAllReq = exports.RolesServiceCountReq = exports.RolesServiceFilterReq = exports.RolesServicePaginationResponse = exports.RolesServicePaginationReq = exports.RolesList = exports.RoleAccess = exports.RolesServiceAccessCreateAndUpdateRequest = exports.Role = exports.RolesServiceUpdateRequest = exports.RolesServiceCreateRequest = exports.ROLE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ROLE_SORT_KEY
 */
var ROLE_SORT_KEY;
(function (ROLE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ROLE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ROLE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ROLE_SORT_KEY_CREATED_AT = 1;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_CREATED_AT"] = 1] = "ROLE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ROLE_SORT_KEY_MODIFIED_AT = 2;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_MODIFIED_AT"] = 2] = "ROLE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ROLE_SORT_KEY_APPROVED_ON = 3;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_APPROVED_ON"] = 3] = "ROLE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ROLE_SORT_KEY_APPROVED_BY = 4;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_APPROVED_BY"] = 4] = "ROLE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ROLE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ROLE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: ROLE_SORT_KEY_COMPLETED_ON = 6;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_COMPLETED_ON"] = 6] = "ROLE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: ROLE_SORT_KEY_NAME = 10;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_NAME"] = 10] = "ROLE_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: ROLE_SORT_KEY_CODE = 11;
     */
    ROLE_SORT_KEY[ROLE_SORT_KEY["ROLE_SORT_KEY_CODE"] = 11] = "ROLE_SORT_KEY_CODE";
})(ROLE_SORT_KEY || (exports.ROLE_SORT_KEY = ROLE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ROLE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ROLE_SORT_KEY, "Scailo.ROLE_SORT_KEY", [
    { no: 0, name: "ROLE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ROLE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ROLE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ROLE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ROLE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ROLE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "ROLE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "ROLE_SORT_KEY_NAME" },
    { no: 11, name: "ROLE_SORT_KEY_CODE" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.RolesServiceCreateRequest
 */
class RolesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the role
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the role
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the role
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * Stores if this role has access to Vault
     *
     * @generated from field: bool vault_access = 13;
     */
    vaultAccess = false;
    /**
     * Stores if the role has access to perform operations on the root folder in Vault
     *
     * @generated from field: bool vault_root_folder_interactions = 14;
     */
    vaultRootFolderInteractions = false;
    /**
     * Stores if the role has access via HTTP (or within local network)
     *
     * @generated from field: bool http_access = 15;
     */
    httpAccess = false;
    /**
     * Stores if the role has access via HTTPS (or through a public FQDN)
     *
     * @generated from field: bool https_access = 16;
     */
    httpsAccess = false;
    /**
     * The list of accessible menu UIDs
     *
     * @generated from field: repeated Scailo.RolesServiceAccessCreateAndUpdateRequest access_list = 20;
     */
    accessList = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "vault_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 14, name: "vault_root_folder_interactions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 15, name: "http_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 16, name: "https_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "access_list", kind: "message", T: RolesServiceAccessCreateAndUpdateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceCreateRequest, a, b);
    }
}
exports.RolesServiceCreateRequest = RolesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.RolesServiceUpdateRequest
 */
class RolesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The name of the role
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the role
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the role
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * Stores if this role has access to Vault
     *
     * @generated from field: bool vault_access = 13;
     */
    vaultAccess = false;
    /**
     * Stores if the role has access to perform operations on the root folder in Vault
     *
     * @generated from field: bool vault_root_folder_interactions = 14;
     */
    vaultRootFolderInteractions = false;
    /**
     * Stores if the role has access via HTTP (or within local network)
     *
     * @generated from field: bool http_access = 15;
     */
    httpAccess = false;
    /**
     * Stores if the role has access via HTTPS (or through a public FQDN)
     *
     * @generated from field: bool https_access = 16;
     */
    httpsAccess = false;
    /**
     * The list of accessible menu UIDs
     *
     * @generated from field: repeated Scailo.RolesServiceAccessCreateAndUpdateRequest access_list = 20;
     */
    accessList = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "vault_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 14, name: "vault_root_folder_interactions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 15, name: "http_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 16, name: "https_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "access_list", kind: "message", T: RolesServiceAccessCreateAndUpdateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceUpdateRequest, a, b);
    }
}
exports.RolesServiceUpdateRequest = RolesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.Role
 */
class Role extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this role
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this role
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this role
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this role was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The name of the role
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The code of the role
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The description of the role
     *
     * @generated from field: string description = 12;
     */
    description = "";
    /**
     * Stores if this role has access to Vault
     *
     * @generated from field: bool vault_access = 13;
     */
    vaultAccess = false;
    /**
     * Stores if the role has access to perform operations on the root folder in Vault
     *
     * @generated from field: bool vault_root_folder_interactions = 14;
     */
    vaultRootFolderInteractions = false;
    /**
     * Stores if the role has access via HTTP (or within local network)
     *
     * @generated from field: bool http_access = 15;
     */
    httpAccess = false;
    /**
     * Stores if the role has access via HTTPS (or through a public FQDN)
     *
     * @generated from field: bool https_access = 16;
     */
    httpsAccess = false;
    /**
     * The list of associated role accesses
     *
     * @generated from field: repeated Scailo.RoleAccess access_list = 20;
     */
    accessList = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Role";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "vault_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 14, name: "vault_root_folder_interactions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 15, name: "http_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 16, name: "https_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 20, name: "access_list", kind: "message", T: RoleAccess, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Role().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Role().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Role().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Role, a, b);
    }
}
exports.Role = Role;
/**
 *
 * Describes the necessary data structure during creation/updation of a role access
 *
 * @generated from message Scailo.RolesServiceAccessCreateAndUpdateRequest
 */
class RolesServiceAccessCreateAndUpdateRequest extends protobuf_1.Message {
    /**
     * The menu UID (or internal code)
     *
     * @generated from field: string menu_uid = 11;
     */
    menuUid = "";
    /**
     * Denotes if the menu is accessible
     *
     * @generated from field: bool is_accessible = 12;
     */
    isAccessible = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceAccessCreateAndUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "menu_uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "is_accessible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceAccessCreateAndUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceAccessCreateAndUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceAccessCreateAndUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceAccessCreateAndUpdateRequest, a, b);
    }
}
exports.RolesServiceAccessCreateAndUpdateRequest = RolesServiceAccessCreateAndUpdateRequest;
/**
 *
 * Describes the data structure of each role access on the platform
 *
 * @generated from message Scailo.RoleAccess
 */
class RoleAccess extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this team
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * The associated Role ID
     *
     * @generated from field: uint64 role_id = 10;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * The menu UID (or internal code)
     *
     * @generated from field: string menu_uid = 11;
     */
    menuUid = "";
    /**
     * Denotes if the menu is accessible
     *
     * @generated from field: bool is_accessible = 12;
     */
    isAccessible = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RoleAccess";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 10, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "menu_uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "is_accessible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new RoleAccess().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RoleAccess().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RoleAccess().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RoleAccess, a, b);
    }
}
exports.RoleAccess = RoleAccess;
/**
 *
 * Describes the message consisting of the list of roles
 *
 * @generated from message Scailo.RolesList
 */
class RolesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.Role list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Role, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new RolesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesList, a, b);
    }
}
exports.RolesList = RolesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.RolesServicePaginationReq
 */
class RolesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ROLE_SORT_KEY sort_key = 5;
     */
    sortKey = ROLE_SORT_KEY.ROLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this role
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ROLE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServicePaginationReq, a, b);
    }
}
exports.RolesServicePaginationReq = RolesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.RolesServicePaginationResponse
 */
class RolesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Role payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Role, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServicePaginationResponse, a, b);
    }
}
exports.RolesServicePaginationResponse = RolesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.RolesServiceFilterReq
 */
class RolesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ROLE_SORT_KEY sort_key = 5;
     */
    sortKey = ROLE_SORT_KEY.ROLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this role
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the role
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The name of the code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ROLE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceFilterReq, a, b);
    }
}
exports.RolesServiceFilterReq = RolesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.RolesServiceCountReq
 */
class RolesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this role
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the role
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The name of the code
     *
     * @generated from field: string code = 21;
     */
    code = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceCountReq, a, b);
    }
}
exports.RolesServiceCountReq = RolesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.RolesServiceSearchAllReq
 */
class RolesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ROLE_SORT_KEY sort_key = 5;
     */
    sortKey = ROLE_SORT_KEY.ROLE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.RolesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ROLE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new RolesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RolesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RolesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(RolesServiceSearchAllReq, a, b);
    }
}
exports.RolesServiceSearchAllReq = RolesServiceSearchAllReq;
