import { salesReceiptFiltersMenu, salesReceiptInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleSaleReceiptFilters } from "./salesreceipts.filters";
import { handleSaleReceiptInsights } from "./salesreceipts.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesReceiptFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesReceiptFiltersMenu)) {
            handleSaleReceiptFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesReceiptInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesReceiptInsightsMenu)) {
            handleSaleReceiptInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}