"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file vault_commons.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultAccessLogsList = exports.VaultAccessLogCreateRequest = exports.VaultAccessLog = exports.VaultDuplicateCheckReq = exports.VaultSearchResponsesList = exports.VaultSearchResponse = exports.VaultSearchReq = exports.GiXAppRunFilterReq = exports.GiXAppRunCountReq = exports.GiXAppRunsList = exports.GiXAppRun = exports.GiXManifest = exports.GixResources = exports.VaultPermissionModifyRequest = exports.VaultPermissionAddRequest = exports.VaultPermission = exports.VAULT_ACCESS_LOG_OPERATION = exports.VAULT_SORT_KEY = exports.VAULT_PERMISSION_CODE = exports.VAULT_REF_FOR = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available references for permissions
 *
 * @generated from enum Scailo.VAULT_REF_FOR
 */
var VAULT_REF_FOR;
(function (VAULT_REF_FOR) {
    /**
     * Ignore ref for (can only be used during search APIs)
     *
     * @generated from enum value: VAULT_REF_FOR_ANY_UNSPECIFIED = 0;
     */
    VAULT_REF_FOR[VAULT_REF_FOR["VAULT_REF_FOR_ANY_UNSPECIFIED"] = 0] = "VAULT_REF_FOR_ANY_UNSPECIFIED";
    /**
     * Permission refers to a file
     *
     * @generated from enum value: VAULT_REF_FOR_FILE = 1;
     */
    VAULT_REF_FOR[VAULT_REF_FOR["VAULT_REF_FOR_FILE"] = 1] = "VAULT_REF_FOR_FILE";
    /**
     * Permission refers to a folder
     *
     * @generated from enum value: VAULT_REF_FOR_FOLDER = 2;
     */
    VAULT_REF_FOR[VAULT_REF_FOR["VAULT_REF_FOR_FOLDER"] = 2] = "VAULT_REF_FOR_FOLDER";
})(VAULT_REF_FOR || (exports.VAULT_REF_FOR = VAULT_REF_FOR = {}));
// Retrieve enum metadata with: proto3.getEnumType(VAULT_REF_FOR)
protobuf_1.proto3.util.setEnumType(VAULT_REF_FOR, "Scailo.VAULT_REF_FOR", [
    { no: 0, name: "VAULT_REF_FOR_ANY_UNSPECIFIED" },
    { no: 1, name: "VAULT_REF_FOR_FILE" },
    { no: 2, name: "VAULT_REF_FOR_FOLDER" },
]);
/**
 *
 * Describes the available permission codes
 *
 * @generated from enum Scailo.VAULT_PERMISSION_CODE
 */
var VAULT_PERMISSION_CODE;
(function (VAULT_PERMISSION_CODE) {
    /**
     * Stores the bit weight of the view permission (value is 2^0 = 1)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_VIEW_UNSPECIFIED = 0;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_VIEW_UNSPECIFIED"] = 0] = "VAULT_PERMISSION_CODE_VIEW_UNSPECIFIED";
    /**
     * Stores the bit weight of the meta permission (value is 2^1 = 2)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_META = 1;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_META"] = 1] = "VAULT_PERMISSION_CODE_META";
    /**
     * Stores the bit weight of the download permission (value is 2^2 = 4)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_DOWNLOAD = 2;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_DOWNLOAD"] = 2] = "VAULT_PERMISSION_CODE_DOWNLOAD";
    /**
     * Stores the bit weight of the add permission (value is 2^3 = 8)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_ADD = 3;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_ADD"] = 3] = "VAULT_PERMISSION_CODE_ADD";
    /**
     * Stores the bit weight of the delete permission (value is 2^4 = 16)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_DELETE = 4;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_DELETE"] = 4] = "VAULT_PERMISSION_CODE_DELETE";
    /**
     * Stores the bit weight of the execute permission (value is 2^5 = 32)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_EXECUTE = 5;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_EXECUTE"] = 5] = "VAULT_PERMISSION_CODE_EXECUTE";
    /**
     * Stores the bit weight of all the available permissions (value is 2^6 = 64)
     *
     * @generated from enum value: VAULT_PERMISSION_CODE_ALL = 20;
     */
    VAULT_PERMISSION_CODE[VAULT_PERMISSION_CODE["VAULT_PERMISSION_CODE_ALL"] = 20] = "VAULT_PERMISSION_CODE_ALL";
})(VAULT_PERMISSION_CODE || (exports.VAULT_PERMISSION_CODE = VAULT_PERMISSION_CODE = {}));
// Retrieve enum metadata with: proto3.getEnumType(VAULT_PERMISSION_CODE)
protobuf_1.proto3.util.setEnumType(VAULT_PERMISSION_CODE, "Scailo.VAULT_PERMISSION_CODE", [
    { no: 0, name: "VAULT_PERMISSION_CODE_VIEW_UNSPECIFIED" },
    { no: 1, name: "VAULT_PERMISSION_CODE_META" },
    { no: 2, name: "VAULT_PERMISSION_CODE_DOWNLOAD" },
    { no: 3, name: "VAULT_PERMISSION_CODE_ADD" },
    { no: 4, name: "VAULT_PERMISSION_CODE_DELETE" },
    { no: 5, name: "VAULT_PERMISSION_CODE_EXECUTE" },
    { no: 20, name: "VAULT_PERMISSION_CODE_ALL" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.VAULT_SORT_KEY
 */
var VAULT_SORT_KEY;
(function (VAULT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: VAULT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    VAULT_SORT_KEY[VAULT_SORT_KEY["VAULT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "VAULT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: VAULT_SORT_KEY_CREATED_AT = 1;
     */
    VAULT_SORT_KEY[VAULT_SORT_KEY["VAULT_SORT_KEY_CREATED_AT"] = 1] = "VAULT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: VAULT_SORT_KEY_MODIFIED_AT = 2;
     */
    VAULT_SORT_KEY[VAULT_SORT_KEY["VAULT_SORT_KEY_MODIFIED_AT"] = 2] = "VAULT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: VAULT_SORT_KEY_NAME = 10;
     */
    VAULT_SORT_KEY[VAULT_SORT_KEY["VAULT_SORT_KEY_NAME"] = 10] = "VAULT_SORT_KEY_NAME";
})(VAULT_SORT_KEY || (exports.VAULT_SORT_KEY = VAULT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(VAULT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(VAULT_SORT_KEY, "Scailo.VAULT_SORT_KEY", [
    { no: 0, name: "VAULT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "VAULT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "VAULT_SORT_KEY_MODIFIED_AT" },
    { no: 10, name: "VAULT_SORT_KEY_NAME" },
]);
/**
 *
 * Describes the available access log operations
 *
 * @generated from enum Scailo.VAULT_ACCESS_LOG_OPERATION
 */
var VAULT_ACCESS_LOG_OPERATION;
(function (VAULT_ACCESS_LOG_OPERATION) {
    /**
     * Ignore operation (useful only for search APIs)
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED = 0;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED"] = 0] = "VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED";
    /**
     * Denotes that the user has created the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_CREATE = 1;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_CREATE"] = 1] = "VAULT_ACCESS_LOG_OPERATION_CREATE";
    /**
     * Denotes that the user has moved the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_MOVE = 2;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_MOVE"] = 2] = "VAULT_ACCESS_LOG_OPERATION_MOVE";
    /**
     * Denotes that the user has renamed the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_RENAME = 3;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_RENAME"] = 3] = "VAULT_ACCESS_LOG_OPERATION_RENAME";
    /**
     * Denotes that the user has deleted the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_DELETE = 4;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_DELETE"] = 4] = "VAULT_ACCESS_LOG_OPERATION_DELETE";
    /**
     * Denotes that the user has downloaded the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_DOWNLOAD = 5;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_DOWNLOAD"] = 5] = "VAULT_ACCESS_LOG_OPERATION_DOWNLOAD";
    /**
     * Denotes that the user has uploaded the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_UPLOAD = 6;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_UPLOAD"] = 6] = "VAULT_ACCESS_LOG_OPERATION_UPLOAD";
    /**
     * Denotes that the user has begun unzipping the resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_UNZIP = 7;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_UNZIP"] = 7] = "VAULT_ACCESS_LOG_OPERATION_UNZIP";
    /**
     * Denotes that the user has marked the resource as persistent
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_PERSISTENT = 8;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_PERSISTENT"] = 8] = "VAULT_ACCESS_LOG_OPERATION_PERSISTENT";
    /**
     * Denotes that the user is updating the permissions to a resource
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_PERMISSIONS = 9;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_PERMISSIONS"] = 9] = "VAULT_ACCESS_LOG_OPERATION_PERMISSIONS";
    /**
     * Denotes that the user is executing the resource (most likely a GiX app)
     *
     * @generated from enum value: VAULT_ACCESS_LOG_OPERATION_EXECUTE = 10;
     */
    VAULT_ACCESS_LOG_OPERATION[VAULT_ACCESS_LOG_OPERATION["VAULT_ACCESS_LOG_OPERATION_EXECUTE"] = 10] = "VAULT_ACCESS_LOG_OPERATION_EXECUTE";
})(VAULT_ACCESS_LOG_OPERATION || (exports.VAULT_ACCESS_LOG_OPERATION = VAULT_ACCESS_LOG_OPERATION = {}));
// Retrieve enum metadata with: proto3.getEnumType(VAULT_ACCESS_LOG_OPERATION)
protobuf_1.proto3.util.setEnumType(VAULT_ACCESS_LOG_OPERATION, "Scailo.VAULT_ACCESS_LOG_OPERATION", [
    { no: 0, name: "VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED" },
    { no: 1, name: "VAULT_ACCESS_LOG_OPERATION_CREATE" },
    { no: 2, name: "VAULT_ACCESS_LOG_OPERATION_MOVE" },
    { no: 3, name: "VAULT_ACCESS_LOG_OPERATION_RENAME" },
    { no: 4, name: "VAULT_ACCESS_LOG_OPERATION_DELETE" },
    { no: 5, name: "VAULT_ACCESS_LOG_OPERATION_DOWNLOAD" },
    { no: 6, name: "VAULT_ACCESS_LOG_OPERATION_UPLOAD" },
    { no: 7, name: "VAULT_ACCESS_LOG_OPERATION_UNZIP" },
    { no: 8, name: "VAULT_ACCESS_LOG_OPERATION_PERSISTENT" },
    { no: 9, name: "VAULT_ACCESS_LOG_OPERATION_PERMISSIONS" },
    { no: 10, name: "VAULT_ACCESS_LOG_OPERATION_EXECUTE" },
]);
/**
 *
 * Describes the parameters of a permission related to a vault file or folder
 *
 * @generated from message Scailo.VaultPermission
 */
class VaultPermission extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the reference for which this permission is applicable
     *
     * @generated from field: Scailo.VAULT_REF_FOR ref_for = 10;
     */
    refFor = VAULT_REF_FOR.VAULT_REF_FOR_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the underlying file or folder that this permission if applicable for
     *
     * @generated from field: uint64 ref_id = 11;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the role
     *
     * @generated from field: uint64 role_id = 12;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the applicable permissions
     *
     * @generated from field: uint64 permission_code = 13;
     */
    permissionCode = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultPermission";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "ref_for", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_REF_FOR) },
        { no: 11, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "permission_code", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultPermission().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultPermission().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultPermission().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultPermission, a, b);
    }
}
exports.VaultPermission = VaultPermission;
/**
 *
 * Describes the parameters required to create a vault permission
 *
 * @generated from message Scailo.VaultPermissionAddRequest
 */
class VaultPermissionAddRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * Stores the UUID of the underlying file or folder that this permission if applicable for
     *
     * @generated from field: string ref_uuid = 11;
     */
    refUuid = "";
    /**
     * Stores the ID of the role
     *
     * @generated from field: uint64 role_id = 12;
     */
    roleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the applicable permissions
     *
     * @generated from field: uint64 permission_code = 13;
     */
    permissionCode = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultPermissionAddRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "permission_code", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultPermissionAddRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultPermissionAddRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultPermissionAddRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultPermissionAddRequest, a, b);
    }
}
exports.VaultPermissionAddRequest = VaultPermissionAddRequest;
/**
 *
 * Describes the parameters required to modify a vault permission
 *
 * @generated from message Scailo.VaultPermissionModifyRequest
 */
class VaultPermissionModifyRequest extends protobuf_1.Message {
    /**
     * The UUID of the permission
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * Stores the applicable permissions
     *
     * @generated from field: uint64 permission_code = 13;
     */
    permissionCode = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultPermissionModifyRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "permission_code", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultPermissionModifyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultPermissionModifyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultPermissionModifyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultPermissionModifyRequest, a, b);
    }
}
exports.VaultPermissionModifyRequest = VaultPermissionModifyRequest;
/**
 *
 * Describes the resources section of a GiX app
 *
 * @generated from message Scailo.GixResources
 */
class GixResources extends protobuf_1.Message {
    /**
     * Stores the entry point of the GiX app
     *
     * @generated from field: string html_entry = 1 [json_name = "html_entry"];
     */
    htmlEntry = "";
    /**
     * Stores the list of logos that are available
     *
     * @generated from field: repeated string logos = 2;
     */
    logos = [];
    /**
     * Stores the list of external APIs that need to be accessible by the GiX app
     *
     * @generated from field: repeated string external_apis = 3 [json_name = "external_apis"];
     */
    externalApis = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GixResources";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "html_entry", jsonName: "html_entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "logos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
        { no: 3, name: "external_apis", jsonName: "external_apis", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GixResources().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GixResources().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GixResources().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GixResources, a, b);
    }
}
exports.GixResources = GixResources;
/**
 *
 * Describes the manifest file of a GiX app. Stores the attributes that can be a part of the MANIFEST.yaml file, that is present in the root folder of a GiX app
 *
 * @generated from message Scailo.GiXManifest
 */
class GiXManifest extends protobuf_1.Message {
    /**
     * Stores the manifest version
     *
     * @generated from field: string manifest_version = 1 [json_name = "manifest_version"];
     */
    manifestVersion = "";
    /**
     * Stores the application version
     *
     * @generated from field: string app_version = 2 [json_name = "app_version"];
     */
    appVersion = "";
    /**
     * Stores the name of the application
     *
     * @generated from field: string app_name = 3 [json_name = "app_name"];
     */
    appName = "";
    /**
     * Stores the unique identifier for the application
     *
     * @generated from field: string app_unique_identifier = 4 [json_name = "app_unique_identifier"];
     */
    appUniqueIdentifier = "";
    /**
     * Stores the minimum version of Genesis that the application supports
     *
     * @generated from field: string min_genesis_version = 5 [json_name = "min_genesis_version"];
     */
    minGenesisVersion = "";
    /**
     * Stores the manimum version of Genesis that the application supports
     *
     * @generated from field: string max_genesis_version = 6 [json_name = "max_genesis_version"];
     */
    maxGenesisVersion = "";
    /**
     * Stores the resources of the GiX application
     *
     * @generated from field: Scailo.GixResources resources = 7;
     */
    resources;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXManifest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "manifest_version", jsonName: "manifest_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "app_version", jsonName: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "app_name", jsonName: "app_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 4, name: "app_unique_identifier", jsonName: "app_unique_identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 5, name: "min_genesis_version", jsonName: "min_genesis_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 6, name: "max_genesis_version", jsonName: "max_genesis_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "resources", kind: "message", T: GixResources },
    ]);
    static fromBinary(bytes, options) {
        return new GiXManifest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXManifest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXManifest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXManifest, a, b);
    }
}
exports.GiXManifest = GiXManifest;
/**
 *
 * Describes the parameters that are a part of each vault app execution (GiX app execution)
 *
 * @generated from message Scailo.GiXAppRun
 */
class GiXAppRun extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the ID of the file that is being executed
     *
     * @generated from field: uint64 vault_file_id = 10;
     */
    vaultFileId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the version of the file that is being executed
     *
     * @generated from field: uint64 vault_file_version_id = 11;
     */
    vaultFileVersionId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unique identifier of the application
     *
     * @generated from field: string app_unique_identifier = 12;
     */
    appUniqueIdentifier = "";
    /**
     * Stores the path of the extracted file on disk, from where static files will be served
     *
     * @generated from field: string extracted_path = 13;
     */
    extractedPath = "";
    /**
     * Stores the IP address from where the app was executed
     *
     * @generated from field: string ip_addr = 14;
     */
    ipAddr = "";
    /**
     * Stores the manifest of the GiX app
     *
     * @generated from field: Scailo.GiXManifest manifest = 15;
     */
    manifest;
    /**
     * Stores the username of the user who is running the app
     *
     * @generated from field: string run_by = 16;
     */
    runBy = "";
    /**
     * Stores the application version
     *
     * @generated from field: string app_version = 30;
     */
    appVersion = "";
    /**
     * Stores the name of the application
     *
     * @generated from field: string app_name = 31;
     */
    appName = "";
    /**
     * Stores the endpoint of the application execution
     *
     * @generated from field: string app_endpoint = 40;
     */
    appEndpoint = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXAppRun";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "vault_file_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "vault_file_version_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "app_unique_identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "extracted_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "ip_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "manifest", kind: "message", T: GiXManifest },
        { no: 16, name: "run_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 31, name: "app_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "app_endpoint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXAppRun().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXAppRun().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXAppRun().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXAppRun, a, b);
    }
}
exports.GiXAppRun = GiXAppRun;
/**
 *
 * Describes the message consisting of the list of gix app runs
 *
 * @generated from message Scailo.GiXAppRunsList
 */
class GiXAppRunsList extends protobuf_1.Message {
    /**
     * List of runs
     *
     * @generated from field: repeated Scailo.GiXAppRun list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXAppRunsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GiXAppRun, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GiXAppRunsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXAppRunsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXAppRunsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXAppRunsList, a, b);
    }
}
exports.GiXAppRunsList = GiXAppRunsList;
/**
 *
 * Describes the request payload of a count filter for app runs
 *
 * @generated from message Scailo.GiXAppRunCountReq
 */
class GiXAppRunCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the file
     *
     * @generated from field: string vault_file_uuid = 20;
     */
    vaultFileUuid = "";
    /**
     * The UUID of the version
     *
     * --------------------------------------------------------
     *
     * @generated from field: string vault_file_version_uuid = 21;
     */
    vaultFileVersionUuid = "";
    /**
     * The username of the user who executed the app
     *
     * @generated from field: string run_by = 30;
     */
    runBy = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXAppRunCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "vault_file_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "vault_file_version_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "run_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXAppRunCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXAppRunCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXAppRunCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXAppRunCountReq, a, b);
    }
}
exports.GiXAppRunCountReq = GiXAppRunCountReq;
/**
 *
 * Describes the base request payload of a filter search for gix app runs
 *
 * @generated from message Scailo.GiXAppRunFilterReq
 */
class GiXAppRunFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * // The sort order that is to be used to fetch the pagination response
     * SORT_ORDER sort_order = 4;
     * // The sort key that is to be used to fetch the pagination response
     * VAULT_SORT_KEY sort_key = 5;
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the file
     *
     * @generated from field: string vault_file_uuid = 20;
     */
    vaultFileUuid = "";
    /**
     * The UUID of the version
     *
     * --------------------------------------------------------
     *
     * @generated from field: string vault_file_version_uuid = 21;
     */
    vaultFileVersionUuid = "";
    /**
     * The username of the user who executed the app
     *
     * @generated from field: string run_by = 30;
     */
    runBy = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GiXAppRunFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "vault_file_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "vault_file_version_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "run_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GiXAppRunFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GiXAppRunFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GiXAppRunFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GiXAppRunFilterReq, a, b);
    }
}
exports.GiXAppRunFilterReq = GiXAppRunFilterReq;
/**
 * @generated from message Scailo.VaultSearchReq
 */
class VaultSearchReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.VAULT_SORT_KEY sort_key = 5;
     */
    sortKey = VAULT_SORT_KEY.VAULT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * Limit the search to the given ref_for
     *
     * @generated from field: Scailo.VAULT_REF_FOR ref_for = 9;
     */
    refFor = VAULT_REF_FOR.VAULT_REF_FOR_ANY_UNSPECIFIED;
    /**
     * Limit the search to an optional folder
     *
     * @generated from field: string folder_uuid = 10;
     */
    folderUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultSearchReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "ref_for", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_REF_FOR) },
        { no: 10, name: "folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultSearchReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultSearchReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultSearchReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultSearchReq, a, b);
    }
}
exports.VaultSearchReq = VaultSearchReq;
/**
 *
 * Describes the search information of vault files and folders into a single message
 *
 * @generated from message Scailo.VaultSearchResponse
 */
class VaultSearchResponse extends protobuf_1.Message {
    /**
     * The search name
     *
     * @generated from field: string name = 1;
     */
    name = "";
    /**
     * The type of the file
     *
     * @generated from field: Scailo.VAULT_REF_FOR type = 2;
     */
    type = VAULT_REF_FOR.VAULT_REF_FOR_ANY_UNSPECIFIED;
    /**
     * The ID of the element
     *
     * @generated from field: uint64 element_id = 3;
     */
    elementId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the element
     *
     * @generated from field: string element_uuid = 4;
     */
    elementUuid = "";
    /**
     * The full path of the folder or the file
     *
     * @generated from field: string path = 5;
     */
    path = "";
    /**
     * The link to the parent folder
     *
     * @generated from field: string parent_folder_uuid = 6;
     */
    parentFolderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultSearchResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "type", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_REF_FOR) },
        { no: 3, name: "element_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "element_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 6, name: "parent_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultSearchResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultSearchResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultSearchResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultSearchResponse, a, b);
    }
}
exports.VaultSearchResponse = VaultSearchResponse;
/**
 *
 * Describes the message consisting of the list of vault search response
 *
 * @generated from message Scailo.VaultSearchResponsesList
 */
class VaultSearchResponsesList extends protobuf_1.Message {
    /**
     * List of search responses
     *
     * @generated from field: repeated Scailo.VaultSearchResponse list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultSearchResponsesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VaultSearchResponse, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultSearchResponsesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultSearchResponsesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultSearchResponsesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultSearchResponsesList, a, b);
    }
}
exports.VaultSearchResponsesList = VaultSearchResponsesList;
/**
 *
 * Describes the message to test if the file or folder already exists in the given folder UUID
 *
 * @generated from message Scailo.VaultDuplicateCheckReq
 */
class VaultDuplicateCheckReq extends protobuf_1.Message {
    /**
     * The name of the file/folder
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The UUID of the folder that the file/folder will reside in
     *
     * @generated from field: string folder_uuid = 13;
     */
    folderUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultDuplicateCheckReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultDuplicateCheckReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultDuplicateCheckReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultDuplicateCheckReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultDuplicateCheckReq, a, b);
    }
}
exports.VaultDuplicateCheckReq = VaultDuplicateCheckReq;
/**
 *
 * Describes the access log of every file or folder
 *
 * @generated from message Scailo.VaultAccessLog
 */
class VaultAccessLog extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this user
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the reference for which this access log is applicable
     *
     * @generated from field: Scailo.VAULT_REF_FOR ref_for = 10;
     */
    refFor = VAULT_REF_FOR.VAULT_REF_FOR_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the underlying file or folder that has been accessed
     *
     * @generated from field: uint64 ref_id = 11;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who accessed this resource
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * Stores the IP address from where the resource was accessed
     *
     * @generated from field: string ip_addr = 13;
     */
    ipAddr = "";
    /**
     * Stores the operation that was performed (Add/Move/Rename/Delete/Download)
     *
     * @generated from field: Scailo.VAULT_ACCESS_LOG_OPERATION operation = 14;
     */
    operation = VAULT_ACCESS_LOG_OPERATION.VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED;
    /**
     * Stores the comment that describes the operation
     *
     * @generated from field: string comment = 15;
     */
    comment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultAccessLog";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "ref_for", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_REF_FOR) },
        { no: 11, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ip_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_ACCESS_LOG_OPERATION) },
        { no: 15, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultAccessLog().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultAccessLog().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultAccessLog().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultAccessLog, a, b);
    }
}
exports.VaultAccessLog = VaultAccessLog;
/**
 *
 * Describes the necessary parameters to create an access log
 *
 * @generated from message Scailo.VaultAccessLogCreateRequest
 */
class VaultAccessLogCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the reference for which this access log is applicable
     *
     * @generated from field: Scailo.VAULT_REF_FOR ref_for = 10;
     */
    refFor = VAULT_REF_FOR.VAULT_REF_FOR_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the underlying file or folder that has been accessed
     *
     * @generated from field: uint64 ref_id = 11;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who accessed this resource
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * Stores the IP address from where the resource was accessed
     *
     * @generated from field: string ip_addr = 13;
     */
    ipAddr = "";
    /**
     * Stores the operation that was performed (Add/Move/Rename/Delete/Download)
     *
     * @generated from field: Scailo.VAULT_ACCESS_LOG_OPERATION operation = 14;
     */
    operation = VAULT_ACCESS_LOG_OPERATION.VAULT_ACCESS_LOG_OPERATION_ANY_UNSPECIFIED;
    /**
     * Stores the comment that describes the operation
     *
     * @generated from field: string comment = 15;
     */
    comment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultAccessLogCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "ref_for", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_REF_FOR) },
        { no: 11, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ip_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(VAULT_ACCESS_LOG_OPERATION) },
        { no: 15, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VaultAccessLogCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultAccessLogCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultAccessLogCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultAccessLogCreateRequest, a, b);
    }
}
exports.VaultAccessLogCreateRequest = VaultAccessLogCreateRequest;
/**
 *
 * Describes the message consisting of the list of access logs
 *
 * @generated from message Scailo.VaultAccessLogsList
 */
class VaultAccessLogsList extends protobuf_1.Message {
    /**
     * List of access logs
     *
     * @generated from field: repeated Scailo.VaultAccessLog list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VaultAccessLogsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: VaultAccessLog, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new VaultAccessLogsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VaultAccessLogsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VaultAccessLogsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VaultAccessLogsList, a, b);
    }
}
exports.VaultAccessLogsList = VaultAccessLogsList;
