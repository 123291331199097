"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file outward_jobs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutwardJobContactsList = exports.OutwardJobContact = exports.OutwardJobsServiceContactCreateRequest = exports.OutwardJobsServicePaginatedOutwardItemsResponse = exports.OutwardJobOutwardItemsSearchRequest = exports.OutwardJobOutwardItemProspectiveInfoRequest = exports.OutwardJobOutwardItemHistoryRequest = exports.OutwardJobsOutwardItemsList = exports.OutwardJobOutwardItem = exports.OutwardJobsServiceOutwardItemUpdateRequest = exports.OutwardJobsServiceMultipleOutwardItemsCreateRequest = exports.OutwardJobsServiceMultipleOutwardItemsSingleton = exports.OutwardJobsServiceOutwardItemCreateRequest = exports.OutwardJobsServicePaginatedInwardItemsResponse = exports.OutwardJobInwardItemsSearchRequest = exports.OutwardJobsServiceSearchAllReq = exports.OutwardJobsServiceCountReq = exports.OutwardJobsServiceFilterReq = exports.OutwardJobsServicePaginationResponse = exports.OutwardJobsServicePaginationReq = exports.OutwardJobInwardItemProspectiveInfoRequest = exports.OutwardJobInwardItemHistoryRequest = exports.OutwardJobsInwardItemsList = exports.OutwardJobsList = exports.OutwardJobInwardItem = exports.OutwardJobsServiceInwardItemUpdateRequest = exports.OutwardJobsServiceMultipleInwardItemsCreateRequest = exports.OutwardJobsServiceMultipleInwardItemsSingleton = exports.OutwardJobsServiceInwardItemCreateRequest = exports.OutwardJob = exports.OutwardJobsServiceAutofillRequest = exports.OutwardJobsServiceUpdateRequest = exports.OutwardJobsServiceCreateRequest = exports.OUTWARD_JOB_OUTWARD_ITEM_STATUS = exports.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY = exports.OUTWARD_JOB_INWARD_ITEM_STATUS = exports.OUTWARD_JOB_INWARD_ITEM_SORT_KEY = exports.OUTWARD_JOB_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.OUTWARD_JOB_SORT_KEY
 */
var OUTWARD_JOB_SORT_KEY;
(function (OUTWARD_JOB_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED"] = 0] = "OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_CREATED_AT = 1;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_CREATED_AT"] = 1] = "OUTWARD_JOB_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_MODIFIED_AT = 2;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_MODIFIED_AT"] = 2] = "OUTWARD_JOB_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_APPROVED_ON = 3;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_APPROVED_ON"] = 3] = "OUTWARD_JOB_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_APPROVED_BY = 4;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_APPROVED_BY"] = 4] = "OUTWARD_JOB_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "OUTWARD_JOB_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_COMPLETED_ON = 6;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_COMPLETED_ON"] = 6] = "OUTWARD_JOB_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_REFERENCE_ID = 10;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_REFERENCE_ID"] = 10] = "OUTWARD_JOB_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "OUTWARD_JOB_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_CONSIGNEE_LOCATION_ID = 12;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_CONSIGNEE_LOCATION_ID"] = 12] = "OUTWARD_JOB_SORT_KEY_CONSIGNEE_LOCATION_ID";
    /**
     * Fetch ordered results by the vendor ID
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_VENDOR_ID = 13;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_VENDOR_ID"] = 13] = "OUTWARD_JOB_SORT_KEY_VENDOR_ID";
    /**
     * Fetch ordered results by the project ID
     *
     * @generated from enum value: OUTWARD_JOB_SORT_KEY_PROJECT_ID = 14;
     */
    OUTWARD_JOB_SORT_KEY[OUTWARD_JOB_SORT_KEY["OUTWARD_JOB_SORT_KEY_PROJECT_ID"] = 14] = "OUTWARD_JOB_SORT_KEY_PROJECT_ID";
})(OUTWARD_JOB_SORT_KEY || (exports.OUTWARD_JOB_SORT_KEY = OUTWARD_JOB_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(OUTWARD_JOB_SORT_KEY)
protobuf_1.proto3.util.setEnumType(OUTWARD_JOB_SORT_KEY, "Scailo.OUTWARD_JOB_SORT_KEY", [
    { no: 0, name: "OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "OUTWARD_JOB_SORT_KEY_CREATED_AT" },
    { no: 2, name: "OUTWARD_JOB_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "OUTWARD_JOB_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "OUTWARD_JOB_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "OUTWARD_JOB_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "OUTWARD_JOB_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "OUTWARD_JOB_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "OUTWARD_JOB_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "OUTWARD_JOB_SORT_KEY_CONSIGNEE_LOCATION_ID" },
    { no: 13, name: "OUTWARD_JOB_SORT_KEY_VENDOR_ID" },
    { no: 14, name: "OUTWARD_JOB_SORT_KEY_PROJECT_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.OUTWARD_JOB_INWARD_ITEM_SORT_KEY
 */
var OUTWARD_JOB_INWARD_ITEM_SORT_KEY;
(function (OUTWARD_JOB_INWARD_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT"] = 1] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the internal quantity
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_SORT_KEY_DELIVERY_DATE = 12;
     */
    OUTWARD_JOB_INWARD_ITEM_SORT_KEY[OUTWARD_JOB_INWARD_ITEM_SORT_KEY["OUTWARD_JOB_INWARD_ITEM_SORT_KEY_DELIVERY_DATE"] = 12] = "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_DELIVERY_DATE";
})(OUTWARD_JOB_INWARD_ITEM_SORT_KEY || (exports.OUTWARD_JOB_INWARD_ITEM_SORT_KEY = OUTWARD_JOB_INWARD_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(OUTWARD_JOB_INWARD_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(OUTWARD_JOB_INWARD_ITEM_SORT_KEY, "Scailo.OUTWARD_JOB_INWARD_ITEM_SORT_KEY", [
    { no: 0, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "OUTWARD_JOB_INWARD_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of outward job inward items
 *
 * @generated from enum Scailo.OUTWARD_JOB_INWARD_ITEM_STATUS
 */
var OUTWARD_JOB_INWARD_ITEM_STATUS;
(function (OUTWARD_JOB_INWARD_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    OUTWARD_JOB_INWARD_ITEM_STATUS[OUTWARD_JOB_INWARD_ITEM_STATUS["OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the outward job inward items must have been approved
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_STATUS_APPROVED = 1;
     */
    OUTWARD_JOB_INWARD_ITEM_STATUS[OUTWARD_JOB_INWARD_ITEM_STATUS["OUTWARD_JOB_INWARD_ITEM_STATUS_APPROVED"] = 1] = "OUTWARD_JOB_INWARD_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the outward job inward items must be waiting for approval
     *
     * @generated from enum value: OUTWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED = 2;
     */
    OUTWARD_JOB_INWARD_ITEM_STATUS[OUTWARD_JOB_INWARD_ITEM_STATUS["OUTWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED"] = 2] = "OUTWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED";
})(OUTWARD_JOB_INWARD_ITEM_STATUS || (exports.OUTWARD_JOB_INWARD_ITEM_STATUS = OUTWARD_JOB_INWARD_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(OUTWARD_JOB_INWARD_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(OUTWARD_JOB_INWARD_ITEM_STATUS, "Scailo.OUTWARD_JOB_INWARD_ITEM_STATUS", [
    { no: 0, name: "OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "OUTWARD_JOB_INWARD_ITEM_STATUS_APPROVED" },
    { no: 2, name: "OUTWARD_JOB_INWARD_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY
 */
var OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY;
(function (OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY) {
    /**
     * Fetch results by id
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch results by the creation timestamp
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT"] = 1] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch results by the modified timestamp
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch results by the approved on timestamp
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch results by the approved by field
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch results by the approver's role ID
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch results by the family ID
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch results by the internal quantity
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY[OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY["OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY";
})(OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY || (exports.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY = OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY, "Scailo.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY", [
    { no: 0, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of outward job outward items
 *
 * @generated from enum Scailo.OUTWARD_JOB_OUTWARD_ITEM_STATUS
 */
var OUTWARD_JOB_OUTWARD_ITEM_STATUS;
(function (OUTWARD_JOB_OUTWARD_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    OUTWARD_JOB_OUTWARD_ITEM_STATUS[OUTWARD_JOB_OUTWARD_ITEM_STATUS["OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the outward job outward items must have been approved
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED = 1;
     */
    OUTWARD_JOB_OUTWARD_ITEM_STATUS[OUTWARD_JOB_OUTWARD_ITEM_STATUS["OUTWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED"] = 1] = "OUTWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the outward job outward items must be waiting for approval
     *
     * @generated from enum value: OUTWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED = 2;
     */
    OUTWARD_JOB_OUTWARD_ITEM_STATUS[OUTWARD_JOB_OUTWARD_ITEM_STATUS["OUTWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED"] = 2] = "OUTWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED";
})(OUTWARD_JOB_OUTWARD_ITEM_STATUS || (exports.OUTWARD_JOB_OUTWARD_ITEM_STATUS = OUTWARD_JOB_OUTWARD_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(OUTWARD_JOB_OUTWARD_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(OUTWARD_JOB_OUTWARD_ITEM_STATUS, "Scailo.OUTWARD_JOB_OUTWARD_ITEM_STATUS", [
    { no: 0, name: "OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "OUTWARD_JOB_OUTWARD_ITEM_STATUS_APPROVED" },
    { no: 2, name: "OUTWARD_JOB_OUTWARD_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.OutwardJobsServiceCreateRequest
 */
class OutwardJobsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the outward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 13;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceCreateRequest = OutwardJobsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.OutwardJobsServiceUpdateRequest
 */
class OutwardJobsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the outward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceUpdateRequest, a, b);
    }
}
exports.OutwardJobsServiceUpdateRequest = OutwardJobsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.OutwardJobsServiceAutofillRequest
 */
class OutwardJobsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceAutofillRequest, a, b);
    }
}
exports.OutwardJobsServiceAutofillRequest = OutwardJobsServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.OutwardJob
 */
class OutwardJob extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this outward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this outward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this outward job
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this outward job was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the outward job
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 13;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated outward job inward items
     *
     * @generated from field: repeated Scailo.OutwardJobInwardItem inward_items_list = 20;
     */
    inwardItemsList = [];
    /**
     * The list of associated outward job outward items
     *
     * @generated from field: repeated Scailo.OutwardJobOutwardItem outward_items_list = 21;
     */
    outwardItemsList = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJob";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inward_items_list", kind: "message", T: OutwardJobInwardItem, repeated: true },
        { no: 21, name: "outward_items_list", kind: "message", T: OutwardJobOutwardItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJob().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJob().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJob().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJob, a, b);
    }
}
exports.OutwardJob = OutwardJob;
/**
 *
 * Describes the parameters required to add an inward item to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceInwardItemCreateRequest
 */
class OutwardJobsServiceInwardItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceInwardItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceInwardItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceInwardItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceInwardItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceInwardItemCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceInwardItemCreateRequest = OutwardJobsServiceInwardItemCreateRequest;
/**
 *
 * Describes the parameters required to add an individual item as part of multiple item addition to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceMultipleInwardItemsSingleton
 */
class OutwardJobsServiceMultipleInwardItemsSingleton extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceMultipleInwardItemsSingleton";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceMultipleInwardItemsSingleton().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceMultipleInwardItemsSingleton().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceMultipleInwardItemsSingleton().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceMultipleInwardItemsSingleton, a, b);
    }
}
exports.OutwardJobsServiceMultipleInwardItemsSingleton = OutwardJobsServiceMultipleInwardItemsSingleton;
/**
 *
 * Describes the parameters required to add multiple items to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceMultipleInwardItemsCreateRequest
 */
class OutwardJobsServiceMultipleInwardItemsCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * List of items
     *
     * @generated from field: repeated Scailo.OutwardJobsServiceMultipleInwardItemsSingleton list = 11;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceMultipleInwardItemsCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "list", kind: "message", T: OutwardJobsServiceMultipleInwardItemsSingleton, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceMultipleInwardItemsCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceMultipleInwardItemsCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceMultipleInwardItemsCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceMultipleInwardItemsCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceMultipleInwardItemsCreateRequest = OutwardJobsServiceMultipleInwardItemsCreateRequest;
/**
 *
 * Describes the parameters required to update an inward item in a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceInwardItemUpdateRequest
 */
class OutwardJobsServiceInwardItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceInwardItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceInwardItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceInwardItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceInwardItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceInwardItemUpdateRequest, a, b);
    }
}
exports.OutwardJobsServiceInwardItemUpdateRequest = OutwardJobsServiceInwardItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an inward item associated to a outward job
 *
 * @generated from message Scailo.OutwardJobInwardItem
 */
class OutwardJobInwardItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this outward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobInwardItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobInwardItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobInwardItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobInwardItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobInwardItem, a, b);
    }
}
exports.OutwardJobInwardItem = OutwardJobInwardItem;
/**
 *
 * Describes the message consisting of the list of outward jobs
 *
 * @generated from message Scailo.OutwardJobsList
 */
class OutwardJobsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.OutwardJob list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: OutwardJob, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsList, a, b);
    }
}
exports.OutwardJobsList = OutwardJobsList;
/**
 *
 * Describes the message consisting of the list of outward job inward items
 *
 * @generated from message Scailo.OutwardJobsInwardItemsList
 */
class OutwardJobsInwardItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.OutwardJobInwardItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsInwardItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: OutwardJobInwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsInwardItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsInwardItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsInwardItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsInwardItemsList, a, b);
    }
}
exports.OutwardJobsInwardItemsList = OutwardJobsInwardItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.OutwardJobInwardItemHistoryRequest
 */
class OutwardJobInwardItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobInwardItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobInwardItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobInwardItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobInwardItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobInwardItemHistoryRequest, a, b);
    }
}
exports.OutwardJobInwardItemHistoryRequest = OutwardJobInwardItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective outward job inward item
 *
 * @generated from message Scailo.OutwardJobInwardItemProspectiveInfoRequest
 */
class OutwardJobInwardItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobInwardItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobInwardItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobInwardItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobInwardItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobInwardItemProspectiveInfoRequest, a, b);
    }
}
exports.OutwardJobInwardItemProspectiveInfoRequest = OutwardJobInwardItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.OutwardJobsServicePaginationReq
 */
class OutwardJobsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.OUTWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = OUTWARD_JOB_SORT_KEY.OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this outward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServicePaginationReq, a, b);
    }
}
exports.OutwardJobsServicePaginationReq = OutwardJobsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.OutwardJobsServicePaginationResponse
 */
class OutwardJobsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.OutwardJob payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: OutwardJob, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServicePaginationResponse, a, b);
    }
}
exports.OutwardJobsServicePaginationResponse = OutwardJobsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.OutwardJobsServiceFilterReq
 */
class OutwardJobsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.OUTWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = OUTWARD_JOB_SORT_KEY.OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this outward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the outward job
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 23;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the inward family
     *
     * @generated from field: uint64 inward_family_id = 30;
     */
    inwardFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "inward_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceFilterReq, a, b);
    }
}
exports.OutwardJobsServiceFilterReq = OutwardJobsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.OutwardJobsServiceCountReq
 */
class OutwardJobsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this outward job
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the outward job
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the outward job
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 23;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the inward family
     *
     * @generated from field: uint64 inward_family_id = 30;
     */
    inwardFamilyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "inward_family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceCountReq, a, b);
    }
}
exports.OutwardJobsServiceCountReq = OutwardJobsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.OutwardJobsServiceSearchAllReq
 */
class OutwardJobsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.OUTWARD_JOB_SORT_KEY sort_key = 5;
     */
    sortKey = OUTWARD_JOB_SORT_KEY.OUTWARD_JOB_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 20;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 21;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceSearchAllReq, a, b);
    }
}
exports.OutwardJobsServiceSearchAllReq = OutwardJobsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.OutwardJobInwardItemsSearchRequest
 */
class OutwardJobInwardItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.OUTWARD_JOB_INWARD_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = OUTWARD_JOB_INWARD_ITEM_SORT_KEY.OUTWARD_JOB_INWARD_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.OUTWARD_JOB_INWARD_ITEM_STATUS status = 7;
     */
    status = OUTWARD_JOB_INWARD_ITEM_STATUS.OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the outward job
     *
     * @generated from field: uint64 outward_job_id = 20;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobInwardItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_INWARD_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_INWARD_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobInwardItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobInwardItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobInwardItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobInwardItemsSearchRequest, a, b);
    }
}
exports.OutwardJobInwardItemsSearchRequest = OutwardJobInwardItemsSearchRequest;
/**
 *
 * Describes the response to a pagination inward items request
 *
 * @generated from message Scailo.OutwardJobsServicePaginatedInwardItemsResponse
 */
class OutwardJobsServicePaginatedInwardItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.OutwardJobInwardItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServicePaginatedInwardItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: OutwardJobInwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServicePaginatedInwardItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServicePaginatedInwardItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServicePaginatedInwardItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServicePaginatedInwardItemsResponse, a, b);
    }
}
exports.OutwardJobsServicePaginatedInwardItemsResponse = OutwardJobsServicePaginatedInwardItemsResponse;
/**
 *
 * Describes the parameters required to add an outward item to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceOutwardItemCreateRequest
 */
class OutwardJobsServiceOutwardItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceOutwardItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceOutwardItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceOutwardItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceOutwardItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceOutwardItemCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceOutwardItemCreateRequest = OutwardJobsServiceOutwardItemCreateRequest;
/**
 *
 * Describes the parameters required to add an individual item as part of multiple item addition to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceMultipleOutwardItemsSingleton
 */
class OutwardJobsServiceMultipleOutwardItemsSingleton extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceMultipleOutwardItemsSingleton";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceMultipleOutwardItemsSingleton().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceMultipleOutwardItemsSingleton().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceMultipleOutwardItemsSingleton().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceMultipleOutwardItemsSingleton, a, b);
    }
}
exports.OutwardJobsServiceMultipleOutwardItemsSingleton = OutwardJobsServiceMultipleOutwardItemsSingleton;
/**
 *
 * Describes the parameters required to add multiple items to a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceMultipleOutwardItemsCreateRequest
 */
class OutwardJobsServiceMultipleOutwardItemsCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * List of items
     *
     * @generated from field: repeated Scailo.OutwardJobsServiceMultipleOutwardItemsSingleton list = 11;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceMultipleOutwardItemsCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "list", kind: "message", T: OutwardJobsServiceMultipleOutwardItemsSingleton, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceMultipleOutwardItemsCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceMultipleOutwardItemsCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceMultipleOutwardItemsCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceMultipleOutwardItemsCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceMultipleOutwardItemsCreateRequest = OutwardJobsServiceMultipleOutwardItemsCreateRequest;
/**
 *
 * Describes the parameters required to update an outward item in a outward job
 *
 * @generated from message Scailo.OutwardJobsServiceOutwardItemUpdateRequest
 */
class OutwardJobsServiceOutwardItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceOutwardItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceOutwardItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceOutwardItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceOutwardItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceOutwardItemUpdateRequest, a, b);
    }
}
exports.OutwardJobsServiceOutwardItemUpdateRequest = OutwardJobsServiceOutwardItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an outward item associated to a outward job
 *
 * @generated from message Scailo.OutwardJobOutwardItem
 */
class OutwardJobOutwardItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this outward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobOutwardItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobOutwardItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobOutwardItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobOutwardItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobOutwardItem, a, b);
    }
}
exports.OutwardJobOutwardItem = OutwardJobOutwardItem;
/**
 *
 * Describes the message consisting of the list of outward job outward items
 *
 * @generated from message Scailo.OutwardJobsOutwardItemsList
 */
class OutwardJobsOutwardItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.OutwardJobOutwardItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsOutwardItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: OutwardJobOutwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsOutwardItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsOutwardItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsOutwardItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsOutwardItemsList, a, b);
    }
}
exports.OutwardJobsOutwardItemsList = OutwardJobsOutwardItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.OutwardJobOutwardItemHistoryRequest
 */
class OutwardJobOutwardItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobOutwardItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobOutwardItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobOutwardItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobOutwardItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobOutwardItemHistoryRequest, a, b);
    }
}
exports.OutwardJobOutwardItemHistoryRequest = OutwardJobOutwardItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective outward job outward item
 *
 * @generated from message Scailo.OutwardJobOutwardItemProspectiveInfoRequest
 */
class OutwardJobOutwardItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobOutwardItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobOutwardItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobOutwardItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobOutwardItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobOutwardItemProspectiveInfoRequest, a, b);
    }
}
exports.OutwardJobOutwardItemProspectiveInfoRequest = OutwardJobOutwardItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.OutwardJobOutwardItemsSearchRequest
 */
class OutwardJobOutwardItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY.OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.OUTWARD_JOB_OUTWARD_ITEM_STATUS status = 7;
     */
    status = OUTWARD_JOB_OUTWARD_ITEM_STATUS.OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the outward job
     *
     * @generated from field: uint64 outward_job_id = 20;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The item hash of the family
     *
     * @generated from field: string item_hash = 22;
     */
    itemHash = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobOutwardItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_OUTWARD_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(OUTWARD_JOB_OUTWARD_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobOutwardItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobOutwardItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobOutwardItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobOutwardItemsSearchRequest, a, b);
    }
}
exports.OutwardJobOutwardItemsSearchRequest = OutwardJobOutwardItemsSearchRequest;
/**
 *
 * Describes the response to a pagination outward items request
 *
 * @generated from message Scailo.OutwardJobsServicePaginatedOutwardItemsResponse
 */
class OutwardJobsServicePaginatedOutwardItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.OutwardJobOutwardItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServicePaginatedOutwardItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: OutwardJobOutwardItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServicePaginatedOutwardItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServicePaginatedOutwardItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServicePaginatedOutwardItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServicePaginatedOutwardItemsResponse, a, b);
    }
}
exports.OutwardJobsServicePaginatedOutwardItemsResponse = OutwardJobsServicePaginatedOutwardItemsResponse;
/**
 *
 * Describes the parameters necessary to create a outward job contact
 *
 * @generated from message Scailo.OutwardJobsServiceContactCreateRequest
 */
class OutwardJobsServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobsServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobsServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobsServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobsServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobsServiceContactCreateRequest, a, b);
    }
}
exports.OutwardJobsServiceContactCreateRequest = OutwardJobsServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a outward job contact
 *
 * @generated from message Scailo.OutwardJobContact
 */
class OutwardJobContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this outward job
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the outward job ID
     *
     * @generated from field: uint64 outward_job_id = 10;
     */
    outwardJobId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "outward_job_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobContact, a, b);
    }
}
exports.OutwardJobContact = OutwardJobContact;
/**
 *
 * Describes the message consisting of the list of outward job contacts
 *
 * @generated from message Scailo.OutwardJobContactsList
 */
class OutwardJobContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.OutwardJobContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.OutwardJobContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: OutwardJobContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new OutwardJobContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OutwardJobContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OutwardJobContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(OutwardJobContactsList, a, b);
    }
}
exports.OutwardJobContactsList = OutwardJobContactsList;
