import { _returnInCents, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, internationalizeMoney, round, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForInwardJobsService, InwardJob, InwardJobsServiceFilterReq } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";
import ApexCharts from 'apexcharts';
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

export function handleInwardJobInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleInwardJobInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    const transport = getTransport();

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForInwardJobsService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new InwardJobsServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded grid grid-cols-12";
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
    });
}

/**Displays all the charts relevant to inward jobs */
export function displayAllRelevantCharts(inwardjobs: InwardJob[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let cumulativeOutwardSeriesData = <number[]>[];
    let incrementingTotalOutwardCount = 0;
    let totalOutwardCount = 0;

    let cumulativeInwardSeriesData = <number[]>[];
    let incrementingTotalInwardCount = 0;
    let totalInwardCount = 0;

    inwardjobs.forEach(inwardjob => {
        incrementingTotalOutwardCount += inwardjob.outwardItemsList.length;
        cumulativeOutwardSeriesData.push(parseFloat(round(incrementingTotalOutwardCount)));
        totalOutwardCount += inwardjob.outwardItemsList.length;

        incrementingTotalInwardCount += inwardjob.inwardItemsList.length;
        cumulativeInwardSeriesData.push(parseFloat(round(incrementingTotalInwardCount)));
        totalInwardCount += inwardjob.inwardItemsList.length;
    });


    let options = {
        title: {
            text: `Total Inward Jobs: ${inwardjobs.length}, Total Outward Item Count: ${new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumSignificantDigits: 2 }).format(parseFloat(round(totalOutwardCount)))}, Total Inward Item Count: ${new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumSignificantDigits: 2 }).format(parseFloat(round(totalInwardCount)))}`,
            align: "left",
            margin: 30,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Inward Jobs Outward Item Count",
                type: "area",
                data: cumulativeOutwardSeriesData
            },
            // Line Series
            {
                name: "Individual Inward Job Outward Item Count",
                type: "line",
                data: inwardjobs.map(inwardjob => parseFloat(round(inwardjob.outwardItemsList.length)))
            },
            // Area Series
            {
                name: "Cumulative Inward Jobs Inward Item Count",
                type: "area",
                data: cumulativeInwardSeriesData
            },
            // Line Series
            {
                name: "Individual Inward Job Inward Item Count",
                type: "line",
                data: inwardjobs.map(inwardjob => parseFloat(round(inwardjob.inwardItemsList.length)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.10, 1],
        },
        labels: inwardjobs.map(inwardjob => convertBigIntTimestampToDate(inwardjob.approvalMetadata!.approvedOn)),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Inward Jobs Outward Item Count",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Inward Job Outward Item Count",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let inwardjob = inwardjobs[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Inward Job Number: ${inwardjob.approvalMetadata!.approvedOn > 0 ? inwardjob.finalRefNumber : inwardjob.referenceId}</li>
                        <li>Inward Job Line Outward Count: ${parseFloat(round(inwardjob.outwardItemsList.length))}</li>
                        <li>Cumulative Line Outward Count: ${cumulativeOutwardSeriesData[dataPointIndex]}</li>
                        <li>Inward Job Line Inward Count: ${parseFloat(round(inwardjob.inwardItemsList.length))}</li>
                        <li>Cumulative Line Inward Count: ${cumulativeInwardSeriesData[dataPointIndex]}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(inwardjob.approvalMetadata!.approvedOn)}</li>
                        <li>Index: ${dataPointIndex + 1}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(chartsDiv, options);
    chart.render();
}