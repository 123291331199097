"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file actions_codes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsCodesServiceSearchAllReq = exports.ActionsCodesServiceCountReq = exports.ActionsCodesServiceFilterReq = exports.ActionsCodesServicePaginationResponse = exports.ActionsCodesServicePaginationReq = exports.ActionsCodesList = exports.ActionCode = exports.ActionsCodesServiceUpdateRequest = exports.ActionsCodesServiceCreateRequest = exports.ACTION_CODE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.ACTION_CODE_SORT_KEY
 */
var ACTION_CODE_SORT_KEY;
(function (ACTION_CODE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "ACTION_CODE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_CREATED_AT = 1;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_CREATED_AT"] = 1] = "ACTION_CODE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_MODIFIED_AT = 2;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_MODIFIED_AT"] = 2] = "ACTION_CODE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_APPROVED_ON = 3;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_APPROVED_ON"] = 3] = "ACTION_CODE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_APPROVED_BY = 4;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_APPROVED_BY"] = 4] = "ACTION_CODE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "ACTION_CODE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_NAME = 10;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_NAME"] = 10] = "ACTION_CODE_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: ACTION_CODE_SORT_KEY_CODE = 11;
     */
    ACTION_CODE_SORT_KEY[ACTION_CODE_SORT_KEY["ACTION_CODE_SORT_KEY_CODE"] = 11] = "ACTION_CODE_SORT_KEY_CODE";
})(ACTION_CODE_SORT_KEY || (exports.ACTION_CODE_SORT_KEY = ACTION_CODE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(ACTION_CODE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(ACTION_CODE_SORT_KEY, "Scailo.ACTION_CODE_SORT_KEY", [
    { no: 0, name: "ACTION_CODE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "ACTION_CODE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "ACTION_CODE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "ACTION_CODE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "ACTION_CODE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "ACTION_CODE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "ACTION_CODE_SORT_KEY_NAME" },
    { no: 11, name: "ACTION_CODE_SORT_KEY_CODE" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ActionsCodesServiceCreateRequest
 */
class ActionsCodesServiceCreateRequest extends protobuf_1.Message {
    /**
     * ActionsCodes a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * ActionsCodes any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The name of the action code
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the action code is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The color of the text that is displayed for easy recognition
     *
     * @generated from field: string fg_color = 12;
     */
    fgColor = "";
    /**
     * The background color that is displayed for easy recognition
     *
     * @generated from field: string bg_color = 13;
     */
    bgColor = "";
    /**
     * The ID of the associated non-leaf parent action code (0, if the first action code that is being created is a leaf action code)
     *
     * @generated from field: uint64 parent_action_code_id = 16;
     */
    parentActionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Stores if this is a leaf action code or a non-leaf action code
     *
     * @generated from field: bool is_leaf = 17;
     */
    isLeaf = false;
    /**
     * The description of the action code
     *
     * @generated from field: string description = 18;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "fg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "bg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "parent_action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "is_leaf", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 18, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServiceCreateRequest, a, b);
    }
}
exports.ActionsCodesServiceCreateRequest = ActionsCodesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ActionsCodesServiceUpdateRequest
 */
class ActionsCodesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * ActionsCodes any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that actions codes if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The name of the action code
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The color of the text that is displayed for easy recognition
     *
     * @generated from field: string fg_color = 12;
     */
    fgColor = "";
    /**
     * The background color that is displayed for easy recognition
     *
     * @generated from field: string bg_color = 13;
     */
    bgColor = "";
    /**
     * The description of the action code
     *
     * @generated from field: string description = 18;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "fg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "bg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServiceUpdateRequest, a, b);
    }
}
exports.ActionsCodesServiceUpdateRequest = ActionsCodesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ActionCode
 */
class ActionCode extends protobuf_1.Message {
    /**
     * ActionsCodes a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * ActionsCodes the metadata of this action code
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * ActionsCodes the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this action code
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * ActionsCodes the logs of every operation performed on this action code
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The name of the action code
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique code by which the action code is classified
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The color of the text that is displayed for easy recognition
     *
     * @generated from field: string fg_color = 12;
     */
    fgColor = "";
    /**
     * The background color that is displayed for easy recognition
     *
     * @generated from field: string bg_color = 13;
     */
    bgColor = "";
    /**
     * The ID of the associated non-leaf parent action code (0, if the first action code that is being created is a leaf action code)
     *
     * @generated from field: uint64 parent_action_code_id = 16;
     */
    parentActionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Stores if this is a leaf action code or a non-leaf action code
     *
     * @generated from field: bool is_leaf = 17;
     */
    isLeaf = false;
    /**
     * The description of the action code
     *
     * @generated from field: string description = 18;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionCode";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "fg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "bg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "parent_action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "is_leaf", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 18, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ActionCode().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionCode().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionCode().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionCode, a, b);
    }
}
exports.ActionCode = ActionCode;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ActionsCodesList
 */
class ActionsCodesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ActionCode list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ActionCode, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesList, a, b);
    }
}
exports.ActionsCodesList = ActionsCodesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ActionsCodesServicePaginationReq
 */
class ActionsCodesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTION_CODE_SORT_KEY sort_key = 5;
     */
    sortKey = ACTION_CODE_SORT_KEY.ACTION_CODE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this action code
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTION_CODE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServicePaginationReq, a, b);
    }
}
exports.ActionsCodesServicePaginationReq = ActionsCodesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ActionsCodesServicePaginationResponse
 */
class ActionsCodesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ActionCode payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ActionCode, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServicePaginationResponse, a, b);
    }
}
exports.ActionsCodesServicePaginationResponse = ActionsCodesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ActionsCodesServiceFilterReq
 */
class ActionsCodesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTION_CODE_SORT_KEY sort_key = 5;
     */
    sortKey = ACTION_CODE_SORT_KEY.ACTION_CODE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this action code
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the action code
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the action code is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The color of the text that is displayed for easy recognition
     *
     * @generated from field: string fg_color = 22;
     */
    fgColor = "";
    /**
     * The background color that is displayed for easy recognition
     *
     * @generated from field: string bg_color = 23;
     */
    bgColor = "";
    /**
     * The ID of the associated non-leaf parent action code (0, if the first action code that is being created is a leaf action code)
     *
     * @generated from field: uint64 parent_action_code_id = 26;
     */
    parentActionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 27;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTION_CODE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "fg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "bg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "parent_action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServiceFilterReq, a, b);
    }
}
exports.ActionsCodesServiceFilterReq = ActionsCodesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ActionsCodesServiceCountReq
 */
class ActionsCodesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this action code
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the action code
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code by which the action code is classified
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The color of the text that is displayed for easy recognition
     *
     * @generated from field: string fg_color = 22;
     */
    fgColor = "";
    /**
     * The background color that is displayed for easy recognition
     *
     * @generated from field: string bg_color = 23;
     */
    bgColor = "";
    /**
     * The ID of the associated non-leaf parent action code (0, if the first action code that is being created is a leaf action code)
     *
     * @generated from field: uint64 parent_action_code_id = 26;
     */
    parentActionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 27;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "fg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "bg_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "parent_action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServiceCountReq, a, b);
    }
}
exports.ActionsCodesServiceCountReq = ActionsCodesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ActionsCodesServiceSearchAllReq
 */
class ActionsCodesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.ACTION_CODE_SORT_KEY sort_key = 5;
     */
    sortKey = ACTION_CODE_SORT_KEY.ACTION_CODE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the associated non-leaf parent action code (0, if the first action code that is being created is a leaf action code)
     *
     * @generated from field: uint64 parent_action_code_id = 25;
     */
    parentActionCodeId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 26;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ActionsCodesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(ACTION_CODE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "parent_action_code_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new ActionsCodesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ActionsCodesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ActionsCodesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ActionsCodesServiceSearchAllReq, a, b);
    }
}
exports.ActionsCodesServiceSearchAllReq = ActionsCodesServiceSearchAllReq;
